/* eslint-disable @typescript-eslint/no-use-before-define */
import CloseIcon from '@mui/icons-material/Close';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import VolumeDownOutlinedIcon from '@mui/icons-material/VolumeDownOutlined';
import VolumeUpOutlinedIcon from '@mui/icons-material/VolumeUpOutlined';
import { Box, IconButton, styled } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSearchParam } from 'react-use';
import streamPlaceholder from '../../../assets/stream-placeholder.png';
import ConfirmDialog from '../../../base/components/ConfirmDialog/ConfirmDialog';
import { Flex } from '../../../base/components/Flex';
import { IconButtonFilled } from '../../../base/components/IconButton';
import ScreenMirrorWorkaround from '../../../base/components/ScreenMirrorWorkaround/ScreenMirrorWorkaround';
import { useUser } from '../../../base/components/UserProvider/useUserHook';
import { VrDevicesContext } from '../../../base/components/VrDevicesProvider/VrDevicesProvider';
import { UserPermission } from '../../../graphql/graphql-operations';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import { Loader } from '../../components/Loader';
import { SessionBattery } from './components/SessionBattery';
import SessionBottomPanel from './components/SessionBottomPanel';
import SessionStatus from './components/SessionStatus';
import useDeviceVolume from './useDeviceVolume';
import { useSessionData } from './useSessionData';

const FULLSCREENABLE_ELEMENT_ID = 'streaming-wrapper';

interface Props {
  skipNavigation?: boolean;
}

const SessionDetailPage = ({ skipNavigation }: Props) => {
  const { vrSessionId } = useParams<{ vrSessionId: string }>();
  const multipleVariant = typeof useSearchParam('multiple') === 'string';
  const uncontrolled = typeof useSearchParam('uncontrolled') === 'string';

  const { boxDevices } = useContext(VrDevicesContext);
  const { _t } = useTranslationPrefix('SessionDetailPage');
  const { enqueueSnackbar } = useSnackbar();
  const [fullScreen, setFullScreen] = useState(!!document.fullscreenElement);

  document.onfullscreenchange = (e) => {
    if (!!document.fullscreenElement !== fullScreen) {
      setFullScreen(!!document.fullscreenElement);
    }
  };

  const navigate = useNavigate();
  const { hasAnyPermission } = useUser();
  const [endConfirmData, setEndConfirmData] = useState<{
    open: boolean;
    all?: boolean;
    deviceIds?: string[];
  }>({ open: false });
  const [nextConfirmOpen, setNextConfirmOpen] = useState<{
    open: boolean;
    itemId?: string;
  }>({ open: false });
  const skip = (itemId?: string) => setNextConfirmOpen({ open: true, itemId });

  const { data, loading, endMutation, handleNext } = useSessionData({
    vrSessionId: vrSessionId!,
    onNextCompleded: () => setNextConfirmOpen({ open: false }),
  });
  const userId = data?.session.user?.id;
  const deviceId = data?.session.vrDevice.id;

  const handleFinishClick = (all: boolean) =>
    setEndConfirmData({
      open: true,
      all: all,
      deviceIds: all
        ? boxDevices.filter((d) => !!d.session?.id).map((d) => d.id)
        : [deviceId!],
    });

  const handleEndCompleted = () => {
    const endData = endConfirmData;
    setEndConfirmData({ open: false });
    if (multipleVariant && !endData.all) {
      return;
    }
    if (skipNavigation) {
      return;
    }
    if (
      !endData.all &&
      userId &&
      hasAnyPermission(
        UserPermission.MANAGE_COMPANY_USERS,
        UserPermission.VIEW_COMPANY_USERS,
      )
    ) {
      navigate(`/users/${userId}`);
    } else {
      navigate(`/`);
    }
  };

  const handleEndSession = () => {
    if (!endConfirmData?.deviceIds?.length) {
      return;
    }
    endMutation({
      variables: {
        deviceIds: endConfirmData.deviceIds,
      },
      onCompleted: handleEndCompleted,
    });
  };

  const { increaseButtonProps, decreaseButtonProps } = useDeviceVolume(
    data?.session?.vrDevice?.id,
  );

  const toggleFullstreen = () => {
    if (!document.fullscreenElement) {
      const ele = document.getElementById(FULLSCREENABLE_ELEMENT_ID);
      ele?.requestFullscreen().catch((err) => {
        enqueueSnackbar(_t('fullscreenFailed'), { variant: 'warning' });
      });
    } else {
      document.exitFullscreen();
    }
  };

  const showStream =
    data?.session.startedAt &&
    !data.session.endedAt &&
    data?.session.vrDevice.id;

  const renderStream = () => {
    return (
      <>
        {showStream && (
          <ScreenMirrorWorkaround
            className={classes.stream}
            deviceId={data?.session.vrDevice.id!}
          />
        )}
        {!showStream && (
          <img
            alt="Stream placeholder"
            src={streamPlaceholder}
            className={classes.streamPlaceholder}
          />
        )}
      </>
    );
  };

  return loading ? (
    <Loader />
  ) : uncontrolled ? (
    <SessionDetailPageImgWrapper>{renderStream()}</SessionDetailPageImgWrapper>
  ) : (
    <Flex flexDirection="column" width="100%" height="100%">
      <Flex flexGrow={1} position="relative">
        <SessionDetailPageImgWrapper
          id={FULLSCREENABLE_ELEMENT_ID}
          onDoubleClick={toggleFullstreen}
        >
          {renderStream()}
          {fullScreen && (
            <TransparentButton
              id="exit-fullscreen"
              onClick={toggleFullstreen}
              sx={{
                position: 'absolute',
                bottom: '2rem',
                right: '2rem',
                color: 'text.secondary',
              }}
            >
              <FullscreenExitIcon />
            </TransparentButton>
          )}
        </SessionDetailPageImgWrapper>

        <Flex
          flexDirection="column"
          justifyContent="space-between"
          width="100%"
          flexGrow={1}
          position="relative"
          p={{ xs: 2, sm: 6 }}
          onDoubleClick={toggleFullstreen}
        >
          <Flex
            justifyContent={{ xs: 'start', sm: 'space-between' }}
            alignItems={{ xs: 'strech', sm: 'start' }}
            flexDirection={{ xs: 'column', sm: 'row' }}
            gap={2}
            flexGrow={1}
          >
            <SessionStatus session={data?.session} />
            <SessionBattery
              deviceId={data?.session.vrDevice?.id}
              multipleVariant={multipleVariant}
              onFinishClick={handleFinishClick}
            />
          </Flex>
          <Flex gap={4} flexShrink={0} flexDirection="column">
            <Flex gap={2} justifyContent="space-between">
              <Flex gap={2}>
                {data?.session?.vrDevice?.id && (
                  <>
                    <IconButtonFilled
                      id="decrease-volume"
                      color="white"
                      {...decreaseButtonProps}
                    >
                      <VolumeDownOutlinedIcon />
                    </IconButtonFilled>
                    <IconButtonFilled
                      id="increase-volume"
                      color="white"
                      {...increaseButtonProps}
                    >
                      <VolumeUpOutlinedIcon />
                    </IconButtonFilled>
                  </>
                )}
              </Flex>
              <Flex>
                <IconButtonFilled
                  id="fullscreen-toggle"
                  color="white"
                  onClick={toggleFullstreen}
                >
                  {fullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                </IconButtonFilled>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <SessionBottomPanel
        session={data?.session}
        skip={skip}
        multipleVariant={multipleVariant}
        onFinishClick={handleFinishClick}
      />
      <ConfirmDialog
        open={endConfirmData.open}
        title={
          endConfirmData.all
            ? _t('endAllSessionsConfirm', 'End all active sessions?')
            : _t('endSessionConfirm', 'End current session?')
        }
        onConfirm={handleEndSession}
        onClose={() => setEndConfirmData({ open: false })}
        confirmButtonStartIcon={<CloseIcon />}
        confirmLabel={_t('finish', 'Finish')}
        confirmButtonColor="error"
      />
      <ConfirmDialog
        open={nextConfirmOpen.open}
        title={_t('nextConfirm', 'Skip current scene?')}
        onConfirm={() => handleNext(nextConfirmOpen.itemId)}
        onClose={() => setNextConfirmOpen({ open: false })}
        confirmButtonStartIcon={<SkipNextIcon />}
        confirmLabel={_t('skip', 'Skip')}
      />
    </Flex>
  );
};

export default SessionDetailPage;

const classes = {
  stream: 'SessionDetailPage-stream',
  streamPlaceholder: 'SessionDetailPage-streamPlaceholder',
};

const SessionDetailPageImgWrapper = styled(Box)(
  ({ theme }) => `
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${
      theme.palette.mode === 'dark' ? 'transparent' : 'black'
    };
    & .${classes.stream}{
      width: 100%;
      max-height: 100%;
      max-width: 100%;
      object-fit: contain;
      color: transparent;
    }
    
    & .${classes.streamPlaceholder} {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
`,
);

const TransparentButton = styled(IconButton)`
  & > svg {
    font-size: 3rem;
    //Make svg icon with black border on all sides to be visible on both white and black background
    filter: drop-shadow(2px 0px 0px rgba(0, 0, 0, 0.7))
      drop-shadow(-2px 0px 0px rgba(0, 0, 0, 0.7))
      drop-shadow(0px 2px 0px rgba(0, 0, 0, 0.7))
      drop-shadow(0px -2px 0px rgba(0, 0, 0, 0.7));
  }
`;
