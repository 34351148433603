import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import {
  Box,
  Collapse,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Modal,
  styled,
} from '@mui/material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import UDILabel from '../../assets/UDI-label.png';
import CEIcon from '../../base/components/Icons/CEIcon';
import CzIcon from '../../base/components/Icons/CzIcon';
import DeIcon from '../../base/components/Icons/DeIcon';
import GbIcon from '../../base/components/Icons/GbIcon';
import { useUser } from '../../base/components/UserProvider/useUserHook';
import { useChangeUserLanguage } from '../../hooks/useChangeUserLanguage';
import { useTranslationPrefix } from '../../hooks/useTranslationPrefix';
import { LANGUAGES } from '../../lib/constants';
import { isMedical } from '../../lib/useAppVariant';
interface Props {
  onMenuClose: () => void;
}

const languageIcons: Record<string, React.ReactElement> = {
  cs: <CzIcon />,
  en: <GbIcon />,
  de: <DeIcon />,
};

const ClientSettingsMenu = ({ onMenuClose }: Props) => {
  const { _t, i18n, t } = useTranslationPrefix('ClientSettingsMenu');
  const [languagesOpen, setLanguagesOpen] = useState(false);
  const { user } = useUser();
  const [UDIOpen, setUDIOpen] = useState(false);
  const [handleLanguageChange] = useChangeUserLanguage(() =>
    setLanguagesOpen(false),
  );
  const medical = isMedical();

  const availableLanguages = LANGUAGES.filter((l) => l !== i18n.language);

  const renderLanguageMenuItem = ({
    lang,
    onClick,
    expanded,
  }: {
    lang: string;
    onClick: () => void;
    expanded?: boolean;
  }) => {
    return (
      <MenuItem key={lang} onClick={onClick} className={classes.item}>
        <ListItemIcon
          sx={{
            fontSize: '1.5rem',
          }}
        >
          {languageIcons[lang]}
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            color: 'grey.900',
          }}
        >
          {t(`enum.language.${lang}`, lang)}
        </ListItemText>
        {typeof expanded === 'boolean' && (
          <ListItemIcon
            sx={{
              fontSize: '1.5rem',
              color: 'primary.main',
            }}
          >
            {expanded ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
          </ListItemIcon>
        )}
      </MenuItem>
    );
  };

  return (
    <ClientSettingsMenuStyled>
      {medical && (
        <MenuItem onClick={() => setUDIOpen(true)} className={classes.item}>
          <ListItemIcon
            sx={{
              fontSize: '1.5rem',
              color: 'grey.400',
            }}
          >
            <CEIcon />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{
              color: 'grey.900',
            }}
          >
            {_t('udiLable', 'UDI label')}
          </ListItemText>
        </MenuItem>
      )}
      <MenuItem
        component={Link}
        onClick={onMenuClose}
        to={user?.id ? '/help' : '/help-login'}
        className={classes.item}
      >
        <ListItemIcon
          sx={{
            fontSize: '1.5rem',
            color: 'grey.400',
          }}
        >
          <HelpOutlineRoundedIcon />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            color: 'grey.900',
          }}
        >
          {_t('help', 'Help')}
        </ListItemText>
      </MenuItem>
      {renderLanguageMenuItem({
        lang: i18n.language,
        onClick: () => setLanguagesOpen((o) => !o),
        expanded: languagesOpen,
      })}
      <Collapse in={languagesOpen} unmountOnExit>
        {availableLanguages.map((lang) =>
          renderLanguageMenuItem({
            lang: lang,
            onClick: () => handleLanguageChange(lang),
          }),
        )}
      </Collapse>
      <Modal open={UDIOpen} onClose={() => setUDIOpen(false)}>
        <ImageWrapper>
          <img alt="range" src={UDILabel} />
        </ImageWrapper>
      </Modal>
    </ClientSettingsMenuStyled>
  );
};
export default ClientSettingsMenu;

const classes = {
  item: 'ClientSettingsMenu-item',
};

//Styling menu item breaks types for component type link
const ClientSettingsMenuStyled = styled(MenuList)(
  ({ theme: { spacing, palette } }) => `
    padding: 0;
    padding-left: ${spacing(2)};
  
    & .${classes.item} {
      padding-top: 1rem;
      padding-bottom: 1rem;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }  
  `,
);

const ImageWrapper = styled(Box)`
  background-color: white;
  padding: 1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  & img {
    max-width: 90vw;
  }
`;
