import { useTranslation } from 'react-i18next';
import HasPermission from '../../../base/components/UserProvider/HasPermission';
import { useUser } from '../../../base/components/UserProvider/useUserHook';
import { UserPermission } from '../../../graphql/graphql-operations';
import { SectionPaper } from '../../components/SectionPaper';
import { TopBar } from '../../components/TopBar';
import Actions from './Actions';
import ManualList from './ManualList';
import ManualTable from './ManualTable';

const Manuals = () => {
  const { t } = useTranslation();
  const { hasPermission } = useUser();
  return (
    <>
      <TopBar
        leftSideText={t(`manuals.topBar.title`, 'Manual')}
        actions={
          <HasPermission val={UserPermission.MANAGE_MANUALS}>
            <Actions />
          </HasPermission>
        }
      />
      <SectionPaper>
        {hasPermission(UserPermission.MANAGE_MANUALS) ? (
          <ManualTable />
        ) : (
          <ManualList />
        )}
      </SectionPaper>
    </>
  );
};

export default Manuals;
