import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
  onNewClientClick: () => void;
}

const PatientsActions = ({ onNewClientClick }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Button
        id="patients-actions-create"
        size="small"
        color="primary"
        variant="contained"
        startIcon={<AddIcon viewBox="5 5 14 14" />}
        onClick={onNewClientClick}
      >
        {t('patients.actions.newPatient', 'New Patient')}
      </Button>
    </>
  );
};

export default PatientsActions;
