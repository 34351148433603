/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from '@mui/material';
import { formatISO } from 'date-fns';
import { Parser, transforms } from 'json2csv';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BaseTabs } from '../../../base/components/BaseTabs';
import { TabData } from '../../../base/components/BaseTabs/BaseTabs';
import { SessionPlaylistItemReportFragmentFragment } from '../../../graphql/graphql-operations';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import { onlyUnique } from '../../../lib/arrayFilters';
import {
  csvDownload,
  csvDurationTransform,
  csvTimestampTransform,
} from '../../../lib/csvDownload';
import ContentWrapper from '../../components/ContentWrapper';
import PageHeader from '../../components/PageHeader';
import ReportSubCompaniesList from './components/ReportSubCompaniesList';
import ReportSummaryCard from './components/ReportSummaryCard';
import ReportUserList from './components/ReportUserList';
import useSessionPlaylistItemReports, {
  ReportContent,
} from './useSessionPlaylistItemReports';

const ReportDetailPage = () => {
  const { id } = useParams<{ id: string }>();
  const { _t } = useTranslationPrefix('ReportDetailPage');
  const [report, setReport] = useState<ReportContent | null>();
  const { getReport } = useSessionPlaylistItemReports();
  const [companyId, setCompanyId] = useState<string>('');
  const [selectedTab, setSelectedTab] = useState<'users' | 'units'>('units');

  const data = useMemo(
    () => report?.data.filter((row) => row.company.id === companyId) || [],
    [report?.data, companyId],
  );

  const subCompanies = useMemo(
    () =>
      report?.subCompanies.filter((row) => row.parentCompanyId === companyId) ||
      [],
    [report?.subCompanies, companyId],
  );

  const tabs: TabData[] = useMemo(
    () => [
      {
        label: _t('orgUnits', 'Org. Units'),
        value: 'units',
        count: subCompanies.length,
      },
      {
        label: _t('users', 'Users'),
        value: 'users',
        count: data.map((d) => d.launchedByUser?.id).filter(onlyUnique).length,
      },
    ],
    [subCompanies, data],
  );

  const loadData = async () => {
    if (!id) {
      setReport(null);
      return;
    }
    const report = await getReport(id);
    setReport(report);
    setCompanyId(report.companyId);
  };

  useEffect(() => {
    loadData();
  }, [id]);

  useEffect(() => {
    if (!subCompanies.length && data.length && selectedTab === 'units') {
      setSelectedTab('users');
    }
    if (subCompanies.length && !data.length && selectedTab === 'users') {
      setSelectedTab('units');
    }
  }, [subCompanies, data]);

  const getFileName = () => {
    const from = report?.from
      ? formatISO(new Date(report.from), { representation: 'date' })
      : null;
    const to = report?.to
      ? formatISO(new Date(report.to), { representation: 'date' })
      : null;
    const companyName = report?.companyName || null;
    return `${companyName}_${from}_${to}.csv`;
  };

  const handleExportClick = () => {
    const data: SessionPlaylistItemReportFragmentFragment[] =
      report?.data || [];
    const csv = new Parser({
      fields: [
        {
          value: 'startedAt',
          label: _t('csv.startedAt', 'Started at'),
        },
        {
          value: 'endedAt',
          label: _t('csv.endedAt', 'Ended at'),
        },
        {
          value: 'company.name',
          label: _t('csv.orgUnit', 'Org. unit'),
        },
        {
          value: 'vrDevice.id',
          label: _t('csv.vrdeviceId', 'VR device id'),
        },
        {
          value: 'vrDevice.name',
          label: _t('csv.vrdeviceName', 'VR device'),
        },
        {
          value: 'launchedByUser.firstName',
          label: _t('csv.firstName', 'Firstname'),
        },
        {
          value: 'launchedByUser.lastName',
          label: _t('csv.lastName', 'Lastname'),
        },
        {
          value: 'launchedByUser.externalId',
          label: _t('csv.externalId', 'User id'),
        },
        {
          value: 'durationMs',
          label: _t('csv.durationMs', 'Duration'),
        },
        {
          value: 'scene.name_t',
          label: _t('csv.sceneName', 'Scene'),
        },
        {
          value: 'completed',
          label: _t('csv.completed', 'Completed'),
        },
      ],
      transforms: [
        transforms.flatten(),
        csvTimestampTransform('startedAt', 'endedAt'),
        csvDurationTransform('durationMs'),
      ],
      withBOM: true,
      delimiter: ';',
    }).parse(data);
    csvDownload(csv, getFileName());
  };

  const handleNavigateToParentCompany = () => {
    const parentCompanyId =
      report?.subCompanies?.find((c) => c.id === companyId)?.parentCompanyId ||
      report?.companyId;
    if (parentCompanyId) {
      setCompanyId(parentCompanyId);
    }
  };

  return (
    <ContentWrapper>
      <PageHeader
        title={_t('title', '{{companyName}} report', {
          companyName: report?.companyName,
        })}
        back={
          companyId === report?.companyId
            ? '../'
            : handleNavigateToParentCompany
        }
      >
        {report?.data.length && (
          <Button
            id="export-report"
            onClick={handleExportClick}
            variant="contained"
          >
            {_t('exportReport', 'Export report')}
          </Button>
        )}
      </PageHeader>
      {report && (
        <>
          <ReportSummaryCard
            report={{
              ...report,
              ...(report?.subCompanies.find((c) => c.id === companyId) || {}),
            }}
            sx={{
              backgroundColor: 'transparent',
              backgroundImage: 'none',
              boxShadow: ({ palette }) =>
                `inset 0 0 0 1px ${
                  palette.mode === 'dark'
                    ? palette.grey[500]
                    : palette.grey[100]
                }`,
              color: 'text.secondary',
            }}
          />
          <BaseTabs
            tabs={tabs}
            value={selectedTab}
            onChange={(e, val) => setSelectedTab(val)}
            sx={{ my: 2 }}
          />

          {selectedTab === 'users' && <ReportUserList data={data} />}
          {selectedTab === 'units' && (
            <ReportSubCompaniesList
              subCompanies={subCompanies}
              onCompanySelect={setCompanyId}
            />
          )}
        </>
      )}
    </ContentWrapper>
  );
};

export default ReportDetailPage;
