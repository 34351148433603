import { TableCell, TableHead, TableRow } from '@mui/material';
import BaseTableHeadCell from './BaseTableHeadCell';
import { TableHeadCellProps } from './types';

interface Props<T extends {}> {
  head: TableHeadCellProps<T>[];
  actionsWidth?: string;
  withoutActions: boolean;
}

const BaseTableHead = <T extends {}>({
  head,
  actionsWidth,
  withoutActions,
}: Props<T>) => {
  return (
    <TableHead>
      <TableRow>
        {head.map((headCell) => (
          <BaseTableHeadCell key={`${headCell.id}`} {...headCell} />
        ))}
        {!withoutActions && (
          <TableCell
            style={{ width: actionsWidth, textAlign: 'right' }}
          ></TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};

export default BaseTableHead;
