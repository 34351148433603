import { useHookstate } from '@hookstate/core';
import { useState } from 'react';
import { adminState } from '../../../../adminState';
import { Flex } from '../../../../base/components/Flex';
import {
  OrderDirection,
  StreamType,
  UserFilterField,
  UserOrderByInput,
  UserOrderField,
  UserRole,
} from '../../../../graphql/graphql-operations';
import { useTranslationPrefix } from '../../../../hooks/useTranslationPrefix';
import useStreamType from '../../../../lib/useStreamType';
import OrderBy from '../../../components/OrderBy/OrderBy';
import { prepareUserSortOptions } from '../../Users/components/prepareUserSortOptions';
import UserGroupsFilter from '../../Users/components/UserGroupsFilter';
import UserList from '../../Users/components/UserList';

interface Props {
  query: string;
  setSelectedUserIds: React.Dispatch<React.SetStateAction<string[]>>;
  selectedUserIds: string[];
}

const PlaylistAssignUsersTab = ({
  query,
  setSelectedUserIds,
  selectedUserIds,
}: Props) => {
  const streamType = useStreamType();
  const { _t } = useTranslationPrefix('PlaylistTemplateAssignUsersPage');
  const companyId = useHookstate(adminState.selectedCompanyId);

  const [selectedUserGroupIds, setSelectedUserGroupIds] = useState<string[]>(
    [],
  );
  const [orderBy, setOrderBy] = useState<UserOrderByInput>({
    direction: OrderDirection.ASC,
    field: UserOrderField.LAST_NAME,
  });

  const [selectedRoles, setSelectedRoles] = useState<UserRole[]>(
    streamType === StreamType.MEDICAL ? [UserRole.NONE] : [],
  );

  const handleSelection = ({ id }: { id: string }) => {
    setSelectedUserIds((s) =>
      s.includes(id) ? s.filter((sId) => sId !== id) : [...s, id],
    );
  };

  return (
    <>
      <Flex justifyContent="space-between" alignItems="end" sx={{ mb: 2 }}>
        <UserGroupsFilter
          companyId={companyId.get()}
          selectedGroupIds={selectedUserGroupIds}
          setSelectedGroupIds={setSelectedUserGroupIds}
          selectedRoles={selectedRoles}
          setSelectedRoles={setSelectedRoles}
          selectableRoles={
            streamType === StreamType.MEDICAL ? [UserRole.NONE] : []
          }
        />
        <OrderBy<UserOrderField>
          id="patients-sort"
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          options={prepareUserSortOptions(_t)}
        />
      </Flex>
      <UserList
        companyId={companyId.get()}
        userGroupIds={selectedUserGroupIds}
        userRoles={selectedRoles}
        searchConfig={{
          filterBy: UserFilterField.LAST_NAME,
          filterValue: query,
        }}
        onClick={handleSelection}
        selectedUserIds={selectedUserIds}
        orderBy={orderBy}
      />
    </>
  );
};

export default PlaylistAssignUsersTab;
