import { useQuery } from '@apollo/client';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  MenuItem,
  styled,
} from '@mui/material';
import { FormikContextType } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { adminState } from '../../../../adminState';
import { FormikFormProvider } from '../../../../base/components/form/FormikForm';
import FormikSelect from '../../../../base/components/form/FormikSelect';
import FormikTextField from '../../../../base/components/form/FormikTextField';
import {
  GetUserDiagnosesDocument,
  LoginType,
  UserFragmentFragment,
  UserRole,
} from '../../../../graphql/graphql-operations';
import useQueryErrorSnackbar from '../../../../hooks/useQueryErrorSnackbar';
import { useTranslationPrefix } from '../../../../hooks/useTranslationPrefix';
import { compareByProp } from '../../../../lib/compare';
import { PHONE_PREFIXES } from '../../../../lib/constants';
import { useUserAvailableGroups } from '../hooks';

export const UNSELECTED_LOGIN_TYPE = 'UNSELECTED';

const NON_REQUIRED_FIELDS_FOR_ROLES = [
  UserRole[UserRole.TRAINING_STUDENT_BASIC],
];

interface Props {
  user?: UserFragmentFragment;
  availableRoles: UserRole[];
  formik: FormikContextType<UserFormData>;
}

export type UserFormData = {
  firstName: string;
  lastName: string;
  externalId: string;
  phonePrefix: string | null;
  phoneNumber: string | null;
  role: UserRole | string;
  diagnosis: string | null;
  groups: string[] | null;
  loginType: LoginType | 'UNSELECTED' | null;
  email: string | null;
  username: string | null;
  password: string | null;
};

const UserDetailForm = ({ availableRoles, formik, user }: Props) => {
  const { _t, t } = useTranslationPrefix('UserDetailForm');

  const companyId = adminState.selectedCompanyId.get();
  const { groups } = useUserAvailableGroups(companyId);
  const { data, error, refetch } = useQuery(GetUserDiagnosesDocument);
  const [isReqiuredForRole, setIsReqiuredForRole] = useState(true);

  useQueryErrorSnackbar(
    error,
    refetch,
    _t('failedToLoadDiagnoses', 'Failed to load diagnoses'),
  );

  const diagnoses = useMemo(() => {
    let result = data?.userDiagnoses || [];
    result = [...result].sort(compareByProp('name'));
    return result;
  }, [data?.userDiagnoses]);

  const role = formik.values.role;
  const loginType = formik.values.loginType;

  useEffect(() => {
    setIsReqiuredForRole(
      NON_REQUIRED_FIELDS_FOR_ROLES.includes(role as UserRole),
    );
  }, [role]);

  const handleRemoveGroup = (value: string) => {
    if ('groups' in formik.values) {
      formik.setFieldValue(
        'groups',
        formik.values.groups?.filter((group) => group !== value) || [],
      );
    }
  };

  const handleEmailBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (!formik.values.username) {
      formik.setFieldValue('username', value);
    }
  };

  return (
    <FormikFormProvider formik={formik}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div></div>
          <FormikTextField
            name="firstName"
            variant="outlined"
            label={_t('firstname', 'Firstname')}
            fullWidth
            required={!isReqiuredForRole}
          />
        </Grid>
        <Grid item xs={12}>
          <FormikTextField
            name="lastName"
            variant="outlined"
            label={_t('lastName', 'Lastname')}
            fullWidth
            required={!isReqiuredForRole}
          />
        </Grid>
        <Grid item xs={12}>
          <FormikTextField
            name="externalId"
            variant="outlined"
            label={_t('externalId', 'ID')}
            fullWidth
            required={isReqiuredForRole}
          />
        </Grid>
        {role === UserRole.NONE && (
          <Grid item xs={12}>
            <FormikTextField
              name="diagnosis"
              variant="outlined"
              label={_t('diagnosis', 'Diagnosis')}
              fullWidth
              select
            >
              {diagnoses.map((diagnosis) => (
                <MenuItem key={diagnosis.id} value={diagnosis.id}>
                  {diagnosis.name}
                </MenuItem>
              ))}
            </FormikTextField>
          </Grid>
        )}
        <Grid item xs={12}>
          <FormikSelect
            name="role"
            variant="outlined"
            label={_t('roleLabel', 'Role')}
            fullWidth
            required
          >
            {availableRoles.map((role) => (
              <MenuItem key={role} value={role}>
                {t(`enum.UserRole.${role}`)}
              </MenuItem>
            ))}
          </FormikSelect>
        </Grid>
        {role !== UserRole.NONE && (
          <>
            {!isReqiuredForRole && (
              <>
                <Grid item xs={12}>
                  <FormikTextField
                    name="email"
                    type="email"
                    variant="outlined"
                    label={_t('emailLabel', 'E-mail')}
                    onBlur={handleEmailBlur}
                    fullWidth
                    required={
                      !(
                        formik.values.password ||
                        user?.hasPassword ||
                        formik.values.loginType === LoginType.PHONE
                      ) && !isReqiuredForRole
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormikSelect
                    name="phonePrefix"
                    variant="outlined"
                    label={_t('phonePrefixLabel', 'Prefix')}
                    fullWidth
                    required={
                      formik.values.loginType !== LoginType.USERNAME &&
                      !isReqiuredForRole
                    }
                  >
                    {PHONE_PREFIXES.map((prefix) => (
                      <MenuItem key={prefix} value={prefix}>
                        {prefix}
                      </MenuItem>
                    ))}
                  </FormikSelect>
                </Grid>
                <Grid item xs={9}>
                  <FormikTextField
                    sx={{ flex: 1 }}
                    name="phoneNumber"
                    variant="outlined"
                    label={_t('phoneNumberLabel', 'Phone number')}
                    fullWidth
                    required={
                      formik.values.loginType !== LoginType.USERNAME &&
                      !isReqiuredForRole
                    }
                  />
                </Grid>
              </>
            )}
            {groups?.length || formik.values.groups?.length ? (
              <Grid item xs={12}>
                <FormikSelect
                  name="groups"
                  variant="outlined"
                  multiple
                  label={_t('groupsLabel', 'Groups')}
                  sx={{ minHeight: '56px' }}
                  inputProps={{ sx: { py: '12px' } }}
                  fullWidth
                  MenuProps={{
                    anchorOrigin: {
                      horizontal: 'center',
                      vertical: 'top',
                    },
                    transformOrigin: {
                      vertical: 'bottom',
                      horizontal: 'center',
                    },
                  }}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {(selected as UserRole[]).map((value) => (
                        <StyledChip
                          key={value}
                          label={
                            groups?.find((g) => g.id === value)?.name || value
                          }
                          onDelete={() => handleRemoveGroup(value)}
                          variant="filled"
                          size="small"
                          deleteIcon={
                            <CancelOutlinedIcon
                              onMouseDown={(event) => event.stopPropagation()}
                            />
                          }
                        />
                      ))}
                    </Box>
                  )}
                >
                  {groups?.map((group) => (
                    <MenuItem key={group.id} value={group.id}>
                      {group.name}
                    </MenuItem>
                  ))}
                </FormikSelect>
              </Grid>
            ) : null}
            {!isReqiuredForRole && (
              <>
                <Grid item xs={12}>
                  <Box gridColumn={{ md: 'span 2' }}>
                    <Divider />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <FormikTextField
                    sx={{ flex: 1 }}
                    name="loginType"
                    variant="outlined"
                    label={_t('loginType', 'Login by')}
                    select
                    fullWidth
                    required={!isReqiuredForRole}
                  >
                    <MenuItem value={UNSELECTED_LOGIN_TYPE}>
                      {_t('form.loginByAny', 'Any')}
                    </MenuItem>
                    <MenuItem value={LoginType.PHONE}>
                      {t(`enum.LoginType.${LoginType.PHONE}`, 'Phone (sms)')}
                    </MenuItem>
                    <MenuItem value={LoginType.USERNAME}>
                      {t(
                        `enum.LoginType.${LoginType.USERNAME}`,
                        'Username and password',
                      )}
                    </MenuItem>
                  </FormikTextField>
                </Grid>
                {loginType !== LoginType.PHONE && (
                  <>
                    <Grid item xs={12}>
                      <FormikTextField
                        sx={{ flex: 1 }}
                        name="username"
                        variant="outlined"
                        label={_t('username', 'Username')}
                        fullWidth
                        required={!isReqiuredForRole}
                      />
                    </Grid>
                    {!user && (
                      <Grid item xs={12}>
                        <FormikTextField
                          sx={{ flex: 1 }}
                          name="password"
                          variant="outlined"
                          label={_t('password', 'Password')}
                          fullWidth
                          required={!formik.values.email && !isReqiuredForRole}
                          autoComplete="new-password"
                        />
                      </Grid>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
        <Grid item xs={12} sx={{ textAlign: 'center', mt: 2 }}>
          <Button
            id="edit-user"
            variant="contained"
            type="submit"
            color="primary"
            disabled={!!user?.id && !formik.dirty}
          >
            {_t('saveChanges', 'Save changes')}
          </Button>
        </Grid>
      </Grid>
    </FormikFormProvider>
  );
};

export default UserDetailForm;

const StyledChip = styled(Chip)(
  ({ theme: { palette } }) => `
    height: 2rem;
    background-color: ${palette.primary.main};
    color: ${palette.white.main};
    svg {
        color: ${palette.white.main} !important;
    }
`,
);
