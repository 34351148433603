import { useQuery } from '@apollo/client';
import BackHandIcon from '@mui/icons-material/BackHand';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { useCallback } from 'react';
import flexImg from '../../../assets/arm_flex.png';
import { Flex } from '../../../base/components/Flex';
import {
  GetUserArmsFlexReportDocument,
  GetUserArmsFlexReportSeriesDocument,
  SeriesAggregationInterval,
} from '../../../graphql/graphql-operations';
import useQueryErrorSnackbar from '../../../hooks/useQueryErrorSnackbar';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import { getEndOfDay } from '../../../lib/date';
import { UpdateAxisPointerEvent } from '../../components/Charts/ChartTypes';
import ContentWrapper from '../../components/ContentWrapper';
import { Loader } from '../../components/Loader';
import PageHeader from '../../components/PageHeader';
import { UserReportPage } from './UserReportsRoute';
import ArmFlexChart from './components/ArmFlexChart';
import ReportNav from './components/ReportNav';
import ReportValue from './components/ReportValue';
import useDebouncedQuery from './utils/useDeboucedQuery';
import { useReportUser } from './utils/useReportUser';

const UserArmsFlexReportPage = () => {
  const { _t } = useTranslationPrefix('UserArmsFlexReportPage');
  const { userId, userData, userLoading, backUrl } = useReportUser();

  const { data: reportData, refetch: reportRefetch } = useDebouncedQuery(
    GetUserArmsFlexReportDocument,
    {
      variables: {
        userId: userId!,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      skip: !userId,
    },
  );

  const {
    data: seriesData,
    loading: seriesLoading,
    error: seriesError,
    refetch: seriesRefetch,
  } = useQuery(GetUserArmsFlexReportSeriesDocument, {
    variables: {
      userId: userId!,
      seriesInterval: SeriesAggregationInterval.day,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    skip: !userId,
  });
  useQueryErrorSnackbar(seriesError, seriesRefetch);

  const updateDate = useCallback(
    (e: UpdateAxisPointerEvent) => {
      const value = e.axesInfo.find((i) => i.axisDim === 'x')?.value;
      if (value) {
        reportRefetch({
          toDate: getEndOfDay(value).toISOString(),
        });
      }
    },
    [reportRefetch],
  );

  const loading = userLoading || !reportData || seriesLoading;
  return (
    <ContentWrapper>
      <PageHeader
        title={_t('title', 'Report {{firstName}} {{lastName}}', {
          firstName: userData?.user.firstName,
          lastName: userData?.user.lastName,
        })}
        back={backUrl}
      ></PageHeader>
      <ReportNav currentPage={UserReportPage.SHOULDERS_FLEX} />
      {loading ? (
        <Loader />
      ) : (
        <>
          <Flex flexDirection="column" gap={2}>
            <Card>
              <CardContent>
                <Flex alignItems="center">
                  <img height={100} width={100} alt="range" src={flexImg} />
                  <Box flexDirection="column" alignItems="flex-start" ml={2}>
                    <Typography variant="h6" component="h2">
                      {_t(
                        'armFlexMotion',
                        'Rozsah pohybu flexe ramenního kloubu',
                      )}
                    </Typography>
                  </Box>
                </Flex>
              </CardContent>
            </Card>
            <Flex
              sx={{
                gap: 2,
                flexGrow: 2,
              }}
            >
              <ReportValue
                label={_t(
                  'increaseRangeOfMotionBy',
                  'Zvýšení rozsahu pohybu o',
                )}
                secondLabel={_t('maxAchieved', 'Dosažené maximum')}
                value={
                  reportData?.userReports.armsFlexChange?.leftPercent
                    ? Math.round(
                        reportData?.userReports.armsFlexChange?.leftPercent,
                      ) + '%'
                    : 0
                }
                secondValue={
                  reportData?.userReports.armsFlexChange?.leftChange
                    ? Math.floor(
                        reportData?.userReports.armsFlexChange?.leftChange,
                      ) + '°'
                    : '0'
                }
                icon={<BackHandIcon fontSize="large" />}
                title={_t('leftHand', 'Levá ruka')}
              />

              <ReportValue
                label={_t(
                  'increaseRangeOfMotionBy',
                  'Zvýšení rozsahu pohybu o',
                )}
                secondLabel={_t('maxAchieved', 'Dosažené maximum')}
                value={
                  reportData?.userReports.armsFlexChange?.rightPercent
                    ? Math.round(
                        reportData?.userReports.armsFlexChange?.rightPercent,
                      ) + '%'
                    : 0
                }
                secondValue={
                  reportData?.userReports.armsFlexChange?.rightChange
                    ? Math.floor(
                        reportData?.userReports.armsFlexChange?.rightChange,
                      ) + '°'
                    : '0'
                }
                icon={
                  <BackHandIcon
                    fontSize="large"
                    sx={{ transform: 'scaleX(-1)' }}
                  />
                }
                title={_t('rightHand', 'Pravá ruka')}
              />
            </Flex>
            <Card>
              <CardContent>
                <ArmFlexChart
                  data={seriesData!.userReports.armsFlexSeries}
                  onAxisPointerChange={updateDate}
                />
              </CardContent>
            </Card>
          </Flex>
        </>
      )}
    </ContentWrapper>
  );
};

export default UserArmsFlexReportPage;
