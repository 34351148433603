import { useQuery } from '@apollo/client';
import { useHookstate } from '@hookstate/core';
import { TabsProps } from '@mui/material';
import { useMemo } from 'react';
import { adminState } from '../../../../adminState';
import BaseTabs from '../../../../base/components/BaseTabs/BaseTabs';
import {
  GetUsersDocument,
  UserRole,
  UserStatus,
} from '../../../../graphql/graphql-operations';
import { useTranslationPrefix } from '../../../../hooks/useTranslationPrefix';
import { TrainingPastDueFilter } from './UserList';

interface Filter {
  patients?: boolean;
  userStatus?: UserStatus;
}

interface Props extends Filter {
  pastDue: TrainingPastDueFilter;
  onChange: (pastDue: TrainingPastDueFilter) => void;
  sx?: TabsProps['sx'];
}

const UserTrainingPastDueFilter = ({
  pastDue,
  onChange,
  sx,
  ...filter
}: Props) => {
  const { _t } = useTranslationPrefix('UserTrainingPastDueFilter');
  const { allCount, completedcount, pastDueCount } =
    useUserTrainingFilterCount(filter);

  return (
    <BaseTabs
      value={pastDue}
      onChange={(e, val) => onChange(val)}
      sx={sx}
      tabs={[
        {
          label: _t('all', 'All'),
          value: TrainingPastDueFilter.ALL,
          count: allCount,
        },
        {
          label: _t('completed', 'Completed'),
          value: TrainingPastDueFilter.COMPLETED,
          count: completedcount,
        },
        {
          label: _t('pastDue', 'Past due'),
          value: TrainingPastDueFilter.PAST_DUE,
          count: pastDueCount,
        },
      ]}
    />
  );
};

export default UserTrainingPastDueFilter;

const useUserTrainingFilterCount = ({
  patients,
  userStatus = UserStatus.ACTIVE,
}: Filter) => {
  const companyId = useHookstate(adminState.selectedCompanyId).get();
  const queryVariables = useMemo(
    () => ({
      first: 0, //do not load them, interested just in total count
      filter: {
        companyId: companyId,
        filter: {
          role: patients
            ? [UserRole.NONE]
            : Object.values(UserRole).filter((role) => role !== UserRole.NONE),
          status: userStatus,
        },
      },
    }),
    [patients, companyId, userStatus],
  );

  const { data: allData } = useQuery(GetUsersDocument, {
    variables: queryVariables,
    fetchPolicy: 'no-cache',
  });

  const { data: completedData } = useQuery(GetUsersDocument, {
    variables: {
      ...queryVariables,
      filter: {
        ...queryVariables.filter,
        filter: { ...queryVariables.filter.filter, trainingPastDue: false },
      },
    },
    fetchPolicy: 'no-cache',
  });

  const { data: pastDueData } = useQuery(GetUsersDocument, {
    variables: {
      ...queryVariables,
      filter: {
        ...queryVariables.filter,
        filter: { ...queryVariables.filter.filter, trainingPastDue: true },
      },
    },
    fetchPolicy: 'no-cache',
  });

  return {
    allCount: allData?.users.totalCount || 0,
    completedcount: completedData?.users.totalCount || 0,
    pastDueCount: pastDueData?.users.totalCount || 0,
  };
};
