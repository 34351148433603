import Cookies from 'js-cookie';
import { HttpError } from '../lib/errors';
import {
  Credentials,
  LoginCompany,
  LoginPhoneFormRequest,
  LoginPhoneFormResponse,
  LoginVrBox,
  PasswordResetRequest,
  PasswordResetResponse,
  RequestPasswordResetRequest,
  VerifyLoginSmsRequest,
  VerifyLoginSmsResponse,
} from './types';

const COOKIE_KEY = 'XSRF-TOKEN';

const authFetch = (
  input: RequestInfo,
  { headers, ...rest }: RequestInit = {},
) => {
  const csrfToken = Cookies.get(COOKIE_KEY);
  return fetch(input, {
    credentials: 'same-origin',
    method: 'GET',
    headers: csrfToken
      ? {
          [COOKIE_KEY]: csrfToken,
          ...headers,
        }
      : headers,
    ...rest,
  });
};

export async function getLoginCompanies(): Promise<LoginCompany[]> {
  const res = await authFetch('/api/auth/companies', {
    method: 'GET',
  });
  const body = await res.json().catch(() => null);
  if (!res.ok) {
    throw new HttpError(res.status, body);
  }
  return body;
}

export async function login(credentials: Credentials) {
  const res = await authFetch('/api/auth/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(credentials),
  });
  const body = await res.json().catch(() => null);
  if (res.status === 401) {
    return { result: false, errorMessage: body.message };
  }
  if (!res.ok) {
    throw new HttpError(res.status, body);
  }
  return { result: true, errorMessage: null };
}

export async function loginVrSession(vrSessionId: string) {
  const res = await authFetch('/api/auth/login-vr-session', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ vrSessionId }),
  });
  const body = await res.json().catch(() => null);
  if (!res.ok) {
    throw new HttpError(res.status, body);
  }
  return { result: true, errorMessage: null };
}

export async function sendLoginSms(
  phoneData: LoginPhoneFormRequest,
): Promise<LoginPhoneFormResponse> {
  const res = await authFetch('/api/auth/send-login-sms', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(phoneData),
  });
  const body = await res.json().catch(() => null);
  if (!res.ok && body.errors) {
    return body;
  }
  if (!res.ok) {
    throw new HttpError(res.status, body);
  }
  return body;
}

export async function verifyLoginSms(
  verifyRequest: VerifyLoginSmsRequest,
): Promise<VerifyLoginSmsResponse | null> {
  const res = await authFetch('/api/auth/verify-login-sms', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(verifyRequest),
  });
  const body = await res.json().catch(() => null);
  if (!res.ok && body.errors) {
    return body;
  }
  if (!res.ok) {
    throw new HttpError(res.status, body);
  }
  return body;
}

export const checkLoginStatus = async () => {
  try {
    const res = await authFetch(
      `/api/auth/check-login?invalidate=${Date.now()}`,
      {
        method: 'GET',
      },
    );
    if (!res.ok) {
      return false;
    }
    const body = await res.text();
    return Boolean(body);
  } catch (err) {
    return false;
  }
};

export const logout = async () => {
  const res = await authFetch('/api/auth/logout', {
    method: 'DELETE',
  });
  if (!res.ok) {
    throw new HttpError(res.status);
  }
};

export async function requestPasswordReset(
  request: RequestPasswordResetRequest,
) {
  const res = await authFetch('/api/auth/request-password-reset', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(request),
  });

  if (!res.ok) {
    throw new HttpError(res.status, res);
  }
}

export async function passwordReset(
  request: PasswordResetRequest,
): Promise<PasswordResetResponse> {
  const res = await authFetch('/api/auth/password-reset', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(request),
  });
  const body: PasswordResetResponse = await res.json().catch(() => null);

  if (!res.ok && body.errors) {
    return body;
  }

  if (!res.ok) {
    throw new HttpError(res.status, res);
  }
  return {};
}

export const getLoginVrBox = async (vrBoxId: string | number) => {
  try {
    const res = await authFetch(`/api/auth/vr-box/${vrBoxId}`, {
      method: 'GET',
    });
    if (!res.ok) {
      return null;
    }
    const body: LoginVrBox = await res.json().catch(() => null);
    return body;
  } catch (err) {
    return null;
  }
};
