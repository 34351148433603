import { useTheme } from '@mui/material';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import { BaseTypography } from '../BaseTypography';
import { Flex } from '../Flex';

const enviroment = process.env.REACT_APP_ENVIRONMENT;

const envConfig: Record<string, EnvConfig> = {
  staging: {
    color: 'error',
    name: 'staging',
  },
  'pre-prod': {
    color: 'warning',
    name: 'preprod',
  },
  hotfix: {
    color: 'info',
    name: 'demo',
  },
};

interface EnvConfig {
  color: 'primary' | 'secondary' | 'error' | 'warning' | 'info' | 'success';
  name: string;
}

const enviromentConfig = enviroment ? envConfig[enviroment] : undefined;
export const ENV_WARNING_BAR_HEIGHT = enviromentConfig ? '2rem' : '0px';

const EnviromentWarningBar = () => {
  const { _t } = useTranslationPrefix('EnviromentWarningBar');
  const { palette } = useTheme();
  const config = enviroment && envConfig[enviroment];

  if (!config) {
    return null;
  }

  const color = palette[config.color];

  return config ? (
    <Flex
      sx={{
        backgroundColor: color.main,
        color: color.contrastText,
        position: 'relative',
        height: ENV_WARNING_BAR_HEIGHT,
        alignItems: 'center',
      }}
    >
      <BaseTypography
        variant="body3"
        fontWeight={500}
        marginLeft={{ xs: 4, sm: 8 }}
      >
        {_t(config.name)}
      </BaseTypography>
    </Flex>
  ) : null;
};

export default EnviromentWarningBar;
