import { useState } from '@hookstate/core';
import CloseIcon from '@mui/icons-material/Close';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import ScreenMirrorWorkaround from './ScreenMirrorWorkaround';

interface Props {
  deviceId: string;
  open: boolean;
  onClose: () => void;
}

const ScreenViewWorkaround: React.VFC<Props> = ({
  deviceId,
  open,
  onClose,
}: Props) => {
  const state = useState({ fullscreen: false });
  const handleClose = () => {
    state.fullscreen.set(false);
    onClose();
  };
  return (
    <Root>
      {open && (
        <div
          className={clsx({
            [classes.screenView]: true,
            [classes.screenViewSmall]: !state.fullscreen.get(),
            [classes.screenViewFull]: state.fullscreen.get(),
          })}
        >
          <div className={classes.screenViewControlls}>
            <IconButton
              id="screen-view-fullscreen"
              onClick={() => state.fullscreen.set((prev) => !prev)}
              size="large"
            >
              {state.fullscreen.get() ? (
                <FullscreenExitIcon className={classes.screenViewIcon} />
              ) : (
                <FullscreenIcon className={classes.screenViewIcon} />
              )}
            </IconButton>
            <IconButton id="webrtc-close" onClick={handleClose} size="large">
              <CloseIcon className={classes.screenViewIcon} />
            </IconButton>
          </div>
          <ScreenMirrorWorkaround deviceId={deviceId} />
        </div>
      )}
    </Root>
  );
};

export default ScreenViewWorkaround;

const PREFIX = 'ScreenView';

const classes = {
  screenView: `${PREFIX}-screenView`,
  screenViewSmall: `${PREFIX}-screenViewSmall`,
  screenViewFull: `${PREFIX}-screenViewFull`,
  screenViewControlls: `${PREFIX}-screenViewControlls`,
  screenViewIcon: `${PREFIX}-screenViewIcon`,
};

const Root = styled('div')(() => {
  return {
    [`& .${classes.screenView}`]: {
      backgroundColor: '#000',
      position: 'fixed',
      zIndex: 5000,
    },
    [`& .${classes.screenViewSmall}`]: {
      height: '350px',
      minWidth: '475px',
      overflow: 'hidden',
      bottom: '16px',
      right: '16px',
      borderRadius: '10px',
      '& img': {
        height: '100%',
      },
    },
    [`& .${classes.screenViewFull}`]: {
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
      display: 'flex',
      '& img': {
        maxHeight: '100%',
        maxWidth: '100%',
        width: '100%',
        height: 'auto',
        alignSelf: 'center',
        objectFit: 'contain',
      },
    },
    [`& .${classes.screenViewControlls}`]: {
      position: 'absolute',
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      zIndex: 5500,
    },
    [`& .${classes.screenViewIcon}`]: {
      fill: '#FFF',
      width: '30px',
      height: '30px',
    },
  };
});
