import { Chip, styled, SvgIconProps } from '@mui/material';
import { Box } from '@mui/system';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { BaseTypography } from '../../../base/components/BaseTypography';
import { Flex } from '../../../base/components/Flex';
import BGIcon from '../../../base/components/Icons/BGIcon';
import CzIcon from '../../../base/components/Icons/CzIcon';
import DeIcon from '../../../base/components/Icons/DeIcon';
import GbIcon from '../../../base/components/Icons/GbIcon';
import LyingIcon from '../../../base/components/Icons/LyingIcon';
import ROIcon from '../../../base/components/Icons/ROIcon';
import SittingIcon from '../../../base/components/Icons/SittingIcon';
import StandingIcon from '../../../base/components/Icons/StandingIcon';
import UkIcon from '../../../base/components/Icons/UkIcon';
import { compareByOrdinalAndProp } from '../../../lib/compare';
import { ScrollableList } from '../../pages/Users/components/ScrollableList';
import { SceneParametrValue } from './PlaylistTypes';

const iconProps: SvgIconProps = {
  sx: {
    fontSize: '2.25rem',
    mr: 1,
    ml: -1.5,
  },
};

const valueIconMap: Record<string, Record<string, ReactNode>> = {
  lang: {
    'cs-CZ': <CzIcon {...iconProps} />,
    'en-US': <GbIcon {...iconProps} />,
    'de-DE': <DeIcon {...iconProps} />,
    'uk-UA': <UkIcon {...iconProps} />,
    'ro-RO': <ROIcon {...iconProps} />,
    'bg-BG': <BGIcon {...iconProps} />,
  },
  position: {
    sitting: <SittingIcon {...iconProps} />,
    standing: <StandingIcon {...iconProps} />,
    lying: <LyingIcon {...iconProps} />,
  },
};

interface Props {
  param: {
    id: string;
    name: string;
    name_t: string;
    values: SceneParametrValue[];
  };
  required?: boolean;
  selectedId?: string;
  enabledIds?: string[];
  onChange: (valueId?: string) => void;
}

const PlaylistLargeParam = ({
  param,
  required,
  selectedId,
  enabledIds,
  onChange,
}: Props) => {
  const isLang = 'lang' === param.name;

  const renderValue = (value: SceneParametrValue) => {
    const icon = valueIconMap[param.name]?.[value.name];

    const selected = selectedId === value.id;

    const handleClick = () => {
      if (!selected) {
        onChange(value.id);
      } else if (!required) {
        onChange();
      }
    };

    return (
      <PlaylistLargeParamChip
        id={`param-${param.name}-${value.name}`}
        disabled={!enabledIds?.includes(value.id)}
        key={value.id}
        variant={selected ? 'filled' : 'outlined'}
        style={{ minWidth: isLang ? '18ch' : undefined }}
        label={
          <Flex alignItems="center" justifyContent="center">
            {icon}
            <BaseTypography variant="body1">{value.name_t}</BaseTypography>
          </Flex>
        }
        onClick={handleClick}
        color="offwhite"
        className={clsx({ 'Mui-selected': selected })}
      />
    );
  };

  return (
    <Box>
      <BaseTypography variant="h4" color="text.secondary">
        {param.name_t}
        {required ? ' *' : ''}
      </BaseTypography>
      <ScrollableList
        id={`params-${param.name}`}
        leftButtonSx={{ transform: 'translateY(-40%)' }}
        rightButtonSx={{ transform: 'translateY(-40%)' }}
      >
        <Flex gap={2} pt={1} flexWrap={isLang ? 'wrap' : undefined}>
          {param.values
            .sort(compareByOrdinalAndProp('name_t'))
            .map(renderValue)}
        </Flex>
      </ScrollableList>
    </Box>
  );
};

export default PlaylistLargeParam;

const PlaylistLargeParamChip = styled(Chip)`
  padding: 0 0.5rem;
`;
