import { styled } from '@mui/material';
import { Box } from '@mui/system';

const ClientBackgroundGradient = styled(Box)(
  ({ theme: { palette } }) => `
  position: absolute;

  width: 100%;
  min-width: 1024px;
  aspect-ratio: 1024/1366;

  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  background: radial-gradient(
    110.11% 59.91% at 83.25% 50%,
    rgba(123, 79, 251, 0) 32.03%,
    rgba(123, 79, 251, 0.603) 100%
  );
  filter: blur(38px);
`,
);

export default ClientBackgroundGradient;
