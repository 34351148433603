import { styled, Typography, TypographyProps } from '@mui/material';
import { ResponsiveStyleValue } from '@mui/system';
import useResponsiveStyleValue from '../../../lib/useResponsiveStyleValue';

export interface BaseTypographyProps
  extends Omit<TypographyProps, 'variant'>,
    StyleProps {
  component?: React.ElementType;
  variant?: ResponsiveStyleValue<TypographyProps['variant']>;
}

const BaseTypography = ({
  children,
  variant,
  ...rest
}: BaseTypographyProps) => {
  const resolvedVariant = useResponsiveStyleValue(variant);
  return (
    <CustomTypography variant={resolvedVariant} {...rest}>
      {children}
    </CustomTypography>
  );
};

export default BaseTypography;

interface StyleProps {
  uppercase?: boolean;
}
const CustomTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'uppercase',
})<StyleProps>(({ uppercase }) => ({
  textTransform: uppercase ? 'uppercase' : undefined,
}));
