import { ApolloError } from '@apollo/client';
import humps from 'humps';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { VALIDATION_FIELD_ERROR } from '../lib/handleValidationFieldError';

const ignoredErrorsMessages = [VALIDATION_FIELD_ERROR];
interface MutationError {
  readonly code: string;
  readonly message: string;
}

// have to add __typename because ts weak type detection.
type MutationResult<F extends string> = Record<
  F,
  {
    readonly errors: ReadonlyArray<MutationError>;
    readonly __typename?: string;
  }
>;

interface Options<D> {
  interpolations?: Record<string, any>;
  onSuccessInterpolations?: (data: D) => Record<string, any>;
  onSuccessfullyCompleted?: (data: D) => void;
  errorCodesToSuppress?: string[];
  translationKey?: string;
}

/**
 * @deprecated Do not use on new places, reacts to errors in mutation body response.
 * use useMutationWithSnackbar from now on, it reacts to regular apollo errors and
 * LocalizedError from backend if translation is needed
 */
export default function useMutationSnackbar<
  D extends MutationResult<F>,
  F extends string,
>(field: F, options: Options<D> = {}) {
  const {
    errorCodesToSuppress,
    onSuccessfullyCompleted,
    translationKey,
    onSuccessInterpolations,
    interpolations,
  } = options;
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const key = translationKey || field;
  return {
    onCompleted(data: D) {
      const successInterpolations = onSuccessInterpolations
        ? onSuccessInterpolations(data)
        : interpolations;
      if (data[field] && typeof data[field] === 'object') {
        const { errors } = data[field];
        if (errors && errors.length) {
          let filteredErrors = [...errors];
          if (errorCodesToSuppress) {
            filteredErrors = filteredErrors.filter(
              (err) => !errorCodesToSuppress.includes(err.code),
            );
          }
          for (const error of filteredErrors) {
            enqueueSnackbar(
              t(
                // need humps because error.code might contain underscore chars which have special meaning in i18next
                `${key}.error.${humps.camelize(error.code.toLowerCase())}`,
                error.message,
                successInterpolations,
              ),
              { variant: 'error' },
            );
          }
        } else {
          enqueueSnackbar(t(`${key}.success`, successInterpolations), {
            variant: 'success',
          });
          onSuccessfullyCompleted && onSuccessfullyCompleted(data);
        }
      } else {
        enqueueSnackbar(t(`${key}.success`, successInterpolations), {
          variant: 'success',
        });
        onSuccessfullyCompleted && onSuccessfullyCompleted(data);
      }
    },
    onError(e: ApolloError) {
      if (ignoredErrorsMessages.includes(e.message)) {
        throw e;
      }
      enqueueSnackbar(t(`${key}.genericError`, interpolations), {
        variant: 'error',
      });
    },
  };
}
