import { Box, BoxProps } from '@mui/material';
import { BarChart, GaugeChart, LineChart, ScatterChart } from 'echarts/charts';
import {
  DataZoomComponent,
  DatasetComponent,
  GridComponent,
  LegendComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { useEffect, useLayoutEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { LegendSelectChangedEvent, UpdateAxisPointerEvent } from './ChartTypes';
import { echars_CS, echars_DE } from './echarts_locale';
import { EchartTheme } from './echarts_theme';
import useChartEvents from './useChartEvents';

echarts.use([
  DatasetComponent,
  BarChart,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition,
  DataZoomComponent,
  GaugeChart,
  ScatterChart,
]);
echarts.registerLocale('cs', echars_CS);
echarts.registerLocale('de', echars_DE);

export interface ChartProps<Options extends echarts.EChartsCoreOption>
  extends BoxProps {
  options: Options;
  setOptionsBehaviour?: echarts.SetOptionOpts;
  onAxisPointerChange?: (event: UpdateAxisPointerEvent) => any;
  onLegendSelectChange?: (event: LegendSelectChangedEvent) => any;
  echartsTheme?: object;
}

const Chart = <Options extends echarts.EChartsCoreOption>({
  options,
  setOptionsBehaviour,
  onAxisPointerChange,
  onLegendSelectChange,
  echartsTheme,
  ...boxProps
}: ChartProps<Options>) => {
  const { i18n } = useTranslation();
  const chartElement = useRef<HTMLDivElement | null>(null);
  const chart = useRef<echarts.ECharts | null>(null);

  useEffect(() => {
    if (chartElement.current) {
      const tmpChart = echarts.init(
        chartElement.current,
        echartsTheme ?? EchartTheme.theme,
        {
          locale: i18n.language,
        },
      );
      chart.current = tmpChart;
      return () => echarts.dispose(tmpChart);
    }
  }, [chartElement, i18n.language, echartsTheme]);

  useChartEvents({ chart, onAxisPointerChange, onLegendSelectChange });

  useEffect(() => {
    if (chart.current) {
      chart.current.setOption(options, setOptionsBehaviour);
      const updateSize = () => {
        chart.current?.resize();
      };
      window.addEventListener('resize', updateSize);
      return () => window.removeEventListener('resize', updateSize);
    }
  }, [chart, options, setOptionsBehaviour]);

  useLayoutEffect(() => {});
  return <Box {...boxProps} ref={chartElement}></Box>;
};

export default Chart;
