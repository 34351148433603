import { Box, styled } from '@mui/material';
import { CommonProps } from '@mui/material/OverridableComponent';
import { BaseTypography } from '../../../base/components/BaseTypography';
import { Flex } from '../../../base/components/Flex';

interface Props extends CommonProps {
  leftSideText: React.ReactNode;
  leftSideStatusText?: React.ReactNode;
  actions?: React.ReactNode;
}

const TopBar = ({
  leftSideText,
  leftSideStatusText,
  actions,
  ...rest
}: Props) => {
  return (
    <Content {...rest}>
      <BaseTypography
        color="textPrimary"
        variant="h4"
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
      >
        {leftSideText}
        {leftSideStatusText && (
          <LeftSideStatusText>{leftSideStatusText}</LeftSideStatusText>
        )}
      </BaseTypography>

      {actions && <Box flexShrink={0}>{actions}</Box>}
    </Content>
  );
};

export default TopBar;

const Content = styled(Flex)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'space-between',
  minHeight: theme.spacing(6),
  marginTop: theme.spacing(2.75),
  marginBottom: theme.spacing(5.5),
}));

const LeftSideStatusText = styled('span')(
  ({ theme }) => `
  font-size: 0.5em;
  text-transform: uppercase;
  margin-left: 1ch;
`,
);
