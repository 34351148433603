import {
  Box,
  Chip,
  FormControlProps,
  FormHelperTextProps,
  InputLabelProps,
  MenuItem,
  SelectProps,
  styled,
} from '@mui/material';
import FormikSelect from './FormikSelect';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

interface Props extends Omit<SelectProps, 'value' | 'error'> {
  name: string;
  formControl?: FormControlProps;
  formHelperText?: FormHelperTextProps;
  inputLabel?: InputLabelProps;
  options: { id: number | string; name: string }[];
  setFieldValue: (name: string, values: any[]) => void;
  formValues: Record<string, any>;
}

export const FormikSelectMultiple = ({
  options,
  formValues,
  ...props
}: Props) => {
  const handleDelete = (value: number | string) => {
    if (props.name in formValues) {
      props.setFieldValue(
        props.name,
        formValues[props.name]?.filter((option: any) => option !== value) || [],
      );
    }
  };
  return (
    <FormikSelect
      {...props}
      MenuProps={{
        sx: {
          maxHeight: 300,
          maxWidth: 300,
        },
        MenuListProps: {
          sx: {
            li: {
              minHeight: 'auto',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              display: 'block',
            },
          },
        },
      }}
      multiple
      renderValue={(selected) => (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {(selected as (string | number)[])?.map((value) => (
            <StyledChip
              key={value}
              label={options?.find((p) => p.id === value)?.name}
              onDelete={() => handleDelete(value)}
              variant="filled"
              size="small"
              deleteIcon={
                <CancelOutlinedIcon
                  onMouseDown={(event) => event.stopPropagation()}
                />
              }
            />
          ))}
        </Box>
      )}
    >
      {options?.map((option) => (
        <MenuItem key={option.id} value={option.id}>
          {option.name}
        </MenuItem>
      ))}
    </FormikSelect>
  );
};

const StyledChip = styled(Chip)(
  ({ theme: { palette } }) => `
      height: 2rem;
      background-color: ${palette.primary.main};
      color: ${palette.white.main};
      svg {
          color: ${palette.white.main} !important;
      }
  `,
);
