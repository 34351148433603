import ArrowBackIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { FC, useCallback, useState } from 'react';
import { BaseTypography } from '../../../base/components/BaseTypography';
import { Flex } from '../../../base/components/Flex';
import { IconButtonOutlined } from '../../../base/components/IconButton';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import { joinStrings } from '../../../lib/strings';

interface Props {
  id?: string;
  pagination: PaginationState;
  positioning?: 'absolute' | 'responsive';
}

interface PaginationState {
  currentPage: number;
  totalPages: number;
  hasNext: boolean;
  hasPrev: boolean;
  next: () => void;
  prev: () => void;
}

export const PaginationPlaceholder = () => {
  return <Box mt={11.5} mr={2} ml={2} width="50px" />;
};

export const Pagination: FC<Props> = ({
  id,
  pagination: { totalPages, currentPage, hasNext, hasPrev, next, prev },
  positioning,
}) => {
  const { _t } = useTranslationPrefix('pagination');
  return (
    <PaginationWrapper
      id={id}
      positioning={positioning}
      flexDirection="column"
      alignItems="center"
      px={2}
    >
      <IconButtonOutlined
        id={joinStrings('-', id, 'prev')}
        aria-label="prev-page"
        onClick={() => prev()}
        sx={{ mb: 1 }}
        disabled={!hasPrev}
        color="bgContrast"
      >
        <ArrowBackIcon />
      </IconButtonOutlined>

      <IconButtonOutlined
        id={joinStrings('-', id, 'next')}
        aria-label="next-page"
        onClick={() => next()}
        sx={{ mb: 1 }}
        disabled={!hasNext}
        color="bgContrast"
      >
        <ArrowForwardIcon />
      </IconButtonOutlined>

      <BaseTypography color="text.secondary">
        {_t('paginationState', '{{currentPage}} of {{totalPages}}', {
          currentPage,
          totalPages,
        })}
      </BaseTypography>
    </PaginationWrapper>
  );
};

const PaginationWrapper = styled(Flex)<{
  positioning?: 'absolute' | 'responsive';
}>(({ positioning, theme }) =>
  positioning === 'absolute'
    ? `
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateX(100%);
  `
    : positioning === 'responsive'
    ? `
    ${theme.breakpoints.up('md')} {
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translateX(100%);
    }
    ${theme.breakpoints.down('md')} {
      justify-content: center;
      flex-direction: row;
      margin-top: ${theme.spacing(2)};
      & :nth-child(2) {
        margin-left: ${theme.spacing(2)};
        order: 3;
      };
      & :nth-child(3) {
        margin-left: ${theme.spacing(2)};
      };
    }
  `
    : ``,
);

export const usePagination = ({
  pageSize,
  total,
}: {
  pageSize: number;
  total: number;
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  const saveCurrentPage = () => Math.max(1, currentPage);
  const hasNext = saveCurrentPage() * pageSize < total;
  const hasPrev = saveCurrentPage() !== 1;
  const totalPages = Math.max(1, Math.ceil(total / pageSize));
  const next = useCallback(() => {
    if (hasNext) {
      setCurrentPage((page) => page + 1);
    }
  }, [hasNext]);
  const prev = useCallback(() => {
    if (hasPrev) {
      setCurrentPage((page) => page - 1);
    }
  }, [hasPrev]);
  return {
    currentPage,
    totalPages,
    pageSize,
    hasNext,
    hasPrev,
    next,
    prev,
  };
};
