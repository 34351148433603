import { useState } from '@hookstate/core';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceState } from '../../../graphql/graphql-operations';
import { formatDuration } from '../../../lib/duration';
import DetailValue from '../../components/DetailCard/DetaiValue';
import DetailCard from '../../components/DetailCard/DetailCard';

interface Props {
  startedAt?: string;
  endedAt?: string | null;
  appName?: string;
  battery?: number;
  state?: DeviceState;
}

const VrSessionDetail: React.VFC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const { startedAt, endedAt, appName, battery, state } = props;
  const comState = useState({ now: new Date().getTime() });
  const duration = getDuration(startedAt, endedAt, comState.now.value);
  useEffect(() => {
    const interval = setInterval(() => {
      comState.now.set(new Date().getTime());
    }, 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DetailCard>
      <DetailValue
        value={formatDuration(duration)}
        label={t('vrSessionDetail.durationLabel', 'Current session')}
      />
      <DetailValue
        value={appName}
        label={t('vrSessionDetail.appLabel', 'Program')}
      />
      <DetailValue
        value={battery ? `${battery} %` : undefined}
        label={t('vrSessionDetail.batteryLabel', 'Battery')}
      />
      <DetailValue
        value={state ? t(`common.deviceState.${state}`) : undefined}
        label={t('vrSessionDetail.stateLabel', 'Status')}
      />
    </DetailCard>
  );
};

const getDuration = (
  startedAt?: string,
  endedAt?: string | null,
  fallbackCurrentTime?: number,
): number => {
  if (!startedAt) {
    return 0;
  }

  const startedAtDate = new Date(startedAt);
  const endedAtDate = endedAt ? new Date(endedAt) : null!;
  return (
    (endedAtDate ? endedAtDate.getTime() : fallbackCurrentTime!) -
    startedAtDate.getTime()
  );
};

export default VrSessionDetail;
