import { useQuery } from '@apollo/client';
import { format } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'react-use';
import { adminState } from '../../../adminState';
import {
  BaseRefetchTableVariables,
  BaseTable,
  getHeadCellsFromObject,
  getRowContentFromObject,
  RowContentConfig,
  TableCellLink,
  TableRowProps,
  TableSearchControlBar,
} from '../../../base/components/BaseTable';
import {
  GetUsersAdminDocument,
  GetUsersAdminQuery,
  GetUsersAdminQueryVariables,
  OrderDirection,
  UserFilterField,
  UserOrderField,
  UserRole,
  UserStatus,
} from '../../../graphql/graphql-operations';
import useQueryErrorSnackbar from '../../../hooks/useQueryErrorSnackbar';
import { DATE_TIME_FORMAT } from '../../../lib/constants';
import { formatDuration } from '../../../lib/duration';
import { joinStrings } from '../../../lib/strings';
import variableStore from '../../../variableStore';

export interface TableData {
  id: string;
  lastName: string;
  lastSession: Date | null;
  sessionCount: number;
  sessionsTotalLengthMs: number;
  vrSessionId?: string;
}

interface Props {
  patientStatus: UserStatus;
  rowActions?: (rowData: TableData) => React.ReactNode;
}

const PatientsTable: React.VFC<Props> = ({
  rowActions,
  patientStatus,
}: Props) => {
  const { t } = useTranslation();
  const [rowsPerPage] = useSessionStorage('rowsPerPage', 15);
  const { data, loading, error, refetch, variables } = useQuery(
    GetUsersAdminDocument,
    {
      fetchPolicy: 'cache-and-network',
      variables: getDefaultVariables(rowsPerPage, patientStatus),
    },
  );
  useQueryErrorSnackbar(error, refetch);
  const tableData: TableData[] = prepareTableData(data);

  const headConfig = [
    {
      id: 'id' as const,
      defaultTrans: 'No.',
    },
    {
      id: 'lastName' as const,
      defaultTrans: 'Name',
    },
    {
      id: 'lastSession' as const,
      defaultTrans: 'Last Session',
    },
    {
      id: 'sessionCount' as const,
      defaultTrans: 'Sessions',
    },
    {
      id: 'sessionsTotalLengthMs' as const,
      defaultTrans: 'All time spent',
    },
  ];

  const tableHead = getHeadCellsFromObject(
    headConfig,
    'patients.table',
    t,
    UserOrderField,
  );

  const rowsConfig: RowContentConfig<TableData>[] = [
    {
      id: 'id' as const,
      getLabel: (id: string) => `#${id}`,
    },
    {
      id: 'lastName' as const,
      component: ({ lastName, id }) => (
        <TableCellLink to={`/admin/patients/${id}`} label={lastName} />
      ),
    },
    {
      id: 'lastSession' as const,
      getLabel: (lastSessionVal: Date | null) =>
        lastSessionVal ? format(lastSessionVal, DATE_TIME_FORMAT) : '',
    },
    {
      id: 'sessionCount' as const,
    },
    {
      id: 'sessionsTotalLengthMs' as const,
      getLabel: (sessionsTotalLengthMs: number) =>
        sessionsTotalLengthMs ? formatDuration(sessionsTotalLengthMs) : '',
    },
  ];

  const tableRows: TableRowProps<TableData>[] = tableData.map((data) => ({
    id: data.id,
    rowContent: getRowContentFromObject(data, rowsConfig),
    rowActions: rowActions ? rowActions(data) : undefined,
  }));

  return (
    <BaseTable<TableData, BaseRefetchTableVariables>
      id="patients-table"
      isLoading={loading}
      rows={tableRows}
      head={tableHead}
      refetch={refetch}
      variables={variables}
      pageCursors={data?.users.pageCursors}
      totalCount={data?.users.totalCount}
      controlBar={
        <TableSearchControlBar
          filterBy={UserFilterField.LAST_NAME}
          title={t(`patients.table.title`, 'Patient list')}
          placeholderTransKey={`patients.search.placeholder`}
        />
      }
    />
  );
};

const getDefaultVariables = (
  rowsPerPage: number,
  status: UserStatus,
): GetUsersAdminQueryVariables => {
  const companyId = adminState.selectedCompanyId.get();
  const defaultVariables = {
    first: rowsPerPage,
    orderBy: {
      direction: OrderDirection.ASC,
      field: UserOrderField.LAST_NAME,
    },
    filter: {
      companyId,
      filter: { role: [UserRole.NONE], status },
    },
  };
  variableStore.setUsersVariables(defaultVariables);
  return defaultVariables;
};

const prepareTableData = (data?: GetUsersAdminQuery): TableData[] => {
  const userNodes = data?.users.nodes || [];
  return userNodes.map((user) => {
    const lastSession = user.sessionCumulativeStatistic?.lastSession
      ? new Date(user.sessionCumulativeStatistic.lastSession)
      : null;
    return {
      id: user.id,
      lastName: joinStrings(' ', user.firstName, user.lastName),
      lastSession: lastSession,
      sessionCount: user.sessionCumulativeStatistic?.numberOfSessions || 0,
      sessionsTotalLengthMs: user.sessionCumulativeStatistic?.allTimeSpent || 0,
      vrSessionId: user.activeSession?.id,
    };
  });
};

export default PatientsTable;
