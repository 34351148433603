import { getOperationName } from '@apollo/client/utilities';
import { useState } from '@hookstate/core';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, DialogProps } from '@mui/material';
import { Formik } from 'formik';
import { equals } from 'ramda';
import { TFunction, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { BaseDialog } from '../../../../base/components/BaseDialog';
import ConfirmDialog from '../../../../base/components/ConfirmDialog/ConfirmDialog';
import { Flex } from '../../../../base/components/Flex';
import FormikCheckBox from '../../../../base/components/form/FormikCheckBox';
import FormikFileSelect from '../../../../base/components/form/FormikFileSelect';
import FormikForm from '../../../../base/components/form/FormikForm';
import FormikTextField from '../../../../base/components/form/FormikTextField';
import {
  CertificateTemplateFragmentFragment,
  CreateCertificateTemplateDocument,
  DeleteCertificateTemplateDocument,
  EditCertificateTemplateDocument,
  GetPlaylistTemplateDocument,
} from '../../../../graphql/graphql-operations';
import { useMutationWithSnackbar } from '../../../../hooks/useMutationWithSnackbar';
import { MAX_UPLOAD_FILE_SIZE } from '../../../../lib/constants';

export const SUPPORTED_FORMATS = ['application/json'];

interface CertificateTemplateForm {
  name: string;
  sendCcTo: string;
  sendToTrainees: boolean;
  attachment: File | null;
}

interface Props extends DialogProps {
  onClose: () => void;
  playlistId: string;
  certificateTemplate?: CertificateTemplateFragmentFragment | null;
}

const UploadCertificateTemplateDialog = ({
  open,
  onClose,
  playlistId,
  certificateTemplate,
  ...rest
}: Props) => {
  const deleteConfirmOpen = useState(false);
  const toggleDeleteConfirmDialog = () =>
    deleteConfirmOpen.set((prev) => !prev);
  const initialValues: CertificateTemplateForm = {
    name: certificateTemplate?.name ?? '',
    sendCcTo: certificateTemplate?.sendCcTo ?? '',
    sendToTrainees: certificateTemplate?.sendToTrainees ?? false,
    attachment: null,
  };
  const isEditVariant = !!certificateTemplate?.id;
  const keyPart = isEditVariant ? 'edit' : 'create';
  const { t } = useTranslation();

  const [createCertificateTemplate] = useMutationWithSnackbar(
    'certificateDialog.create',
    CreateCertificateTemplateDocument,
    {
      onCompleted: onClose,
    },
  );
  const [editCertificateTemplate] = useMutationWithSnackbar(
    'certificateDialog.edit',
    EditCertificateTemplateDocument,
    {
      onCompleted: onClose,
    },
  );
  const [deleteCertificateTemplate, { loading }] = useMutationWithSnackbar(
    'certificateDialog.delete',
    DeleteCertificateTemplateDocument,
    {
      update: (cache) =>
        cache.modify({
          fields: {
            certificateTemplates(certificateTemplateRefs: [], { readField }) {
              onClose();
              return certificateTemplateRefs.filter(
                (certificateTemplateRef) =>
                  readField('id', certificateTemplateRef) !==
                  certificateTemplate?.id,
              );
            },
          },
        }),
    },
  );

  const handleSubmit = (input: CertificateTemplateForm) => {
    const { attachment, ...restInput } = input;
    if (certificateTemplate?.id) {
      return editCertificateTemplate({
        variables: {
          file: input.attachment || undefined,
          input: {
            certificateId: certificateTemplate.id,
            playlistId: playlistId,
            ...restInput,
          },
        },
      });
    }
    return createCertificateTemplate({
      variables: {
        file: input.attachment,
        input: { playlistId: playlistId, ...restInput },
      },
      refetchQueries: [getOperationName(GetPlaylistTemplateDocument)!],
    });
  };

  const handleDeleteCertificateTemplate = () =>
    certificateTemplate?.id &&
    deleteCertificateTemplate({
      variables: { input: { certificateId: certificateTemplate.id } },
      onCompleted: () => {
        toggleDeleteConfirmDialog();
        onClose();
      },
      refetchQueries: [getOperationName(GetPlaylistTemplateDocument)!],
    });

  return (
    <BaseDialog
      {...rest}
      open={open}
      onClose={onClose}
      title={t(`certificateDialog.title.${keyPart}`, 'Upload Certificate')}
      disableEnforceFocus
    >
      <Formik<CertificateTemplateForm>
        initialValues={initialValues}
        validationSchema={getValidationSchema(t, !isEditVariant)}
        onSubmit={handleSubmit}
        validateOnMount
      >
        {({ isSubmitting, isValid, values }) => (
          <FormikForm>
            <FormikFileSelect
              name="attachment"
              label={t(
                `certificateDialog.form.attachment.label.${keyPart}`,
                'Add attachment',
              )}
              withValueLabel={t(
                'certificateDialog.form.attachment.changeLabel',
                'Change attachment',
              )}
              accept={SUPPORTED_FORMATS.join(', ')}
              disabled={isSubmitting}
              color="primary"
            />
            <FormikTextField
              name="name"
              type="text"
              placeholder={t(
                'certificateDialog.form.name.placeholder',
                'Certificate name',
              )}
              label={t('certificateDialog.form.name.label', 'Certificate name')}
              required
            />
            <FormikTextField
              name="sendCcTo"
              type="text"
              placeholder={t(
                'certificateDialog.form.sendCcTo.placeholder',
                'Send e-mail CC to',
              )}
              label={t(
                'certificateDialog.form.sendCcTo.label',
                'e-mail address list',
              )}
            />
            <FormikCheckBox
              name="sendToTrainees"
              label={t(
                'certificateDialog.form.sendToTrainees.label',
                'Send to trainees',
              )}
            />
            {isEditVariant && (
              <Flex justifyContent="center" marginTop={3}>
                <Button
                  id="certificateTemplate-edit-dialog-delete"
                  color="error"
                  variant="text"
                  startIcon={<DeleteIcon color="error" />}
                  onClick={toggleDeleteConfirmDialog}
                >
                  {t(
                    'certificateDialog.delete.button.label',
                    'Delete certificateTemplate',
                  )}
                </Button>
              </Flex>
            )}
            <Flex justifyContent="space-between">
              <Button
                id="certificateTemplate-edit-dialog-cancel"
                size="large"
                color="primary"
                disabled={isSubmitting}
                onClick={onClose}
              >
                {t('common.cancel', 'Cancel')}
              </Button>
              <Button
                id="certificateTemplate-edit-dialog-save"
                type="submit"
                size="large"
                color="primary"
                variant="contained"
                disabled={
                  isSubmitting ||
                  !isValid ||
                  (isEditVariant && equals(initialValues, values))
                }
              >
                {t('certificateDialog.save', 'Save')}
              </Button>
            </Flex>
          </FormikForm>
        )}
      </Formik>
      <ConfirmDialog
        open={deleteConfirmOpen.get()}
        onClose={toggleDeleteConfirmDialog}
        onConfirm={handleDeleteCertificateTemplate}
        title={t(
          'deleteCertificateDialog.title',
          'Do you want to delete this certificateTemplate?',
        )}
        confirmLabel={t(
          'deleteCertificateDialog.button.label',
          'delete certificateTemplate',
        )}
        confirmButtonColor="error"
        confirmDisabled={loading}
        confirmButtonStartIcon={<DeleteIcon />}
      />
    </BaseDialog>
  );
};

export default UploadCertificateTemplateDialog;

const getValidationSchema = (t: TFunction, fileRequired: boolean) =>
  Yup.object({
    name: Yup.string().required(
      t('certificateDialog.form.name.required', 'Fill your name'),
    ),
    sendCcTo: Yup.string().email(
      t('certificateDialog.form.email.valid', 'Invalid e-mail'),
    ),
    attachment: Yup.mixed()
      .test(
        'fileSize',
        t(
          'certificateDialog.form.attachment.fileTooLarge',
          'File is too large. Maximum is 10 MB',
        ),
        (value) => {
          if (!value) {
            return true;
          }
          return value.size <= MAX_UPLOAD_FILE_SIZE;
        },
      )
      .test(
        'fileType',
        t(
          'certificateDialog.form.attachment.fileTypeForbidden',
          'This file type is not supported.',
        ),
        (value) => {
          if (!value) {
            return true;
          }
          return SUPPORTED_FORMATS.includes(value?.type);
        },
      )
      .test(
        'required',
        t(
          'certificateDialog.form.attachment.required',
          'File must be provided',
        ),
        (value) => {
          if (fileRequired) {
            return value != null;
          }
          return true;
        },
      ),
  });
