import { useHookstate } from '@hookstate/core';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { adminState } from '../../../adminState';
import { Flex } from '../../../base/components/Flex';
import { useUser } from '../../../base/components/UserProvider/useUserHook';
import {
  OrderDirection,
  StreamType,
  UserFilterField,
  UserOrderByInput,
  UserOrderField,
  UserPermission,
  UserRole,
} from '../../../graphql/graphql-operations';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import useStreamType from '../../../lib/useStreamType';
import ContentWrapper from '../../components/ContentWrapper';
import { Loader } from '../../components/Loader';
import OrderBy from '../../components/OrderBy/OrderBy';
import PageHeader from '../../components/PageHeader';
import { SearchField } from '../../components/SearchField';
import UserGroupsFilter from './components/UserGroupsFilter';
import UserList, { TrainingPastDueFilter } from './components/UserList';
import UserTrainingPastDueFilter from './components/UserTrainingPastDueFilter';
import { prepareUserSortOptions } from './components/prepareUserSortOptions';

const UsersPage = () => {
  const streamType = useStreamType();
  const companyId = useHookstate(adminState.selectedCompanyId);
  const { _t } = useTranslationPrefix('UsersPage');
  const [query, setQuery] = useState('');
  const { hasAnyPermission } = useUser();
  const [selectedUserGroupIds, setSelectedUserGroupIds] = useState<string[]>(
    [],
  );

  const [selectedRoles, setSelectedRoles] = useState<UserRole[]>(
    streamType === StreamType.MEDICAL ? [UserRole.NONE] : [],
  );

  useEffect(() => {
    if (streamType === StreamType.MEDICAL) {
      setSelectedRoles([UserRole.NONE]);
    }
  }, [streamType, setSelectedRoles]);

  const [orderBy, setOrderBy] = useState<UserOrderByInput>({
    direction: OrderDirection.ASC,
    field: UserOrderField.LAST_NAME,
  });

  const [trainingPastDue, setTrainingPastDue] = useState<TrainingPastDueFilter>(
    TrainingPastDueFilter.ALL,
  );

  return !streamType ? (
    <Loader />
  ) : (
    <ContentWrapper>
      <PageHeader title={_t('title', 'People')}>
        {hasAnyPermission(UserPermission.MANAGE_COMPANY_USERS) && (
          <Button
            id="create-user"
            component={Link}
            to="/users/create"
            variant="contained"
            color="primary"
            startIcon={<AddRoundedIcon />}
          >
            {_t('addUser', 'Add Person')}
          </Button>
        )}
        <SearchField value={query} onValueChange={setQuery}></SearchField>
      </PageHeader>
      {streamType === StreamType.TRAINING && (
        <UserTrainingPastDueFilter
          pastDue={trainingPastDue}
          onChange={setTrainingPastDue}
          sx={{ mb: 2 }}
        />
      )}
      <Flex justifyContent="space-between" alignItems="end" sx={{ mb: 2 }}>
        <UserGroupsFilter
          companyId={companyId.get()}
          selectedGroupIds={selectedUserGroupIds}
          setSelectedGroupIds={setSelectedUserGroupIds}
          selectedRoles={selectedRoles}
          setSelectedRoles={setSelectedRoles}
          selectableRoles={
            streamType === StreamType.MEDICAL ? [UserRole.NONE] : []
          }
        />
        <OrderBy<UserOrderField>
          id="patients-sort"
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          options={prepareUserSortOptions(_t)}
        />
      </Flex>
      <UserList
        companyId={companyId.get()}
        trainingPastDue={trainingPastDue}
        userGroupIds={selectedUserGroupIds}
        userRoles={selectedRoles}
        searchConfig={{
          filterBy: UserFilterField.FULLTEXT,
          filterValue: query,
        }}
        createLink={(user) => `/users/${user.id}`}
        orderBy={orderBy}
      />
    </ContentWrapper>
  );
};

export default UsersPage;
