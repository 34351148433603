import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { BaseDialog } from '../../../base/components/BaseDialog';
import { Flex } from '../../../base/components/Flex';
import { useUser } from '../../../base/components/UserProvider/useUserHook';
import { VrDeviceType } from '../../../base/components/VrDevicesProvider/VrDevicesProvider';
import { UserPermission } from '../../../graphql/graphql-operations';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import useDeviceUpdate from './useDeviceUpdate';

interface Props {
  device?: VrDeviceType | null;
  onClose: () => void;
}

const DeviceUpdateDialog = ({ device, onClose }: Props) => {
  const [step, setStep] = useState<'preInstal' | 'postInstall'>('preInstal');
  const { hasPermission } = useUser();
  const open = !!device;
  const hasRightToUpdate = hasPermission(
    UserPermission.UPDATE_APPS_ON_VR_DEVICE,
  );
  const { _t } = useTranslationPrefix('DeviceUpdateDialog');
  const { doVrDeviceUpdate } = useDeviceUpdate();

  useEffect(() => setStep('preInstal'), [device, setStep]);

  const handleUpdateClick = async () => {
    if (device) {
      await doVrDeviceUpdate(device);
      setStep('postInstall');
    }
  };

  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      title={_t('title', 'Device requires updates.')}
    >
      {step === 'preInstal' && (
        <>
          <Box textAlign="center" pb={3} maxWidth="30ch" m="auto">
            {device?.deviceOwner ? (
              <span>
                {_t(
                  'autoUpdateInstructions',
                  'Device will update on its own, just leave it on and charging overnight.',
                )}
              </span>
            ) : hasRightToUpdate ? (
              <span>
                {_t(
                  'installInstructions',
                  'You will have to put vr device on and confirm instalation of updates.',
                )}
              </span>
            ) : (
              <span>
                {_t('contactYourSupervisor', 'Please contact your supervisor.')}
              </span>
            )}
          </Box>
          <Flex justifyContent="center">
            {hasRightToUpdate ? (
              <>
                <Button
                  id="device-update-start"
                  onClick={handleUpdateClick}
                  sx={{ m: 1 }}
                  variant="contained"
                  color="primary"
                >
                  {_t('startUpdate', 'Start Update')}
                </Button>
                {device?.deviceOwner && (
                  <Button
                    id="device-update-ok"
                    onClick={onClose}
                    variant="contained"
                    color="primary"
                    sx={{ m: 1 }}
                  >
                    {_t('ok', 'Ok')}
                  </Button>
                )}
              </>
            ) : (
              <Button
                id="device-update-contact-supervisor"
                onClick={onClose}
                sx={{ m: 1 }}
                variant="contained"
                color="primary"
              >
                {_t('willContactSupervisor', 'OK')}
              </Button>
            )}
          </Flex>
        </>
      )}
      {step === 'postInstall' && (
        <>
          <Box textAlign="center" pb={3} maxWidth="30ch" m="auto">
            {device?.deviceOwner ? (
              <span>
                {_t(
                  'autoInstallInstructions',
                  'Device will now update, please leave it connected to charger. Device will restart after update completes',
                )}
              </span>
            ) : (
              <span>
                {_t(
                  'postInstallInstructions',
                  'Please restart the device after instalation is complete or if any error occures.',
                )}
              </span>
            )}
          </Box>
          <Flex justifyContent="center">
            <Button
              id="device-update-done"
              onClick={onClose}
              variant="contained"
              color="primary"
            >
              {_t('updatesFinished', 'Finished')}
            </Button>
          </Flex>
        </>
      )}
    </BaseDialog>
  );
};

export default DeviceUpdateDialog;
