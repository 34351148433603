import { Route, Routes } from 'react-router-dom';
import NotFoundPage from '../NotFoundPage/NotFoundPage';
import SalesScenesPage from './SalesScenesPage';

const SalesRoute = () => {
  return (
    <Routes>
      <Route index element={<SalesScenesPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default SalesRoute;
