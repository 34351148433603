import { Route, Routes } from 'react-router-dom';
import AuthCheck from '../../../base/components/UserProvider/AuthCheck';
import { UserPermission } from '../../../graphql/graphql-operations';
import NotFoundPage from '../NotFoundPage/NotFoundPage';
import TrainingReportsRoute from '../TrainingReports/TrainingReportsRoute';
import UserReportsRoute from '../UserReports/UserReportsRoute';
import UserAssignTrainingPage from './UserAssignTrainingPage';
import UserChangePasswordPage from './UserChangePasswordPage';
import UserDetailPage from './UserDetailPage';
import UserEditPage from './UserEditPage';
import UsersPage from './UsersPage';

const UsersRoute = () => {
  return (
    <Routes>
      <Route
        element={
          <AuthCheck
            permission={[
              UserPermission.MANAGE_COMPANY_USERS,
              UserPermission.VIEW_COMPANY_USERS,
            ]}
          />
        }
      >
        <Route index element={<UsersPage />} />
      </Route>
      <Route
        element={<AuthCheck permission={UserPermission.MANAGE_COMPANY_USERS} />}
      >
        <Route path="/create" element={<UserEditPage />} />
        <Route path="/:userId/edit" element={<UserEditPage />} />
      </Route>
      <Route
        element={
          <AuthCheck
            permission={[
              UserPermission.UPDATE_MY_PASSWORD,
              UserPermission.UPDATE_COMPANY_USER_PASSWORD,
            ]}
          />
        }
      >
        <Route path="/:userId/password" element={<UserChangePasswordPage />} />
      </Route>
      <Route
        element={
          <AuthCheck
            permission={[
              UserPermission.MANAGE_COMPANY_USERS,
              UserPermission.VIEW_COMPANY_USERS,
            ]}
          />
        }
      >
        <Route path="/:userId" element={<UserDetailPage />} />
      </Route>
      <Route
        element={<AuthCheck permission={UserPermission.VIEW_MEDICAL_REPORTS} />}
      >
        <Route
          path="/:userId/medical-reports/*"
          element={<UserReportsRoute />}
        />
      </Route>
      <Route
        element={
          <AuthCheck permission={UserPermission.VIEW_TRAINING_REPORTS} />
        }
      >
        <Route
          path="/:userId/training-reports/*"
          element={<TrainingReportsRoute />}
        />
      </Route>
      <Route
        element={
          <AuthCheck permission={UserPermission.MANAGE_TRAINING_ASSIGNMENTS} />
        }
      >
        <Route
          path="/:userId/assign-training"
          element={<UserAssignTrainingPage />}
        />
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default UsersRoute;
