import { styled, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { PropsWithChildren } from 'react';
import EnviromentWarningBar, {
  ENV_WARNING_BAR_HEIGHT,
} from '../../base/components/EnviromentWarningBar/EnviromentWarningBar';
import { Flex } from '../../base/components/Flex';
import ClientBackgroundGradient from './ClientBackgroundGradient';
import ClientTopBar, { ClientTopBarHeight } from './ClientTopBar';

type Props = PropsWithChildren<{}>;

export const LAYOUT_SCROLL_DISABLED = 'ClientLayout-scrollDisabled';

const ClientLayout = ({ children }: Props) => {
  const theme = useTheme();
  return (
    <ClientLayoutPageWrapper
      flexDirection="column"
      height="100vh"
      overflow="hidden"
    >
      {theme.palette.mode === 'dark' && (
        <Box
          overflow="hidden"
          top={0}
          left={0}
          right={0}
          bottom={0}
          position="absolute"
        >
          <ClientBackgroundGradient />
        </Box>
      )}
      <EnviromentWarningBar />
      <ClientTopBar />
      <ClientLayoutWrapper>{children}</ClientLayoutWrapper>
    </ClientLayoutPageWrapper>
  );
};

export default ClientLayout;

const ClientLayoutPageWrapper = styled(Flex)(
  ({ theme: { palette } }) => `
  position: relative;
  background-color: ${palette.background.default};

  @media print {
    height: auto;
    overflow: visible;
  }
`,
);

const ClientLayoutWrapper = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-height: calc(100% - ${ClientTopBarHeight} - ${ENV_WARNING_BAR_HEIGHT});
  overflow: auto;
  align-items: center;

  body.${LAYOUT_SCROLL_DISABLED} & {
    overflow: hidden;
  }

  @media print {
    max-height: unset;
  }
`;
