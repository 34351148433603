import AddRoundedIcon from '@mui/icons-material/AddRounded';
import CloseIcon from '@mui/icons-material/Close';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import { alpha, Box, IconButton, styled } from '@mui/material';
import * as Sentry from '@sentry/react';
import { useSnackbar } from 'notistack';
import { PDFDocumentProxy } from 'pdfjs-dist/types/src/display/api';
import { useEffect, useRef, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { Loader } from '../../../client/components/Loader';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import { LAYOUT_SCROLL_DISABLED } from '../../../layouts/ClientLayout/ClientLayout';
import { BaseTypography } from '../BaseTypography';
import { Flex } from '../Flex';
import { IconButtonOutlined } from '../IconButton';

const HEADER_HEIGHT = '6rem';

interface Props {
  title: string;
  url?: string | null;
  onClose: () => void;
}

const PdfReader = ({ title, url, onClose }: Props) => {
  const [numPages, setNumPages] = useState<number[]>([]);
  const [scale, setScale] = useState<number>(1);
  const documentProxy = useRef<PDFDocumentProxy>();
  const { enqueueSnackbar } = useSnackbar();
  const { _t } = useTranslationPrefix('PdfReader');

  useEffect(() => {
    setNumPages([]);
    if (url) {
      document.body.classList.add(LAYOUT_SCROLL_DISABLED);
    } else {
      document.body.classList.remove(LAYOUT_SCROLL_DISABLED);
    }
  }, [url]);

  const handleDocumentLoadSuccess = (proxy: PDFDocumentProxy) => {
    setNumPages(
      Array(proxy.numPages)
        .fill(null)
        .map((_, i) => i + 1),
    );
    documentProxy.current = proxy;
  };

  const handleLoadError = (error: Error) => {
    console.error('Failed to load pdf', error);
    Sentry.captureException(error);
    enqueueSnackbar(_t('failedToLoadPdf', 'Failed to load PDF file'), {
      variant: 'error',
    });
    onClose();
  };

  const handleDownload = async () => {
    const content = await documentProxy.current?.getData();
    if (!content) return;
    const blob = new Blob([content], { type: 'application/octet-stream' });
    const url = URL.createObjectURL(blob);

    const pom = document.createElement('a');
    pom.href = url;
    pom.setAttribute('download', `${title}.pdf`);
    pom.click();
    pom.remove();
    setTimeout(function () {
      return window.URL.revokeObjectURL(url);
    }, 1000);
  };

  return url ? (
    <PdfReaderWrapper>
      <Flex
        sx={{
          backgroundColor: '#000',
          opacity: 0.8,
          height: HEADER_HEIGHT,
          width: '100%',
          flexShrink: 0,
        }}
      >
        <Flex
          sx={{
            flexBasis: 10,
            flexGrow: 1,
            alignItems: 'center',
            px: 2,
          }}
        >
          <IconButton id="download-pdf" color="white" onClick={handleDownload}>
            <DownloadRoundedIcon />
          </IconButton>
          <BaseTypography
            color="white.main"
            variant="h5"
            ml={1}
            display={{ xs: 'none', sm: 'initial' }}
          >
            {title}
          </BaseTypography>
        </Flex>
        <Flex sx={{ flexShrink: 0 }} alignItems="center" gap={2}>
          <IconButtonOutlined
            id="zoom-out"
            color="white"
            onClick={() => setScale((s) => s - 0.25)}
            disabled={scale < 0.3}
          >
            <RemoveRoundedIcon />
          </IconButtonOutlined>
          <BaseTypography color="white.main" variant="h5">{`${
            scale * 100
          } %`}</BaseTypography>
          <IconButtonOutlined
            id="zoom-in"
            color="white"
            onClick={() => setScale((s) => s + 0.25)}
          >
            <AddRoundedIcon />
          </IconButtonOutlined>
        </Flex>
        <Flex
          sx={{
            flexBasis: 10,
            flexGrow: 1,
            alignItems: 'center',
            justifyContent: 'end',
            px: 2,
          }}
        >
          <IconButton id="close-pdf-reader" color="white" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Flex>
      </Flex>
      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: 'center',
          overflow: 'auto',
          height: `calc( 100% - ${HEADER_HEIGHT})`,
          p: 2,
          gap: 2,
        }}
      >
        <Document
          file={url}
          onLoadSuccess={handleDocumentLoadSuccess}
          options={{
            cMapUrl: 'cmaps/',
            cMapPacked: true,
            standardFontDataUrl: 'standard_fonts/',
          }}
          className={classes.document}
          onLoadError={handleLoadError}
        >
          {numPages.map((pageNo) => (
            <Page
              key={pageNo}
              pageNumber={pageNo}
              renderMode="canvas"
              renderTextLayer={false}
              scale={scale}
              className={classes.page}
            />
          ))}
        </Document>
        {!numPages?.length && <Loader />}
      </Flex>
    </PdfReaderWrapper>
  ) : null;
};
export default PdfReader;

const classes = {
  document: 'PdfReader-document',
  page: 'PdfReader-page',
};

const PdfReaderWrapper = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${alpha('#000', 0.7)};
  z-index: 1;

  & .${classes.document} {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    gap: 1rem;
  }

  & .${classes.page} {
  }
`;
