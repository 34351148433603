import { TableCell } from '@mui/material';
import { Link } from 'react-router-dom';
import { BaseTypography } from '../BaseTypography';

interface Props {
  to: string;
  label: string;
}
const TableCellLink = ({ to, label }: Props) => {
  return (
    <TableCell>
      <Link to={to} style={{ textDecoration: 'none' }}>
        <BaseTypography fontWeight="bold" color="primary">
          {label}
        </BaseTypography>
      </Link>
    </TableCell>
  );
};

export default TableCellLink;
