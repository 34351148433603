import { Box, Button, Paper as MuiPaper, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BaseTypography } from '../../../base/components/BaseTypography';
import { Manual } from './types';

const ManualCard = ({ id, name, description, url }: Manual) => {
  const { t } = useTranslation();
  return (
    <Paper>
      <BaseTypography variant="h6">{name}</BaseTypography>
      <Description>
        <BaseTypography variant="body1">{description}</BaseTypography>
      </Description>
      <Box marginTop="auto">
        <Button
          id={`manual-card-read-${id}`}
          variant="contained"
          color="secondary"
          component="a"
          href={url}
        >
          {t('manualCard.button.label', 'READ')}
        </Button>
      </Box>
    </Paper>
  );
};

export default ManualCard;

const Paper = styled(MuiPaper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2),
  margin: theme.spacing(0.5),
  textAlign: 'center',
  minWidth: theme.spacing(19),
  '& > *:not(:last-child)': {
    marginBottom: theme.spacing(1),
  },
}));

const Description = styled('div')(({ theme }) => ({
  width: theme.spacing(19),
}));
