import { Button } from '@mui/material';
import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BaseTabs } from '../../../base/components/BaseTabs';
import HasPermission from '../../../base/components/UserProvider/HasPermission';
import { useUser } from '../../../base/components/UserProvider/useUserHook';
import { UserPermission } from '../../../graphql/graphql-operations';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import ContentWrapper from '../../components/ContentWrapper';
import PageHeader from '../../components/PageHeader';
import { SearchField } from '../../components/SearchField';
import PlaylistAssignCompaniesTab from './components/PlaylistAssignCompaniesTab';
import usePlaylistFolderAssignCompanies from './components/usePlaylistFolderAssignCompanies';

enum TabValue {
  COMPANIES = 'COMPANIES',
}

const PlaylistTemplateFolderAssignPage = () => {
  const { folderId } = useParams<{ folderId: string }>();
  const navigate = useNavigate();
  const { _t } = useTranslationPrefix('PlaylistTemplateFolderAssignPage');
  const { hasPermission } = useUser();
  const [query, setQuery] = useState('');
  const [tab, setTab] = useState(TabValue.COMPANIES);

  const onCompleted = useCallback(
    () => navigate(`/playlist-templates/folders/${folderId}`),
    [navigate, folderId],
  );

  const {
    companies,
    setSelectedCompanies,
    assignCompaniesDisabled,
    assignCompanies,
  } = usePlaylistFolderAssignCompanies({
    // TODO replace with folderId and update usePlaylistFolderAssignCompanies hook
    folderId,
    onCompleted,
  });

  return (
    <ContentWrapper>
      <PageHeader title={_t('title', 'Assign folder')} back>
        {tab === TabValue.COMPANIES && (
          <Button
            id="share-playlist"
            variant="contained"
            color="primary"
            disabled={assignCompaniesDisabled}
            onClick={assignCompanies}
          >
            {assignCompaniesDisabled
              ? _t('comanies.selectFirst', 'Change selection')
              : _t('finish', 'Finish')}
          </Button>
        )}
        <SearchField value={query} onValueChange={setQuery}></SearchField>
      </PageHeader>
      <BaseTabs
        value={tab}
        onChange={(e, val) => setTab(val)}
        sx={{ mb: 2 }}
        tabs={[
          {
            label: _t('tabs.companies', 'Org. units'),
            value: TabValue.COMPANIES,
            count: companies.length,
            shouldRender: hasPermission(
              UserPermission.MANAGE_OTHERS_PLAYLIST_TEMPLATES,
            ),
          },
        ]}
      />
      <HasPermission val={UserPermission.MANAGE_OTHERS_PLAYLIST_TEMPLATES}>
        {tab === TabValue.COMPANIES && (
          <PlaylistAssignCompaniesTab
            query={query}
            companies={companies}
            setSelectedCompanies={setSelectedCompanies}
          />
        )}
      </HasPermission>
    </ContentWrapper>
  );
};

export default PlaylistTemplateFolderAssignPage;
