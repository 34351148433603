import { Button, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { ChangeEvent, useState } from 'react';
import { Flex } from '../../../../base/components/Flex';
import HasPermission from '../../../../base/components/UserProvider/HasPermission';
import {
  LaunchUrlDocument,
  UserPermission,
} from '../../../../graphql/graphql-operations';
import { useMutationWithSnackbar } from '../../../../hooks/useMutationWithSnackbar';
import { SectionPaper } from '../../../components/SectionPaper';

interface Props {
  deviceId: string;
}

const VrDeviceDebugLaunchUrl = ({ deviceId }: Props) => {
  const [url, setUrl] = useState('');
  const [launchUrl] = useMutationWithSnackbar(
    'VrDeviceDebugLaunchUrl',
    LaunchUrlDocument,
  );

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setUrl(value);
  };

  const handleClick = () => {
    launchUrl({
      variables: {
        input: {
          vrDeviceId: deviceId,
          url: url,
        },
      },
    });
  };

  return (
    <HasPermission val={UserPermission.DEBUG}>
      <Box mt={2}>
        <SectionPaper title="$ Debug - launch url">
          <Flex>
            <TextField
              value={url}
              onChange={handleChange}
              fullWidth
            ></TextField>
            <Button
              id="debug-launch-url"
              onClick={handleClick}
              disabled={url.length < 3}
            >
              Launch
            </Button>
          </Flex>
        </SectionPaper>
      </Box>
    </HasPermission>
  );
};

export default VrDeviceDebugLaunchUrl;
