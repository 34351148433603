import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import { Flex } from '../../../base/components/Flex';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';

const Loader = () => {
  const { _t } = useTranslationPrefix('Loader');
  return (
    <Flex width="100%" flexGrow={1} justifyContent="center" alignItems="center">
      <CircularProgress />
      <Box pl={2} color="text.secondary">
        {_t('title', 'Loading...')}
      </Box>
    </Flex>
  );
};

export default Loader;
