import { Card, CardProps } from '@mui/material';

interface Props extends CardProps {}
const SessionFloatingCard = ({ children, ...cardProps }: Props) => {
  return (
    <Card
      onClick={(e) => e.stopPropagation()}
      onDoubleClick={(e) => e.stopPropagation()}
      {...cardProps}
    >
      {children}
    </Card>
  );
};

export default SessionFloatingCard;
