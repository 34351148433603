import { Box, Typography } from '@mui/material';
import { Flex } from '../../../base/components/Flex';
import HelpFaqItemChoiceButton from './HelpFaqItemChoiceButton';
import { useHelpFaq } from './provider';
import { HelpFaqContentItemChoices } from './types';

const HelpFaqItemChoices = ({ item }: { item: HelpFaqContentItemChoices }) => {
  const { onButtonClick } = useHelpFaq();
  const { title, buttons } = item;
  return (
    <Box width="100%">
      {title && (
        <Typography variant="h4" mb={4}>
          {title}
        </Typography>
      )}
      <Flex gap={2} flexDirection="column">
        {buttons.map((button) => (
          <HelpFaqItemChoiceButton
            id={button.id.toString()}
            key={button.id}
            onClick={() => onButtonClick(button)}
          >
            {button.title}
          </HelpFaqItemChoiceButton>
        ))}
      </Flex>
    </Box>
  );
};

export default HelpFaqItemChoices;
