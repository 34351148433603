import { Field } from 'formik';
import { CheckboxWithLabel } from 'formik-mui';

interface Props {
  name: string;
  label: string;
  className?: string;
}

const FormikCheckBox = ({ name, label, className }: Props) => {
  return (
    <div className={className}>
      <Field
        component={CheckboxWithLabel}
        name={name}
        type="checkbox"
        color="primary"
        Label={{ label: label }}
      />
    </div>
  );
};

export default FormikCheckBox;
