import { useQuery } from '@apollo/client';
import { ResponsiveStyleValue } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import {
  GetUserTrainingAssignmentsDocument,
  UserTrainingFragmentFragment,
} from '../../../../graphql/graphql-operations';
import useQueryErrorSnackbar from '../../../../hooks/useQueryErrorSnackbar';
import { ListView, SearchListVariables } from '../../../components/ListView';
import UserTrainingListCard, {
  TrainingListCardFeatures,
} from './UserTrainingListCard';

type Training = UserTrainingFragmentFragment;

interface Props {
  userId?: string | null;
  columns?: ResponsiveStyleValue<number>;
  features?: TrainingListCardFeatures;
  searchConfig?: SearchListVariables<UserTrainingFragmentFragment>;
}

const UserTrainingList = ({
  userId,
  columns = { xs: 1, sm: 3, lg: 4 },
  features,
  searchConfig,
}: Props) => {
  const navigate = useNavigate();
  const { data, loading, error, refetch } = useQuery(
    GetUserTrainingAssignmentsDocument,
    {
      variables: {
        userId: userId!,
      },
      skip: userId == null,
      // TO-CHECK BE SURE THAT THIS QUERY IS CALLED EARLIER ON SAME PAGE
      fetchPolicy: 'cache-and-network',
    },
  );
  useQueryErrorSnackbar(error, refetch);
  const trainings = data?.userTrainingAssignments || [];

  const renderTraining = (training: Training) => (
    <UserTrainingListCard
      training={training}
      onClick={() => navigate(`/trainings/user/${training.id}`)}
      features={features}
      fullHeight
    />
  );

  return (
    <ListView<Training>
      data={trainings}
      columns={columns}
      rowKey="id"
      renderValue={renderTraining}
      loading={loading}
      searchConfig={searchConfig}
      sx={{ mt: 0 }}
    />
  );
};

export default UserTrainingList;
