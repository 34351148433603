import { useQuery } from '@apollo/client';
import { useEffect } from 'react';
import {
  EndVrSessionMutation,
  GetVrSessionDetailDocument,
  LaunchNextInVrSessionDocument,
  LaunchNextInVrSessionMutation,
  ObserveVrSessionChangesDocument,
  StopSessionOnDevicesDocument,
} from '../../../graphql/graphql-operations';
import { useMutationWithSnackbar } from '../../../hooks/useMutationWithSnackbar';
import useQueryErrorSnackbar from '../../../hooks/useQueryErrorSnackbar';

interface Params {
  vrSessionId: string;
  onEndCompleted?: (data: EndVrSessionMutation) => void;
  onNextCompleded?: (data: LaunchNextInVrSessionMutation) => void;
}

export const useSessionData = ({ vrSessionId, onNextCompleded }: Params) => {
  const { data, loading, error, refetch, subscribeToMore } = useQuery(
    GetVrSessionDetailDocument,
    {
      variables: {
        vrSessionId,
      },
      skip: !vrSessionId,
      fetchPolicy: 'cache-and-network',
    },
  );
  useEffect(() => {
    return subscribeToMore({
      document: ObserveVrSessionChangesDocument,
      variables: { vrSessionId },
      updateQuery: (prev, updated) => {
        const sessionData = updated.subscriptionData.data.sessionChange;
        return { ...prev, session: sessionData };
      },
    });
  }, [vrSessionId, subscribeToMore]);

  useQueryErrorSnackbar(error, refetch);

  const [endMutation] = useMutationWithSnackbar(
    'SessionDetailPage.endMutation',
    StopSessionOnDevicesDocument,
  );

  const [nextMutation] = useMutationWithSnackbar(
    'SessionDetailPage.nextMutation',
    LaunchNextInVrSessionDocument,
    {
      onCompleted: onNextCompleded,
    },
  );

  const handleNext = (itemId?: string) => {
    if (!vrSessionId) {
      return;
    }
    nextMutation({
      variables: {
        nextInput: {
          sessionId: vrSessionId,
          sessionPlaylistItemId: itemId,
        },
      },
    });
  };

  return { data, loading, handleNext, endMutation };
};
