import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import {
  Box,
  Button,
  Paper as MuiPaper,
  styled,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ReactComponent as VrCardboard } from '../../../assets/svg/vr-cardboard-solid.svg';
import { BaseTypography as MedTypography } from '../../../base/components/BaseTypography';
import { Flex } from '../../../base/components/Flex';
import { VrDeviceType } from '../../../base/components/VrDevicesProvider/VrDevicesProvider';
import { DeviceState } from '../../../graphql/graphql-operations';

interface Props {
  device: VrDeviceType;
  hideDeviceLink?: boolean; // TODO: remove after device select page redesign
  onDeviceSelected?: (deviceInfo: VrDeviceType) => void;
}

const VrDeviceCard = ({ device, hideDeviceLink, onDeviceSelected }: Props) => {
  const { status, color, name, id } = device;
  const { state } = status;
  const { t } = useTranslation();

  return (
    <Paper>
      <Flex justifyContent="space-between">
        <DeviceStateStyle state={state} variant="body2">
          {t(`common.deviceState.${state}`)}
        </DeviceStateStyle>
        <Flex color="red">
          <BatteryStatus variant="body2" battery={status.battery}>
            {status?.battery ?? '-'}%
          </BatteryStatus>
          <Box marginLeft={0.5}>
            <BatteryIcon viewBox="2 2 20 20" battery={status.battery} />
          </Box>
        </Flex>
      </Flex>
      <Flex flexDirection="column" alignItems="center" title={name}>
        {hideDeviceLink ? (
          <Flex flexDirection="column" alignItems="center">
            <IconWrapper style={{ fill: color ?? 'yellowgreen' }}>
              <VrCardboard />
            </IconWrapper>
            <Box marginBottom={2} style={{ maxWidth: '100%' }}>
              <BaseTypography variant="h6">{name}</BaseTypography>
            </Box>
          </Flex>
        ) : (
          <DeviceLink to={`/admin/vr-devices/${id}`}>
            <IconWrapper style={{ fill: color ?? 'yellowgreen' }}>
              <VrCardboard />
            </IconWrapper>
            <Box marginBottom={2} style={{ maxWidth: '100%' }}>
              <BaseTypography variant="h6">{name}</BaseTypography>
            </Box>
          </DeviceLink>
        )}
        {onDeviceSelected && (
          <Button
            id={`vr-device-card-select-${device.id}`}
            size="medium"
            color="secondary"
            variant="contained"
            onClick={() => onDeviceSelected && onDeviceSelected(device)}
            disabled={state !== DeviceState.ONLINE}
          >
            {t('vrDeviceCard.select', 'SELECT')}
          </Button>
        )}
      </Flex>
    </Paper>
  );
};

export default VrDeviceCard;

const Paper = styled(MuiPaper)(({ theme }) => ({
  padding: theme.spacing(2),
  margin: theme.spacing(0.5),
  width: '150px',
}));

const BaseTypography = styled(MedTypography)(({ theme }) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const DeviceStateStyle = styled(Typography)<{ state: DeviceState }>(
  ({ theme, state }) => ({
    fontWeight: 'bold',
    color:
      state === DeviceState.ONLINE
        ? theme.palette.secondary.main
        : state === DeviceState.IN_USE
        ? theme.palette.primary.main
        : theme.palette.error.main,
  }),
);

const BatteryStatus = styled(Typography)<{
  battery: number | undefined | null;
}>(({ theme, battery }) => ({
  fontWeight: 'bold',
  color:
    battery && battery < 20
      ? theme.palette.error.main
      : theme.palette.text.primary,
}));

const BatteryIcon = styled(BatteryFullIcon)<{
  battery: number | undefined | null;
}>(({ theme, battery }) => ({
  transform: 'rotate(90deg)',
  fontSize: '1.25rem',
  color:
    battery && battery < 20
      ? theme.palette.error.main
      : theme.palette.grey.A700,
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  width: theme.spacing(10.5),
  marginTop: theme.spacing(1),
}));

const DeviceLink = styled(Link)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textDecoration: 'none',
  color: 'unset',
  maxWidth: '100%',
});
