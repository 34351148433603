import InfoIcon from '@mui/icons-material/Info';
import { Tooltip, TooltipProps } from '@mui/material';
interface Props extends Omit<TooltipProps, 'children'> {}

const InfoTooltip = ({ ...rest }: Props) => {
  return (
    <Tooltip arrow={true} placement="right-start" {...rest}>
      <InfoIcon color="primary" />
    </Tooltip>
  );
};

export default InfoTooltip;
