export const formatDistance = (
  distanceMeters: number,
  space: string = ' ',
  includeMM = false,
) => {
  const absDistance = Math.abs(distanceMeters);
  let m, cm, mm;
  m = Math.floor(absDistance);
  cm = Math.floor((absDistance - m) * 100);
  mm = Math.floor(((absDistance - m) * 100 - cm) * 10);

  let parts = [];
  if (m) {
    parts.push(`${m} m`);
  }
  if (cm) {
    parts.push(`${cm} cm`);
  }
  if (mm && includeMM) {
    parts.push(`${mm} mm`);
  }
  if (!parts.length) {
    parts.push(`0 cm`);
  }

  if (distanceMeters < 0) {
    parts = ['-', ...parts];
  }

  return parts.join(space);
};
