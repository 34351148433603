import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import { Box, Button, Grid } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import usevrbg from '../../../assets/use-vr-bg.png';
import { Flex } from '../../../base/components/Flex';
import useLogout from '../../../hooks/useLogout';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import ContentWrapper from '../../components/ContentWrapper';
import { useSessionData } from '../Sessions/useSessionData';

const AppStartedPage = () => {
  const { vrSessionId } = useParams<{ vrSessionId: string }>();
  const navigate = useNavigate();
  const { _t } = useTranslationPrefix('appStarted');
  const theme = useTheme();
  const { logout } = useLogout();
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const [seconds, setSeconds] = useState(30);
  const handleEndCompleted = async () => {
    navigate('/');
  };

  const cancelTimer = () => {
    timerRef.current && clearTimeout(timerRef.current);
    timerRef.current = null;
  };

  useEffect(() => {
    if (seconds <= 0) {
      logout();
      return;
    }
    timerRef.current = setTimeout(() => {
      setSeconds((prev) => {
        if (prev <= 0) {
          cancelTimer();
        }
        return Math.max(0, prev - 1);
      });
    }, 1000);
    return () => {
      cancelTimer();
    };
  }, [seconds, logout]);

  const { data } = useSessionData({
    vrSessionId: vrSessionId!,
    onEndCompleted: handleEndCompleted,
  });

  const device = data?.session?.vrDevice;

  return (
    <ContentWrapper justifyContent="center" alignItems="center">
      <UseVr>
        <UseVrBox>{device?.name}</UseVrBox>
      </UseVr>
      <MessageBox>
        <TitleMessage>
          {_t('putOnHeadsetMessage', 'Put the headset {{deviceName}} on.', {
            deviceName: device?.name,
          })}
        </TitleMessage>
        <TitleMessage>
          {_t('logoutTimerMessage', 'You will be logged out in {{seconds}}s.', {
            seconds: seconds,
          })}
        </TitleMessage>
        <IconMessage>
          <BatteryChargingFullIcon
            htmlColor={theme.palette.grey[700]}
            fontSize="large"
            sx={{ marginRight: 0.5 }}
          />{' '}
          {_t(
            'plugInMessage',
            'Always plug in the headset into charger after training completion.',
          )}
        </IconMessage>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              id="app-started-stop-session"
              variant="outlined"
              color="white"
              onClick={() => {
                navigate('/');
              }}
              fullWidth
            >
              {_t('cancel', 'Cancel')}
            </Button>
          </Grid>
        </Grid>
      </MessageBox>
    </ContentWrapper>
  );
};

export default AppStartedPage;

const UseVrBox = styled(Box)(
  ({ theme }) => `
  display: flex;
  position: absolute;
  top: 118px;
  left: 98px;
  width: 230px;
  height: 64px;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-size: ${theme.spacing(5.5)};
  line-height: ${theme.spacing(5.5)};
  font-weight: 700;
  color: ${theme.palette.common.black};
`,
);

const UseVr = styled(Flex)(() => ({
  background: `url(${usevrbg})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  width: '428px',
  height: '264px',
  position: 'relative',
  flexShrink: 0,
}));

const TitleMessage = styled(Flex)(({ theme }) => ({
  textAlign: 'center',
  fontWeight: 700,
  fontSize: theme.spacing(2.5),
  marginBottom: theme.spacing(3),
  justifyContent: 'center',
}));

const MessageBox = styled(Flex)(({ theme }) => ({
  flexDirection: 'column',
  maxWidth: '430px',
  marginTop: theme.spacing(4),
}));

const IconMessage = styled(Flex)(({ theme }) => ({
  alignItems: 'center',
  marginBottom: theme.spacing(3),
}));
