import { useQuery } from '@apollo/client';
import { styled, TableCell } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import {
  BaseTable,
  getHeadCellsFromObject,
  getRowContentFromObject,
  RowContentConfig,
  TableSearchControlBar,
} from '../../../base/components/BaseTable';
import { BaseTypography } from '../../../base/components/BaseTypography';
import { ManualsDocument } from '../../../graphql/graphql-operations';
import useQueryErrorSnackbar from '../../../hooks/useQueryErrorSnackbar';
import { DATE_FORMAT } from '../../../lib/constants';
import ManualRowActions from './ManualRowActions';

interface TableData {
  name: string;
  url: string;
  description: string;
  updatedAt: string;
}

const ManualTable = () => {
  const { t } = useTranslation();

  const { data, loading, error, refetch } = useQuery(ManualsDocument);
  useQueryErrorSnackbar(error, refetch);

  const headConfig = [
    {
      id: 'name' as const,
      defaultTrans: 'Name',
    },
    {
      id: 'description' as const,
      defaultTrans: 'Description',
      sortable: false,
    },
    {
      id: 'updatedAt' as const,
      defaultTrans: 'Date of change',
      sortable: false,
    },
  ];

  const tableHead = getHeadCellsFromObject(headConfig, 'manuals.table', t);
  const rowsConfig: RowContentConfig<TableData>[] = [
    {
      id: 'name' as const,
      component: ({ name, url }) => (
        <TableCell>
          <BaseTypography fontWeight="bold" color="primary">
            <Link href={url}>{name}</Link>
          </BaseTypography>
        </TableCell>
      ),
    },
    {
      id: 'description' as const,
    },
    {
      id: 'updatedAt' as const,
      getLabel: (updatedAt: string) => format(parseISO(updatedAt), DATE_FORMAT),
    },
  ];

  const manuals = data?.manuals || [];
  const tableRows = manuals.map((manual) => ({
    id: manual.id,
    rowContent: getRowContentFromObject(manual, rowsConfig),
    rowActions: <ManualRowActions {...manual} />,
  }));
  return (
    <BaseTable
      id="manual-table"
      isLoading={loading}
      rows={tableRows}
      head={tableHead}
      useInBuildFeatures
      controlBar={
        <TableSearchControlBar
          filterBy="name"
          title={t('manuals.controlBar.title', 'Manual list')}
          placeholderTransKey={'manuals.controlBar.placeholder'}
        />
      }
    />
  );
};

export default ManualTable;

const Link = styled('a')({
  color: 'inherit',
  textDecoration: 'none',
});
