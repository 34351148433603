import { useQuery } from '@apollo/client';
import { useState as useHookState } from '@hookstate/core';
import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { adminState } from '../../../adminState';
import {
  GetDevicesChangeSubscriptionDocument,
  GetVrBoxesDocument,
  GetVrBoxesQuery,
  GetVrDevicesDocument,
  GetVrDevicesQuery,
  UserPermission,
} from '../../../graphql/graphql-operations';
import { compareByProp } from '../../../lib/compare';
import { BOX_ID_KEY } from '../../../lib/localStorageKeys';
import { useUser } from '../UserProvider/useUserHook';
import { handleDeviceEventUpdate } from './deviceEventHandler';

interface Props {}

export type VrDeviceType = GetVrDevicesQuery['vrDevices'][0];

export interface VrDevicesContextType {
  loading: boolean;
  devices: GetVrDevicesQuery['vrDevices'];
  boxDevices: GetVrDevicesQuery['vrDevices'];
  hasActiveSession: boolean;
  vrBoxes: GetVrBoxesQuery['vrBoxes'];
  vrBoxId: string | null;
  setVrBoxId: (vrBoxId: string | null) => void;
}

export const VrDevicesContext = React.createContext<VrDevicesContextType>({
  loading: true,
  devices: [],
  boxDevices: [],
  hasActiveSession: false,
  vrBoxId: null,
  vrBoxes: [],
  setVrBoxId: () => {},
});

export const setVrBoxIdToLocalStorage = (vrBoxId: string | null) => {
  if (vrBoxId) {
    localStorage.setItem(BOX_ID_KEY, vrBoxId);
  } else {
    localStorage.removeItem(BOX_ID_KEY);
  }
};

export const getVrBoxIdFromLocalStorage = () => {
  return localStorage.getItem(BOX_ID_KEY);
};

const VrDevicesProvider: React.FC<PropsWithChildren<Props>> = ({
  children,
}: PropsWithChildren<Props>) => {
  const selectedCompanyId = useHookState(adminState.selectedCompanyId).get();
  // memoize compnayId to prevent unnecessary resubscriptions
  const companyId = useMemo(() => selectedCompanyId, [selectedCompanyId]);
  const [vrBoxId, setVrBoxId] = useState<string | null>(null);
  const { hasPermission } = useUser();
  const { data: vrBoxData } = useQuery(GetVrBoxesDocument, {
    variables: {
      companyIdOptionalInput: {
        companyId,
      },
    },
    skip: !hasPermission(UserPermission.READ_COMPANY_VR_BOXES),
  });
  const {
    data: devicesData,
    loading,
    subscribeToMore,
  } = useQuery(GetVrDevicesDocument, {
    variables: {
      companyIdOptionalInput: {
        companyId,
      },
    },
  });

  const vrDevices = useMemo(() => {
    return [...(devicesData?.vrDevices || [])].sort(
      compareByProp('name', 'asc'),
    );
  }, [devicesData]);

  useEffect(() => {
    const boxCode = getVrBoxIdFromLocalStorage();
    if (!vrBoxData?.vrBoxes) {
      return;
    }
    const vrBox = vrBoxData.vrBoxes.find((box) => box.id === boxCode);
    if (vrBox) {
      setVrBoxId(vrBox.id);
    }
  }, [vrBoxData?.vrBoxes]);

  useEffect(() => {
    return subscribeToMore({
      document: GetDevicesChangeSubscriptionDocument,
      variables: {
        companyIdOptionalInput: {
          companyId,
        },
      },
      updateQuery: (prev, updated) => {
        const updateEvent = updated.subscriptionData.data.vrDevicesChanges;
        const updatedDevices = handleDeviceEventUpdate(
          prev.vrDevices || [],
          updateEvent,
        );
        return { ...prev, vrDevices: updatedDevices };
      },
    });
  }, [companyId, subscribeToMore]);

  const hasActiveSession =
    vrDevices.filter((device) => device.session?.id).length > 0;

  const handleSetVrBoxId = (vrBoxId: string | null) => {
    setVrBoxIdToLocalStorage(vrBoxId);
    setVrBoxId(vrBoxId || null);
  };

  const boxDevices = useMemo(() => {
    return vrDevices.filter((dev) => !vrBoxId || dev.vrBoxId === vrBoxId);
  }, [vrBoxId, vrDevices]);
  return (
    <VrDevicesContext.Provider
      value={{
        loading,
        devices: vrDevices,
        boxDevices: boxDevices,
        hasActiveSession,
        vrBoxes: vrBoxData?.vrBoxes || [],
        vrBoxId,
        setVrBoxId: handleSetVrBoxId,
      }}
    >
      {children}
    </VrDevicesContext.Provider>
  );
};

export default VrDevicesProvider;
