import { BaseTypography } from '../../../../base/components/BaseTypography';
import { BaseTypographyProps } from '../../../../base/components/BaseTypography/BaseTypography';

interface Props extends BaseTypographyProps {
  disabled?: boolean;
  children: React.ReactNode;
}

const SessionParamBubble = ({
  children,
  disabled,
  sx = {},
  ...restProps
}: Props) => {
  return (
    <BaseTypography
      variant="caption"
      whiteSpace="nowrap"
      sx={{
        backgroundColor: disabled ? 'grey.100' : 'background.paper',
        px: 2,
        py: 0.5,
        borderRadius: '200px',
        maxWidth: '11ch',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        ...sx,
      }}
      {...restProps}
    >
      {children}
    </BaseTypography>
  );
};

export default SessionParamBubble;
