import { useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Grid,
} from '@mui/material';
import { styled } from '@mui/system';
import { useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Flex } from '../../../base/components/Flex';
import HasCompanyManagementPermission from '../../../base/components/UserProvider/HasCompanyManagementPermission';
import HasPermission from '../../../base/components/UserProvider/HasPermission';
import { useUser } from '../../../base/components/UserProvider/useUserHook';
import {
  CompanyStatus,
  GetCompanyDocument,
  UserPermission,
} from '../../../graphql/graphql-operations';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import DetailValue from '../../components/DetailCard/DetaiValue';
import { TopBar } from '../../components/TopBar';
import CompanyList from './CompanyList';
import CompanyNoteCard from './CompanyNoteCard';
import EditCompanyDialog from './EditCompanyDialog';
import NewCompanyDialog from './NewCompanyDialog';
import CompanyLogoCard from './components/CompanyLogoCard';

const CompanyDetailPage = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const { _t } = useTranslationPrefix('companyDetailPage');
  const [selectedStatus, setSelectedStatus] = useState(CompanyStatus.ACTIVE);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [newSubSidiaryOpened, setNewSubsidiaryOpened] = useState(false);
  const { hasPermission } = useUser();
  const { data, loading } = useQuery(GetCompanyDocument, {
    variables: {
      companyIdInput: {
        companyId: companyId,
      },
    },
    skip: !companyId,
    fetchPolicy: 'network-only',
  });

  const company = data?.company;

  const getOtherStatus = (companyStatus: CompanyStatus) => {
    return companyStatus === CompanyStatus.ACTIVE
      ? CompanyStatus.SUSPENDED
      : CompanyStatus.ACTIVE;
  };

  if (loading || !company) return <CircularProgress />;
  const companyTitle = company.parentCompany
    ? `${company.name} (${company.parentCompany.name})`
    : company.name;
  return (
    <>
      <TopBar
        leftSideText={companyTitle}
        actions={
          company.status === CompanyStatus.ACTIVE && (
            <HasCompanyManagementPermission companyId={company.id}>
              <Button
                id="company-detail-page-edit"
                size="large"
                color="primary"
                variant="text"
                startIcon={<EditIcon viewBox="5 5 14 14" />}
                onClick={() => setEditDialogOpen(true)}
              >
                {_t('editOrganization', 'Edit organization')}
              </Button>
            </HasCompanyManagementPermission>
          )
        }
      />
      <Flex gap={2} flexDirection={{ xs: 'column', md: 'row' }}>
        <CompanyLogoCard company={company} />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <Card>
              <CardActionArea
                component={Link}
                to={`/admin/companies/${companyId}/devices`}
              >
                <CardContent>
                  <DetailValue
                    label={_t('statistics.headsets', 'Headsets')}
                    value={company.statistics.headsets}
                  />
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <HasCompanyManagementPermission companyId={company.id}>
            {(!!company.parentCompanyId ||
              hasPermission(UserPermission.MANAGE_COMPANIES)) && (
              <Grid item xs={12} sm={6} md={3}>
                <Card>
                  <CardActionArea
                    component={Link}
                    to={`/admin/companies/${companyId}/scenes`}
                  >
                    <CardContent>
                      <DetailValue
                        label={_t('statistics.scenes', 'Apps')}
                        value={company.statistics.scenes}
                      />
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            )}
            <HasPermission val={UserPermission.MANAGE_COMPANY_VR_BOXES}>
              <Grid item xs={12} sm={6} md={3}>
                <Card>
                  <CardActionArea
                    component={Link}
                    to={`/admin/companies/${companyId}/boxes`}
                  >
                    <CardContent>
                      <DetailValue
                        label={_t('statistics.vrBoxes', 'VR Boxes')}
                        value={company.statistics.vrBoxes}
                      />
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            </HasPermission>
            <HasPermission val={UserPermission.MANAGE_COMPANY_USERS}>
              <Grid item xs={12} sm={6} md={3}>
                <Card>
                  <CardActionArea
                    component={Link}
                    to={`/admin/companies/${companyId}/user-groups`}
                  >
                    <CardContent>
                      <DetailValue
                        label={_t('statistics.userGroups', 'User Groups')}
                        value={company.statistics.userGroups}
                      />
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            </HasPermission>
            <HasPermission val={UserPermission.MANAGE_COMPANY_API_KEYS}>
              <Grid item xs={12} sm={6} md={3}>
                <Card>
                  <CardActionArea
                    component={Link}
                    to={`/admin/companies/${companyId}/api-keys`}
                  >
                    <CardContent>
                      <DetailValue
                        label={_t('statistics.apiKeys', 'API keys')}
                        value={company.statistics.apiKeys}
                      />
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            </HasPermission>
            <HasPermission val={UserPermission.DEBUG}>
              <Grid item xs={12} sm={6} md={3}>
                <Card>
                  <CardActionArea
                    component={Link}
                    to={`/admin/companies/${companyId}/features`}
                  >
                    <CardContent>
                      <DetailValue
                        label={_t('statistics.features', 'Company features')}
                        value={company.statistics.features}
                      />
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            </HasPermission>

            <Grid item xs={12} sm={6} md={3}>
              <CompanyNoteCard company={company}></CompanyNoteCard>
            </Grid>
          </HasCompanyManagementPermission>
        </Grid>
      </Flex>
      <HasCompanyManagementPermission companyId={company.id}>
        <Flex justifyContent="flex-end" mt={4}>
          <Button
            id="company-detail-page-archived-subsidiaries"
            size="large"
            color="primary"
            variant="text"
            style={{ marginRight: '1rem' }}
            onClick={() => setSelectedStatus((prev) => getOtherStatus(prev))}
          >
            {_t(
              `actions.${getOtherStatus(selectedStatus).toLowerCase()}.linkTo`,
              'Archived subsidiaries',
            )}
          </Button>
          <Button
            id="company-detail-page-create-subsidiary"
            size="large"
            color="primary"
            variant="contained"
            startIcon={<AddIcon viewBox="5 5 14 14" />}
            onClick={() => setNewSubsidiaryOpened(true)}
          >
            {_t('actions.newSubsidiary', 'New Subsidiary')}
          </Button>
        </Flex>

        <SubCompanyList
          translationPrefix="companyDetailPage.subsidiaries"
          companyStatus={selectedStatus}
          companyId={company.id}
        />
      </HasCompanyManagementPermission>

      <NewCompanyDialog
        open={newSubSidiaryOpened}
        companyId={company.id}
        streamType={company.streamType}
        onClose={() => setNewSubsidiaryOpened(false)}
      />

      <EditCompanyDialog
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        company={company}
      ></EditCompanyDialog>
    </>
  );
};

export default CompanyDetailPage;

const SubCompanyList = styled(CompanyList)(
  ({ theme: { spacing } }) => `
  margin-top: ${spacing(2)};
`,
);
