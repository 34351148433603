import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import { Box, BoxProps, IconButtonProps, styled } from '@mui/material';
import { PropsWithChildren, useLayoutEffect, useRef, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import { Flex } from '../../../../base/components/Flex';
import { IconButtonFilled } from '../../../../base/components/IconButton';

const SCROLL_SKIP_LENGTH = 100;

interface Props {
  id: string;
  wrapperSx?: BoxProps['sx'];
  sx?: BoxProps['sx'];
  leftButtonSx?: IconButtonProps['sx'];
  rightButtonSx?: IconButtonProps['sx'];
}

export const ScrollableList = ({
  id,
  children,
  sx,
  wrapperSx,
  leftButtonSx,
  rightButtonSx,
}: PropsWithChildren<Props>) => {
  const [leftSkipShow, setLeftSkipShow] = useState(false);
  const [rightSkipShow, setRightSkipShow] = useState(false);
  const scrollRef = useRef<HTMLDivElement | null>(null);

  // ensure right arrow is loaded when necessary on first render
  useLayoutEffect(() => {
    if (
      scrollRef.current &&
      scrollRef.current.scrollWidth - scrollRef.current.offsetWidth > 0
    ) {
      setRightSkipShow(true);
    }
  }, []);

  const handleScrollRight = () => {
    if (scrollRef.current) {
      const maxScroll =
        scrollRef.current.scrollWidth - scrollRef.current.offsetWidth;
      const nextScroll = scrollRef.current.scrollLeft + SCROLL_SKIP_LENGTH;

      scrollRef.current.scrollTo({
        left:
          nextScroll + SCROLL_SKIP_LENGTH < maxScroll ? nextScroll : maxScroll,
      });
    }
  };

  const handleScrollLeft = () => {
    if (scrollRef.current) {
      const nextScroll = scrollRef.current.scrollLeft - SCROLL_SKIP_LENGTH;
      scrollRef.current.scrollTo({
        left: nextScroll - SCROLL_SKIP_LENGTH > 0 ? nextScroll : 0,
      });
    }
  };
  return (
    <AppListWrapper id={id} sx={wrapperSx}>
      <AppList ref={scrollRef} sx={sx}>
        <Waypoint
          horizontal
          onEnter={() => setLeftSkipShow(false)}
          onLeave={() => setLeftSkipShow(true)}
        />
        {children}
        <Waypoint
          horizontal
          onEnter={() => setRightSkipShow(false)}
          onLeave={() => setRightSkipShow(true)}
        />
      </AppList>
      {leftSkipShow ? (
        <IconButtonFilled
          id={`${id}-scroll-left`}
          onClick={handleScrollLeft}
          size="small"
          color="white"
          sx={{
            position: 'absolute',
            top: '50%',
            left: 0,
            transform: 'translateY(-50%)',
            ...(leftButtonSx || {}),
          }}
        >
          <ArrowBackRoundedIcon />
        </IconButtonFilled>
      ) : null}
      {rightSkipShow ? (
        <IconButtonFilled
          id={`${id}-scroll-right`}
          onClick={handleScrollRight}
          size="small"
          color="white"
          sx={{
            position: 'absolute',
            top: '50%',
            right: 0,
            transform: 'translateY(-50%)',
            ...(rightButtonSx || {}),
          }}
        >
          <ArrowForwardRoundedIcon />
        </IconButtonFilled>
      ) : null}
    </AppListWrapper>
  );
};

const AppListWrapper = styled(Flex)`
  align-items: center;
  overflow: hidden;
  position: relative;
`;

const AppList = styled(Box)`
  display: flex;
  overflow: scroll;
  scrollbar-width: none;

  padding-left: 16px;
  padding-right: 16px;
  margin-right: -16px;
  margin-left: -16px;

  ::-webkit-scrollbar {
    display: none;
    width: 0;
    background: transparent;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    border: transparent;
  }
`;
