import {
  Button,
  Card,
  CardContent,
  Grid,
  ListItemText,
  MenuItem,
} from '@mui/material';
import { useFormik } from 'formik';
import { adminState } from '../../../../adminState';
import FormikCompanySelect from '../../../../base/components/form/FormikCompanySelect';
import { FormikFormProvider } from '../../../../base/components/form/FormikForm';
import FormikTextField from '../../../../base/components/form/FormikTextField';
import useActiveCompanies from '../../../../hooks/useActiveCompanies';
import { useTranslationPrefix } from '../../../../hooks/useTranslationPrefix';
import {
  DateOptionPreset,
  getDatesByPreset,
} from '../../../../lib/datePresetHelper';

interface FormData {
  companyId: string;
  datePreset: DateOptionPreset;
}

export interface ReportFormData {
  companyId: string;
  companyName: string;
  datePreset: DateOptionPreset;
  from: Date;
  to: Date;
}

interface Props {
  onSubmit: (values: ReportFormData) => Promise<void> | void;
}

const dateOptions: DateOptionPreset[] = [
  DateOptionPreset.thisWeek,
  DateOptionPreset.lastWeek,
  DateOptionPreset.last14Days,
  DateOptionPreset.thisMonth,
  DateOptionPreset.lastMonth,
  DateOptionPreset.thisQuarter,
  DateOptionPreset.lastQuarter,
  DateOptionPreset.thisYear,
  DateOptionPreset.lastYear,
];

const ReportFilterForm = ({ onSubmit }: Props) => {
  const { _t, t, i18n } = useTranslationPrefix('ReportFilterForm');

  const activeCompanies = useActiveCompanies();

  const handleSubmit = async ({ companyId, datePreset }: FormData) => {
    const dates = getDatesByPreset(datePreset, i18n);
    const company = activeCompanies.find((c) => c.id === companyId);

    await onSubmit({
      companyName: company?.name || `company-${companyId}`,
      datePreset,
      companyId,
      ...dates,
    });
  };

  const formik = useFormik<FormData>({
    initialValues: {
      companyId: adminState.selectedCompanyId.get(),
      datePreset: DateOptionPreset.thisMonth,
    },
    onSubmit: handleSubmit,
  });
  return (
    <FormikFormProvider formik={formik}>
      <Card>
        <CardContent>
          <Grid container spacing={2} p={1}>
            <Grid item xs={12} sm={6} md={4}>
              <FormikCompanySelect name="companyId" />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormikTextField
                name="datePreset"
                variant="outlined"
                select
                fullWidth
              >
                {dateOptions.map((dateOption) => (
                  <MenuItem key={dateOption} value={dateOption}>
                    <ListItemText>
                      {t(`enum.DateOptionPreset.${dateOption}`)}
                    </ListItemText>
                  </MenuItem>
                ))}
              </FormikTextField>
            </Grid>
            <Grid item xs={12} md={4} sx={{ textAlign: 'right' }}>
              <Button
                id="generate-report"
                type="submit"
                variant="contained"
                disabled={formik.isSubmitting}
              >
                {_t('generateReport', 'Generate Report')}
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </FormikFormProvider>
  );
};

export default ReportFilterForm;
