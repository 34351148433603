import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import {
  Card,
  CardContent,
  CardProps,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  Radio,
  TextField,
  styled,
} from '@mui/material';
import { isAfter, subMonths, subYears } from 'date-fns';
import { useMemo, useState } from 'react';
import { BaseTypography } from '../../../../base/components/BaseTypography';
import { Flex } from '../../../../base/components/Flex';
import { useTranslationPrefix } from '../../../../hooks/useTranslationPrefix';
import { ensureDate, formatDate, formatTime } from '../../../../lib/date';
import { UserTrainingReport } from '../useTrainingReport';

interface Props extends Omit<CardProps, 'children'> {
  selectedSceneId: string | null;
  setSelectedSceneId: (sceneid: string | null) => any;
  scenes: { code: string; label: string }[];
  reports: UserTrainingReport[];
  selectedReportId: string | null;
  setSelectedReportId: (sceneid: string | null) => any;
}

const filterOptions = ['all', 'lastMonth', 'lastYear'] as const;
type FilterOptions = typeof filterOptions[number];

const TrainingReportFilterCard = ({
  selectedSceneId,
  setSelectedSceneId,
  scenes,
  reports,
  selectedReportId,
  setSelectedReportId,
  ...cardProps
}: Props) => {
  const { _t } = useTranslationPrefix('TrainingReportPage');
  const [filter, setFilter] = useState<FilterOptions>('all');
  const [filterAnchor, setFilterAnchor] = useState<null | HTMLElement>(null);
  const filterOpen = Boolean(filterAnchor);

  const handleSelectFilter = (filterOption: FilterOptions) => () => {
    setFilter(filterOption);
    setFilterAnchor(null);
  };

  const filteredReports = useMemo(() => {
    const now = Date.now();
    const fromDate =
      filter === 'lastMonth'
        ? subMonths(now, 1)
        : filter === 'lastYear'
        ? subYears(now, 1)
        : new Date(0);

    return reports.filter((r) => isAfter(ensureDate(r.date), fromDate));
  }, [reports, filter]);

  function renderReportItem(report: UserTrainingReport) {
    return (
      <ListItemWrapper key={report.reportId}>
        <FormControlLabel
          sx={{
            width: '100%',
            label: {
              width: '100%',
            },
          }}
          checked={selectedReportId === report.reportId}
          onChange={(e, checked) =>
            checked && setSelectedReportId(report.reportId)
          }
          control={<Radio />}
          componentsProps={{
            typography: {
              sx: { width: '100%' },
            },
          }}
          label={
            <Flex justifyContent="space-between">
              <span>{formatDate(report.date)}</span>
              <span>{formatTime(report.date)}</span>
            </Flex>
          }
        />
      </ListItemWrapper>
    );
  }

  const renderFilterMenu = () => {
    return (
      <>
        <IconButton
          id="time-filter"
          size="small"
          color={filter === 'all' ? 'default' : 'primary'}
          aria-controls={filterOpen ? 'filter-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={filterOpen ? 'true' : undefined}
          onClick={(e) => setFilterAnchor(e.currentTarget)}
        >
          <FilterListRoundedIcon />
        </IconButton>
        <Menu
          id="filter-menu"
          anchorEl={filterAnchor}
          open={filterOpen}
          onClose={() => setFilterAnchor(null)}
          MenuListProps={{
            'aria-labelledby': 'time-filter',
          }}
        >
          <MenuItem
            selected={filter === 'all'}
            onClick={handleSelectFilter('all')}
          >
            {_t('filter.all', 'All')}
          </MenuItem>
          <MenuItem
            selected={filter === 'lastMonth'}
            onClick={handleSelectFilter('lastMonth')}
          >
            {_t('filter.lastMonth', 'Last month')}
          </MenuItem>
          <MenuItem
            selected={filter === 'lastYear'}
            onClick={handleSelectFilter('lastYear')}
          >
            {_t('filter.lastYear', 'Last year')}
          </MenuItem>
        </Menu>
      </>
    );
  };

  return (
    <Card {...cardProps}>
      <CardContent>
        <Flex flexDirection="column" gap={1}>
          <Flex flexDirection="column" gap={1}>
            <BaseTypography variant="body3" component="label" fontWeight={500}>
              {_t('selectApp', 'Aplikace')}
            </BaseTypography>
            <TextField
              id="select-app"
              select
              value={selectedSceneId || ''}
              onChange={(e) => setSelectedSceneId(e.target.value || null)}
              fullWidth
            >
              {scenes.map((scene) => (
                <MenuItem key={scene.code} value={scene.code}>
                  {scene.label}
                </MenuItem>
              ))}
            </TextField>
          </Flex>
          {reports?.length ? (
            <Flex flexDirection="column" gap={0}>
              <Flex justifyContent="space-between" alignItems="center">
                <BaseTypography
                  variant="body3"
                  component="label"
                  fontWeight={500}
                >
                  {_t('trainingByTime', 'Cvičení podle času')}
                </BaseTypography>
                {renderFilterMenu()}
              </Flex>
              <ListWrapper flexDirection="column">
                {filteredReports?.map(renderReportItem)}
              </ListWrapper>
            </Flex>
          ) : undefined}
        </Flex>
      </CardContent>
    </Card>
  );
};

const ListWrapper = styled(Flex)`
  overflow: auto;
  scrollbar-width: thin;
  max-height: calc(100vh - 380px);
`;

const ListItemWrapper = styled(Flex)(({ theme: { palette, spacing } }) => ({
  backgroundColor: `${palette.primary.main}12`,
  borderRadius: 3,
  paddingLeft: spacing(1),
  '&:nth-child(2n)': {
    backgroundColor: 'transparent',
  },
}));

export default TrainingReportFilterCard;
