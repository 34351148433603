import SortIcon from '@mui/icons-material/Sort';
import {
  SortDirection,
  styled,
  TableCell,
  TableSortLabel,
} from '@mui/material';
import humps from 'humps';
import { useContext } from 'react';
import { OrderDirection } from '../../../graphql/graphql-operations';
import { BaseTableContext } from './BaseTableProvider';
import { TableHeadCellProps } from './types';

const BaseTableHeadCell = <T extends {}>({
  width,
  id,
  sortable,
  label,
}: TableHeadCellProps<T>) => {
  const { orderField, orderDirection, setOrder } = useContext(BaseTableContext);

  const isOrdered = humps.camelize(orderField.toString().toLowerCase()) === id;
  const order = isOrdered
    ? (orderDirection.toLowerCase() as SortDirection)
    : undefined;

  const handleSortClick = () => {
    let newOrderDirection: OrderDirection = OrderDirection.ASC;
    if (isOrdered) {
      newOrderDirection =
        order === 'asc' ? OrderDirection.DESC : OrderDirection.ASC;
    }
    setOrder(id, newOrderDirection);
  };
  return (
    <TableCell style={{ width: width || 'auto' }} sortDirection={order}>
      {sortable == null || sortable ? (
        <TableSortLabel
          active={isOrdered}
          direction={order as any}
          IconComponent={SortIcon}
          onClick={handleSortClick}
        >
          {label}
        </TableSortLabel>
      ) : (
        <UnSortableLabel>{label}</UnSortableLabel>
      )}
    </TableCell>
  );
};

export default BaseTableHeadCell;

const UnSortableLabel = styled('span')(({ theme }) => ({
  color: theme.palette.grey[400],
}));
