import { useTheme } from '@mui/material';
import { ResponsiveStyleValue } from '@mui/system';
import { useWindowSize } from 'react-use';

type BreakpointValues = {
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
};
type ResponsiveStyleValueObject<T> = {
  [k in keyof BreakpointValues]?: T | null;
};

const resolveArrayValue = <T>(
  breakpointValues: BreakpointValues,
  width: number,
  values: (T | null | undefined)[],
): T | undefined => {
  const breakpointOrder: (keyof BreakpointValues)[] = [
    'xs',
    'sm',
    'md',
    'lg',
    'xl',
  ];
  if (values.length < 1) {
    return undefined;
  }
  let currentValue = values[0];
  for (let index = 0; index < values.length; index++) {
    const tmpValue = values[index];
    if (typeof tmpValue === 'undefined') {
      continue;
    }
    const breakpoint = breakpointOrder[index];
    if (breakpoint) {
      const minWidth = breakpointValues[breakpoint];
      if (minWidth <= width) {
        currentValue = tmpValue;
      }
    }
  }
  return currentValue ?? undefined;
};

const useResponsiveStyleValue = <T>(
  responsiveValue: ResponsiveStyleValue<T>,
): T | undefined => {
  const { width } = useWindowSize();
  const {
    breakpoints: { values: breakpointValues },
  } = useTheme();

  if (Array.isArray(responsiveValue)) {
    return resolveArrayValue(breakpointValues, width, responsiveValue);
  }

  if (typeof responsiveValue === 'object') {
    const reponsiveValueObject =
      responsiveValue as ResponsiveStyleValueObject<T>;
    const values: (T | null | undefined)[] = [
      reponsiveValueObject['xs'],
      reponsiveValueObject['sm'],
      reponsiveValueObject['md'],
      reponsiveValueObject['lg'],
      reponsiveValueObject['xl'],
    ];
    return resolveArrayValue(breakpointValues, width, values);
  }
  return responsiveValue;
};

export default useResponsiveStyleValue;
