import { Route, Routes } from 'react-router-dom';
import NotFoundDetail from '../../components/NotFoundDetail/NotFoundDetail';
import PatientDetailPage from './PatientDetailPage';
import PatientsPage from './PatientsPage';

const PatientsRoute = () => {
  return (
    <Routes>
      <Route index element={<PatientsPage />} />
      <Route path="/:userId" element={<PatientDetailPage />} />
      <Route path="*" element={<NotFoundDetail />} />
    </Routes>
  );
};

export default PatientsRoute;
