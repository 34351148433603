import { useQuery } from '@apollo/client';
import { useState as useHookState } from '@hookstate/core';
import { clone } from 'ramda';
import { useEffect, useMemo } from 'react';
import { adminState } from '../../../adminState';
import {
  CreateSessionPlaylistItemInput,
  GetApplicationScenesDocument,
  GetPlaylistTemplateQuery,
} from '../../../graphql/graphql-operations';
import useQueryErrorSnackbar from '../../../hooks/useQueryErrorSnackbar';
import { onlyUnique } from '../../../lib/arrayFilters';
import {
  PlaylistParamValueIds,
  PlayListSceneParamsResolution,
  PlaylistSelectedParams,
  PlaylistSelectedScene,
  Scene,
  SceneParametr,
  SceneParametrValue,
  SelectedParam,
} from './PlaylistTypes';
import { usePlaylistPreload } from './preloadPlaylistHook';
import { usePlaylistState } from './usePlaylistState';

const CATEGORY_PARAM_NAME = 'category';

export interface PlaylistData {
  loading: boolean;
  filteredScenes: Scene[];
  selectedScenes: Scene[];
  params: SceneParametr[];
  categories: SceneParametrValue[];
  selectedCategory?: SceneParametrValue;
  setSelectedCategory: (category: SceneParametrValue) => void;
  setFilterParam: (paramId: string, valueId?: string) => void;
  selectedParams: PlaylistSelectedParams;
  enabledParams: PlaylistParamValueIds;
  enabledScenes: string[];
  resolveSceneParams: (sceneId: string) => PlayListSceneParamsResolution;
  selectScene: (selectedScene: PlaylistSelectedScene, idx?: number) => void;
  removeScene: (sceneId: string, idx: number) => void;
  reorder: (fromIdx: number, toIdx: number) => void;
  playlistEmpty: boolean;
  getCurrentPlaylistData: () => CreateSessionPlaylistItemInput[];
  template?: GetPlaylistTemplateQuery;
}

export const usePlaylistData = (): PlaylistData => {
  const {
    selectedCategory,
    selectedParams,
    selectedScenesData,
    setSelectedScenesData,
    setSetSelectedParams,
    setSelectedCategory,
  } = usePlaylistState();

  const companyId = useHookState(adminState.selectedCompanyId);
  const { data, loading, error, refetch } = useQuery(
    GetApplicationScenesDocument,
    {
      variables: {
        filter: {
          enabled: true,
          launchable: true,
          companyId: companyId.value,
        },
      },
    },
  );
  useQueryErrorSnackbar(error, refetch);

  const scenes = useMemo(() => {
    return data?.applicationScenes || [];
  }, [data]) as Scene[];

  //scenes = mockPlaylistScenes;
  const allParams = useMemo(() => getSceneParams(scenes), [scenes]);
  const categories = useMemo(
    () => allParams.find((p) => p.name === CATEGORY_PARAM_NAME)?.values || [],
    [allParams],
  );

  //Load data by prevSessionId query params
  const { scenes: preloadedScenes, template } = usePlaylistPreload();
  useEffect(() => {
    const sceneIds = scenes.map((scene) => scene.id);
    const requiredFilteredParamIds = allParams
      .filter((param) => param.required && param.filtered)
      .map((p) => p.id);
    const existingScenes = preloadedScenes.filter((scene) =>
      sceneIds.includes(scene.sceneId),
    );
    let selectedParams: PlaylistSelectedParams = existingScenes.reduce(
      (result, current) => ({ ...current.params, ...result }),
      {},
    );
    Object.keys(selectedParams).forEach((key) => {
      if (!requiredFilteredParamIds.includes(key)) {
        delete selectedParams[key];
      }
    });
    if (Object.keys(selectedParams).length && existingScenes.length) {
      setSetSelectedParams(selectedParams);
    }
    if (existingScenes.length) {
      setSelectedScenesData(existingScenes);
    }
  }, [
    preloadedScenes,
    scenes,
    allParams,
    setSelectedScenesData,
    setSetSelectedParams,
  ]);

  useEffect(() => {
    if (!selectedCategory && categories.length > 0) {
      setSelectedCategory(categories[0]);
    }
  }, [categories, selectedCategory, setSelectedCategory]);

  const categoryScenes = useMemo(
    () => filterScenesByCategory(scenes, selectedCategory),
    [scenes, selectedCategory],
  );
  const categoryParams = useMemo(
    () => getSceneParams(categoryScenes),
    [categoryScenes],
  );

  const selectedScenes = useMemo(
    () =>
      selectedScenesData.map(({ sceneId, params }) => {
        const scene = scenes.find((scene) => scene.id === sceneId) as Scene;

        return {
          ...scene,
          selectedParams: Object.entries(params)
            .map<SelectedParam | null>(([paramId, valueId]) => {
              const param: SceneParametr | undefined = allParams.find(
                (p) => p.id === paramId,
              );
              const value = param?.values.find((v) => v.id === valueId);
              return value && param && !param.filtered
                ? {
                    paramName: param.name,
                    paramName_t: param.name_t,
                    valueName: value.name,
                    valueName_t: value.name_t,
                  }
                : null;
            })
            .filter((v) => !!v) as SelectedParam[],
        };
      }),
    [selectedScenesData, scenes, allParams],
  );

  const filteredScenes = useMemo(
    () =>
      categoryScenes
        //.filter((scene) => !selectedSceneIds.includes(scene.id))
        .filter(parameterFilter(selectedParams)),
    [categoryScenes, selectedParams],
  );

  const enabledScenes = useMemo(
    () =>
      resolveEnabledScenes({
        scenes: categoryScenes,
        selectedParams: selectedParams,
      }),
    [categoryScenes, selectedParams],
  );

  const enabledParams: PlaylistParamValueIds = useMemo(
    () =>
      resolveEnabledParams({
        scenes: categoryScenes,
        params: categoryParams,
        selectedParams: selectedParams,
        selectedScenesData: selectedScenesData,
      }),
    [categoryScenes, categoryParams, selectedParams, selectedScenesData],
  );

  const setFilterParam = (paramId: string, valueId?: string) => {
    setSetSelectedParams((filter) => ({ ...filter, [paramId]: valueId }));
  };

  const handleSetSelectedCategory = (category: SceneParametrValue) => {
    setSelectedCategory(category);
    setSetSelectedParams({});
    setSelectedScenesData([]);
  };

  const handleSelectScene = (
    selectedScene: PlaylistSelectedScene,
    idx?: number,
  ) => {
    const missingParams = categoryParams
      .filter((p) => p.required && p.filtered)
      .filter((p) => !selectedParams[p.id])
      .map((p) => p.id);
    const addedMissingParams = selectedScene.params
      ? Object.keys(selectedScene.params).filter((p) =>
          missingParams.includes(p),
        )
      : [];
    addedMissingParams.forEach((p) =>
      setFilterParam(p, selectedScene.params[p]),
    );

    setSelectedScenesData((data) => {
      if (typeof idx === 'number') {
        const result = [...data];
        result.splice(idx, 0, selectedScene);
        return result;
      }
      return [...data, selectedScene];
    });
  };

  const handleRemoveScene = (sceneId: string, idx: number) => {
    setSelectedScenesData((data) =>
      data.filter(
        (sceneData, index) => sceneData.sceneId !== sceneId || index !== idx,
      ),
    );
  };

  const handleReorder = (fromIdx: number, toIdx: number) => {
    setSelectedScenesData((data) => {
      const result = [...data];
      const item = result.splice(fromIdx, 1);
      result.splice(toIdx, 0, ...item);
      return result;
    });
  };

  //Check if all required params for scene are there and get them with missing
  const resolveSceneParams = (
    sceneId: string,
  ): PlayListSceneParamsResolution => {
    const scene = scenes.find((s) => s.id === sceneId);
    if (!scene) {
      throw new Error('Failed to find scene by id');
    }
    const requiredParams = scene.parameters.filter((p) => p.required);
    const requiredParamIds = requiredParams.map((p) => p.id);
    const missingParams = requiredParams
      .filter((p) => !selectedParams[p.id])
      .map<SceneParametr>(clone);
    const filledParams: Record<string, string> = Object.entries(selectedParams)
      .filter(
        ([paramId, valueId]) => !!valueId && requiredParamIds.includes(paramId),
      )
      .reduce<PlaylistSelectedParams>(
        (result, [paramId, valueId]) => ({ ...result, [paramId]: valueId }),
        {},
      ) as Record<string, string>;

    return {
      sceneId: sceneId,
      complete: missingParams.length === 0,
      missingParams: missingParams,
      filledParams: filledParams,
    };
  };

  const getCurrentPlaylistData = (): CreateSessionPlaylistItemInput[] => {
    const paramIdNameMap = allParams.reduce<Record<string, string>>(
      (result, param) => ({ ...result, [param.id]: param.name }),
      {},
    );
    const valueIdNameMap = allParams
      .reduce<SceneParametrValue[]>(
        (result, param) => [...result, ...param.values],
        [],
      )
      .reduce<Record<string, string>>(
        (result, val) => ({ ...result, [val.id]: val.name }),
        {},
      );
    const result: CreateSessionPlaylistItemInput[] = selectedScenesData.map(
      (sceneData) => ({
        sceneId: sceneData.sceneId,
        params: Object.entries(sceneData.params).map(([paramId, valueId]) => ({
          paramId,
          valueId,
          name: paramIdNameMap[paramId],
          value: valueIdNameMap[valueId],
        })),
      }),
    );
    return result;
  };

  return {
    loading,
    filteredScenes: filteredScenes,
    selectedScenes: selectedScenes,
    params: categoryParams,
    categories,
    selectedCategory,
    setSelectedCategory: handleSetSelectedCategory,
    setFilterParam,
    selectedParams,
    enabledParams,
    enabledScenes,
    resolveSceneParams,
    selectScene: handleSelectScene,
    removeScene: handleRemoveScene,
    reorder: handleReorder,
    playlistEmpty: selectedScenesData.length === 0,
    getCurrentPlaylistData,
    template,
  };
};

const getSceneParams = (scenes: readonly Scene[]) => {
  const paramsById: Record<string, SceneParametr> = {};
  for (const scene of scenes) {
    for (const param of scene.parameters) {
      if (paramsById[param.id]) {
        paramsById[param.id].values = paramValueUnion(
          paramsById[param.id].values,
          param.values,
        );
      } else {
        paramsById[param.id] = clone(param);
      }
    }
  }
  return Object.values(paramsById);
};

const paramValueUnion = (
  vals1: SceneParametrValue[],
  vals2: SceneParametrValue[],
): SceneParametrValue[] => {
  const vals1ById = vals1.reduce<Record<string, SceneParametrValue>>(
    (result, val) => ({ ...result, [val.id]: val }),
    {},
  );
  const vals2ById = vals2.reduce<Record<string, SceneParametrValue>>(
    (result, val) => ({ ...result, [val.id]: val }),
    {},
  );
  return Object.values({ ...vals1ById, ...vals2ById });
};

const filterScenesByCategory = (
  scenes: Scene[],
  category?: SceneParametrValue,
): Scene[] => {
  if (!scenes) {
    return [];
  }
  let filteredScenes = scenes;
  if (category) {
    filteredScenes = filteredScenes.filter(categoryFilter(category));
  }
  return filteredScenes;
};

const categoryFilter = (category: SceneParametrValue) => (scene: Scene) => {
  const categoryParam = scene.parameters.find(
    (p) => p.name === CATEGORY_PARAM_NAME,
  );
  if (!categoryParam) {
    //App does not have category, show it in all
    return true;
  }
  return !!categoryParam.values.find((val) => val.id === category.id);
};

const parameterFilter = (params: PlaylistSelectedParams) => (scene: Scene) => {
  for (const [paramId, valueId] of Object.entries(params)) {
    if (!valueId) {
      continue;
    }
    const param = scene.parameters.find((p) => p.id === paramId);
    if (!param) {
      return false;
    }
    const value = param.values.find((v) => v.id === valueId);
    if (!value) {
      return false;
    }
  }
  return true;
};

const getParamValueIds = (params: SceneParametr[]): PlaylistParamValueIds => {
  return params.reduce<PlaylistParamValueIds>(
    (result, param) => ({
      ...result,
      [param.id]: param.values.map((val) => val.id),
    }),
    {},
  );
};

const resolveLockedParamIds = ({
  selectedScenesData,
  params,
}: {
  params: SceneParametr[];
  selectedScenesData: PlaylistSelectedScene[];
}): string[] => {
  const lockableParamIds = params
    .filter((p) => p.required && p.filtered)
    .map((p) => p.id);
  let selectedParamIds: string[] = [];
  for (const sceneData of selectedScenesData) {
    selectedParamIds = [
      ...selectedParamIds,
      ...Object.keys(sceneData.params),
    ].filter(onlyUnique);
  }
  return selectedParamIds.filter((paramId) =>
    lockableParamIds.includes(paramId),
  );
};

const resolveEnabledParams = ({
  scenes,
  params,
  selectedParams,
  selectedScenesData,
}: {
  scenes: Scene[];
  params: SceneParametr[];
  selectedParams: PlaylistSelectedParams;
  selectedScenesData: PlaylistSelectedScene[];
}): PlaylistParamValueIds => {
  const lockedParamIds = resolveLockedParamIds({ selectedScenesData, params });

  //merge together with already selected params (selected must be enabled)
  const paramIds = params.map((p) => p.id);
  const result = paramIds.reduce<PlaylistParamValueIds>((result, paramId) => {
    if (lockedParamIds.includes(paramId)) {
      return { ...result };
    }
    // get all available params for filter other then current param.
    const availableScenes = scenes.filter(
      parameterFilter({ ...selectedParams, [paramId]: undefined }),
    );
    const availableParams = getSceneParams(availableScenes);
    const availableParamIds = getParamValueIds(availableParams);

    const available: string[] = availableParamIds[paramId] || [];
    const selected: string[] = selectedParams[paramId]
      ? [selectedParams[paramId]!]
      : [];
    return {
      ...result,
      [paramId]: [...available, ...selected].filter(onlyUnique),
    };
  }, {});

  return result;
};

const resolveEnabledScenes = ({
  scenes,
  selectedParams,
}: {
  scenes: Scene[];
  selectedParams: PlaylistSelectedParams;
}): string[] => {
  const result = scenes.filter((scene) => {
    const missingParams = scene.parameters.filter(
      missingRequiredParamFilter(selectedParams, true),
    );
    return !missingParams.length;
  });

  return result.map((s) => s.id);
};

//Find all scenes where required and filtered (optional) param is not among selected
const missingRequiredParamFilter =
  (selectedParams: PlaylistSelectedParams, onlyFiltered: boolean = false) =>
  (param: SceneParametr) => {
    if (!param.required) {
      return false;
    }
    if (onlyFiltered && !param.filtered) {
      return false;
    }
    if (!!selectedParams[param.id]) {
      return false;
    }
    return true;
  };
