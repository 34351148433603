import { Route, Routes } from 'react-router-dom';
import NotFoundDetail from '../../components/NotFoundDetail/NotFoundDetail';
import Manuals from './Manuals';

const ManualsRoute = () => {
  return (
    <Routes>
      <Route index element={<Manuals />} />
      <Route path="*" element={<NotFoundDetail />} />
    </Routes>
  );
};

export default ManualsRoute;
