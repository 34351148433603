import { useState } from '@hookstate/core';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { formatDurationMinutesShort } from '../../../lib/duration';

interface Props {
  vrSessionId: string;
  name: string;
  duration: number;
  active: boolean;
}

const VrSessionBubble: React.VFC<Props> = (props: Props) => {
  const { name, vrSessionId, duration, active } = props;
  const navigate = useNavigate();
  const state = useState({ mouseDownXLocation: 0, mouseDownTime: 0 });

  const handleMouseDown = (e: React.MouseEvent) => {
    if (e.button === 0) {
      state.batch((state) => {
        state.mouseDownXLocation.set(e.clientX);
        state.mouseDownTime.set(new Date().getTime());
      });
    }
  };

  const handleMouseUp = (e: React.MouseEvent) => {
    if (e.button === 0) {
      const moved = Math.abs(state.mouseDownXLocation.value - e.clientX);
      const timeTaken = new Date().getTime() - state.mouseDownTime.value;
      if (moved < 30 && timeTaken < 250) {
        navigate(`/admin/vr-session/${vrSessionId}`);
      }
    }
  };

  return (
    <StyledBox
      className={clsx(classes.bubble, { [classes.bubbleActive]: active })}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
    >
      {`${name} | `}
      <strong>{formatDurationMinutesShort(duration)}</strong>
    </StyledBox>
  );
};

export default VrSessionBubble;

const PREFIX = 'VrSessionBubble';

const classes = {
  bubble: `${PREFIX}-bubble`,
  bubbleActive: `${PREFIX}-bubbleActive`,
};

const StyledBox = styled(Box)(({ theme: { palette } }) => {
  return {
    [`&.${classes.bubble}`]: {
      margin: '0.2rem',
      backgroundColor: '#FFF',
      cursor: 'pointer',
      color: palette.primary.main,
      padding: '0.3rem 1rem',
      borderRadius: '1rem',
      '&:hover': {
        backgroundColor: '#F5F5F5',
      },
    },
    [`&.${classes.bubbleActive}`]: {
      backgroundColor: palette.secondary.main,
      color: palette.secondary.contrastText,
      '&:hover': {
        backgroundColor: palette.secondary.dark,
      },
    },
  };
});
