import {
  endOfMonth,
  endOfQuarter,
  endOfToday,
  endOfWeek,
  endOfYear,
  endOfYesterday,
  startOfMonth,
  startOfQuarter,
  startOfToday,
  startOfWeek,
  startOfYear,
  startOfYesterday,
  subDays,
  subHours,
  subMonths,
  subYears,
} from 'date-fns';
import { cs, enUS } from 'date-fns/locale';
import { i18n } from 'i18next';

export enum DateOptionPreset {
  today = 'today',
  yesterday = 'yesterday',

  last1Hours = 'last1Hours',
  last24Hours = 'last24Hours',
  last7Days = 'last7Days',
  last14Days = 'last14Days',

  thisWeek = 'thisWeek',
  lastWeek = 'lastWeek',
  thisMonth = 'thisMonth',
  lastMonth = 'lastMonth',
  thisQuarter = 'thisQuarter',
  lastQuarter = 'lastQuarter',
  thisYear = 'thisYear',
  lastYear = 'lastYear',
}

interface DateOptions {
  from: Date;
  to: Date;
}

export const getDatesByPreset = (
  preset: DateOptionPreset,
  i18n: i18n,
): DateOptions => {
  const now = new Date();
  const locale = i18n.language === 'cs' ? cs : enUS;
  const todayEnd = endOfToday();
  switch (preset) {
    case DateOptionPreset.today:
      return {
        from: startOfToday(),
        to: endOfToday(),
      };
    case DateOptionPreset.yesterday:
      return {
        from: startOfYesterday(),
        to: endOfYesterday(),
      };
    case DateOptionPreset.last1Hours:
      return {
        from: subHours(now, 1),
        to: now,
      };
    case DateOptionPreset.last24Hours:
      return {
        from: subDays(now, 1),
        to: now,
      };
    case DateOptionPreset.last7Days:
      return {
        from: subDays(todayEnd, 7),
        to: todayEnd,
      };
    case DateOptionPreset.last14Days:
      return {
        from: subDays(todayEnd, 14),
        to: todayEnd,
      };
    case DateOptionPreset.thisWeek:
      return {
        from: startOfWeek(now, { locale }),
        to: endOfWeek(now, { locale }),
      };
    case DateOptionPreset.lastWeek:
      const lastWeek = subDays(now, 7);
      return {
        from: startOfWeek(lastWeek, { locale }),
        to: endOfWeek(lastWeek, { locale }),
      };
    case DateOptionPreset.thisMonth:
      return {
        from: startOfMonth(now),
        to: endOfMonth(now),
      };
    case DateOptionPreset.lastMonth:
      return {
        from: startOfMonth(subMonths(now, 1)),
        to: endOfMonth(subMonths(now, 1)),
      };
    case DateOptionPreset.thisQuarter:
      return {
        from: startOfQuarter(now),
        to: endOfQuarter(now),
      };
    case DateOptionPreset.lastQuarter:
      return {
        from: startOfQuarter(subMonths(now, 3)),
        to: endOfQuarter(subMonths(now, 3)),
      };
    case DateOptionPreset.thisYear:
      return {
        from: startOfYear(now),
        to: endOfYear(now),
      };
    case DateOptionPreset.lastYear:
      return {
        from: startOfYear(subYears(now, 1)),
        to: endOfYear(subYears(now, 1)),
      };
  }
};
