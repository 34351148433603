import { Box, Button, FormHelperText } from '@mui/material';
import { useEffect, useState } from 'react';
import { BaseDialog } from '../../../base/components/BaseDialog';
import { Flex } from '../../../base/components/Flex';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import { compareByOrdinalAndProp } from '../../../lib/compare';
import PlaylistDialogParam from './PlaylistDialogParam';
import {
  PlayListSceneParamsResolution,
  PlaylistSelectedScene,
} from './PlaylistTypes';

interface Props {
  data: PlayListSceneParamsResolution | null;
  onConfirm: (scene: PlaylistSelectedScene, idx?: number) => void;
  onClose: () => void;
}

const PlaylistAdditionalParamsDialog = ({
  data,
  onConfirm,
  onClose,
}: Props) => {
  const { _t, t } = useTranslationPrefix('PlaylistAdditionalParamsDialog');
  const [selectedParams, setSelectedParams] = useState<Record<string, string>>(
    {},
  );
  const [missingParams, setMissingParams] = useState<Record<string, boolean>>(
    {},
  );

  const open = !!data;

  useEffect(() => {
    if (open) {
      setSelectedParams({});
      setMissingParams({});
    }
  }, [open]);

  const handleChange = (paramId: string) => (valueId?: string) => {
    if (valueId) {
      setSelectedParams((params) => ({ ...params, [paramId]: valueId }));
      setMissingParams((missing) => ({ ...missing, [paramId]: false }));
    }
  };

  const handleConfirm = () => {
    const { sceneId, missingParams, filledParams, idx } = data!;
    const filledIds = Object.keys(selectedParams);
    const missing = missingParams
      .map((d) => d.id)
      .filter((id) => !filledIds.includes(id));
    if (missing.length === 0) {
      onConfirm(
        {
          sceneId: sceneId,
          params: { ...filledParams, ...selectedParams },
        },
        idx,
      );
    } else {
      setMissingParams(
        missing.reduce(
          (result, paramId) => ({ ...result, [paramId]: true }),
          {},
        ),
      );
    }
  };
  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      title={_t('title', 'Required parameters')}
      disableRestoreFocus
      PaperProps={{
        sx: {
          maxWidth: { md: '80vw', xs: '95vw' },
        },
      }}
    >
      {data && (
        <Flex flexDirection="column" marginTop={-2} px={4} gap={3}>
          {data.missingParams
            .sort(compareByOrdinalAndProp('name_t'))
            .map((param) => (
              <Box key={param.id} position="relative">
                <PlaylistDialogParam
                  param={param}
                  required
                  enabledIds={param.values.map((p) => p.id)}
                  selectedId={selectedParams[param.id]}
                  onChange={handleChange(param.id)}
                />
                {missingParams[param.id] && (
                  <FormHelperText error sx={{ textAlign: 'center', pt: 0.5 }}>
                    {_t('required', 'Field is required')}
                  </FormHelperText>
                )}
              </Box>
            ))}
          <Flex justifyContent="space-around" pt={5} gap={2}>
            <Button
              id="playlist-additional-params-cancel"
              color="primary"
              onClick={onClose}
              variant="outlined"
            >
              {t('common.cancel', 'Cancel')}
            </Button>
            <Button
              id="playlist-additional-params-confirm"
              color="primary"
              onClick={handleConfirm}
            >
              {_t('confirm', 'Confirm')}
            </Button>
          </Flex>
        </Flex>
      )}
    </BaseDialog>
  );
};

export default PlaylistAdditionalParamsDialog;
