import { Route, Routes } from 'react-router-dom';
import NotFoundPage from '../NotFoundPage/NotFoundPage';
import CompanyDevicesPage from './CompanyDevicesPage';
import LaunchSessionOnDevicePage from './LaunchSessionOnDevicePage';

const DevicesRoute = () => {
  return (
    <Routes>
      <Route index element={<CompanyDevicesPage />} />
      <Route
        path="/launch/:playlistStorageKey"
        element={<LaunchSessionOnDevicePage />}
      />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default DevicesRoute;
