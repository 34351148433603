import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Button } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import HasPermission from '../../../base/components/UserProvider/HasPermission';
import {
  ApplicationSceneFragmentFragment,
  UploadApplicationSceneManualDocument,
  UserPermission,
} from '../../../graphql/graphql-operations';
import { useMutationWithSnackbar } from '../../../hooks/useMutationWithSnackbar';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import { SectionPaper } from '../../components/SectionPaper';

interface Props {
  scene: ApplicationSceneFragmentFragment;
  applicationVersionId: string;
}

const ApplicationSceneManualSection = ({
  scene,
  applicationVersionId,
}: Props) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const { _t } = useTranslationPrefix('applications.scene.manual');
  const [upload] = useMutationWithSnackbar(
    'applications.scene.manual.mutation',
    UploadApplicationSceneManualDocument,
  );

  const manualUrl = scene.manualUrl;
  useEffect(() => {
    //Must be done this way or if it changes iframe adds url to browser history and breaks back button
    const iframe = iframeRef.current;
    if (iframe) {
      iframe.contentWindow?.location.replace(manualUrl || '');
    }
  }, [iframeRef, manualUrl]);

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file?.type !== 'application/pdf') {
      return;
    }
    upload({
      variables: {
        input: {
          applicationSceneId: scene.id,
          applicationVersionId: applicationVersionId,
        },
        manual: file,
      },
    });
  };

  return (
    <SectionPaper
      title={_t('title', 'Instructions')}
      sx={{ marginTop: 2 }}
      actions={
        <HasPermission val={UserPermission.MANAGE_SCENES}>
          <label htmlFor="manual-upload-file">
            <input
              accept="application/pdf"
              id="manual-upload-file"
              type="file"
              style={{ display: 'none' }}
              onChange={handleUpload}
            />
            <Button
              id="manual-upload-file-button"
              component="span"
              startIcon={<FileUploadIcon />}
            >
              {_t('upload', 'Upload new')}
            </Button>
          </label>
        </HasPermission>
      }
    >
      {scene.manualUrl ? (
        <iframe
          ref={iframeRef}
          title={scene.name_t}
          frameBorder="0"
          width="100%"
          style={{ aspectRatio: '4/3' }}
        />
      ) : (
        _t('notFound', 'No manual found for current language')
      )}
    </SectionPaper>
  );
};

export default ApplicationSceneManualSection;
