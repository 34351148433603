interface VersionObject {
  version: string;
}

type Direction = 'asc' | 'desc';

export const compareVersions =
  (dir: Direction = 'asc') =>
  (vo1: VersionObject, vo2: VersionObject) => {
    const modifier = dir === 'asc' ? 1 : -1;

    try {
      const v1 = vo1.version.split('.').map((i) => Number.parseInt(i));
      const v2 = vo2.version.split('.').map((i) => Number.parseInt(i));
      const length = Math.min(v1.length, v2.length);

      for (let index = 0; index < length; index++) {
        const result = v1[index] - v2[index];
        if (result !== 0) {
          return result * modifier;
        }
        if (index === length - 1) {
          return (v1.length - v2.length) * modifier;
        }
      }
    } catch (err) {
      console.error(
        'Failed to compare versions ' + vo1?.version + ' ' + vo2?.version,
        err,
      );
    }
    return vo1?.version.localeCompare(vo2?.version) * modifier;
  };
