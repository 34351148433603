import { useQuery } from '@apollo/client';
import { Button } from '@mui/material';
import { Formik } from 'formik';
import { useState } from 'react';
import { adminState } from '../../../adminState';
import FormikAutocomplete from '../../../base/components/form/FormikAutocomplete';
import FormikForm from '../../../base/components/form/FormikForm';
import FormikTextField from '../../../base/components/form/FormikTextField';
import { useUser } from '../../../base/components/UserProvider/useUserHook';
import {
  CreateTrainingAssigmentForUserDocument,
  GetPlaylistTemplatesDocument,
  GetUsersAdminDocument,
  PlaylistTemplateFilterField,
  TrainingAssignmentUsersInput,
  UserFilterField,
} from '../../../graphql/graphql-operations';
import { useMutationWithSnackbar } from '../../../hooks/useMutationWithSnackbar';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import { TopBar } from '../../components/TopBar';

interface FormValues {
  validForDays?: number;
  gracePeriodDays?: number;
  validTo?: string | null;
  playlistTemplate: {
    id: string;
    label: string;
  } | null;
  user: {
    id: string;
    label: string;
  } | null;
}

const DebugAssignmentsPage = () => {
  const { _t } = useTranslationPrefix('debugTrainingAssigments');
  const [userSearch, setUserSearch] = useState('');
  const [templateSearch, setTemplateSearch] = useState('');
  const { user } = useUser();

  const { data: userData } = useQuery(GetUsersAdminDocument, {
    variables: {
      first: 10,
      filter: {
        companyId: adminState.selectedCompanyId.get(),
      },
      filterBy: {
        field: UserFilterField.LAST_NAME,
        query: userSearch,
      },
    },
    skip: userSearch.length < 1,
  });
  const { data: templateData } = useQuery(GetPlaylistTemplatesDocument, {
    variables: {
      first: 10,
      filterBy: {
        field: PlaylistTemplateFilterField.NAME,
        query: templateSearch,
      },
    },
  });
  const [createTrainingAssigmentForUser] = useMutationWithSnackbar(
    _t('trainingAssignment.create'),
    CreateTrainingAssigmentForUserDocument,
  );

  const onSubmit = (values: FormValues) => {
    if (!values.user?.id) {
      return;
    }
    if (!values.playlistTemplate?.id) {
      return;
    }
    if (!values.gracePeriodDays) {
      return;
    }
    if (!values.validForDays) {
      return;
    }
    const input: TrainingAssignmentUsersInput = {
      userIds: [values.user.id],
      playlistTemplateId: values.playlistTemplate.id,
      gracePeriodDays: values.gracePeriodDays,
      validForDays: values.validForDays,
      validTo: values.validTo,
    };
    createTrainingAssigmentForUser({
      variables: {
        input,
      },
    });
  };
  return (
    <>
      <TopBar leftSideText={_t('title', 'Assignments')} />
      <Formik<FormValues>
        initialValues={{
          gracePeriodDays: 14,
          validForDays: 356,
          validTo: '',
          playlistTemplate: null,
          user: null,
        }}
        onSubmit={onSubmit}
      >
        <FormikForm>
          <FormikTextField
            type="number"
            label={_t('validForDays', 'validForDays')}
            name="validForDays"
          />

          <FormikTextField
            type="number"
            label={_t('gracePeriodDays', 'GracePeriodDays')}
            name="gracePeriodDays"
          />

          <FormikTextField
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            label={_t('validTo', 'ValidTo')}
            name="validTo"
          />

          <FormikAutocomplete<{
            label: string;
            id: string;
          }>
            name="user"
            inputProps={{
              label: _t('user', 'User'),
            }}
            getOptionLabel={(value) => value.label || ''}
            isOptionEqualToValue={(option, value) => option.id === value?.id}
            onInputChange={(event, newValue) => setUserSearch(newValue)}
            options={(userData?.users?.nodes || [user!]).map((user) => ({
              id: user.id,
              label: user.lastName || '',
            }))}
          />

          <FormikAutocomplete<{
            label: string;
            id: string;
          }>
            name="playlistTemplate"
            getOptionLabel={(value) => value.label}
            isOptionEqualToValue={(option, value) => option.id === value?.id}
            onInputChange={(event, newValue) => setTemplateSearch(newValue)}
            inputProps={{
              label: _t('playlistTemplate', 'PlaylistTemplate'),
            }}
            options={(templateData?.playlistTemplates?.nodes || []).map(
              (playlistTemplate) => ({
                id: playlistTemplate.id,
                label: playlistTemplate.name || '',
              }),
            )}
          />

          <Button id="submit-assignment" type="submit">
            submit
          </Button>
        </FormikForm>
      </Formik>
    </>
  );
};

export default DebugAssignmentsPage;
