import DateRangeIcon from '@mui/icons-material/DateRange';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import { CardContent, Divider } from '@mui/material';
import { Box } from '@mui/system';
import { BaseCard } from '../../../base/components/BaseCard';
import { BaseTypography } from '../../../base/components/BaseTypography';
import { Flex } from '../../../base/components/Flex';
import { IconButtonFilled } from '../../../base/components/IconButton';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import { formatDate } from '../../../lib/date';

export type SceneListItemStatus = 'completed' | 'runnable' | 'waiting';

interface Scene {
  name_t: string;
}

interface SceneParam {
  name: string;
  value_t: string;
}

interface Props {
  idx: number;
  scene: Scene;
  params: readonly SceneParam[];
  completionDate?: string | Date;
  status?: SceneListItemStatus;
  onPlayClick?: () => void;
}

const SceneListItem = ({
  idx,
  status,
  onPlayClick,
  scene,
  params,
  completionDate,
}: Props) => {
  const { _t } = useTranslationPrefix('SceneListItem');
  return (
    <BaseCard disabled={status === 'waiting'}>
      <CardContent>
        <Flex alignItems="center" overflow="hidden">
          <Flex
            sx={{
              overflowX: 'hidden',
              overflowY: 'visible',
              alignItems: { xs: 'left', sm: 'center' },
              flexDirection: { xs: 'column', sm: 'row' },
              flexGrow: 1,
            }}
          >
            <Flex
              sx={{
                alignItems: 'center',
                flexBasis: 50,
                flexGrow: 1,
                overflowX: 'clip',
                overflowY: 'visible',
                minWidth: '15ch',
              }}
            >
              <BaseTypography
                variant="h4"
                p={2.5}
                textAlign="center"
                color={status === 'waiting' ? 'grey.300' : 'text.primary'}
              >
                {(idx + 1).toLocaleString('cs-CZ', { minimumIntegerDigits: 2 })}
              </BaseTypography>
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
              ></Divider>

              <Box sx={{ overflowX: 'hidden', overflowY: 'visible' }}>
                <BaseTypography
                  variant="h5"
                  color={status === 'waiting' ? 'grey.300' : 'text.primary'}
                  sx={{
                    pl: 3,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {scene.name_t}
                </BaseTypography>
                {(completionDate || status === 'waiting') && (
                  <Flex alignItems="center" mt={0.5} pl={3}>
                    <DateRangeIcon
                      sx={{
                        ml: '-2px',
                        mr: 1,
                        fontSize: '1.25rem',
                        color:
                          status === 'waiting' ? 'grey.300' : 'text.primary',
                      }}
                    />
                    {completionDate ? (
                      <Box color="grey.500">
                        {_t('completed', 'Completed')}:{' '}
                        <b>{formatDate(completionDate)}</b>
                      </Box>
                    ) : (
                      <Box color="grey.300">{_t('locked', 'Locked')}</Box>
                    )}
                  </Flex>
                )}
              </Box>
            </Flex>
            <Flex
              flexBasis={30}
              flexGrow={1}
              gap={1}
              py={1}
              pl={1}
              pr={{ xs: 1, sm: 4 }}
              justifyContent={{ xs: 'center', sm: 'end' }}
              flexWrap="wrap"
            >
              {params.map((p) => (
                <BaseTypography
                  variant="caption"
                  key={p.name}
                  sx={{
                    py: 0.5,
                    px: 1.5,
                    backgroundColor: 'grey.100',
                    borderRadius: '200px',
                    color: status === 'waiting' ? 'grey.600' : 'text.primary',
                  }}
                >
                  {p.value_t}
                </BaseTypography>
              ))}
            </Flex>
          </Flex>
          {(status === 'runnable' || status === 'completed') && (
            <IconButtonFilled
              color="primary"
              id={`play-from-${idx}`}
              onClick={onPlayClick}
              sx={{
                ml: 'auto',
                mr: 2,
                cursor: 'pointer',
              }}
            >
              <PlayArrowOutlinedIcon />
            </IconButtonFilled>
          )}
          {status === 'waiting' && (
            <IconButtonFilled
              id={`locked-${idx}`}
              disabled
              sx={{
                ml: 'auto',
                mr: 2,
                cursor: 'pointer',
              }}
            >
              <LockOutlinedIcon />
            </IconButtonFilled>
          )}
        </Flex>
      </CardContent>
    </BaseCard>
  );
};
export default SceneListItem;
