import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Box, CardContent, Divider } from '@mui/material';
import { useState } from 'react';
import { useInterval } from 'react-use';
import { BaseTypography } from '../../../../base/components/BaseTypography';
import { Flex } from '../../../../base/components/Flex';
import { VrSessionFragmentFragment } from '../../../../graphql/graphql-operations';
import { useTranslationPrefix } from '../../../../hooks/useTranslationPrefix';
import { formatDuration } from '../../../../lib/duration';
import SessionFloatingCard from './SessionFloatingCard';

interface Props {
  session?: VrSessionFragmentFragment;
}

const SessionStatus = ({ session }: Props) => {
  const { _t } = useTranslationPrefix('SessionDetailPage.status');
  const [now, setNow] = useState(Date.now());
  useInterval(() => {
    setNow(Date.now());
  }, 1000);

  const currentSessionItem = session?.playlist.find(
    (item) => item.startedAt && !item.endedAt,
  );

  const getSessionDuration = (startedAt?: string, endedAt?: string | null) => {
    if (!startedAt) {
      return null;
    }

    const startedAtTime = new Date(startedAt).getTime();
    const endedAtTime = endedAt ? new Date(endedAt).getTime() : now;
    const duration = formatDuration(endedAtTime - startedAtTime, '\u00a0');
    return duration;
  };

  const getSessionItemDuration = (
    startedAt?: string | null,
    endedAt?: string | null,
  ) => {
    if (!startedAt) {
      return null;
    }

    const startedAtTime = new Date(startedAt).getTime();
    const endedAtTime = endedAt ? new Date(endedAt).getTime() : now;
    const duration = formatDuration(endedAtTime - startedAtTime, '\u00a0');

    return duration;
  };

  return (
    <SessionFloatingCard>
      <CardContent>
        <Flex alignItems="center">
          <AccessTimeIcon
            sx={{ color: 'text.primary', fontSize: '1.5rem', mr: 2 }}
          />
          {!session?.endedAt && (
            <>
              <Box display={{ xs: 'none', sm: 'block' }}>
                <BaseTypography color="grey.500" variant="body3">
                  {_t('currentExcercise', 'Current excercise')}
                </BaseTypography>
                <BaseTypography fontWeight="bold">
                  {getSessionItemDuration(
                    currentSessionItem?.startedAt,
                    currentSessionItem?.endedAt,
                  )}
                </BaseTypography>
              </Box>
              <Divider
                orientation="vertical"
                flexItem
                variant="middle"
                sx={{ mx: 2, display: { xs: 'none', sm: 'initial' } }}
              />
            </>
          )}
          <Box>
            <BaseTypography color="grey.500" variant="body3">
              {_t('total', 'Total')}
            </BaseTypography>
            <BaseTypography fontWeight="bold" minWidth="7ch">
              {getSessionDuration(session?.startedAt, session?.endedAt)}
            </BaseTypography>
          </Box>
        </Flex>
      </CardContent>
    </SessionFloatingCard>
  );
};

export default SessionStatus;
