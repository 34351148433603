import { useMutation } from '@apollo/client';
import { useState } from '@hookstate/core';
import ArchiveIcon from '@mui/icons-material/Archive';
import { Button, styled } from '@mui/material';
import { Field, Formik } from 'formik';
import { TextField as MuiTextField } from 'formik-mui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BaseDialog } from '../../../base/components/BaseDialog';
import ConfirmDialog from '../../../base/components/ConfirmDialog/ConfirmDialog';
import { Flex } from '../../../base/components/Flex';
import FormikForm from '../../../base/components/form/FormikForm';
import {
  EditPatientAdminDocument,
  SuspendUsersDocument,
} from '../../../graphql/graphql-operations';
import useMutationSnackbar from '../../../hooks/useMutationSnackbar';
import { getPatientValidationSchema } from '../../components/NewPatientDialog/NewPatientForm';

interface Props {
  open: boolean;
  onClose: () => void;
  patientId: string;
  patientName: string;
}

interface NewPatientForm {
  patientName: string;
}

const EditPatientDialog: React.VFC<Props> = (props: Props) => {
  const { open, onClose, patientId, patientName } = props;
  const { t } = useTranslation();
  const state = useState({ archiveConfirmOpen: false });

  const editUserHandler = useMutationSnackbar('editUser', {
    translationKey: 'editPatientDialog.edit',
    onSuccessfullyCompleted: onClose,
  });

  const [editUser] = useMutation(EditPatientAdminDocument, editUserHandler);

  const handleFormSubmit = async ({ patientName }: NewPatientForm) => {
    await editUser({
      variables: {
        editUserInput: { lastName: patientName, userId: patientId },
      },
    });
  };

  const suspendUserHandler = useMutationSnackbar('suspendUsers', {
    translationKey: 'editPatientDialog.suspend',
  });

  const [suspendUsers] = useMutation(SuspendUsersDocument, {
    ...suspendUserHandler,
  });

  const handleArchiveClick = () => {
    suspendUsers({
      variables: { suspendUsersInput: { userIds: [patientId] } },
    });
  };

  return (
    <>
      <BaseDialog
        open={open}
        title={t('editPatientDialog.title', 'Edit patient')}
        onClose={onClose}
      >
        <Formik<NewPatientForm>
          initialValues={{ patientName: patientName }}
          onSubmit={handleFormSubmit}
          validationSchema={getPatientValidationSchema(t)}
        >
          {({ isSubmitting }) => (
            <FormikForm>
              <Field
                component={TextField}
                name="patientName"
                variant="outlined"
                label={t('editPatientDialog.form.patientName', 'Patient name')}
              />
              <Flex justifyContent="center">
                <Button
                  id="edit-patient-dialog-archive"
                  variant="text"
                  size="large"
                  color="error"
                  onClick={() => state.archiveConfirmOpen.set(true)}
                  startIcon={<ArchiveIcon />}
                >
                  {t('editPatientDialog.archive', 'Archive')}
                </Button>
              </Flex>
              <Flex justifyContent="space-between">
                <Button
                  id="edit-patient-dialog-cancel"
                  size="large"
                  color="primary"
                  onClick={onClose}
                >
                  {t('common.cancel', 'Cancel')}
                </Button>
                <Button
                  id="edit-patient-dialog-confirm"
                  type="submit"
                  size="large"
                  color="primary"
                  variant="contained"
                  disabled={isSubmitting}
                >
                  {t('editPatientDialog.save', 'Save changes')}
                </Button>
              </Flex>
            </FormikForm>
          )}
        </Formik>
      </BaseDialog>
      <ConfirmDialog
        open={state.archiveConfirmOpen.get()}
        onClose={() => state.archiveConfirmOpen.set(false)}
        onConfirm={handleArchiveClick}
      />
    </>
  );
};

const TextField = styled(MuiTextField)(({ theme }) => ({
  width: '320px',
}));

export default EditPatientDialog;
