import { getOperationName } from '@apollo/client/utilities';
import { useHookstate } from '@hookstate/core';
import { Button, useTheme } from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { adminState } from '../../../../adminState';
import { BaseDialog } from '../../../../base/components/BaseDialog';
import { Flex } from '../../../../base/components/Flex';
import FormikForm from '../../../../base/components/form/FormikForm';
import FormikTextField from '../../../../base/components/form/FormikTextField';
import {
  GetVrDevicesDocument,
  RegisterVrDeviceDocument,
} from '../../../../graphql/graphql-operations';
import { useMutationWithSnackbar } from '../../../../hooks/useMutationWithSnackbar';
import {
  TFunctionPrefixed,
  useTranslationPrefix,
} from '../../../../hooks/useTranslationPrefix';
import { handleValidationFieldError } from '../../../../lib/handleValidationFieldError';

interface RegisterDeviceForm {
  name: string;
  registrationCode: string;
}

interface Props {
  open: boolean;
  onClose: () => void;
}

const RegisterDeviceDialog = ({ open, onClose }: Props) => {
  const { _t, t } = useTranslationPrefix('RegisterDeviceDialog');
  const { palette } = useTheme();
  const companyId = useHookstate(adminState.selectedCompanyId);
  const [registerDevice] = useMutationWithSnackbar(
    'RegisterDeviceDialog.mutation',
    RegisterVrDeviceDocument,
    {
      refetchQueries: [getOperationName(GetVrDevicesDocument)!],
    },
  );

  const handleSubmit = async (
    values: RegisterDeviceForm,
    formikHelpers: FormikHelpers<RegisterDeviceForm>,
  ) => {
    try {
      await registerDevice({
        variables: {
          deviceRegistration: {
            companyId: companyId.get(),
            color: palette.primary.main,
            name: values.name,
            registrationCode: values.registrationCode.toLocaleUpperCase(),
          },
        },
      });
      onClose();
    } catch (error: any) {
      handleValidationFieldError({ error, formikHelpers, t });
    }
  };

  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      title={_t('title', 'Register headset')}
      disableEnforceFocus
    >
      <>
        <Formik<RegisterDeviceForm>
          initialValues={{
            name: '',
            registrationCode: '',
          }}
          onSubmit={handleSubmit}
          validationSchema={getValidationSchema(_t)}
          initialErrors={{ name: 'required', registrationCode: 'required' }}
        >
          {({ isSubmitting }) => (
            <FormikForm>
              <FormikTextField
                variant="outlined"
                name="name"
                label={_t('form.name', 'Headset name')}
                inputProps={{
                  autoComplete: 'off',
                }}
              />
              <FormikTextField
                variant="outlined"
                name="registrationCode"
                label={_t('form.registrationCode', 'Code')}
                inputProps={{
                  autoComplete: 'off',
                  style: { textTransform: 'uppercase' },
                }}
              />
              <Flex justifyContent="center" mt={1}>
                <Button
                  id="register-vr-device-confirm"
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={isSubmitting}
                >
                  {_t('form.submit', 'Register')}
                </Button>
              </Flex>
            </FormikForm>
          )}
        </Formik>
      </>
    </BaseDialog>
  );
};

export default RegisterDeviceDialog;

const getValidationSchema = (_t: TFunctionPrefixed) =>
  Yup.object({
    name: Yup.string().required(
      _t('form.nameRequired', 'Device name is required'),
    ),
    registrationCode: Yup.string()
      .required(
        _t(
          'registerVrDevice.form.registrationCodeRequired',
          'Registration code is required',
        ),
      )
      .length(
        6,
        _t(
          'registerVrDevice.form.registrationCodeLength',
          'Registration code must have exactly 6 characters',
        ),
      ),
  });
