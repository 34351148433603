import { useQuery } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { Button } from '@mui/material';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useUser } from '../../../base/components/UserProvider/useUserHook';
import {
  AddTrainingAssigmentsForUserDocument,
  GetUserTrainingAssignmentsDocument,
  PlaylistTemplateFilterField,
  PlaylistTemplateListFragmentFragment,
  StreamType,
  TrainingAssignmentsUserInput,
  UserPermission,
} from '../../../graphql/graphql-operations';
import { useMutationWithSnackbar } from '../../../hooks/useMutationWithSnackbar';
import useQueryErrorSnackbar from '../../../hooks/useQueryErrorSnackbar';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import { onlyUnique } from '../../../lib/arrayFilters';
import {
  MEDICAL_ASSIGNMENT_GRACE_PERIOD_DEFAULT,
  MEDICAL_ASSIGNMENT_VALID_FOR_DEFAULT,
  TRAINING_ASSIGNMENT_GRACE_PERIOD_DEFAULT,
  TRAINING_ASSIGNMENT_VALID_FOR_DEFAULT,
} from '../../../lib/constants';
import useStreamType from '../../../lib/useStreamType';
import ContentWrapper from '../../components/ContentWrapper';
import DebugTrainingAssignmentDialog, {
  DebugTrainingAssignmentFormValues,
} from '../../components/Debug/DebugTrainingAssignmentDialog';
import { Loader } from '../../components/Loader';
import PageHeader from '../../components/PageHeader';
import { SearchField } from '../../components/SearchField';
import PlaylistTemplatesList from '../PlaylistTemplates/components/PlaylistTemplatesList';

const UserAssignTrainingPage = () => {
  const streamType = useStreamType();
  const { _t } = useTranslationPrefix('UserAssignTrainingPage');
  const { userId } = useParams<{ userId: string }>();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [debugDialogOpen, setDebugDialogOpen] = useState(false);
  const [query, setQuery] = useState('');
  const navigate = useNavigate();
  const { hasPermission } = useUser();

  const [createAssignmnet, { loading: submitting }] = useMutationWithSnackbar(
    'UserAssignTrainingPage.mutation',
    AddTrainingAssigmentsForUserDocument,
    {
      onCompleted: () => navigate(`/users/${userId}`),
      refetchQueries: [getOperationName(GetUserTrainingAssignmentsDocument)!],
    },
  );

  const {
    data: currentTrainings,
    loading,
    error,
    refetch,
  } = useQuery(GetUserTrainingAssignmentsDocument, {
    variables: {
      userId: userId,
    },
    fetchPolicy: 'cache-and-network',
  });
  useQueryErrorSnackbar(error, refetch);

  const currentTrainingIds = currentTrainings?.userTrainingAssignments
    .map((ta) => ta.playlistTemplate.id)
    .filter(onlyUnique);

  const handleTemplateClick = (t: PlaylistTemplateListFragmentFragment) => {
    setSelectedIds((selected) => {
      const id = t.id;
      return selected.includes(id)
        ? selected.filter((s) => s !== id)
        : [...selected, id];
    });
  };

  const handleConfirm = async (vals?: DebugTrainingAssignmentFormValues) => {
    const input: TrainingAssignmentsUserInput = {
      userId: userId!,
      playlistTemplateIds: selectedIds,
      gracePeriodDays:
        streamType === StreamType.MEDICAL
          ? MEDICAL_ASSIGNMENT_GRACE_PERIOD_DEFAULT
          : TRAINING_ASSIGNMENT_GRACE_PERIOD_DEFAULT,
      validForDays:
        streamType === StreamType.MEDICAL
          ? MEDICAL_ASSIGNMENT_VALID_FOR_DEFAULT
          : TRAINING_ASSIGNMENT_VALID_FOR_DEFAULT,

      ...vals,
      validTo: vals?.validTo?.toISOString() || null,
    };
    await createAssignmnet({
      variables: {
        input: input,
      },
    });
    setDebugDialogOpen(false);
  };

  return loading ? (
    <Loader />
  ) : (
    <ContentWrapper>
      <PageHeader title={_t('title', 'Assign training')} back>
        <Button
          id="assign-training"
          variant="contained"
          color="primary"
          disabled={!selectedIds.length || submitting}
          onClick={() =>
            hasPermission(UserPermission.DEBUG)
              ? setDebugDialogOpen(true)
              : handleConfirm()
          }
        >
          {!selectedIds.length
            ? _t('selectFirst', 'Select training')
            : _t('finish', 'Finish')}
        </Button>
        <SearchField value={query} onValueChange={setQuery} />
      </PageHeader>
      <PlaylistTemplatesList
        ignoreIds={currentTrainingIds}
        selectedIds={selectedIds}
        onClick={handleTemplateClick}
        searchConfig={{
          filterBy: PlaylistTemplateFilterField.NAME,
          filterValue: query,
        }}
      />
      <DebugTrainingAssignmentDialog
        open={debugDialogOpen}
        onConfirm={handleConfirm}
        onClose={() => setDebugDialogOpen(false)}
      />
    </ContentWrapper>
  );
};

export default UserAssignTrainingPage;
