import { SvgIcon, SvgIconProps } from '@mui/material';

const LyingIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M21.89 15.9321C21.7416 15.6035 21.4502 15.3014 21.0951 15.0841L21.111 15.0523C19.5741 14.2203 16.76 12.5721 15.9333 12.0898C15.3874 11.7666 14.7196 11.7825 14.1897 12.1322L11.842 13.6638L7.76655 9.95939H7.76125C7.44327 9.68381 7.0352 9.53012 6.61123 9.54602C6.18726 9.56191 5.78979 9.7421 5.50891 10.0548C5.22803 10.3622 5.07964 10.7755 5.10614 11.1995C5.13264 11.6182 5.32343 12.0156 5.6361 12.3018L5.6149 12.3283L5.6626 12.3707L4.53378 15.2696C4.47549 15.418 4.46489 15.5664 4.48079 15.7095C4.50199 16.1652 4.86236 16.5362 5.32873 16.5627L9.54192 16.7694C9.8281 16.7853 10.0878 16.6581 10.268 16.4567C10.7979 16.7482 11.4498 16.7376 11.9691 16.3984L15.1065 14.3475C16.336 15.0682 18.6096 16.3878 19.9822 17.1298L19.9981 17.098C20.3478 17.2782 20.7294 17.3682 21.0686 17.3364C21.4396 17.2994 21.7363 17.1351 21.8847 16.8701C22.0384 16.6051 22.0384 16.2659 21.8847 15.9268L21.89 15.9321ZM8.482 14.9198L6.68013 14.835L7.11999 13.6956L8.482 14.9198Z"
        fill="currentColor"
      />
      <path
        d="M5.49302 6.85911C4.91536 5.97938 3.73885 5.7356 2.85911 6.31325C1.97938 6.88561 1.7356 8.06213 2.31325 8.93656C2.89091 9.811 4.06743 10.0601 4.94716 9.48242C5.8269 8.91006 6.07068 7.73355 5.49302 6.85381V6.85911Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default LyingIcon;
