import DateRangeIcon from '@mui/icons-material/DateRange';
import { Button, CardContent, LinearProgress, styled } from '@mui/material';
import clsx from 'clsx';
import { differenceInYears } from 'date-fns';
import { FC } from 'react';
import { BaseTypography } from '../../../../base/components/BaseTypography';
import { Flex } from '../../../../base/components/Flex';
import { ImageBackground } from '../../../../base/components/ImageBackground';
import { OutlinedCard } from '../../../../base/components/OutlinedCard';
import TermDays from '../../../../base/components/TimeFormat/TermDays';
import { UserTrainingFragmentFragment } from '../../../../graphql/graphql-operations';
import { useTranslationPrefix } from '../../../../hooks/useTranslationPrefix';
import TrainingStatusTag from '../TrainingStatusTag';

type Training = UserTrainingFragmentFragment;

interface Props {
  training: Training;
}

const MAX_DUE_DATE_YEARS = 10;

const UserTrainingHeaderCard: FC<Props> = ({ training }) => {
  const { _t } = useTranslationPrefix('UserTrainingCard');
  const pastdue = new Date(training.completion.dueDate) < new Date();
  const completionValue =
    100 * (training.completion.itemsCompleted / training.completion.itemsTotal);
  const completed = completionValue === 100;
  const imageUrl = training.playlistTemplate.items.find((i) => i.scene.imageUrl)
    ?.scene?.imageUrl;

  const renderCompletion = () => {
    return (
      <ValueWrapper>
        <span>{_t('finished', 'Finished')}:</span>
        <span className={clsx(classes.value)}>
          {training.completion.itemsCompleted}/{training.completion.itemsTotal}
        </span>
      </ValueWrapper>
    );
  };

  const renderDueDate = () => {
    if (
      differenceInYears(new Date(training.completion.dueDate), new Date()) >
      MAX_DUE_DATE_YEARS
    ) {
      return null;
    }
    return (
      <ValueWrapper>
        <DateRangeIcon />
        <span className={classes.pastDueLabel}>
          {pastdue ? _t('pastDue', 'Past due') : _t('dueBy', 'Due by')}:
        </span>
        <span className={classes.value}>
          <TermDays term={training.completion.dueDate} />
        </span>
      </ValueWrapper>
    );
  };

  return (
    <>
      <OutlinedCard id={`training-${training.id}`}>
        <CardContent>
          <Flex flexDirection={{ xs: 'column', sm: 'row' }} gap={2}>
            <ImageBackground
              src={imageUrl}
              greyScale={completed}
              sx={{
                aspectRatio: '16/10',
                width: { xs: '100%', sm: '45%', md: '35%' },
              }}
            >
              <Flex
                flexDirection="column"
                height="100%"
                justifyContent="space-between"
                overflow="hidden"
              >
                <Flex justifyContent="space-between">
                  <BaseTypography
                    variant="h5"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    color="white.main"
                  >
                    {training.playlistTemplate.name}
                  </BaseTypography>
                </Flex>
                <Flex justifyContent="left">
                  <TrainingStatusTag
                    variant={completed ? 'success' : 'error'}
                  />
                </Flex>
              </Flex>
            </ImageBackground>
            <Flex flexDirection="column" gap={2} flexGrow={1} pt={2}>
              <Flex flexDirection="column">
                {renderCompletion()}
                <LinearProgress
                  variant="determinate"
                  value={completionValue}
                  sx={{
                    height: '.5rem',
                    mb: 1,
                  }}
                />
                {renderDueDate()}
              </Flex>
              <Flex justifyContent={{ xs: 'center', sm: 'end' }} mt="auto">
                {completed && training.certificate && (
                  <Button
                    id="show-certificate"
                    variant="outlined"
                    href={training.certificate.url}
                    target="_blank"
                    color="bgContrast"
                  >
                    {_t('downloadCertificate', 'Download certificate')}
                  </Button>
                )}
              </Flex>
            </Flex>
          </Flex>
        </CardContent>
      </OutlinedCard>
    </>
  );
};

export default UserTrainingHeaderCard;

const classes = {
  value: 'UserTrainingCard-value',
  pastDueLabel: 'UserTrainingCard-pastDueLabel',
};

const ValueWrapper = styled(Flex)(
  ({ theme: { palette, spacing } }) => `
  flex-direction: row;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1rem; 
  margin-bottom: ${spacing(1)};
  color: ${palette.grey[500]};
  
  & svg {
    font-size: 1rem;
    margin-right: ${spacing(1)};
  }

  & .${classes.value} {
    flex-grow: 1;
    text-align: right;
  }
  & .${classes.pastDueLabel} {
    color: ${palette.grey[500]}
  }
`,
);
