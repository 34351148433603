import { useState } from '@hookstate/core';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ApplicationEditDialog from './ApplicationEditDialog';

const ApplicationPageActions: React.VFC = () => {
  const { t } = useTranslation();
  const state = useState({ newApplicationDialogOpen: false });
  return (
    <>
      <Button
        id="application-page-actions-archived"
        size="large"
        color="primary"
        variant="text"
        component={Link}
        // @ts-ignore
        to={'/admin/applications/suspended'}
      >
        {t(`applications.suspendedApplications`, 'Archived Applications')}
      </Button>
      <Button
        id="application-page-actions-create"
        size="large"
        color="primary"
        variant="contained"
        startIcon={<AddIcon viewBox="5 5 14 14" />}
        onClick={() => state.newApplicationDialogOpen.set(true)}
      >
        {t('applications.newApplicationAction', 'New application')}
      </Button>
      <ApplicationEditDialog
        open={state.newApplicationDialogOpen.value}
        onClose={() => state.newApplicationDialogOpen.set(false)}
      />
    </>
  );
};

export default ApplicationPageActions;
