import { useQuery } from '@apollo/client';
import { CircularProgress } from '@mui/material';
import { useParams } from 'react-router';
import HasCompanyManagementPermission from '../../../base/components/UserProvider/HasCompanyManagementPermission';
import HasPermission from '../../../base/components/UserProvider/HasPermission';
import {
  GetCompanyDocument,
  UserPermission,
} from '../../../graphql/graphql-operations';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import { TopBar } from '../../components/TopBar';
import VrBoxList from './VrBoxes/VrBoxList';

const CompanyVrBoxesPage = () => {
  const { _t } = useTranslationPrefix('CompanyVrBoxesPage');
  const { companyId } = useParams<{ companyId: string }>();
  const { data, loading } = useQuery(GetCompanyDocument, {
    variables: {
      companyIdInput: {
        companyId: companyId,
      },
    },
    skip: !companyId,
  });

  const company = data?.company;
  if (loading || !company) return <CircularProgress />;
  return (
    <>
      <TopBar
        leftSideText={_t('title', 'VR Boxes of {{companyName}}', {
          companyName: company.name,
        })}
      />
      <HasCompanyManagementPermission companyId={company.id}>
        <HasPermission val={UserPermission.MANAGE_COMPANY_VR_BOXES}>
          <VrBoxList companyId={companyId!} />
        </HasPermission>
      </HasCompanyManagementPermission>
    </>
  );
};

export default CompanyVrBoxesPage;
