import { Box } from '@mui/material';
import React, { useMemo } from 'react';
import { BaseTypography } from '../../../base/components/BaseTypography';
import { Flex } from '../../../base/components/Flex';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import { compareByProp } from '../../../lib/compare';
import PlaylistSceneCard from './PlaylistSceneCard';
import { Scene } from './PlaylistTypes';

interface Props {
  filteredScenes: Scene[];
  enabledScenes: string[];
  onSceneSelected: (sceneId: string) => void;
  onInfoClick: (pdfUrl: string) => void;
  selectedSceneIds: string[];
}

const PlaylistAvailableScenes = ({
  filteredScenes,
  enabledScenes,
  onSceneSelected,
  onInfoClick,
  selectedSceneIds,
}: Props) => {
  const { _t } = useTranslationPrefix('PlaylistAvailableScenes');
  const scenes = useMemo(
    () => filteredScenes.sort(compareByProp('name_t')),
    [filteredScenes],
  );

  return filteredScenes.length && enabledScenes?.length ? (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
      width={{ md: '1px' }}
      flexGrow={{ md: 1 }}
    >
      <BaseTypography variant="h4" color="text.secondary">
        {_t('title', '{{count}} Available Scenes', {
          count: scenes.length,
        })}
      </BaseTypography>
      {scenes.map((scene, idx) => {
        return (
          <React.Fragment key={scene.id}>
            <PlaylistSceneCard
              id={`available-scene-${scene.id}`}
              idx={idx}
              disabled={!enabledScenes.includes(scene.id)}
              scene={scene}
              onSceneAddClick={onSceneSelected}
              onInfoClick={onInfoClick}
              selected={selectedSceneIds.includes(scene.id)}
            />
          </React.Fragment>
        );
      })}
    </Box>
  ) : enabledScenes?.length ? (
    <Flex
      justifyContent="center"
      flexGrow={{ md: 1 }}
      width={{ md: '1px' }}
      py={{ xs: 4, md: 22 }}
    >
      <BaseTypography variant="h5" color="text.secondary">
        {_t('noScenesFound', 'No current scenes found for filter')}
      </BaseTypography>
    </Flex>
  ) : (
    <Flex
      alignItems="center"
      flexDirection="column"
      flexGrow={{ md: 1 }}
      width={{ md: '1px' }}
      py={{ xs: 4, md: 20 }}
      gap={2}
    >
      <BaseTypography variant="h5" color="text.secondary">
        {_t('selectFilterTitle', 'Please select filter')}
      </BaseTypography>
      <BaseTypography color="text.secondary">
        {_t(
          'selectFilterInstruction',
          'To display scenes, please select filters above',
        )}
      </BaseTypography>
    </Flex>
  );
};

export default PlaylistAvailableScenes;
