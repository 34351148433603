import { CardProps, Chip } from '@mui/material';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BaseTabs } from '../../../../base/components/BaseTabs';
import { Flex } from '../../../../base/components/Flex';
import { useTranslationPrefix } from '../../../../hooks/useTranslationPrefix';
import { UserReportPage } from '../UserReportsRoute';

enum Tabs {
  RUNTIME = 'runtime',
  GENERAL_MOTORICS = 'generalMotorics',
  FINE_MOTORICS = 'fineMotorics',
  ACTIVIZATION = 'activization',
  SPATIAL_MOVEMENT = 'spatialMovement',
  SHOULDERS_RANGE = 'souldersRange',
  SHOULDERS_FLEX_RANGE = 'souldersFlexRange',
  SHOULDERS_HORIZONTAL_ABDUCTION = 'souldersHorizontalAbduction',
  CERVICAL_SPINE = 'cervicalSpine',
  UPPER_LIMB_ACTIVITY = 'upperLimbActivity',
  UPPER_LIMB_REACTION_TIME = 'upperLimbReactionTime',
  HEAD_STABILOGRAM = 'headStabilogam',
  HEAD_STATOKINEZIOGRAM = 'headStatokineziogram',
  UPPER_LIMB_MOVEMENT_VIEW = 'upperLimbMovementView',
}

interface Props extends CardProps {
  currentPage: UserReportPage;
}

const ReportNav = ({ currentPage }: Props) => {
  const { _t } = useTranslationPrefix('UserReports.nav');
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState<Tabs | null>(null);
  const tabs = [
    {
      value: Tabs.RUNTIME,
      label: _t(Tabs.RUNTIME, 'Souhrn'),
      url: UserReportPage.RUNTIME,
    },
    {
      value: Tabs.GENERAL_MOTORICS,
      label: _t(Tabs.GENERAL_MOTORICS, 'Hrubá motorika'),
      url: UserReportPage.SHOULDERS,
      childPages: [
        {
          value: Tabs.SHOULDERS_RANGE,
          label: _t(Tabs.SHOULDERS_RANGE, 'Rozsah paže'),
          url: UserReportPage.SHOULDERS,
        },
        {
          value: Tabs.SHOULDERS_FLEX_RANGE,
          label: _t(Tabs.SHOULDERS_FLEX_RANGE, 'Flexe paže'),
          url: UserReportPage.SHOULDERS_FLEX,
        },
        {
          value: Tabs.SHOULDERS_HORIZONTAL_ABDUCTION,
          label: _t(Tabs.SHOULDERS_HORIZONTAL_ABDUCTION, 'Hor. abdukce paže'),
          url: UserReportPage.SHOULDERS_HORIZONTAL_ABDUCTION,
        },
        {
          value: Tabs.CERVICAL_SPINE,
          label: _t(Tabs.CERVICAL_SPINE, 'Krční páteř'),
          url: UserReportPage.CERVICAL_SPINE,
        },
      ],
    },
    {
      value: Tabs.FINE_MOTORICS,
      label: _t(Tabs.FINE_MOTORICS, 'Jemná motorika'),
      url: UserReportPage.FINE_MOTORICS,
    },
    {
      value: Tabs.ACTIVIZATION,
      label: _t(Tabs.ACTIVIZATION, 'Aktivizace'),
      url: UserReportPage.UPPER_LIMB_ACTIVITY,
      childPages: [
        {
          value: Tabs.UPPER_LIMB_ACTIVITY,
          label: _t(Tabs.UPPER_LIMB_ACTIVITY, 'Aktivita horních končetin'),
          url: UserReportPage.UPPER_LIMB_ACTIVITY,
        },
        {
          value: Tabs.UPPER_LIMB_REACTION_TIME,
          label: _t(Tabs.UPPER_LIMB_REACTION_TIME, 'Reakční čas'),
          url: UserReportPage.UPPER_LIMB_REACTION_TIME,
        },
      ],
    },
    {
      value: Tabs.SPATIAL_MOVEMENT,
      label: _t(Tabs.SPATIAL_MOVEMENT, 'Pohyb v prostoru'),
      url: UserReportPage.HEAD_STABILOGRAM,
      childPages: [
        {
          value: Tabs.HEAD_STABILOGRAM,
          label: _t(Tabs.HEAD_STABILOGRAM, 'Stabilogram hlavy'),
          url: UserReportPage.HEAD_STABILOGRAM,
        },

        {
          value: Tabs.HEAD_STATOKINEZIOGRAM,
          label: _t(Tabs.HEAD_STATOKINEZIOGRAM, 'Statokineziogram hlavy'),
          url: UserReportPage.HEAD_STATOKINEZIOGRAM,
        },
        {
          value: Tabs.UPPER_LIMB_MOVEMENT_VIEW,
          label: _t(Tabs.UPPER_LIMB_MOVEMENT_VIEW, 'Pohyb horních končetin'),
          url: UserReportPage.UPPER_LIMB_MOVEMENT_VIEW,
        },
      ],
    },
  ];

  const currentTopTab = tabs.filter((tab) => {
    if (selectedTab) {
      return (
        tab.value === selectedTab ||
        tab.childPages?.find((ch) => ch.value === selectedTab)
      );
    } else {
      return (
        tab.url === currentPage ||
        tab.childPages?.find((ch) => ch.url === currentPage)
      );
    }
  })[0];

  const onChange = (e: any, val: Tabs) => {
    const tab = tabs.find((tab) => tab.value === val);
    setSelectedTab(val);
    if (tab?.url) {
      navigate(`..${tab?.url}`);
    }
  };

  const renderlink = (id: Tabs, page: UserReportPage, label: string) => {
    return (
      <Chip
        key={id}
        id={id}
        variant={page === currentPage ? 'outlined' : 'filled'}
        size="small"
        component={Link}
        to={`..${page}`}
        color={page === currentPage ? 'primary' : 'default'}
        label={label}
        sx={{
          paddingX: 0,
          paddingY: 0,
        }}
      ></Chip>
    );
  };

  return (
    <Flex mb={5} gap={2} flexDirection="column">
      <BaseTabs
        value={currentTopTab.value}
        onChange={onChange}
        tabs={tabs}
        tabProps={{
          wrapped: true,
        }}
        sx={{
          overflow: 'visible',
        }}
      />
      {!!currentTopTab?.childPages?.length && (
        <Flex gap={1} flexWrap="wrap">
          {currentTopTab.childPages.map((ch) =>
            renderlink(ch.value, ch.url, ch.label),
          )}
        </Flex>
      )}
    </Flex>
  );
};

export default ReportNav;
