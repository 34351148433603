import { Route, Routes } from 'react-router-dom';
import TrainingReportPage from './TrainingReportPage';

const TrainingReportsRoute = () => {
  return (
    <Routes>
      <Route path="*" element={<TrainingReportPage />} />
    </Routes>
  );
};

export default TrainingReportsRoute;
