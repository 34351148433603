import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import { Card, CardActionArea, CardContent, Grid } from '@mui/material';
import { Flex } from '../../../../base/components/Flex';
import { useTranslationPrefix } from '../../../../hooks/useTranslationPrefix';
import { formatDate } from '../../../../lib/date';
import { formatDuration } from '../../../../lib/duration';
import { LabeledValue } from '../../../components/LabeledValue';
import { ReportSubCompany } from '../useSessionPlaylistItemReports';

interface Props {
  company: ReportSubCompany;
  onCompanySelect: (companyId: string) => void;
}

const ReportSubCompanyCard = ({ company, onCompanySelect }: Props) => {
  const { _t } = useTranslationPrefix('ReportSubCompanyCard');
  return (
    <Card>
      <CardActionArea onClick={() => onCompanySelect(company.id)}>
        <CardContent>
          <Flex alignItems="center">
            <Grid container spacing={2} p={1}>
              <Grid item xs={12} sm={6} md={12}>
                <LabeledValue
                  size="medium"
                  value={company.companyName}
                  label={_t('orgUnit', 'Org. Unit')}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <LabeledValue
                  label={_t('appRunCount', 'App runs')}
                  value={company.totalRuns}
                  size="medium"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <LabeledValue
                  label={_t('durationSum', 'Time in VR')}
                  value={formatDuration(company.totalDurationMs)}
                  size="medium"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <LabeledValue
                  label={_t('lastRunTime', 'Last session')}
                  value={company.lastRunTime && formatDate(company.lastRunTime)}
                  size="medium"
                />
              </Grid>
            </Grid>
            <ArrowForwardRoundedIcon
              fontSize="medium"
              sx={{ m: 2, color: 'primary.main' }}
            />
          </Flex>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default ReportSubCompanyCard;
