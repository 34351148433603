import DeleteIcon from '@mui/icons-material/Delete';
import { Button, DialogProps } from '@mui/material';
import { Formik } from 'formik';
import { equals } from 'ramda';
import { useState } from 'react';
import { TFunction } from 'react-i18next';
import * as Yup from 'yup';
import { BaseDialog } from '../../../../base/components/BaseDialog';
import ConfirmDialog from '../../../../base/components/ConfirmDialog/ConfirmDialog';
import { Flex } from '../../../../base/components/Flex';
import FormikForm from '../../../../base/components/form/FormikForm';
import FormikTextField from '../../../../base/components/form/FormikTextField';
import {
  DeleteVrBoxDocument,
  EditVrBoxDocument,
  GetVrBoxesDocument,
  GetVrBoxesQuery,
} from '../../../../graphql/graphql-operations';
import { useMutationWithSnackbar } from '../../../../hooks/useMutationWithSnackbar';
import { useTranslationPrefix } from '../../../../hooks/useTranslationPrefix';

interface VrBoxForm {
  name: string;
}

interface Props extends DialogProps {
  onClose: () => void;
  vrBox: GetVrBoxesQuery['vrBoxes'][0];
}

const EditVrBoxDialog = ({ open, onClose, vrBox, ...rest }: Props) => {
  const { _t, t } = useTranslationPrefix('vrBoxEditDialog');
  const [deleteConfirmDialog, setDeleteConfirmDialog] = useState(false);
  const [editVrBox] = useMutationWithSnackbar(
    'vrBoxEditDialog.vrBoxEdited',
    EditVrBoxDocument,
  );

  const handleFormSubmit = (toEdit: VrBoxForm) => {
    editVrBox({
      variables: {
        vrBoxInput: {
          id: vrBox.id,
          ...toEdit,
        },
      },
    });
    onClose();
  };

  const [deleteVrBox, { loading }] = useMutationWithSnackbar(
    'vrBoxEditDialog.vrBoxDeleted',
    DeleteVrBoxDocument,
  );

  const handleDeleteVrBox = async () => {
    await deleteVrBox({
      variables: { vrBoxIdInput: { id: vrBox.id } },
      refetchQueries: [
        {
          query: GetVrBoxesDocument,
          variables: {
            companyIdOptionalInput: {
              companyId: String(vrBox.companyId),
            },
          },
        },
      ],
    });
    setDeleteConfirmDialog(false);
    onClose();
  };

  const initialValues: VrBoxForm = {
    name: vrBox.name,
  };

  return (
    <BaseDialog
      {...rest}
      open={open}
      onClose={onClose}
      title={_t('title', 'Edit VR Box')}
      disableEnforceFocus
    >
      <Formik<VrBoxForm>
        initialValues={initialValues}
        validationSchema={getValidationSchema(_t)}
        onSubmit={handleFormSubmit}
      >
        {({ isSubmitting, values, isValid }) => (
          <FormikForm>
            <FormikTextField
              variant="outlined"
              name="name"
              label={_t('form.name', 'VR Box name')}
              inputProps={{
                autoComplete: 'off',
              }}
              required
            />
            <Button
              id="edit-vr-box-delete"
              color="error"
              variant="text"
              startIcon={<DeleteIcon color="error" />}
              onClick={() => setDeleteConfirmDialog(true)}
            >
              {_t('delete.button.label', 'Delete')}
            </Button>
            <Flex justifyContent="space-between">
              <Button
                id="edit-vr-box-cancel"
                size="large"
                color="primary"
                disabled={isSubmitting}
                onClick={onClose}
              >
                {t('common.cancel', 'Cancel')}
              </Button>
              <Button
                id="edit-vr-box-save"
                type="submit"
                size="large"
                color="primary"
                variant="contained"
                disabled={
                  isSubmitting || !isValid || equals(initialValues, values)
                }
              >
                {t('editVrBoxDialog.save', 'Save')}
              </Button>
            </Flex>
          </FormikForm>
        )}
      </Formik>
      <ConfirmDialog
        open={deleteConfirmDialog}
        onClose={() => setDeleteConfirmDialog(false)}
        onConfirm={handleDeleteVrBox}
        title={_t(
          'deleteVrBoxDialog.title',
          'Do you want to delete this VR Box?',
        )}
        confirmLabel={_t('deleteVrBoxDialog.label', 'delete VR Box')}
        confirmButtonColor="error"
        confirmDisabled={loading}
        confirmButtonStartIcon={<DeleteIcon />}
      />
    </BaseDialog>
  );
};

export default EditVrBoxDialog;

const getValidationSchema = (t: TFunction) =>
  Yup.object({
    name: Yup.string().required(
      t('form.nameRequired', 'VR Box name is required'),
    ),
  });
