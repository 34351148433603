import {
  ApolloError,
  DocumentNode,
  MutationHookOptions,
  MutationTuple,
  OperationVariables,
  TypedDocumentNode,
  useMutation,
} from '@apollo/client';
import { GraphQLError } from 'graphql';
import { useSnackbar } from 'notistack';
import { VALIDATION_FIELD_ERROR } from '../lib/handleValidationFieldError';
import { useTranslationPrefix } from './useTranslationPrefix';

const ignoredErrorsMessages = [VALIDATION_FIELD_ERROR];
const LOCALIZED_ERROR = 'LOCALIZED_ERROR';

/*
 * New version, that is simplified deom useMutationSnackbar.
 * this version does not use errors in response body, instead reacts to LocalizedError from Backend,
 * so each and every mutation does not need to define errors in schema
 */
export const useMutationWithSnackbar = <
  TData = any,
  TVariables = OperationVariables,
>(
  messageKeyPrefix: string,
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options: MutationHookOptions<TData, TVariables> & {
    skipSuccessSnackbar?: boolean;
  } = {},
): MutationTuple<TData, TVariables> => {
  const { enqueueSnackbar } = useSnackbar();
  const { _t } = useTranslationPrefix(messageKeyPrefix);

  const originalOnCompleted = options.onCompleted;
  const originalOnError = options.onError;

  const handleCompleted = (data: TData) => {
    if (!options.skipSuccessSnackbar) {
      enqueueSnackbar(_t('success'), {
        variant: 'success',
      });
    }
    originalOnCompleted?.(data);
  };

  const handleError = (error: ApolloError) => {
    if (ignoredErrorsMessages.includes(error.message)) {
      throw error;
    }
    if (error.message === LOCALIZED_ERROR) {
      const errors: Readonly<GraphQLError[]> = error.graphQLErrors;
      errors.forEach((err) => {
        if (err.message === LOCALIZED_ERROR) {
          const { messageKey, defaultMessage } = err.extensions!;
          enqueueSnackbar(_t(messageKey, defaultMessage), {
            variant: 'error',
          });
        }
      });
    } else {
      enqueueSnackbar(_t(`genericError`), {
        variant: 'error',
      });
    }
    originalOnError?.(error);
  };

  return useMutation(mutation, {
    ...options,
    onCompleted: handleCompleted,
    onError: handleError,
  });
};
