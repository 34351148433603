import React, { PropsWithChildren } from 'react';
import { AuthUserQuery } from '../../../graphql/graphql-operations';

export type AuthUser = AuthUserQuery['user'];

interface Props {
  user: AuthUser;
}

export const AuthUserContext = React.createContext<AuthUser | null>(null);

const UserProvider: React.FC<Props> = ({
  user,
  children,
}: PropsWithChildren<Props>) => {
  return (
    <AuthUserContext.Provider value={user}>{children}</AuthUserContext.Provider>
  );
};

export default UserProvider;
