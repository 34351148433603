import { Route, Routes } from 'react-router-dom';
import HelpFaqProvider from '../../components/HelpFaq/provider';
import NotFoundPage from '../NotFoundPage/NotFoundPage';
import HelpFaqPage from './HelpFaqPage';

const HelpFaqRoute = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <HelpFaqProvider>
            <HelpFaqPage />
          </HelpFaqProvider>
        }
      />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default HelpFaqRoute;
