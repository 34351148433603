import { BoxProps } from '@mui/material';
import { BaseTypography } from '../../../base/components/BaseTypography';
import { BaseTypographyProps } from '../../../base/components/BaseTypography/BaseTypography';
import { Flex } from '../../../base/components/Flex';

interface Props {
  label: React.ReactNode;
  value?: React.ReactNode;
  title?: string;
  size?: keyof SizeConfigs;
  sx?: BoxProps['sx'];
}

export interface SizeConfig {
  valueVariant: BaseTypographyProps['variant'];
  capitalizeLabel: boolean;
}
interface SizeConfigs {
  large: SizeConfig;
  medium: SizeConfig;
}

const sizes: SizeConfigs = {
  large: {
    valueVariant: { xs: 'h4' },
    capitalizeLabel: true,
  },
  medium: {
    valueVariant: { xs: 'h5' },
    capitalizeLabel: false,
  },
};

const LabeledValue = ({
  label,
  value,
  title,
  size = 'large',
  sx = {},
}: Props) => {
  const conf = sizes[size];
  return (
    <Flex flexDirection="column" flexGrow={1} sx={sx}>
      <Flex gap={2} alignItems={'center'}>
        <BaseTypography
          variant={conf.valueVariant}
          fontWeight="bold"
          title={title}
        >
          {value ? value : '--'}
        </BaseTypography>
      </Flex>

      <BaseTypography
        variant="body3"
        color="grey.500"
        uppercase={conf.capitalizeLabel}
        paddingY={1}
      >
        {label}
      </BaseTypography>
    </Flex>
  );
};

export default LabeledValue;
