import * as Sentry from '@sentry/react';
import { useCallback, useState } from 'react';
import useMutationObservable from './mutationObserverHook';

export type AppVariant = 'admin' | 'client';

const medicalUrl = process.env.REACT_APP_MEDICAL_URL || 'vrMedical';
const trainingUrl = process.env.REACT_APP_TRAINING_URL || 'vrTraining';
const schoolUrl = process.env.REACT_APP_TRAINING_URL || 'vrSchool';

export const isTraining = () =>
  window.location.hostname
    .toLocaleLowerCase()
    .includes(trainingUrl!.toLocaleLowerCase());

export const isMedical = () =>
  window.location.hostname
    .toLocaleLowerCase()
    .includes(medicalUrl!.toLocaleLowerCase());

export const isSchool = () =>
  window.location.hostname
    .toLocaleLowerCase()
    .includes(schoolUrl!.toLocaleLowerCase());

export const useAppVariant = () => {
  const [, pathnamePart11] = window.location.pathname.split('/');
  const [isAdminUrl, setIsAdminUrl] = useState(pathnamePart11 === 'admin');

  const onDocumentChange = useCallback(() => {
    const [, pathnamePart1] = window.location.pathname.split('/');
    setIsAdminUrl(pathnamePart1 === 'admin');
  }, [setIsAdminUrl]);

  useMutationObservable(document, onDocumentChange);

  const handleSetVariant = (variant: AppVariant) => {
    if (variant === 'admin') {
      window.location.href = '/admin';
      return;
    }
    window.location.href = '/';
  };

  return {
    variant: (isAdminUrl ? 'admin' : 'client') as AppVariant,
    setVariant: handleSetVariant,
    domainVariant: resolveDefaultAppVariant() || 'client',
    isAdminUrl,
  };
};

const resolveDefaultAppVariant = (): AppVariant | null => {
  const { hostname } = window.location;

  let stream: AppVariant | null;

  if (hostname.toLocaleLowerCase().includes(trainingUrl!.toLocaleLowerCase())) {
    stream = 'client';
  } else if (
    hostname.toLocaleLowerCase().includes(medicalUrl!.toLocaleLowerCase())
  ) {
    stream = 'admin';
  } else {
    if (process.env.NODE_ENV !== 'development') {
      const message = `Failed to determine stream by url ${hostname} fallbacking to vrMedical`;
      console.error(message);
      Sentry.captureMessage(message, Sentry.Severity.Error);
    }
    stream = null;
  }

  return stream;
};
