import { Route, Routes } from 'react-router-dom';
import NotFoundDetail from '../../components/NotFoundDetail/NotFoundDetail';
import VrDeviceDetailPage from './VrDeviceDetail/VrDeviceDetailPage';
import VrDevices from './VrDevices';

const VrDevicesRoute = () => {
  return (
    <Routes>
      <Route index element={<VrDevices />} />
      <Route path="/:vrDeviceId" element={<VrDeviceDetailPage />} />
      <Route path="*" element={<NotFoundDetail />} />
    </Routes>
  );
};

export default VrDevicesRoute;
