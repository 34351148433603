import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Button } from '@mui/material';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex } from '../../../base/components/Flex';
import HasPermission from '../../../base/components/UserProvider/HasPermission';
import { useUser } from '../../../base/components/UserProvider/useUserHook';
import {
  VrDevicesContext,
  VrDeviceType,
} from '../../../base/components/VrDevicesProvider/VrDevicesProvider';
import {
  DeviceState,
  UserPermission,
} from '../../../graphql/graphql-operations';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import ContentWrapper from '../../components/ContentWrapper';
import PageHeader from '../../components/PageHeader';
import EditDeviceDialog from './components/EditDeviceDialog';
import RegisterDeviceDialog from './components/RegisterDeviceDialog';
import { DeviceList } from './DeviceList';
import DeviceUpdateDialog from './DeviceUpdateDialog';
import useDeviceUpdate from './useDeviceUpdate';
import useDeviceRestart from './useDeviceRestart';

const CompanyDevicesPage = () => {
  const navigate = useNavigate();
  const { restartVrDevice } = useDeviceRestart();
  const [registerDialogOpen, setRegisterDialogOpen] = useState(false);
  const [deviceToEdit, setDeviceToEdit] = useState<VrDeviceType | null>(null);
  const { checkDeviceUpdatable } = useDeviceUpdate();
  const { boxDevices } = useContext(VrDevicesContext);
  const { hasPermission } = useUser();
  const firstActiveSessionDevice = boxDevices.find(
    (d) => !!d.session?.id && d.status.state !== DeviceState.OFFLINE,
  );

  const [deviceToUpdate, setDeviceToUpdate] = useState<VrDeviceType | null>(
    null,
  );
  const { _t } = useTranslationPrefix('companyDevicePage');

  const goToSession = (device: VrDeviceType) => {
    const sessionId = device.session?.id;
    if (sessionId) {
      navigate(`/sessions/${sessionId}`);
    }
  };

  const handleOpenAllClick = () => {
    const sessionId = firstActiveSessionDevice?.session?.id;
    if (sessionId) {
      navigate(`/sessions/${sessionId}?multiple`);
    }
  };

  const handleDeviceUpdateClick = (device: VrDeviceType) => {
    const updatable = checkDeviceUpdatable(device);
    if (updatable) {
      setDeviceToUpdate(device);
    }
  };

  const handleDeviceRestartClick = async (device: VrDeviceType) => {
    await restartVrDevice(device);
  };

  return (
    <ContentWrapper>
      <PageHeader back title={_t('companyDevices', 'Company devices')}>
        <HasPermission val={UserPermission.MANAGE_COMPANY_VR_DEVICES}>
          <Button
            id="register-new"
            onClick={() => setRegisterDialogOpen(true)}
            variant="outlined"
            startIcon={<AddRoundedIcon />}
            color="bgContrast"
          >
            {_t('newDevice', 'Add device')}
          </Button>
        </HasPermission>
        <Button
          id="openAll"
          disabled={!firstActiveSessionDevice}
          onClick={handleOpenAllClick}
          variant="contained"
        >
          {_t('openAll', 'Open all')}
        </Button>
      </PageHeader>
      <Flex justifyContent="center">
        <DeviceList
          selectedDevices={[]}
          onDeviceUpdateClick={handleDeviceUpdateClick}
          isActive={(device: VrDeviceType) => !!device.session?.id}
          onDeviceClick={goToSession}
          onDeviceEditClick={
            hasPermission(UserPermission.UPDATE_COMPANY_VR_DEVICES)
              ? setDeviceToEdit
              : undefined
          }
          onDeviceRestartClick={handleDeviceRestartClick}
        />
      </Flex>
      <DeviceUpdateDialog
        device={deviceToUpdate}
        onClose={() => setDeviceToUpdate(null)}
      ></DeviceUpdateDialog>
      <RegisterDeviceDialog
        open={registerDialogOpen}
        onClose={() => setRegisterDialogOpen(false)}
      />
      <EditDeviceDialog
        vrDeviceId={deviceToEdit?.id || null}
        onClose={() => setDeviceToEdit(null)}
      />
    </ContentWrapper>
  );
};

export default CompanyDevicesPage;
