import { useQuery } from '@apollo/client';
import { useState } from '@hookstate/core';
import { format } from 'date-fns';
import React from 'react';
import { useSessionStorage } from 'react-use';
import { adminState } from '../../../adminState';
import {
  ColumnConfig,
  SimpleTable,
} from '../../../base/components/SimpleTable';
import {
  GetUserSessionsDocument,
  GetUserSessionsQueryVariables,
  OrderDirection,
  SessionOrderField,
} from '../../../graphql/graphql-operations';
import useQueryErrorSnackbar from '../../../hooks/useQueryErrorSnackbar';
import { DATE_TIME_FORMAT } from '../../../lib/constants';
import { formatDuration } from '../../../lib/duration';
import { joinStrings } from '../../../lib/strings';
import { UserSession } from './UserDetail';
import UserSessionsTableRowActions from './UserSessionsTableRowActions';

interface Props {
  vrSessionId?: string;
  userId: string;
}

export interface TableData {
  id: string;
  startedAt: Date;
  durationMs: number;
  scenes: string;
}

const UserSessionsTable: React.VFC<Props> = ({
  vrSessionId,
  userId,
}: Props) => {
  const [rowsPerPage] = useSessionStorage('rowsPerPage', 15);
  const selectedCompanyId = useState(adminState.selectedCompanyId);
  const { data, loading, error, refetch, variables } = useQuery(
    GetUserSessionsDocument,
    {
      fetchPolicy: 'cache-and-network',
      variables: getDefaultVariables(
        rowsPerPage,
        userId,
        selectedCompanyId.value,
      ),
    },
  );
  useQueryErrorSnackbar(error, refetch);

  const tableData: TableData[] = prepareTableData(
    data?.sessions?.nodes ? [...data.sessions.nodes] : [],
  );

  const columns: ColumnConfig<TableData>[] = [
    {
      id: 'startedAt' as const,
      defaultTrans: 'Date',
      getLabel: (val: Date | null) =>
        val ? format(val, DATE_TIME_FORMAT) : '',
    },
    {
      id: 'durationMs' as const,
      defaultTrans: 'Duration',
      sortable: false,
      getLabel: (durationSeconds?: number) =>
        durationSeconds && formatDuration(durationSeconds * 1000),
    },
    {
      id: 'scenes' as const,
      defaultTrans: 'Scenes',
      sortable: false,
    },
  ];

  const renderRowActions = (rowData: TableData) => {
    return (
      <UserSessionsTableRowActions
        userId={userId}
        vrSessionId={vrSessionId}
        rowData={rowData}
      />
    );
  };

  return (
    <SimpleTable<TableData, GetUserSessionsQueryVariables>
      id="user-sessions"
      rowId="id"
      data={tableData}
      columns={columns}
      translationPrefix="vrSessionDetail.sessionsTable"
      renderRowActions={renderRowActions}
      isLoading={loading}
      lazyConfig={{
        pageCursors: data?.sessions?.pageCursors,
        totalCount: data?.sessions.totalCount,
        variables: variables,
        refetch: refetch,
      }}
    />
  );
};

const getDefaultVariables = (
  rowsPerPage: number,
  userId?: string,
  companyId?: string,
): GetUserSessionsQueryVariables => {
  const defaultVariables: GetUserSessionsQueryVariables = {
    first: rowsPerPage,
    orderBy: {
      direction: OrderDirection.DESC,
      field: SessionOrderField.STARTED_AT,
    },
    filter: {
      userId: userId,
      companyId: companyId,
    },
  };
  return defaultVariables;
};

const prepareTableData = (sessions: UserSession[]): TableData[] => {
  return sessions.map((session) => {
    return {
      id: session.id,
      startedAt: new Date(session.startedAt),
      durationMs: session.duration ? session.duration : 0,
      scenes: joinStrings(
        ' | ',
        ...session.playlist.map((item) => item.scene.name_t),
      ),
    };
  });
};

export default UserSessionsTable;
