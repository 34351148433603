import { Button, styled, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { Trans } from 'react-i18next';
import { BaseTypography } from '../../../base/components/BaseTypography';
import { Flex } from '../../../base/components/Flex';
import ClientLoginLogo from '../../../client/pages/Login/ClientLoginLogo';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import ClientBackgroundGradient from '../../../layouts/ClientLayout/ClientBackgroundGradient';

interface Props {
  error?: Error;
  eventId?: string | null;
}

const ErrorPage = ({ eventId, error }: Props) => {
  const { _t } = useTranslationPrefix('ErrorPage');
  const { palette } = useTheme();
  const handleReload = () => {
    window.location.reload();
  };

  return (
    <PageWrapper p={4}>
      <Box
        overflow="hidden"
        top={0}
        left={0}
        right={0}
        bottom={0}
        position="absolute"
      >
        <ClientBackgroundGradient />
      </Box>
      <Flex
        flexGrow={1}
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        position="relative"
      >
        <ClientLoginLogo sx={{ mb: 4 }} color={palette.text.secondary} />
        <BaseTypography
          textAlign={'center'}
          variant="h1"
          color="text.secondary"
        >
          {_t('sorry', 'Sorry, out servers are experiencing some problems.')}
        </BaseTypography>
        <BaseTypography
          textAlign={'center'}
          variant="h4"
          color="text.secondary"
          sx={{ mt: 1, mb: 4 }}
        >
          {_t('willBeBack', 'We will be back up and running again shortly.')}
        </BaseTypography>
        <Button
          id="reload"
          onClick={handleReload}
          size="large"
          variant="contained"
        >
          {_t('tryReloading', 'Try reloading')}
        </Button>
      </Flex>
      <BaseTypography
        position="relative"
        py={2}
        color="text.secondary"
        variant="body3"
      >
        <Trans
          i18nKey={'ErrorPage.footer'}
          defaults="If this problem still persists, please contact us at <0>{{supportEmailAddress}}</0>."
          values={{
            supportEmailAddress: _t(
              'supportEmailAddress',
              'support@vrmedical.cz',
            ),
          }}
        >
          <a
            href={`mailto:${_t('supportEmailAddress', 'support@vrmedical.cz')}`}
          >
            {' '}
          </a>
        </Trans>
      </BaseTypography>
    </PageWrapper>
  );
};

export default ErrorPage;

const PageWrapper = styled(Flex)(
  ({ theme: { palette } }) => `
  position: relative;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: ${palette.background.default};
`,
);
