import { Grid, GridProps, LinearProgress } from '@mui/material';
import { BoxProps, ResponsiveStyleValue } from '@mui/system';
import { ReactElement } from 'react';
import { Waypoint } from 'react-waypoint';
import { BaseRefetchListVariables, RefetchList, SearchListVariables } from '.';
import { Flex } from '../../../base/components/Flex';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import { joinStrings } from '../../../lib/strings';
import { Pagination } from '../Pagination';
import { useListSearch } from './listSearchHook';
import ListViewEmpty from './ListViewEmpty';
import { useListPagination } from './paginationHook';
import { PaginationConfig } from './types';

interface Props<T, K extends BaseRefetchListVariables | undefined = undefined> {
  id?: string;
  data: readonly T[];
  lazyConfig?: RefetchList<K, T>;
  eagerConfig?: PaginationConfig;
  rowKey: keyof T;
  renderValue: (val: T, idx: number) => ReactElement;
  columns?: ResponsiveStyleValue<number>;
  searchConfig?: SearchListVariables<T>;
  loading?: boolean;
  emptyMessage?: string;
  hideEmptyMessage?: boolean;
  gridSpacing?: GridProps['spacing'];
  sx?: BoxProps['sx'];
  emptyMessageColor?: BoxProps['color'];
  paginationType?: 'infinite' | 'button';
}

const ListView = <
  T extends Record<string, any>,
  K extends BaseRefetchListVariables | undefined = undefined,
>({
  id,
  data,
  rowKey,
  renderValue,
  columns = 3,
  lazyConfig,
  searchConfig,
  eagerConfig,
  loading,
  emptyMessage,
  gridSpacing = 2,
  paginationType: paginationTypeRaw,
  sx,
  emptyMessageColor,
  hideEmptyMessage,
}: Props<T, K>) => {
  const { _t } = useTranslationPrefix('ListView');
  const { filter } = useListSearch({ lazyConfig, searchConfig, loading });
  const paginationType =
    paginationTypeRaw || (eagerConfig ? 'button' : 'infinite');
  const {
    pageNo,
    pageCount,
    hasNext,
    hasPrev,
    handlePrev,
    handleNext,
    loadMore,
    pageData,
  } = useListPagination({
    lazyConfig,
    eagerConfig,
    data: filter(data),
  });

  const hasPagination = hasPrev || hasNext;

  return (
    <Flex
      id={id}
      width="100%"
      position="relative"
      flexDirection="column"
      minHeight="140px"
      flexShrink={0}
      sx={{ mt: -1.5, ...sx }}
    >
      <LinearProgress
        sx={{ mb: 1, visibility: loading ? 'visible' : 'hidden' }}
      />
      {!loading && !pageData.length && (
        <ListViewEmpty
          color={emptyMessageColor || 'text.secondary'}
          hideIcon={hideEmptyMessage}
          emptyMessage={
            hideEmptyMessage
              ? undefined
              : emptyMessage || _t('emptyMessage', 'No items found.')
          }
        />
      )}
      <Grid container columns={columns} spacing={gridSpacing} flexGrow={1}>
        {pageData.map((val, idx) => (
          <Grid
            item
            key={`rowKey-${val[rowKey]}`}
            xs={1}
            sm={1}
            md={1}
            lg={1}
            xl={1}
          >
            {renderValue(val, idx)}
          </Grid>
        ))}
      </Grid>
      {paginationType === 'infinite'
        ? hasNext && (
            <Waypoint onEnter={loadMore} bottomOffset="-300px"></Waypoint>
          )
        : hasPagination && (
            <Pagination
              id={joinStrings('-', id, 'list-view-pagination')}
              positioning="responsive"
              pagination={{
                currentPage: pageNo,
                totalPages: pageCount,
                hasNext,
                hasPrev,
                next: handleNext,
                prev: handlePrev,
              }}
            />
          )}
    </Flex>
  );
};

export default ListView;
