import { VrDeviceType } from '../../../base/components/VrDevicesProvider/VrDevicesProvider';
import { RestartDeviceDocument } from '../../../graphql/graphql-operations';
import { useMutationWithSnackbar } from '../../../hooks/useMutationWithSnackbar';

const useDeviceRestart = () => {
  const [restartDeviceMutation] = useMutationWithSnackbar(
    'deviceRestart.mutation',
    RestartDeviceDocument,
  );

  const restartVrDevice = async (device: VrDeviceType) => {
    await restartDeviceMutation({
      variables: {
        deviceId: device.id,
      },
    });
  };

  return { restartVrDevice };
};

export default useDeviceRestart;
