const MANIFEST_LINK_ID = 'pwa-manifest-link';

interface PrepareOptions {
  origin: string;
  short_name?: string;
  name?: string;
  start_url?: string;
}

let deferredInstallPrompt: any;

window.addEventListener('beforeinstallprompt', (e) => {
  e.preventDefault();
  deferredInstallPrompt = e;
});

const usePwaInstall = () => {
  const prepareManifest = ({ origin, ...options }: PrepareOptions) => {
    const manifest = {
      short_name: 'Vr Medical',
      name: 'Vr Medical',
      icons: [
        {
          src: `${origin}/favicon.ico`,
          sizes: '256x256 128x128 96x96 72x72 64x64 48x48 32x32 24x24 16x16',
          type: 'image/x-icon',
        },
        {
          src: `${origin}/logo_192.png`,
          type: 'image/png',
          sizes: '192x192',
        },
        {
          src: `${origin}/logo_256.png`,
          type: 'image/png',
          sizes: '256x256',
        },
        {
          src: `${origin}/logo_512.png`,
          type: 'image/png',
          sizes: '512x512',
        },
      ],
      start_url: '/',
      display: 'standalone',
      theme_color: '#000000',
      background_color: '#ffffff',
      ...options,
    };
    let content = encodeURIComponent(JSON.stringify(manifest));
    let url = 'data:application/manifest+json,' + content;
    const element = document.getElementById(MANIFEST_LINK_ID);
    element!.setAttribute('href', url);
  };

  const install = async (options: PrepareOptions) => {
    const promise = new Promise<void>((resolve) => {
      prepareManifest(options);
      setTimeout(() => {
        resolve();
      }, 250);
    });
    await promise;

    deferredInstallPrompt.prompt();
  };

  return {
    installAvailable: !!deferredInstallPrompt,
    prepareManifest,
    install,
  };
};

export default usePwaInstall;
