import { styled } from '@mui/material';
import { BoxProps } from '@mui/system';
import { Flex } from '../../../base/components/Flex';

interface Props extends BoxProps {
  ignoreNavSpacing?: boolean;
}

const ContentWrapper: React.FC<Props> = ({
  ignoreNavSpacing,
  ...boxProps
}: Props) => {
  return (
    <WrapperStyle
      flex={1}
      flexDirection="column"
      maxWidth={ignoreNavSpacing ? '1296px' : '1376px'}
      width="100%"
      flexShrink={0}
      height="fit-content"
      px={{ xs: 4, md: ignoreNavSpacing ? 6 : 11 }}
      py={4}
      {...boxProps}
    />
  );
};

export default ContentWrapper;

const WrapperStyle = styled(Flex)`
  @media print {
    padding: 0;
    max-width: unset;
  }
`;
