import { useMutation } from '@apollo/client';
import { useState } from '@hookstate/core';
import { Button, styled } from '@mui/material';
import { Field, Formik, FormikHelpers } from 'formik';
import { TextField as MuiTextField } from 'formik-mui';
import { TFunction } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { adminState } from '../../../adminState';
import { Flex } from '../../../base/components/Flex';
import FormikForm from '../../../base/components/form/FormikForm';
import {
  CreatePatientAdminDocument,
  CreateUserMutation,
  GetUsersAdminDocument,
} from '../../../graphql/graphql-operations';
import useMutationSnackbar from '../../../hooks/useMutationSnackbar';
import variableStore from '../../../variableStore';

interface Props {
  onCancel: () => void;
  onUserCreated: (userId: string) => void;
}

interface NewPatientFormData {
  patientName: string;
}

const NewPatientForm: React.VFC<Props> = (props: Props) => {
  const { onCancel, onUserCreated } = props;
  const companyId = useState(adminState.selectedCompanyId).value;
  const { t } = useTranslation();

  const createUserHandler = useMutationSnackbar('createUser', {
    translationKey: 'newPatientDialog',
  });

  const [createUser] = useMutation(CreatePatientAdminDocument, {
    ...createUserHandler,
    refetchQueries: [
      {
        query: GetUsersAdminDocument,
        variables: variableStore.getUsersVariables(),
      },
    ],
  });

  const handleFormSubmit = async (
    { patientName }: NewPatientFormData,
    formikHelpers: FormikHelpers<NewPatientFormData>,
  ) => {
    await createUser({
      variables: {
        createUserInput: { lastName: patientName, companyId: companyId },
      },
    }).then(async (result) => {
      if (result.data) {
        const retypedData = result.data as unknown as CreateUserMutation;
        await onUserCreated(retypedData.createUser.id);
      }
    });
  };

  return (
    <Formik<NewPatientFormData>
      initialValues={{ patientName: '' }}
      onSubmit={handleFormSubmit}
      validationSchema={getPatientValidationSchema(t)}
    >
      {({ isSubmitting, isValid }) => (
        <FormikForm>
          <Field
            component={TextField}
            name="patientName"
            variant="outlined"
            label={t('newPatientDialog.form.patientName', 'Patient name')}
          />
          <Flex justifyContent="space-between">
            <Button
              id="new-patient-form-cancel"
              size="large"
              color="primary"
              onClick={onCancel}
            >
              {t('common.cancel', 'Cancel')}
            </Button>
            <Button
              id="new-patient-form-confirm"
              type="submit"
              size="large"
              color="primary"
              variant="contained"
              disabled={isSubmitting}
            >
              {t('newPatientDialog.save', 'Create')}
            </Button>
          </Flex>
        </FormikForm>
      )}
    </Formik>
  );
};

const TextField = styled(MuiTextField)(({ theme }) => ({
  width: '320px',
}));

export const getPatientValidationSchema = (t: TFunction) =>
  Yup.object({
    patientName: Yup.string()
      .min(
        4,
        t('newPatientDialog.form.nameMin', 'Minimal number of characters is 4'),
      )
      .required(
        t('newPatientDialog.form.nameRequired', 'Patient name is required'),
      ),
  });

export default NewPatientForm;
