import { Button, Grid } from '@mui/material';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { BaseTypography } from '../../../base/components/BaseTypography';
import { Flex } from '../../../base/components/Flex';
import { UserRole } from '../../../graphql/graphql-operations';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import { roleHomepage } from '../ClientContent';

const SuperAdminHomepage = () => {
  const { _t, t } = useTranslationPrefix('SuperAdminHomepage');
  const [selectedRole, setSelectedRole] = useState<UserRole>();

  const renderRole = (role: UserRole) => {
    return (
      <Grid item>
        {' '}
        <Button
          id={`select-role-${role}`}
          onClick={() => setSelectedRole(role)}
        >
          {t(`enum.UserRole.${role}`)}
        </Button>
      </Grid>
    );
  };
  const selectedRolePage = selectedRole
    ? roleHomepage[selectedRole]
    : undefined;

  return selectedRolePage ? (
    typeof selectedRolePage === 'string' ? (
      <Navigate to={selectedRolePage} />
    ) : (
      selectedRolePage
    )
  ) : (
    <Flex flexDirection="column" pt={2}>
      <BaseTypography variant="h1" color="text.secondary">
        {_t('title', 'Homescreens by role')}
      </BaseTypography>
      <BaseTypography my={2} variant="h2" color="text.secondary">
        {_t('medicalRoles', 'Medical')}
      </BaseTypography>
      <Grid
        container
        columns={{ xs: 1, md: 3 }}
        columnSpacing={2}
        rowSpacing={2}
      >
        {renderRole(UserRole.MEDICAL_ADMIN)}
        {renderRole(UserRole.MEDICAL_LECTURER)}
        {renderRole(UserRole.MEDICAL_LECTURER_ASSISTANT)}
      </Grid>
      <BaseTypography my={2} variant="h2" color="text.secondary">
        {_t('trainingRoles', 'Training')}
      </BaseTypography>
      <Grid
        container
        columns={{ xs: 1, md: 3 }}
        columnSpacing={2}
        rowSpacing={2}
      >
        {renderRole(UserRole.TRAINING_ADMIN)}
        {renderRole(UserRole.TRAINING_LECTURER)}
        {renderRole(UserRole.TRAINING_LECTURER_ASSISTANT)}
        {renderRole(UserRole.TRAINING_STUDENT)}
      </Grid>
      <BaseTypography my={2} variant="h2" color="text.secondary">
        {_t('salesRoles', 'Sales')}
      </BaseTypography>
      <Grid
        container
        columns={{ xs: 1, md: 3 }}
        columnSpacing={2}
        rowSpacing={2}
      >
        {renderRole(UserRole.SALES_SALESPERSON)}
      </Grid>
      <BaseTypography my={2} variant="h2" color="text.secondary">
        {_t('schoolRoles', 'School')}
      </BaseTypography>
      <Grid
        container
        columns={{ xs: 1, md: 3 }}
        columnSpacing={2}
        rowSpacing={2}
      >
        {renderRole(UserRole.SCHOOL_TEACHER)}
      </Grid>
    </Flex>
  );
};

export default SuperAdminHomepage;
