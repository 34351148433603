import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import MovieCreationOutlinedIcon from '@mui/icons-material/MovieCreationOutlined';
import { BoxProps, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import { BaseTypography } from '../../../base/components/BaseTypography';
import { Flex } from '../../../base/components/Flex';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import PlaylistSelectedScenes from './PlaylistSelectedScenes';
import { Scene } from './PlaylistTypes';

export const BOTTOM_DRAWER_COLLAPSED_HEIGHT = '4.5rem';
export const BOTTOM_DRAWER_EXPANDED_HEIGHT = '14rem';

interface Props {
  selectedScenes: Scene[];
  onRemoveClick: (sceneId: string, idx: number) => void;
  expanded: 'y' | 'n' | 'forced';
  setExpanded: React.Dispatch<React.SetStateAction<'y' | 'n' | 'forced'>>;
  draggingNewScene: boolean;
  actions: React.ReactNode;
}

const PlaylistSelectedScenesDrawer = ({
  selectedScenes,
  onRemoveClick,
  expanded,
  setExpanded,
  draggingNewScene,
  actions,
}: Props) => {
  const swipeHandlers = useSwipeable({
    onSwipedUp: () => {
      setExpanded('y');
    },
    onSwipedDown: () => {
      setExpanded('n');
    },
    preventScrollOnSwipe: true,
  });
  const { _t } = useTranslationPrefix('PlaylistSelectedScenes');

  const height =
    expanded === 'n'
      ? BOTTOM_DRAWER_COLLAPSED_HEIGHT
      : BOTTOM_DRAWER_EXPANDED_HEIGHT;

  useEffect(() => {
    if (selectedScenes.length === 1) {
      setExpanded('y');
    }
  }, [selectedScenes.length, setExpanded]);

  return (
    <Box height={height} width="100%">
      <Box
        height={height}
        sx={{
          position: 'fixed',
          right: 0,
          bottom: 0,
          left: 0,
          transition: expanded === 'forced' ? undefined : 'height 0.5s',
          boxShadow:
            '0px -24px 32px rgba(0, 0, 0, 0.04), 0px -16px 24px rgba(0, 0, 0, 0.04), 0px -4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);',
        }}
      >
        <Box
          height={BOTTOM_DRAWER_COLLAPSED_HEIGHT}
          sx={{
            display: 'flex',
            backgroundColor: 'common.white',
            justifyContent: 'center',
          }}
          {...swipeHandlers}
        >
          <RowContentWrapperStyled
            alignItems="center"
            justifyContent="space-between"
          >
            <Flex alignItems="center">
              <IconButton
                id="selected-scenes-expand-toggle"
                onClick={() => setExpanded((e) => (e === 'n' ? 'y' : 'n'))}
                sx={{ flexGrow: 0, flexShrink: 0, flexBasis: 20 }}
              >
                {expanded === 'n' ? (
                  <ExpandLessRoundedIcon />
                ) : (
                  <ExpandMoreRoundedIcon />
                )}
              </IconButton>
              <BaseTypography variant="h5" flexShrink={0}>
                {_t('title', 'Your playlist')}
              </BaseTypography>
              <Flex
                flexShrink={0}
                alignItems="center"
                justifyContent="end"
                ml={4}
                display={{ xs: 'none', sm: 'flex' }}
              >
                <MovieCreationOutlinedIcon
                  sx={{ fontSize: '1.5rem', mr: 0.5, color: 'grey.500' }}
                />
                <BaseTypography variant="body3" color="grey.500">
                  {_t('sceneCount', '{{count}} scenes', {
                    count: selectedScenes.length,
                  })}
                </BaseTypography>
              </Flex>
            </Flex>
            <Flex alignItems="center" gap={{ xs: 1, sm: 2 }} flexShrink={0}>
              {actions}
            </Flex>
          </RowContentWrapperStyled>
        </Box>
        <Flex
          height="9.5rem"
          sx={{ backgroundColor: 'grey.50', justifyContent: 'center' }}
        >
          <RowContentWrapperStyled>
            <PlaylistSelectedScenes
              selectedScenes={selectedScenes}
              onRemoveClick={onRemoveClick}
              draggingNewScene={draggingNewScene}
            />
          </RowContentWrapperStyled>
        </Flex>
      </Box>
    </Box>
  );
};

export default PlaylistSelectedScenesDrawer;

const RowContentWrapperStyled = (boxProps: BoxProps) => (
  <Flex width="100%" flexShrink={0} px={{ xs: 3, sm: 4 }} {...boxProps} />
);
