import { SvgIcon, SvgIconProps } from '@mui/material';

const StandingIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M12.0519 5.78174C13.0962 5.78174 13.9427 4.93517 13.9427 3.89087C13.9427 2.84657 13.0962 2 12.0519 2C11.0076 2 10.161 2.84657 10.161 3.89087C10.161 4.93517 11.0076 5.78174 12.0519 5.78174Z"
        fill="currentColor"
      />
      <path
        d="M16.2658 8.77471V14.2852C16.2658 14.3609 16.2442 14.4311 16.201 14.4959C16.1578 14.5662 16.0983 14.6256 16.0227 14.6796C15.9471 14.7283 15.8552 14.7715 15.7526 14.7985C15.6553 14.8255 15.5473 14.8363 15.4392 14.8363C15.3312 14.8363 15.2231 14.8255 15.1205 14.7985C15.0232 14.7715 14.9314 14.7283 14.8558 14.6796C14.7747 14.6256 14.7153 14.5662 14.6721 14.4959C14.6343 14.4311 14.6126 14.3609 14.6126 14.2852V9.05024C14.6126 8.89897 14.4884 8.77471 14.3371 8.77471C14.1858 8.77471 14.0616 8.89897 14.0616 9.05024V21.4543C14.0616 21.53 14.04 21.5948 13.9968 21.665C13.9589 21.7299 13.8941 21.7893 13.8185 21.8433C13.7428 21.892 13.651 21.9352 13.5484 21.9622C13.4511 21.9892 13.3431 22 13.235 22C13.127 22 13.0189 21.9892 12.9163 21.9622C12.819 21.9352 12.7272 21.892 12.6515 21.8433C12.5759 21.7893 12.5111 21.7299 12.4733 21.665C12.43 21.5948 12.4084 21.53 12.4084 21.4543V15.1118C12.4084 14.9606 12.2842 14.8363 12.1329 14.8363C11.9816 14.8363 11.8574 14.9606 11.8574 15.1118V21.4543C11.8574 21.53 11.8358 21.5948 11.7925 21.665C11.7493 21.7299 11.6899 21.7893 11.6143 21.8433C11.5386 21.892 11.4468 21.9352 11.3441 21.9622C11.2469 21.9892 11.1388 22 11.0308 22C10.9227 22 10.8147 21.9892 10.712 21.9622C10.6148 21.9352 10.523 21.892 10.4419 21.8433C10.3663 21.7893 10.3069 21.7299 10.2636 21.665C10.2258 21.5948 10.2042 21.53 10.2042 21.4543V9.05024C10.2042 8.89897 10.08 8.77471 9.92869 8.77471C9.77742 8.77471 9.65316 8.89897 9.65316 9.05024V14.2852C9.65316 14.3609 9.63155 14.4311 9.58833 14.4959C9.55051 14.5662 9.48568 14.6256 9.41005 14.6796C9.33441 14.7283 9.24257 14.7661 9.13992 14.7985C9.04268 14.8255 8.93463 14.8363 8.82658 14.8363C8.71853 14.8363 8.61048 14.8255 8.50783 14.7985C8.41059 14.7661 8.31875 14.7283 8.24311 14.6796C8.16207 14.6256 8.10265 14.5662 8.06483 14.4959C8.02161 14.4311 8 14.3609 8 14.2852V8.77471C8 7.55915 8.98865 6.5705 10.2042 6.5705H14.0616C15.2771 6.5705 16.2658 7.55915 16.2658 8.77471Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default StandingIcon;
