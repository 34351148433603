import { getOperationName } from '@apollo/client/utilities';
import { useState } from '@hookstate/core';
import { Button, DialogProps } from '@mui/material';
import { Field, Formik, FormikHelpers } from 'formik';
import { TextField } from 'formik-mui';
import { TFunction, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { adminState } from '../../../adminState';
import { BaseDialog } from '../../../base/components/BaseDialog';
import { Flex } from '../../../base/components/Flex';
import FormikColorPicker from '../../../base/components/form/FormikColorPicker';
import FormikForm from '../../../base/components/form/FormikForm';
import {
  GetCompanyDocument,
  GetVrDevicesDocument,
  RegisterVrDeviceDocument,
} from '../../../graphql/graphql-operations';
import { useMutationWithSnackbar } from '../../../hooks/useMutationWithSnackbar';
import { handleValidationFieldError } from '../../../lib/handleValidationFieldError';

interface RegisterDeviceForm {
  name: string;
  color: string;
  registrationCode: string;
}

interface Props extends DialogProps {
  companyId?: string;
}

const RegisterVrDeviceDialog = ({
  open,
  companyId: companyIdParam,
  ...rest
}: Props) => {
  const { t } = useTranslation();
  const globalCompanyId = useState(adminState.selectedCompanyId);
  const companyId = companyIdParam || globalCompanyId.get();
  const handleClose = () => rest.onClose && rest.onClose({}, 'backdropClick');

  const [registerDevice] = useMutationWithSnackbar(
    'RegisterDeviceDialog.mutation',
    RegisterVrDeviceDocument,
    {
      refetchQueries: [getOperationName(GetVrDevicesDocument)!],
    },
  );

  const handleFormSubmit = async (
    { color, registrationCode, ...rest }: RegisterDeviceForm,
    formikHelpers: FormikHelpers<RegisterDeviceForm>,
  ) => {
    try {
      await registerDevice({
        variables: {
          deviceRegistration: {
            ...rest,
            registrationCode: registrationCode.toUpperCase(),
            color: color,
            companyId: companyId,
          },
        },
        refetchQueries: [getOperationName(GetCompanyDocument)!],
      });
      handleClose();
    } catch (error: any) {
      handleValidationFieldError({ error, formikHelpers, t });
    }
  };

  return (
    <BaseDialog
      {...rest}
      open={open}
      title={t('registerVrDevice.title', 'Create headset')}
      disableEnforceFocus
    >
      <>
        <Formik<RegisterDeviceForm>
          initialValues={{
            name: '',
            color: '',
            registrationCode: '',
          }}
          onSubmit={handleFormSubmit}
          validationSchema={getValidationSchema(t)}
          initialErrors={{ name: 'required', registrationCode: 'required' }}
        >
          {({ isSubmitting }) => (
            <FormikForm>
              <Field
                component={TextField}
                variant="outlined"
                name="name"
                label={t('registerVrDevice.form.name', 'Headset name')}
                inputProps={{
                  autoComplete: 'off',
                }}
                required
              />
              <Field
                component={TextField}
                variant="outlined"
                name="registrationCode"
                label={t('registerVrDevice.form.registrationCode', 'Code')}
                inputProps={{
                  autoComplete: 'off',
                  style: { textTransform: 'uppercase' },
                }}
                required
              />
              <FormikColorPicker
                name="color"
                label={t('registerVrDevice.form.color', 'Color')}
              />
              <Flex justifyContent="center">
                <Button
                  id="register-vr-device-confirm"
                  type="submit"
                  size="large"
                  color="primary"
                  variant="contained"
                  disabled={isSubmitting}
                >
                  {t('registerVrDevice.form.submit', 'Register')}
                </Button>
              </Flex>
            </FormikForm>
          )}
        </Formik>
      </>
    </BaseDialog>
  );
};

export default RegisterVrDeviceDialog;

const getValidationSchema = (t: TFunction) =>
  Yup.object({
    name: Yup.string().required(
      t('registerVrDevice.form.nameRequired', 'Device name is required'),
    ),
    registrationCode: Yup.string()
      .required(
        t(
          'registerVrDevice.form.registrationCodeRequired',
          'Registration code is required',
        ),
      )
      .length(
        6,
        t(
          'registerVrDevice.form.registrationCodeLength',
          'Registration code must have exactly 6 characters',
        ),
      ),
    color: Yup.string().required(
      t('registerVrDevice.form.colorRequired', 'Color is required'),
    ),
  });
