import { useQuery } from '@apollo/client';
import isPropValid from '@emotion/is-prop-valid';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  useTheme,
} from '@mui/material';
import { Box, styled } from '@mui/system';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { BaseTypography } from '../../../base/components/BaseTypography';
import { Flex } from '../../../base/components/Flex';
import ImagePlaceholder from '../../../base/components/ImagePlaceholder/ImagePlaceholder';
import {
  EditApplicationSceneDocument,
  GetApplicationSceneDocument,
  GetApplicationSceneQuery,
  SetSceneParameterValueInput,
} from '../../../graphql/graphql-operations';
import { useMutationWithSnackbar } from '../../../hooks/useMutationWithSnackbar';
import useQueryErrorSnackbar from '../../../hooks/useQueryErrorSnackbar';
import { SectionPaper } from '../../components/SectionPaper';
import { TopBar } from '../../components/TopBar';
import ApplicationSceneAddGroupDialog from './ApplicationSceneAddGroupDialog';
import ApplicationSceneEditDialog from './ApplicationSceneEditDialog';
import ApplicationSceneManualSection from './ApplicationSceneManualSection';
import ApplicationVersionSelector from './ApplicationVersionSelector';

type Group = NonNullable<
  GetApplicationSceneQuery['applicationScene']['applicationSceneGroups']
>[0];
type Parameter = NonNullable<
  GetApplicationSceneQuery['applicationScene']['parameters']
>[0];
interface CheckedParam {
  [parameterId: string]: { [valueId: string]: boolean };
}

const ApplicationSceneDetailPage = () => {
  const theme = useTheme();
  const { applicationSceneId } = useParams<{ applicationSceneId: string }>();
  const { t } = useTranslation();
  const [selectedVersionId, setSelectedVersionId] = useState<string>();
  const [checkedParams, setCheckedParams] = useState<CheckedParam>({});
  const [removedGroups, setRemovedGroups] = useState<string[]>([]);
  const [edited, setEdited] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [addGroupDialogOpen, setAddGroupDialogOpen] = useState(false);

  const { data, loading, error, refetch } = useQuery(
    GetApplicationSceneDocument,
    {
      variables: {
        applicationSceneId: applicationSceneId,
        applicationVersionId: selectedVersionId,
      },
      skip: !applicationSceneId || !selectedVersionId,
    },
  );
  useQueryErrorSnackbar(error, refetch);
  useEffect(() => {
    if (applicationSceneId && selectedVersionId) {
      refetch({
        applicationSceneId: applicationSceneId,
        applicationVersionId: selectedVersionId,
      });
    }
  }, [applicationSceneId, selectedVersionId, refetch]);

  const scene = data?.applicationScene;
  const enabled = !!scene?.enabled;

  const [editMutation] = useMutationWithSnackbar(
    'applications.scene.editApplicationScene',
    EditApplicationSceneDocument,
  );

  const prepareSceneParamsForSave = (
    checkedParams: CheckedParam,
  ): SetSceneParameterValueInput[] => {
    const result: SetSceneParameterValueInput[] = [];
    for (const paramId in checkedParams) {
      const paramValues = checkedParams[paramId];
      for (const valueId in paramValues) {
        const checked = paramValues[valueId];
        result.push({
          parameterId: paramId,
          valueId: valueId,
          enabled: checked,
        });
      }
    }
    return result;
  };

  const handleSaveChanges = () => {
    if (applicationSceneId && selectedVersionId && scene) {
      editMutation({
        variables: {
          scene: {
            applicationSceneId: applicationSceneId,
            applicationVersionId: selectedVersionId,
            applicationSceneGroupIds: scene.applicationSceneGroups
              ?.map((group) => group.id)
              .filter((groupId) => !removedGroups.includes(groupId)),
            sceneParameterValue: prepareSceneParamsForSave(checkedParams),
          },
        },
      });
    }
  };

  useEffect(() => {
    if (scene) {
      const newCheckedParams = scene?.parameters.reduce((acc, param) => {
        const vals = param.values.reduce(
          (paramVals, currVal) => ({
            ...paramVals,
            [currVal.id]: currVal.enabled,
          }),
          {},
        );
        return { ...acc, [param.id]: vals };
      }, {} as CheckedParam);
      setCheckedParams(newCheckedParams);
      setEdited(false);
      setRemovedGroups([]);
    }
  }, [scene, setCheckedParams]);

  const handleRemoveGroup = (groupId: string) => () => {
    setRemovedGroups((oldState) => [...oldState, groupId]);
    setEdited(true);
  };

  const handleParamSelectionChanged =
    (paramId: string, valueId: string) =>
    (e: ChangeEvent, checked: boolean) => {
      setCheckedParams((oldState) => ({
        ...oldState,
        [paramId]: {
          ...oldState[paramId],
          [valueId]: checked,
        },
      }));
      setEdited(true);
    };

  const renderGroup = (group: Group) => {
    return (
      <ApplicationSceneGroup key={group.id}>
        <span>{group.name}</span>
        <ApplicationSceneGroupButtonWrapper>
          <IconButton
            id={`application-scene-detail-remove-${group.id}`}
            color="error"
            onClick={handleRemoveGroup(group.id)}
          >
            <DeleteIcon />
          </IconButton>
        </ApplicationSceneGroupButtonWrapper>
      </ApplicationSceneGroup>
    );
  };

  const renderParameter = (param: Parameter) => {
    return (
      <Flex key={param.id} alignItems="baseline">
        <ApplicationSceneGroupParamName>
          {param.name_t}
        </ApplicationSceneGroupParamName>
        <Flex flexGrow={1}>
          {param.values.map((value) => (
            <ApplicationSceneParamValueWrapper key={value.id}>
              <FormControlLabel
                label={value.name_t}
                control={
                  <Checkbox
                    checked={!!checkedParams?.[param.id]?.[value.id]}
                    onChange={handleParamSelectionChanged(param.id, value.id)}
                  />
                }
              />
            </ApplicationSceneParamValueWrapper>
          ))}
        </Flex>
      </Flex>
    );
  };

  return (
    <>
      <TopBar
        leftSideText={scene?.name_t}
        leftSideStatusText={
          <ApplicationSceneStatus enabled={enabled}>
            {enabled
              ? t('applications.scene.enabled', 'Enabled')
              : t('applications.scene.disabled', 'Disabled')}
          </ApplicationSceneStatus>
        }
        actions={
          <>
            <ApplicationVersionSelector
              versions={
                scene?.applicationVersions ? [...scene.applicationVersions] : []
              }
              selectedVersionId={selectedVersionId}
              onVersionSelected={setSelectedVersionId}
            />
            <Button
              id="application-scene-detail-page-edit"
              variant="text"
              startIcon={<EditIcon />}
              onClick={() => setEditDialogOpen(true)}
            >
              {t('applications.scene.editScene', 'Edit scene')}
            </Button>
            <Button
              id="application-scene-detail-page-save"
              variant="contained"
              disabled={!edited}
              startIcon={<SaveIcon />}
              onClick={handleSaveChanges}
            >
              {t('applications.scene.saveChanges', 'Save changes')}
            </Button>
          </>
        }
      />
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <SectionPaper>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                {scene?.imageUrl ? (
                  <img
                    alt={scene?.name_t}
                    src={scene.imageUrl}
                    style={{ maxWidth: '100%' }}
                  />
                ) : (
                  <ImagePlaceholder />
                )}
              </Grid>
              <Grid item xs={8}>
                <Box style={{ marginBottom: theme.spacing(0.5) }}>
                  <BaseTypography variant="body2" uppercase color="gray">
                    {t('applications.scene.description', 'Description')}
                  </BaseTypography>
                </Box>

                <BaseTypography variant="body1">
                  {scene?.description}
                </BaseTypography>
              </Grid>
            </Grid>
          </SectionPaper>
          <SectionPaper
            title={t('applications.scene.applicationSceneGroups', 'Programs')}
            style={{ marginTop: theme.spacing(2) }}
          >
            <Flex justifyContent="start">
              {scene?.applicationSceneGroups
                ?.filter((group) => !removedGroups.includes(group.id))
                .map(renderGroup)}
              <Button
                id="application-scene-detail-add-group"
                startIcon={<AddIcon />}
                onClick={() => setAddGroupDialogOpen(true)}
              >
                {t('applications.scene.addGroup', 'Add')}
              </Button>
            </Flex>
          </SectionPaper>

          <SectionPaper
            title={t('applications.scene.parameters', 'Scene parameters')}
            sx={{ marginTop: 2 }}
          >
            <Flex flexDirection="column">
              {scene?.parameters?.map(renderParameter)}
            </Flex>
          </SectionPaper>

          {scene && selectedVersionId && (
            <ApplicationSceneManualSection
              scene={scene}
              applicationVersionId={selectedVersionId}
            />
          )}
        </>
      )}
      {scene && selectedVersionId && (
        <>
          <ApplicationSceneEditDialog
            scene={scene}
            applicationVersionId={selectedVersionId}
            open={editDialogOpen}
            onClose={() => setEditDialogOpen(false)}
          />
          <ApplicationSceneAddGroupDialog
            open={addGroupDialogOpen}
            applicationVersionId={selectedVersionId}
            applicationSceneId={applicationSceneId!}
            onClose={() => setAddGroupDialogOpen(false)}
            groups={
              scene.applicationSceneGroups
                ? [...scene.applicationSceneGroups]
                : []
            }
          />
        </>
      )}
    </>
  );
};
export default ApplicationSceneDetailPage;

const ApplicationSceneStatus = styled('span', {
  shouldForwardProp: isPropValid,
})<{ enabled: boolean }>(
  ({ enabled, theme: { palette } }) => `
      color: ${enabled ? palette.success.main : palette.error.main};
  `,
);

const ApplicationSceneGroup = styled(Paper)(
  ({ theme: { spacing } }) => `
    display: flex;
    font-weight: bold;
    padding-left: ${spacing(2)};
    margin-right: ${spacing(2)};
    align-items: center;
`,
);

const ApplicationSceneGroupButtonWrapper = styled('div')(
  ({ theme: { spacing } }) => `
    padding: ${spacing(1)};
    margin-left: ${spacing(2)};
    border-left: 1px solid #F2F2F2;
`,
);

const ApplicationSceneGroupParamName = styled('div')(
  ({ theme: { spacing } }) => `
    min-width: 12ch;
    text-transform: uppercase;
    font-weight: bold;
`,
);

const ApplicationSceneParamValueWrapper = styled(Paper)(
  ({ theme: { spacing } }) => `
    padding-left: ${spacing(2)};
    margin-left: ${spacing(2)};
    margin-top: ${spacing(1)};
    margin-bottom: ${spacing(1)};
`,
);
