import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  spacing: (factor: number) => `${0.5 * factor}rem`,
  palette: {
    common: {
      black: '#202020',
    },
    primary: {
      main: '#2653C9',
      light: '#687FFD',
      dark: '#002C97',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#20CE8D',
      light: '#68FFBD',
      dark: '#009C5F',
      contrastText: '#FFF',
    },
    white: {
      main: '#FFFFFF',
      light: '#687FFD',
      dark: '#002C97',
      contrastText: '#01539F',
    },
    bgContrast: {
      main: '#2653C9',
      light: '#687FFD',
      dark: '#002C97',
      contrastText: '#FFF',
    },
    offwhite: {
      main: '#E8E8E9',
      light: '#FFFFFF',
      dark: '#D1D0D2',
      contrastText: '#000',
    },
    neutral: {
      main: '#000',
      light: '#5D5B62',
      dark: '#000',
      contrastText: '#FFF',
    },
    text: {
      primary: '#202020',
      secondary: '#606060',
    },
    grey: {
      400: '#BEBEBE',
    },
    background: {
      default: '#F5F9FC',
      paper: '#FFF',
    },
    error: {
      main: '#EA5161',
      light: '#FF848E',
      dark: '#B21537',
      contrastText: '#FFF',
    },
    success: {
      main: '#20CE8D',
      light: '#68FFBD',
      dark: '#009C5F',
      contrastText: '#FFF',
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        h4: {
          lineHeight: '1.3235',
          letterSpacing: '0.015rem',
        },
        h6: {
          fontSize: '1.125rem',
          fontWeight: 'bold',
          lineHeight: '1.222',
        },
        body1: {
          fontSize: '0.875rem',
          lineHeight: '1.3235',
        },
        body2: {
          fontSize: '0.75rem',
          lineHeight: '1.3235',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          lineHeight: 1.25,
          borderRadius: 18,
          padding: '.5rem 1.875rem',
          letterSpacing: '0.1rem',
        },
        outlined: {
          fontSize: '0.875rem',
          lineHeight: 1.214,
          borderRadius: 24,
          padding: '0.875rem 1.375rem',
          letterSpacing: '0.07875rem',
        },
        containedSizeLarge: {
          fontSize: '0.875rem',
          lineHeight: 1.214,
          borderRadius: 24,
          padding: '0.875rem 1.375rem',
          letterSpacing: '0.07875rem',
        },
        sizeLarge: {
          fontSize: '0.875rem',
          lineHeight: 1.214,
          padding: '0.875rem 1.375rem',
          letterSpacing: '0.07875rem',
          borderRadius: 24,
        },
        sizeMedium: {
          fontSize: '0.875rem',
          lineHeight: 1.214,
          padding: '0.7rem 1.25rem',
          letterSpacing: '0.07875rem',
          borderRadius: 24,
        },
        sizeSmall: {
          fontSize: '0.75rem',
          lineHeight: 1.175,
          padding: '0.5rem 1rem',
          letterSpacing: '0.07875rem',
          borderRadius: 24,
        },
        iconSizeLarge: {
          '& > svg:first-of-type': {
            fontSize: '0.875rem',
          },
        },
        iconSizeMedium: {
          '& > svg:first-of-type': {
            fontSize: '0.875rem',
          },
        },
        iconSizeSmall: {
          '& > svg:first-of-type': {
            fontSize: '0.75rem',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid #F2F2F2',
        },
        head: {
          fontSize: '0.75rem',
          paddingTop: 0,
          paddingBottom: 0,
          borderBottom: 'none',
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          color: '#BEBEBE !important',
        },
        icon: {
          color: '#2653C9!important',
        },
      },
    },
  },
});

export default theme;
