import { useMutation } from '@apollo/client';
import { useState } from '@hookstate/core';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton, Switch } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from '../../../base/components/ConfirmDialog/ConfirmDialog';
import { SetManualEnabledDocument } from '../../../graphql/graphql-operations';
import useMutationSnackbar from '../../../hooks/useMutationSnackbar';
import { Manual } from './types';
import UpsertManualDialog from './UpsertManualDialog';

const ManualRowActions = (manual: Manual) => {
  const { id, enabled } = manual;
  const { t } = useTranslation();
  const keysParts = ['setEnabled', 'setDisabled'];
  const keyPart = keysParts[+enabled];
  const confirmDialogOpened = useState(false);
  const upsertDialogOpen = useState(false);
  const toggleConfirmDialog = () => confirmDialogOpened.set((prev) => !prev);
  const toggleUpsertDialog = () => upsertDialogOpen.set((prev) => !prev);
  const setManualEnabledHandler = useMutationSnackbar(
    `manuals.rowActions.${keysParts[+!enabled]}`,
    {
      onSuccessfullyCompleted: toggleConfirmDialog,
    },
  );
  const [setManualEnabled, { loading }] = useMutation(
    SetManualEnabledDocument,
    setManualEnabledHandler,
  );

  const handleSetManualEnabled = () =>
    setManualEnabled({
      variables: { editManualInput: { manualId: id, enabled: !enabled } },
    });

  return (
    <>
      <IconButton
        id={`manual-row-action-edit-${id}`}
        color="primary"
        onClick={toggleUpsertDialog}
        size="large"
      >
        <EditIcon />
      </IconButton>
      <Switch
        checked={enabled}
        onChange={toggleConfirmDialog}
        color="primary"
      />
      <ConfirmDialog
        open={confirmDialogOpened.get()}
        onConfirm={handleSetManualEnabled}
        onClose={toggleConfirmDialog}
        title={t(`manuals.rowActions.${keyPart}.confirm.title`)}
        confirmLabel={t(`manuals.rowActions.${keyPart}.confirm.confirmLabel`)}
        confirmDisabled={loading}
        confirmButtonColor={enabled ? 'error' : 'primary'}
      />
      <UpsertManualDialog
        open={upsertDialogOpen.get()}
        onClose={toggleUpsertDialog}
        manual={manual}
      />
    </>
  );
};

export default ManualRowActions;
