import { useQuery } from '@apollo/client';
import { styled } from '@mui/material';
import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ReactComponent as VrCardboard } from '../../../assets/svg/vr-cardboard-solid.svg';
import { GetVrSessionDocument } from '../../../graphql/graphql-operations';
import useQueryErrorSnackbar from '../../../hooks/useQueryErrorSnackbar';
import { joinStrings } from '../../../lib/strings';
import { LiveSessionsContext } from '../../components/LiveSessions/LiveSessionsProvider';
import { TopBar } from '../../components/TopBar';
import Actions from './Actions';
import NoUserDetail from './NoUserDetail';
import UserDetail from './UserDetail';
import VrSessionDetail from './VrSessionDetail';
import VrSessionUserActions from './VrSessionUserActions';

const VrSessionDetailPage: React.VFC = () => {
  const { vrSessionId } = useParams<{ vrSessionId: string }>();
  const { data, error, refetch } = useQuery(GetVrSessionDocument, {
    variables: { sessionInput: { sessionId: vrSessionId } },
    skip: !vrSessionId,
  });
  useQueryErrorSnackbar(error, refetch);
  const { changeActiveSession } = useContext(LiveSessionsContext);

  const session = data?.session;
  const vrDevice = session?.vrDevice;

  useEffect(() => {
    changeActiveSession && changeActiveSession(vrSessionId!);
    return () => {
      changeActiveSession && changeActiveSession(null);
    };
  }, [changeActiveSession, vrSessionId]);

  return (
    <>
      <TopBar
        leftSideText={
          <>
            {vrDevice?.name}
            <IconWrapper style={{ fill: vrDevice?.color ?? 'yellowgreen' }}>
              <VrCardboard />
            </IconWrapper>
          </>
        }
        actions={
          vrDevice && session ? (
            <Actions
              userId={session.user?.id}
              vrDeviceId={vrDevice.id}
              vrDeviceState={vrDevice.status.state}
              vrSessionId={session.id}
              sessionEnded={!!session.endedAt}
            />
          ) : undefined
        }
      />
      <VrSessionDetail
        startedAt={session?.startedAt}
        endedAt={session?.endedAt}
        appName={joinStrings(
          ' | ',
          ...(session?.playlist.map((item) => item.scene.name_t) || []),
        )}
        state={vrDevice?.status?.state}
        battery={
          vrDevice?.status?.battery ? vrDevice?.status?.battery : undefined
        }
      />

      {session && session.user ? (
        <UserDetail
          vrSessionId={vrSessionId}
          user={session.user}
          actions={
            !session.endedAt && (
              <VrSessionUserActions vrSessionId={vrSessionId!} />
            )
          }
        />
      ) : (
        <NoUserDetail vrSessionId={vrSessionId!} />
      )}
    </>
  );
};

const IconWrapper = styled('div')(({ theme }) => ({
  width: theme.spacing(8),
  verticalAlign: 'middle',
  display: 'inline-block',
  paddingLeft: theme.spacing(2),
}));

export default VrSessionDetailPage;
