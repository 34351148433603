import { useMutation } from '@apollo/client';
import { useState } from '@hookstate/core';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, DialogProps, styled } from '@mui/material';
import { Field, Formik } from 'formik';
import { TextField as FormikTextField } from 'formik-mui';
import { equals, omit } from 'ramda';
import { TFunction, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { BaseDialog } from '../../../base/components/BaseDialog';
import ConfirmDialog from '../../../base/components/ConfirmDialog/ConfirmDialog';
import { Flex } from '../../../base/components/Flex';
import FormikFileSelect from '../../../base/components/form/FormikFileSelect';
import FormikForm from '../../../base/components/form/FormikForm';
import {
  CreateManualDocument,
  DeleteManualDocument,
  EditManualDocument,
  ManualsDocument,
} from '../../../graphql/graphql-operations';
import useMutationSnackbar from '../../../hooks/useMutationSnackbar';
import { MAX_UPLOAD_FILE_SIZE } from '../../../lib/constants';
import { Manual } from './types';

export const SUPPORTED_FORMATS = ['application/pdf'];

interface ManualForm {
  name: string;
  description: string;
  attachment: File[] | null;
}

interface Props extends DialogProps {
  onClose: () => void;
  manual?: Manual;
}

const UpsertManualDialog = ({ open, onClose, manual, ...rest }: Props) => {
  const deleteConfirmOpen = useState(false);
  const toggleDeleteConfirmDialog = () =>
    deleteConfirmOpen.set((prev) => !prev);
  const initialValues: ManualForm = {
    name: manual?.name ?? '',
    description: manual?.description || '',
    attachment: null,
  };
  const isEditVariant = !!manual?.id;
  const keyPart = isEditVariant ? 'edit' : 'create';
  const { t } = useTranslation();

  const createManualHandler = useMutationSnackbar('manualDialog.create', {
    onSuccessfullyCompleted: onClose,
  });
  const editManualHandler = useMutationSnackbar('manualDialog.edit', {
    onSuccessfullyCompleted: onClose,
  });
  const deleteManualHandle = useMutationSnackbar('manualDialog.delete');
  const [createManual] = useMutation(CreateManualDocument, createManualHandler);
  const [editManual] = useMutation(EditManualDocument, editManualHandler);
  const [deleteManual, { loading }] = useMutation(DeleteManualDocument, {
    ...deleteManualHandle,
    update: (cache) =>
      cache.modify({
        fields: {
          manuals(manualRefs: [], { readField }) {
            onClose();
            return manualRefs.filter(
              (manualRef) => readField('id', manualRef) !== manual?.id,
            );
          },
        },
      }),
  });

  const handleSubmit = (manualInput: ManualForm) => {
    if (manual?.id) {
      return editManual({
        variables: {
          file: manualInput.attachment || undefined,
          editManualInput: {
            manualId: manual.id,
            ...omit(['attachment'], manualInput),
          },
        },
      });
    }
    return createManual({
      variables: {
        file: manualInput.attachment,
        manualInput: omit(['attachment'], manualInput),
      },
      refetchQueries: [
        {
          query: ManualsDocument,
        },
      ],
    });
  };

  const handleDeleteManual = () =>
    manual?.id &&
    deleteManual({ variables: { deleteManualInput: { manualId: manual.id } } });
  return (
    <BaseDialog
      {...rest}
      open={open}
      onClose={onClose}
      title={t(`manualDialog.title.${keyPart}`, 'New Manual')}
      disableEnforceFocus
    >
      <Formik<ManualForm>
        initialValues={initialValues}
        validationSchema={getValidationSchema(t, !isEditVariant)}
        onSubmit={handleSubmit}
        validateOnMount
      >
        {({ isSubmitting, isValid, values }) => (
          <FormikForm>
            <FormikFileSelect
              name="attachment"
              label={t(
                `manualDialog.form.attachment.label.${keyPart}`,
                'Add attachment',
              )}
              withValueLabel={t(
                'manualDialog.form.attachment.changeLabel',
                'Change attachment',
              )}
              accept={SUPPORTED_FORMATS.join(', ')}
              disabled={isSubmitting}
              color="primary"
            />
            <Field
              name="name"
              component={TextField}
              variant="outlined"
              type="text"
              placeholder={t('manualDialog.form.name.placeholder', 'Your name')}
              label={t('manualDialog.form.name.label', 'Manual name')}
              required
            />
            <Field
              name="description"
              component={TextField}
              variant="outlined"
              type="text"
              placeholder={t(
                'manualDialog.form.description.placeholder',
                'Description',
              )}
              label={t('manualDialog.form.description.label', 'Description')}
              required
              InputProps={{ minRows: 4, maxRows: 10, multiline: true }}
            />
            {isEditVariant && (
              <Flex justifyContent="center" marginTop={3}>
                <Button
                  id="manual-edit-dialog-delete"
                  color="error"
                  variant="text"
                  startIcon={<DeleteIcon color="error" />}
                  onClick={toggleDeleteConfirmDialog}
                >
                  {t('manualDialog.delete.button.label', 'Delete Manual')}
                </Button>
              </Flex>
            )}
            <Flex justifyContent="space-between">
              <Button
                id="manual-edit-dialog-cancel"
                size="large"
                color="primary"
                disabled={isSubmitting}
                onClick={onClose}
              >
                {t('common.cancel', 'Cancel')}
              </Button>
              <Button
                id="manual-edit-dialog-save"
                type="submit"
                size="large"
                color="primary"
                variant="contained"
                disabled={
                  isSubmitting ||
                  !isValid ||
                  (isEditVariant && equals(initialValues, values))
                }
              >
                {t('manualDialog.save', 'Save')}
              </Button>
            </Flex>
          </FormikForm>
        )}
      </Formik>
      <ConfirmDialog
        open={deleteConfirmOpen.get()}
        onClose={toggleDeleteConfirmDialog}
        onConfirm={handleDeleteManual}
        title={t(
          'deleteManualDialog.title',
          'Do you want to delete this manual?',
        )}
        confirmLabel={t('deleteManualDialog.button.label', 'delete manual')}
        confirmButtonColor="error"
        confirmDisabled={loading}
        confirmButtonStartIcon={<DeleteIcon />}
      />
    </BaseDialog>
  );
};

export default UpsertManualDialog;

const TextField = styled(FormikTextField)(({ theme }) => ({
  marginTop: theme.spacing(2),
  minWidth: theme.spacing(40),
}));

// const

const getValidationSchema = (t: TFunction, fileRequired: boolean) =>
  Yup.object({
    name: Yup.string().required(
      t('manualDialog.form.name.required', 'Fill your name'),
    ),
    description: Yup.string().required(
      t('manualDialog.form.description.required', 'Fill description'),
    ),
    attachment: Yup.mixed()
      .test(
        'fileSize',
        t(
          'manualDialog.form.attachment.fileTooLarge',
          'File is too large. Maximum is 10 MB',
        ),
        (value) => {
          if (!value) {
            return true;
          }
          return value.size <= MAX_UPLOAD_FILE_SIZE;
        },
      )
      .test(
        'fileType',
        t(
          'manualDialog.form.attachment.fileTypeForbidden',
          'This file type is not supported.',
        ),
        (value) => {
          if (!value) {
            return true;
          }
          return SUPPORTED_FORMATS.includes(value?.type);
        },
      )
      .test(
        'required',
        t('manualDialog.form.attachment.required', 'File must be provided'),
        (value) => {
          if (fileRequired) {
            return value != null;
          }
          return true;
        },
      ),
  });
