import { useMutation, useQuery } from '@apollo/client';
import ArchiveIcon from '@mui/icons-material/Archive';
import { Button, MenuItem } from '@mui/material';
import { Field, Formik } from 'formik';
import { Select, TextField } from 'formik-mui';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { BaseDialog } from '../../../base/components/BaseDialog';
import ConfirmDialog from '../../../base/components/ConfirmDialog/ConfirmDialog';
import { Flex } from '../../../base/components/Flex';
import FormikForm from '../../../base/components/form/FormikForm';
import { useUser } from '../../../base/components/UserProvider/useUserHook';
import {
  CompanyFragmentFragment,
  CompanyStatus,
  EditCompanyDocument,
  HelpFaqsDocument,
  LoginType,
  UserPermission,
} from '../../../graphql/graphql-operations';
import useMutationSnackbar from '../../../hooks/useMutationSnackbar';
import {
  TFunctionPrefixed,
  useTranslationPrefix,
} from '../../../hooks/useTranslationPrefix';
import { useCompanyStatusMutation } from './hooks';

const UNSELECTED = 'unselected';

interface Props {
  open: boolean;
  onClose: () => void;
  company: CompanyFragmentFragment;
}

interface FormData {
  name: string;
  defaultLoginType: LoginType | 'unselected';
  helpFaqId?: string | null;
}

const NOT_SELECTED_OPTION = 'null';

const EditCompanyDialog: React.VFC<Props> = (props: Props) => {
  const { open, onClose, company } = props;
  const { hasAnyPermission } = useUser();
  const [archiveConfirmOpen, setArchiveConfirmOpen] = useState(false);
  const { t, _t } = useTranslationPrefix('editCompanyDialog');
  const editCompanyHandler = useMutationSnackbar('editCompany', {
    translationKey: 'editCompanyDialog',
    onSuccessfullyCompleted: onClose,
  });

  const { data: helpFaqsData } = useQuery(HelpFaqsDocument);

  const [editCompany] = useMutation(EditCompanyDocument, editCompanyHandler);
  const handleFormSubmit = async ({
    name,
    defaultLoginType,
    helpFaqId,
  }: FormData) => {
    editCompany({
      variables: {
        editCompany: {
          companyId: company.id,
          name,
          helpFaqId: helpFaqId === NOT_SELECTED_OPTION ? null : helpFaqId,
          defaultLoginType:
            defaultLoginType === UNSELECTED ? null : defaultLoginType,
        },
      },
    });
  };

  const { companyStatusMutation } = useCompanyStatusMutation({
    onCompleted: () => {
      setArchiveConfirmOpen(false);
      onClose();
    },
  });

  const handleArchiveClick = () => {
    companyStatusMutation({
      variables: {
        status: {
          companyId: company.id,
          newStatus: CompanyStatus.SUSPENDED,
        },
      },
    });
  };

  return (
    <>
      <BaseDialog
        open={open}
        title={_t('editTitle', 'Edit organization')}
        onClose={onClose}
      >
        <Formik<FormData>
          initialValues={{
            name: company.name,
            defaultLoginType: company.defaultLoginType || 'unselected',
            helpFaqId: company.helpFaqId
              ? company.helpFaqId.toString()
              : 'null',
          }}
          onSubmit={handleFormSubmit}
          validationSchema={getValidationSchema(_t)}
        >
          {({ isSubmitting, isValid }) => (
            <FormikForm>
              <Field
                component={TextField}
                name="name"
                variant="outlined"
                label={_t('nameLabel', 'Name')}
                sx={{ mb: 2 }}
              />
              <Field
                component={Select}
                name="defaultLoginType"
                variant="outlined"
                label={t(
                  'newCompanyDialog.form.defaultLoginType',
                  'Login type',
                )}
                required
              >
                <MenuItem value={UNSELECTED}>
                  {t(`newCompanyDialog.noLoginType`, 'Not defined (inherited)')}
                </MenuItem>
                <MenuItem value={LoginType.PHONE}>
                  {t(`enum.LoginType.${LoginType.PHONE}`, 'Phone (sms)')}
                </MenuItem>
                <MenuItem value={LoginType.USERNAME}>
                  {t(
                    `enum.LoginType.${LoginType.USERNAME}`,
                    'Username and password',
                  )}
                </MenuItem>
              </Field>
              <Field
                component={Select}
                name="helpFaqId"
                variant="outlined"
                label={t('newCompanyDialog.form.helpFaq', 'FAQ')}
              >
                <MenuItem value={NOT_SELECTED_OPTION}>
                  {t(`newCompanyDialog.noHelpFaq`, 'Not choosed')}
                </MenuItem>
                {helpFaqsData?.helpFaqs?.map((faq) => (
                  <MenuItem value={faq.id}>{faq.name_t}</MenuItem>
                ))}
              </Field>
              {(!!company.parentCompanyId ||
                hasAnyPermission(UserPermission.MANAGE_COMPANIES)) && (
                <Flex justifyContent="center">
                  <Button
                    id="company-edit-dialog-archive"
                    variant="text"
                    size="large"
                    color="error"
                    onClick={() => setArchiveConfirmOpen(true)}
                    startIcon={<ArchiveIcon />}
                  >
                    {t('archive', 'Archive')}
                  </Button>
                </Flex>
              )}
              <Flex justifyContent="space-between" mt={1}>
                <Button
                  id="company-edit-dialog-cancel"
                  size="large"
                  color="primary"
                  onClick={onClose}
                >
                  {t('common.cancel', 'Cancel')}
                </Button>
                <Button
                  id="company-edit-dialog-confirm"
                  type="submit"
                  size="large"
                  color="primary"
                  variant="contained"
                  disabled={isSubmitting || !isValid}
                >
                  {_t('save', 'Save')}
                </Button>
              </Flex>
            </FormikForm>
          )}
        </Formik>
      </BaseDialog>
      <ConfirmDialog
        title={_t('archiveConfirmTitle', 'Archive organization?')}
        open={archiveConfirmOpen}
        onClose={() => setArchiveConfirmOpen(false)}
        onConfirm={handleArchiveClick}
      />
    </>
  );
};

const getValidationSchema = (t: TFunctionPrefixed) =>
  Yup.object({
    name: Yup.string()
      .min(4, t('form.nameMin', 'Minimal number of characters is 4'))
      .required(t('form.nameRequired', 'Fill name')),
  });

export default EditCompanyDialog;
