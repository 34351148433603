import { Card, styled } from '@mui/material';

const OutlinedCard = styled(Card)(
  ({ theme: { palette } }) => `
  background-color: transparent;
  background-image: none;
  box-shadow: inset 0 0 0 1px ${
    palette.mode === 'dark' ? palette.grey[500] : palette.grey[100]
  };`,
);

export default OutlinedCard;
