import { TableBody, TableCell, TableRow } from '@mui/material';
import { sort } from 'ramda';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { OrderDirection } from '../../../graphql/graphql-operations';
import { BaseTypography } from '../BaseTypography';
import { Flex } from '../Flex';
import BaseTableBodyRow from './BaseTableBodyRow';
import { BaseTableContext } from './BaseTableProvider';
import { TableRowProps } from './types';
import { getPropValue, numberComparator, stringComparator } from './utils';

interface Props {
  actionsWidth?: string;
  withoutActions: boolean;
  useInBuildFeatures: boolean;
  numberOfColumns: number;
  isLoading: boolean;
}

const BaseTableBody = ({
  isLoading,
  useInBuildFeatures,
  numberOfColumns,
  withoutActions,
  actionsWidth,
}: Props) => {
  const { t } = useTranslation();
  const { rows, rowsPerPage, page, orderDirection, orderField } =
    useContext(BaseTableContext);

  const emptyTable = page === 0 && rows.length === 0 && !isLoading;
  if (emptyTable) {
    return (
      <TableBody>
        <TableRow key="last_empty_row" style={{ height: 43 }}>
          <TableCell colSpan={numberOfColumns}>
            <Flex justifyContent="center">
              {
                <BaseTypography variant="h6" color="primary">
                  {t('table.emptyTable', 'Tabulka neobsahuje žádné záznamy')}
                </BaseTypography>
              }
            </Flex>
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }

  let rowsToRender = rows;
  if (useInBuildFeatures) {
    const sortedRows = sort(
      (a, b) => sortFn<any>(a, b, orderDirection, orderField),
      rows,
    );
    rowsToRender = slicer(page, rowsPerPage, sortedRows);
  }

  return (
    <TableBody>
      {rowsToRender.map((row) => (
        <BaseTableBodyRow
          key={row.id}
          {...row}
          withoutActions={withoutActions}
          actionsWidth={actionsWidth}
        />
      ))}
    </TableBody>
  );
};

export default BaseTableBody;

function slicer<T>(
  page: number,
  rowsPerPage: number,
  rows: TableRowProps<T>[],
) {
  if (!rows.length) {
    return rows;
  }
  const slicedContent = rows.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  );

  return slicedContent;
}

function sortFn<T extends {}>(
  a: TableRowProps<T>,
  b: TableRowProps<T>,
  order: OrderDirection,
  orderBy: keyof T,
) {
  const valueA = getPropValue(a.rowContent, orderBy);
  const valueB = getPropValue(b.rowContent, orderBy);
  const orderMultiplier = order === 'ASC' ? 1 : -1;
  if (valueA && valueB) {
    if (valueA.comparator) {
      // @ts-ignore
      return orderMultiplier * valueA.comparator(valueA.label, valueB.label);
    } else if (
      typeof valueA.label === 'string' &&
      typeof valueB.label === 'string'
    ) {
      return orderMultiplier * stringComparator(valueA.label, valueB.label);
    } else if (
      typeof valueA.label === 'number' &&
      typeof valueB.label === 'number'
    ) {
      return orderMultiplier * numberComparator(valueA.label, valueB.label);
    }
  }
  return 0;
}
