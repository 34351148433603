import { BoxProps, styled } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { useTranslationPrefix } from '../../../../hooks/useTranslationPrefix';
import { formatDate } from '../../../../lib/date';
import Chart, { ChartProps } from '../../../components/Charts/Chart';
import { UpdateAxisPointerEvent } from '../../../components/Charts/ChartTypes';
import { axisPointer } from '../utils/axisPointer';
import { prepareMonthAxis } from '../utils/monthAxis';

interface Props extends BoxProps {
  data: readonly ActivityData[];
  onAxisPointerChange?: ChartProps<any>['onAxisPointerChange'];
}

interface ActivityData {
  key: string;
  leftActive: number;
  leftTotal: number;
  rightActive: number;
  rightTotal: number;
}

export const calcActivityPercent = (value?: number, total?: number): number => {
  const rate = (value || 0) / (total || 1);
  const percent = rate * 100;
  const rounced = percent < 1 ? Math.ceil(percent) : Math.round(percent);
  return rounced;
};

export const formatPercent = (value: number): string => {
  return value + '%';
};

const UpperLimbActivityChart = ({
  data,
  onAxisPointerChange: onAxisPointerChangeOrig,
  ...boxProps
}: Props) => {
  const { _t } = useTranslationPrefix('UpperLimbActivityChart');

  const onAxisPointerChange = useCallback(
    //Its category type, so value is index, by actual value is needed
    (e: UpdateAxisPointerEvent) => {
      const dimension = e.axesInfo.find((i) => i.axisDim === 'x');
      if (typeof dimension?.value === 'number' && data[dimension.value]) {
        var item = data[dimension.value];
        dimension.value = item.key;
      }
      onAxisPointerChangeOrig?.(e);
    },
    [onAxisPointerChangeOrig, data],
  );

  const dataWithPercent = useMemo(
    () =>
      data.map((d) => ({
        ...d,
        leftPercent: calcActivityPercent(d.leftActive, d.leftTotal),
        rightPercent: calcActivityPercent(d.rightActive, d.rightTotal),
      })),
    [data],
  );

  return (
    <UpperLimbActivityChartWrapper
      onAxisPointerChange={onAxisPointerChange}
      {...boxProps}
      options={{
        tooltip: {
          trigger: 'axis',
          triggerOn: 'click',
          valueFormatter: (val: number) => formatPercent(val),
        },
        legend: {},
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        grid: {
          left: '3%',
          right: '10px',
          bottom: '70px',
          containLabel: true,
        },
        dataZoom: [
          {
            type: 'inside',
            start: Math.max(0, 100 - (20 / data.length) * 100),
            end: 100,
          },
          {
            type: 'slider',
            start: 0,
            end: 100,
            labelFormatter: (_: number, val: string) => formatDate(val),
          },
        ],
        dataset: {
          source: dataWithPercent,
        },
        xAxis: [
          {
            type: 'category',
            axisLabel: {
              formatter: (val: string) => {
                return formatDate(val);
              },
            },
            axisPointer: axisPointer({}),
          },
          prepareMonthAxis(data),
        ],
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: (val: number) => formatPercent(val),
          },
        },
        series: [
          {
            name: _t('left', 'Levá ruka'),
            type: 'bar',
            barGap: '0%',
            barCategoryGap: '50%',
            dimensions: [
              { name: 'key', type: 'time' },
              { name: 'leftPercent', type: 'number' },
            ],
          },
          {
            name: _t('right', 'Pravá ruka'),
            type: 'bar',
            barGap: '0%',
            barCategoryGap: '25%',
            dimensions: [
              { name: 'key', type: 'time' },
              { name: 'rightPercent', type: 'number' },
            ],
          },
        ],
      }}
    />
  );
};

const UpperLimbActivityChartWrapper = styled(Chart)`
  min-height: 330px;
  height: 33vh;
`;

export default React.memo(UpperLimbActivityChart);
