import { Route, Routes } from 'react-router-dom';
import AdminContent from './admin/pages/AdminContent';
import VrDevicesProvider from './base/components/VrDevicesProvider/VrDevicesProvider';
import ClientContent from './client/pages/ClientContent';

const AppContent = () => {
  return (
    <VrDevicesProvider>
      <Routes>
        <Route path="/admin/*" element={<AdminContent />} />
        <Route path="*" element={<ClientContent />} />
      </Routes>
    </VrDevicesProvider>
  );
};

export default AppContent;
