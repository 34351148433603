import { Route, Routes } from 'react-router-dom';
import AuthCheck from '../../../base/components/UserProvider/AuthCheck';
import { UserPermission } from '../../../graphql/graphql-operations';
import NotFoundPage from '../NotFoundPage/NotFoundPage';
import PlaylistTemplateAssignPage from './PlaylistTemplateAssignPage';
import PlaylistTemplateDetailPage from './PlaylistTemplateDetailPage';
import PlaylistTemplateEditPage from './PlaylistTemplateEditPage';
import PlaylistTemplatesFolderAssignPage from './PlaylistTemplateFolderAssignPage';
import PlaylistTemplateFolderPage from './PlaylistTemplateFolderPage';
import PlaylistTemplatesPage from './PlaylistTemplatesPage';

const PlaylistTemplatesRoute = () => {
  return (
    <Routes>
      <Route index element={<PlaylistTemplatesPage />} />
      <Route
        element={
          <AuthCheck
            permission={[
              UserPermission.MANAGE_MY_PLAYLIST_TEMPLATES,
              UserPermission.MANAGE_OTHERS_PLAYLIST_TEMPLATES,
            ]}
          />
        }
      >
        <Route path="/create" element={<PlaylistTemplateEditPage />} />
        <Route path="/edit" element={<PlaylistTemplateEditPage />} />
      </Route>
      <Route
        element={
          <AuthCheck
            permission={[UserPermission.PLAYLIST_FOLDERS_ASSIGNMENTS]}
          />
        }
      >
        <Route
          path="/folders/:folderId/assign"
          element={<PlaylistTemplatesFolderAssignPage />}
        />
      </Route>
      <Route
        element={
          <AuthCheck
            permission={[
              UserPermission.MANAGE_PLAYLIST_FOLDERS,
              UserPermission.READ_PLAYLIST_FOLDERS,
            ]}
          />
        }
      >
        <Route
          path="/folders/:folderId"
          element={<PlaylistTemplateFolderPage />}
        />
      </Route>
      <Route
        element={
          <AuthCheck permission={UserPermission.MANAGE_TRAINING_ASSIGNMENTS} />
        }
      >
        <Route
          path="/:templateId/assign"
          element={<PlaylistTemplateAssignPage />}
        />
      </Route>
      <Route path="/:templateId" element={<PlaylistTemplateDetailPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default PlaylistTemplatesRoute;
