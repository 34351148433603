import { ApolloClient, from, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';
import Cookies from 'js-cookie';
import packageInfo from '../../package.json';
import errorLink from './links/error';
import loggingLink from './links/logging';
import WebSocketLink from './links/websocket';

const COOKIE_KEY = 'XSRF-TOKEN';
const GRAPHQL_URI = '/api/graphql';
const SUBSCRIPTION_SERVER_URI = '/api/v2/graphql';

const httpLink = createUploadLink({
  uri: GRAPHQL_URI,
  headers: {
    'X-Requested-With': 'fetch',
  },
  credentials: 'same-origin',
});

const getCorrectProtocol = () =>
  window.location.protocol === 'http:' ? 'ws' : 'wss';

const getWsUri = (path: string) => {
  // Because of https://github.com/facebook/create-react-app/issues/8094 in development we have to connect to API directly
  // websockets don't adhere to same-site policy so no cors necessary
  if (process.env.NODE_ENV === 'development') {
    const apiUrl = process.env.REACT_APP_API_URL?.replace(
      'https',
      'wss',
    )?.replace('http', 'ws');
    return `${apiUrl}${path}`;
  }
  return `${getCorrectProtocol()}://${window.location.host}${path}`;
};

const wsLink = () =>
  new WebSocketLink({
    url: getWsUri(SUBSCRIPTION_SERVER_URI),
    connectionParams: () => ({
      langs: navigator.languages,
    }),
  });

const requestLink = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink(),
  // @ts-ignore
  httpLink,
);

const authLink = setContext((req, { headers }) => {
  const csrfToken = Cookies.get(COOKIE_KEY);

  return {
    headers: csrfToken
      ? {
          [COOKIE_KEY]: csrfToken,
          ...headers,
        }
      : headers,
  };
});

export default function createClient() {
  return new ApolloClient({
    name: 'Admin',
    version: packageInfo.version,
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            companies: { merge: (_, incoming) => incoming },
          },
        },
        //Wierd bug where param values (lang for me) are sometimes different in data then what was actually returned from server
        //https://github.com/apollographql/apollo-client/issues/3234
        SceneParameter: {
          keyFields: false,
        },
        UserConnection: {
          keyFields: false,
        },
        PageCursor: {
          keyFields: false,
        },
      },
    }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
      },
    },
    link: from([authLink, errorLink, loggingLink, requestLink]),
    assumeImmutableResults: true,
  });
}
