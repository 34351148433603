import { Box, CircularProgress, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BaseTypography } from '../../../base/components/BaseTypography';
import { Flex } from '../../../base/components/Flex';
import { ClientLayout } from '../../../layouts';
import { useAppVariant } from '../../../lib/useAppVariant';
import SimplePageLayout from '../SimplePageLayout/SimplePageLayout';

const LoadingPage = () => {
  const { variant } = useAppVariant();
  const theme = useTheme();
  const { t } = useTranslation();
  const color = theme.palette.secondary.main;

  const title = t('loadingPage.loading', 'wait a moment');
  return variant === 'admin' ? (
    <SimplePageLayout vrCardboardColor={color} title={title}>
      <Box color={color} marginTop={4}>
        <CircularProgress color="inherit" size="4rem" />
      </Box>
    </SimplePageLayout>
  ) : (
    <ClientLayout>
      <Flex flexDirection="column" alignItems="center" justifyContent="center">
        <BaseTypography variant="h4" marginBottom={theme.spacing(2)}>
          {title}
        </BaseTypography>
        <CircularProgress color="primary" size="4rem" />
      </Flex>
    </ClientLayout>
  );
};

export default LoadingPage;
