import { formatInTimeZone } from 'date-fns-tz';

export const csvDownload = (content: string, filename: string) => {
  const blob = new Blob([content], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);

  // Create a link to download it
  const pom = document.createElement('a');
  pom.href = url;
  pom.setAttribute('download', filename);
  pom.click();
};

export const csvTimestampTransform =
  (...fields: string[]) =>
  (row: any) => {
    const override: any = {};
    for (const field of fields) {
      if (field && row[field]) {
        override[field] = formatInTimeZone(
          new Date(row[field]),
          Intl.DateTimeFormat().resolvedOptions().timeZone,
          'yyyy-MM-dd HH:mm:ss',
        );
      }
    }
    return { ...row, ...override };
  };

export const csvDurationTransform =
  (...fields: string[]) =>
  (row: any) => {
    const override: any = {};
    for (const field of fields) {
      if (field && row[field]) {
        const h = Math.floor(row[field] / 1000 / 60 / 60);
        const m = Math.floor((row[field] / 1000 / 60 / 60 - h) * 60);
        const s = Math.floor(((row[field] / 1000 / 60 / 60 - h) * 60 - m) * 60);
        override[field] = `${h.toLocaleString('en-US', {
          minimumIntegerDigits: 2,
        })}:${m.toLocaleString('en-US', {
          minimumIntegerDigits: 2,
        })}:${s.toLocaleString('en-US', { minimumIntegerDigits: 2 })}`;
      }
    }
    return { ...row, ...override };
  };
