import { StateMethods } from '@hookstate/core';
import { ChangeEvent } from 'react';

export const handleOnClickToggle =
  (setState: (value: React.SetStateAction<boolean>) => void) =>
  (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
    setState((prev) => !prev);

export const handleOnClickToggleHook =
  (state: StateMethods<boolean>) =>
  (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
    state.set((prev) => !prev);

export const handleTextFieldChange =
  (setValue: (value: string) => void) =>
  (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
    setValue(e.target.value);
