import { CircularProgress } from '@mui/material';
import { intlFormat } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { GetApplicationVersionQuery } from '../../../graphql/graphql-operations';
import DetailCard from '../../components/DetailCard/DetailCard';
import DetailValue from '../../components/DetailCard/DetaiValue';
import ApplicationScenesTable from './ApplicationScenesTable';

interface Props {
  versionData: GetApplicationVersionQuery;
  isLoading: boolean;
}

const ApplicationVersionDetail = ({ versionData, isLoading }: Props) => {
  const { t } = useTranslation();

  const { scenes, publishedAt, application, id } =
    versionData?.applicationVersion || {};
  return isLoading ? (
    <CircularProgress />
  ) : (
    <>
      <DetailCard>
        <DetailValue
          value={scenes?.length || 0}
          label={t('applications.detail.scenes', 'Scenes')}
        />
        <DetailValue
          value={application?.usageStatistics?.sessionCount}
          label={t('applications.detail.sessions', 'Sessions')}
        />
        <DetailValue
          value={publishedAt ? intlFormat(new Date(publishedAt)) : '--'}
          label={t('applications.detail.published', 'Published')}
        />
      </DetailCard>

      <ApplicationScenesTable
        applicationVersionId={id}
        scenes={scenes ? [...scenes] : []}
      />
    </>
  );
};

export default ApplicationVersionDetail;
