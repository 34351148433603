import { Button } from '@mui/material';
import { styled, useTheme } from '@mui/system';
import { Form, Formik, FormikHelpers } from 'formik';
import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import * as Yup from 'yup';
import { ReactComponent as VrCardboard } from '../../../assets/svg/vr-cardboard-solid.svg';
import { BaseTypography } from '../../../base/components/BaseTypography';
import { Flex } from '../../../base/components/Flex';
import FormikOptionPicker from '../../../base/components/form/FormikOptionPicker';
import {
  CreateSessionInput,
  CreateSessionMutationDocument,
  CreateSessionMutationMutation,
  GetVrDeviceScenesQuery,
} from '../../../graphql/graphql-operations';
import { useMutationWithSnackbar } from '../../../hooks/useMutationWithSnackbar';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';

type LaunchableScene = GetVrDeviceScenesQuery['vrDevice']['scenes'][0];
type LaunchableSceneParam =
  GetVrDeviceScenesQuery['vrDevice']['scenes'][0]['scene']['parameters'][0];

interface Props {
  vrDeviceId: string;
  launchableScene: LaunchableScene;
  userId?: string;
}

interface FormValues {
  [name: string]: string;
}

const LaunchSceneForm = ({ launchableScene, userId, vrDeviceId }: Props) => {
  const { scene } = launchableScene;
  const { _t } = useTranslationPrefix('launchSceneForm');
  const { spacing } = useTheme();
  const navigate = useNavigate();

  const handleLaunchSuccess = ({
    createSession: sessions,
  }: CreateSessionMutationMutation) => {
    navigate(`/admin/vr-session/${sessions[0].id}`);
  };

  const [createSession] = useMutationWithSnackbar(
    'launchSceneForm.createSessionMutation',
    CreateSessionMutationDocument,
    { onCompleted: handleLaunchSuccess },
  );

  const handleSubmit = async (
    values: FormValues,
    helpers: FormikHelpers<FormValues>,
  ) => {
    const input: CreateSessionInput = {
      vrDeviceIds: [vrDeviceId],
      launch: true,
      userId: userId,
      scenes: [
        {
          sceneId: scene.id,
          params: Object.entries(values).map(([name, value]) => ({
            name,
            value,
          })),
        },
      ],
    };
    await createSession({ variables: { createSessionInput: input } });
  };

  const validations = useMemo(() => {
    const objectShape = scene.parameters
      .filter((param) => param.required)
      .reduce((acc, param) => {
        return {
          ...acc,
          [param.name]: Yup.string().required(
            _t('paramRequired', 'Scene parameter is required'),
          ),
        };
      }, {});
    return Yup.object(objectShape);
  }, [scene, _t]);

  const initialValues = useMemo(
    () =>
      scene.parameters.reduce(
        (acc, param) => ({ ...acc, [param.name]: '' }),
        {},
      ),
    [scene],
  );

  const renderParam = (param: LaunchableSceneParam) => {
    const values = param.values.map((value) => ({
      label: value.name_t,
      value: value.name,
    }));
    return (
      <FormikOptionPicker
        required={param.required}
        label={param.name_t}
        key={param.id}
        options={values}
        name={param.name}
      />
    );
  };

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validations}
    >
      {({ isSubmitting, setFieldValue, values }) => (
        <Form>
          {!scene.parameters.length && (
            <BaseTypography textAlign="center" variant="h6">
              {_t('noParams', 'Scene without parameters')}
            </BaseTypography>
          )}
          {scene.parameters.map(renderParam)}

          <Flex justifyContent="center" marginTop={spacing(4)}>
            <Button
              id="launch-scene-form-confirm"
              type="submit"
              size="large"
              color="success"
              variant="contained"
              startIcon={<LaunchSceneFormIcon />}
              disabled={isSubmitting}
            >
              {_t('startSession', 'Start now')}
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

const LaunchSceneFormIcon = styled(VrCardboard)(
  ({ theme: { palette } }) => `
  width: 4ch;
  fill: ${palette.success.contrastText};
`,
);

export default LaunchSceneForm;
