import { useQuery } from '@apollo/client';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Button } from '@mui/material';
import { uuid4 } from '@sentry/utils';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { adminState } from '../../../adminState';
import {
  CreateSessionPlaylistItemInput,
  GetApplicationScenesDocument,
  GetApplicationScenesQuery,
} from '../../../graphql/graphql-operations';
import useQueryErrorSnackbar from '../../../hooks/useQueryErrorSnackbar';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import { setToLocalStorageWithExpiration } from '../../../lib/localExpiringStorage';
import ContentWrapper from '../../components/ContentWrapper';
import { ListView } from '../../components/ListView';
import PageHeader from '../../components/PageHeader';
import ApplicationCard from './ApplicationCard';

type Scene = GetApplicationScenesQuery['applicationScenes'][0];

const SalesScenesPage = () => {
  const navigate = useNavigate();
  const { _t } = useTranslationPrefix('SalesScenesPage');
  const [selectedAppScene, setSelectedAppScene] = useState<string | null>(null);

  const {
    data: scenesData,
    error,
    refetch,
  } = useQuery(GetApplicationScenesDocument, {
    variables: {
      filter: {
        companyId: adminState.selectedCompanyId.get(),
        enabled: true,
        launchable: true,
      },
    },
  });
  useQueryErrorSnackbar(error, refetch);

  const scenes = scenesData?.applicationScenes || [];

  const handlePlaylistStarted = (
    playlistData: CreateSessionPlaylistItemInput[],
  ) => {
    const id = uuid4();
    setToLocalStorageWithExpiration(id, playlistData, 24 * 60 * 60 * 1000);
    navigate(`/devices/launch/${id}`);
  };

  const handleStartTraining = async () => {
    if (!selectedAppScene) {
      return;
    }

    handlePlaylistStarted([
      {
        sceneId: selectedAppScene,
      },
    ]);
  };

  const renderScene = (scene: Scene, idx: number) => {
    return (
      <ApplicationCard
        key={scene.id}
        id={scene.id}
        idx={idx + 1}
        selected={selectedAppScene === scene.id}
        onPress={() => {
          setSelectedAppScene(scene.id);
        }}
        name={scene.name_t}
      />
    );
  };

  return (
    <ContentWrapper>
      <PageHeader title={_t('title', 'Scenes')}>
        <Button
          id="sales-scene-start"
          variant="contained"
          disabled={!selectedAppScene}
          onClick={handleStartTraining}
        >
          <PlayArrowIcon sx={{ mr: 0.5 }} />
          {_t('startScene', 'Start Scene')}
        </Button>
      </PageHeader>
      <ListView
        rowKey={'id'}
        data={scenes}
        columns={{ xs: 1, sm: 2, md: 3 }}
        renderValue={renderScene}
      />
    </ContentWrapper>
  );
};

export default SalesScenesPage;
