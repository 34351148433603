import { BaseTypography } from '../../../base/components/BaseTypography';
import { Flex } from '../../../base/components/Flex';

interface Props {
  label: string;
  value?: React.ReactNode;
  flexGrow?: number;
}

const DetailValue = ({ label, value, flexGrow }: Props) => {
  return (
    <Flex flexDirection="column" flexGrow={flexGrow ?? 1}>
      <BaseTypography variant="h5" fontWeight="bold">
        {value ? value : '--'}
      </BaseTypography>
      <BaseTypography uppercase={true} style={{ opacity: 0.5 }}>
        {label}
      </BaseTypography>
    </Flex>
  );
};

export default DetailValue;
