import { Route, Routes } from 'react-router-dom';
import AuthCheck from '../../../base/components/UserProvider/AuthCheck';
import { UserPermission } from '../../../graphql/graphql-operations';
import NotFoundPage from '../NotFoundPage/NotFoundPage';
import TrainingReportsRoute from '../TrainingReports/TrainingReportsRoute';
import UserReportsRoute from '../UserReports/UserReportsRoute';
import UserTrainingDetailPage from './UserTrainingDetailPage';
import UserTrainingListPage from './UserTrainingListPage';

const TrainingRoute = () => {
  return (
    <Routes>
      <Route
        element={
          <AuthCheck permission={UserPermission.VIEW_MY_MEDICAL_REPORTS} />
        }
      >
        <Route path="/user/medical-reports/*" element={<UserReportsRoute />} />
      </Route>
      <Route
        element={
          <AuthCheck permission={UserPermission.VIEW_MY_TRAINING_REPORTS} />
        }
      >
        <Route
          path="/user/training-reports/*"
          element={<TrainingReportsRoute />}
        />
      </Route>
      <Route
        path="/user/:userTrainingId"
        element={<UserTrainingDetailPage />}
      />
      <Route path="/user" element={<UserTrainingListPage />} />

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default TrainingRoute;
