import { useQuery } from '@apollo/client';
import { Box } from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  GetUserDetailDocument,
  StreamType,
} from '../../../graphql/graphql-operations';
import useQueryErrorSnackbar from '../../../hooks/useQueryErrorSnackbar';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import useStreamType from '../../../lib/useStreamType';
import ContentWrapper from '../../components/ContentWrapper';
import { Loader } from '../../components/Loader';
import PageHeader from '../../components/PageHeader';
import UserDetailHeaderCard from './components/UserDetailHeaderCard';
import UserDetailList from './components/UserDetailList';
import UserDetailTabs, { UserDetailFilter } from './components/UserDetailTabs';

const UserDetailPage = () => {
  const trainingVariant = useStreamType() === StreamType.TRAINING;
  const [activeTab, setActiveTab] = useState(
    trainingVariant ? UserDetailFilter.TRAINING : UserDetailFilter.HISTORY,
  );
  const { userId } = useParams<{ userId?: string }>();
  const { data, loading, error, refetch } = useQuery(GetUserDetailDocument, {
    variables: {
      userId,
    },
    skip: !userId,
  });
  useQueryErrorSnackbar(error, refetch);

  const { _t } = useTranslationPrefix('UserDetailPage');

  return loading ? (
    <Loader />
  ) : (
    <ContentWrapper>
      <PageHeader back="../" title={_t('title', 'User profile')}></PageHeader>
      <UserDetailHeaderCard
        user={data?.user}
        sx={{ mb: 2 }}
        trainingVariant={trainingVariant}
      />
      <UserDetailTabs
        onChange={setActiveTab}
        activeTab={activeTab}
        user={data?.user}
      />
      <Box sx={{ mt: 2 }}>
        <UserDetailList activeTab={activeTab} userId={userId} />
      </Box>
    </ContentWrapper>
  );
};

export default UserDetailPage;
