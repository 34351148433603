import SearchOffIcon from '@mui/icons-material/SearchOff';
import { BoxProps } from '@mui/material';
import { Flex } from '../../../base/components/Flex';

interface Props {
  emptyMessage?: string;
  color?: BoxProps['color'];
  hideIcon?: boolean;
}

const ListViewEmpty = ({ emptyMessage, color, hideIcon }: Props) => {
  return (
    <Flex alignItems="center" justifyContent="center" color={color}>
      {!hideIcon && <SearchOffIcon fontSize="large" />}
      {emptyMessage && <span>{emptyMessage}</span>}
    </Flex>
  );
};

export default ListViewEmpty;
