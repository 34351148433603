import { useField } from 'formik';
import { CompanySelect } from '../CompanySelect';

interface Props {
  name: string;
  label?: string;
}

const FormikCompanySelect = ({ name, label }: Props) => {
  const [{ value }, { touched, error }, { setValue, setTouched }] =
    useField<string>(name);

  const handleOnChange = (newValue: string) => {
    if (!touched) {
      setTouched(true);
    }
    setValue(newValue);
  };

  return (
    <CompanySelect
      id={name}
      value={value}
      onValueChange={handleOnChange}
      error={touched && error}
      label={label}
    />
  );
};

export default FormikCompanySelect;
