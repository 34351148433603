import { useMutation } from '@apollo/client';
import { VariablesOf } from '@graphql-typed-document-node/core';
import { useState } from '@hookstate/core';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from '../../../../base/components/ConfirmDialog/ConfirmDialog';
import {
  DeleteVrSessionDocument,
  GetVrDeviceSessionsDocument,
} from '../../../../graphql/graphql-operations';
import useMutationSnackbar from '../../../../hooks/useMutationSnackbar';
import { Session } from './VrDeviceSessions';

type Variables = VariablesOf<typeof GetVrDeviceSessionsDocument>;
interface Props {
  session: Session;
  variables?: Variables;
  refetch: (variables: Variables | undefined) => void;
}

const SessionRowActions = ({ session, refetch, variables }: Props) => {
  const { t } = useTranslation();
  const deleteDialogOpened = useState(false);
  const toggleDeleteDialog = () => deleteDialogOpened.set((p) => !p);

  const deleteSessionHandler = useMutationSnackbar('deleteSession', {
    translationKey: 'vrSessionDetail.sessionsTable.actions.delete',
    onSuccessfullyCompleted: () => {
      toggleDeleteDialog();
      refetch(variables);
    },
  });
  const [deleteSession, { loading }] = useMutation(DeleteVrSessionDocument, {
    ...deleteSessionHandler,
  });

  const handleDeleteSession = () =>
    deleteSession({
      variables: { deleteSessionInput: { sessionId: session.id } },
    });

  return (
    <>
      <IconButton
        id={`session-row-action-delete-${session.id}`}
        onClick={toggleDeleteDialog}
        size="large"
      >
        <DeleteIcon color="error" />
      </IconButton>
      <ConfirmDialog
        open={deleteDialogOpened.get()}
        onClose={toggleDeleteDialog}
        onConfirm={handleDeleteSession}
        title={t(
          'vrDeviceSession.rowActions.deleteSession.title',
          'Do you want to delete this session?',
        )}
        confirmLabel={t(
          'vrDeviceSession.rowActions.deleteSession.confirmLabel',
          'Delete session',
        )}
        confirmButtonColor={'error'}
        confirmDisabled={loading}
      />
    </>
  );
};

export default SessionRowActions;
