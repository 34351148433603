import { useQuery } from '@apollo/client';
import { useEffect, useMemo, useState } from 'react';
import {
  GetUserDetailDocument,
  UserTrainingReportDetailDocument,
  UserTrainingReportDetailQuery,
  UserTrainingReportsDocument,
  UserTrainingReportsQuery,
} from '../../../graphql/graphql-operations';
import useQueryErrorSnackbar from '../../../hooks/useQueryErrorSnackbar';

export type UserTrainingReport =
  UserTrainingReportsQuery['userTrainingReports']['trainingReports'][0];

export type UserTrainingDetailReport = NonNullable<
  UserTrainingReportDetailQuery['userTrainingReports']['trainingDetail']
>;

export const useTrainingReport = (userId?: string) => {
  const [selectedSceneId, setSelectedSceneId] = useState<string | null>(null);
  const [selectedReportId, setSelectedReportId] = useState<string | null>(null);
  const {
    data: userData,
    loading: userLoading,
    error: userError,
    refetch: userRefetch,
  } = useQuery(GetUserDetailDocument, {
    variables: {
      userId,
    },
    skip: !userId,
  });
  useQueryErrorSnackbar(userError, userRefetch);

  const {
    data: reportsData,
    loading: reportsLoading,
    error: reportsError,
    refetch: reportsRefetch,
  } = useQuery(UserTrainingReportsDocument, {
    variables: {
      userId,
    },
    skip: !userId,
  });
  useQueryErrorSnackbar(reportsError, reportsRefetch);

  const {
    data: detailData,
    previousData: prevDetailData,
    error: detailError,
    refetch: detailRefetch,
  } = useQuery(UserTrainingReportDetailDocument, {
    variables: {
      userId: userId!,
      reportId: selectedReportId!,
    },
    skip: !userId || !selectedReportId,
  });
  useQueryErrorSnackbar(detailError, detailRefetch);

  //select latest scene
  useEffect(() => {
    setSelectedSceneId(
      reportsData?.userTrainingReports.trainingReports[0]?.sceneId || null,
    );
  }, [reportsData?.userTrainingReports.trainingReports]);

  //distinct scenes for filter
  const scenes = useMemo(() => {
    const sceneIdNameMap =
      reportsData?.userTrainingReports.trainingReports.reduce<
        Record<string, string>
      >(
        (prev, val) => ({
          [val.sceneId]: val.sceneName,
          ...prev,
        }),
        {},
      ) || {};
    return Object.entries(sceneIdNameMap).map((entry) => ({
      code: entry[0],
      label: entry[1],
    }));
  }, [reportsData?.userTrainingReports.trainingReports]);

  //reports matching selected scene
  const reports: UserTrainingReport[] = useMemo(() => {
    return (
      reportsData?.userTrainingReports.trainingReports.filter(
        (r) => r.sceneId === selectedSceneId,
      ) || []
    );
  }, [reportsData?.userTrainingReports.trainingReports, selectedSceneId]);

  //select latest report
  useEffect(() => {
    setSelectedReportId(reports[0]?.reportId || null);
  }, [reports]);

  return {
    user: userData?.user,
    scenes,
    selectedSceneId,
    setSelectedSceneId,
    selectedReportId,
    setSelectedReportId,
    reports,
    detailData:
      detailData?.userTrainingReports?.trainingDetail ||
      prevDetailData?.userTrainingReports?.trainingDetail,
    loading: userLoading || reportsLoading,
  };
};
