import { Route, Routes } from 'react-router-dom';
import NotFoundDetail from '../../components/NotFoundDetail/NotFoundDetail';
import ArchivedPatientsPage from './ArchivedPatientsPage';

const ArchivedRoute = () => {
  return (
    <Routes>
      <Route index element={<ArchivedPatientsPage />} />
      <Route path="*" element={<NotFoundDetail />} />
    </Routes>
  );
};

export default ArchivedRoute;
