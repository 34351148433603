import { Badge, styled, Tab, TabProps, Tabs, TabsProps } from '@mui/material';
import { BaseTypography } from '../BaseTypography';

export interface TabData {
  label: string;
  value: string;
  count?: number;
  shouldRender?: boolean;
}

interface Props extends Omit<TabsProps, 'children'> {
  tabs: TabData[];
  tabProps?: TabProps;
}

const BaseTabs = ({ tabs, tabProps, ...tabsProps }: Props) => {
  const renderTabLabel = (tab: TabData) => {
    const hasCount = typeof tab.count === 'number';
    if (!hasCount) {
      return <BaseTypography component="span">{tab.label}</BaseTypography>;
    }
    return (
      <Badge
        badgeContent={`${tab.count}`}
        max={99}
        variant="standard"
        invisible={false}
      >
        <BaseTypography component="span">{tab.label}</BaseTypography>
      </Badge>
    );
  };

  const resolveShoulRender = (tab: TabData): boolean => {
    if (typeof tab.shouldRender === 'boolean') {
      return tab.shouldRender;
    }
    return true;
  };

  return (
    <Tabs {...tabsProps}>
      {tabs.filter(resolveShoulRender).map((tab) => (
        <BastTabStyled
          {...tabProps}
          key={tab.value}
          value={tab.value}
          label={renderTabLabel(tab)}
        />
      ))}
    </Tabs>
  );
};

export default BaseTabs;

const BastTabStyled = styled(Tab)(
  ({ theme: { palette, spacing } }) => `
    text-transform: none;

    & .MuiBadge-badge {
        position: unset;
        transform: none;
        min-width: 3ch;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: ${spacing(1)};
        background-color: ${palette.primary.lighter};
        color: ${palette.primary.main};
    }

    &.Mui-selected .MuiBadge-badge {
        background-color: ${palette.primary.main};
        color: ${palette.primary.contrastText};
    }
`,
);
