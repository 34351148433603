import { SvgIcon, SvgIconProps } from '@mui/material';

const SittingIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M8.21664 6.12771C9.36322 6.12771 10.2929 5.20425 10.2929 4.06386C10.2929 2.92347 9.36322 2 8.21664 2C7.07005 2 6.13419 2.92347 6.13419 4.06386C6.13419 5.20425 7.06385 6.12771 8.21044 6.12771H8.21664Z"
        fill="currentColor"
      />
      <path
        d="M6.14039 11.3772L5 11.6809L6.62382 17.8353C6.74777 18.3125 7.18162 18.6472 7.67744 18.6472H12.5861V17.4696H7.74561L6.14039 11.3772Z"
        fill="currentColor"
      />
      <path
        d="M19.1309 20.3702L19.2549 20.302C18.3066 18.5543 16.627 15.1765 16.2985 14.5195C16.001 13.9183 15.3999 13.5465 14.7305 13.5465H10.7081L9.25167 8.01804H9.25786C9.1711 7.68956 8.96037 7.40446 8.67527 7.23092C8.38398 7.05739 8.0431 7.00781 7.71462 7.08838C7.39233 7.16895 7.11344 7.37967 6.9461 7.67097C6.77876 7.96226 6.72917 8.30934 6.81594 8.63782H6.82834L8.43976 14.7426C8.64428 15.5111 9.33843 16.0442 10.1317 16.0442H14.2719C14.8916 17.2899 16.2304 19.9549 17.0609 21.492L17.1786 21.43C17.3645 21.6779 17.6806 21.9134 17.9719 21.9754C18.2756 22.0374 18.5793 21.9816 18.8272 21.8143C19.0751 21.6469 19.2301 21.3866 19.2735 21.0829C19.3106 20.835 19.2549 20.5871 19.1371 20.3578L19.1309 20.3702Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default SittingIcon;
