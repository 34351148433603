import { Button } from '@mui/material';
import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BaseTabs } from '../../../base/components/BaseTabs';
import HasPermission from '../../../base/components/UserProvider/HasPermission';
import { useUser } from '../../../base/components/UserProvider/useUserHook';
import { UserPermission } from '../../../graphql/graphql-operations';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import ContentWrapper from '../../components/ContentWrapper';
import DebugTrainingAssignmentDialog, {
  DebugTrainingAssignmentFormValues,
} from '../../components/Debug/DebugTrainingAssignmentDialog';
import PageHeader from '../../components/PageHeader';
import { SearchField } from '../../components/SearchField';
import PlaylistAssignCompaniesTab from './components/PlaylistAssignCompaniesTab';
import PlaylistAssignGroupsTab from './components/PlaylistAssignGroupsTab';
import PlaylistAssignUsersTab from './components/PlaylistAssignUsersTab';
import usePlaylistAssignCompanies from './components/usePlaylistAssignCompanies';
import usePlaylistAssignGroups from './components/usePlaylistAssignGroups';
import usePlaylistAssignUsers from './components/usePlaylistAssignUsers';

enum TabValue {
  COMPANIES = 'COMPANIES',
  GROUPS = 'GROUPS',
  USERS = 'USERS',
}

const PlaylistTemplateAssignPage = () => {
  const { templateId } = useParams<{ templateId: string }>();
  const navigate = useNavigate();
  const { _t } = useTranslationPrefix('PlaylistTemplateAssignUsersPage');
  const { hasPermission } = useUser();
  const [query, setQuery] = useState('');
  const [debugDialogOpen, setDebugDialogOpen] = useState(false);
  const [tab, setTab] = useState(
    hasPermission(UserPermission.MANAGE_OTHERS_PLAYLIST_TEMPLATES)
      ? TabValue.COMPANIES
      : TabValue.GROUPS,
  );
  const onCompleted = useCallback(
    () => navigate(`/playlist-templates/${templateId}`),
    [navigate, templateId],
  );

  const {
    assignSelectedUsers,
    selectedUserIds,
    setSelectedUserIds,
    userAssignmentSubmitting,
    usersCount,
  } = usePlaylistAssignUsers({ templateId, onCompleted });

  const {
    groups,
    assignToGroups,
    groupAssignmentSubmitting,
    selectedUserGroupIds,
    setSelectedUserGroupIds,
  } = usePlaylistAssignGroups({ templateId, onCompleted });

  const {
    companies,
    setSelectedCompanies,
    assignCompaniesDisabled,
    assignCompanies,
  } = usePlaylistAssignCompanies({
    templateId,
    onCompleted,
  });

  const handleConfirm = async (vals?: DebugTrainingAssignmentFormValues) => {
    switch (tab) {
      case TabValue.USERS:
        await assignSelectedUsers();
        break;
      case TabValue.GROUPS:
        await assignToGroups();
        break;
      case TabValue.COMPANIES:
        await assignSelectedUsers();
        break;
    }
    setDebugDialogOpen(false);
  };

  return (
    <ContentWrapper>
      <PageHeader
        title={
          tab === TabValue.COMPANIES
            ? _t('titleSharePlaylist', 'Share Playlist')
            : _t('title', 'Assign training')
        }
        back
      >
        {tab === TabValue.COMPANIES && (
          <Button
            id="share-playlist"
            variant="contained"
            color="primary"
            disabled={assignCompaniesDisabled}
            onClick={assignCompanies}
          >
            {assignCompaniesDisabled
              ? _t('comanies.selectFirst', 'Change selection')
              : _t('finish', 'Finish')}
          </Button>
        )}
        {tab === TabValue.USERS && (
          <Button
            id="assign-training"
            variant="contained"
            color="primary"
            disabled={!selectedUserIds.length || userAssignmentSubmitting}
            onClick={() =>
              hasPermission(UserPermission.DEBUG)
                ? setDebugDialogOpen(true)
                : handleConfirm()
            }
          >
            {!selectedUserIds.length
              ? _t('users.selectFirst', 'Select users')
              : _t('finish', 'Finish')}
          </Button>
        )}
        {tab === TabValue.GROUPS && (
          <Button
            id="assign-training"
            variant="contained"
            color="primary"
            disabled={!selectedUserGroupIds.length || groupAssignmentSubmitting}
            onClick={() =>
              hasPermission(UserPermission.DEBUG)
                ? setDebugDialogOpen(true)
                : handleConfirm()
            }
          >
            {!selectedUserGroupIds.length
              ? _t('groups.selectFirst', 'Select groups')
              : _t('finish', 'Finish')}
          </Button>
        )}

        <SearchField value={query} onValueChange={setQuery}></SearchField>
      </PageHeader>
      <BaseTabs
        value={tab}
        onChange={(e, val) => setTab(val)}
        sx={{ mb: 2 }}
        tabs={[
          {
            label: _t('tabs.companies', 'Org. units'),
            value: TabValue.COMPANIES,
            count: companies.length,
            shouldRender: hasPermission(
              UserPermission.MANAGE_OTHERS_PLAYLIST_TEMPLATES,
            ),
          },
          {
            label: _t('tabs.groups', 'Groups'),
            value: TabValue.GROUPS,
            count: groups.length,
            shouldRender: hasPermission(
              UserPermission.MANAGE_TRAINING_ASSIGNMENTS,
            ),
          },
          {
            label: _t('tabs.users', 'Users'),
            value: TabValue.USERS,
            count: usersCount,
            shouldRender: hasPermission(
              UserPermission.MANAGE_TRAINING_ASSIGNMENTS,
            ),
          },
        ]}
      />
      <HasPermission val={UserPermission.MANAGE_OTHERS_PLAYLIST_TEMPLATES}>
        {tab === TabValue.COMPANIES && (
          <PlaylistAssignCompaniesTab
            query={query}
            companies={companies}
            setSelectedCompanies={setSelectedCompanies}
          />
        )}
      </HasPermission>
      <HasPermission val={UserPermission.MANAGE_TRAINING_ASSIGNMENTS}>
        {tab === TabValue.USERS && (
          <PlaylistAssignUsersTab
            selectedUserIds={selectedUserIds}
            setSelectedUserIds={setSelectedUserIds}
            query={query}
          />
        )}
        {tab === TabValue.GROUPS && (
          <PlaylistAssignGroupsTab
            groups={groups}
            selectedUserGroupIds={selectedUserGroupIds}
            setSelectedUserGroupIds={setSelectedUserGroupIds}
            query={query}
          />
        )}
        <DebugTrainingAssignmentDialog
          open={debugDialogOpen}
          onConfirm={handleConfirm}
          onClose={() => setDebugDialogOpen(false)}
        />
      </HasPermission>
    </ContentWrapper>
  );
};

export default PlaylistTemplateAssignPage;
