import { useMutation } from '@apollo/client';
import {
  ActivateUsersDocument,
  ChangeCompanyStatusDocument,
  CompanyStatus,
  GetCompaniesDocument,
  SuspendUsersDocument,
} from '../../../graphql/graphql-operations';
import useMutationSnackbar from '../../../hooks/useMutationSnackbar';

interface Options {
  onCompleted: () => void;
}

export const useCompanyStatusMutation = ({ onCompleted }: Options) => {
  const companyStatusHandler = useMutationSnackbar('changeCompanyStatus', {
    translationKey: 'companies.rowActions.companyStatus',
    onSuccessfullyCompleted: () => onCompleted(),
  });

  const [companyStatusMutation, { loading }] = useMutation(
    ChangeCompanyStatusDocument,
    {
      ...companyStatusHandler,
      refetchQueries: [
        {
          query: GetCompaniesDocument,
          variables: {
            companyFilter: { status: CompanyStatus.ACTIVE },
          },
        },
        {
          query: GetCompaniesDocument,
          variables: {
            companyFilter: { status: CompanyStatus.SUSPENDED },
          },
        },
      ],
    },
  );

  return { companyStatusMutation, loading };
};

export const useCompanyUserStatusMutation = ({ onCompleted }: Options) => {
  const suspendUserHandler = useMutationSnackbar('suspendUsers', {
    translationKey: 'companyUserStatusMutation.suspend',
    onSuccessfullyCompleted: onCompleted,
  });

  const [suspendUsers] = useMutation(SuspendUsersDocument, {
    ...suspendUserHandler,
  });

  const activateUserHandler = useMutationSnackbar('activateUsers', {
    translationKey: 'companyUserStatusMutation.reactivate',
    onSuccessfullyCompleted: onCompleted,
  });

  const [activateUsers] = useMutation(ActivateUsersDocument, {
    ...activateUserHandler,
  });

  return {
    suspendUsers,
    activateUsers,
  };
};
