import { useQuery } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { useHookstate } from '@hookstate/core';
import { useMemo, useState } from 'react';
import { adminState } from '../../../../adminState';
import {
  AddTrainingAssigmentForUsersDocument,
  GetUsersDocument,
  GetUserTrainingAssignmentsDocument,
  StreamType,
  TrainingAssignmentUsersInput,
  UserStatus,
} from '../../../../graphql/graphql-operations';
import { useMutationWithSnackbar } from '../../../../hooks/useMutationWithSnackbar';
import {
  MEDICAL_ASSIGNMENT_GRACE_PERIOD_DEFAULT,
  MEDICAL_ASSIGNMENT_VALID_FOR_DEFAULT,
  TRAINING_ASSIGNMENT_GRACE_PERIOD_DEFAULT,
  TRAINING_ASSIGNMENT_VALID_FOR_DEFAULT,
} from '../../../../lib/constants';
import useStreamType from '../../../../lib/useStreamType';
import { DebugTrainingAssignmentFormValues } from '../../../components/Debug/DebugTrainingAssignmentDialog';

interface Params {
  templateId?: string;
  onCompleted: () => void;
}

const usePlaylistAssignUsers = ({ templateId, onCompleted }: Params) => {
  const streamType = useStreamType();
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
  const companyId = useHookstate(adminState.selectedCompanyId).get();
  const queryVariables = useMemo(
    () => ({
      first: 0, //do not load them, interested just in total count
      filter: {
        companyId: companyId,
        filter: {
          status: UserStatus.ACTIVE,
        },
      },
    }),
    [companyId],
  );
  const { data: countData } = useQuery(GetUsersDocument, {
    variables: queryVariables,
    fetchPolicy: 'no-cache',
  });

  const [createAssignmnet, { loading }] = useMutationWithSnackbar(
    'PlaylistTemplateAssignUsersPage.mutation',
    AddTrainingAssigmentForUsersDocument,
    {
      onCompleted: onCompleted,
      refetchQueries: [getOperationName(GetUserTrainingAssignmentsDocument)!],
    },
  );

  const assignSelectedUsers = async (
    vals?: DebugTrainingAssignmentFormValues,
  ) => {
    const input: TrainingAssignmentUsersInput = {
      userIds: selectedUserIds,
      playlistTemplateId: templateId!,
      gracePeriodDays:
        streamType === StreamType.MEDICAL
          ? MEDICAL_ASSIGNMENT_GRACE_PERIOD_DEFAULT
          : TRAINING_ASSIGNMENT_GRACE_PERIOD_DEFAULT,
      validForDays:
        streamType === StreamType.MEDICAL
          ? MEDICAL_ASSIGNMENT_VALID_FOR_DEFAULT
          : TRAINING_ASSIGNMENT_VALID_FOR_DEFAULT,
      ...vals,
      validTo: vals?.validTo?.toISOString() || null,
    };
    await createAssignmnet({
      variables: {
        input: input,
      },
    });
  };

  return {
    userAssignmentSubmitting: loading,
    selectedUserIds,
    setSelectedUserIds,
    assignSelectedUsers,
    usersCount: countData?.users.totalCount || 0,
  };
};

export default usePlaylistAssignUsers;
