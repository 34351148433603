import { useQuery } from '@apollo/client';
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { equals } from 'ramda';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { Flex } from '../../../base/components/Flex';
import HasPermission from '../../../base/components/UserProvider/HasPermission';
import {
  CompanyFeature,
  EditCompanyFeaturesDocument,
  GetCompanyDocument,
  UserPermission,
} from '../../../graphql/graphql-operations';
import { useMutationWithSnackbar } from '../../../hooks/useMutationWithSnackbar';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import { onlyUnique } from '../../../lib/arrayFilters';
import { COMPANY_FEATURES } from '../../../lib/companyFeatures';
import { TopBar } from '../../components/TopBar';

const CompanyFeaturesPage = () => {
  const [selected, setSelected] = useState<CompanyFeature[]>([]);
  const { _t, t } = useTranslationPrefix('CompanyFeaturesPage');
  const { companyId } = useParams<{ companyId: string }>();
  const { data, loading } = useQuery(GetCompanyDocument, {
    variables: {
      companyIdInput: {
        companyId: companyId,
      },
    },
    skip: !companyId,
  });

  const company = data?.company;
  const original = useMemo(
    () => (company?.features ? [...company.features] : []),
    [company],
  );
  useEffect(() => setSelected(original), [original]);
  const isDirty = !equals(new Set(original), new Set(selected));

  const [editFeatures, { loading: saving }] = useMutationWithSnackbar(
    'editCompanyFeaturesMutation',
    EditCompanyFeaturesDocument,
  );

  const handleChange =
    (feature: CompanyFeature) =>
    (e: React.ChangeEvent<HTMLInputElement>, selected: boolean) => {
      setSelected((s) =>
        selected
          ? [...s, feature].filter(onlyUnique)
          : s.filter((v) => v !== feature),
      );
    };

  const handleSave = async () => {
    if (!company?.id) {
      return;
    }
    await editFeatures({
      variables: {
        input: {
          companyId: company.id,
          features: selected,
        },
      },
    });
  };

  if (loading || !company) return <CircularProgress />;

  return (
    <>
      <TopBar
        leftSideText={_t('title', 'Features of {{companyName}}', {
          companyName: company.name,
        })}
        actions={
          <HasPermission val={UserPermission.DEBUG}>
            <Button
              id="saveCompanyFeatures"
              disabled={!isDirty || saving}
              variant="contained"
              onClick={handleSave}
            >
              {_t('save', 'Save changes')}
            </Button>
          </HasPermission>
        }
      />
      <HasPermission val={UserPermission.DEBUG}>
        <Card>
          <CardContent>
            <Flex flexDirection="column" gap={2}>
              {COMPANY_FEATURES.map((feature) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={selected.includes(feature)}
                      onChange={handleChange(feature)}
                    />
                  }
                  label={t(`enum.companyFeature.${feature}`)}
                />
              ))}
            </Flex>
          </CardContent>
        </Card>
      </HasPermission>
    </>
  );
};

export default CompanyFeaturesPage;
