import { Button } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { BaseDialog } from '../../../../base/components/BaseDialog';
import { BaseTypography } from '../../../../base/components/BaseTypography';
import { Flex } from '../../../../base/components/Flex';
import FormikFileSelect from '../../../../base/components/form/FormikFileSelect';
import FormikForm from '../../../../base/components/form/FormikForm';
import {
  TFunctionPrefixed,
  useTranslationPrefix,
} from '../../../../hooks/useTranslationPrefix';

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: (file: File) => Promise<void>;
}

interface FormData {
  file: File | null;
}

const CompanyLogoDialog = ({ open, onClose, onConfirm }: Props) => {
  const { _t, t } = useTranslationPrefix('CompanyLogoDialog');

  const handleSubmit = async ({ file }: FormData) => {
    await onConfirm(file!);
  };

  return (
    <BaseDialog
      open={open}
      title={_t('title', 'Upload new logo')}
      onClose={onClose}
    >
      <Formik<FormData>
        initialValues={{
          file: null,
        }}
        onSubmit={handleSubmit}
        validationSchema={getValidationSchema(_t)}
      >
        {({ isSubmitting, setFieldValue, values }) => (
          <FormikForm>
            <BaseTypography mb={2}>
              {_t(
                'svgHelp',
                `SVG file should be visible in both dark/light modes. Inside the
              file currentColor can be used as fill="currentColor" or
              stroke="currentColor" to change from black to white depending on
              mode and context.`,
              )}
            </BaseTypography>
            <FormikFileSelect
              name="file"
              label={_t('input.title', 'SVG file')}
              accept="image/svg+xml"
              disabled={isSubmitting}
              color="primary"
            />
            <Flex justifyContent="space-between">
              <Button
                id="upload-logo-cancel"
                size="large"
                color="primary"
                onClick={onClose}
              >
                {t('common.cancel', 'Cancel')}
              </Button>
              <Button
                id="upload-logo-confirm"
                type="submit"
                size="large"
                color="primary"
                variant="contained"
                disabled={isSubmitting}
              >
                {_t('save', 'Save')}
              </Button>
            </Flex>
          </FormikForm>
        )}
      </Formik>
    </BaseDialog>
  );
};

const getValidationSchema = (t: TFunctionPrefixed) =>
  Yup.object({
    file: Yup.mixed()
      .test('validation.fileSize', 'The file is too large', (value) => {
        const val = Array.isArray(value)
          ? value.length
            ? value[0]
            : undefined
          : value;
        if (!val?.size) return true;
        return val.size <= 2000000;
      })
      .test('validation.required', 'SVG file is required', (value) => {
        const val = Array.isArray(value) ? value[0] : value;
        if (!val?.size) return false;
        return val.size >= 100;
      }),
  });

export default CompanyLogoDialog;
