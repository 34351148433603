import { useQuery } from '@apollo/client';
import { styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ReactComponent as VrCardboard } from '../../../../assets/svg/vr-cardboard-solid.svg';
import {
  DeviceState,
  GetVrDeviceDetailDocument,
  GetVrDeviceDetailQuery,
} from '../../../../graphql/graphql-operations';
import useQueryErrorSnackbar from '../../../../hooks/useQueryErrorSnackbar';
import DetailValue from '../../../components/DetailCard/DetaiValue';
import { SectionPaper } from '../../../components/SectionPaper';
import { TopBar } from '../../../components/TopBar';
import Actions from './Actions';
import VrDeviceDebugLaunchUrl from './VrDeviceDebugLaunchUrl';
import VrDeviceSessions from './VrDeviceSessions';

export type VrDevice = GetVrDeviceDetailQuery['vrDevice'];

const VrDeviceDetailPage = () => {
  const { t } = useTranslation();
  const { vrDeviceId } = useParams<{ vrDeviceId: string }>();
  const { data, error, refetch } = useQuery(GetVrDeviceDetailDocument, {
    variables: { vrDeviceInput: { id: vrDeviceId } },
    skip: !vrDeviceId,
  });
  useQueryErrorSnackbar(error, refetch);
  const vrDevice = data?.vrDevice;

  if (!vrDevice) {
    return <div>Not found</div>;
  }
  return (
    <>
      <TopBar
        leftSideText={
          <>
            <IconWrapper style={{ fill: vrDevice?.color ?? 'yellowgreen' }}>
              <VrCardboard />
            </IconWrapper>
            {vrDevice?.name}
          </>
        }
        actions={
          <Actions
            canLaunchApplication={vrDevice.status.state === DeviceState.ONLINE}
            vrDevice={vrDevice}
          />
        }
      />
      <SectionPaper>
        <DetailContainer>
          <DetailValue
            flexGrow={0}
            value={
              vrDevice.status?.battery
                ? `${vrDevice.status.battery} %`
                : undefined
            }
            label={t('vrSessionDetail.batteryLabel', 'Battery')}
          />
          <DetailValue
            flexGrow={0}
            value={t(
              `common.deviceState.${
                vrDevice.status?.state || DeviceState.OFFLINE
              }`,
            )}
            label={t('vrSessionDetail.stateLabel', 'Status')}
          />
        </DetailContainer>
      </SectionPaper>
      <VrDeviceDebugLaunchUrl deviceId={vrDeviceId!} />
      <TableContainer>
        <VrDeviceSessions vrDeviceId={vrDeviceId!} />
      </TableContainer>
    </>
  );
};

export default VrDeviceDetailPage;

const IconWrapper = styled('div')(({ theme }) => ({
  width: theme.spacing(8),
  verticalAlign: 'middle',
  display: 'inline-block',
  paddingLeft: theme.spacing(2),
}));

const DetailContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  '& > *:not(:last-child)': {
    marginRight: theme.spacing(10),
  },
}));

const TableContainer = styled(SectionPaper)(({ theme }) => ({
  marginTop: theme.spacing(3),
}));
