import { useMutation } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { useState } from '@hookstate/core';
import { Button } from '@mui/material';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { adminState } from '../../../../adminState';
import { BaseDialog } from '../../../../base/components/BaseDialog';
import { Flex } from '../../../../base/components/Flex';
import FormikForm from '../../../../base/components/form/FormikForm';
import FormikTextField from '../../../../base/components/form/FormikTextField';
import {
  CreatePlaylistTemplateFolderDocument,
  EditPlaylistTemplateFolderDocument,
  GetFoldersDocument,
  GetPlaylistTemplatesFoldersDocument,
} from '../../../../graphql/graphql-operations';
import {
  TFunctionPrefixed,
  useTranslationPrefix,
} from '../../../../hooks/useTranslationPrefix';

interface FolderForm {
  name: string;
}

interface Props {
  folderId?: string | number | null;
  open: boolean;
  onClose: () => void;
  folderName?: string;
  onSuccess?: (folderId: string) => void;
}

const PlaylistTemplateFolderDialog = ({
  folderId,
  open,
  onClose,
  folderName,
  onSuccess,
}: Props) => {
  const { _t } = useTranslationPrefix('PlaylistTemplateFolderDialog');
  const navigate = useNavigate();

  const [createPlaylistFolder] = useMutation(
    CreatePlaylistTemplateFolderDocument,
  );

  const [editPlaylistFolder] = useMutation(EditPlaylistTemplateFolderDocument);
  const companyId = useState(adminState.selectedCompanyId).get();

  const handleSubmit = async (values: FolderForm) => {
    if (folderId == null) {
      createPlaylistFolder({
        variables: {
          input: {
            name: values.name,
            companyId,
          },
        },
        onCompleted: (data) => {
          if (!onSuccess) {
            navigate(`folders/${data.createPlaylistTemplateFolder.id}`);
            return;
          }
          onSuccess(data.createPlaylistTemplateFolder.id);
        },
        refetchQueries: [
          getOperationName(GetPlaylistTemplatesFoldersDocument)!,
          getOperationName(GetFoldersDocument)!,
        ],
      });
    } else {
      editPlaylistFolder({
        variables: {
          input: {
            id: folderId.toString(),
            name: values.name,
          },
        },
        onCompleted: () => {
          onClose();
        },
      });
    }
  };

  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      title={_t('title', "Enter folder's name")}
      disableEnforceFocus
    >
      <>
        <Formik<FolderForm>
          initialValues={{
            name: folderName || '',
          }}
          onSubmit={handleSubmit}
          enableReinitialize
          validationSchema={getValidationSchema(_t)}
        >
          {({ isSubmitting }) => (
            <FormikForm>
              <FormikTextField
                variant="outlined"
                name="name"
                label={_t('form.name', 'Name')}
                inputProps={{
                  autoComplete: 'off',
                }}
              />
              <Flex
                justifyContent="center"
                mt={1}
                gap={3}
                flexDirection={{ xs: 'column-reverse', md: 'row' }}
              >
                <Button
                  id="create-playlist-folder-cancel"
                  onClick={onClose}
                  color="primary"
                  variant="outlined"
                  disabled={isSubmitting}
                >
                  {_t('form.cancel', 'Cancel')}
                </Button>
                <Button
                  id="create-playlist-folder-confirm"
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={isSubmitting}
                >
                  {_t('form.submit', 'Save')}
                </Button>
              </Flex>
            </FormikForm>
          )}
        </Formik>
      </>
    </BaseDialog>
  );
};

export default PlaylistTemplateFolderDialog;

const getValidationSchema = (_t: TFunctionPrefixed) =>
  Yup.object({
    name: Yup.string().required(_t('form.required', 'Required')),
  });
