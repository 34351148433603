import { State, useState } from '@hookstate/core';
import escapeStringRegexp from 'escape-string-regexp';
import humps from 'humps';
import { createContext, useMemo } from 'react';
import { useSessionStorage } from 'react-use';
import {
  Maybe,
  OrderDirection,
  PageCursor,
} from '../../../graphql/graphql-operations';
import variableStore from '../../../variableStore';
import {
  BaseRefetchTableVariables,
  RefetchTable,
  TableRowProps,
} from './types';
import { getPropValue } from './utils';

type ObjectKeys = string | number | symbol;
interface BaseTableContextType extends TableState {
  id?: string;
  rowsPerPage: number;
  setRowsPerPage: (rowsPerPage: number) => void;
  setPage: (newPage: number) => void;
  setOrder: (id: ObjectKeys, direction: OrderDirection) => void;
  setSearch: (id: ObjectKeys | null, value: string) => void;
  rows: TableRowProps<any>[];
  numberOfRows: number;
  totalCount: number;
}

// This is bad default, but will not use this context outside provider, so we will never get this value.
export const BaseTableContext = createContext<BaseTableContextType>({
  rowsPerPage: 15,
  setRowsPerPage: () => {},
  setPage: () => {},
  setOrder: () => {},
  setSearch: () => {},
  rows: [],
  numberOfRows: 0,
  totalCount: 0,
  page: 0,
  orderDirection: OrderDirection.ASC,
  orderField: '',
  filterQuery: '',
  filterField: null,
});

interface Props<T, K extends BaseRefetchTableVariables> {
  id: string;
  orderDirection: OrderDirection;
  orderField: keyof T;
  children: React.ReactNode | null;
  rows: TableRowProps<T>[];
  refetchTable: RefetchTable<K> | undefined;
}

interface TableState {
  page: number;
  orderDirection: OrderDirection;
  orderField: ObjectKeys;
  filterQuery: string;
  filterField: ObjectKeys | null;
}

export const BaseTableProvider = <
  T extends {},
  K extends BaseRefetchTableVariables,
>({
  id,
  children,
  orderDirection,
  orderField,
  rows,
  refetchTable = {},
}: Props<T, K>) => {
  const { pageCursors, variables, refetch } = refetchTable;
  const [rowsPerPage, setRowsPerPage] = useSessionStorage('rowsPerPage', 15);
  const tableState = useState<TableState>({
    page: 0,
    orderDirection,
    orderField,
    filterQuery: '',
    filterField: null,
  });
  const filterField = tableState.filterField.get() as keyof T;
  const filterQuery = tableState.filterQuery.get();
  const filteredRows = useMemo(
    () => filter(rows, filterField, filterQuery),
    [filterField, filterQuery, rows],
  );

  const handleRefetch = async (variables: K) => {
    if (refetch) {
      await refetch(variables);
      variableStore.setUsersVariables(variables);
    } else {
      throw new Error(
        'Called handleRefetch, but refetch method is not defined!',
      );
    }
  };
  const handleChangePage = (newPage: number) => {
    if (pageCursors) {
      const page = Object.entries(pageCursors).find(
        ([_, value]: [string, Maybe<PageCursor> | 'PageCursors']) =>
          typeof value !== 'string' && value?.pageNumber === newPage + 1,
      );
      if (page && page[1] && variables) {
        const pageCursor = page[1] as PageCursor;
        handleRefetch({ ...variables, after: pageCursor.cursor });
      }
    } else {
      tableState.page.set(newPage);
    }
  };

  const handleChangeRowsPerPage = async (rowsPerPage: number) => {
    if (refetch && variables) {
      await handleRefetch({ ...variables, after: null, first: rowsPerPage });
    } else {
      tableState.page.set(0);
    }
    setRowsPerPage(rowsPerPage);
  };

  const handleChangeOrder = (id: ObjectKeys, direction: OrderDirection) => {
    if (refetch && variables) {
      handleRefetch(getOrderVariables(id as keyof T, direction, variables));
    } else {
      tableState.batch(() => {
        tableState.orderDirection.set(direction);
        tableState.orderField.set(id);
        tableState.page.set(0);
      });
    }
  };

  const handleSearchChange = (id: ObjectKeys | null, value: string) => {
    const isNotEmpty = !!value.length;
    if (refetch && variables) {
      handleRefetch(
        getFilterVariables(
          isNotEmpty ? (id as keyof T) : null,
          isNotEmpty ? value : null,
          variables,
        ),
      );
    } else {
      tableState.batch(() => {
        tableState.filterQuery.set(value);
        tableState.filterField.set(isNotEmpty ? id : null);
        tableState.page.set(0);
      });
    }
  };

  return (
    <BaseTableContext.Provider
      value={{
        id: id,
        setRowsPerPage: handleChangeRowsPerPage,
        setPage: handleChangePage,
        setOrder: handleChangeOrder,
        setSearch: handleSearchChange,
        rowsPerPage,
        rows: filteredRows,
        numberOfRows: refetchTable?.totalCount ?? filteredRows.length,
        totalCount: refetchTable?.totalCount ?? rows.length,
        ...getTableState(tableState, refetchTable),
      }}
    >
      {children}
    </BaseTableContext.Provider>
  );
};

function getTableState<K extends BaseRefetchTableVariables>(
  tableState: State<TableState>,
  refetchTable: RefetchTable<K> | undefined = {},
): TableState {
  const { variables, pageCursors } = refetchTable;
  const orderDirection = variables?.orderBy?.direction;
  const orderField = variables?.orderBy?.field;
  let page = 0;
  if (pageCursors?.next) {
    page = pageCursors.next.pageNumber - 2;
  } else if (pageCursors?.previous) {
    page = pageCursors.previous.pageNumber;
  }
  if (!orderDirection || !orderField) {
    return tableState.get();
  }
  const filterField = variables?.filterBy?.field.toLowerCase() || null;
  const filterQuery = variables?.filterBy?.query || '';
  return {
    page,
    orderDirection,
    orderField,
    filterField,
    filterQuery,
  };
}

function filter<T>(
  rows: TableRowProps<T>[],
  filterField: keyof T | null,
  filterQuery: string | null,
) {
  if (!filterField || !filterQuery) {
    return rows;
  }
  // remove all special character, or it could break search regex
  filterQuery = escapeStringRegexp(filterQuery);
  const regex = new RegExp(`.*${filterQuery}.*`, 'gi');
  return rows.filter((r) => {
    const label = getPropValue(r.rowContent, filterField)?.label;
    if (!label) return false;
    return (label as string).match(regex);
  });
}

function getOrderVariables<T, K extends BaseRefetchTableVariables>(
  id: keyof T,
  direction: OrderDirection,
  variables: K,
) {
  const field = humps.decamelize(id.toString()).toLocaleUpperCase();
  return {
    ...variables,
    after: null,
    orderBy: {
      field,
      direction,
    },
  };
}
function getFilterVariables<T, K extends BaseRefetchTableVariables>(
  id: keyof T | null,
  query: string | null,
  variables: K,
) {
  if (!id) {
    return {
      ...variables,
      after: null,
      filterBy: null,
    };
  }
  const field = id.toString().toLocaleUpperCase();
  return {
    ...variables,
    after: null,
    filterBy: {
      field,
      query,
    },
  };
}
