import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CompanyStatus } from '../../../graphql/graphql-operations';
import { handleOnClickToggle } from '../../../lib/EventHandlers';
import NewCompanyDialog from './NewCompanyDialog';

interface Props {
  companyStatus: CompanyStatus;
}

const Actions = ({ companyStatus }: Props) => {
  const { t } = useTranslation();
  const [newClientOpened, setNewClientOpened] = useState(false);

  const handleDialogClose = () => setNewClientOpened((prev) => !prev);
  const idActive = companyStatus === CompanyStatus.ACTIVE;
  return (
    <>
      <Button
        id="companies-page-actions-change-status"
        size="large"
        color="primary"
        variant="text"
        style={{ marginRight: '1rem' }}
        component={Link}
        to={idActive ? '/admin/companies/suspended' : '/admin/companies'}
      >
        {t(
          `companies.actions.${companyStatus.toLowerCase()}.linkTo`,
          'Archived clients',
        )}
      </Button>
      {idActive && (
        <Button
          id="companies-page-actions-create"
          size="large"
          color="primary"
          variant="contained"
          startIcon={<AddIcon viewBox="5 5 14 14" />}
          onClick={handleOnClickToggle(handleDialogClose)}
        >
          {t('companies.actions.newClient', 'New Client')}
        </Button>
      )}
      <NewCompanyDialog open={newClientOpened} onClose={handleDialogClose} />
    </>
  );
};

export default Actions;
