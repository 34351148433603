import { Box, Card, CardContent, CardProps, Divider } from '@mui/material';
import { ReactNode } from 'react';
import { BaseTypography } from '../../../../base/components/BaseTypography';
import { Flex } from '../../../../base/components/Flex';
import { LabeledValue } from '../../../components/LabeledValue';

interface Props extends CardProps {
  label: React.ReactNode;
  value: React.ReactNode;
  icon?: ReactNode;
  secondValue?: string;
  secondLabel?: string;
}

const ReportValue = ({
  label,
  value,
  title,
  icon,
  secondLabel,
  secondValue,
  ...cardProps
}: Props) => {
  return (
    <Card
      {...cardProps}
      sx={{
        display: 'flex',
        padding: '1.25rem',
        width: '100%',
        flexGrow: 1,
        ...(cardProps?.sx || {}),
      }}
    >
      <CardContent sx={{ display: 'flex', width: '100%' }}>
        <Box width={'100%'}>
          <Flex display={'flex'} alignItems={'center'} mb={2}>
            {icon && <Box mr={3}>{icon}</Box>}
            {title && (
              <BaseTypography variant={{ xs: 'h4' }} fontWeight="bold">
                {title}
              </BaseTypography>
            )}
          </Flex>

          <LabeledValue
            label={label}
            value={value}
            sx={{
              display: 'flex',
              flexGrow: 1,
              justifyContent: 'space-evenly',
            }}
          />
          {!!(secondLabel && secondValue) && (
            <>
              <Divider variant="fullWidth" sx={{ mt: 3, mb: 3 }} flexItem />
              <LabeledValue
                label={secondLabel}
                value={secondValue}
                sx={{
                  display: 'flex',
                  flexGrow: 1,
                  justifyContent: 'space-evenly',
                }}
              />
            </>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default ReportValue;
