import { StringMap, TOptions } from 'i18next';
import { useTranslation } from 'react-i18next';

export type TFunctionPrefixed = (
  key: string,
  defaultValue?: string,
  options?: TOptions<StringMap>,
) => string;

export const useTranslationPrefix = (prefix: string) => {
  const { t, ...rest } = useTranslation();

  const _t: TFunctionPrefixed = (
    key: string,
    defaultValue?: string,
    options?: TOptions<StringMap>,
  ) => {
    return t(`${prefix}.${key}`, defaultValue, options);
  };

  return {
    t,
    _t,
    ...rest,
  };
};
