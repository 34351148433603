import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useUser } from '../../../../base/components/UserProvider/useUserHook';
import { GetUserDetailDocument } from '../../../../graphql/graphql-operations';
import useQueryErrorSnackbar from '../../../../hooks/useQueryErrorSnackbar';

export const useReportUser = () => {
  const { userId } = useParams<{ userId?: string }>();
  const { user } = useUser();

  const {
    data: userData,
    loading: userLoading,
    error: userError,
    refetch: userRefetch,
  } = useQuery(GetUserDetailDocument, {
    variables: {
      userId: userId ? userId : user?.id,
    },
  });
  useQueryErrorSnackbar(userError, userRefetch);

  return {
    userId: userData?.user.id,
    backUrl: `../../${userId || 'user'}`,
    userData,
    userLoading,
  };
};
