import { Card } from '@mui/material';
import { styled } from '@mui/system';
import { Flex } from '../../../base/components/Flex';
import ImagePlaceholder from '../../../base/components/ImagePlaceholder/ImagePlaceholder';
import { GetVrDeviceScenesQuery } from '../../../graphql/graphql-operations';

type LaunchableScene = GetVrDeviceScenesQuery['vrDevice']['scenes'][0];

interface Props {
  scenes: LaunchableScene[];
  onSceneSelected: (scene: LaunchableScene) => void;
}

const SelectApplicationScene = ({ scenes, onSceneSelected }: Props) => {
  const renderScene = (ls: LaunchableScene) => {
    return (
      <SelectApplicationSceneCard
        key={ls.scene.id}
        onClick={() => onSceneSelected(ls)}
      >
        {ls.scene.imageUrl ? (
          <SelectApplicationSceneCardImage
            alt={ls.scene.name_t || 'Scene logo'}
            src={ls.scene.imageUrl}
          />
        ) : (
          <SelectApplicationSceneCardImagePlaceholder />
        )}
        <SelectApplicationSceneCardLabel>
          {ls.scene.name_t}
        </SelectApplicationSceneCardLabel>
      </SelectApplicationSceneCard>
    );
  };

  return (
    <Flex flexDirection="row" flexWrap="wrap">
      {scenes.map(renderScene)}
    </Flex>
  );
};

const SelectApplicationSceneCard = styled(Card)(
  ({ theme: { spacing, shadows } }) => `
    display: flex;
    flex-direction: column;
    margin-bottom: ${spacing(2)};
    cursor: pointer;
    font-weight: bold;
    width: calc( 20% - ${spacing(2)});
    margin: ${spacing(1)};

    :hover {
        box-shadow: ${(shadows as string[])[5]}
    }
`,
);

const SelectApplicationSceneCardImage = styled('img')(`
        width: 100%;
        height: 90px;
`);

const SelectApplicationSceneCardImagePlaceholder = styled(ImagePlaceholder)(`
        width: 100%;
        height: 90px;
`);

const SelectApplicationSceneCardLabel = styled('div')(
  ({ theme: { spacing } }) => `
        padding: ${spacing(1)} ${spacing(2)};
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
`,
);

export default SelectApplicationScene;
