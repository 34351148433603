import {
  CompanyFeature,
  StreamType,
  UserRole,
} from '../../../graphql/graphql-operations';

export const getAvailableRolesByStreamAndCurrentUserRole = (
  strem: StreamType,
  role: UserRole,
  features: CompanyFeature[],
): UserRole[] => {
  switch (strem) {
    case StreamType.MEDICAL:
      return availableMedicalRoles(role, features);
    case StreamType.TRAINING:
      return availableTrainingRoles(role);
    case StreamType.SALES:
      return availableSalesRoles(role);
    case StreamType.SCHOOL:
      return availableSchoolRoles(role);
  }
};

const availableTrainingRoles = (role: UserRole) => {
  if (role === UserRole.SUPER_ADMIN || role === UserRole.TRAINING_ADMIN) {
    return [
      UserRole.TRAINING_STUDENT,
      UserRole.TRAINING_STUDENT_BASIC,
      UserRole.TRAINING_LECTURER_ASSISTANT,
      UserRole.TRAINING_LECTURER,
      UserRole.TRAINING_ADMIN,
      UserRole.SCHOOL_TEACHER,
    ];
  }
  if (role === UserRole.TRAINING_LECTURER) {
    return [
      UserRole.TRAINING_STUDENT,
      UserRole.TRAINING_STUDENT_BASIC,
      UserRole.TRAINING_LECTURER_ASSISTANT,
    ];
  }
  return [];
};

const availableMedicalRoles = (role: UserRole, features: CompanyFeature[]) => {
  const patientLogin = features?.includes(CompanyFeature.PATIENT_LOGIN)
    ? [UserRole.MEDICAL_PATIENT]
    : [];

  if (role === UserRole.SUPER_ADMIN || role === UserRole.MEDICAL_ADMIN) {
    return [
      UserRole.NONE,
      ...patientLogin,
      UserRole.MEDICAL_LECTURER_ASSISTANT,
      UserRole.MEDICAL_LECTURER,
      UserRole.MEDICAL_ADMIN,
    ];
  }
  if (role === UserRole.MEDICAL_LECTURER) {
    return [
      UserRole.NONE,
      ...patientLogin,
      UserRole.MEDICAL_PATIENT,
      UserRole.MEDICAL_LECTURER_ASSISTANT,
    ];
  }
  return [];
};

const availableSalesRoles = (role: UserRole) => {
  if (role === UserRole.SUPER_ADMIN || role === UserRole.SALES_ADMIN) {
    return [UserRole.SALES_SALESPERSON, UserRole.SALES_ADMIN];
  }
  return [];
};

const availableSchoolRoles = (role: UserRole) => {
  if (role === UserRole.SUPER_ADMIN || role === UserRole.SCHOOL_ADMIN) {
    return [UserRole.SCHOOL_TEACHER, UserRole.SCHOOL_ADMIN];
  }
  return [];
};
