import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import { Box, styled } from '@mui/system';

interface Props {
  className?: string;
  width?: string;
  height?: string;
}

const ImagePlaceholder = ({ className, width, height }: Props) => {
  return (
    <ImagePlaceholderWrapper
      className={className}
      width={width}
      height={height}
    >
      <ImageNotSupportedIcon />
    </ImagePlaceholderWrapper>
  );
};

export default ImagePlaceholder;

const ImagePlaceholderWrapper = styled(Box)(`
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        background-color: #F2F2F2;
        color: #DADADA;

        svg {
            max-height: 40%;
            width: 40%;
            height: auto;
        }
`);
