import { useState as hookState } from '@hookstate/core';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Box,
  Button,
  Collapse,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  styled,
  TextField,
} from '@mui/material';
import { useState } from 'react';
import { adminState } from '../../../adminState';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import { highlighQuery, matchQuery } from '../../../lib/filter';
import useCompanyTree, { TreeCompany } from '../CompanySelect/useCompanyTree';

interface Props {
  color?: string;
}

const TopBarCompanySelector = ({ color }: Props) => {
  const { _t } = useTranslationPrefix('ClientCompanySelector');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [query, setQuery] = useState('');
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) =>
    !anchorEl && setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  const selectedCompanyId = hookState(adminState.selectedCompanyId);
  const { companies, rootCompanies, toggleCompany } = useCompanyTree();
  const selectedCompany = companies.find(
    (c) => c.id === selectedCompanyId.value,
  );

  const handleCompanyChange = (companyId: string) =>
    selectedCompanyId.set(companyId);

  const disableEvent = (e: React.KeyboardEvent) => {
    e.stopPropagation();
  };

  const renderCompanyTreeItem = (lvl: number) => (company: TreeCompany) => {
    const handleToggleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      toggleCompany(company.id);
    };

    return [
      <MenuItem
        key={`tree-${company.id}`}
        value={company.id}
        onClick={() => handleCompanyChange(company.id)}
        selected={selectedCompany?.id === company.id}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          pl: 2 * lvl + 1,
        }}
      >
        <ListItemText
          sx={{
            py: 1.25,
            color: 'grey.600',
          }}
          primaryTypographyProps={{
            fontWeight: lvl === 1 ? 'bold' : undefined,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          title={company.name}
        >
          {company.name}
        </ListItemText>

        {company.subCompanies.length ? (
          <IconButton
            id={`expand-${company.id}`}
            size="small"
            onClick={handleToggleClick}
          >
            {company.expanded ? (
              <ExpandLessRoundedIcon />
            ) : (
              <ExpandMoreRoundedIcon />
            )}
          </IconButton>
        ) : (
          <Box sx={{ w: 1, h: 1 }}></Box>
        )}
      </MenuItem>,
      <Collapse key={`collapse-${company.id}`} in={company.expanded}>
        {company.subCompanies.map(renderCompanyTreeItem(lvl + 1))}
      </Collapse>,
    ];
  };

  const renderCompanySearchItem = (company: TreeCompany) => {
    return (
      <MenuItem
        key={`search-${company.id}`}
        value={company.id}
        onClick={() => handleCompanyChange(company.id)}
        selected={selectedCompany?.id === company.id}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          px: 3,
        }}
      >
        <HighlightedListItemText
          sx={{
            py: 1.25,
            color: 'grey.600',
          }}
          primaryTypographyProps={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          title={company.name}
        >
          {highlighQuery(company.name, query)}
        </HighlightedListItemText>
      </MenuItem>
    );
  };

  return (
    <>
      <Button
        id="client-company-selector"
        onClick={handleMenuOpen}
        endIcon={<KeyboardArrowDownIcon sx={{ fontSize: '1em !important' }} />}
        variant="text"
        sx={{
          mr: 2,
          color: color || 'text.primary',
          overflow: 'hidden',
          p: 1,
        }}
      >
        <CompanySelectorText>{selectedCompany?.name || ''}</CompanySelectorText>
      </Button>

      <Menu
        id="training-top-bar-company-select"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        variant="menu"
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          sx: { width: '40ch', maxWidth: 'calc( 100% - 2rem)' },
        }}
      >
        <Box
          p={1}
          onKeyDown={disableEvent}
          onKeyUp={disableEvent}
          onKeyPress={disableEvent}
        >
          <TextField
            fullWidth
            value={query}
            label={_t('search', 'Search')}
            onChange={(e) => setQuery(e.target.value)}
            InputProps={{
              endAdornment: (
                <ClearRoundedIcon
                  sx={{
                    visibility: query ? 'visible' : 'hidden',
                    cursor: 'pointer',
                  }}
                  onClick={() => setQuery('')}
                />
              ),
            }}
          />
        </Box>
        {query
          ? companies
              .filter((c) => matchQuery(c.name, query))
              .map(renderCompanySearchItem)
          : rootCompanies.map(renderCompanyTreeItem(1))}
      </Menu>
    </>
  );
};

export default TopBarCompanySelector;

const CompanySelectorText = styled('span')`
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const HighlightedListItemText = styled(ListItemText)(
  ({ theme: { palette } }) => `
  & mark {
    background-color: transparent;
    font-weight: bold;
    color: ${palette.success.dark};
  }
`,
);
