import { Box, Button, MenuItem, styled, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { LoginPhoneFormRequest, sendLoginSms } from '../../../auth';
import { BaseTabs } from '../../../base/components/BaseTabs';
import { Flex } from '../../../base/components/Flex';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import { PHONE_PREFIXES } from '../../../lib/constants';

interface Props {
  companyId: string | null;
  type: 'phone' | 'username';
  onTypeChange: (type: 'phone' | 'username') => void;
  data: ClientLoginUserIdData;
  onBack?: () => void;
  onContinue: (data: Partial<ClientLoginUserIdData>) => void;
}

interface ClientLoginUserIdData {
  phonePrefix: string;
  phoneNumber: string;
  username: string;
}

const PHONE_LENGTH = 9;

const ClientLoginUserId = ({
  type,
  onTypeChange,
  data,
  onBack,
  companyId,
  onContinue,
}: Props) => {
  const { _t, i18n } = useTranslationPrefix('ClientLoginUserId');
  const [submitting, setSubmitting] = useState(false);
  const [valueState, setValueState] = useState(data);
  const [errors, setErrors] = useState<ClientLoginUserIdData>({
    phoneNumber: '',
    phonePrefix: '',
    username: '',
  });
  useEffect(() => setValueState(data), [data]);

  const normalizePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    const phoneNumber = e.target.value.replace(/[^.\d]/g, '');
    setValueState((s) => ({ ...s, phoneNumber }));
    if (phoneNumber.length === PHONE_LENGTH) {
      setErrors((e) => ({ ...e, phoneNumber: '' }));
    }
  };

  const normalizeUsername = (e: React.ChangeEvent<HTMLInputElement>) => {
    const username = e.target.value;
    setValueState((s) => ({ ...s, username }));
    if (username.length > 1) {
      setErrors((e) => ({ ...e, username: '' }));
    }
  };

  const handleSendSms = async () => {
    const result = await sendLoginSms({
      ...valueState,
      companyId: companyId,
      lang: i18n.language,
    });
    if (result.errors) {
      for (const key in result.errors) {
        const { code, message } =
          result.errors[key as keyof LoginPhoneFormRequest]!;
        setErrors((e) => ({ ...e, [key]: _t(`formErrors.${code}`, message) }));
      }
    }
    return !result.errors;
  };

  const handleContinue = async () => {
    if (type === 'phone') {
      if (valueState.phoneNumber.length !== PHONE_LENGTH) {
        setErrors((e) => ({
          ...e,
          phoneNumber: _t(
            'formErrors.phoneNumberRequired',
            'Enter valid phone number',
          ),
        }));
        return;
      }
      setSubmitting(true);
      try {
        const sent = await handleSendSms();

        if (sent) {
          onContinue({
            phonePrefix: valueState.phonePrefix,
            phoneNumber: valueState.phoneNumber,
          });
        }
      } finally {
        setSubmitting(false);
      }
    }
    if (type === 'username') {
      if (!valueState.username) {
        setErrors((e) => ({
          ...e,
          username: _t('formErrors.usernameRequired', 'Enter username'),
        }));
        return;
      }
      onContinue({
        username: valueState.username,
      });
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      handleContinue();
    }
  };

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      width="100%"
      maxWidth="300px"
      paddingBottom={3}
    >
      <BaseTabs
        value={type}
        onChange={(e, v) => onTypeChange(v)}
        sx={{ mb: 2 }}
        tabs={[
          { label: _t('loginByPhone', 'Phone'), value: 'phone' },
          { label: _t('loginByUsername', 'Username'), value: 'username' },
        ]}
      />
      {type === 'phone' && (
        <Flex width="100%">
          <Box marginRight={1}>
            <TextField
              id="login-phonePrefix"
              select
              variant="outlined"
              disabled={submitting}
              value={valueState.phonePrefix}
              onChange={(e) =>
                setValueState((s) => ({ ...s, phonePrefix: e.target.value }))
              }
              error={!!errors.phonePrefix}
              helperText={errors.phonePrefix}
            >
              {PHONE_PREFIXES.map((prefix) => (
                <MenuItem key={prefix} value={prefix}>
                  {prefix}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <TextField
            id="login-phoneNumber"
            autoComplete="phoneNumber"
            variant="outlined"
            type="tel"
            name="phoneNumber"
            label={_t('phoneNumber', 'Phone number')}
            disabled={submitting}
            value={valueState.phoneNumber}
            onChange={normalizePhone}
            sx={{ flexGrow: 1 }}
            error={!!errors.phoneNumber}
            helperText={errors.phoneNumber}
            onKeyDown={handleKeyDown}
            autoFocus
          />
        </Flex>
      )}
      {type === 'username' && (
        <>
          <TextField
            id="login-username"
            name="username"
            autoComplete="username"
            variant="outlined"
            label={_t('username', 'Username')}
            disabled={submitting}
            value={valueState.username}
            onChange={normalizeUsername}
            fullWidth
            error={!!errors.username}
            helperText={errors.username}
            onKeyDown={handleKeyDown}
            autoFocus
          />
          <InvisibleInput
            name="password"
            type="password"
            autoComplete="password"
          />
        </>
      )}
      <Flex justifyContent="center">
        {onBack && (
          <Button
            id="back"
            variant="outlined"
            sx={{ mb: 2, mt: 3, mr: 2 }}
            onClick={onBack}
            disabled={submitting}
          >
            {_t('back', 'Back')}
          </Button>
        )}
        <Button
          id="next"
          variant="contained"
          sx={{ mb: 2, mt: 3 }}
          onClick={handleContinue}
          disabled={submitting}
        >
          {_t('continue', 'Continue')}
        </Button>
      </Flex>
    </Flex>
  );
};

export default ClientLoginUserId;

const InvisibleInput = styled('input')`
  display: none;
`;
