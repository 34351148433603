import { Route, Routes } from 'react-router-dom';
import OSUpdatesPage from './OSUpdatesPage';

const OSUpdatesRoute = () => {
  return (
    <Routes>
      <Route index element={<OSUpdatesPage />} />
    </Routes>
  );
};

export default OSUpdatesRoute;
