import { Button, Card, CardContent, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { FormikHelpers, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { Trans } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import { passwordReset } from '../../../auth';
import ClientLoginLogo from '../../../client/pages/Login/ClientLoginLogo';
import { PASSWORD_REGEX } from '../../../client/pages/Users/UserChangePasswordPage';
import {
  TFunctionPrefixed,
  useTranslationPrefix,
} from '../../../hooks/useTranslationPrefix';
import { ClientLayout } from '../../../layouts';
import { BaseTypography } from '../../components/BaseTypography';
import { Flex } from '../../components/Flex';
import { FormikFormProvider } from '../../components/form/FormikForm';
import FormikTextField from '../../components/form/FormikTextField';

interface FormData {
  newPassword: string;
  newPassword2: string;
}

const LoginPasswordReset = () => {
  const { _t } = useTranslationPrefix('LoginPasswordReset');
  const { palette } = useTheme();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const handleTokenIvalid = () => {
    enqueueSnackbar(
      _t('tokenIvalid', 'Token to reset password is invalid or expired.'),
      { variant: 'error' },
    );
  };

  const handleSubmit = async (
    values: FormData,
    formikHelpers: FormikHelpers<FormData>,
  ) => {
    const token = searchParams.get('token');
    if (!token) {
      handleTokenIvalid();
      return;
    }
    const { errors } = await passwordReset({
      token: token,
      newPassword: values.newPassword,
    });
    if (errors) {
      if (errors.token) {
        handleTokenIvalid();
      }
      return;
    }
    navigate('/');
  };

  const formik = useFormik<FormData>({
    initialValues: { newPassword: '', newPassword2: '' },
    onSubmit: handleSubmit,
    validationSchema: getValidationSchema(_t),
  });

  return (
    <ClientLayout>
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        flexGrow={1}
      >
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          maxWidth="80vw"
          width="100%"
          flexGrow={1}
        >
          <ClientLoginLogo
            color={palette.text.secondary}
            sx={{ px: 8, pb: 5 }}
          />

          <Card sx={{ flexShrink: 0, width: '100%', maxWidth: '38rem' }}>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <BaseTypography
                fontSize="1.5rem"
                sx={{ my: { xs: 1, sm: 2 } }}
                fontWeight="bold"
              >
                {_t('title', 'Set up your new password')}
              </BaseTypography>
              <FormikFormProvider formik={formik}>
                <Box
                  sx={{
                    display: 'grid',
                    gap: 3,
                    maxWidth: '40ch',
                    margin: 'auto',
                    pt: 4,
                  }}
                >
                  <FormikTextField
                    name="newPassword"
                    type="password"
                    autoComplete="new-password"
                    label={_t('newPassword', 'New password')}
                  />
                  <FormikTextField
                    name="newPassword2"
                    type="password"
                    autoComplete="new-password"
                    label={_t('newPasswordAgain', 'New password again')}
                  />
                  <Flex justifyContent="space-around" py={2}>
                    <Button id="submit" type="submit" variant="contained">
                      {_t('submit', 'Save and log in')}
                    </Button>
                  </Flex>
                </Box>
              </FormikFormProvider>
            </CardContent>
          </Card>
        </Flex>
        <Box sx={{ textAlign: 'center', pb: 2, color: 'text.secondary' }}>
          <Trans
            i18nKey={'ClientLogin.footer'}
            defaults="In case of any problems, please contact us at <0>{{supportEmailAddress}}</0>."
            values={{
              supportEmailAddress: _t(
                'supportEmailAddress',
                'support@vrmedical.cz',
              ),
            }}
          >
            <a
              href={`mailto:${_t(
                'supportEmailAddress',
                'support@vrmedical.cz',
              )}`}
            >
              {' '}
            </a>
          </Trans>
        </Box>
      </Flex>
    </ClientLayout>
  );
};

export default LoginPasswordReset;

const getValidationSchema = (_t: TFunctionPrefixed) => {
  const passwordValidation = Yup.string()
    .min(8, _t('form.passwordMin', 'Minimal number of characters is 8'))
    .required(_t('form.passwordRequired', 'Password is required'))
    .matches(
      PASSWORD_REGEX,
      _t(
        'form.passwordRegex',
        'Password must have at least 1 uppercase, 1 lower case character and 1 digit',
      ),
    );

  return Yup.object({
    newPassword: passwordValidation,
    newPassword2: passwordValidation.oneOf(
      [Yup.ref('newPassword')],
      _t('form.passwordMismatch', 'Password does not match'),
    ),
  });
};
