import { useState } from '@hookstate/core';
import { default as EditIcon } from '@mui/icons-material/Edit';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import HasPermission from '../../../../base/components/UserProvider/HasPermission';
import { UserPermission } from '../../../../graphql/graphql-operations';
import LaunchSceneDialog from '../../../components/LaunchSceneDialog/LaunchSceneDialog';
import EditVrDeviceDialog from './EditVrDeviceDialog';
import { VrDevice } from './VrDeviceDetailPage';

interface Props {
  canLaunchApplication: boolean;
  vrDevice: VrDevice;
}
const Actions = ({ vrDevice, canLaunchApplication }: Props) => {
  const { t } = useTranslation();
  const editDialogOpened = useState(false);
  const launchSceneDialogOpen = useState(false);
  const toggleEditDialog = () => editDialogOpened.set((prev) => !prev);
  const toggleLaunchSceneDialog = () => launchSceneDialogOpen.set((p) => !p);
  return (
    <>
      <HasPermission val={UserPermission.UPDATE_COMPANY_VR_DEVICES}>
        <Button
          id="vr-device-action-edit"
          color="primary"
          variant="text"
          size="large"
          startIcon={<EditIcon />}
          onClick={toggleEditDialog}
        >
          {t('vrDevices.detail.editVrDevice', 'Edit headset')}
        </Button>
      </HasPermission>
      <Button
        id="vr-device-action-start-session"
        color="secondary"
        variant="contained"
        size="large"
        onClick={toggleLaunchSceneDialog}
        disabled={!canLaunchApplication}
      >
        {t('vrDevices.detail.startSession', 'start session')}
      </Button>
      <EditVrDeviceDialog
        onClose={toggleEditDialog}
        open={editDialogOpened.get()}
        vrDevice={vrDevice}
      />
      <LaunchSceneDialog
        open={launchSceneDialogOpen.get()}
        title={t(
          'vrDeviceCard.applicationListDialog.title',
          'Headset {{ name }} - New Session',
          { name: vrDevice.name },
        )}
        vrDeviceId={vrDevice.id}
        onClose={toggleLaunchSceneDialog}
      />
    </>
  );
};

export default Actions;
