import { CardActionArea, CardContent, Divider } from '@mui/material';
import { FC } from 'react';
import { BaseTypography } from '../../../base/components/BaseTypography';
import { Flex } from '../../../base/components/Flex';
import { SelectableCard } from '../../../base/components/SelectableCard';

interface Props {
  id: string;
  name: string;
  selected?: boolean;
  idx: number;
  onPress: () => void;
}

const ApplicationCard: FC<Props> = ({ id, name, selected, idx, onPress }) => {
  return (
    <SelectableCard id={id} selected={selected} onClick={onPress}>
      <CardActionArea onClick={onPress}>
        <CardContent>
          <Flex flexDirection="row" alignItems="center">
            <BaseTypography variant="h4" p={2.5} textAlign="center">
              {idx}
            </BaseTypography>
            <Divider orientation="vertical" variant="middle" flexItem></Divider>
            <BaseTypography
              variant="h5"
              sx={{
                pl: 3,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {name}
            </BaseTypography>
          </Flex>
        </CardContent>
      </CardActionArea>
    </SelectableCard>
  );
};

export default ApplicationCard;
