import { formatDate } from '../../../../lib/date';
import { lightColors } from '../../../theme';

//https://echarts.apache.org/en/option.html#xAxis.axisPointer
export const axisPointer = ({
  labelFormatter = ({ value }: { value: number }) => formatDate(value),
}: {
  labelFormatter?: LabelFormatterFunction;
}) => {
  return {
    snap: true,
    show: true,
    label: {
      show: true,
      backgroundColor: lightColors.primary.light,
      formatter: labelFormatter,
    },
    lineStyle: {
      color: lightColors.primary.main,
      width: 2,
    },
    handle: {
      show: true,
      color: lightColors.primary.main,
      position: 'top',
      shadowColor: 'rgba(0, 0, 0, 0.1)',
    },
  };
};

export interface AxisLabelFormatterProps {
  componentType: 'series';
  // Series type
  seriesType: string;
  // Series index in option.series
  seriesIndex: number;
  // Series name
  seriesName: string;
  // Data name, or category name
  name: string;
  // Data index in input data array
  dataIndex: number;
  // Original data as input
  data: Object;
  // Value of data. In most series it is the same as data.
  // But in some series it is some part of the data (e.g., in map, radar)
  value: any;
  // encoding info of coordinate system
  // Key: coord, like ('x' 'y' 'radius' 'angle')
  // value: Must be an array, not null/undefined. Contain dimension indices, like:
  // {
  //     x: [2] // values on dimension index 2 are mapped to x axis.
  //     y: [0] // values on dimension index 0 are mapped to y axis.
  // }
  encode: Object;
  // dimension names list
  dimensionNames: Array<String>;
  // data dimension index, for example 0 or 1 or 2 ...
  // Only work in `radar` series.
  dimensionIndex: number;
  // Color of data
  color: string;
}

export type LabelFormatterFunction = (props: AxisLabelFormatterProps) => string;
