import { useState } from 'react';

const LOGIN_TYPE = 'vr-login-type';
const COMPANY_ID = 'vr-login-company-id';
const NULL_COMPANY_ID = 'null';

export const useLocalyStoredLoginDefaults = () => {
  const [defaultLoginType] = useState<'phone' | 'username'>(
    localStorage.getItem(LOGIN_TYPE) === 'username' ? 'username' : 'phone',
  );

  const tmpCpId = localStorage.getItem(COMPANY_ID);
  const [defaultCompanyId] = useState(
    tmpCpId === NULL_COMPANY_ID ? null : tmpCpId || '',
  );

  const setDefaultLoginType = (loginType: 'phone' | 'username') => {
    localStorage.setItem(LOGIN_TYPE, loginType);
  };

  const setDefaultCompanyId = (companyId: string | null) => {
    localStorage.setItem(
      COMPANY_ID,
      companyId === null ? NULL_COMPANY_ID : companyId,
    );
  };

  return {
    defaultLoginType,
    defaultCompanyId,
    setDefaultLoginType,
    setDefaultCompanyId,
  };
};
