import { Card, CardProps } from '@mui/material';
import React from 'react';
import { OutlinedCard } from '../OutlinedCard';
interface Props extends CardProps {
  disabled?: boolean;
}

const BaseCard = React.forwardRef<HTMLDivElement | null, Props>(
  ({ disabled, ...props }, ref) => {
    return disabled ? (
      <OutlinedCard ref={ref} {...props} />
    ) : (
      <Card ref={ref} {...props} />
    );
  },
);

export default BaseCard;
