import { styled } from '@mui/system';
import { ReactComponent as SilhouetteSVG } from '../../../assets/svg/silhouette.svg';
import SilhouetteLocationPoint from './SilhouetteLocationPoint';

const points = [
  { id: 1, x: 54, y: 88 }, //neck
  { id: 2, x: 125, y: 145 }, //shoulder
  { id: 3, x: 152, y: 255 }, //forearm
  { id: 4, x: 168, y: 355 }, //wrist
  { id: 5, x: 165, y: 400 }, //hand
];

interface Props {
  selectedPointId?: number;
  pointsFilter?: (pointId: number) => boolean;
  disabled?: boolean;
  onChange: (selectedPointId: number) => void;
}

const SilhouetteLocationSelect = ({
  selectedPointId,
  pointsFilter,
  onChange,
  disabled,
}: Props) => {
  const renderedPoints = pointsFilter
    ? points.filter((point) => pointsFilter(point.id))
    : points;

  const handleClick = (pointId: number) => () => {
    onChange(pointId);
  };

  return (
    <SilhouetteLocationSelectWrapper disabled={disabled}>
      <SilhouetteSVG />
      {renderedPoints.map((point) => (
        <SilhouetteLocationPoint
          disabled={disabled}
          key={point.id}
          posX={point.x}
          posY={point.y}
          onClick={handleClick(point.id)}
          isSelected={selectedPointId === point.id}
        />
      ))}
    </SilhouetteLocationSelectWrapper>
  );
};

const SilhouetteLocationSelectWrapper = styled('div')<{ disabled?: boolean }>(
  ({ disabled }) => `
    position: relative;
    width: 190px;
    height: 440px;
    ${
      disabled &&
      `
      opacity: 0.5;
    `
    }
    svg {
        width: auto;
        height: 100%;
    }
`,
);

export default SilhouetteLocationSelect;
