import { useCallback, useReducer } from 'react';
import {
  PlaylistSelectedParams,
  PlaylistSelectedScene,
  SceneParametrValue,
} from './PlaylistTypes';

interface PlaylistState {
  selectedCategory: SceneParametrValue | undefined;
  selectedParams: PlaylistSelectedParams;
  selectedScenesData: Array<PlaylistSelectedScene>;
}

type SetReduceStateAction<T extends keyof PlaylistState> =
  | {
      key: T;
      value: PlaylistState[T];
    }
  | {
      key: T;
      callback: (data: PlaylistState[T]) => PlaylistState[T];
    };

const playlistReducer: React.Reducer<
  PlaylistState,
  | SetReduceStateAction<'selectedCategory'>
  | SetReduceStateAction<'selectedParams'>
  | SetReduceStateAction<'selectedScenesData'>
> = (
  prevState: PlaylistState,
  action:
    | SetReduceStateAction<'selectedCategory'>
    | SetReduceStateAction<'selectedParams'>
    | SetReduceStateAction<'selectedScenesData'>,
) => {
  const key = action.key;
  if ('callback' in action) {
    const newValue = action.callback(prevState[key] as any);
    const newState = { ...prevState, [key]: newValue };
    window.history.replaceState(newState, '');
    return newState;
  } else {
    const newState = { ...prevState, [key]: action.value };
    window.history.replaceState(newState, '');
    return newState;
  }
};

/**
 *
 * @returns Internal use in UsePlaylistData only. separated to file because its long
 */
export const usePlaylistState = () => {
  const [state, dispatch] = useReducer(playlistReducer, {
    selectedCategory: window.history.state.selectedCategory || undefined,
    selectedParams: window.history.state.selectedParams || {},
    selectedScenesData: window.history.state.selectedScenesData || [],
  });

  const setSelectedCategory = useCallback(
    (
      newState:
        | SceneParametrValue
        | ((data: SceneParametrValue | undefined) => SceneParametrValue),
    ) => {
      if (typeof newState === 'function') {
        dispatch({
          key: 'selectedCategory',
          callback: newState,
        });
      } else {
        dispatch({
          key: 'selectedCategory',
          value: newState,
        });
      }
    },
    [],
  );

  const setSetSelectedParams = useCallback(
    (
      newState:
        | PlaylistSelectedParams
        | ((data: PlaylistSelectedParams) => PlaylistSelectedParams),
    ) => {
      if (typeof newState === 'function') {
        dispatch({
          key: 'selectedParams',
          callback: newState,
        });
      } else {
        dispatch({
          key: 'selectedParams',
          value: newState,
        });
      }
    },
    [],
  );

  const setSelectedScenesData = useCallback(
    (
      newState:
        | Array<PlaylistSelectedScene>
        | ((
            data: Array<PlaylistSelectedScene>,
          ) => Array<PlaylistSelectedScene>),
    ) => {
      if (typeof newState === 'function') {
        dispatch({
          key: 'selectedScenesData',
          callback: newState,
        });
      } else {
        dispatch({
          key: 'selectedScenesData',
          value: newState,
        });
      }
    },
    [],
  );

  return {
    selectedCategory: state.selectedCategory,
    selectedParams: state.selectedParams,
    selectedScenesData: state.selectedScenesData,
    setSelectedScenesData,
    setSetSelectedParams,
    setSelectedCategory,
  };
};
