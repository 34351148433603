import { Card, CardContent, CardHeader, useTheme } from '@mui/material';
import { useParams } from 'react-router-dom';
import { BaseTypography } from '../../../base/components/BaseTypography';
import { Flex } from '../../../base/components/Flex';
import { useUser } from '../../../base/components/UserProvider/useUserHook';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import ContentWrapper from '../../components/ContentWrapper';
import { Loader } from '../../components/Loader';
import PageHeader from '../../components/PageHeader';
import TrainingReportFilterCard from './components/TrainingReportFilterCard';
import TrainingResultChart from './components/TrainingResultChart';
import TrainingResultSequenceTable from './components/TrainingResultSequenceTable';
import { useTrainingReport } from './useTrainingReport';

const TrainingReportPage = () => {
  const { palette } = useTheme();
  const { _t } = useTranslationPrefix('TrainingReportPage');
  const { userId } = useParams<{ userId?: string }>();
  const { user: authUser } = useUser();
  const {
    user,
    loading,
    scenes,
    reports,
    detailData,
    selectedSceneId,
    setSelectedSceneId,
    selectedReportId,
    setSelectedReportId,
  } = useTrainingReport(userId || authUser?.id);

  const lvl3Finished = detailData?.result === 'Dokončeno';

  return (
    <ContentWrapper>
      <PageHeader
        title={_t('title', 'Training Report {{firstName}} {{lastName}}', {
          firstName: user?.firstName,
          lastName: user?.lastName,
        })}
        back={`../../${userId || 'user'}`}
      ></PageHeader>
      {loading ? (
        <Loader />
      ) : !scenes?.length ? (
        <Flex justifyContent="center">
          <BaseTypography>
            {_t('noReportsFound', 'No training reports found fo user')}
          </BaseTypography>
        </Flex>
      ) : (
        <>
          <Flex gap={2} alignItems="flex-start">
            <TrainingReportFilterCard
              reports={reports}
              scenes={scenes}
              selectedReportId={selectedReportId}
              setSelectedReportId={setSelectedReportId}
              selectedSceneId={selectedSceneId}
              setSelectedSceneId={setSelectedSceneId}
              sx={{ width: '27ch', flexShrink: 0 }}
            />

            <Card sx={{ flexGrow: 1 }}>
              <CardHeader title={detailData?.sceneName} />
              <CardContent>
                {}
                {detailData?.sequences?.length ? (
                  <>
                    <TrainingResultChart data={detailData.sequences || []} />
                    <TrainingResultSequenceTable
                      data={detailData.sequences || []}
                      mt={2}
                    />
                  </>
                ) : detailData?.result ? (
                  <Flex>
                    <BaseTypography
                      sx={{
                        border: '1px solid',
                        borderColor: lvl3Finished
                          ? palette.success.main
                          : palette.error.main,
                        color: lvl3Finished
                          ? palette.success.main
                          : palette.error.main,
                        fontWeight: 'bold',
                        borderRadius: 4,
                        padding: 1,
                      }}
                    >
                      {detailData.result}
                    </BaseTypography>
                  </Flex>
                ) : (
                  <BaseTypography>
                    {_t('invalidData', 'Training does not have valid data.')}
                  </BaseTypography>
                )}
              </CardContent>
            </Card>
          </Flex>
        </>
      )}
    </ContentWrapper>
  );
};

export default TrainingReportPage;
