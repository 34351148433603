import {
  GetDevicesChangeSubscriptionSubscription,
  GetVrDevicesQuery,
} from '../../../graphql/graphql-operations';

type UpdateEvents =
  GetDevicesChangeSubscriptionSubscription['vrDevicesChanges'];

export const handleDeviceEventUpdate = (
  devices: GetVrDevicesQuery['vrDevices'],
  deviceUpdate: UpdateEvents,
) => {
  if (deviceUpdate.__typename === 'VrDeviceUnRegistered') {
    const newDevices = devices.filter(
      (dev) => dev.id !== deviceUpdate.device.id,
    );
    return newDevices;
  }
  if (deviceUpdate.__typename === 'VrDeviceRegistered') {
    const exists = devices.find((dev) => dev.id === deviceUpdate.device.id);
    const newDevices = [...devices];
    if (!exists) {
      newDevices.push(deviceUpdate.device);
    }
    return newDevices;
  }
  if (deviceUpdate.__typename === 'VrDeviceAppsUpdated') {
    const newDevices = devices.map((dev) => {
      if (dev.id === deviceUpdate.device.id) {
        return {
          ...dev,
          upToDate: deviceUpdate.device.upToDate,
        };
      }
      return dev;
    });
    return newDevices;
  }
  if (deviceUpdate.__typename === 'VrDeviceStatusUpdateEvent') {
    const newDevices = devices.map((dev) => {
      if (dev.id === deviceUpdate.deviceId) {
        return {
          ...dev,
          status: {
            battery: deviceUpdate.battery,
            state: deviceUpdate.state,
            updateInProgress: deviceUpdate.updateInProgress,
          },
        };
      }
      return dev;
    });
    return newDevices;
  }
  if (deviceUpdate.__typename === 'DeviceSessionStarted') {
    const newDevices = devices.map((dev) => {
      if (dev.id === deviceUpdate.deviceId) {
        return {
          ...dev,
          session: {
            id: deviceUpdate.sessionId,
          },
        };
      }
      return dev;
    });
    return newDevices;
  }
  if (deviceUpdate.__typename === 'DeviceSessionEnded') {
    const newDevices = devices.map((dev) => {
      if (dev.id === deviceUpdate.deviceId) {
        return { ...dev, session: null };
      }
      return dev;
    });
    return newDevices;
  }
  return devices;
};
