import { ListItemText, MenuItem, TextField, useTheme } from '@mui/material';
import React from 'react';
import { OrderDirection } from '../../../graphql/graphql-operations';

export interface OrderByState<T> {
  readonly direction: OrderDirection;
  readonly field: T;
}

export interface OrderByOption<T> extends OrderByState<T> {
  label: React.ReactNode;
}

interface Props<T> {
  id: string;
  setOrderBy: React.Dispatch<React.SetStateAction<OrderByState<T>>>;
  orderBy: OrderByState<T>;
  options: OrderByOption<T>[];
}

const OrderBy = <T extends string = string>({
  id,
  orderBy,
  setOrderBy,
  options,
}: Props<T>) => {
  const { palette } = useTheme();
  const currentOption = options.find(
    (o) => o.direction === orderBy.direction && o.field === orderBy.field,
  );

  const handleOptionClick = (option: OrderByOption<T>) => {
    const { direction, field } = option;
    setOrderBy((o) => ({ ...o, direction, field }));
  };

  return (
    <TextField
      select
      id={id}
      color={palette.mode === 'dark' ? 'white' : undefined}
      value={
        currentOption ? `${currentOption.field}-${currentOption.direction}` : ''
      }
    >
      {options.map((option) => (
        <MenuItem
          key={`${option.field}-${option.direction}`}
          id={`${option.field}-${option.direction}`}
          onClick={() => handleOptionClick(option)}
          value={`${option.field}-${option.direction}`}
        >
          <ListItemText>{option.label}</ListItemText>
        </MenuItem>
      ))}
    </TextField>
  );
};

export default OrderBy;
