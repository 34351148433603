import { Grid, GridProps } from '@mui/material';
import { formatISO } from 'date-fns';
import { groupBy } from 'ramda';
import { BaseTypography } from '../../../../base/components/BaseTypography';
import { SessionPlaylistItemReportFragmentFragment } from '../../../../graphql/graphql-operations';
import { useTranslationPrefix } from '../../../../hooks/useTranslationPrefix';
import { formatDate } from '../../../../lib/date';
import ReportSceneList from './ReportSceneList';

const UNKNOWN_DATE = 'unknown';

interface Props {
  data: SessionPlaylistItemReportFragmentFragment[];
  sx?: GridProps['sx'];
}

const ReportUserDayList = ({ data, sx = {} }: Props) => {
  const { _t } = useTranslationPrefix('ReportUserDayList');
  const dayScenes = groupBy(
    (row) =>
      row.endedAt
        ? formatISO(new Date(row.endedAt), { representation: 'date' })
        : UNKNOWN_DATE,
    data,
  );
  const days = Object.keys(dayScenes).sort(
    (d1, d2) => d1.localeCompare(d2) * -1,
  );

  return (
    <Grid container spacing={2} sx={sx}>
      {days.map((day, idx) => (
        <Grid key={day} item xs={12} pb={idx + 1 >= days.length ? 2 : 6}>
          <BaseTypography variant="h6" color="primary.main">
            {day === UNKNOWN_DATE ? _t('unknown', 'Unknown') : formatDate(day)}
          </BaseTypography>
          <ReportSceneList data={dayScenes[day]} />
        </Grid>
      ))}
    </Grid>
  );
};

export default ReportUserDayList;
