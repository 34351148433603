import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import {
  Box,
  Button,
  CardActionArea,
  CardContent,
  IconButton,
  css,
  styled,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import { BaseTypography } from '../../../../base/components/BaseTypography';
import ConfirmDialog from '../../../../base/components/ConfirmDialog/ConfirmDialog';
import { Flex } from '../../../../base/components/Flex';
import { SelectableCard } from '../../../../base/components/SelectableCard';
import { useUser } from '../../../../base/components/UserProvider/useUserHook';
import { VrDeviceType } from '../../../../base/components/VrDevicesProvider/VrDevicesProvider';
import {
  DeviceState,
  UserPermission,
} from '../../../../graphql/graphql-operations';
import DeviceBatteryStatus from './DeviceBatteryStatus';
import DeviceStateLabel from './DeviceStateLabel';
import DeviceUpdateIndicator from './DeviceUpdateIndicator';

interface Props {
  device: VrDeviceType;
  onDeviceClick?: (device: VrDeviceType) => void;
  onDeviceUpdateClick?: (device: VrDeviceType) => void;
  onDeviceEditClick?: (device: VrDeviceType) => void;
  small?: boolean;
  selected?: boolean;
  onDeviceRestartClick?: (device: VrDeviceType) => void;
}

interface RestartButtonProps {
  small?: boolean;
}

export const DeviceCard = ({
  device,
  small,
  selected,
  onDeviceEditClick,
  onDeviceUpdateClick,
  onDeviceClick,
  onDeviceRestartClick,
}: Props) => {
  const { hasPermission } = useUser();
  const { t } = useTranslation();
  const [restartConfirmOpen, setRestartConfirmOpen] = useState(false);
  const battery = device.status.battery;
  const state = device.status.state;
  const updatable = !device.upToDate && onDeviceUpdateClick;

  const textColor =
    state === DeviceState.ONLINE ? 'text.primary' : 'text.secondary';

  const renderDeviceName = () => {
    return (
      <Flex
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        mt="auto"
        pt={4}
      >
        <BaseTypography
          variant="h4"
          whiteSpace="nowrap"
          pr={1}
          color={textColor}
          textOverflow="ellipsis"
          overflow="hidden"
        >
          {device.name}
        </BaseTypography>
        <Flex gap={1}>
          {hasPermission(UserPermission.RESTART_DEVICE) &&
            onDeviceRestartClick &&
            state !== DeviceState.OFFLINE && (
              <Flex alignItems={'center'}>
                <RestartButton
                  id="restart-device"
                  size="small"
                  color="primary"
                  variant="outlined"
                  small={small}
                  sx={small ? { ml: 1, mr: 0, p: 1 } : { ml: 1, mr: 3 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setRestartConfirmOpen(true);
                  }}
                >
                  {t('deviceRestart', 'Restart')}
                </RestartButton>
              </Flex>
            )}

          {(updatable || device.status.updateInProgress) && (
            <DeviceUpdateIndicator
              small={small}
              onClick={onDeviceUpdateClick}
              device={device}
            />
          )}
          {onDeviceEditClick && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                onDeviceEditClick(device);
              }}
              size="small"
              color={state === DeviceState.ONLINE ? 'primary' : 'bgContrast'}
              id={`device-${device.id}-edit`}
            >
              <ModeEditOutlineOutlinedIcon />
            </IconButton>
          )}
        </Flex>
      </Flex>
    );
  };

  const renderRegularContent = () => {
    return (
      <Flex flexDirection="column" alignItems="center">
        <Flex width="100%" justifyContent="space-between" alignItems="center">
          <DeviceBatteryStatus battery={battery} textColor={textColor} />

          <DeviceStateLabel state={state} />
        </Flex>
        {small ? (
          renderDeviceName()
        ) : (
          <HeadsetImageWrapper state={state}>
            {state === DeviceState.IN_USE ? (
              <SVG
                src="/assets/headset-in-use.svg"
                className="HeadsetImageWrapper-headset"
              />
            ) : (
              <SVG
                src="/assets/headset-ready.svg"
                className="HeadsetImageWrapper-headset"
              />
            )}
            {renderDeviceName()}
          </HeadsetImageWrapper>
        )}
      </Flex>
    );
  };

  return (
    <>
      <SelectableCard
        id={`device-${device.id}`}
        selected={!!selected}
        disabled={state === DeviceState.OFFLINE}
      >
        {onDeviceClick ? (
          <CardActionArea onClick={() => onDeviceClick(device)}>
            <CardContent sx={{ overflow: 'hidden' }}>
              {renderRegularContent()}
            </CardContent>
          </CardActionArea>
        ) : (
          <CardContent sx={{ overflow: 'hidden' }}>
            {renderRegularContent()}
          </CardContent>
        )}
      </SelectableCard>
      {onDeviceRestartClick && (
        <ConfirmDialog
          title={t(
            'restartDeviceConfirmation',
            'Do you really want to restart the device?',
          )}
          open={restartConfirmOpen}
          onClose={() => setRestartConfirmOpen(false)}
          onConfirm={() => {
            onDeviceRestartClick(device);
            setRestartConfirmOpen(false);
          }}
        />
      )}
    </>
  );
};

const HeadsetImageWrapper = styled(Box)<{ state: DeviceState }>(
  ({ state }) => `
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: end;
  aspect-ratio: 382/264;
  width: 100%;

  & svg.HeadsetImageWrapper-headset {
    position: absolute;
    top: 0;
    width: 100%;
    height: auto;
    opacity: ${state === DeviceState.OFFLINE ? 0.2 : 1};
  }
`,
);

const RestartButton = styled(Button)<RestartButtonProps>`
  ${(props) =>
    props.small &&
    css`
      @media screen and (min-width: 900px) and (max-width: 1100px) {
        margin-left: 0;
        margin-right: 0;
        width: 36px;
        height: 36px;
        min-width: 0;
        font-size: 0.5rem;
      }
    `}
`;
