import { TableCell } from '@mui/material';
import { useTheme } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { TableCellLink } from '../../../base/components/BaseTable';
import {
  ColumnConfig,
  SimpleTable,
} from '../../../base/components/SimpleTable';
import { ApplicationSceneFragmentFragment } from '../../../graphql/graphql-operations';
import { joinStrings } from '../../../lib/strings';
import { SectionPaper } from '../../components/SectionPaper';

interface Props {
  scenes: ApplicationSceneFragmentFragment[];
  applicationVersionId?: string;
}

const ApplicationScenesTable = ({ scenes, applicationVersionId }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const columns: ColumnConfig<ApplicationSceneFragmentFragment>[] = [
    {
      id: 'name',
      defaultTrans: 'Name',
      component: (row: ApplicationSceneFragmentFragment) => {
        const version =
          applicationVersionId ||
          row.applicationVersions
            .map((version) => Number.parseInt(version.id))
            .reduce(
              (candidate, latest) =>
                !latest || candidate > latest ? candidate : latest,
              0,
            );
        return (
          <TableCellLink
            to={`/admin/applications/scenes/${row.id}?applicationVersionId=${version}`}
            label={row.name_t}
          />
        );
      },
    },

    {
      id: 'applicationSceneGroups',
      defaultTrans: 'Programs',
      component: (row: ApplicationSceneFragmentFragment) => {
        return (
          <TableCell>
            {joinStrings(
              ' | ',
              row.applicationSceneGroups?.length,
              ...(row.applicationSceneGroups?.map((g) => g.name) || []),
            )}
          </TableCell>
        );
      },
    },
    {
      id: 'parameters',
      defaultTrans: 'Parameters',
      component: (row: ApplicationSceneFragmentFragment) => {
        return (
          <TableCell>
            {row.parameters.length +
              ' | ' +
              row.parameters
                .map((param) => `${param.name_t} - ${param.values.length}`)
                .join(' | ')}
          </TableCell>
        );
      },
    },
    {
      id: 'enabled' as const,
      defaultTrans: 'Status',
      getLabel: (enabled: boolean) =>
        enabled
          ? t('applicationScenes.table.enabledValue', 'Enabled')
          : t('applicationScenes.table.disabled', 'Disabled'),
    },
  ];

  return (
    <SectionPaper style={{ marginTop: theme.spacing(2) }}>
      <SimpleTable<ApplicationSceneFragmentFragment>
        id="application-scenes"
        translationPrefix="applicationScenes.table"
        rowId="id"
        data={scenes}
        columns={columns}
        searchConfig={{
          filterBy: 'name',
          defaultTitle: 'Scene list',
          defaultPlaceholder: 'Search {{count}} scenes',
        }}
      />
    </SectionPaper>
  );
};

export default ApplicationScenesTable;
