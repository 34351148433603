import { useQuery } from '@apollo/client';
import { uuid4 } from '@sentry/utils';
import { indexBy } from 'ramda';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CreateSessionPlaylistItemInput,
  GetUserTrainingAssignmentDocument,
  UserTrainingFragmentFragment,
} from '../../../graphql/graphql-operations';
import useQueryErrorSnackbar from '../../../hooks/useQueryErrorSnackbar';
import { setToLocalStorageWithExpiration } from '../../../lib/localExpiringStorage';
import ContentWrapper from '../../components/ContentWrapper';
import { ListView } from '../../components/ListView';
import { Loader } from '../../components/Loader';
import PageHeader from '../../components/PageHeader';
import { SceneListItem } from '../../components/SceneListItem';
import { SceneListItemStatus } from '../../components/SceneListItem/SceneListItem';
import UserTrainingHeaderCard from './components/UserTrainingHeaderCard';

type PlaylistItem =
  UserTrainingFragmentFragment['playlistTemplate']['items'][0];

const UserTrainingDetailPage = () => {
  const navigate = useNavigate();
  const { userTrainingId } = useParams<{ userTrainingId: string }>();

  const { data, loading, error, refetch } = useQuery(
    GetUserTrainingAssignmentDocument,
    {
      variables: {
        userTrainingId: userTrainingId,
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  useQueryErrorSnackbar(error, refetch);
  const training = data?.userTrainingAssignment;

  const completed = useMemo(
    () =>
      training ? indexBy((i) => i.id, training.completion.completedItems) : {},
    [training],
  );

  const runnable = useMemo(
    () =>
      training
        ? training.playlistTemplate.items.find((i) => !completed[i.id])
        : null,
    [completed, training],
  );

  const handlePlayClick = (idx: number) => {
    const id = uuid4();
    const toRunItems = training!.playlistTemplate.items.slice(idx);
    const scenes: CreateSessionPlaylistItemInput[] = toRunItems.map((item) => ({
      sceneId: item.scene.id,
      params: item.params,
    }));
    setToLocalStorageWithExpiration(id, scenes, 24 * 60 * 60 * 1000);
    navigate(`/devices/launch/${id}?userId=${training!.user.id}`);
  };

  const renderItem = (item: PlaylistItem, idx: number) => {
    const completedData = completed[item.id];
    const status: SceneListItemStatus = !!completedData
      ? 'completed'
      : item.id === runnable?.id || !training?.playlistTemplate?.enforceOrder
      ? 'runnable'
      : 'waiting';
    if (true) {
      return (
        <SceneListItem
          idx={idx}
          scene={item.scene}
          params={item.params}
          completionDate={completedData?.completionDate}
          status={status}
          onPlayClick={() => handlePlayClick(idx)}
        />
      );
    }
  };

  return loading || !training ? (
    <Loader />
  ) : (
    <ContentWrapper>
      <PageHeader title={training.playlistTemplate.name} back></PageHeader>
      <UserTrainingHeaderCard training={training} />
      <ListView
        columns={1}
        rowKey="id"
        data={training.playlistTemplate.items}
        renderValue={renderItem}
        sx={{ mt: 1 }}
      />
    </ContentWrapper>
  );
};

export default UserTrainingDetailPage;
