import { useQuery } from '@apollo/client';
import { useMemo, useState } from 'react';
import { useDebounce } from 'react-use';
import {
  GetUsersDocument,
  GetUsersQuery,
  GetUsersQueryVariables,
  OrderDirection,
  UserOrderByInput,
  UserOrderField,
  UserRole,
  UserStatus,
} from '../../../../graphql/graphql-operations';
import useQueryErrorSnackbar from '../../../../hooks/useQueryErrorSnackbar';
import { ListView, SearchListVariables } from '../../../components/ListView';
import UserListCard from './UserListCard';

interface Props {
  companyId: string;
  patients?: boolean;
  userStatus?: UserStatus;
  trainingPastDue?: TrainingPastDueFilter;
  searchConfig?: SearchListVariables<UserListType>;
  userGroupIds?: string[];
  userRoles?: UserRole[];
  onClick?: (user: UserListType) => void;
  createLink?: (user: UserListType) => string;
  selectedUserIds?: string[];
  orderBy: UserOrderByInput;
}

export enum TrainingPastDueFilter {
  ALL = 'ALL',
  COMPLETED = 'COMPLETED',
  PAST_DUE = 'PAST_DUE',
}

type UserListType = NonNullable<GetUsersQuery['users']['nodes']>[0];

const UserList = ({
  companyId,
  trainingPastDue = TrainingPastDueFilter.ALL,
  searchConfig,
  userStatus = UserStatus.ACTIVE,
  userGroupIds,
  onClick,
  createLink,
  selectedUserIds,
  userRoles,
  orderBy,
}: Props) => {
  const [queryVariables, setQueryVariables] =
    useState<GetUsersQueryVariables>();

  const userRolesFixed = useMemo(() => {
    if (!userRoles?.length) {
      //all roles if none selected
      return Object.values(UserRole);
    }
    if (userRoles.includes(UserRole.NONE)) {
      //patient and none are simmilar except for login privilages
      return [...userRoles, UserRole.MEDICAL_PATIENT];
    }
    return userRoles;
  }, [userRoles]);

  useDebounce(
    () => {
      setQueryVariables({
        first: 30,
        filter: {
          companyId: companyId,
          filter: {
            role: userRolesFixed,
            status: userStatus,
            userGroupIds: userGroupIds,
            trainingPastDue:
              trainingPastDue === TrainingPastDueFilter.ALL
                ? null
                : trainingPastDue === TrainingPastDueFilter.PAST_DUE
                ? true
                : false,
          },
        },
        orderBy: orderBy || {
          direction: OrderDirection.ASC,
          field: UserOrderField.LAST_NAME,
        },
      });
    },
    125,
    [
      companyId,
      userStatus,
      trainingPastDue,
      userGroupIds,
      userRolesFixed,
      orderBy,
    ],
  );

  const { data, loading, variables, error, refetch, fetchMore } = useQuery(
    GetUsersDocument,
    {
      variables: queryVariables,
      fetchPolicy: 'cache-and-network',
      skip: !queryVariables,
    },
  );
  useQueryErrorSnackbar(error, refetch);

  const users = data?.users?.nodes || [];
  const renderValue = (user: UserListType) => {
    return (
      <UserListCard
        user={user}
        onClick={onClick ? () => onClick && onClick(user) : undefined}
        link={createLink && createLink(user)}
        selected={selectedUserIds?.includes(user.id)}
      />
    );
  };

  return (
    <ListView<UserListType, GetUsersQueryVariables>
      rowKey="id"
      data={users}
      renderValue={renderValue}
      loading={loading}
      lazyConfig={{
        fetchMore: fetchMore,
        refetch: refetch,
        variables: variables,
        pageCursors: data?.users.pageCursors,
        totalCount: data?.users.totalCount,
      }}
      searchConfig={searchConfig}
      columns={{ xs: 1, sm: 3 }}
    />
  );
};

export default UserList;
