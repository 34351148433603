import {
  ApolloQueryResult,
  DocumentNode,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  TypedDocumentNode,
  useQuery,
} from '@apollo/client';
import { useCallback, useState } from 'react';
import useQueryErrorSnackbar from '../../../../hooks/useQueryErrorSnackbar';

const debounceTimeout = 250;

function debounce<T>(f: (...args: any) => Promise<T>, interval: number) {
  let timer: NodeJS.Timer | undefined = undefined;

  return (...args: any) => {
    clearTimeout(timer);
    return new Promise<T>((resolve) => {
      timer = setTimeout(() => {
        resolve(f(...args));
      }, interval);
    });
  };
}

const useDebouncedQuery = <TData = any, TVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>,
): QueryResult<TData, TVariables> => {
  const [data, setData] = useState<TData>();

  const {
    data: _,
    error,
    refetch,
    ...rest
  } = useQuery(query, {
    ...options,
    onCompleted: setData,
  });
  useQueryErrorSnackbar(error, refetch);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounced = useCallback(
    debounce<ApolloQueryResult<TData>>((vars?: Partial<TVariables>) => {
      return refetch(vars);
    }, debounceTimeout),
    [refetch],
  );

  return { data, refetch: debounced, error, ...rest };
};

export default useDebouncedQuery;
