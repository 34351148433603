import {
  ExtendButtonBase,
  MenuItem,
  MenuItemTypeMap,
  styled,
} from '@mui/material';

const ClientUserMenuItem = styled(MenuItem)(
  ({ theme: { spacing } }) => `
    padding: ${spacing(2)} ${spacing(3)};
`,
) as ExtendButtonBase<MenuItemTypeMap>;

export default ClientUserMenuItem;
