import SVG from 'react-inlinesvg';
import { ReactComponent as MedicalLogo } from '../../../assets/svg/vr-medical-logo.svg';
import { ReactComponent as SchoolLogo } from '../../../assets/svg/vr-school-logo.svg';
import { ReactComponent as TrainingLogo } from '../../../assets/svg/vr-training-logo.svg';
import { isSchool, isTraining } from '../../../lib/useAppVariant';
import useCompany from '../../../lib/useCompany';

interface Props {
  className?: string;
  color?: string;
}

const StreamLogo = (props: Props) => {
  const { company } = useCompany();
  if (company?.logoUrl) {
    return <SVG src={company.logoUrl} {...props} />;
  }
  if (isTraining()) {
    return <TrainingLogo {...props} />;
  }
  if (isSchool()) {
    return <SchoolLogo {...props} />;
  }

  return <MedicalLogo {...props} />;
};

export default StreamLogo;
