import { FormikHelpers } from 'formik';
import { GraphQLError } from 'graphql';
import { TFunction } from 'react-i18next';

export const VALIDATION_FIELD_ERROR = 'VALIDATION_FIELD_ERROR';

interface Options {
  error: any;
  formikHelpers: FormikHelpers<any>;
  t: TFunction;
}

export const handleValidationFieldError = ({
  error,
  formikHelpers,
  t,
}: Options) => {
  if (error.message !== VALIDATION_FIELD_ERROR) throw error;
  const errors: GraphQLError[] = error.graphQLErrors;

  errors.forEach((err) => {
    if (err.message === VALIDATION_FIELD_ERROR) {
      const { field, messageKey, defaultMessage } = err.extensions!;
      formikHelpers.setFieldError(
        field,
        t(`validation.backend.error.${messageKey}`, defaultMessage),
      );
    }
  });
};
