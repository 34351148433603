import { useQuery } from '@apollo/client';
import { Button, styled, Typography } from '@mui/material';
import { useState } from 'react';
import { Flex } from '../../../../base/components/Flex';
import { useUser } from '../../../../base/components/UserProvider/useUserHook';
import {
  GetVrBoxesDocument,
  GetVrBoxesQuery,
  UserPermission,
} from '../../../../graphql/graphql-operations';
import { useTranslationPrefix } from '../../../../hooks/useTranslationPrefix';
import usePwaInstall from '../../../../lib/usePwaInstall';
import { SectionPaper } from '../../../components/SectionPaper';
import EditVrBoxDialog from './EditVrBoxDialog';
import NewVrBoxDialog from './NewVrBoxDialog';

interface Props {
  companyId: string;
}

export const VR_BOX_PARAM = 'vrBoxId';

type VrBox = GetVrBoxesQuery['vrBoxes'][0];

const VrBoxList = ({ companyId }: Props) => {
  const { _t } = useTranslationPrefix('vrBoxList');
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [editedVrBox, setEditedVrBox] = useState<VrBox | null>();
  const [createDialogOpened, setCreateDialogOpened] = useState(false);
  const { hasPermission } = useUser();
  const { data } = useQuery(GetVrBoxesDocument, {
    variables: {
      companyIdOptionalInput: {
        companyId,
      },
    },
    skip: !hasPermission(UserPermission.READ_COMPANY_VR_BOXES),
  });
  const { install, installAvailable } = usePwaInstall();
  const handleOpenEditDialog = (vrBox: VrBox) => {
    setEditedVrBox(vrBox);
    setEditDialogOpened(true);
  };
  const handleCloseEditDialog = () => {
    setEditDialogOpened(false);
  };

  const handleToggleCreateDialog = () => {
    setCreateDialogOpened((prev) => !prev);
  };
  return (
    <>
      <Flex justifyContent="flex-end" mt={4}>
        <Button
          id="create-vr-box-dialog-toggle"
          size="large"
          color="primary"
          variant="contained"
          onClick={handleToggleCreateDialog}
        >
          {_t(`createNew`, 'Create New VR Box')}
        </Button>
      </Flex>
      <Container>
        <Flex flexDirection="row" justifyContent="space-between">
          <Typography variant="h6" mb={2}>
            {_t('title', 'VR Box List')}
          </Typography>
        </Flex>
        <Flex flexDirection="column">
          {data?.vrBoxes.map((vrBox) => (
            <VrBoxItem key={vrBox.id}>
              <Flex>{vrBox.name}</Flex>
              <Flex>{`${window.location.origin}?${VR_BOX_PARAM}=${vrBox.id}`}</Flex>
              <Flex>
                {installAvailable && (
                  <Button
                    id={`install-vr-box-${vrBox.id}`}
                    sx={{ mr: 2 }}
                    onClick={() => {
                      install({
                        origin: window.location.origin,
                        name: vrBox.name,
                        short_name: vrBox.name,
                        start_url: `${window.location.origin}?${VR_BOX_PARAM}=${vrBox.id}`,
                      });
                    }}
                  >
                    {_t('install', 'Install')}
                  </Button>
                )}
                <Button
                  id={`edit-vr-box-dialog-toggle-${vrBox.id}`}
                  onClick={() => {
                    handleOpenEditDialog(vrBox);
                  }}
                >
                  {_t('edit', 'Edit')}
                </Button>
              </Flex>
            </VrBoxItem>
          ))}
          {data?.vrBoxes.length === 0 && (
            <Flex>{_t('noVrBoxes', 'No VR Boxes')}</Flex>
          )}
        </Flex>
        <NewVrBoxDialog
          onClose={handleToggleCreateDialog}
          open={createDialogOpened}
          companyId={companyId}
        />
        {editedVrBox && (
          <EditVrBoxDialog
            onClose={handleCloseEditDialog}
            open={editDialogOpened}
            vrBox={editedVrBox}
          />
        )}
      </Container>
    </>
  );
};

const Container = styled(SectionPaper)(
  ({ theme: { spacing } }) => `
  margin-top: ${spacing(2)};
`,
);

const VrBoxItem = styled(Flex)(
  ({ theme: { spacing, palette } }) => `
  border-top: solid 1px ${palette.grey[500]};
  align-items: center;
  justify-content: space-between;
  padding: ${spacing(2)} ${spacing(2)};
  flex: 1;
`,
);

export default VrBoxList;
