import { useQuery } from '@apollo/client';
import { TableCell } from '@mui/material';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'react-use';
import { TableCellLink } from '../../../base/components/BaseTable';
import {
  ColumnConfig,
  SimpleTable,
} from '../../../base/components/SimpleTable';
import {
  ApplicationOrderField,
  ApplicationStatus,
  GetApplicationsDocument,
  GetApplicationsQuery,
  GetApplicationsQueryVariables,
  OrderDirection,
} from '../../../graphql/graphql-operations';
import useQueryErrorSnackbar from '../../../hooks/useQueryErrorSnackbar';
import { DATE_FORMAT } from '../../../lib/constants';
import { joinStrings } from '../../../lib/strings';

interface TableData {
  id: string;
  name: string;
  scenes: string[];
  published: Date | null;
  version: string;
  enabled: boolean;
  package: string;
}

interface Props {
  status?: ApplicationStatus;
}

const ApplicationsTable = ({ status }: Props) => {
  const { t } = useTranslation();
  const [rowsPerPage] = useSessionStorage('rowsPerPage', 15);

  const { data, loading, error, refetch, variables } = useQuery(
    GetApplicationsDocument,
    {
      variables: getDefaultVariables(rowsPerPage, status),
    },
  );
  useQueryErrorSnackbar(error, refetch);
  const tableData: TableData[] = prepareTableData(data);

  const columns: ColumnConfig<TableData>[] = [
    {
      id: 'name' as const,
      defaultTrans: 'Name',
      component: ({ name, id }) => (
        <TableCellLink to={`/admin/applications/${id}`} label={name} />
      ),
    },
    {
      id: 'package' as const,
      defaultTrans: 'Package',
    },
    {
      id: 'scenes' as const,
      defaultTrans: 'Scenes',
      component: ({ scenes }) => (
        <TableCell>{joinStrings(' | ', scenes.length, ...scenes)}</TableCell>
      ),
      sortable: false,
    },
    {
      id: 'published' as const,
      defaultTrans: 'Published',
      getLabel: (published: Date | null) =>
        published
          ? format(published, DATE_FORMAT)
          : t('applications.table.unpublished', 'Unpublished'),
      sortable: false,
    },
    {
      id: 'version' as const,
      defaultTrans: 'Current Version',
      sortable: false,
    },
    {
      id: 'enabled' as const,
      defaultTrans: 'Status',
      getLabel: (enabled: boolean) =>
        enabled
          ? t('applications.table.enabled', 'Enabled')
          : t('applications.table.disabled', 'Disabled'),
    },
  ];

  return (
    <SimpleTable<TableData, GetApplicationsQueryVariables>
      id="applications"
      rowId="id"
      translationPrefix="applications.table"
      isLoading={loading}
      data={tableData}
      columns={columns}
      lazyConfig={{
        refetch: refetch,
        variables: variables,
        pageCursors: data?.applications.pageCursors,
        totalCount: data?.applications.totalCount,
      }}
      searchConfig={{
        filterBy: 'name',
        defaultPlaceholder: 'Search {{count}} applications',
        defaultTitle: 'Application list',
      }}
    />
  );
};

export default ApplicationsTable;

const getDefaultVariables = (
  rowsPerPage: number,
  status?: ApplicationStatus,
): GetApplicationsQueryVariables => {
  const defaultVariables = {
    first: rowsPerPage,
    orderBy: {
      direction: OrderDirection.ASC,
      field: ApplicationOrderField.NAME,
    },
    filter: status && {
      status: status,
    },
  };
  return defaultVariables;
};

const prepareTableData = (data?: GetApplicationsQuery): TableData[] => {
  const nodes = data?.applications.nodes || [];
  return nodes.map((app) => {
    return {
      id: app.id,
      name: app.name,
      enabled: app.enabled,
      scenes: app.currentVersion?.scenes.map((scene) => scene.name_t) || [],
      published: app.currentVersion?.publishedAt
        ? new Date(app.currentVersion.publishedAt)
        : null,
      version: app.currentVersion?.version || '',
      package: app.package || '',
    };
  });
};
