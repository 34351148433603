import { BoxProps, styled } from '@mui/material';
import { useTranslationPrefix } from '../../../../hooks/useTranslationPrefix';
import Chart from '../../../components/Charts/Chart';

interface Props extends BoxProps {
  data?: ProgressData | null;
}

interface ProgressData {
  readonly key: string;
  readonly gameTimeSeconds: number;
  readonly runTimeSeconds: number;
}

const RunTimeProgressGuage = ({ data, ...boxProps }: Props) => {
  const { _t } = useTranslationPrefix('RunTimeProgressGuage');
  const gameTimeSeconds = data?.gameTimeSeconds || 0;
  const runTimeSeconds = data?.runTimeSeconds || data?.gameTimeSeconds || 1;
  const progress = gameTimeSeconds / runTimeSeconds;
  return (
    <RunTimeProgressGuageWrapper
      {...boxProps}
      options={{
        series: [
          {
            type: 'gauge',
            startAngle: 180,
            endAngle: 0,
            center: ['50%', '90%'],
            radius: '150%',
            min: 0,
            max: 1,
            splitNumber: 8,
            axisLine: {
              lineStyle: {
                width: 6,
                color: [
                  [0.25, '#FF6E76'],
                  [0.75, '#FDDD60'],
                  [1, '#0dcc5d'],
                ],
              },
            },
            pointer: {
              icon: 'triangle',
              length: '12%',
              width: 20,
              offsetCenter: [0, '-60%'],
              itemStyle: {
                color: 'auto',
              },
            },
            axisTick: {
              length: 12,
              lineStyle: {
                color: 'auto',
                width: 2,
              },
            },
            splitLine: {
              length: 20,
              lineStyle: {
                color: 'auto',
                width: 5,
              },
            },
            axisLabel: {
              color: '#464646',
              fontSize: 20,
              distance: -50,
              rotate: 'tangential',
              formatter: (value: number) => {
                if (value === 0.875) {
                  return _t('value.high', 'Vysoká');
                } else if (value === 0.5) {
                  return _t('value.middle', 'Střední');
                } else if (value === 0.125) {
                  return _t('value.low', 'Nízká');
                }
                return '';
              },
            },
            detail: {
              fontSize: 30,
              offsetCenter: [0, '-15%'],
              valueAnimation: true,
              formatter: (value: number) => {
                return Math.round(value * 100) + '%';
              },
              color: 'inherit',
            },
            data: [
              {
                value: progress,
              },
            ],
          },
        ],
      }}
    />
  );
};

const RunTimeProgressGuageWrapper = styled(Chart)`
  min-height: 200px;
  min-width: 200px;
  width: 100%;
  text-align: center;
  & > div {
    margin: auto !important;
  }
`;

export default RunTimeProgressGuage;
