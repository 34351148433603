import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Dialog,
  DialogProps,
  DialogTitle as MuiDialogTitle,
  IconButton as MuiIconButton,
  Slide,
  styled,
  Typography as MuiTypography,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';
import { Flex } from '../Flex';

interface Props extends Omit<DialogProps, 'title'> {
  title: React.ReactNode;
  children: React.ReactNode;
}

const BaseDialog = ({ onClose, title, children, ...rest }: Props) => {
  return (
    <Dialog
      {...rest}
      onClose={onClose}
      TransitionComponent={BaseDialogTransition}
    >
      <Box padding={2}>
        <DialogTitle>
          <Flex justifyContent="flex-end">
            <IconButton
              id="dialog-close"
              aria-label="close"
              onClick={(event) => onClose && onClose(event, 'escapeKeyDown')}
            >
              <CloseIcon color="primary" />
            </IconButton>
          </Flex>
          <Flex justifyContent="center">
            <Title color="textPrimary" variant="h4">
              {title}
            </Title>
          </Flex>
        </DialogTitle>
        <Box paddingBottom={2}> {children}</Box>
      </Box>
    </Dialog>
  );
};

export default BaseDialog;

export const BaseDialogTransition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
  padding: theme.spacing(0, 0, 4, 0),
}));

const IconButton = styled(MuiIconButton)(({ theme }) => ({
  padding: 0,
}));

const Title = styled(MuiTypography)(({ theme }) => ({
  padding: theme.spacing(0, 4, 0, 4),
}));
