import { useState } from '@hookstate/core';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ScreenViewWorkaround from '../../../base/components/ScreenMirrorWorkaround/ScreenViewWorkaround';
import { DeviceState } from '../../../graphql/graphql-operations';
import EndVrSessionDialog from '../../components/EndVrSessionDialog/EndVrSessionDialog';
interface Props {
  vrDeviceId: string;
  vrDeviceState: DeviceState;
  vrSessionId: string;
  sessionEnded: boolean;
  userId?: string;
}

const Actions: React.VFC<Props> = ({
  vrDeviceId,
  vrSessionId,
  sessionEnded,
  vrDeviceState,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const state = useState({
    screenViewOpen: false,
    endVrSessionOpen: false,
  });
  const handleVrSessionEnded = (userId?: string) => {
    if (userId) {
      navigate(`/admin/patients/${userId}`);
    } else {
      navigate('/admin/vr-devices');
    }
  };
  return (
    <>
      {!sessionEnded && vrDeviceState !== DeviceState.OFFLINE && (
        <Button
          id="vr-session-action-livestream"
          size="large"
          color="primary"
          variant="text"
          startIcon={<PlayArrowIcon color="error" />}
          onClick={() => state.screenViewOpen.set(true)}
        >
          {t('vrSessionDetail.actions.liveStream', 'Watch livestream')}
        </Button>
      )}
      <Button
        id="vr-session-action-stop"
        size="large"
        color="error"
        variant="contained"
        startIcon={<NotInterestedIcon />}
        onClick={() => state.endVrSessionOpen.set(true)}
      >
        {sessionEnded
          ? t('vrSessionDetail.actions.rateVrSession', 'Rate session')
          : t('vrSessionDetail.actions.endVrSession', 'Stop session')}
      </Button>
      <EndVrSessionDialog
        open={state.endVrSessionOpen.get()}
        onClose={() => state.endVrSessionOpen.set(false)}
        vrSessionId={vrSessionId}
        onFinished={handleVrSessionEnded}
      />
      <ScreenViewWorkaround
        deviceId={vrDeviceId}
        open={state.screenViewOpen.get()}
        onClose={() => state.screenViewOpen.set(false)}
      />
    </>
  );
};

export default Actions;
