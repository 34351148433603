import { useQuery } from '@apollo/client';
import BackHandIcon from '@mui/icons-material/BackHand';
import { Card, CardContent } from '@mui/material';
import { useCallback } from 'react';
import { Flex } from '../../../base/components/Flex';
import {
  GetUpperLimbActivityReportDocument,
  GetUpperLimbActivityReportQuery,
  GetUpperLimbActivitySeriesDocument,
} from '../../../graphql/graphql-operations';
import useQueryErrorSnackbar from '../../../hooks/useQueryErrorSnackbar';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import { getEndOfDay } from '../../../lib/date';
import { formatDuration } from '../../../lib/duration';
import { UpdateAxisPointerEvent } from '../../components/Charts/ChartTypes';
import ContentWrapper from '../../components/ContentWrapper';
import { Loader } from '../../components/Loader';
import PageHeader from '../../components/PageHeader';
import { UserReportPage } from './UserReportsRoute';
import ReportNav from './components/ReportNav';
import ReportValue from './components/ReportValue';
import UpperLimbActivityChart, {
  calcActivityPercent,
} from './components/UpperLimbActivityChart';
import useDebouncedQuery from './utils/useDeboucedQuery';
import { useReportUser } from './utils/useReportUser';

const calcImprovement = (
  data: GetUpperLimbActivityReportQuery['userReports']['upperLimbActivityChange'],
  side: 'left' | 'right',
) => {
  const percentFirstDay = calcActivityPercent(
    side === 'left' ? data?.leftActiveFirst : data?.rightActiveFirst,
    side === 'left' ? data?.leftTotalFirst : data?.rightTotalFirst,
  );
  const percentLastDay = calcActivityPercent(
    side === 'left' ? data?.leftActiveLast : data?.rightActiveLast,
    side === 'left' ? data?.leftTotalLast : data?.rightTotalLast,
  );
  return Math.round((percentLastDay / percentFirstDay - 1) * 10000) / 100;
};

const UserUpperLimbActivityReportPage = () => {
  const { _t } = useTranslationPrefix('UserUpperLimbActivityReportPage');
  const { userId, userData, userLoading, backUrl } = useReportUser();

  const { data: reportData, refetch: reportRefetch } = useDebouncedQuery(
    GetUpperLimbActivityReportDocument,
    {
      variables: {
        userId: userId!,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      skip: !userId,
    },
  );

  const {
    data: seriesData,
    refetch: seriesRefetch,
    error: seriesError,
    loading: seriesLoading,
  } = useQuery(GetUpperLimbActivitySeriesDocument, {
    variables: {
      userId: userId!,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    skip: !userId,
  });

  useQueryErrorSnackbar(seriesError, seriesRefetch);

  const updateDate = useCallback(
    (e: UpdateAxisPointerEvent) => {
      const value = e.axesInfo.find((i) => i.axisDim === 'x')?.value;
      if (value) {
        reportRefetch({
          toDate: getEndOfDay(value).toISOString(),
        });
      }
    },
    [reportRefetch],
  );

  const loading = userLoading || !reportData || seriesLoading;

  return (
    <ContentWrapper>
      <PageHeader
        title={_t('title', 'Report {{firstName}} {{lastName}}', {
          firstName: userData?.user.firstName,
          lastName: userData?.user.lastName,
        })}
        back={backUrl}
      ></PageHeader>
      <ReportNav currentPage={UserReportPage.UPPER_LIMB_ACTIVITY} />
      {loading ? (
        <Loader />
      ) : (
        <>
          <Flex flexDirection="column" gap={2}>
            <Flex
              sx={{
                flexDirection: { xs: 'row', md: 'row' },
                gap: 2,
                flexGrow: 2,
              }}
            >
              <ReportValue
                label={_t('increasedActivity', 'Zlepření aktivity')}
                value={
                  calcImprovement(
                    reportData!.userReports.upperLimbActivityChange,
                    'left',
                  ) + '%'
                }
                secondLabel={_t('reachedMaxSeconds', 'Dosažené maximum')}
                secondValue={formatDuration(
                  (reportData!.userReports.upperLimbActivityChange
                    ?.leftActiveMax || 0) * 1000,
                )}
                icon={<BackHandIcon fontSize="large" />}
                title={_t('leftHand', 'Levá ruka')}
              />

              <ReportValue
                label={_t('increasedActivity', 'Zlepření aktivity')}
                value={
                  calcImprovement(
                    reportData!.userReports.upperLimbActivityChange,
                    'right',
                  ) + '%'
                }
                secondLabel={_t('reachedMaxSeconds', 'Dosažené maximum')}
                secondValue={formatDuration(
                  (reportData!.userReports.upperLimbActivityChange
                    ?.rightActiveMax || 0) * 1000,
                )}
                icon={
                  <BackHandIcon
                    fontSize="large"
                    sx={{ transform: 'scaleX(-1)' }}
                  />
                }
                title={_t('rightHand', 'Pravá ruka')}
              />
            </Flex>
            <Card>
              <CardContent>
                <UpperLimbActivityChart
                  data={seriesData!.userReports.upperLimbActivitySeries}
                  onAxisPointerChange={updateDate}
                />
              </CardContent>
            </Card>
          </Flex>
        </>
      )}
    </ContentWrapper>
  );
};

export default UserUpperLimbActivityReportPage;
