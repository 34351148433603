import { styled } from '@mui/material';
import { BaseCard } from '../BaseCard';

const SelectableCard = styled(BaseCard, {
  shouldForwardProp: (prop) => prop !== 'selected',
})<{ selected?: boolean }>(({ selected, theme: { palette } }) => {
  if (selected) {
    return `
  background-color:  ${palette.primary.lighter};
  `;
  }
  return ``;
});

export default SelectableCard;
