import { TableFooter, TablePagination, TableRow } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import BaseTablePaginationActions from './BaseTablePaginationActions';
import { BaseTableContext } from './BaseTableProvider';

const BaseTableFooter = () => {
  const { t } = useTranslation();
  const { numberOfRows, rowsPerPage, page, setRowsPerPage, setPage, id } =
    useContext(BaseTableContext);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(rowsPerPage);
  };

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => setPage(newPage);

  return (
    <TableFooter>
      <TableRow>
        <TablePagination
          id={`${id}Pagination`}
          ActionsComponent={BaseTablePaginationActions}
          rowsPerPageOptions={[15, 30, 45]}
          count={numberOfRows}
          page={page}
          rowsPerPage={rowsPerPage}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} ${t('table.pagination.of', 'z')} ${
              count !== -1
                ? count
                : `${t('table.pagination.moreThan', 'více než')} ${to}`
            }`
          }
          labelRowsPerPage={`${t(
            'table.rowsPerPage',
            'Počet řádků na stránce',
          )}:`}
          SelectProps={{
            inputProps: {
              'aria-label': t('table.rowsPerPage', 'Počet řádků na stránce'),
            },
            native: true,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableRow>
    </TableFooter>
  );
};

export default BaseTableFooter;
