import { useState } from '@hookstate/core';
import { debounce } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseTypography } from '../../../base/components/BaseTypography';
import { Flex } from '../../../base/components/Flex';
import SearchField from '../SearchField/SearchField';

interface Props {
  id: string;
  title: string;
  onChange: (value: string) => void;
  totalCount: number;
  placeholderTransKey: string;
  defaultPlaceholder?: string;
}

const SearchControlBar = ({
  id,
  title,
  onChange,
  totalCount,
  placeholderTransKey,
  defaultPlaceholder,
}: Props) => {
  const { t } = useTranslation();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedTableStateChange = useCallback(
    debounce((value: string) => onChange(value), 300),
    [onChange, debounce],
  );

  const handleSearchChange = (value: string) => {
    valueState.set(value);

    debouncedTableStateChange(value);
  };
  const valueState = useState<string>('');

  return (
    <Flex id={id} justifyContent="space-between" marginBottom={3}>
      <BaseTypography variant="h6">{title}</BaseTypography>
      <SearchField
        id={`${id}-search-field`}
        size="large"
        onChange={handleSearchChange}
        value={valueState.get()}
        placeholder={t(placeholderTransKey, defaultPlaceholder, {
          count: totalCount,
        })}
      />
    </Flex>
  );
};

export default SearchControlBar;
