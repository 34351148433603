import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { TextFieldProps } from '@mui/material';
import csLocale from 'date-fns/locale/cs';
import enLocale from 'date-fns/locale/en-US';
import { Field } from 'formik';
import { DatePicker } from 'formik-mui-lab';
import { useTranslation } from 'react-i18next';

interface Props extends Omit<TextFieldProps, 'value' | 'error'> {
  name: string;
}

const localeMap: Record<string, object> = {
  en: enLocale,
  cs: csLocale,
};

const FormkDatePicker = ({ name, ...textFieldProps }: Props) => {
  const { i18n } = useTranslation();
  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      locale={localeMap[i18n.language]}
    >
      <Field component={DatePicker} name={name} {...textFieldProps} />
    </LocalizationProvider>
  );
};

export default FormkDatePicker;
