import { Box } from '@mui/material';
import {
  Draggable,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from '@react-forked/dnd';
import { ScrollableList } from '../../pages/Users/components/ScrollableList';
import PlaylistSelectedScene from './PlaylistSelectedScene';
import PlaylistSelectedScenePlaceholder from './PlaylistSelectedScenePlaceholder';
import { Scene } from './PlaylistTypes';

interface Props {
  selectedScenes: Scene[];
  onRemoveClick: (sceneId: string, idx: number) => void;
  draggingNewScene: boolean;
}

const PlaylistSelectedScenes = ({
  selectedScenes,
  onRemoveClick,
  draggingNewScene,
}: Props) => {
  return (
    <ScrollableList
      id="playlist-selected-scenes"
      sx={{ height: '100%', minWidth: '100%' }}
      wrapperSx={{ minWidth: '100%' }}
    >
      <Droppable droppableId="selected-scenes" direction="horizontal">
        {(provided: DroppableProvided, snaphot: DroppableStateSnapshot) => {
          return (
            <Box
              display="flex"
              gap={3}
              py={2}
              flexGrow={1}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {selectedScenes.map((scene, idx) => (
                <Draggable
                  key={idx}
                  draggableId={`selected-scene-${scene.id}-${idx}`}
                  index={idx}
                >
                  {(provided, snapshot) => (
                    <PlaylistSelectedScene
                      cardRef={provided.innerRef}
                      idx={idx}
                      scene={scene}
                      onRemoveClick={onRemoveClick}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    />
                  )}
                </Draggable>
              ))}
              {!selectedScenes.length && (
                <PlaylistSelectedScenePlaceholder
                  draggingNewScene={draggingNewScene}
                />
              )}
              {provided.placeholder}
            </Box>
          );
        }}
      </Droppable>
    </ScrollableList>
  );
};

export default PlaylistSelectedScenes;
