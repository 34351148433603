import { useState } from '@hookstate/core';
import { default as EditIcon } from '@mui/icons-material/Edit';
import { Button, styled, SvgIcon as MuiSvgIcon } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as VrCardboard } from '../../../assets/svg/vr-cardboard-solid.svg';
import LaunchSceneDialog from '../../components/LaunchSceneDialog/LaunchSceneDialog';
import EditPatientDialog from './EditPatientDialog';

interface Props {
  userId: string;
  name: string;
  vrSessionId?: string;
}

const PatientDetailActions: React.VFC<Props> = ({
  userId,
  name,
  vrSessionId,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const state = useState({
    newVrSessionDialogOpen: false,
    editPatientDialogOpen: false,
  });

  return (
    <>
      <Button
        id="patient-detail-edit"
        color="primary"
        variant="text"
        size="large"
        startIcon={<EditIcon />}
        onClick={() => state.editPatientDialogOpen.set(true)}
        style={{ marginRight: '20px' }}
      >
        {t('patients.detail.editPatient', 'Edit Patient')}
      </Button>
      {vrSessionId ? (
        <Button
          id="patient-detail-open-session"
          color="primary"
          variant="contained"
          size="large"
          startIcon={<SvgIcon fontSize="large"></SvgIcon>}
          onClick={() => navigate(`/admin/vr-session/${vrSessionId}`)}
        >
          {t('patients.detail.openSession', 'Open current session')}
        </Button>
      ) : (
        <Button
          id="patient-detail-start-session"
          color="primary"
          variant="contained"
          size="large"
          startIcon={
            <SvgIcon fontSize="large">
              <VrCardboard />
            </SvgIcon>
          }
          onClick={() => state.newVrSessionDialogOpen.set(true)}
        >
          {t('patients.detail.startSession', 'Start a session')}
        </Button>
      )}
      <LaunchSceneDialog
        open={state.newVrSessionDialogOpen.get()}
        onClose={() => state.newVrSessionDialogOpen.set(false)}
        userId={userId}
      />
      <EditPatientDialog
        open={state.editPatientDialogOpen.get()}
        onClose={() => state.editPatientDialogOpen.set(false)}
        patientId={userId}
        patientName={name}
      />
    </>
  );
};

export default PatientDetailActions;
const SvgIcon = styled(MuiSvgIcon)(({ theme }) => ({
  fontSize: `${theme.spacing(2)} !important`,
}));
