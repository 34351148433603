import { useMutation, useQuery } from '@apollo/client';
import { useState } from '@hookstate/core';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, DialogProps, MenuItem, styled } from '@mui/material';
import { Field, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { equals, omit } from 'ramda';
import { TFunction, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import * as Yup from 'yup';
import { BaseDialog } from '../../../../base/components/BaseDialog';
import ConfirmDialog from '../../../../base/components/ConfirmDialog/ConfirmDialog';
import { Flex } from '../../../../base/components/Flex';
import FormikColorPicker from '../../../../base/components/form/FormikColorPicker';
import FormikCompanySelect from '../../../../base/components/form/FormikCompanySelect';
import FormikForm from '../../../../base/components/form/FormikForm';
import FormikSelect from '../../../../base/components/form/FormikSelect';
import HasPermission from '../../../../base/components/UserProvider/HasPermission';
import {
  DeleteVrDeviceDocument,
  EditVrDeviceDocument,
  GetVrBoxesDocument,
  UserPermission,
} from '../../../../graphql/graphql-operations';
import useMutationSnackbar from '../../../../hooks/useMutationSnackbar';
import { VrDevice } from './VrDeviceDetailPage';

interface VrDeviceForm {
  name: string;
  color: string;
  vrBoxId: string;
  companyId: string;
}

interface Props extends DialogProps {
  onClose: () => void;
  vrDevice: VrDevice;
}

let companyIdChanged: boolean = false;

const EditVrDeviceDialog = ({ open, onClose, vrDevice, ...rest }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const deleteConfirmOpen = useState(false);
  const transferOpenedState = useState(false);
  const transferOpened = transferOpenedState.get();
  // NOTE: We can not use this in onSuccessfullyCompleted, because race condition between
  // mutation result and UnregisteredEvent
  const onCompanyIdChanged = () => {
    onClose();
    navigate('/admin/vr-devices');
  };

  const { data: vrBoxData } = useQuery(GetVrBoxesDocument, {
    variables: {
      companyIdOptionalInput: {
        companyId: String(vrDevice.companyId),
      },
    },
  });
  const toggleDeleteConfirmDialog = () =>
    deleteConfirmOpen.set((prev) => !prev);
  const editVrDeviceHandler = useMutationSnackbar('editVrdDeviceDialog', {
    onSuccessfullyCompleted: () => {
      if (!companyIdChanged) {
        onClose();
      }
    },
  });
  const [editVrDevice] = useMutation(EditVrDeviceDocument, editVrDeviceHandler);
  const handleFormSubmit = ({ color, vrBoxId, ...rest }: VrDeviceForm) => {
    let toEdit: Partial<VrDeviceForm> = rest;
    if (rest.companyId === vrDevice.companyId.toString()) {
      toEdit = omit(['companyId'], toEdit);
      companyIdChanged = false;
    } else {
      companyIdChanged = true;
    }
    editVrDevice({
      variables: {
        editVrDeviceInput: {
          vrDeviceId: vrDevice.id,
          color: color,
          vrBoxId: vrBoxId || null,
          ...toEdit,
        },
      },
    });
    if (companyIdChanged) {
      onCompanyIdChanged();
    }
  };

  const deleteVrDeviceHandle = useMutationSnackbar(
    'editVrdDeviceDialog.delete',
  );
  const [deleteVrDevice, { loading }] = useMutation(DeleteVrDeviceDocument, {
    ...deleteVrDeviceHandle,
  });
  const handleDeleteVrDevice = () => {
    deleteVrDevice({
      variables: { deleteVrDeviceInput: { vrDeviceId: vrDevice.id } },
    });
    onCompanyIdChanged();
  };

  const initialValues: VrDeviceForm = {
    name: vrDevice.name,
    color: vrDevice.color,
    vrBoxId: vrDevice.vrBoxId || '',
    companyId: vrDevice.companyId.toString(),
  };

  return (
    <BaseDialog
      {...rest}
      open={open}
      onClose={onClose}
      title={t('editVrdDeviceDialog.title', 'Edit headset')}
      disableEnforceFocus
    >
      <Formik<VrDeviceForm>
        initialValues={initialValues}
        validationSchema={getValidationSchema(t)}
        onSubmit={handleFormSubmit}
      >
        {({ isSubmitting, values, isValid }) => (
          <FormikForm>
            <Field
              component={TextField}
              variant="outlined"
              name="name"
              label={t('registerVrDevice.form.name', 'Headset name')}
              inputProps={{
                autoComplete: 'off',
              }}
              required
            />
            <FormikSelect
              name="vrBoxId"
              label={t('registerVrDevice.form.vrBox', 'VR Box')}
            >
              <MenuItem value="">
                <em>
                  {t('registerVrDevice.from.vrBoxUnassigned', 'Not Assigned')}
                </em>
              </MenuItem>
              {vrBoxData?.vrBoxes.map((vrBox) => (
                <MenuItem key={vrBox.id} value={vrBox.id}>
                  {vrBox.name}
                </MenuItem>
              ))}
            </FormikSelect>
            <FormikColorPicker name="color" />
            <TransferContainer transferOpened={transferOpened}>
              {transferOpened && (
                <HasPermission val={UserPermission.MANAGE_COMPANY_VR_DEVICES}>
                  {transferOpened && (
                    <FormikCompanySelect
                      name="companyId"
                      label={t(
                        'registerVrDevice.form.companyId',
                        'Headset Organization',
                      )}
                    />
                  )}
                </HasPermission>
              )}
              <HasPermission val={UserPermission.MANAGE_COMPANY_VR_DEVICES}>
                <Button
                  id="edit-vr-device-delete"
                  color="error"
                  variant="text"
                  startIcon={<DeleteIcon color="error" />}
                  onClick={toggleDeleteConfirmDialog}
                >
                  {t('editVrdDeviceDialog.delete.button.label', 'Delete')}
                </Button>
                {!transferOpened && (
                  <Button
                    id="edit-vr-device-transfer"
                    color="primary"
                    variant="text"
                    startIcon={<AccountBalanceIcon color="primary" />}
                    onClick={() => transferOpenedState.set((prev) => !prev)}
                  >
                    {t('editVrdDeviceDialog.transfer.button.label', 'Transfer')}
                  </Button>
                )}
              </HasPermission>
            </TransferContainer>
            <Flex justifyContent="space-between">
              <Button
                id="edit-vr-device-cancel"
                size="large"
                color="primary"
                disabled={isSubmitting}
                onClick={onClose}
              >
                {t('common.cancel', 'Cancel')}
              </Button>
              <Button
                id="edit-vr-device-confirm"
                type="submit"
                size="large"
                color="primary"
                variant="contained"
                disabled={
                  isSubmitting || !isValid || equals(initialValues, values)
                }
              >
                {t('editVrdDeviceDialog.save', 'Save')}
              </Button>
            </Flex>
          </FormikForm>
        )}
      </Formik>
      <ConfirmDialog
        open={deleteConfirmOpen.get()}
        onClose={toggleDeleteConfirmDialog}
        onConfirm={handleDeleteVrDevice}
        title={t('deleteVrDevice.title', 'Do you want to delete this headset?')}
        confirmLabel={t('deleteVrDevice.button.label', 'delete headset')}
        confirmButtonColor="error"
        confirmDisabled={loading}
        confirmButtonStartIcon={<DeleteIcon />}
      />
    </BaseDialog>
  );
};

export default EditVrDeviceDialog;

const TransferContainer = styled(Flex)<{ transferOpened: boolean }>(
  ({ transferOpened, theme: { spacing } }) => ({
    justifyContent: 'space-between',
    flexDirection: transferOpened ? 'column' : 'row',
    '& >:not(:last-child)': {
      marginBottom: transferOpened ? spacing(2) : 0,
    },
  }),
);

const getValidationSchema = (t: TFunction) =>
  Yup.object({
    name: Yup.string().required(
      t('registerVrDevice.form.nameRequired', 'Device name is required'),
    ),
    color: Yup.string().required(
      t('registerVrDevice.form.colorRequired', 'Color is required'),
    ),
  });
