import { Route, Routes } from 'react-router-dom';
import { useUser } from '../../../base/components/UserProvider/useUserHook';
import { UserPermission } from '../../../graphql/graphql-operations';
import AppStartedPage from '../AppStarted/AppStartedPage';
import NotFoundPage from '../NotFoundPage/NotFoundPage';
import SessionDetailPage from './SessionDetailPage';

const SessionsRoute = () => {
  const { hasPermission } = useUser();
  return (
    <Routes>
      <Route
        path="/:vrSessionId"
        element={
          hasPermission(UserPermission.VIEW_LIVE_STREAM) ? (
            <SessionDetailPage />
          ) : (
            <AppStartedPage />
          )
        }
      />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default SessionsRoute;
