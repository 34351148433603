import { compareByOrdinalAndProp } from '../../../lib/compare';
import PlaylistLargeParam from './PlaylistLargeParam';
import PlaylistOptionalFilter from './PlaylistOptionalFilter';
import {
  PlaylistParamValueIds,
  PlaylistSelectedParams,
  SceneParametr,
} from './PlaylistTypes';

interface Props {
  params: SceneParametr[];
  selectedParams: PlaylistSelectedParams;
  enabledParams: PlaylistParamValueIds;
  setFilterParam: (paramId: string, valueId?: string) => void;
  enabledScenes: string[];
}

const PlaylistFilter = ({
  params,
  selectedParams,
  enabledParams,
  setFilterParam,
  enabledScenes,
}: Props) => {
  const all = params.filter((param) => param.filtered);
  const required = all
    .filter((param) => param.required)
    .sort(compareByOrdinalAndProp('name_t'));
  const optional = all
    .filter((param) => !param.required)
    .sort(compareByOrdinalAndProp('name_t'));

  const handleChange = (paramId: string) => (valueId?: string) =>
    setFilterParam(paramId, valueId);
  return (
    <>
      {required.map((param) => (
        <PlaylistLargeParam
          key={param.id}
          param={param}
          enabledIds={enabledParams[param.id]}
          selectedId={selectedParams[param.id]}
          onChange={handleChange(param.id)}
          required={true}
        />
      ))}
      {enabledScenes.length ? (
        <PlaylistOptionalFilter
          params={optional}
          selectedParams={selectedParams}
          enabledParams={enabledParams}
          setFilterParam={setFilterParam}
        />
      ) : null}
    </>
  );
};

export default PlaylistFilter;
