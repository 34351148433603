import { useMutation, useQuery } from '@apollo/client';
import { useState } from '@hookstate/core';
import {
  Button,
  CircularProgress,
  debounce,
  styled,
  TextField,
} from '@mui/material';
import { ErrorMessage, Field, Formik } from 'formik';
import { Autocomplete } from 'formik-mui';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { adminState } from '../../../adminState';
import { Flex } from '../../../base/components/Flex';
import FormikForm from '../../../base/components/form/FormikForm';
import {
  AppointUserToSessionDocument,
  GetUsersForSelectDocument,
  User,
  UserFilterField,
  UserRole,
  UserStatus,
} from '../../../graphql/graphql-operations';
import useMutationSnackbar from '../../../hooks/useMutationSnackbar';
import useQueryErrorSnackbar from '../../../hooks/useQueryErrorSnackbar';

interface Props {
  vrSessionId: string;
  onCancel: () => void;
  onNext: () => void;
  onNewUser: () => void;
}

type SelectUser = Pick<User, 'id' | 'lastName' | 'activeSession'>;

interface UserSelectOption {
  id: string;
  name: string;
}
interface SelectUserForm {
  user: UserSelectOption | null;
}
const SelectUserStep: React.VFC<Props> = (props: Props) => {
  const { vrSessionId, onCancel, onNext, onNewUser } = props;
  const { t } = useTranslation();
  const companyId = useState(adminState.selectedCompanyId).get();
  const { data, loading, variables, error, refetch } = useQuery(
    GetUsersForSelectDocument,
    {
      variables: {
        filter: {
          companyId,
          filter: { role: [UserRole.NONE], status: UserStatus.ACTIVE },
        },
      },
      fetchPolicy: 'network-only',
    },
  );
  useQueryErrorSnackbar(error, refetch);
  const appointHandler = useMutationSnackbar('addUserToSession', {
    translationKey: 'endVrSessionDialog.selectUser.appoint',
    onSuccessfullyCompleted: onNext,
  });

  const [appointMutation] = useMutation(
    AppointUserToSessionDocument,
    appointHandler,
  );

  const handleSubmit = (values: SelectUserForm) => {
    appointMutation({
      variables: {
        addUserToSessionInput: {
          sessionId: vrSessionId,
          userId: values.user!.id,
        },
      },
    });
  };

  const handleLoad = (query: string) => {
    refetch({
      ...variables,
      filterBy: {
        field: UserFilterField.LAST_NAME,
        query,
      },
    });
  };
  const handleLoadDebounced = debounce(handleLoad, 300);

  const userGroupBy = (user: SelectUser) => {
    return !!user.activeSession?.id
      ? t(
          'endVrSessionDialog.selectUser.userWithActiveSession',
          'Users with active session',
        )
      : t(
          'endVrSessionDialog.selectUser.userWithoutActiveSession',
          'Users without active session',
        );
  };

  const getOptionDisabled = (user: SelectUser) => !!user.activeSession?.id;
  const getOptionLabel = (option: SelectUser) => option.lastName ?? 'Unknown';
  const getOptionSelected = (option: SelectUser, value: SelectUser) =>
    option.id === value.id;

  const options = [...(data?.users.nodes ?? [])].sort((a, b) => {
    if (a.activeSession?.id) {
      return 1;
    }
    return b.activeSession?.id ? -1 : 0;
  });
  return (
    <Formik<SelectUserForm>
      initialValues={{ user: null }}
      onSubmit={handleSubmit}
      validationSchema={getValidationSchema(t)}
    >
      {({ isSubmitting, isValid, touched, errors }) => (
        <FormikForm>
          <Flex justifyContent="space-between">
            <Button
              id="end-session-new-patient"
              color="primary"
              size="small"
              onClick={onNewUser}
              sx={{ whiteSpace: 'nowrap' }}
            >
              {t('endVrSessionDialog.selectUser.newPatient', 'New patient')}
            </Button>
            <Button
              id="end-session-skip"
              color="primary"
              size="small"
              onClick={onNext}
              sx={{ whiteSpace: 'nowrap' }}
            >
              {t('endVrSessionDialog.selectUser.skip', 'Skip this')}
            </Button>
          </Flex>

          <Flex flexDirection="column" alignItems="center">
            <Field
              name="user"
              component={Autocomplete}
              loading={loading}
              options={options}
              getOptionLabel={getOptionLabel}
              groupBy={userGroupBy}
              getOptionDisabled={getOptionDisabled}
              getOptionSelected={getOptionSelected}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  placeholder={t(
                    'endVrSessionDialog.selectUser.searchPlaceHolder',
                    'Search patient',
                  )}
                  variant="outlined"
                  type="text"
                  style={{ width: '300px' }}
                  error={touched['user'] && !!errors['user']}
                  onChange={(e) => handleLoadDebounced(e.target.value)}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? (
                          <CircularProgress color="primary" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
            <ErrorMessageContainer>
              <ErrorMessage name="user" />
            </ErrorMessageContainer>
          </Flex>
          <Flex justifyContent="space-between">
            <Button
              id="end-session-new-cancel"
              size="large"
              color="primary"
              onClick={onCancel}
            >
              {t('common.cancel', 'Cancel')}
            </Button>
            <Button
              id="end-session-new-confirm"
              type="submit"
              size="large"
              color="primary"
              variant="contained"
              disabled={isSubmitting}
            >
              {t('endVrSessionDialog.selectUser.finish', 'Finish')}
            </Button>
          </Flex>
        </FormikForm>
      )}
    </Formik>
  );
};

const getValidationSchema = (t: TFunction) =>
  Yup.object({
    user: Yup.object()
      .typeError(
        t('endVrSessionDialog.selectUser.required', 'Please select user'),
      )
      .required(
        t('endVrSessionDialog.selectUser.required', 'Please select user'),
      ),
  });

export default SelectUserStep;

const ErrorMessageContainer = styled('p')(({ theme }) => ({
  color: theme.palette.error.main,
  margin: theme.spacing(1),
}));
