import { useEffect, useState } from 'react';

interface Options {
  config: MutationObserverInit;
}

const DEFAULT_OPTIONS: Options = {
  config: { attributes: true, childList: true, subtree: true },
};

const useMutationObservable = (
  target: Node,
  cb: MutationCallback,
  options: Options = DEFAULT_OPTIONS,
) => {
  const [observer, setObserver] = useState<MutationObserver | null>(null);

  useEffect(() => {
    const obs = new MutationObserver(cb);
    setObserver(obs);
  }, [cb, options, setObserver]);

  useEffect(() => {
    if (!observer) return;
    const { config } = options;
    observer.observe(target, config);
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [observer, target, options]);
};

export default useMutationObservable;
