import { styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BaseTypography } from '../../../../base/components/BaseTypography';
import { DeviceState } from '../../../../graphql/graphql-operations';

interface Props {
  state: DeviceState;
}

const DeviceStateLabel = ({ state }: Props) => {
  const { t } = useTranslation();
  return (
    <DeviceStateLabelStyled state={state} variant="caption" lineHeight="1.5rem">
      {t(`enum.DeviceState.${state}`, state)}
    </DeviceStateLabelStyled>
  );
};

export default DeviceStateLabel;

const DeviceStateLabelStyled = styled(BaseTypography)<{ state: DeviceState }>(
  ({ state, theme: { spacing, palette } }) => `
    text-align: center;
    background-color: ${
      state === DeviceState.IN_USE
        ? palette.primary.main
        : state === DeviceState.ONLINE
        ? palette.success.main
        : palette.error.main
    };
     border-radius: 200px;
     padding: 0 ${spacing(1)};
     color: ${
       state === DeviceState.IN_USE
         ? palette.primary.contrastText
         : state === DeviceState.ONLINE
         ? palette.success.contrastText
         : palette.error.contrastText
     };
  `,
);
