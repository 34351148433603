import { useQuery } from '@apollo/client';
import BackHandIcon from '@mui/icons-material/BackHand';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { useCallback } from 'react';
import rangeImg from '../../../assets/range.png';
import { Flex } from '../../../base/components/Flex';
import {
  GetUserShoulderRangeReportDocument,
  GetUserShoulderRangeReportSeriesDocument,
} from '../../../graphql/graphql-operations';
import useQueryErrorSnackbar from '../../../hooks/useQueryErrorSnackbar';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import { getEndOfDay } from '../../../lib/date';
import { formatDistance } from '../../../lib/distance';
import { UpdateAxisPointerEvent } from '../../components/Charts/ChartTypes';
import ContentWrapper from '../../components/ContentWrapper';
import { Loader } from '../../components/Loader';
import PageHeader from '../../components/PageHeader';
import { UserReportPage } from './UserReportsRoute';
import ReportNav from './components/ReportNav';
import ReportValue from './components/ReportValue';
import ShoulderRangeChart from './components/ShoulderRangeChart';
import useDebouncedQuery from './utils/useDeboucedQuery';
import { useReportUser } from './utils/useReportUser';

const UserShoulderRangeReportPage = () => {
  const { _t } = useTranslationPrefix('UserShoulderRangeReportPage');
  const { userId, userData, userLoading, backUrl } = useReportUser();

  const { data: reportData, refetch: reportRefetch } = useDebouncedQuery(
    GetUserShoulderRangeReportDocument,
    {
      variables: {
        userId: userId!,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      skip: !userId,
    },
  );

  const {
    data: seriesData,
    refetch: seriesRefetch,
    error: seriesError,
    loading: seriesLoading,
  } = useQuery(GetUserShoulderRangeReportSeriesDocument, {
    variables: {
      userId: userId!,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    skip: !userId,
  });

  useQueryErrorSnackbar(seriesError, seriesRefetch);

  const updateDate = useCallback(
    (e: UpdateAxisPointerEvent) => {
      const value = e.axesInfo.find((i) => i.axisDim === 'x')?.value;
      if (value) {
        reportRefetch({
          toDate: getEndOfDay(value).toISOString(),
        });
      }
    },
    [reportRefetch],
  );

  const loading = userLoading || !reportData || seriesLoading;

  return (
    <ContentWrapper>
      <PageHeader
        title={_t('title', 'Report {{firstName}} {{lastName}}', {
          firstName: userData?.user.firstName,
          lastName: userData?.user.lastName,
        })}
        back={backUrl}
      ></PageHeader>
      <ReportNav currentPage={UserReportPage.SHOULDERS} />
      {loading ? (
        <Loader />
      ) : (
        <>
          <Flex flexDirection="column" gap={2}>
            <Card>
              <CardContent>
                <Flex alignItems="center">
                  <img height={60} width={100} alt="range" src={rangeImg} />
                  <Box flexDirection="column" alignItems="flex-start" ml={2}>
                    <Typography variant="h6" component="h2">
                      {_t('armRangeOfMotion', 'Rozsah pohybu paže')}
                    </Typography>
                    <Flex alignItems="center">
                      <InfoRoundedIcon
                        color="primary"
                        style={{ marginRight: 8 }}
                      />
                      <Typography component="span">
                        {_t(
                          'elbowTrunkRange',
                          'Rozsah měřený včetně pokrčení lokte a rotace v trupu.',
                        )}
                      </Typography>
                    </Flex>
                  </Box>
                </Flex>
              </CardContent>
            </Card>
            <Flex
              sx={{
                flexDirection: { xs: 'row', md: 'row' },
                gap: 2,
                flexGrow: 2,
              }}
            >
              <ReportValue
                label={_t(
                  'increaseRangeOfMotionBy',
                  'Zvýšení rozsahu pohybu o',
                )}
                secondLabel={_t(
                  'differentMeasurementValue',
                  'Rozdílná hodnota měření',
                )}
                value={
                  reportData?.userReports.shouldersRangeChange?.leftPercent
                    ? Math.round(
                        reportData?.userReports.shouldersRangeChange
                          ?.leftPercent,
                      ) + '%'
                    : 0
                }
                secondValue={
                  reportData?.userReports.shouldersRangeChange?.leftChange
                    ? formatDistance(
                        reportData?.userReports.shouldersRangeChange
                          ?.leftChange,
                      )
                    : '0'
                }
                icon={<BackHandIcon fontSize="large" />}
                title={_t('leftHand', 'Levá ruka')}
              />

              <ReportValue
                label={_t(
                  'increaseRangeOfMotionBy',
                  'Zvýšení rozsahu pohybu o',
                )}
                secondLabel={_t(
                  'differentMeasurementValue',
                  'Rozdílná hodnota měření',
                )}
                value={
                  reportData?.userReports.shouldersRangeChange?.rightPercent
                    ? Math.round(
                        reportData?.userReports.shouldersRangeChange
                          ?.rightPercent,
                      ) + '%'
                    : 0
                }
                secondValue={
                  reportData?.userReports.shouldersRangeChange?.rightChange
                    ? formatDistance(
                        reportData?.userReports.shouldersRangeChange
                          ?.rightChange,
                      )
                    : '0'
                }
                icon={
                  <BackHandIcon
                    fontSize="large"
                    sx={{ transform: 'scaleX(-1)' }}
                  />
                }
                title={_t('rightHand', 'Pravá ruka')}
              />
            </Flex>
            <Card>
              <CardContent>
                <ShoulderRangeChart
                  data={seriesData!.userReports.shouldersRangeSeries}
                  onAxisPointerChange={updateDate}
                />
              </CardContent>
            </Card>
          </Flex>
        </>
      )}
    </ContentWrapper>
  );
};

export default UserShoulderRangeReportPage;
