import { useQuery } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { useEffect, useMemo, useState } from 'react';
import {
  ChangePlaylistTemplateCompanyAssignmentDocument,
  GetPlaylistTemplateCompaniesDocument,
  GetPlaylistTemplatesDocument,
} from '../../../../graphql/graphql-operations';
import { useMutationWithSnackbar } from '../../../../hooks/useMutationWithSnackbar';
import {
  RankedCompany,
  useOrderedActiveCompanies,
} from '../../../../hooks/useOrderedActiveCompanies';
import useQueryErrorSnackbar from '../../../../hooks/useQueryErrorSnackbar';

export interface AssignableCompany extends RankedCompany {
  selected: boolean;
}

interface Params {
  templateId?: string;
  onCompleted: () => void;
}

const listsAreTheSame = (list1: string[], list2: string[]) => {
  return (
    list1.every((v) => list2.includes(v)) &&
    list2.every((v) => list1.includes(v))
  );
};

const usePlaylistAssignCompanies = ({ templateId, onCompleted }: Params) => {
  const companiesRaw = useOrderedActiveCompanies();

  const [selectedCompanies, setSelectedCompanies] = useState<string[]>([]);
  const { data, error, refetch } = useQuery(
    GetPlaylistTemplateCompaniesDocument,
    {
      variables: { id: templateId },
      skip: !templateId,
    },
  );
  useQueryErrorSnackbar(error, refetch);

  const [changeAssignment, { loading: submitting }] = useMutationWithSnackbar(
    'ChangePlaylistTemplateCompanyAssignmnetMutation',
    ChangePlaylistTemplateCompanyAssignmentDocument,
    {
      onCompleted: onCompleted,
      refetchQueries: [getOperationName(GetPlaylistTemplatesDocument)!],
    },
  );

  const originalSelectedCompanies = useMemo(
    () =>
      data?.playlistTemplate?.assignedToCompanies
        ? data?.playlistTemplate?.assignedToCompanies.map((c) => c.id)
        : [],
    [data?.playlistTemplate?.assignedToCompanies],
  );

  useEffect(
    () => setSelectedCompanies(originalSelectedCompanies),
    [setSelectedCompanies, originalSelectedCompanies],
  );

  const companies = useMemo(() => {
    return companiesRaw.map((c) => ({
      ...c,
      selected: selectedCompanies.includes(c.id),
    }));
  }, [companiesRaw, selectedCompanies]);

  const handleAssignCompanies = async () => {
    const assignedCompanies = selectedCompanies.filter(
      (c) => !originalSelectedCompanies.includes(c),
    );
    const unAssignedCompanies = originalSelectedCompanies.filter(
      (c) => !selectedCompanies.includes(c),
    );

    await changeAssignment({
      variables: {
        input: {
          playlistTemplateId: templateId!,
          assignedCompanies: assignedCompanies,
          unAssignedCompanies: unAssignedCompanies,
        },
      },
    });
  };

  const disabled = useMemo(
    () =>
      submitting ||
      listsAreTheSame(originalSelectedCompanies, selectedCompanies),
    [originalSelectedCompanies, selectedCompanies, submitting],
  );

  return {
    companies,
    setSelectedCompanies,
    assignCompaniesDisabled: disabled,
    assignCompanies: handleAssignCompanies,
  };
};

export default usePlaylistAssignCompanies;
