import { useMutation } from '@apollo/client';
import { useState } from '@hookstate/core';
import { Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppointUserToSessionDocument,
  UserStatus,
} from '../../../graphql/graphql-operations';
import useMutationSnackbar from '../../../hooks/useMutationSnackbar';
import NewPatientDialog from '../../components/NewPatientDialog/NewPatientDialog';
import PatientsTable from '../../components/PatientsTable/PatientsTable';
import { SectionPaper } from '../../components/SectionPaper';
import { TopBar } from '../../components/TopBar';
import PatientsActions from '../Patients/PatientsActions';

interface Props {
  vrSessionId: string;
}

const NoUserDetail: React.VFC<Props> = ({ vrSessionId }: Props) => {
  const { t } = useTranslation();
  const state = useState({ newPatientDialogOpen: false });

  const handleCloseNewPatientDialog = () => {
    state.newPatientDialogOpen.set(false);
  };

  const appointHandler = useMutationSnackbar('addUserToSession', {
    translationKey: 'vrSessionDetail.noUser.actions.appoint',
  });

  const [appointMutation] = useMutation(
    AppointUserToSessionDocument,
    appointHandler,
  );

  const handleAppointPatient = async (userId: string) => {
    await appointMutation({
      variables: {
        addUserToSessionInput: {
          sessionId: vrSessionId,
          userId: userId,
        },
      },
    });
  };

  return (
    <>
      <TopBar
        leftSideText={t('vrSessionDetail.noUser.title', 'Appoint patient')}
        actions={
          <PatientsActions
            onNewClientClick={() => state.newPatientDialogOpen.set(true)}
          />
        }
      />

      <SectionPaper style={{ marginTop: '16px' }}>
        <PatientsTable
          patientStatus={UserStatus.ACTIVE}
          rowActions={(rowData) =>
            !rowData.vrSessionId && (
              <Button
                id={`session-no-user-appoint-${rowData.id}`}
                variant="contained"
                color="secondary"
                size="medium"
                onClick={() => handleAppointPatient(rowData.id)}
              >
                {t('vrSessionDetail.noUser.appoint', 'Appoint')}
              </Button>
            )
          }
        />
      </SectionPaper>
      <NewPatientDialog
        open={state.newPatientDialogOpen.get()}
        onClose={handleCloseNewPatientDialog}
        onUserCreated={handleAppointPatient}
      />
    </>
  );
};

export default NoUserDetail;
