import { useState } from '@hookstate/core';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import FeedbackDialog from '../../admin/components/FeedbackDialog/FeedbackDialog';
import { Flex } from '../../base/components/Flex';
import AppBar, { AppBarHeight } from './AppBar';
import Drawer from './Drawer';
import { DrawerContent } from './types';

interface Props {
  drawerContent: DrawerContent;
  children: React.ReactNode;
}

const AdminLayout = ({ children, drawerContent }: Props) => {
  const showFeedback = useState(false);
  return (
    <Flex>
      <AppBar />
      <Drawer drawerContent={drawerContent} showFeedback={showFeedback} />
      <Main>
        <AppBarHeight />
        <Content>{children}</Content>
      </Main>
      <FeedbackDialog showFeedback={showFeedback} />
    </Flex>
  );
};

export default AdminLayout;

const Main = styled('main')(({ theme: { palette } }) => ({
  flexGrow: 1,
  height: '100vh',
  overflow: 'auto',
  backgroundColor: palette.background.default,
}));

const Content = styled(Box)(({ theme: { spacing } }) => ({
  padding: spacing(2),
}));
