import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Card,
  CardActionArea,
  CardContent,
  Collapse,
  Grid,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import { Flex } from '../../../../base/components/Flex';
import { SessionPlaylistItemReportFragmentFragment } from '../../../../graphql/graphql-operations';
import { useTranslationPrefix } from '../../../../hooks/useTranslationPrefix';
import { formatDuration } from '../../../../lib/duration';
import { joinStrings } from '../../../../lib/strings';
import { LabeledValue } from '../../../components/LabeledValue';
import ReportUserDayList from './ReportUserDayList';

interface Props {
  data: SessionPlaylistItemReportFragmentFragment[];
}

const ReportUserCard = ({ data }: Props) => {
  const { _t } = useTranslationPrefix('ReportUserCard');
  const [expanded, setExpanded] = useState(false);
  const firstName = data?.[0].launchedByUser?.firstName;
  const lastName =
    data?.[0].launchedByUser?.lastName || _t('unknowUser', 'Unknown');
  const name = joinStrings(' ', firstName, lastName);
  const duration = useMemo(
    () => data.reduce((curr, row) => curr + row.durationMs, 0),
    [data],
  );

  return (
    <Card>
      <CardActionArea onClick={() => setExpanded((e) => !e)}>
        <CardContent>
          <Flex alignItems="center">
            <Grid container spacing={2} p={1}>
              <Grid item xs={12} sm={6} md={4}>
                <LabeledValue
                  size="medium"
                  value={name}
                  label={_t('name', 'Full name')}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <LabeledValue
                  size="medium"
                  value={data.length}
                  label={_t('appRunCount', 'App runs')}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <LabeledValue
                  size="medium"
                  value={formatDuration(duration)}
                  label={_t('timeInVR', 'Time in VR')}
                />
              </Grid>
            </Grid>
            {expanded ? (
              <ExpandLessIcon
                fontSize="medium"
                sx={{ color: 'primary.main', m: 2 }}
              />
            ) : (
              <ExpandMoreIcon
                fontSize="medium"
                sx={{ color: 'primary.main', m: 2 }}
              />
            )}
          </Flex>
        </CardContent>
      </CardActionArea>
      <Collapse in={expanded}>
        <CardContent>
          <Flex pl={2} justifyContent="end">
            <ReportUserDayList data={data} />
          </Flex>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default React.memo(ReportUserCard);
