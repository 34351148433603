import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Button,
  CardContent,
  Collapse,
  ExtendButtonBase,
  IconButton,
  MenuItem,
  MenuItemTypeMap,
  MenuList,
  styled,
  Typography,
} from '@mui/material';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { BaseTypography } from '../../../../base/components/BaseTypography';
import { Flex } from '../../../../base/components/Flex';
import { VrDevicesContext } from '../../../../base/components/VrDevicesProvider/VrDevicesProvider';
import { useTranslationPrefix } from '../../../../hooks/useTranslationPrefix';
import DeviceBatteryStatus from '../../Devices/components/DeviceBatteryStatus';
import SessionFloatingCard from './SessionFloatingCard';

interface Props {
  deviceId?: string | null;
  multipleVariant?: boolean;
  onFinishClick: (all: boolean) => void;
}

export const SessionBattery = ({
  deviceId,
  multipleVariant,
  onFinishClick,
}: Props) => {
  const { _t } = useTranslationPrefix('SessionDetailPage.sessionBattery');
  const [expanded, setExpanded] = useState(false);
  const { boxDevices } = useContext(VrDevicesContext);

  const device = boxDevices.find((dev) => dev.id === deviceId);
  if (!device?.status.battery && !multipleVariant) {
    return null;
  }
  const devices = boxDevices.filter((d) => !!d.session?.id);

  return (
    <SessionFloatingCard sx={{ maxWidth: { sm: '50%', md: '30%' } }}>
      <CardContent>
        <Flex alignItems="center" width="100%" flexShrink={0}>
          {device && (
            <StatusText
              sx={{
                pr: 1,
                py: 1.5,
                mr: 'auto',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {device.name}
            </StatusText>
          )}
          <DeviceBatteryStatus
            battery={device?.status?.battery}
            textColor="text.primary"
          />
          {multipleVariant && (
            <IconButton
              id="devices-expand"
              onClick={() => setExpanded((e) => !e)}
              size="small"
              sx={{ ml: '1ch' }}
            >
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          )}
        </Flex>
        <Collapse in={expanded && multipleVariant} sx={{ width: '100%' }}>
          <DevicesMenuList>
            {devices.map((d) => (
              <DevicesMenuItem
                key={d.id}
                id={`switch-to-device-${d.id}`}
                selected={d.id === deviceId}
                // @ts-ignore
                component={Link}
                to={`../${d.session?.id}?multiple`}
              >
                <BaseTypography
                  variant="h5"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  {d.name}
                </BaseTypography>
                <DeviceBatteryStatus
                  battery={d?.status?.battery}
                  textColor="text.primary"
                />
              </DevicesMenuItem>
            ))}
          </DevicesMenuList>
          <Flex>
            <Button
              id="finish-current"
              onClick={() => onFinishClick(false)}
              color="error"
              variant="outlined"
              size="small"
              startIcon={<CloseRoundedIcon />}
              sx={{ mx: 'auto' }}
            >
              {_t('finishCurrent', 'Finish current')}
            </Button>
          </Flex>
        </Collapse>
      </CardContent>
    </SessionFloatingCard>
  );
};

const StatusText = styled(Typography)`
  font-size: max(1rem, min(1.3rem, 3vw));
  font-weight: bold;
`;

const DevicesMenuList = styled(MenuList)(
  ({ theme: { spacing, palette } }) => `
  border-top: 1px solid ${palette.divider};
  margin: ${spacing(1)} 0;
  max-height: calc( 100vh - 26rem );
  overflow-y: auto;
  overflow-x: hidden;

  scrollbar-width: thin;
  ::-webkit-scrollbar {
    width: 9px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(155, 155, 155, 0.5);
    border-radius: 20px;
    border: transparent;
  }
`,
);

const DevicesMenuItem = styled<
  ExtendButtonBase<MenuItemTypeMap<{}, typeof Link>>
>(MenuItem as any)(
  ({ theme: { spacing } }) => `
  display: flex;
  justify-content: space-between;
  padding: ${spacing(1.5)} ${spacing(1)};
  border-radius: ${spacing(1)};
`,
);
