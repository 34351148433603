import { PropsWithChildren } from 'react';
import { useUser } from './useUserHook';

interface Props {
  companyId: string | number;
}

const HasCompanyManagementPermission: React.FC<Props> = ({
  companyId,
  children,
}: PropsWithChildren<Props>) => {
  const { hasCompanyManagementPermission } = useUser();
  return hasCompanyManagementPermission(companyId) ? <>{children}</> : null;
};

export default HasCompanyManagementPermission;
