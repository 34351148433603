import {
  Box,
  MenuItem,
  Paper as MuiPaper,
  Select,
  styled,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from '../../../base/components/Flex';
import { formatDuration } from '../../../lib/duration';
import DetailValue from '../../components/DetailCard/DetaiValue';
import { usePatientData } from './patientData';
import { UserData } from './UserDetail';

export type SessionStatistics = NonNullable<
  UserData['sessionCumulativeStatistic']
>;

interface Props {
  statisctics: SessionStatistics;
}

const PERIOD_CHOICES = [7, 14, 30];

const UserSessionSummary: React.VFC<Props> = ({ statisctics }: Props) => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const [programPeriod, setProgramPeriod] = useState(30);
  const { mobilityData } = usePatientData(programPeriod, palette.primary.main);
  return (
    <Flex flexDirection="row">
      <Flex flex={1} mr={1}>
        <Paper sx={{ flex: 1 }}>
          <Flex flexDirection="row" justifyContent="space-between">
            <ProgramInfo>
              <DetailValue
                value="Test Program"
                label={t(
                  'vrSessionDetail.summary.popularProgramLabel',
                  'Popular program',
                )}
              />
              <DetailValue
                value={statisctics.mostUsedScene?.name_t}
                label={t(
                  'vrSessionDetail.summary.popularAppLabel',
                  'Popular app',
                )}
              />
              <Select
                variant="standard"
                value={programPeriod}
                onChange={(value) => {
                  setProgramPeriod(Number(value.target.value));
                }}
                disableUnderline={true}
                color="primary"
              >
                {PERIOD_CHOICES.map((period) => (
                  <MenuItem key={period} value={period}>
                    {t(
                      'vrSessionDetail.summary.selectLabel',
                      '{{days}} days change',
                      { days: period },
                    )}
                  </MenuItem>
                ))}
              </Select>
            </ProgramInfo>
            <Flex></Flex>
          </Flex>
        </Paper>
      </Flex>
      <Flex flex={1} ml={1} flexDirection="column">
        <Flex flexDirection="row" flex={1}>
          <StatPaper sx={{ mr: 1, mb: 1 }}>
            <DetailValue
              value={`+ ${mobilityData} %`}
              label={t(
                'vrSessionDetail.summary.mobilityProgressLabel',
                'Mobility progress',
              )}
            />
          </StatPaper>
          <StatPaper sx={{ ml: 1, mb: 1 }}>
            <DetailValue
              value={
                statisctics.allTimeSpent &&
                formatDuration(statisctics.allTimeSpent)
              }
              label={t(
                'vrSessionDetail.summary.durationsSumLabel',
                'All time spent',
              )}
            />
          </StatPaper>
        </Flex>
        <Flex flexDirection="row" flex={1}>
          <StatPaper sx={{ mr: 1, mt: 1 }}>
            <Box>
              <DetailValue
                value={`${statisctics.numberOfSessions}`}
                label={t(
                  'vrSessionDetail.summary.sessionCountLabel',
                  'Sessions',
                )}
              />
            </Box>
          </StatPaper>
        </Flex>
      </Flex>
    </Flex>
  );
};

const ProgramInfo = styled(Flex)(({ theme }) => {
  return {
    flexDirection: 'column',
    marginTop: theme.spacing(6.5),
    marginBottom: theme.spacing(6.5),
  };
});

const Paper = styled(MuiPaper)(({ theme }) => ({
  padding: theme.spacing(3),
}));

const StatPaper = styled(Paper)`
  display: flex;
  flex: 1;
  align-items: center;
`;

export default UserSessionSummary;
