import { Button, styled, useTheme } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ReactComponent as VrCardboard } from '../../../assets/svg/vr-cardboard-solid.svg';
import { BaseTypography } from '../../../base/components/BaseTypography';

const NotFoundDetail = () => {
  const { palette } = useTheme();
  const { t } = useTranslation();
  return (
    <Container>
      <Grid>
        <IconWrapper style={{ fill: palette.secondary.main }}>
          <VrCardboard />
        </IconWrapper>
        <BaseTypography variant="h4">
          {t('notFoundDetail.title', 'Oops!')}
        </BaseTypography>
        <BaseTypography variant="h6">
          {t('notFoundDetail.subTitle', "We couldn't find this page")}
        </BaseTypography>
        <ParagraphContainer>
          <BaseTypography variant="body1">
            <Trans
              i18nKey={'notFoundDetail.description'}
              defaults={
                'Check the URL to make sure there are no typing errors. <br /> If you thing this page should work, contact us at novikov@cie-group.cz'
              }
            />
          </BaseTypography>
        </ParagraphContainer>
        <Button
          id="not-found-home"
          size="large"
          color="secondary"
          variant="contained"
          component={Link}
          // @ts-ignore
          to={'/'}
        >
          {t(`notFoundDetail.goToHome`, 'Go to Home page')}
        </Button>
      </Grid>
    </Container>
  );
};

export default NotFoundDetail;

const Container = styled('div')(({ theme }) => ({
  width: '100%',
  marginTop: '30vh',
}));

const IconWrapper = styled('div')(({ theme }) => ({
  width: theme.spacing(11),
  margin: theme.spacing(1),
}));

const ParagraphContainer = styled('div')(({ theme }) => ({
  margin: theme.spacing(3, 0, 3, 0),
  maxWidth: theme.spacing(60),
  textAlign: 'center',
}));

const Grid = styled('div')({
  display: 'grid',
  alignItems: 'center',
  justifyItems: 'center',
});
