import { useQuery } from '@apollo/client';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { lighten, styled } from '@mui/material';
import {
  GetRolePermissionsDocument,
  UserPermission,
  UserRole,
} from '../../../graphql/graphql-operations';
import useQueryErrorSnackbar from '../../../hooks/useQueryErrorSnackbar';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import { TopBar } from '../../components/TopBar';

const RolePermissionsPage = () => {
  const { _t, t } = useTranslationPrefix('RolePermissionsPage');
  const { data, error, refetch } = useQuery(GetRolePermissionsDocument, {
    variables: {
      roles: Object.values(UserRole),
    },
  });
  useQueryErrorSnackbar(error, refetch);
  const roleMap =
    data?.getRolePermissions.reduce<Record<string, readonly UserPermission[]>>(
      (result, current) => ({ ...result, [current.role]: current.permissions }),
      {},
    ) || {};

  const roles = Object.values(UserRole);
  const permissions = Object.values(UserPermission);

  return (
    <>
      <TopBar leftSideText={_t('title', 'Roles X Persmissions')} />
      <RolePermissionsPageTable>
        <thead>
          <tr>
            <th></th>
            {roles.map((role) => (
              <th key={role}>{t(`enum.UserRole.${role}`, role)}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {permissions.map((permission) => (
            <tr key={permission}>
              <td>{t(`enum.UserPermission.${permission}`, permission)}</td>
              {roles.map((role) => {
                const hasPermission = !!roleMap[role]?.includes(permission);
                return (
                  <td
                    key={role}
                    className={
                      hasPermission
                        ? classes.hasPermission
                        : classes.hasNotPermission
                    }
                  >
                    {hasPermission ? (
                      <CheckIcon color="success" />
                    ) : (
                      <CloseIcon color="error" />
                    )}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </RolePermissionsPageTable>
    </>
  );
};

export default RolePermissionsPage;

const classes = {
  hasPermission: 'RolePermissionsPage-hasPermission',
  hasNotPermission: 'RolePermissionsPage-hasNotPermission',
};
const RolePermissionsPageTable = styled('table')(
  ({ theme: { palette, spacing } }) => `
    & .${classes.hasNotPermission},
    & .${classes.hasPermission},
    th {
        min-width: 75px;
        padding: 0 ${spacing(1)}; 
        text-align: center;
        vertical-align: middle;
        svg {
            vertical-align: middle;
        }
    }

    & .${classes.hasNotPermission} {
        background-color: ${lighten(palette.error.main, 0.85)};
    }
    
    & .${classes.hasPermission} {
        background-color: ${lighten(palette.success.main, 0.85)};
    }
`,
);
