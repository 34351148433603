import { flatten } from 'ramda';
import { useMemo } from 'react';
import { useUser } from '../base/components/UserProvider/useUserHook';
import { CompanyFragmentFragment } from '../graphql/graphql-operations';
import { compareByProp } from '../lib/compare';
import useActiveCompanies from './useActiveCompanies';

export type RankedCompany = CompanyFragmentFragment & {
  rank: number;
};

export const useOrderedActiveCompanies = () => {
  const companies = useActiveCompanies();
  const { user } = useUser();

  const orderedCompanies: RankedCompany[] = useMemo(() => {
    let subsidiariesByParent = companies.reduce((res, company) => {
      const parentCompanyId = company.parentCompanyId ?? null;
      if (parentCompanyId == null) {
        return res;
      }
      if (!res[parentCompanyId]) {
        res[parentCompanyId] = [];
      }
      res[parentCompanyId].push(company);
      return res;
    }, {} as Record<string, CompanyFragmentFragment[]>);

    const visited: Record<number, boolean> = {}; // cycle protection

    const getSubsidiaries = (
      companyId: number,
      rank: number,
    ): RankedCompany[] => {
      const subsidiaries = subsidiariesByParent[companyId];
      if (visited[companyId]) {
        console.warn('Possible cycle in recusive function.');
      }
      if (!subsidiaries || visited[companyId]) {
        return [];
      }
      visited[companyId] = true;
      return flatten(
        subsidiaries
          .sort(compareByProp('name'))
          .map((subs) => [
            { ...subs, rank },
            ...getSubsidiaries(Number(subs.id), rank + 1),
          ]),
      );
    };

    const topLevelCompanies: RankedCompany[] = companies
      .filter((company) => {
        if (!user) {
          return false;
        }
        if (user.companyId) {
          return Number(company.id) === user.companyId;
        }
        return company.parentCompanyId == null;
      })
      .map((company) => ({ ...company, rank: 0 }))
      .sort(compareByProp('name'));

    return flatten(
      topLevelCompanies.map((parentCompany) => {
        const subsidiaries = getSubsidiaries(Number(parentCompany.id), 1);

        return [parentCompany, ...subsidiaries];
      }),
    );
  }, [companies, user]);
  return orderedCompanies;
};
