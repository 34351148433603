import { useState } from '@hookstate/core';
import { Button, styled, SvgIcon as MuiSvgIcon } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as VrCardboard } from '../../../assets/svg/vr-cardboard-solid.svg';
import EndVrSessionDialog from '../../components/EndVrSessionDialog/EndVrSessionDialog';
import LaunchSceneDialog from '../../components/LaunchSceneDialog/LaunchSceneDialog';
import { TableData } from './PatientsPage';

interface Props {
  rowData: TableData;
}
const PatientRowActions: React.VFC<Props> = ({ rowData }: Props) => {
  const { id, vrSessionId } = rowData;
  const { t } = useTranslation();
  const state = useState({
    patientSessionDialogOpen: false,
    endVrSessionDialogOpen: false,
  });

  const handleVrSessionDialogClose = () => {
    state.endVrSessionDialogOpen.set(false);
  };
  return (
    <>
      {rowData.vrSessionId ? (
        <Button
          id={`patient-row-action-stop-session-${rowData.id}`}
          variant="contained"
          color="error"
          startIcon={
            <SvgIcon>
              <VrCardboard />
            </SvgIcon>
          }
          onClick={() => state.endVrSessionDialogOpen.set(true)}
        >
          {t('patients.rowActions.stopSession', 'Stop')}
        </Button>
      ) : (
        <Button
          id={`patient-row-action-start-session-${rowData.id}`}
          color="secondary"
          variant="contained"
          size="medium"
          startIcon={
            <SvgIcon>
              <VrCardboard />
            </SvgIcon>
          }
          onClick={() => state.patientSessionDialogOpen.set(true)}
        >
          {t('patients.rowActions.startSession', 'Start')}
        </Button>
      )}
      <LaunchSceneDialog
        open={state.patientSessionDialogOpen.get()}
        onClose={() => state.patientSessionDialogOpen.set(false)}
        userId={id}
      />
      <EndVrSessionDialog
        open={state.endVrSessionDialogOpen.get()}
        onClose={handleVrSessionDialogClose}
        onFinished={handleVrSessionDialogClose}
        vrSessionId={vrSessionId!}
      />
    </>
  );
};

const SvgIcon = styled(MuiSvgIcon)(({ theme }) => ({
  fontSize: `${theme.spacing(2.5)} !important`,
}));

export default PatientRowActions;
