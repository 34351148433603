import { useContext } from 'react';
import { Flex } from '../../../base/components/Flex';
import {
  VrDevicesContext,
  VrDeviceType,
} from '../../../base/components/VrDevicesProvider/VrDevicesProvider';
import VrDeviceCard from './VrDeviceCard';

interface Props {
  hideDeviceLink?: boolean; // TODO: remove after device select page redesign
  onDeviceSelected: (deviceInfo: VrDeviceType) => void;
}
const VrDeviceList: React.VFC<Props> = ({
  onDeviceSelected,
  hideDeviceLink,
}: Props) => {
  const { devices } = useContext(VrDevicesContext);
  return (
    <Flex flexWrap="wrap">
      {devices?.map((device) => (
        <VrDeviceCard
          key={device.id}
          device={device}
          hideDeviceLink={hideDeviceLink}
          onDeviceSelected={onDeviceSelected}
        />
      ))}
    </Flex>
  );
};

export default VrDeviceList;
