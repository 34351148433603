import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, TextField } from '@mui/material';
import { handleTextFieldChange } from '../../../lib/EventHandlers';

interface Props {
  id: string;
  placeholder: string;
  size?: 'large';
  value: string;
  onChange: (value: string) => void;
}
const SearchField = ({ id, placeholder, size, value, onChange }: Props) => (
  <TextField
    id={id}
    color="primary"
    value={value}
    onChange={handleTextFieldChange(onChange)}
    style={{ minWidth: size ? '16rem' : undefined }}
    placeholder={placeholder}
    variant="standard"
    InputProps={{
      style: { fontSize: '0.75rem' },
      startAdornment: <SearchIcon color="primary" />,
      endAdornment: (
        <IconButton
          id={`${id}-clear`}
          disabled={!value.length}
          size="small"
          color="primary"
          onClick={() => onChange('')}
        >
          <ClearIcon />
        </IconButton>
      ),
    }}
  />
);

export default SearchField;
