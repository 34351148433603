import { Card } from '@mui/material';
import { Box, styled } from '@mui/system';
import { useField } from 'formik';
import FormikErrorMessage from './FormikErrorMessage';

type valueType = string | number;

interface Option<T extends valueType> {
  label: string;
  value: T;
}

interface Props<T extends valueType> {
  name: string;
  required: boolean;
  label?: string;
  options: Option<T>[];
}

const FormikOptionPicker = <T extends valueType = string>({
  name,
  label,
  options,
  required,
}: Props<T>) => {
  const [{ value }, { touched, error }, { setValue, setTouched }] =
    useField<T>(name);
  const handleOnChange = (newValue: T) => () => {
    if (!touched) {
      setTouched(true);
    }
    setValue(newValue);
  };

  const renderOption = (option: Option<T>) => {
    return (
      <OptionsOptionCard
        key={option.value}
        selected={value === option.value}
        onClick={handleOnChange(option.value)}
      >
        {option.label}
      </OptionsOptionCard>
    );
  };

  return (
    <Box>
      {label && (
        <FormikOptionPickerLabel>
          {label}
          {required ? ' *' : ''}
        </FormikOptionPickerLabel>
      )}
      <OptionsWrapper>{options.map(renderOption)}</OptionsWrapper>
      {error && touched && <FormikErrorMessage>{error}</FormikErrorMessage>}
    </Box>
  );
};

const OptionsWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
`;

const FormikOptionPickerLabel = styled('label')`
  font-weight: bold;
`;

const OptionsOptionCard = styled(Card)<{ selected: boolean }>(
  ({ selected, theme: { spacing, shadows, palette } }) => `
    display: flex;
    margin: ${spacing(1)};
    padding: ${spacing(2)};
    cursor: pointer;
    font-weight: bold;
    
    :hover {
        box-shadow: ${(shadows as string[])[5]}
    }

    ${
      selected &&
      `
      color: ${palette.primary.main};
      background-color: ${palette.background.default};
    
    `
    }
`,
);

export default FormikOptionPicker;
