import { useState } from '@hookstate/core';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import HasPermission from '../../../base/components/UserProvider/HasPermission';
import { UserPermission } from '../../../graphql/graphql-operations';
import { handleOnClickToggleHook } from '../../../lib/EventHandlers';
import RegisterVrDeviceDialog from './RegisterVrDeviceDialog';

interface Props {
  companyId?: string;
}

const VrDevicesActions = ({ companyId }: Props) => {
  const { t } = useTranslation();
  const registrationOpened = useState(false);

  const handleDialogClose = () => registrationOpened.set((prev) => !prev);

  return (
    <HasPermission val={UserPermission.MANAGE_COMPANY_VR_DEVICES}>
      <Button
        id="vr-devices-actions-create"
        size="large"
        color="primary"
        variant="contained"
        startIcon={<AddIcon viewBox="5 5 14 14" />}
        onClick={handleOnClickToggleHook(registrationOpened)}
      >
        {t('vrDevices.actions.newDevice', 'New Device')}
      </Button>
      <RegisterVrDeviceDialog
        open={registrationOpened.get()}
        onClose={handleDialogClose}
        companyId={companyId}
      />
    </HasPermission>
  );
};

export default VrDevicesActions;
