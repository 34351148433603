import { useQuery } from '@apollo/client';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import {
  GetDebugReportsDocument,
  GetDebugReportsQuery,
} from '../../../graphql/graphql-operations';
import useQueryErrorSnackbar from '../../../hooks/useQueryErrorSnackbar';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import { formatDateTimeSeconds } from '../../../lib/date';
import { TopBar } from '../../components/TopBar';

type Report = GetDebugReportsQuery['debugReports'][0];
const DebugReportsPage = () => {
  const { _t } = useTranslationPrefix('DebugReportsPage');

  const { data, error, refetch } = useQuery(GetDebugReportsDocument);
  useQueryErrorSnackbar(error, refetch);
  const reports = data?.debugReports || [];

  const renderReport = (report: Report) => {
    return (
      <Accordion key={report.id}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} id={report.id}>
          <Typography>
            {formatDateTimeSeconds(report.updatedAt)} - {report.scene.name_t} --
            version: {report.version} -- ordinal: {report.ordinal}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <pre>{JSON.stringify(JSON.parse(report.reportData), null, '\t')}</pre>
          <Typography></Typography>
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <>
      <TopBar leftSideText={_t('title', 'Reports')} />

      <Box>{reports.map(renderReport)}</Box>
    </>
  );
};

export default DebugReportsPage;
