import { useMutation } from '@apollo/client';
import { AppointUserToSessionDocument } from '../../../graphql/graphql-operations';
import useMutationSnackbar from '../../../hooks/useMutationSnackbar';
import NewPatientForm from '../NewPatientDialog/NewPatientForm';

interface Props {
  vrSessionId: string;
  onCancel: () => void;
  onNext: () => void;
}
const NewUserStep: React.VFC<Props> = (props: Props) => {
  const { vrSessionId, onCancel, onNext } = props;
  const appointHandler = useMutationSnackbar('addUserToSession', {
    translationKey: 'endVrSessionDialog.selectUser.appoint',
    onSuccessfullyCompleted: onNext,
  });

  const [appointMutation] = useMutation(
    AppointUserToSessionDocument,
    appointHandler,
  );
  const handleUserCreated = async (userId: string) => {
    await appointMutation({
      variables: {
        addUserToSessionInput: { sessionId: vrSessionId, userId: userId },
      },
    });
  };

  return (
    <NewPatientForm onUserCreated={handleUserCreated} onCancel={onCancel} />
  );
};

export default NewUserStep;
