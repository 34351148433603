import {
  IconButton,
  IconButtonProps,
  Palette,
  PaletteColor,
  styled,
} from '@mui/material';

interface Props extends IconButtonProps {}

const IconButtonFilled = ({ color, children, ...buttonProps }: Props) => {
  return (
    <StyledIconButton customColor={color} {...buttonProps}>
      {children}
    </StyledIconButton>
  );
};

export default IconButtonFilled as typeof IconButton;

const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'customColor',
})<{ customColor?: string }>(({ customColor, theme: { palette } }) => {
  const color = palette[customColor as keyof Palette] as PaletteColor;

  const base = `
  &.Mui-disabled {
    background-color: ${palette.grey[100]};
    color: ${palette.grey[300]};
  }`;
  if (!color?.main) {
    return base;
  }
  return `
        background-color: ${color.main};
        color: ${color.contrastText};

        &:hover {
            background-color: ${color.dark};
        }

        ${base}
    `;
});
