import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-locize-backend';
import locizeLastUsed from 'locize-lastused';
import { initReactI18next } from 'react-i18next';
import { setLangCookie } from '../hooks/useChangeUserLanguage';
import { LANGUAGES } from '../lib/constants';
import { isMedical, isSchool } from '../lib/useAppVariant';

export async function initializeI18n() {
  const namespace = `admin_${
    isSchool() ? 'vrSchool' : isMedical() ? 'vrMedical' : 'vrTraining'
  }`;
  const i18next = await i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(Backend)
    .use(locizeLastUsed)
    .init({
      fallbackLng: process.env.REACT_APP_LOCIZE_LANGUAGE,
      supportedLngs: LANGUAGES,
      lowerCaseLng: true,
      debug: process.env.NODE_ENV === 'development',
      load: 'languageOnly',
      ns: [namespace],
      defaultNS: namespace,
      saveMissing: !!process.env.REACT_APP_LOCIZE_API_KEY,
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
      backend: {
        allowedAddOrUpdateHosts: [
          '127.0.0.1',
          'localhost',
          'vrtraining.localhost',
          'vrmedical.localhost',
          'vrschool.localhost',
          'vrtraining.staging.cievr.cz',
          'vrmedical.staging.cievr.cz',
          'vrschool.staging.cievr.cz',
        ],
        projectId: process.env.REACT_APP_LOCIZE_PROJECTID || '',
        apiKey: process.env.REACT_APP_LOCIZE_API_KEY || undefined,
        referenceLng: process.env.REACT_APP_LOCIZE_LANGUAGE,
        version: process.env.REACT_APP_LOCIZE_VERSION || undefined,
      },
      locizeLastUsed: {
        projectId: process.env.REACT_APP_LOCIZE_PROJECTID || '',
        apiKey: process.env.REACT_APP_LOCIZE_API_KEY || undefined,
        referenceLng: process.env.REACT_APP_LOCIZE_LANGUAGE,
        version: process.env.REACT_APP_LOCIZE_VERSION || undefined,
        allowedHosts: [
          '127.0.0.1',
          'localhost',
          'vrtraining.localhost',
          'vrmedical.localhost',
          'vrschool.localhost',
          'vrtraining.staging.cievr.cz',
          'vrmedical.staging.cievr.cz',
          'vrschool.staging.cievr.cz',
        ],
      },
      detection: {
        order: ['localStorage', 'navigator'],
      },
    });
  setLangCookie(i18n.language);
  if (i18n.language && !i18n.languages.includes(i18n.language)) {
    const lang = i18n.language.split('-')[0];
    if (i18n.languages.includes(lang)) {
      setLangCookie(lang);
      i18n.changeLanguage(lang);
    }
  }
  return i18next;
}

export default i18n;
