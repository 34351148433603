import { init } from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import * as ExtraIntegrations from '@sentry/integrations';

const dsn = process.env.REACT_APP_SENTRY_DSN;
const environment = process.env.REACT_APP_ENVIRONMENT;
const release = process.env.REACT_APP_RELEASE;

init({
  enabled: Boolean(dsn),
  dsn,
  environment,
  normalizeDepth: 5,
  release,
  integrations: [
    new ExtraIntegrations.Dedupe(),
    new ExtraIntegrations.ExtraErrorData(),
    new Integrations.BrowserTracing(),
  ],
});
