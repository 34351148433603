import { useQuery } from '@apollo/client';
import { CircularProgress } from '@mui/material';
import { useParams } from 'react-router';
import { Flex } from '../../../base/components/Flex';
import {
  GetCompanyDocument,
  GetVrDevicesDocument,
} from '../../../graphql/graphql-operations';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import { SectionPaper } from '../../components/SectionPaper';
import { TopBar } from '../../components/TopBar';
import VrDeviceCard from '../../components/VrDeviceList/VrDeviceCard';
import VrDevicesActions from '../VrDevices/VrDevicesActions';

const CompanyDevicesPage = () => {
  const { _t } = useTranslationPrefix('CompanyDevicesPage');
  const { companyId } = useParams<{ companyId: string }>();
  const { data, loading } = useQuery(GetCompanyDocument, {
    variables: {
      companyIdInput: {
        companyId: companyId,
      },
    },
    skip: !companyId,
  });

  const { data: devicesData, loading: devicesLoading } = useQuery(
    GetVrDevicesDocument,
    {
      variables: {
        companyIdOptionalInput: {
          companyId,
        },
      },
    },
  );
  const devices = devicesData?.vrDevices || [];
  const company = data?.company;
  if (loading || devicesLoading || !company) return <CircularProgress />;
  return (
    <>
      <TopBar
        leftSideText={_t('title', 'Devices of {{companyName}}', {
          companyName: company.name,
        })}
        actions={<VrDevicesActions companyId={companyId} />}
      />
      <SectionPaper title={_t('headsets', 'Headsets')}>
        <Flex flexWrap="wrap">
          {devices?.map((device) => (
            <VrDeviceCard key={device.id} device={device} />
          ))}
        </Flex>
      </SectionPaper>
    </>
  );
};

export default CompanyDevicesPage;
