import { Dialog, DialogProps } from '@mui/material';
import { Box } from '@mui/system';
import { BaseDialogTransition } from '../../../base/components/BaseDialog';
import SelectSceneWizard from './SelectSceneWizard';

interface Props extends DialogProps {
  vrDeviceId?: string;
  userId?: string;
}

const LaunchSceneDialog = ({
  open,
  vrDeviceId,
  userId,
  onClose,
  ...rest
}: Props) => {
  return (
    <Dialog
      {...rest}
      open={open}
      onClose={onClose}
      maxWidth={'md'}
      fullWidth={true}
      TransitionComponent={BaseDialogTransition}
    >
      <Box paddingBottom={2}>
        {open && (
          <SelectSceneWizard
            vrDeviceId={vrDeviceId}
            userId={userId}
            onClose={onClose}
          />
        )}
      </Box>
    </Dialog>
  );
};

export default LaunchSceneDialog;
