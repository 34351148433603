import { CardActionArea, CardContent } from '@mui/material';
import { BaseTypography } from '../../../../base/components/BaseTypography';
import { SelectableCard } from '../../../../base/components/SelectableCard';
import { UserGroupFragmentFragment } from '../../../../graphql/graphql-operations';
import { ListView } from '../../../components/ListView';

interface Props {
  groups: UserGroupFragmentFragment[];
  setSelectedUserGroupIds: React.Dispatch<React.SetStateAction<string[]>>;
  selectedUserGroupIds: string[];
  query: string;
}

const PlaylistAssignGroupsTab = ({
  groups,
  setSelectedUserGroupIds,
  selectedUserGroupIds,
  query,
}: Props) => {
  const handleSelection = ({ id }: { id: string }) => {
    setSelectedUserGroupIds((groups) =>
      groups.includes(id)
        ? groups.filter((groupId) => groupId !== id)
        : [...groups, id],
    );
  };

  const renderValue = (group: UserGroupFragmentFragment) => {
    return (
      <SelectableCard
        id={`group-${group.id}`}
        selected={selectedUserGroupIds.includes(group.id)}
      >
        <CardActionArea onClick={() => handleSelection(group)}>
          <CardContent>
            <BaseTypography variant="h4" fontWeight="bold">
              {group.name}
            </BaseTypography>
          </CardContent>
        </CardActionArea>
      </SelectableCard>
    );
  };

  return (
    <ListView<UserGroupFragmentFragment>
      data={groups}
      rowKey="id"
      renderValue={renderValue}
      columns={{ xs: 1, sm: 2, md: 3 }}
      searchConfig={{
        filterBy: 'name',
        filterValue: query,
      }}
    />
  );
};

export default PlaylistAssignGroupsTab;
