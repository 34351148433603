import { useContext } from 'react';
import {
  VrDevicesContext,
  VrDeviceType,
} from '../../../base/components/VrDevicesProvider/VrDevicesProvider';
import { ListView } from '../../components/ListView';
import { DeviceCard } from './components/DeviceCard';

interface Props {
  onDeviceUpdateClick?: (device: VrDeviceType) => void;
  onDeviceEditClick?: (device: VrDeviceType) => void;
  onDeviceClick?: (device: VrDeviceType) => void;
  selectedDevices: string[];
  isActive: (device: VrDeviceType) => boolean;
  onDeviceRestartClick?: (device: VrDeviceType) => void;
}

export const DeviceList = ({
  onDeviceClick,
  onDeviceUpdateClick,
  onDeviceEditClick,
  selectedDevices,
  isActive,
  onDeviceRestartClick,
}: Props) => {
  const { boxDevices } = useContext(VrDevicesContext);
  const devices = boxDevices || [];
  const small = devices.length > 8;

  return (
    <ListView<VrDeviceType>
      columns={{
        xs: small ? 2 : 1,
        sm: small ? 3 : 2,
        md: small ? 4 : 2,
      }}
      gridSpacing={small ? 2 : 3}
      data={devices}
      rowKey="id"
      renderValue={(device) => (
        <DeviceCard
          device={device}
          selected={selectedDevices.includes(device.id)}
          onDeviceUpdateClick={onDeviceUpdateClick}
          onDeviceEditClick={onDeviceEditClick}
          onDeviceClick={isActive(device) ? onDeviceClick : undefined}
          small={devices.length > 8}
          onDeviceRestartClick={onDeviceRestartClick}
        />
      )}
    />
  );
};
