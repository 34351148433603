import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import MovieCreationOutlinedIcon from '@mui/icons-material/MovieCreationOutlined';
import { Box, BoxProps, Button, IconButton } from '@mui/material';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { BaseTypography } from '../../../../base/components/BaseTypography';
import { Flex } from '../../../../base/components/Flex';
import { VrSessionFragmentFragment } from '../../../../graphql/graphql-operations';
import { useTranslationPrefix } from '../../../../hooks/useTranslationPrefix';
import {
  BOTTOM_DRAWER_COLLAPSED_HEIGHT,
  BOTTOM_DRAWER_EXPANDED_HEIGHT,
} from '../../../components/PlaylistBuilder/PlaylistSelectedScenesDrawer';
import { ScrollableList } from '../../Users/components/ScrollableList';
import SessionSceneCard from './SessionSceneCard';

interface Props {
  session?: VrSessionFragmentFragment;
  skip: (itemId?: string) => void;
  onFinishClick: (all: boolean) => void;
  multipleVariant: boolean;
}

const SessionBottomPanel = ({
  session,
  skip,
  multipleVariant,
  onFinishClick,
}: Props) => {
  const { _t } = useTranslationPrefix('SessionBottomPanel');
  const [expanded, setExpanded] = useState(true);
  const swipeHandlers = useSwipeable({
    onSwipedUp: () => {
      setExpanded(true);
    },
    onSwipedDown: () => {
      setExpanded(false);
    },
    preventScrollOnSwipe: true,
  });

  const height = expanded
    ? BOTTOM_DRAWER_EXPANDED_HEIGHT
    : BOTTOM_DRAWER_COLLAPSED_HEIGHT;

  return (
    <Box
      height={height}
      sx={{
        transition: 'height 0.5s',
        boxShadow:
          '0px -24px 32px rgba(0, 0, 0, 0.04), 0px -16px 24px rgba(0, 0, 0, 0.04), 0px -4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);',
      }}
    >
      <Box
        height={BOTTOM_DRAWER_COLLAPSED_HEIGHT}
        sx={{
          display: 'flex',
          backgroundColor: 'common.white',
          justifyContent: 'center',
        }}
        {...swipeHandlers}
      >
        <RowContentWrapperStyled
          alignItems="center"
          justifyContent="space-between"
        >
          <Flex alignItems="center">
            <IconButton
              id="selected-scenes-expand-toggle"
              onClick={() => setExpanded((e) => !e)}
              sx={{ flexGrow: 0, flexShrink: 0, flexBasis: 20 }}
            >
              {expanded ? <ExpandMoreRoundedIcon /> : <ExpandLessRoundedIcon />}
            </IconButton>
            <BaseTypography variant="h5" flexShrink={0}>
              {_t('title', 'Your playlist')}
            </BaseTypography>
            <Flex
              flexShrink={0}
              alignItems="center"
              justifyContent="end"
              ml={4}
              display={{ xs: 'none', sm: 'flex' }}
            >
              <MovieCreationOutlinedIcon
                sx={{ fontSize: '1.5rem', mr: 0.5, color: 'grey.500' }}
              />
              <BaseTypography variant="body3" color="grey.500">
                {_t('sceneCount', '{{count}} scenes', {
                  count: session?.playlist.length,
                })}
              </BaseTypography>
            </Flex>
          </Flex>
          <Flex alignItems="center" gap={{ xs: 1, sm: 2 }} flexShrink={0}>
            {!session?.endedAt && (
              <>
                <Button
                  id="finish-session-button"
                  variant="contained"
                  color="error"
                  onClick={() => onFinishClick(multipleVariant)}
                  sx={{ ml: { xs: 'auto', sm: 2 } }}
                  size="small"
                  startIcon={<CloseRoundedIcon />}
                >
                  {multipleVariant
                    ? _t('finishAll', 'Finish all')
                    : _t('finish', 'Finish')}
                </Button>
              </>
            )}
            {session?.endedAt && session?.user?.id && (
              <Button
                id="go-to-patient-link"
                startIcon={<AccountCircleRoundedIcon />}
                variant="outlined"
                component={Link}
                to={`/users/${session.user.id}`}
                sx={{ ml: { xs: 'auto', sm: 2 } }}
                size="small"
              >
                {session.user.lastName}
              </Button>
            )}
          </Flex>
        </RowContentWrapperStyled>
      </Box>
      <Flex
        height="9.5rem"
        sx={{ backgroundColor: 'grey.50', justifyContent: 'center' }}
      >
        <RowContentWrapperStyled>
          <ScrollableList
            id="session-scenes"
            sx={{ height: '100%', minWidth: '100%' }}
            wrapperSx={{ minWidth: '100%' }}
          >
            <Flex gap={2} py={2} flexGrow={1} height="100%">
              {session?.playlist.map((item) => (
                <SessionSceneCard key={item.id} item={item} skip={skip} />
              ))}
            </Flex>
          </ScrollableList>
        </RowContentWrapperStyled>
      </Flex>
    </Box>
  );
};

export default SessionBottomPanel;

const RowContentWrapperStyled = (boxProps: BoxProps) => (
  <Flex width="100%" flexShrink={0} px={{ xs: 3, sm: 4 }} {...boxProps} />
);
