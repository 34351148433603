import { Box, styled } from '@mui/system';
import { useField } from 'formik';
import FormikErrorMessage from '../../../base/components/form/FormikErrorMessage';
import SilhouetteLocationSelect from './SilhouetteLocationSelect';

interface Props {
  name: string;
  label?: string;
  disabled?: boolean;
  pointsFilter?: (pointId: number) => boolean;
}

const FormikSilhouetteLocationSelect = ({
  name,
  pointsFilter,
  label,
  disabled,
}: Props) => {
  const [{ value }, { touched, error }, { setValue, setTouched }] =
    useField<number>(name);

  const handleOnChange = (newValue: number) => {
    if (!touched) {
      setTouched(true);
    }
    setValue(newValue);
  };

  return (
    <Box>
      {label && (
        <FormikSilhouetteLocationSelectLabel>
          {label}
        </FormikSilhouetteLocationSelectLabel>
      )}
      <SilhouetteLocationSelect
        disabled={disabled}
        selectedPointId={value}
        pointsFilter={pointsFilter}
        onChange={handleOnChange}
      />
      {error && touched && <FormikErrorMessage>{error}</FormikErrorMessage>}
    </Box>
  );
};

export default FormikSilhouetteLocationSelect;

const FormikSilhouetteLocationSelectLabel = styled('label')`
  font-weight: bold;
`;
