import { useMutation } from '@apollo/client';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Flex } from '../../../base/components/Flex';
import { EndVrSessionDocument } from '../../../graphql/graphql-operations';
import useMutationSnackbar from '../../../hooks/useMutationSnackbar';

interface Props {
  vrSessionId: string;
  onNext: () => void;
  onCancel: () => void;
}

const EndSessionStep: React.VFC<Props> = (props: Props) => {
  const { onNext, onCancel, vrSessionId } = props;
  const { t } = useTranslation();
  const endHandler = useMutationSnackbar('endSession', {
    translationKey: `endVrSessionDialog.endSession.endAction`,
    onSuccessfullyCompleted: onNext,
  });

  const [endMutation] = useMutation(EndVrSessionDocument, endHandler);

  const handleEndVrSession = async () => {
    await endMutation({
      variables: { endSessionInput: { sessionId: vrSessionId } },
    });
  };

  return (
    <Flex justifyContent="space-around">
      <Button
        id="end-session-cancel"
        size="large"
        color="primary"
        onClick={onCancel}
      >
        {t('common.cancel', 'Cancel')}
      </Button>
      <Button
        id="end-session-confirm"
        size="large"
        color="primary"
        variant="contained"
        onClick={handleEndVrSession}
      >
        {t('endVrSessionDialog.endSession.next', 'End session')}
      </Button>
    </Flex>
  );
};

export default EndSessionStep;
