import { useQuery } from '@apollo/client';
import { adminState } from '../../../adminState';
import { useUser } from '../../../base/components/UserProvider/useUserHook';
import {
  GetPlaylistTemplatesDocument,
  UserPermission,
} from '../../../graphql/graphql-operations';

export const usePlaylistTemplateCount = () => {
  const { user, hasAnyPermission } = useUser();
  const { data } = useQuery(GetPlaylistTemplatesDocument, {
    variables: {
      first: 0,
      filter: {
        companyId: adminState.selectedCompanyId.get(),
        ownerId: user?.id || null,
        enabled: true,
      },
    },
    skip: !hasAnyPermission(
      UserPermission.MANAGE_MY_PLAYLIST_TEMPLATES,
      UserPermission.MANAGE_OTHERS_PLAYLIST_TEMPLATES,
    ),
  });
  return data?.playlistTemplates.totalCount || 0;
};
