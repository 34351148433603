import SettingsIcon from '@mui/icons-material/Settings';
import {
  IconButton as MuiIconButton,
  ListItemText,
  Menu,
  MenuItem,
  styled,
} from '@mui/material';
import i18next from 'i18next';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import HasPermission from '../../base/components/UserProvider/HasPermission';
import { useUser } from '../../base/components/UserProvider/useUserHook';
import { UserPermission } from '../../graphql/graphql-operations';
import { useChangeUserLanguage } from '../../hooks/useChangeUserLanguage';
import useLogout from '../../hooks/useLogout';
import { useAppVariant } from '../../lib/useAppVariant';

const AppBarMenu = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const language = i18next.languages[0];
  const { logout } = useLogout();

  const { user } = useUser();

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) =>
    !anchorEl && setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);
  const [handleLanguageChange] = useChangeUserLanguage(handleMenuClose);
  const { setVariant } = useAppVariant();

  return (
    <>
      <IconButton
        id="appbar-settings"
        aria-controls="app-bar-menu"
        aria-haspopup="true"
        onClick={handleMenuOpen}
        size="large"
      >
        <SettingsIcon />
      </IconButton>
      <Menu
        id="app-bar-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div>
          <MenuItem>
            <ListItemText>{user?.email || ''}</ListItemText>
          </MenuItem>
        </div>
        <MenuItem onClick={() => handleLanguageChange()}>
          <ListItemText style={{ textAlign: 'center' }}>
            <SelectedLang selected={language === 'en'}>
              {t('appBarMenu.language.en', 'English')}
            </SelectedLang>
            {' / '}
            <SelectedLang selected={language === 'cs'}>
              {t('appBarMenu.language.cs', 'Czech')}
            </SelectedLang>
            {' / '}
            <SelectedLang selected={language === 'de'}>
              {t('appBarMenu.language.de', 'Deutsch')}
            </SelectedLang>
          </ListItemText>
        </MenuItem>
        <HasPermission val={UserPermission.LOGIN_CLIENT}>
          <MenuItem onClick={() => setVariant('client')}>
            <ListItemText style={{ textAlign: 'center' }}>
              {t('appBarMenu.client', 'Client app')}
            </ListItemText>
          </MenuItem>
        </HasPermission>
        <MenuItem onClick={logout}>
          <LogoutText style={{ textAlign: 'center' }}>
            {t('appBarMenu.logout', 'Log out')}
          </LogoutText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default AppBarMenu;

const IconButton = styled(MuiIconButton)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(1.5, 2, 1.5, 2),
  borderLeft: `1px solid ${theme.palette.primary.contrastText}`,
  borderRadius: 0,
}));

const SelectedLang = styled('span')<{ selected: boolean }>(
  ({ theme: { palette, typography }, selected }) => ({
    color: selected ? palette.primary.main : palette.grey[400],
    fontWeight: selected ? typography.fontWeightBold : 'inherit',
  }),
);

const LogoutText = styled(ListItemText)(({ theme }) => ({
  color: theme.palette.error.main,
}));
