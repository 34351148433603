import { Box, Card, CardContent, styled, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useLongPress } from 'react-use';
import { useLoginCompanies } from '../../../auth/useLoginCompanies';
import { useLoginVrBox } from '../../../auth/useLoginVrBox';
import { BaseTypography } from '../../../base/components/BaseTypography';
import { Flex } from '../../../base/components/Flex';
import { clearLoginRefreshTimeout } from '../../../graphql/links/error';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import { ClientLayout } from '../../../layouts';
import { PHONE_PREFIXES } from '../../../lib/constants';
import { Loader } from '../../components/Loader';
import ClientLoginLogo from './ClientLoginLogo';
import ClientLoginPassword from './ClientLoginPassword';
import ClientLoginSelectCompany from './ClientLoginSelectCompany';
import ClientLoginUserId from './ClientLoginUserId';
import { useLocalyStoredLoginDefaults } from './useLocalyStoredLoginDefaults';

type LoginStep = 'company' | 'userid' | 'password';
export interface LoginData {
  companyId: string | null;
  type: 'phone' | 'username';
  phonePrefix: string;
  phoneNumber: string;
  username: string;
}

const ClientLogin = () => {
  const { _t } = useTranslationPrefix('ClientLogin');
  const { palette } = useTheme();
  const [loginStep, setLoginStep] = useState<LoginStep>('company');
  const {
    defaultCompanyId,
    defaultLoginType,
    setDefaultCompanyId,
    setDefaultLoginType,
  } = useLocalyStoredLoginDefaults();
  const [loginData, setLoginData] = useState<LoginData>({
    type: defaultLoginType,
    companyId: defaultCompanyId,
    phonePrefix: PHONE_PREFIXES[0],
    phoneNumber: '',
    username: '',
  });
  const { loading: loadingCompanies, loginCompanies } = useLoginCompanies();
  const { loading: loadingVrBox, vrBox } = useLoginVrBox();
  const loading = loadingCompanies || loadingVrBox;

  clearLoginRefreshTimeout();

  const longPressProps = useLongPress(() => setLoginStep('company'), {
    delay: 3000,
  });

  useEffect(() => {
    if (!loading && loginCompanies?.length) {
      const companyIds = loginCompanies.map((c) => c.id);
      const vrboxCompanyId =
        vrBox?.rootCompanyId && companyIds.includes(String(vrBox.rootCompanyId))
          ? String(vrBox?.rootCompanyId)
          : '';

      setLoginData((d) => {
        const storedCompanyId =
          d.companyId && !companyIds.includes(d.companyId) ? '' : d.companyId;
        return {
          ...d,
          companyId: vrboxCompanyId || storedCompanyId,
        };
      });
      if (!!vrboxCompanyId) {
        setLoginStep('userid');
      }
    }
  }, [loading, loginCompanies, vrBox?.rootCompanyId]);

  const selectCompany = (companyId: string | null) => {
    setDefaultCompanyId(companyId);
    setLoginData((d) => ({ ...d, companyId }));
    setLoginStep('userid');
  };

  const setUserIds = (data: Partial<LoginData>) => {
    setLoginData((d) => ({ ...d, ...data }));
    setLoginStep('password');
  };

  const handleLoginTypeChange = (type: 'phone' | 'username') => {
    setDefaultLoginType(type);
    setLoginData((d) => ({ ...d, type }));
  };

  const selectedCompany = loginCompanies?.find(
    (c) => c.id === loginData.companyId,
  );
  return loading ? (
    <Flex height="100vh">
      <Loader />
    </Flex>
  ) : (
    <ClientLayout>
      <Flex flexDirection="column" alignItems="center" flexGrow={1}>
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          maxWidth="80vw"
          width="100%"
          flexGrow={1}
        >
          <ClientLoginLogo
            {...longPressProps}
            color={palette.text.secondary}
            sx={{ px: 8, pb: 5 }}
          />

          <Card sx={{ flexShrink: 0, width: '100%', maxWidth: '38rem' }}>
            <ClientLoginForm onSubmit={(e) => e.preventDefault()}>
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <BaseTypography variant="h3" py={1}>
                  {_t('logInTitle', 'Log in')}
                </BaseTypography>
                <BaseTypography color="grey.500">
                  {selectedCompany
                    ? _t(
                        'titleWithName',
                        'Enter the world of virtual training in {{companyName}}',
                        { companyName: selectedCompany?.name },
                      )
                    : _t('title', 'Enter the world of virtual training')}
                </BaseTypography>

                {loginStep === 'company' && (
                  <ClientLoginSelectCompany
                    companyId={loginData.companyId}
                    companies={loginCompanies || []}
                    onContinue={selectCompany}
                  />
                )}
                {loginStep === 'userid' && (
                  <ClientLoginUserId
                    companyId={loginData.companyId}
                    type={loginData.type}
                    onTypeChange={handleLoginTypeChange}
                    data={{
                      phonePrefix: loginData.phonePrefix,
                      phoneNumber: loginData.phoneNumber,
                      username: loginData.username,
                    }}
                    onBack={vrBox ? undefined : () => setLoginStep('company')}
                    onContinue={setUserIds}
                  />
                )}
                {loginStep === 'password' && (
                  <ClientLoginPassword
                    data={loginData}
                    onBack={() => setLoginStep('userid')}
                  />
                )}
              </CardContent>
            </ClientLoginForm>
          </Card>
        </Flex>
        <Box sx={{ textAlign: 'center', pb: 2, color: 'text.secondary' }}>
          <Trans
            i18nKey={'ClientLogin.footer'}
            defaults="In case of any problems, please contact us at <0>{{supportEmailAddress}}</0>."
            values={{
              supportEmailAddress: _t(
                'supportEmailAddress',
                'support@vrmedical.cz',
              ),
            }}
          >
            <a
              href={`mailto:${_t(
                'supportEmailAddress',
                'support@vrmedical.cz',
              )}`}
            >
              {' '}
            </a>
          </Trans>
        </Box>
      </Flex>
    </ClientLayout>
  );
};

export default ClientLogin;

const ClientLoginForm = styled('form')`
  width: "100%",
  flex-shrink: 0,
`;
