import { ECharts } from 'echarts/core';
import { useEffect } from 'react';
import { LegendSelectChangedEvent, UpdateAxisPointerEvent } from './ChartTypes';

interface Props {
  chart: React.MutableRefObject<ECharts | null>;
  onAxisPointerChange?: (event: UpdateAxisPointerEvent) => any;
  onLegendSelectChange?: (event: LegendSelectChangedEvent) => any;
}

const useChartEvents = ({
  chart,
  onAxisPointerChange,
  onLegendSelectChange,
}: Props) => {
  useEffect(() => {
    const tmpChart = chart.current;
    if (tmpChart && onAxisPointerChange) {
      const callback = (e: any) => {
        if (e?.axesInfo?.length > 0) {
          onAxisPointerChange(e);
        }
      };
      tmpChart.on('updateAxisPointer', callback);
      return () => {
        tmpChart.off('updateAxisPointer', callback);
      };
    }
  }, [chart, onAxisPointerChange]);

  useEffect(() => {
    const tmpChart = chart.current;
    if (tmpChart && onLegendSelectChange) {
      const callback = (e: any) => {
        onLegendSelectChange(e);
      };
      tmpChart.on('legendselectchanged', callback);
      return () => {
        tmpChart.off('legendselectchanged', callback);
      };
    }
  }, [chart, onLegendSelectChange]);
};

export default useChartEvents;
