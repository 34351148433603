import ReportGmailerrorredRoundedIcon from '@mui/icons-material/ReportGmailerrorredRounded';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { loginVrSession } from '../../../auth/auth';
import { BaseTypography } from '../../../base/components/BaseTypography';
import { Flex } from '../../../base/components/Flex';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import ClientBackgroundGradient from '../../../layouts/ClientLayout/ClientBackgroundGradient';
import { HttpError } from '../../../lib/errors';
import { Loader } from '../../components/Loader';
import SessionDetailPage from '../Sessions/SessionDetailPage';

enum PageState {
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  STREAM = 'STREAM',
}

const LiveVrSession = () => {
  const { vrSessionId } = useParams<{ vrSessionId: string }>();
  const [pageState, setPageState] = useState<PageState>(PageState.LOADING);
  const { _t } = useTranslationPrefix('LiveVrSession');

  useEffect(() => {
    if (!vrSessionId) {
      return;
    }
    setPageState(PageState.LOADING);
    loginVrSession(vrSessionId)
      .then(() => setPageState(PageState.STREAM))
      .catch((e: HttpError) => {
        setPageState(PageState.ERROR);
        console.error('Login failed', e);
      });
  }, [vrSessionId, setPageState]);

  const renderError = () => {
    return (
      <Flex m="auto" flexDirection="column" alignItems="center">
        <ReportGmailerrorredRoundedIcon
          sx={{ color: 'text.secondary', fontSize: '3rem' }}
        />
        <BaseTypography color="text.secondary">
          {_t('noLongerAvailable', 'No longer available')}
        </BaseTypography>
      </Flex>
    );
  };

  return (
    <Flex height="100vh" overflow="hidden">
      <Box
        overflow="hidden"
        top={0}
        left={0}
        right={0}
        bottom={0}
        position="absolute"
      >
        <ClientBackgroundGradient />
      </Box>
      <Flex height="100vh" width="100vw" position="relative">
        {pageState === PageState.LOADING && <Loader />}
        {pageState === PageState.ERROR && renderError()}
        {pageState === PageState.STREAM && <SessionDetailPage skipNavigation />}
      </Flex>
    </Flex>
  );
};

export default LiveVrSession;
