import { useState } from '@hookstate/core';
import { useTranslation } from 'react-i18next';
import { UserStatus } from '../../../graphql/graphql-operations';
import NewPatientDialog from '../../components/NewPatientDialog/NewPatientDialog';
import PatientsTable from '../../components/PatientsTable/PatientsTable';
import { SectionPaper } from '../../components/SectionPaper';
import { TopBar } from '../../components/TopBar';
import PatientRowActions from './PatientRowActions';
import PatientsActions from './PatientsActions';

export interface TableData {
  id: string;
  lastName: string;
  lastSession: Date | null;
  sessionCount: number;
  sessionsTotalLengthMs: number;
  vrSessionId?: string;
}
const PatientsPage: React.VFC = () => {
  const { t } = useTranslation();
  const state = useState({ newPatientDialogOpen: false });

  const handleCloseNewPatientDialog = () => {
    state.newPatientDialogOpen.set(false);
  };

  return (
    <>
      <TopBar
        leftSideText={t('patients.topBar.title', 'Patients')}
        actions={
          <PatientsActions
            onNewClientClick={() => state.newPatientDialogOpen.set(true)}
          />
        }
      />
      <SectionPaper>
        <PatientsTable
          rowActions={(rowData) => <PatientRowActions rowData={rowData} />}
          patientStatus={UserStatus.ACTIVE}
        />
      </SectionPaper>
      <NewPatientDialog
        open={state.newPatientDialogOpen.get()}
        onClose={handleCloseNewPatientDialog}
        onUserCreated={handleCloseNewPatientDialog}
      />
    </>
  );
};

export default PatientsPage;
