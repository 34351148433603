import { useApolloClient } from '@apollo/client';
import { Button, Link, styled, TextField } from '@mui/material';
import * as Sentry from '@sentry/react';
import humps from 'humps';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login, requestPasswordReset, verifyLoginSms } from '../../../auth';
import { Flex } from '../../../base/components/Flex';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import { LoginData } from './ClientLogin';

interface Props {
  data: LoginData;
  onBack: () => void;
}

const ClientLoginPassword = ({ data, onBack }: Props) => {
  const { _t } = useTranslationPrefix('ClientLoginPassword');
  const [passwordResetSent, setPasswordResetSent] = useState(false);
  const client = useApolloClient();
  const [valueState, setValueState] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  //always redirect to homepage after login
  //let location = useLocation();
  //const { from } = (location.state as any) || { from: { pathname: '/' } };
  const { from } = { from: { pathname: '/' } };

  const handleUsernameLogin = async () => {
    const { result, errorMessage } = await login({
      companyId: data.companyId,
      username: data.username,
      password: valueState,
    });
    if (result && typeof result === 'boolean') {
      await client.clearStore();
      navigate(from, { replace: true });
    } else {
      setValueState('');
      setError(
        _t(
          `error.${humps.camelize(
            (errorMessage ?? 'genericError').toLowerCase(),
          )}`,
          'Wrong combination of username and password',
        ),
      );
    }
  };

  const handlePhoneLogin = async () => {
    const result = await verifyLoginSms({
      companyId: data.companyId,
      phonePrefix: data.phonePrefix,
      phoneNumber: data.phoneNumber,
      smsCode: valueState,
    });
    if (!result?.errors) {
      await client.clearStore();
      navigate(from, { replace: true });
    } else {
      setValueState('');

      for (const [, error] of Object.entries(result.errors)) {
        const { code, message } = error || {};
        if (code && message) {
          setError(_t(`error.${code}`, message));
        }
      }
    }
  };

  const handleContinue = async () => {
    if (!valueState) {
      setError(_t('passwordRequired', 'Password is required'));
      return;
    }
    if (data.type === 'phone' && valueState.length !== 6) {
      setError(_t('smsPinLengthError', 'Expected 6 characters'));
      return;
    }

    setSubmitting(true);
    try {
      if (data.type === 'phone') {
        await handlePhoneLogin();
      } else {
        await handleUsernameLogin();
      }
    } finally {
      setSubmitting(false);
    }
  };

  const normalizeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    if (data.type === 'phone') {
      value = value.replace(/[^.\d]/g, '');
    }
    if (value) {
      setError('');
    }
    setValueState(value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      handleContinue();
    }
  };

  const handlePasswordReset = async () => {
    try {
      await requestPasswordReset({
        companyId: data.companyId || null,
        username: data.username,
      });
      setPasswordResetSent(true);
    } catch (err) {
      Sentry.captureException(err);
      enqueueSnackbar(
        _t(
          'failedToSendPasswordReset',
          'Failed to send password reset e-mail.',
        ),
        { variant: 'error' },
      );
    }
  };

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      width="100%"
      maxWidth="300px"
      pt={{ xs: 0, sm: 9 }}
      pb={data.type === 'phone' ? 3 : 0}
    >
      {data.type === 'username' && (
        <InvisibleInput
          name="username"
          autoComplete="username"
          value={data.username}
        />
      )}
      <TextField
        id="login-password"
        name={data.type === 'phone' ? 'one-time-code' : 'password'}
        autoComplete={data.type === 'phone' ? 'one-time-code' : 'password'}
        variant="outlined"
        label={
          data.type === 'phone'
            ? _t('smsCode', 'Password (SMS)')
            : _t('password', 'Password')
        }
        disabled={submitting}
        value={valueState}
        onChange={normalizeValue}
        type={data.type === 'phone' ? 'text' : 'password'}
        fullWidth
        error={!!error}
        helperText={error}
        onKeyDown={handleKeyDown}
        autoFocus
      />

      <Flex justifyContent="center">
        <Button
          id="back"
          variant="outlined"
          sx={{ mb: 2, mt: 3, mr: 2 }}
          onClick={onBack}
          disabled={submitting}
        >
          {_t('back', 'Back')}
        </Button>
        <Button
          id="login"
          variant="contained"
          sx={{ mb: 2, mt: 3 }}
          onClick={handleContinue}
          disabled={submitting}
        >
          {_t('logIn', 'Log in')}
        </Button>
      </Flex>
      {data.type === 'username' && (
        <>
          {passwordResetSent ? (
            <PasswordResetSuccessText>
              {_t('resetSent', 'Instructions sent to you e-mail.')}
            </PasswordResetSuccessText>
          ) : (
            <Link onClick={handlePasswordReset} sx={{ cursor: 'pointer' }}>
              {_t('forgotenPassword', 'Forgotten password?')}
            </Link>
          )}
        </>
      )}
    </Flex>
  );
};

export default ClientLoginPassword;

const PasswordResetSuccessText = styled('div')(
  ({ theme: { palette } }) => `
  color: ${palette.success.main};
`,
);

const InvisibleInput = styled('input')`
  display: none;
`;
