import { ApolloError } from '@apollo/client';
import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const DEFAULT_MSG = 'Nelze získat data ze serveru';

export default function useQueryErrorSnackbar(
  error: ApolloError | undefined,
  refetch: () => void,
  message: string = DEFAULT_MSG,
) {
  const { t } = useTranslation();
  // Show snackbar on error
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  useEffect(() => {
    let key: string | number | null | undefined = null;
    if (error != null) {
      key = enqueueSnackbar(message, {
        persist: true,
        action: (key) => (
          <Button
            id={`error-reload-${key}`}
            size="small"
            color="secondary"
            onClick={() => {
              refetch();
              closeSnackbar(key);
            }}
          >
            {t('common.reload', 'Reload')}
          </Button>
        ),
      });
    }
    return () => {
      if (key != null) {
        closeSnackbar(key);
      }
    };
  }, [error, enqueueSnackbar, closeSnackbar, refetch, message, t]);
}
