import { Box, Paper as MuiPaper, PaperProps, styled } from '@mui/material';
import { BaseTypography } from '../../../base/components/BaseTypography';
import { Flex } from '../../../base/components/Flex';

interface Props extends PaperProps {
  children: React.ReactNode;
  title?: string;
  actions?: React.ReactNode;
}

const SectionPaper = ({ children, title, actions, ...rest }: Props) => {
  return (
    <Paper {...rest}>
      {title && (
        <Flex
          justifyContent="space-between"
          marginBottom={3}
          alignItems="center"
        >
          <BaseTypography variant="h6">{title}</BaseTypography>
          <Flex>{actions}</Flex>
        </Flex>
      )}
      <Box>{children}</Box>
    </Paper>
  );
};

export default SectionPaper;

const Paper = styled(MuiPaper)(({ theme }) => ({
  padding: theme.spacing(3),
}));
