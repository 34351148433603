export enum HelpFaqButtonType {
  BACK = 'BACK',
  NEXT = 'NEXT',
  CONFIRM = 'CONFIRM',
  LEAVE = 'LEAVE',
}

export interface HelpFaqContentButtonCommon {
  title: string;
  id: number;
  type: HelpFaqButtonType;
  variant: 'contained' | 'outlined';
}

export interface HelpFaqContentNextButton extends HelpFaqContentButtonCommon {
  type: HelpFaqButtonType.NEXT;
  next_slide: HelpFaqContentSlide;
}

export interface HelpFaqContentBackButton extends HelpFaqContentButtonCommon {
  type: HelpFaqButtonType.BACK;
}

export interface HelpFaqContenConfirmButton extends HelpFaqContentButtonCommon {
  type: HelpFaqButtonType.CONFIRM;
  next_slide: HelpFaqContentSlide;
}

export interface HelpFaqContenLeaveButton extends HelpFaqContentButtonCommon {
  type: HelpFaqButtonType.LEAVE;
}

export type HelpFaqContentButton =
  | HelpFaqContentNextButton
  | HelpFaqContentBackButton
  | HelpFaqContenConfirmButton
  | HelpFaqContenLeaveButton;

export interface HelpFaqContentItemCommon {
  title?: string;
}

export enum HelpFaqContentItemType {
  CHOICES = 'CHOICES',
  CONTENT = 'CONTENT',
  TEXT_INPUT = 'TEXT_INPUT',
  TEXT_AREA = 'TEXT_AREA',
}

export interface HelpFaqContentItemChoices extends HelpFaqContentItemCommon {
  type: HelpFaqContentItemType.CHOICES;
  buttons: HelpFaqContentNextButton[];
}

export interface HelpFaqContentItemContent extends HelpFaqContentItemCommon {
  type: HelpFaqContentItemType.CONTENT;
  text?: string;
  image?: {
    position: 'left' | 'right';
    // BASE64
    uri: string;
  };
}

export interface HelpFaqContentItemTextField extends HelpFaqContentItemCommon {
  type: HelpFaqContentItemType.TEXT_INPUT | HelpFaqContentItemType.TEXT_AREA;
  text?: string;
  label?: string;
  name: string;
  required?: string;
}

export type HelpFaqContentItem =
  | HelpFaqContentItemTextField
  | HelpFaqContentItemContent
  | HelpFaqContentItemChoices;

export interface HelpFaqContentSlide {
  name: string;
  id: number;
  title?: string;
  items: HelpFaqContentItem[];
  buttons?: HelpFaqContentButton[];
}
