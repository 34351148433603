import { Grid } from '@mui/material';
import { ReportSubCompany } from '../useSessionPlaylistItemReports';
import ReportSubCompanyCard from './ReportSubCompanyCard';

interface Props {
  subCompanies: ReportSubCompany[];
  onCompanySelect: (companyId: string) => void;
}

const ReportSubCompaniesList = ({ subCompanies, onCompanySelect }: Props) => {
  return (
    <Grid container spacing={2}>
      {subCompanies.map((company) => (
        <Grid
          key={company.id}
          id={`subcompany-list-report-${company.id}`}
          item
          xs={12}
        >
          <ReportSubCompanyCard
            company={company}
            onCompanySelect={onCompanySelect}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ReportSubCompaniesList;
