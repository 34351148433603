import { useMutation, useQuery } from '@apollo/client';
import isPropValid from '@emotion/is-prop-valid';
import EditIcon from '@mui/icons-material/Edit';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import { Button, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import ConfirmDialog from '../../../base/components/ConfirmDialog/ConfirmDialog';
import {
  ApplicationSceneGroupStatus,
  EditApplicationSceneGroupDocument,
  GetApplicationSceneGroupDocument,
} from '../../../graphql/graphql-operations';
import useMutationSnackbar from '../../../hooks/useMutationSnackbar';
import useQueryErrorSnackbar from '../../../hooks/useQueryErrorSnackbar';
import { TopBar } from '../../components/TopBar';
import ApplicationGroupEditDialog from './ApplicationGroupEditDialog';
import ApplicationScenesTable from './ApplicationScenesTable';

const ApplicationGroupDetailPage = () => {
  const { groupId } = useParams<{ groupId: string }>();
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [unarchiveDialogOpen, setUnarchiveDialogOpen] = useState(false);
  const { t } = useTranslation();
  const { data, loading, error, refetch } = useQuery(
    GetApplicationSceneGroupDocument,
    {
      variables: {
        id: groupId,
      },
      skip: !groupId,
    },
  );
  useQueryErrorSnackbar(error, refetch);

  const unarchiveHandler = useMutationSnackbar('editApplicationSceneGroup', {
    translationKey: 'applicationGroups.detail.unarchive',
    onSuccessfullyCompleted: () => setUnarchiveDialogOpen(false),
  });

  const [unarchiveMutation] = useMutation(EditApplicationSceneGroupDocument, {
    ...unarchiveHandler,
  });

  const handleUnarchiveProgram = () => {
    unarchiveMutation({
      variables: {
        editApplicationSceneGroupInput: {
          applicationSceneGroupId: groupId!,
          status: ApplicationSceneGroupStatus.ACTIVE,
        },
      },
    });
  };

  const group = data?.applicationSceneGroup;
  return loading ? (
    <CircularProgress />
  ) : (
    <>
      <TopBar
        leftSideText={group?.name}
        leftSideStatusText={
          <ApplicationGroupDetailPageStatus enabled={!!group?.enabled}>
            {!!group?.enabled
              ? t('applicationGroups.detail.enabled', 'Enabled')
              : t('applicationGroups.detail.disabled', 'Disabled')}
          </ApplicationGroupDetailPageStatus>
        }
        actions={
          <>
            {group?.status === ApplicationSceneGroupStatus.ACTIVE ? (
              <Button
                id="application-group-detail-page-edit"
                variant="text"
                startIcon={<EditIcon />}
                onClick={() => setEditDialogOpen(true)}
              >
                {t('applicationGroups.detail.editGroup', 'Edit program')}
              </Button>
            ) : (
              <Button
                id="application-group-detail-page-reactivate"
                size="large"
                color="success"
                variant="contained"
                startIcon={<UnarchiveIcon viewBox="5 5 14 14" />}
                onClick={() => setUnarchiveDialogOpen(true)}
              >
                {t(
                  'applicationGroups.detail.unarchiveGroup',
                  'Reactivate program',
                )}
              </Button>
            )}
          </>
        }
      />
      <ApplicationScenesTable
        scenes={group?.currentScenes ? [...group.currentScenes] : []}
      />
      {group && (
        <ApplicationGroupEditDialog
          applicationGroup={group}
          open={editDialogOpen}
          onClose={() => setEditDialogOpen(false)}
        />
      )}
      <ConfirmDialog
        open={unarchiveDialogOpen}
        title={t(
          'applicationGroups.detail.unarchiveGroupConfirm',
          'Reactivate program?',
        )}
        onClose={() => setUnarchiveDialogOpen(false)}
        onConfirm={handleUnarchiveProgram}
      ></ConfirmDialog>
    </>
  );
};

export default ApplicationGroupDetailPage;

const ApplicationGroupDetailPageStatus = styled('span', {
  shouldForwardProp: isPropValid,
})<{ enabled: boolean }>(
  ({ enabled, theme: { palette } }) => `
        color: ${enabled ? palette.success.main : palette.error.main};
    `,
);
