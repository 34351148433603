import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import {
  Box,
  BoxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useMemo } from 'react';
import { Flex } from '../../../../base/components/Flex';
import { useTranslationPrefix } from '../../../../hooks/useTranslationPrefix';
import { UserTrainingDetailReport } from '../useTrainingReport';
type TableData = UserTrainingDetailReport['sequences'][0];

interface Props extends Omit<BoxProps, 'children'> {
  data: TableData[];
}

const TrainingResultSequenceTable = ({ data, ...boxProps }: Props) => {
  const { _t } = useTranslationPrefix('TrainingResultSequenceTable');

  //errors in data, remove duplicate rows by name
  const unique = useMemo(
    () =>
      data.filter((row, idx, rest) => {
        const firstIdx = rest.findIndex((tmp) => tmp.name === row.name);
        return firstIdx === idx;
      }),
    [data],
  );

  return (
    <>
      <TableContainer component={Box} {...boxProps}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ minWidth: '14ch' }}>
                {_t('name', 'Scéna')}
              </TableCell>
              <TableCell>{_t('onTime', 'Včas')}</TableCell>
              <TableCell>{_t('mistakes', 'Chyby')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {unique.map((row) => {
              const onTime = row.onTime === 'Stihl';
              return (
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td': { border: 0 } }}
                >
                  <TableCell>{row.name}</TableCell>
                  <TableCell
                    sx={(theme) => ({
                      color: onTime
                        ? theme.palette.success.main
                        : theme.palette.error.main,
                    })}
                  >
                    <Flex alignItems="center" gap={1}>
                      {onTime ? (
                        <CheckCircleRoundedIcon />
                      ) : (
                        <CancelRoundedIcon />
                      )}
                      <span>{row.onTime}</span>
                    </Flex>
                  </TableCell>
                  <TableCell>
                    {row.mistakes?.length ? row.mistakes.join(', ') : '--'}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default TrainingResultSequenceTable;
