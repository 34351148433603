import { useQuery } from '@apollo/client';
import { BaseTabs } from '../../../../base/components/BaseTabs';
import { TabData } from '../../../../base/components/BaseTabs/BaseTabs';
import { useUser } from '../../../../base/components/UserProvider/useUserHook';
import {
  GetUserTrainingAssignmentsDocument,
  StreamType,
  UserDetailFragmentFragment,
  UserPermission,
} from '../../../../graphql/graphql-operations';
import { useTranslationPrefix } from '../../../../hooks/useTranslationPrefix';
import useStreamType from '../../../../lib/useStreamType';
import { usePlaylistTemplateCount } from '../../PlaylistTemplates/playlistTemplateCountHook';

export enum UserDetailFilter {
  TRAINING = 'training',
  PLAYLISTS = 'playlists',
  HISTORY = 'history',
}

interface Props {
  onChange: (tab: UserDetailFilter) => void;
  activeTab: UserDetailFilter;
  user?: UserDetailFragmentFragment | null;
}

const UserDetailTabs = ({ activeTab, onChange, user }: Props) => {
  const trainingVariant = useStreamType() === StreamType.TRAINING;
  const templateCount = usePlaylistTemplateCount();
  const { hasAnyPermission } = useUser();

  const { data } = useQuery(GetUserTrainingAssignmentsDocument, {
    variables: {
      userId: user?.id!,
    },
    skip:
      !user?.id || !hasAnyPermission(UserPermission.VIEW_TRAINING_ASSIGNMENTS),
  });

  const { _t } = useTranslationPrefix('UserDetailTabs');

  const tabs: TabData[] = [
    {
      label: _t('history', 'History'),
      count: user?.sessionCumulativeStatistic?.numberOfSessions || 0,
      value: UserDetailFilter.HISTORY,
    },
  ];
  if (hasAnyPermission(UserPermission.VIEW_TRAINING_ASSIGNMENTS)) {
    tabs.push({
      label: _t('training', 'Training'),
      count: data?.userTrainingAssignments?.length || 0,
      value: UserDetailFilter.TRAINING,
    });
  }
  if (
    !trainingVariant &&
    hasAnyPermission(UserPermission.MANAGE_MY_PLAYLIST_TEMPLATES)
  ) {
    tabs.push({
      label: _t('playlists', 'Playlists'),
      count: templateCount || 0,
      value: UserDetailFilter.PLAYLISTS,
    });
  }

  return (
    <BaseTabs
      value={activeTab}
      onChange={(e, val: UserDetailFilter) => onChange(val)}
      tabs={tabs}
    />
  );
};

export default UserDetailTabs;
