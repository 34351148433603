import { useQuery } from '@apollo/client';
import { ResultOf } from '@graphql-typed-document-node/core';
import { useState } from '@hookstate/core';
import { format, parseISO } from 'date-fns';
import { useSessionStorage } from 'react-use';
import { adminState } from '../../../../adminState';
import {
  BaseRefetchTableVariables,
  TableCellLink,
} from '../../../../base/components/BaseTable';
import { BaseTypography } from '../../../../base/components/BaseTypography';
import {
  ColumnConfig,
  SimpleTable,
} from '../../../../base/components/SimpleTable';
import {
  GetVrDeviceSessionsDocument,
  OrderDirection,
  SessionOrderField,
} from '../../../../graphql/graphql-operations';
import useQueryErrorSnackbar from '../../../../hooks/useQueryErrorSnackbar';
import { useTranslationPrefix } from '../../../../hooks/useTranslationPrefix';
import { DATE_TIME_FORMAT } from '../../../../lib/constants';
import { joinStrings } from '../../../../lib/strings';
import SessionRowActions from './SessionRowActions';

interface Props {
  vrDeviceId: string;
}

export interface TableData {
  id: string;
  startedAt: string;
  patientName: string;
  patientId: string;
  scenes: string;
  session: Session;
}

export type Session = NonNullable<
  ResultOf<typeof GetVrDeviceSessionsDocument>['sessions']['nodes']
>[0];

const VrDeviceSessions = ({ vrDeviceId }: Props) => {
  const { _t } = useTranslationPrefix('vrDeviceSession');
  const selectedCompanyId = useState(adminState.selectedCompanyId);

  const [rowsPerPage] = useSessionStorage('rowsPerPage', 15);
  const { data, loading, error, refetch, variables } = useQuery(
    GetVrDeviceSessionsDocument,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        filter: {
          vrDeviceId: vrDeviceId,
          companyId: selectedCompanyId.value,
        },
        first: rowsPerPage,
        orderBy: {
          direction: OrderDirection.ASC,
          field: SessionOrderField.STARTED_AT,
        },
      },
    },
  );
  useQueryErrorSnackbar(error, refetch);

  const columns: ColumnConfig<TableData>[] = [
    {
      id: 'startedAt' as const,
      defaultTrans: 'Date',
      getLabel: (startedAt: string) =>
        format(parseISO(startedAt), DATE_TIME_FORMAT),
    },
    {
      id: 'patientName' as const,
      defaultTrans: 'Patient',
      sortable: false,
      component: ({ patientName, patientId }) => (
        <TableCellLink
          to={`/admin/patients/${patientId}`}
          label={patientName}
        />
      ),
    },
    {
      id: 'scenes' as const,
      defaultTrans: 'Scenes',
      sortable: false,
    },
  ];

  const tableData = data?.sessions?.nodes?.map(getTableDataFromSession) || [];

  return (
    <>
      <BaseTypography variant="h6" mb={2}>
        {_t('table.title', 'Past sessions')}
      </BaseTypography>
      <SimpleTable<TableData, BaseRefetchTableVariables>
        id="vrdevice-sessions"
        rowId="id"
        translationPrefix="vrDeviceSession.table"
        isLoading={loading}
        data={tableData}
        columns={columns}
        renderRowActions={(rowData) => (
          <SessionRowActions
            session={rowData.session}
            refetch={refetch}
            variables={variables}
          />
        )}
        lazyConfig={{
          refetch: refetch,
          variables: variables,
          pageCursors: data?.sessions.pageCursors,
          totalCount: data?.sessions.totalCount,
        }}
      />
    </>
  );
};

export default VrDeviceSessions;
const getTableDataFromSession = (session: Session): TableData => ({
  id: session.id,
  startedAt: session.startedAt,
  patientName: joinStrings(
    ' ',
    session.user?.firstName,
    session.user?.lastName,
  ),
  scenes: joinStrings(
    ' | ',
    ...session.playlist.map((item) => item.scene.name_t),
  ),
  patientId: session.user?.id ?? '',
  session: session,
});
