import { Operation } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';
import * as Sentry from '@sentry/react';
import { GraphQLError } from 'graphql';
import { logout } from '../../auth';

var refreshTimeoutId: number | string | NodeJS.Timeout | null = null;

export const clearLoginRefreshTimeout = () => {
  if (refreshTimeoutId) {
    clearTimeout(refreshTimeoutId);
  }
  refreshTimeoutId = null;
};

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      if (handleGlobalError(error)) {
        clearLoginRefreshTimeout();
        refreshTimeoutId = setTimeout(() => window.location.reload(), 3000);
        return;
      }

      // this is in fact NOT an error object despite ts type so use captureMessage instead
      Sentry.withScope((scope) => {
        scope.setExtra('error', error);
        scope.setExtra('operation', {
          name: operation.operationName,
          variables: operation.variables,
        });
        Sentry.captureMessage(
          `GraphQL error: ${error.message}`,
          Sentry.Severity.Error,
        );
      });
      console.error(`[GraphQL error] ${operation.operationName}:`, error);
    });
  }
  if (networkError) {
    if (isSubscription(operation)) {
      // In subscriptions graphql errors can be networkError
      if (handleGlobalError(networkError as GraphQLError)) {
        return;
      }
    }

    Sentry.withScope((scope) => {
      scope.setExtra('operation', {
        name: operation.operationName,
        variables: operation.variables,
      });
      // Sometimes even networkError is not an error object
      if (networkError instanceof Error) {
        Sentry.captureException(networkError);
      } else {
        scope.setExtra('error', networkError);
        Sentry.captureMessage(
          `Network error: ${(networkError as any).message}`,
          Sentry.Severity.Error,
        );
      }
    });
    console.error(`[Network error] ${operation.operationName}:`, networkError);
  }
});

export default errorLink;

const HANDLED_GLOBAL_MESSAGES = ['Unauthorized'];
const UNAUTHORIZED_CODES = ['UNAUTHENTICATED', 'FORBIDDEN'];
const STATUS_CODES = [401, 403];

const handleGlobalError = (error: GraphQLError) => {
  const statusCode =
    error.extensions?.exception?.status ||
    error.extensions?.response?.statusCode;
  if (
    STATUS_CODES.includes(statusCode) &&
    (HANDLED_GLOBAL_MESSAGES.includes(error.message) ||
      UNAUTHORIZED_CODES.includes(error.extensions?.code))
  ) {
    logout();
    return true;
  }
  return false;
};

const isSubscription = (operation: Operation) => {
  const definition = getMainDefinition(operation.query);
  return (
    definition.kind === 'OperationDefinition' &&
    definition.operation === 'subscription'
  );
};
