import { BoxProps, styled } from '@mui/material';
import { useMemo } from 'react';
import { useTranslationPrefix } from '../../../../hooks/useTranslationPrefix';
import Chart from '../../../components/Charts/Chart';
import { lightColors } from '../../../theme';

interface Props extends BoxProps {
  data: SequenceData[];
}

interface SequenceData {
  name?: string | null;
  correctCount?: number | null;
  totalCount?: number | null;
}

const calcPercent = (data: SequenceData) => {
  const correctCount = data.correctCount || 0;
  const totalCount = data.totalCount || 1;
  const wrongCount = totalCount - correctCount;

  return {
    ...data,
    correctCount,
    totalCount,
    wrongCount,
    correctPercent: (correctCount / totalCount) * 100,
    wrongPercent: (wrongCount / totalCount) * 100,
  };
};

const formatPercent = (value: number, decimalPlaces = 2) => {
  const decimalMultiplier = decimalPlaces ? 10 ** decimalPlaces : 1;

  return `${
    Math.round(Math.abs(value) * decimalMultiplier) / decimalMultiplier
  } %`;
};

const TrainingResultChart = ({ data, ...boxProps }: Props) => {
  const { _t } = useTranslationPrefix('TrainingResultChart');
  const dataWithPercent = useMemo(
    () => data.map(calcPercent).reverse(),
    [data],
  );
  return (
    <TrainingResultChartWrapper
      {...boxProps}
      options={{
        grid: {
          left: '3%',
          right: '50px',
          bottom: '3%',
          top: '30px',
          containLabel: true,
        },
        dataset: {
          source: dataWithPercent,
        },
        legend: {
          selectedMode: false,
        },
        xAxis: {
          type: 'value',
          axisLabel: {
            formatter: formatPercent,
          },
          interval: 25,
          min: 0,
          max: 100,
        },
        yAxis: [
          {
            type: 'category',
          },
        ],

        series: [
          {
            name: _t('correct', 'Správně'),
            type: 'bar',
            stack: 'total',
            barGap: '80%',
            barCategoryGap: '30%',
            dimensions: [
              { name: 'name', type: 'category' },
              { name: 'correctPercent', type: 'number' },
            ],
            itemStyle: {
              color: lightColors.success.main,
            },
            label: {
              show: true,
              position: 'inside',
              formatter: ({ value }: { value: SequenceData }) =>
                value.correctCount || '',
              overflow: 'truncate',
            },
          },
          {
            name: _t('wrong', 'Špatně'),
            type: 'bar',
            stack: 'total',
            barGap: '80%',
            barCategoryGap: '30%',
            dimensions: [
              { name: 'name', type: 'category' },
              { name: 'wrongPercent', type: 'number' },
            ],
            itemStyle: {
              color: lightColors.error.main,
            },
            label: {
              show: true,
              position: 'inside',
              formatter: ({ value }: { value: any }) => value.wrongCount || '',
              overflow: 'truncate',
            },
          },
        ],
      }}
    />
  );
};

const TrainingResultChartWrapper = styled(Chart)`
  height: 200px;
`;

export default TrainingResultChart;
