import { useQuery } from '@apollo/client';
import { useState } from '@hookstate/core';
import { Button, Card } from '@mui/material';
import { Box, styled } from '@mui/system';
import { useNavigate } from 'react-router';
import { adminState } from '../../../adminState';
import { ReactComponent as VrCardboard } from '../../../assets/svg/vr-cardboard-solid.svg';
import { BaseTypography } from '../../../base/components/BaseTypography';
import { Flex } from '../../../base/components/Flex';
import {
  CreateSessionInput,
  CreateSessionMutationDocument,
  CreateSessionMutationMutation,
  CreateSessionPlaylistItemInput,
  GetLastLaunchableSessionsDocument,
  GetLastLaunchableSessionsQuery,
} from '../../../graphql/graphql-operations';
import { useMutationWithSnackbar } from '../../../hooks/useMutationWithSnackbar';
import useQueryErrorSnackbar from '../../../hooks/useQueryErrorSnackbar';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import { joinStrings } from '../../../lib/strings';

interface Props {
  vrDeviceId: string;
  userId: string;
}

type Session = NonNullable<
  GetLastLaunchableSessionsQuery['sessions']['nodes']
>[0];

const LaunchLastScenes = ({ userId, vrDeviceId }: Props) => {
  const { _t } = useTranslationPrefix('launchLastScenes');
  const navigate = useNavigate();
  const companyId = useState(adminState.selectedCompanyId);

  const { data, error, refetch } = useQuery(GetLastLaunchableSessionsDocument, {
    variables: {
      userId: userId,
      companyId: companyId.value,
    },
  });
  useQueryErrorSnackbar(error, refetch);

  const handleLaunchSuccess = ({
    createSession: sessions,
  }: CreateSessionMutationMutation) => {
    navigate(`/admin/vr-session/${sessions[0].id}`);
  };

  const [createSession, { loading: launching }] = useMutationWithSnackbar(
    'launchLastScenes.createSessionMutation',
    CreateSessionMutationDocument,
    { onCompleted: handleLaunchSuccess },
  );

  const handleClick = (session: Session) => () => {
    const scenes: CreateSessionPlaylistItemInput[] = session.playlist.map(
      (item) => ({
        sceneId: item.scene.id,
        params: item.params.map((param) => ({
          name: param.name,
          value: param.value,
        })),
      }),
    );
    const input: CreateSessionInput = {
      vrDeviceIds: [vrDeviceId],
      launch: true,
      scenes: scenes,
      userId: userId,
    };
    createSession({ variables: { createSessionInput: input } });
  };

  const sessions = data?.sessions.nodes || [];

  const renderSessionCard = (session: Session) => {
    return (
      <LaunchLastScenesSessionCard key={session.id}>
        <Flex flexDirection="column">
          {session.playlist.map((item) => {
            const params =
              item.params.map(
                (param) => `${param.name_t}:\u00a0${param.value_t}`,
              ) || [];

            return (
              <span key={item.id}>
                <LaunchLastScenesSessionCardLabel>
                  {item.scene.name_t}
                </LaunchLastScenesSessionCardLabel>
                {joinStrings(' | ', ...params)}
              </span>
            );
          })}
        </Flex>

        <Button
          id={`launch-last-scene-start-${session.id}`}
          size="medium"
          color="success"
          variant="contained"
          startIcon={<LaunchLastScenesIcon />}
          disabled={launching}
          onClick={handleClick(session)}
        >
          {_t('start', 'Start')}
        </Button>
      </LaunchLastScenesSessionCard>
    );
  };

  return sessions.length ? (
    <>
      <BaseTypography variant="h6" uppercase marginBottom={2}>
        {_t('previouslyUsed', 'Previously used')}
      </BaseTypography>
      <Box marginBottom={4}>{sessions.map(renderSessionCard)}</Box>
    </>
  ) : null;
};

export default LaunchLastScenes;

const LaunchLastScenesSessionCard = styled(Card)(
  ({ theme: { spacing } }) => `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${spacing(2)};
  padding: ${spacing(1)};
`,
);

const LaunchLastScenesSessionCardLabel = styled('b')`
  margin-right: 1ch;
`;

const LaunchLastScenesIcon = styled(VrCardboard)(
  ({ theme: { palette } }) => `
  height: 1em;
  fill: ${palette.success.contrastText};
`,
);
