import { BoxProps, styled } from '@mui/material';
import React from 'react';
import { usePrevious } from 'react-use';
import { formatTime } from '../../../../lib/date';
import { formatDistance } from '../../../../lib/distance';
import { formatDuration } from '../../../../lib/duration';
import Chart from '../../../components/Charts/Chart';

interface Props extends BoxProps {
  data: HeadStabilogramData[];
  view: HeadStabilogramView;
}

interface HeadStabilogramData {
  key: string;
  sceneId: string;
  sceneName: string;
  data: HeadStabilogramDataVector[];
}

interface HeadStabilogramDataVector {
  x: number;
  y: number;
  z: number;
  time: number;
}

export type HeadStabilogramView = 'vertical' | 'laterolateral' | 'horizontal';
const mapView = (
  data: HeadStabilogramDataVector,
  view: HeadStabilogramView,
): number[] => {
  switch (view) {
    case 'vertical':
      return [data.time, data.y];
    case 'laterolateral':
      return [data.time, -data.x];
    case 'horizontal':
      return [data.time, data.z];
  }
};

const HeadStabilogramChart = ({ data, view, ...boxProps }: Props) => {
  const prevData = usePrevious(data);

  const prepareSeries = (run: HeadStabilogramData) => {
    return {
      name: formatTime(run.key) + ' ' + run.sceneName,
      type: 'scatter',
      data: run.data.map((val) => mapView(val, view)),
      symbol: 'circle',
      symbolSize: 5,
    };
  };

  return (
    <HeadStabilogramPageWrapper
      {...boxProps}
      setOptionsBehaviour={{ notMerge: data !== prevData }}
      options={{
        legend: {
          bottom: 10,
        },
        tooltip: {
          trigger: 'axis',
          valueFormatter: (val: number) => formatDistance(val, ' ', true),
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        grid: [
          {
            containLabel: true,
            left: '3%',
            right: '4%',
            bottom: 80,
          },
        ],
        xAxis: [
          {
            gridIndex: 0,
            type: 'value',
            axisLabel: {
              formatter: (val: number) => {
                return formatDuration(val * 1000);
              },
            },
          },
        ],
        yAxis: [
          {
            gridIndex: 0,
            type: 'value',
            axisLabel: {
              formatter: (val: number) => {
                return formatDistance(val, ' ', true);
              },
            },
          },
        ],
        series: [...data.map((run) => prepareSeries(run))],
      }}
    />
  );
};

const HeadStabilogramPageWrapper = styled(Chart)`
  min-height: 330px;
  height: 38vh;
`;

export default React.memo(HeadStabilogramChart);
