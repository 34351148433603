import { Box, TextField, Typography } from '@mui/material';
import HtmlParser from '../HtmlParser';
import { useHelpFaq } from './provider';
import { HelpFaqContentItemTextField } from './types';

const HelpFaqItemTextField = ({
  item,
  multiline,
}: {
  item: HelpFaqContentItemTextField;
  multiline?: boolean;
}) => {
  const { updateValue } = useHelpFaq();
  const { title } = item;
  return (
    <Box width="100%">
      {title && (
        <Typography variant="h4" mb={4}>
          {title}
        </Typography>
      )}
      <Box sx={{ marginBottom: 2 }}>
        <HtmlParser html={item.text || ''} />
      </Box>
      <TextField
        id={item.name}
        variant="outlined"
        multiline={multiline}
        rows={multiline ? 5 : 1}
        sx={multiline ? { width: '100%' } : undefined}
        name={item.name}
        label={item.label}
        onChange={(e) => updateValue(item.name, e.target.value)}
      />
    </Box>
  );
};

export default HelpFaqItemTextField;
