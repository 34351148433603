import { Button, Card, CardContent, Typography } from '@mui/material';
import { Flex } from '../../../../base/components/Flex';
import { GetPlaylistTemplateFolderQuery } from '../../../../graphql/graphql-operations';
import { useTranslationPrefix } from '../../../../hooks/useTranslationPrefix';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ImageBackground } from '../../../../base/components/ImageBackground';

interface Props {
  data?: GetPlaylistTemplateFolderQuery;
  onOpenEdit: () => void;
  onDelete: () => void;
}

export const PlaylistTemplateFolderDetail = ({
  data,
  onOpenEdit,
  onDelete,
}: Props) => {
  const playlistTemplates = data?.folder.playlistTemplates;
  const firstTemplate = playlistTemplates?.[0];
  const playlistTemplatesCount = playlistTemplates?.length;
  const { _t } = useTranslationPrefix('PlaylistTemplateFolder');

  return (
    <Card sx={{ mb: 4 }}>
      <CardContent>
        <Flex flexDirection={{ xs: 'column', md: 'row' }} gap={3}>
          <Flex width={{ xs: '100%', md: '120px' }} height="100px">
            <ImageBackground src={firstTemplate?.items[0]?.scene?.imageUrl} />
          </Flex>
          <Flex flexDirection="column" flexGrow={1} justifyContent="center">
            <Typography variant="h5">{data?.folder.name}</Typography>
            <Flex alignItems="center" gap={0.5} mt={1}>
              <InfoOutlinedIcon />
              <Typography variant="caption">
                {_t('playlistCount', '{{count}} playlists', {
                  count: playlistTemplatesCount || 0,
                })}
              </Typography>
            </Flex>
          </Flex>
          <Flex alignItems="center" gap={2}>
            <Button
              id="playlist-template-folder-cancel"
              color="primary"
              variant="outlined"
              onClick={onOpenEdit}
            >
              {_t('action.rename', 'Rename')}
            </Button>
            <Button
              id="playlist-template-folder-confirm"
              color="error"
              variant="outlined"
              onClick={onDelete}
            >
              {_t('action.remove', 'Remove')}
            </Button>
          </Flex>
        </Flex>
      </CardContent>
    </Card>
  );
};
