import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import NoAccountsOutlinedIcon from '@mui/icons-material/NoAccountsOutlined';
import { ListItemIcon, ListItemText, MenuItem, styled } from '@mui/material';
import { useRef, useState } from 'react';
import { BaseTypography } from '../../base/components/BaseTypography';
import { Flex } from '../../base/components/Flex';
import { useUser } from '../../base/components/UserProvider/useUserHook';
import { useTranslationPrefix } from '../../hooks/useTranslationPrefix';
import { joinStrings } from '../../lib/strings';
import ClientUserMenu from './ClientUserMenu';

interface Props {
  onMenuClose: () => void;
}

const ClientUserProfile = ({ onMenuClose }: Props) => {
  const { _t, t } = useTranslationPrefix('ClientUserProfile');
  const { user } = useUser();
  const ref = useRef<HTMLLIElement>(null);
  const [useMenuOpen, setUserMenuOpen] = useState(false);
  const renderUserInfo = () => {
    return (
      <Flex flexDirection="column">
        <BaseTypography
          fontWeight={700}
          color="primary.main"
          lineHeight="1.25em"
        >
          {joinStrings(' ', user?.firstName, user?.lastName)}
        </BaseTypography>
        <BaseTypography variant="body3" color="grey.700" lineHeight="1.25em">
          {t(`enum.UserRole.${user?.role}`, user?.role)}
        </BaseTypography>
      </Flex>
    );
  };

  return (
    <>
      <ClientUserProfileStyled
        ref={ref}
        onClick={() => setUserMenuOpen((o) => !o)}
      >
        <ListItemIcon
          sx={{
            fontSize: '1.5rem',
            color: 'grey.400',
          }}
        >
          {user ? <AccountCircleOutlinedIcon /> : <NoAccountsOutlinedIcon />}
        </ListItemIcon>
        <ListItemText>
          {user ? renderUserInfo() : _t('noUserLoggedIn', 'Not logged in')}
        </ListItemText>
        {user && (
          <ListItemIcon
            sx={{
              fontSize: '1.5rem',
              color: 'primary.main',
            }}
          >
            <KeyboardArrowRightRoundedIcon />
          </ListItemIcon>
        )}
      </ClientUserProfileStyled>
      <ClientUserMenu
        anchorEl={ref.current}
        open={useMenuOpen}
        onClose={() => {
          setUserMenuOpen(false);
          onMenuClose();
        }}
      />
    </>
  );
};

export default ClientUserProfile;

const ClientUserProfileStyled = styled(MenuItem)(
  ({ theme: { palette, spacing } }) => `
    min-height: ${spacing(9.5)} !important;
    padding-top: ${spacing(2)};
    padding-bottom: ${spacing(2)};
    
    padding-left: ${spacing(4)};
    background-color: ${palette.grey[50]};
`,
);
