import { useQuery } from '@apollo/client';
import { Box, Button, Card, CardContent, Divider } from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { ObjectShape } from 'yup/lib/object';
import { Flex } from '../../../base/components/Flex';
import { FormikFormProvider } from '../../../base/components/form/FormikForm';
import FormikTextField from '../../../base/components/form/FormikTextField';
import { useUser } from '../../../base/components/UserProvider/useUserHook';
import {
  GetUserForPasswordChangeDocument,
  ResetPasswordDocument,
} from '../../../graphql/graphql-operations';
import { useMutationWithSnackbar } from '../../../hooks/useMutationWithSnackbar';
import useQueryErrorSnackbar from '../../../hooks/useQueryErrorSnackbar';
import {
  TFunctionPrefixed,
  useTranslationPrefix,
} from '../../../hooks/useTranslationPrefix';
import { handleValidationFieldError } from '../../../lib/handleValidationFieldError';
import ContentWrapper from '../../components/ContentWrapper';
import { Loader } from '../../components/Loader';
import PageHeader from '../../components/PageHeader';

export const PASSWORD_REGEX = /^(?=.*[A-Z])(?=.*[a-z])(?=.*?[0-9]).*$/;

interface FormData {
  password: string;
  newPassword: string;
  newPassword2: string;
}

const UserChangePasswordPage = () => {
  const { userId } = useParams<{ userId: string }>();
  const { _t, t } = useTranslationPrefix('UserChangePasswordPage');
  const navigate = useNavigate();

  const { user: currentUser } = useUser();
  const { data, loading, error, refetch } = useQuery(
    GetUserForPasswordChangeDocument,
    {
      variables: { userId },
      skip: !userId,
    },
  );
  useQueryErrorSnackbar(error, refetch);
  const user = data?.user;
  const requireOldPassword = !!(
    user?.hasPassword && user?.id === currentUser?.id
  );

  const [resetPassword] = useMutationWithSnackbar(
    'UserChangePasswordPage.mutation',
    ResetPasswordDocument,
  );

  const handleSubmit = async (
    values: FormData,
    formikHelpers: FormikHelpers<FormData>,
  ) => {
    if (userId) {
      try {
        const { data } = await resetPassword({
          variables: {
            resetPassword: {
              userId,
              newPassword: values.newPassword,
              password: values.password || null,
            },
          },
        });
        if (!data) {
          return;
        }
        if (userId === currentUser?.id) {
          window.location.reload();
        } else {
          navigate(-1);
        }
      } catch (error: any) {
        handleValidationFieldError({ error, formikHelpers, t });
      }
    }
  };

  const formik = useFormik<FormData>({
    initialValues: { password: '', newPassword: '', newPassword2: '' },
    onSubmit: handleSubmit,
    validationSchema: getValidationSchema(_t, requireOldPassword),
  });

  return loading ? (
    <Loader />
  ) : (
    <ContentWrapper>
      <PageHeader
        back={userId === currentUser?.id ? '/' : true}
        title={_t('title', 'Change password')}
      ></PageHeader>
      <Card
        sx={{
          width: '100%',
          maxWidth: '38rem',
          alignSelf: 'center',
          flexShrink: 0,
        }}
      >
        <CardContent>
          <FormikFormProvider formik={formik}>
            <Box
              sx={{
                display: 'grid',
                gap: 3,
                margin: 'auto',
                p: 3,
              }}
            >
              {requireOldPassword && (
                <>
                  <FormikTextField
                    name="password"
                    type="password"
                    autoComplete="password"
                    label={_t('currentPassword', 'Current password')}
                  />
                  <Divider />
                </>
              )}
              <FormikTextField
                name="newPassword"
                type="password"
                autoComplete="new-password"
                label={_t('newPassword', 'New password')}
              />
              <FormikTextField
                name="newPassword2"
                type="password"
                autoComplete="new-password"
                label={_t('newPasswordAgain', 'New password again')}
              />
              <Flex justifyContent="space-around" pt={1}>
                <Button id="submit" type="submit" variant="contained">
                  {_t('submit', 'Save')}
                </Button>
              </Flex>
            </Box>
          </FormikFormProvider>
        </CardContent>
      </Card>
    </ContentWrapper>
  );
};

export default UserChangePasswordPage;

const getValidationSchema = (
  _t: TFunctionPrefixed,
  requireOldPassword: boolean,
) => {
  const passwordValidation = Yup.string()
    .min(8, _t('form.passwordMin', 'Minimal number of characters is 8'))
    .required(_t('form.passwordRequired', 'Password is required'))
    .matches(
      PASSWORD_REGEX,
      _t(
        'form.passwordRegex',
        'Password must have at least 1 uppercase, 1 lower case character and 1 digit',
      ),
    );

  const newPasswordPart: ObjectShape = {
    newPassword: passwordValidation,
    newPassword2: passwordValidation.oneOf(
      [Yup.ref('newPassword')],
      _t('form.passwordMismatch', 'Password does not match'),
    ),
  };

  const oldPasswordPart = {
    password: Yup.string().required(
      _t('form.passwordRequired', 'Password is required'),
    ),
  };

  return Yup.object({
    ...newPasswordPart,
    ...(requireOldPassword ? oldPasswordPart : {}),
  });
};
