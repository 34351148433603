import { Route, Routes } from 'react-router-dom';
import NotFoundPage from '../NotFoundPage/NotFoundPage';
import HeadRotationReportPage from './HeadRotationReportPage';
import HeadStabilogramPage from './HeadStabilogramPage';
import HeadStatokinesiogramPage from './HeadStatokinesiogramPage';
import UpperLimbMovementBackViewPage from './UpperLimbMovementViewPage';
import UserArmsFlexReportPage from './UserArmsFlexReportPage';
import UserFineMotoricsReportPage from './UserFineMotoricsReportPage';
import UserRunTimeReportPage from './UserRunTimeReportPage';
import UserShoulderHorizontalAbductionPage from './UserShoulderHorizontalAbductionPage';
import UserShoulderRangeReportPage from './UserShoulderRangeReportPage';
import UserTimeReactionReportPage from './UserTimeReactionReportPage';
import UserUpperLimbActivityReportPage from './UserUpperLimbActivityReportPage';

export enum UserReportPage {
  RUNTIME = '/', //1
  SHOULDERS = '/shoulders-range', //2
  SHOULDERS_FLEX = '/shoulders-flex-range', //3
  SHOULDERS_HORIZONTAL_ABDUCTION = '/shoulders-horizontal-abdruction', //4
  UPPER_LIMB_ACTIVITY = '/upper-limb-activity', //5
  UPPER_LIMB_REACTION_TIME = '/upper-limb-reaction-time', //6
  UPPER_LIMB_MOVEMENT_VIEW = '/upper-limb-movement-view', //7 + 8
  HEAD_STABILOGRAM = '/head-stabilogram', //9 + 10
  HEAD_STATOKINEZIOGRAM = '/head-statokineziogram', //11
  FINE_MOTORICS = '/fine-motorics', //12
  CERVICAL_SPINE = '/cervical-spine', //13, 14, 15
}

const UserReportsRoute = () => {
  return (
    <Routes>
      <Route
        path={UserReportPage.RUNTIME}
        element={<UserRunTimeReportPage />}
      />
      <Route
        path={UserReportPage.SHOULDERS}
        element={<UserShoulderRangeReportPage />}
      />
      <Route
        path={UserReportPage.SHOULDERS_FLEX}
        element={<UserArmsFlexReportPage />}
      />
      <Route
        path={UserReportPage.SHOULDERS_HORIZONTAL_ABDUCTION}
        element={<UserShoulderHorizontalAbductionPage />}
      />
      <Route
        path={UserReportPage.UPPER_LIMB_ACTIVITY}
        element={<UserUpperLimbActivityReportPage />}
      />
      <Route
        path={UserReportPage.UPPER_LIMB_REACTION_TIME}
        element={<UserTimeReactionReportPage />}
      />
      <Route
        path={UserReportPage.UPPER_LIMB_MOVEMENT_VIEW}
        element={<UpperLimbMovementBackViewPage />}
      />

      <Route
        path={UserReportPage.HEAD_STABILOGRAM}
        element={<HeadStabilogramPage />}
      />

      <Route
        path={UserReportPage.HEAD_STATOKINEZIOGRAM}
        element={<HeadStatokinesiogramPage />}
      />
      <Route
        path={UserReportPage.FINE_MOTORICS}
        element={<UserFineMotoricsReportPage />}
      />
      <Route
        path={UserReportPage.CERVICAL_SPINE}
        element={<HeadRotationReportPage />}
      />

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default UserReportsRoute;
