import { Button } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { BaseDialog } from '../../../base/components/BaseDialog';
import { Flex } from '../../../base/components/Flex';
import FormikDatePicker from '../../../base/components/form/FormikDatePicker';
import FormikForm from '../../../base/components/form/FormikForm';
import FormikTextField from '../../../base/components/form/FormikTextField';
import { StreamType } from '../../../graphql/graphql-operations';
import {
  MEDICAL_ASSIGNMENT_GRACE_PERIOD_DEFAULT,
  MEDICAL_ASSIGNMENT_VALID_FOR_DEFAULT,
  TRAINING_ASSIGNMENT_GRACE_PERIOD_DEFAULT,
  TRAINING_ASSIGNMENT_VALID_FOR_DEFAULT,
} from '../../../lib/constants';
import useStreamType from '../../../lib/useStreamType';

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: (
    values: DebugTrainingAssignmentFormValues,
  ) => void | Promise<unknown>;
}

export interface DebugTrainingAssignmentFormValues {
  validForDays: number;
  gracePeriodDays: number;
  validTo: Date | null;
}

const DebugTrainingAssignmentDialog = ({ open, onClose, onConfirm }: Props) => {
  const streamType = useStreamType();

  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      title="$Debug Training Assignment"
    >
      <Formik<DebugTrainingAssignmentFormValues>
        initialValues={{
          gracePeriodDays:
            streamType === StreamType.MEDICAL
              ? MEDICAL_ASSIGNMENT_GRACE_PERIOD_DEFAULT
              : TRAINING_ASSIGNMENT_GRACE_PERIOD_DEFAULT,
          validForDays:
            streamType === StreamType.MEDICAL
              ? MEDICAL_ASSIGNMENT_VALID_FOR_DEFAULT
              : TRAINING_ASSIGNMENT_VALID_FOR_DEFAULT,
          validTo: null,
        }}
        validationSchema={getValidationSchema()}
        onSubmit={onConfirm}
      >
        <FormikForm>
          <FormikTextField
            type="number"
            label="Valid for days"
            name="validForDays"
          />

          <FormikTextField
            type="number"
            label="GracePeriodDays"
            name="gracePeriodDays"
          />

          <FormikDatePicker
            variant="outlined"
            label="Valid to"
            name="validTo"
          />
          <Flex justifyContent="center" pt={2}>
            <Button
              id="save-training-assignment-debug"
              variant="contained"
              type="submit"
            >
              Confirm
            </Button>
          </Flex>
        </FormikForm>
      </Formik>
    </BaseDialog>
  );
};

export default DebugTrainingAssignmentDialog;

const getValidationSchema = () =>
  Yup.object({
    validForDays: Yup.number().min(1).required('Required'),
    gracePeriodDays: Yup.number().min(1).required('Required'),
    validTo: Yup.date().nullable().min(new Date()),
  });
