import { indexBy } from 'ramda';
import { useMemo, useState } from 'react';
import useActiveCompanies from '../../../hooks/useActiveCompanies';
import { compareByProp } from '../../../lib/compare';

export interface TreeCompany {
  id: string;
  parentCompanyId: string | null;
  name: string;
  expanded: boolean;
  subCompanies: TreeCompany[];
}

const fixCompanyExpanstion = (
  companies: TreeCompany[],
  expanded: string[],
): TreeCompany[] => {
  if (!companies?.length) return [];
  return companies.map((c) => ({
    ...c,
    expanded: expanded.includes(c.id),
    subCompanies: fixCompanyExpanstion(c.subCompanies, expanded),
  }));
};

const useCompanyTree = () => {
  const companiesRaw = useActiveCompanies();
  const [expanded, setExpanded] = useState<string[]>([]);

  const { companies: companyTemplates, rootCompanyIds } = useMemo(() => {
    const companies: TreeCompany[] = companiesRaw
      .map((c) => ({
        id: c.id,
        parentCompanyId: c.parentCompanyId || null,
        name: c.name,
        expanded: false,
        subCompanies: [],
      }))
      .sort(compareByProp('name'));
    let rootCompanyIds = companies.map((c) => c.id);

    const indexedCompanies = indexBy((c) => c.id, companies);
    for (const company of companies) {
      if (
        !company.parentCompanyId ||
        !indexedCompanies[company.parentCompanyId]
      ) {
        continue;
      }
      const parent = indexedCompanies[company.parentCompanyId];
      parent.subCompanies.push(company);
      rootCompanyIds = rootCompanyIds.filter((cid) => cid !== company.id);
    }

    return { companies, rootCompanyIds };
  }, [companiesRaw]);

  const companies = useMemo(
    () => fixCompanyExpanstion(companyTemplates, expanded),
    [companyTemplates, expanded],
  );
  const rootCompanies = useMemo(
    () => companies.filter((c) => rootCompanyIds.includes(c.id)),
    [companies, rootCompanyIds],
  );

  const toggleCompany = (companyId: string) => {
    setExpanded((e) =>
      e.includes(companyId)
        ? e.filter((c) => c !== companyId)
        : [...e, companyId],
    );
  };

  return { companies, rootCompanies, toggleCompany };
};

export default useCompanyTree;
