import { useQuery } from '@apollo/client';
import { Card, CardContent } from '@mui/material';
import { useCallback } from 'react';
import { Flex } from '../../../base/components/Flex';
import {
  GetUserRuntimeReportDocument,
  GetUserRuntimeReportSeriesDocument,
  SeriesAggregationInterval,
} from '../../../graphql/graphql-operations';
import useQueryErrorSnackbar from '../../../hooks/useQueryErrorSnackbar';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import { getEndOfDay } from '../../../lib/date';
import { formatDuration } from '../../../lib/duration';
import { UpdateAxisPointerEvent } from '../../components/Charts/ChartTypes';
import ContentWrapper from '../../components/ContentWrapper';
import { LabeledValue } from '../../components/LabeledValue';
import { Loader } from '../../components/Loader';
import PageHeader from '../../components/PageHeader';
import { UserReportPage } from './UserReportsRoute';
import ReportCard from './components/ReportCard';
import ReportNav from './components/ReportNav';
import RunTimeProgressChart from './components/RunTimeProgressChart';
import RunTimeProgressGuage from './components/RunTimeProgressGuage';
import useDebouncedQuery from './utils/useDeboucedQuery';
import { useReportUser } from './utils/useReportUser';

const UserRunTimeReportPage = () => {
  const { _t } = useTranslationPrefix('UserReportPage');
  const { userId, userData, userLoading, backUrl } = useReportUser();

  const { data: reportData, refetch: reportRefetch } = useDebouncedQuery(
    GetUserRuntimeReportDocument,
    {
      variables: {
        userId: userId!,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      skip: !userId,
    },
  );

  const {
    data: seriesData,
    loading: seriesLoading,
    error: seriesError,
    refetch: seriesRefetch,
  } = useQuery(GetUserRuntimeReportSeriesDocument, {
    variables: {
      userId: userId!,
      seriesInterval: SeriesAggregationInterval.day,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    skip: !userId,
  });
  useQueryErrorSnackbar(seriesError, seriesRefetch);

  const updateDate = useCallback(
    (e: UpdateAxisPointerEvent) => {
      const value = e.axesInfo.find((i) => i.axisDim === 'x')?.value;
      if (value) {
        reportRefetch({
          toDate: getEndOfDay(value).toISOString(),
        });
      }
    },
    [reportRefetch],
  );

  const loading = userLoading || !reportData || seriesLoading;
  return (
    <ContentWrapper>
      <PageHeader
        title={_t('title', 'Report {{firstName}} {{lastName}}', {
          firstName: userData?.user.firstName,
          lastName: userData?.user.lastName,
        })}
        back={backUrl}
      ></PageHeader>
      <ReportNav currentPage={UserReportPage.RUNTIME} />
      {loading ? (
        <Loader />
      ) : (
        <>
          <Flex flexDirection="column" gap={2}>
            <Flex sx={{ flexDirection: { xs: 'column', md: 'row' }, gap: 2 }}>
              <Flex
                sx={{
                  flexDirection: { xs: 'row', md: 'column' },
                  gap: 2,
                  flexGrow: 2,
                }}
              >
                <Card
                  sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}
                >
                  <CardContent sx={{ paddingLeft: '2.25rem' }}>
                    <LabeledValue
                      label={_t('sessionCount', 'Počet cvičení')}
                      value={reportData?.userReports.sessionCount}
                    />
                  </CardContent>
                </Card>

                <Card
                  sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}
                >
                  <CardContent sx={{ paddingLeft: '2.25rem' }}>
                    <LabeledValue
                      label={_t('gameTimeSeconds', 'Odcvičený čas')}
                      value={
                        <div style={{ minWidth: '175px' }}>
                          {formatDuration(
                            reportData!.userReports.gameTimeSeconds * 1000,
                          )}
                        </div>
                      }
                    />
                  </CardContent>
                </Card>
              </Flex>
              <ReportCard
                sx={{ flexGrow: 3 }}
                header={{
                  title: _t(
                    'gameTimeLastSession',
                    'Míra plnění terapeutického plánu',
                  ),
                }}
              >
                <RunTimeProgressGuage
                  data={reportData?.userReports.gameTimeLastSession}
                />
              </ReportCard>
            </Flex>
            <ReportCard
              header={{
                title: _t('runTimeChartTitle', 'Časový průběh cvičení'),
              }}
            >
              <RunTimeProgressChart
                data={seriesData!.userReports.gameTimeProgressSeries}
                onAxisPointerChange={updateDate}
              />
            </ReportCard>
          </Flex>
        </>
      )}
    </ContentWrapper>
  );
};

export default UserRunTimeReportPage;
