import { Card } from '@mui/material';
import { styled, useTheme } from '@mui/system';
import { useState } from 'react';
import { BaseTypography } from '../../../base/components/BaseTypography';
import { Flex } from '../../../base/components/Flex';
import { VrDeviceSceneGroupFragmentFragment } from '../../../graphql/graphql-operations';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import SilhouetteLocationSelect from '../SilhouetteLocationSelect/SilhouetteLocationSelect';
import VerticalSeparator from '../VerticalSeparator/VerticalSeparator';
import LaunchLastScenes from './LaunchLastScenes';

interface Props {
  groups: SceneGroup[];
  vrDviceId?: string;
  userId?: string;
  onGroupSelected: (group: SceneGroup) => void;
}

type SceneGroup = VrDeviceSceneGroupFragmentFragment;

const SelectApplicationGroup = ({
  onGroupSelected,
  groups,
  userId,
  vrDviceId,
}: Props) => {
  const { _t } = useTranslationPrefix('launchSceneDialog.selectGroup');
  const { spacing } = useTheme();
  const [selectedMapPointId, setSelectedMapPointId] = useState<number>();

  const noMapSelection =
    groups.filter((group) => !group.mapSelectionEnabled) || [];
  const selectedOnMap = groups.find(
    (group) => group.mapSelectionId === selectedMapPointId,
  );

  const renderPoint = (pointId: number) => {
    return !!groups.find((group) => group.mapSelectionId === pointId);
  };

  const handleSelectGroup = (group?: SceneGroup) => () => {
    group && onGroupSelected(group);
  };

  const renderGroupCard = (group: SceneGroup) => {
    return (
      <SelectApplicationGroupCard
        key={group.id}
        onClick={handleSelectGroup(group)}
      >
        {group.name}
      </SelectApplicationGroupCard>
    );
  };

  return (
    <>
      {userId && vrDviceId && (
        <>
          <LaunchLastScenes
            userId={userId}
            vrDeviceId={vrDviceId}
          ></LaunchLastScenes>
          <BaseTypography variant="h6" uppercase marginBottom={4}>
            {_t('startNew', 'Start new program')}
          </BaseTypography>
        </>
      )}
      <Flex flexDirection="row" justifyContent="center">
        <Flex alignItems="start" flexBasis="50%" justifyContent="center">
          <SilhouetteLocationSelect
            onChange={setSelectedMapPointId}
            selectedPointId={selectedMapPointId}
            pointsFilter={renderPoint}
          />
          <SelectApplicationGroupSilhuetteCard
            disabled={!selectedOnMap}
            onClick={handleSelectGroup(selectedOnMap)}
          >
            {selectedOnMap ? (
              selectedOnMap?.name
            ) : (
              <BaseTypography variant="caption" fontStyle="italic">
                {_t('selectBodypart', 'Select bodypart')}
              </BaseTypography>
            )}
          </SelectApplicationGroupSilhuetteCard>
        </Flex>
        <VerticalSeparator label={_t('or', 'or')} />
        <Flex
          paddingLeft={spacing(2)}
          alignItems="baseline"
          minWidth="240px"
          flexBasis="50%"
        >
          <Flex flexDirection="column">
            {noMapSelection.map(renderGroupCard)}
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

const SelectApplicationGroupCard = styled(Card)<{ disabled?: boolean }>(
  ({ disabled, theme: { spacing, shadows } }) => `
    padding: ${spacing(3)};
    margin-bottom: ${spacing(2)};
    font-weight: bold;
    ${
      !disabled &&
      `
      cursor: pointer;
      :hover {
          box-shadow: ${(shadows as string[])[5]}
      }
    `
    };
`,
);

const SelectApplicationGroupSilhuetteCard = styled(SelectApplicationGroupCard)`
  margin-left: -50px;
  width: 18ch;
  text-align: center;
`;

export default SelectApplicationGroup;
