export const formatDuration = (durationMs: number, space: string = ' ') => {
  let d, h, m, s;
  d = Math.floor(durationMs / 1000 / 60 / 60 / 24);
  h = Math.floor((durationMs / 1000 / 60 / 60 / 24 - d) * 24);
  m = Math.floor(((durationMs / 1000 / 60 / 60 / 24 - d) * 24 - h) * 60);
  s = Math.floor(
    (((durationMs / 1000 / 60 / 60 / 24 - d) * 24 - h) * 60 - m) * 60,
  );

  const secondsText = `${s.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
  })}s`;

  return d
    ? `${d}d${space}${h}h${space}${m}m`
    : h
    ? `${h}h${space}${m}m${space}${secondsText}`
    : m
    ? `${m}m${space}${secondsText}`
    : secondsText;
};

export const formatDurationMinutesShort = (durationMs: number) => {
  let m, s;
  m = Math.floor(durationMs / 1000 / 60);
  s = Math.floor(durationMs / 1000) - m * 60;
  return `${m}:${s.toLocaleString('en-US', { minimumIntegerDigits: 2 })}`;
};
