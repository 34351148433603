import { useMutation } from '@apollo/client';
import { Button } from '@mui/material';
import { Field, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { useTranslation } from 'react-i18next';
import { BaseDialog } from '../../../base/components/BaseDialog';
import { Flex } from '../../../base/components/Flex';
import FormikCheckBox from '../../../base/components/form/FormikCheckBox';
import FormikFileSelect from '../../../base/components/form/FormikFileSelect';
import FormikForm from '../../../base/components/form/FormikForm';
import {
  EditApplicationSceneDocument,
  SceneFragmentFragment,
} from '../../../graphql/graphql-operations';
import useMutationSnackbar from '../../../hooks/useMutationSnackbar';

interface Props {
  open: boolean;
  scene: SceneFragmentFragment;
  applicationVersionId: string;
  onClose: () => void;
}

interface FormData {
  enabled: boolean;
  name_t: string;
  description: string;
  file?: File;
}

const ApplicationSceneEditDialog = ({
  open,
  scene,
  onClose,
  applicationVersionId,
}: Props) => {
  const { t } = useTranslation();
  const editSceneHandler = useMutationSnackbar('editApplicationScene', {
    translationKey: 'applications.scene.editApplicationScene',
    onSuccessfullyCompleted: () => onClose(),
  });

  const [editMutation] = useMutation(EditApplicationSceneDocument, {
    ...editSceneHandler,
  });

  const handleSubmit = async ({ file, ...rest }: FormData) => {
    await editMutation({
      variables: {
        scene: {
          applicationSceneId: scene?.id,
          applicationVersionId: applicationVersionId,
          ...rest,
        },
        image: file,
      },
    });
  };
  return (
    <BaseDialog
      open={open}
      title={t('applications.scene.editDialog.title', 'Edit scene')}
      onClose={onClose}
    >
      <Formik<FormData>
        initialValues={{
          enabled: !!scene?.enabled,
          name_t: scene.name_t,
          description: scene.description,
        }}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, setFieldValue, values }) => (
          <FormikForm>
            <FormikFileSelect
              name="file"
              label={t(
                'applications.scene.editDialog.previewImage',
                'Preview image',
              )}
              accept="image/*"
              disabled={isSubmitting}
              color="primary"
            />
            <Field
              component={TextField}
              name="name_t"
              variant="outlined"
              label={t('applications.scene.editDialog.name', 'Name')}
            />
            <Field
              component={TextField}
              name="description"
              variant="outlined"
              label={t(
                'applications.scene.editDialog.description',
                'Description',
              )}
              InputProps={{ minRows: 4, maxRows: 10, multiline: true }}
            />
            <FormikCheckBox
              name="enabled"
              label={t('applications.scene.editDialog.enabled', 'Enabled')}
            />
            <Flex justifyContent="space-between">
              <Button
                id="application-scene-edit-dialog-cancel"
                size="large"
                color="primary"
                onClick={onClose}
              >
                {t('common.cancel', 'Cancel')}
              </Button>
              <Button
                id="application-scene-edit-dialog-confirm"
                type="submit"
                size="large"
                color="primary"
                variant="contained"
                disabled={isSubmitting}
              >
                {t('applications.scene.editDialog.save', 'Save')}
              </Button>
            </Flex>
          </FormikForm>
        )}
      </Formik>
    </BaseDialog>
  );
};

export default ApplicationSceneEditDialog;
