import { useMutation } from '@apollo/client';
import { Button } from '@mui/material';
import { Field, Formik } from 'formik';
import { TextField } from 'formik-mui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BaseDialog } from '../../../base/components/BaseDialog';
import { Flex } from '../../../base/components/Flex';
import FormikForm from '../../../base/components/form/FormikForm';
import { EditCompanyDocument } from '../../../graphql/graphql-operations';
import useMutationSnackbar from '../../../hooks/useMutationSnackbar';

interface Props {
  open: boolean;
  onClose: () => void;
  currentNote?: string;
  companyId: string;
}

interface EditCompanyForm {
  note: string;
}

const EditCompanyNoteDialog: React.VFC<Props> = (props: Props) => {
  const { open, onClose, currentNote, companyId } = props;
  const { t } = useTranslation();
  const editCompanyHandler = useMutationSnackbar('editCompany', {
    translationKey: 'editCompanyDialog',
    onSuccessfullyCompleted: onClose,
  });

  const [editCompany] = useMutation(EditCompanyDocument, editCompanyHandler);
  const handleFormSubmit = async ({ note }: EditCompanyForm) => {
    editCompany({
      variables: {
        editCompany: {
          companyId,
          note,
        },
      },
    });
  };

  return (
    <BaseDialog
      open={open}
      title={t('editCompanyDialog.title')}
      onClose={onClose}
    >
      <Formik<EditCompanyForm>
        initialValues={{ note: currentNote ? currentNote : '' }}
        onSubmit={handleFormSubmit}
      >
        {({ isSubmitting, isValid }) => (
          <FormikForm>
            <Field
              component={TextField}
              name="note"
              variant="outlined"
              style={{ width: '320px' }}
              InputProps={{ minRows: 4, maxRows: 10, multiline: true }}
            />
            <Flex justifyContent="space-between">
              <Button
                id="edit-company-note-cancel"
                size="large"
                color="primary"
                onClick={onClose}
              >
                {t('common.cancel', 'Cancel')}
              </Button>
              <Button
                id="edit-company-note-confirm"
                type="submit"
                size="large"
                color="primary"
                variant="contained"
                disabled={isSubmitting || !isValid}
              >
                {t('editCompanyDialog.save', 'Save')}
              </Button>
            </Flex>
          </FormikForm>
        )}
      </Formik>
    </BaseDialog>
  );
};

export default EditCompanyNoteDialog;
