import { useSnackbar } from 'notistack';
import { VrDeviceType } from '../../../base/components/VrDevicesProvider/VrDevicesProvider';
import {
  DeviceState,
  UpdateAppsOnVrDeviceDocument,
} from '../../../graphql/graphql-operations';
import { useMutationWithSnackbar } from '../../../hooks/useMutationWithSnackbar';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';

const useDeviceUpdate = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { _t } = useTranslationPrefix('deviceUpdate');

  const [updateMutation] = useMutationWithSnackbar(
    'deviceUpdate.mutation',
    UpdateAppsOnVrDeviceDocument,
  );

  const checkDeviceUpdatable = (device: VrDeviceType) => {
    if (device.upToDate) {
      return false;
    }
    if (device.status.state !== DeviceState.ONLINE) {
      enqueueSnackbar(
        _t(`deviceMustBeOnline`, 'Device must be online for update.'),
        {
          variant: 'error',
          key: 'useDeviceUpdate-deviceMustBeOnline',
          preventDuplicate: true,
        },
      );
      return false;
    }
    return true;
  };

  const doVrDeviceUpdate = async (device: VrDeviceType) => {
    const updatable = checkDeviceUpdatable(device);
    if (updatable) {
      await updateMutation({
        variables: {
          vrDeviceId: device.id,
        },
      });
    }
  };

  return { checkDeviceUpdatable, doVrDeviceUpdate };
};

export default useDeviceUpdate;
