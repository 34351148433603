import {
  Card,
  CardContent,
  CardHeader,
  CardHeaderProps,
  CardProps,
} from '@mui/material';

interface Props extends CardProps {
  header?: CardHeaderProps;
}

const ReportCard = ({ children, header, ...cardprops }: Props) => {
  return (
    <Card {...cardprops}>
      {header && (
        <CardHeader
          {...header}
          sx={{ paddingBottom: 0, ...(header.sx || {}) }}
        />
      )}
      <CardContent>{children}</CardContent>
    </Card>
  );
};

export default ReportCard;
