import { SvgIcon, SvgIconProps } from '@mui/material';

const GbIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#F0F0F0"
      />
      <path
        d="M2.48069 4.69299C1.53808 5.91938 0.827311 7.3329 0.413452 8.86848H6.65617L2.48069 4.69299Z"
        fill="#0052B4"
      />
      <path
        d="M23.587 8.86863C23.1732 7.3331 22.4624 5.91958 21.5198 4.69319L17.3444 8.86863H23.587Z"
        fill="#0052B4"
      />
      <path
        d="M0.413452 15.1302C0.827359 16.6657 1.53812 18.0793 2.48069 19.3056L6.65603 15.1302H0.413452Z"
        fill="#0052B4"
      />
      <path
        d="M19.3055 2.47977C18.0791 1.53716 16.6657 0.826397 15.1301 0.412491V6.65517L19.3055 2.47977Z"
        fill="#0052B4"
      />
      <path
        d="M4.69394 21.5177C5.92033 22.4603 7.33385 23.1711 8.86938 23.585V17.3423L4.69394 21.5177Z"
        fill="#0052B4"
      />
      <path
        d="M8.86933 0.412491C7.3338 0.826397 5.92028 1.53716 4.69394 2.47972L8.86933 6.65511V0.412491Z"
        fill="#0052B4"
      />
      <path
        d="M15.1301 23.585C16.6656 23.1711 18.0791 22.4603 19.3055 21.5177L15.1301 17.3423V23.585Z"
        fill="#0052B4"
      />
      <path
        d="M17.3444 15.1302L21.5198 19.3056C22.4624 18.0793 23.1732 16.6657 23.587 15.1302H17.3444Z"
        fill="#0052B4"
      />
      <path
        d="M23.8984 10.4348H13.5653H13.5653V0.101578C13.0529 0.034875 12.5305 0 12 0C11.4694 0 10.9471 0.034875 10.4348 0.101578V10.4347V10.4347H0.101578C0.034875 10.9471 0 11.4695 0 12C0 12.5306 0.034875 13.0529 0.101578 13.5652H10.4347H10.4347V23.8984C10.9471 23.9651 11.4694 24 12 24C12.5305 24 13.0529 23.9652 13.5652 23.8984V13.5653V13.5653H23.8984C23.9651 13.0529 24 12.5306 24 12C24 11.4695 23.9651 10.9471 23.8984 10.4348Z"
        fill="#D80027"
      />
      <path
        d="M15.1304 15.1313L20.4852 20.4861C20.7315 20.2399 20.9665 19.9825 21.1906 19.7157L16.6062 15.1312H15.1304V15.1313Z"
        fill="#D80027"
      />
      <path
        d="M8.86954 15.1312H8.86944L3.51468 20.486C3.76087 20.7323 4.01826 20.9672 4.28507 21.1913L8.86954 16.6068V15.1312Z"
        fill="#D80027"
      />
      <path
        d="M8.86936 8.86913V8.86903L3.51455 3.51418C3.26827 3.76036 3.03333 4.01775 2.80917 4.28457L7.39369 8.86908H8.86936V8.86913Z"
        fill="#D80027"
      />
      <path
        d="M15.1304 8.87019L20.4853 3.51528C20.2391 3.269 19.9817 3.03406 19.7149 2.80995L15.1304 7.39447V8.87019Z"
        fill="#D80027"
      />
    </SvgIcon>
  );
};

export default GbIcon;
