import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { Box, Button, styled } from '@mui/material';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Flex } from '../../base/components/Flex';
import { StreamLogo } from '../../base/components/StreamLogo';
import { TopBarCompanySelector } from '../../base/components/TopBarCompanySelector';
import HasAnyPermission from '../../base/components/UserProvider/HasAnyPermission';
import { UserPermission } from '../../graphql/graphql-operations';
import { useTranslationPrefix } from '../../hooks/useTranslationPrefix';
import ClientSideMenu from './ClientSideMenu';

const ClientTopBar = () => {
  const { _t } = useTranslationPrefix('ClientTopBar');
  const [sideMenuOpen, setSideMenuOpen] = useState(false);

  return (
    <ClientTopBarContainer>
      <Flex
        alignItems="center"
        overflow="hidden"
        flexShrink={1}
        gap={{ xs: 1, sm: 2 }}
      >
        <Button
          id="sidebar-open"
          startIcon={<MenuRoundedIcon />}
          onClick={() => setSideMenuOpen(true)}
          variant="outlined"
          size="small"
          sx={{ textTransform: 'uppercase' }}
        >
          {_t('menu', 'Menu')}
        </Button>

        <HasAnyPermission
          val={[
            UserPermission.SWITCH_COMPANIES,
            UserPermission.MANAGE_SUBSIDIARIES,
          ]}
        >
          <TopBarCompanySelector />
        </HasAnyPermission>
      </Flex>
      <Link id="client-topbar-home" to="/" className={classes.logoWrapper}>
        <StreamLogo className={classes.logo} />
      </Link>

      <ClientSideMenu open={sideMenuOpen} setOpen={setSideMenuOpen} />
    </ClientTopBarContainer>
  );
};

export default ClientTopBar;

export const ClientTopBarHeight = '3.5rem';

const classes = {
  logo: 'ClientTopBar-logo',
  logoWrapper: 'ClientTopBar-logoWrapper',
};

const ClientTopBarContainer = styled(Box)(
  ({ theme: { spacing, palette, breakpoints } }) => `
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    z-index: 1;
    
    width: 100%;
    height: ${ClientTopBarHeight};

    padding-left: ${spacing(2)};
    padding-right: ${spacing(2)};
    
    background-color: ${palette.background.paper};
    box-shadow: 
      0px 24px 32px rgba(0, 0, 0, 0.04), 
      0px 16px 24px rgba(0, 0, 0, 0.04), 
      0px 4px 8px rgba(0, 0, 0, 0.04), 
      0px 0px 1px rgba(0, 0, 0, 0.04);

    & .${classes.logoWrapper} {
      display: none;
    }
    
    ${breakpoints.up('sm')} {
      & .${classes.logoWrapper} {
        display: flex;
      }
      padding-left: ${spacing(3)};
      padding-right: ${spacing(3)};
    }

    & .${classes.logo} {
        height: ${spacing(3.5)};
        width: auto;
        color: ${palette.text.primary};
    }
   
    @media print {
      display: none;
    }
`,
);
