import { Button, ButtonTypeMap } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BaseDialog } from '../BaseDialog';
import { Flex } from '../Flex';

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title?: string;
  cancelLabel?: string;
  cancelButtonColor?: ButtonTypeMap['props']['color'];
  confirmLabel?: string;
  confirmDisabled?: boolean;
  confirmButtonColor?: ButtonTypeMap['props']['color'];
  confirmButtonStartIcon?: React.ReactNode;
  disableRestoreFocus?: boolean;
  children?: React.ReactNode;
}

const ConfirmDialog: React.VFC<Props> = (props: Props) => {
  const {
    open,
    onClose,
    onConfirm,
    title,
    confirmDisabled,
    cancelLabel,
    cancelButtonColor = 'primary',
    confirmLabel,
    confirmButtonStartIcon,
    confirmButtonColor = 'primary',
    disableRestoreFocus,
    children,
  } = props;
  const { t } = useTranslation();
  const titleOrDefault = title
    ? title
    : t('confirmDialog.title', 'Are you sure?');
  const cancelOrDefault = cancelLabel
    ? cancelLabel
    : t('common.cancel', 'Cancel');
  const confirmOrDefault = confirmLabel
    ? confirmLabel
    : t('confirmDialog.confirm', 'Confirm');
  return (
    <BaseDialog
      title={titleOrDefault}
      open={open}
      onClose={onClose}
      disableRestoreFocus={disableRestoreFocus}
    >
      {children}
      <Flex justifyContent="center" gap={2}>
        <Button
          id="confirm-dialog-cancel"
          color={cancelButtonColor}
          onClick={onClose}
          variant="outlined"
        >
          {cancelOrDefault}
        </Button>
        <Button
          id="confirm-dialog-confirm"
          color={confirmButtonColor}
          variant="contained"
          disabled={confirmDisabled}
          onClick={onConfirm}
          startIcon={confirmButtonStartIcon}
        >
          {confirmOrDefault}
        </Button>
      </Flex>
    </BaseDialog>
  );
};

export default ConfirmDialog;
