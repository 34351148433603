export const joinStrings = (
  separator: string,
  ...vars: (string | number | undefined | null)[]
) => {
  const values = vars.filter((value) => !!value || value === 0);
  return values.join(separator);
};

export const formatPhone = (rawValue: string) => {
  const value = rawValue?.replaceAll(/\s/g, '');
  if (!value || value.length < 3) return rawValue;
  let extra = value.length % 3;
  const values: string[] = [];

  for (let index = 0; index < value.length; ) {
    const part = value.substr(index, extra + 3);
    values.push(part);
    index = index + extra + 3;
    extra = 0;
  }
  return joinStrings('\u00a0', ...values);
};

export const formaPersonPhone = (user?: {
  phonePrefix?: string | null;
  phoneNumber?: string | null;
}) => {
  const phonePrefix = user?.phonePrefix || '';
  const phoneNumber = user?.phoneNumber;

  if (phoneNumber) {
    return formatPhone(phonePrefix + phoneNumber);
  }
  return '';
};
