import { useQuery } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Button, CircularProgress, IconButton } from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router';
import ConfirmDialog from '../../../base/components/ConfirmDialog/ConfirmDialog';
import {
  ColumnConfig,
  SimpleTable,
} from '../../../base/components/SimpleTable';
import HasCompanyManagementPermission from '../../../base/components/UserProvider/HasCompanyManagementPermission';
import HasPermission from '../../../base/components/UserProvider/HasPermission';
import {
  GetCompanyDocument,
  GetUserGroupsDocument,
  RemoveUserGroupDocument,
  UserGroupFragmentFragment,
  UserPermission,
} from '../../../graphql/graphql-operations';
import { useMutationWithSnackbar } from '../../../hooks/useMutationWithSnackbar';
import useQueryErrorSnackbar from '../../../hooks/useQueryErrorSnackbar';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import { SectionPaper } from '../../components/SectionPaper';
import { TopBar } from '../../components/TopBar';
import CompanyUserGroupDialog from './CompanyUserGroupDialog';
interface DialogState {
  open: boolean;
  userGroup?: UserGroupFragmentFragment;
}

const CompanyUserGroupsPage = () => {
  const { _t } = useTranslationPrefix('CompanyUserGroupsPage');
  const [dialogState, setDialogState] = useState<DialogState>({ open: false });
  const [toDelete, setToDelete] = useState<UserGroupFragmentFragment | null>(
    null,
  );
  const { companyId } = useParams<{ companyId: string }>();
  const { data, loading } = useQuery(GetCompanyDocument, {
    variables: {
      companyIdInput: {
        companyId: companyId,
      },
    },
    skip: !companyId,
  });
  const company = data?.company;
  const {
    data: userGroupsData,
    loading: userGroupsLoading,
    error,
    refetch,
  } = useQuery(GetUserGroupsDocument, {
    variables: {
      input: {
        companyId: companyId,
      },
    },
  });
  useQueryErrorSnackbar(error, refetch);

  const [removeMutation] = useMutationWithSnackbar(
    'CompanyUserGroupsPage.removeMutation',
    RemoveUserGroupDocument,
    { refetchQueries: [getOperationName(GetUserGroupsDocument)!] },
  );

  const handleRemoveGroupConfirm = async () => {
    if (!toDelete) {
      return;
    }
    await removeMutation({
      variables: {
        input: {
          id: toDelete.id,
        },
      },
    });
    setToDelete(null);
  };

  const groups = [...(userGroupsData?.userGroups || [])];

  const columns: ColumnConfig<UserGroupFragmentFragment>[] = [
    {
      id: 'name',
      sortable: true,
      defaultTrans: 'Name',
    },
  ];

  const renderRowActions = (rowData: UserGroupFragmentFragment) => {
    return (
      <>
        <IconButton
          id={`edit-group-${rowData.id}`}
          color="primary"
          onClick={() => setDialogState({ open: true, userGroup: rowData })}
          title={_t('edit', 'Edit')}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          id={`remove-group-${rowData.id}`}
          color="error"
          onClick={() => setToDelete(rowData)}
          title={_t('remove', 'Remove')}
          sx={{ ml: 1 }}
        >
          <DeleteIcon />
        </IconButton>
      </>
    );
  };

  if (loading) return <CircularProgress />;
  return (
    <>
      <TopBar
        leftSideText={_t('title', 'User groups of {{companyName}}', {
          companyName: company?.name,
        })}
        actions={
          <Button
            id="create-user-group"
            variant="contained"
            size="large"
            startIcon={<AddIcon sx={{ width: '1rem', height: '1rem' }} />}
            onClick={() => setDialogState({ open: true })}
          >
            {_t(`addUserGroup`, 'Add user group')}
          </Button>
        }
      />
      <HasCompanyManagementPermission companyId={companyId!}>
        <HasPermission val={UserPermission.MANAGE_COMPANY_USERS}>
          <SectionPaper>
            <SimpleTable
              id="groups-table"
              data={groups}
              rowId="id"
              columns={columns}
              translationPrefix="CompanyUserGroupsPage.table"
              searchConfig={{
                defaultTitle: 'Groups',
                filterBy: 'name',
                defaultPlaceholder: 'Search {{count}} groups',
              }}
              isLoading={userGroupsLoading}
              renderRowActions={renderRowActions}
            />
          </SectionPaper>
          <CompanyUserGroupDialog
            {...dialogState}
            companyId={companyId!}
            onClose={() => setDialogState({ open: false })}
          />
          <ConfirmDialog
            open={!!toDelete}
            onClose={() => setToDelete(null)}
            onConfirm={handleRemoveGroupConfirm}
            title={_t('removeConfirm', 'Remove group {{name}}?', {
              name: toDelete?.name,
            })}
          />
        </HasPermission>
      </HasCompanyManagementPermission>
    </>
  );
};

export default CompanyUserGroupsPage;
