import { BaseRefetchTableVariables } from './base/components/BaseTable';

class VariableStore {
  private rowsPerPage = 15;
  private usersVariables: BaseRefetchTableVariables = {
    first: this.rowsPerPage,
  };

  getUsersVariables = () => this.usersVariables;
  setUsersVariables = (variables: BaseRefetchTableVariables) => {
    this.usersVariables = variables;
  };
}

const variableStore = new VariableStore();
export default variableStore;
