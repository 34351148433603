import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { checkLoginStatus } from '../../../auth/auth';
import ClientLogin from '../../../client/pages/Login/ClientLogin';

const Login = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = checkLoginStatus();
    token.then((loggedIn) => {
      if (loggedIn) {
        navigate('/', { replace: true });
      }
    });
  });

  return <ClientLogin />;
};

export default Login;
