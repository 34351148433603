// unused and not working with react-scripts 5
//import zlib from 'zlib';

const FMETP_DATA_LABEL = 1001;
const CIE_STREAM_LABEL = 1;

const CIE_HEADER_LENGTH = 17;

export const createImageHandler = () => {
  let lastImageData: Uint8Array = new Uint8Array([]);
  let lastDataId: number = -1;
  let dataStartIndex = 17;

  const handleImageData = (data: Uint8Array) => {
    const label = bytesToInt32(data, 0);
    if (label === FMETP_DATA_LABEL) {
      return handleFmetpData(data);
    }

    if (label === CIE_STREAM_LABEL) {
      return handleCieStreamData(data);
    }

    return null;
  };

  const handleCieStreamData = (data: Uint8Array) => {
    const frameId = bytesToInt32(data, 4);
    const dataLength = bytesToInt32(data, 8);

    const imageData = data.slice(CIE_HEADER_LENGTH, data.length);

    return processImageData(frameId, dataLength, imageData, false);
  };

  const handleFmetpData = (data: Uint8Array) => {
    const dataId = bytesToInt32(data, 4);
    const dataLength = bytesToInt32(data, 8);
    const gZipMode = data[16] === 1;

    //CIEVR-574 Hack for error where image data starts on byte 17 in older version of plugin
    //and byte 18 in newer version
    if (lastDataId !== dataId) {
      //New image check where data starts, jpeg starts with 255 byte so if byte 17 is zero
      // it does start on byte 18
      dataStartIndex = data[17] === 0 ? 18 : 17;
    }
    const imageData = data.slice(dataStartIndex, data.length);

    return processImageData(dataId, dataLength, imageData, gZipMode);
  };

  const processImageData = (
    dataId: number,
    dataLength: number,
    imageData: Uint8Array,
    gZipMode: boolean,
  ) => {
    var bytes = new Uint8Array(imageData);
    if (gZipMode) {
      // unused and not working with react-scripts 5
      //bytes = zlib.gunzipSync(bytes);
      throw new Error('Unused so disabled to fix problem with node 20');
    }
    let partialImageData = null;
    if (dataId !== lastDataId) {
      lastDataId = dataId;
      partialImageData = lastImageData;
      lastImageData = bytes;
    } else {
      const newImageData = new Uint8Array(lastImageData.length + bytes.length);
      newImageData.set(lastImageData, 0);
      newImageData.set(bytes, lastImageData.length);
      lastImageData = newImageData;
    }

    if (lastImageData.byteLength < dataLength) {
      return partialImageData && convertToJpegImage(partialImageData);
    }
    return convertToJpegImage(lastImageData);
  };

  const convertToJpegImage = (imageBytes: Uint8Array) => {
    let binaryString = '';
    var len = imageBytes.byteLength;
    for (var i = 0; i < len; i++) {
      binaryString += String.fromCharCode(imageBytes[i]);
    }

    return 'data:image/jpeg;base64,' + btoa(binaryString);
  };

  const bytesToInt32 = (bytes: Uint8Array, offset: number) => {
    return (
      (bytes[offset] & 255) |
      ((bytes[offset + 1] & 255) << 8) |
      ((bytes[offset + 2] & 255) << 16) |
      ((bytes[offset + 3] & 255) << 24)
    );
  };

  return {
    handleImageData,
  };
};
