import { useState } from '@hookstate/core';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { handleOnClickToggle } from '../../../lib/EventHandlers';
import UpsertManualDialog from './UpsertManualDialog';

const Actions = () => {
  const { t } = useTranslation();
  const newManualOpened = useState(false);
  const handleDialogClose = () => newManualOpened.set((prev) => !prev);
  return (
    <>
      <Button
        id="manuals-actions-create"
        size="large"
        color="primary"
        variant="contained"
        startIcon={<AddIcon viewBox="5 5 14 14" />}
        onClick={handleOnClickToggle(handleDialogClose)}
      >
        {t('manuals.actions.newManual', 'New Manual')}
      </Button>
      <UpsertManualDialog
        open={newManualOpened.get()}
        onClose={handleDialogClose}
      />
    </>
  );
};

export default Actions;
