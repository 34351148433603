import { useState } from '@hookstate/core';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ApplicationGroupEditDialog from './ApplicationGroupEditDialog';

const ApplicationGroupsPageActions: React.VFC = () => {
  const { t } = useTranslation();
  const state = useState({ newApplicationDialogOpen: false });
  return (
    <>
      <Button
        id="application-groups-page-archived"
        size="large"
        color="primary"
        variant="text"
        component={Link}
        // @ts-ignore
        to={'/admin/applications/groups/suspended'}
      >
        {t(`applicationGroups.suspendedPrograms`, 'Archived programs')}
      </Button>
      <Button
        id="application-groups-page-create"
        size="large"
        color="primary"
        variant="contained"
        startIcon={<AddIcon viewBox="5 5 14 14" />}
        onClick={() => state.newApplicationDialogOpen.set(true)}
      >
        {t('applicationGroups.newProgramAction', 'New program')}
      </Button>
      <ApplicationGroupEditDialog
        open={state.newApplicationDialogOpen.value}
        onClose={() => state.newApplicationDialogOpen.set(false)}
      />
    </>
  );
};

export default ApplicationGroupsPageActions;
