import {
  GetLiveSessionsSubscriptionSubscription,
  SessionFragmentFragment,
} from '../../../graphql/graphql-operations';
import { joinStrings } from '../../../lib/strings';
import {
  LiveSessionsContextType,
  LiveSessionType,
} from './LiveSessionsProvider';

type Action =
  | GetLiveSessionsSubscriptionSubscription['activeSessions']
  | { __typename: 'ChangeActiveSession'; sessionId: string | null };

const LiveSessionsReducer = (
  state: LiveSessionsContextType,
  action: Action,
): LiveSessionsContextType => {
  if (!action) return state;
  switch (action.__typename) {
    case 'ActiveSessions': {
      return {
        ...state,
        loading: false,
        vrSessions: action.sessions.map(transformSession),
      };
    }
    case 'SessionStarted': {
      const newSession = action.session;
      return {
        ...state,
        loading: false,
        vrSessions: [...state.vrSessions, transformSession(newSession)],
      };
    }
    case 'SessionEnded': {
      const sessionId = action.sessionId;
      return {
        ...state,
        loading: false,
        vrSessions: state.vrSessions.filter((s) => s.id !== sessionId),
      };
    }
    case 'UserAddedToSession': {
      const newSessionData = transformSession(action.session);
      const oldSession = state.vrSessions.find(
        (s) => s.id === newSessionData.id,
      );
      if (oldSession) {
        oldSession.userName = newSessionData.userName;
      }
      return state;
    }
    case 'UserRemovedFromSession': {
      const sessionId = action.sessionId;
      const oldSession = state.vrSessions.find((s) => s.id === sessionId);
      if (oldSession) {
        oldSession.userName = undefined;
      }
      return state;
    }
    case 'ChangeActiveSession': {
      const sessionId = action.sessionId;
      for (const session of state.vrSessions) {
        session.active = sessionId ? sessionId === session.id : false;
      }
      return state;
    }
  }
  return state;
};

const transformSession = (
  vrSession: SessionFragmentFragment,
): LiveSessionType => {
  return {
    id: vrSession.id,
    startedAt: new Date(vrSession.startedAt),
    deviceName: vrSession.vrDevice.name,
    vrDeviceId: vrSession.vrDevice.id,
    userName: joinStrings(
      ' ',
      vrSession.user?.firstName,
      vrSession.user?.lastName,
    ),
  };
};

export default LiveSessionsReducer;
