import { Box, LinearProgress, Table } from '@mui/material';
import { OrderDirection } from '../../../graphql/graphql-operations';
import BaseTableBody from './BaseTableBody';
import BaseTableFooter from './BaseTableFooter';
import BaseTableHead from './BaseTableHead';
import { BaseTableProvider } from './BaseTableProvider';
import {
  BaseRefetchTableVariables,
  RefetchTable,
  TableHeadCellProps,
  TableRowProps,
} from './types';

type BaseProps<T> = {
  id: string;
  head: TableHeadCellProps<T>[];
  rows: TableRowProps<T>[];
  controlBar?: JSX.Element;
  initialOrder?: keyof T;
  actionsWidth?: string;
  withoutActions?: boolean;
  useInBuildFeatures?: boolean;
  isLoading?: boolean;
};

type PropsWithAdditionalData<
  T,
  K extends BaseRefetchTableVariables,
> = BaseProps<T> & RefetchTable<K>;

type Props<
  T,
  K extends BaseRefetchTableVariables | void,
> = K extends BaseRefetchTableVariables
  ? PropsWithAdditionalData<T, K>
  : BaseProps<T>;

const BaseTable = <
  T extends {},
  K extends BaseRefetchTableVariables | void = void,
>({
  id,
  initialOrder,
  head,
  rows,
  controlBar: ControlBar,
  withoutActions = false,
  isLoading = false,
  useInBuildFeatures = false,
  actionsWidth,
  ...refetchTable
}: Props<T, K>) => {
  const orderField = initialOrder ?? head[0].id;
  const isRefetchTable = !!(
    refetchTable as RefetchTable<BaseRefetchTableVariables>
  ).refetch;

  return (
    <BaseTableProvider
      id={id}
      orderDirection={OrderDirection.ASC}
      orderField={orderField}
      rows={rows}
      refetchTable={isRefetchTable ? refetchTable : undefined}
    >
      <Box>
        {ControlBar && <Box>{ControlBar}</Box>}
        <Box minHeight="4px">{isLoading && <LinearProgress />}</Box>
        <Table size="medium">
          <BaseTableHead
            head={head}
            withoutActions={withoutActions}
            actionsWidth={actionsWidth}
          />
          <BaseTableBody
            withoutActions={withoutActions}
            useInBuildFeatures={useInBuildFeatures}
            isLoading={isLoading}
            numberOfColumns={withoutActions ? head.length - 1 : head.length}
            actionsWidth={actionsWidth}
          />
          {(useInBuildFeatures || isRefetchTable) && <BaseTableFooter />}
        </Table>
      </Box>
    </BaseTableProvider>
  );
};

export default BaseTable;
