import { Button, useTheme } from '@mui/material';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { BaseTypography } from '../../../base/components/BaseTypography';
import { Flex } from '../../../base/components/Flex';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import ClientLoginLogo from '../Login/ClientLoginLogo';

interface Props {
  error?: Error;
  eventId?: string | null;
}

const NotFoundPage = ({ eventId, error }: Props) => {
  const { _t } = useTranslationPrefix('ErrorPage');
  const { palette } = useTheme();

  return (
    <>
      <Flex
        flexGrow={1}
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        position="relative"
      >
        <ClientLoginLogo sx={{ mb: 4 }} color={palette.text.secondary} />
        <BaseTypography
          textAlign={'center'}
          variant="h1"
          color="text.secondary"
        >
          {_t('pageNotFound', 'Page not found')}
        </BaseTypography>
        <BaseTypography
          textAlign={'center'}
          variant="h4"
          color="text.secondary"
          sx={{ mt: 1, mb: 4 }}
        >
          {_t('checkRole', 'Check if you are logged in with the right role.')}
        </BaseTypography>
        <Button
          component={Link}
          id="reload"
          to="/"
          size="large"
          variant="contained"
        >
          {_t('goHome', 'Back to home page')}
        </Button>
      </Flex>
      <BaseTypography
        position="relative"
        py={2}
        color="text.secondary"
        variant="body3"
      >
        <Trans
          i18nKey={'ErrorPage.footer'}
          defaults="If this problem still persists, please contact us at <0>{{supportEmailAddress}}</0>."
          values={{
            supportEmailAddress: _t(
              'supportEmailAddress',
              'support@vrmedical.cz',
            ),
          }}
        >
          <a
            href={`mailto:${_t('supportEmailAddress', 'support@vrmedical.cz')}`}
          >
            {' '}
          </a>
        </Trans>
      </BaseTypography>
    </>
  );
};

export default NotFoundPage;
