import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { IconButton as MuiIconButton, styled } from '@mui/material';
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';
import { Flex } from '../Flex';

const CollapsibleTablePaginationActions: React.FC<TablePaginationActionsProps> =
  ({ count, page, rowsPerPage, onPageChange, id }) => {
    const handleFirstPageButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>,
    ) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>,
    ) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>,
    ) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>,
    ) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
    return (
      <Actions>
        <IconButton
          id={`pagination-first-page-${id}`}
          color="primary"
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
          size="large"
        >
          <FirstPageIcon />
        </IconButton>
        <IconButton
          id={`pagination-prev-page-${id}`}
          color="primary"
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
          size="large"
        >
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          id={`pagination-next-page-${id}`}
          color="primary"
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
          size="large"
        >
          <KeyboardArrowRight />
        </IconButton>
        <IconButton
          id={`pagination-last-page-${id}`}
          color="primary"
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
          size="large"
        >
          <LastPageIcon />
        </IconButton>
      </Actions>
    );
  };

export default CollapsibleTablePaginationActions;

const Actions = styled(Flex)(({ theme }) => ({
  flexShrink: 0,
  margin: theme.spacing(0, 3, 0, 3),
}));

const IconButton = styled(MuiIconButton)(({ theme }) => ({
  padding: 0,
}));
