import { styled } from '@mui/material';
import { ReactComponent as VrCardboard } from '../../../assets/svg/vr-cardboard-solid.svg';
import { BaseTypography } from '../../../base/components/BaseTypography';
import Flex from '../../../base/components/Flex/Flex';
import { StreamLogo } from '../../../base/components/StreamLogo';

interface Props {
  vrCardboardColor?: string;
  title: string;
  children?: React.ReactNode;
}

const SimplePageLayout = ({ vrCardboardColor, title, children }: Props) => {
  return (
    <Container>
      <LogoContainer>
        <StreamLogo />
      </LogoContainer>
      <Grid>
        <ContentContainer>
          <IconWrapper style={{ fill: vrCardboardColor ?? 'yellowgreen' }}>
            <VrCardboard />
          </IconWrapper>
          <BaseTypography variant="h4">{title}</BaseTypography>
          {children}
        </ContentContainer>
      </Grid>
    </Container>
  );
};

export default SimplePageLayout;

const Container = styled('div')(({ theme }) => ({
  width: '100vw',
  height: '100vh',
  backgroundColor: theme.palette.primary.main,
  overflow: 'auto',
  padding: theme.spacing(3, 1.5),
}));

const LogoContainer = styled(Flex)(({ theme }) => ({
  maxWidth: theme.spacing(9),
  position: 'fixed',
}));

const Grid = styled('div')({
  display: 'grid',
  alignItems: 'center',
  justifyItems: 'center',
  height: '100%',
});

const ContentContainer = styled(Flex)(({ theme }) => ({
  flexDirection: 'column',
  alignItems: 'center',
  color: theme.palette.common.white,
}));

const IconWrapper = styled('div')(({ theme }) => ({
  width: theme.spacing(11),
  margin: theme.spacing(1),
}));
