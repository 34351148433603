import {
  Autocomplete,
  AutocompleteProps,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { useField } from 'formik';

interface Props<T>
  extends Omit<AutocompleteProps<T, false, false, false>, 'renderInput'> {
  name: string;
  inputProps?: Partial<TextFieldProps>;
}

const FormikAutocomplete = <T extends object>(props: Props<T>) => {
  const { name, inputProps, ...rest } = props;
  const [{ value }, { touched, error }, { setValue, setTouched }] =
    useField(name);

  const handleOnChange = (event: React.ChangeEvent<{}>, newValue: T | null) => {
    if (!touched) {
      setTouched(true);
    }
    setValue(newValue);
  };

  return (
    <Autocomplete
      {...rest}
      value={value}
      onChange={handleOnChange}
      includeInputInList
      renderInput={(params) => (
        <TextField
          {...params}
          {...inputProps}
          error={Boolean(touched && error)}
          fullWidth
          helperText={touched && error}
        />
      )}
    />
  );
};

export default FormikAutocomplete;
