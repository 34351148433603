import { Button, ButtonProps, IconButton } from '@mui/material';
import { IconButtonFilled, IconButtonOutlined } from '../IconButton';

interface Props extends ButtonProps {
  startIcon: React.ReactNode;
  breakOn?: 'sm' | 'md' | 'lg' | 'xl';
}

const ResponsiveButton = ({
  breakOn = 'sm',
  sx,
  startIcon,
  variant,
  ...restProps
}: Props) => {
  return (
    <>
      <Button
        {...restProps}
        variant={variant}
        startIcon={startIcon}
        sx={{ ...sx, display: { xs: 'none', [breakOn]: 'flex' } }}
      />
      {variant === 'text' ? (
        <IconButton
          {...restProps}
          id={`${restProps.id}-sm`}
          sx={{ ...sx, display: { xs: 'flex', [breakOn]: 'none' } }}
        >
          {startIcon}
        </IconButton>
      ) : variant === 'outlined' ? (
        <IconButtonOutlined
          {...restProps}
          id={`${restProps.id}-sm`}
          sx={{ ...sx, display: { xs: 'flex', [breakOn]: 'none' } }}
        >
          {startIcon}
        </IconButtonOutlined>
      ) : (
        <IconButtonFilled
          {...restProps}
          id={`${restProps.id}-sm`}
          sx={{ ...sx, display: { xs: 'flex', [breakOn]: 'none' } }}
        >
          {startIcon}
        </IconButtonFilled>
      )}
    </>
  );
};

export default ResponsiveButton;
