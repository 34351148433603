import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import { Box, BoxProps } from '@mui/material';

interface Props extends BoxProps {
  src?: string | null;
  greyScale?: boolean;
  darken?: boolean;
}

const ImageBackground = ({
  src,
  children,
  greyScale,
  sx,
  darken = true,
  ...rest
}: Props) => {
  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: '100%',
        flexShrink: 0,
        borderRadius: '10px',
        ...sx,
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          borderRadius: '6px',
          backgroundColor: 'grey.50',
          backgroundImage: src ? `url("${src}")` : undefined,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          filter: greyScale ? 'grayscale(100%)' : '',
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          borderRadius: '6px',
          background: darken
            ? 'linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%)'
            : undefined,
        }}
      />
      {!src && (
        <ImageNotSupportedIcon
          sx={{
            width: '40%',
            maxHeight: '40%',
            height: 'auto',
            position: 'absolute',
            top: '35%',
            left: '30%',
            color: 'grey.100',
          }}
        />
      )}
      <Box position="relative" p={2} height={1} overflow="hidden">
        {children}
      </Box>
    </Box>
  );
};

export default ImageBackground;
