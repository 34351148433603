import { Route, Routes } from 'react-router-dom';
import NotFoundDetail from '../../components/NotFoundDetail/NotFoundDetail';
import DebugAssignmentsPage from './DebugAssignmentsPage';
import DebugPage from './DebugPage';
import DebugReportsPage from './DebugReportsPage';
import RolePermissionsPage from './RolePermissionsPage';

const DebugRoute = () => {
  return (
    <Routes>
      <Route index element={<DebugPage />} />
      <Route path="/roles" element={<RolePermissionsPage />} />
      <Route path="/reports" element={<DebugReportsPage />} />
      <Route path="/assignments" element={<DebugAssignmentsPage />} />
      <Route path="*" element={<NotFoundDetail />} />
    </Routes>
  );
};

export default DebugRoute;
