import { BoxProps, styled } from '@mui/material';
import React from 'react';
import { useTranslationPrefix } from '../../../../hooks/useTranslationPrefix';
import { formatDate } from '../../../../lib/date';
import { formatDuration } from '../../../../lib/duration';
import Chart, { ChartProps } from '../../../components/Charts/Chart';
import { axisPointer } from '../utils/axisPointer';
interface Props extends BoxProps {
  data: readonly ProgressData[];
  onAxisPointerChange?: ChartProps<any>['onAxisPointerChange'];
}

interface ProgressData {
  readonly key: string;
  readonly gameTimeSeconds: number;
  readonly runTimeSeconds: number;
}

const RunTimeProgressChart = ({ data, ...boxProps }: Props) => {
  const { _t } = useTranslationPrefix('RunTimeProgressChart');
  return (
    <RunTimeProgressChartWrapper
      {...boxProps}
      options={{
        tooltip: {
          triggerOn: 'click',
          show: true,
          valueFormatter: (val: number) => formatDuration(val * 1000),
        },
        legend: {},
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '110px',
          containLabel: true,
        },
        dataZoom: [
          {
            type: 'inside',
            start: Math.max(0, 100 - (50 / data.length) * 100),
            end: 100,
            filterMode: 'none',
          },
          {
            type: 'slider',
            start: 0,
            end: 100,
            labelFormatter: (_: number, val: string) => formatDate(val),
            filterMode: 'none',
          },
        ],
        dataset: {
          source: data,
        },

        xAxis: {
          type: 'time',
          axisPointer: axisPointer({}),
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: (val: number) => formatDuration(val * 1000),
          },
        },
        series: [
          {
            name: _t('gameTimeSeconds', 'Délka cvičení'),
            type: 'line',
            areaStyle: {},
            smooth: 0.4,
            dimensions: [
              { name: 'key', type: 'time' },
              { name: 'gameTimeSeconds', type: 'number' },
            ],
          },
          {
            name: _t('runTimeSeconds', 'Délka terapeutického planu'),
            type: 'line',
            smooth: 0.4,
            dimensions: [
              { name: 'key', type: 'time' },
              { name: 'runTimeSeconds', type: 'number' },
            ],
          },
        ],
      }}
    />
  );
};

const RunTimeProgressChartWrapper = styled(Chart)`
  min-height: 330px;
  height: 33vh;
`;

export default React.memo(RunTimeProgressChart);
