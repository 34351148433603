import { useMutation } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { Button, MenuItem, styled } from '@mui/material';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { Select, TextField as FormikTextField } from 'formik-mui';
import humps from 'humps';
import { TFunction, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { BaseDialog } from '../../../base/components/BaseDialog';
import { Flex } from '../../../base/components/Flex';
import {
  CrateCompanyError,
  CreateCompanyDocument,
  CreateCompanyMutation,
  GetCompaniesDocument,
  GetDirectSubsidiariesDocument,
  LoginType,
  StreamType,
} from '../../../graphql/graphql-operations';
import useMutationSnackbar from '../../../hooks/useMutationSnackbar';

const UNSELECTED = 'unselected';
interface NewCompanyForm {
  name: string;
  streamType: StreamType;
  defaultLoginType: LoginType | 'unselected';
}

const formErrorCodes = [
  CrateCompanyError.COMPANY_WITH_SAME_NAME_ALREADY_EXISTS,
  CrateCompanyError.USER_ALREADY_EXISTS,
];

interface Props {
  open: boolean;
  onClose: () => void;
  companyId?: string;
  streamType?: StreamType;
}

const NewCompanyDialog = ({ open, onClose, companyId, streamType }: Props) => {
  const { t } = useTranslation();

  const createCompanyHandler = useMutationSnackbar('createCompany', {
    translationKey: 'newCompanyDialog',
    onSuccessInterpolations: (data: CreateCompanyMutation) => ({
      name: data.createCompany.company?.name,
    }),
    onSuccessfullyCompleted: onClose,
    errorCodesToSuppress: formErrorCodes,
  });

  const [createCompany] = useMutation(CreateCompanyDocument, {
    ...createCompanyHandler,
    refetchQueries: [
      getOperationName(GetCompaniesDocument)!,
      getOperationName(GetDirectSubsidiariesDocument)!,
    ],
  });

  const handleFormSubmit = async (
    formData: NewCompanyForm,
    { setFieldError }: FormikHelpers<NewCompanyForm>,
  ) => {
    const { data } = await createCompany({
      variables: {
        createCompany: {
          ...formData,
          parentCompanyId: companyId,
          defaultLoginType:
            formData.defaultLoginType === UNSELECTED
              ? null
              : formData.defaultLoginType,
        },
      },
    });
    if (data && data?.createCompany.errors.length) {
      data.createCompany.errors.forEach((error) => {
        const key = error.argument.toLowerCase() as keyof NewCompanyForm;
        setFieldError(
          key,
          t(
            `newCompanyDialog.error.${humps.camelize(
              error.code.toLowerCase(),
            )}`,
          ),
        );
      });
    }
  };

  return (
    <BaseDialog
      onClose={onClose}
      open={open}
      title={t('newCompanyDialog.title', 'Create new client')}
      disableEnforceFocus
    >
      <Formik<NewCompanyForm>
        initialValues={{
          name: '',
          streamType: streamType || StreamType.MEDICAL,
          defaultLoginType: UNSELECTED,
        }}
        onSubmit={handleFormSubmit}
        validationSchema={getValidationSchema(t)}
        enableReinitialize
      >
        {({ isSubmitting, isValid }) => (
          <Form>
            <Flex flexDirection="column">
              <Field
                component={TextField}
                name="name"
                variant="outlined"
                label={t('newCompanyDialog.form.name', 'Client name')}
                required
              />
              <Field
                component={Select}
                disabled={!!streamType}
                name="streamType"
                variant="outlined"
                label={t('newCompanyDialog.form.streamType', 'Type')}
                required
                sx={{ mb: 2 }}
              >
                <MenuItem value={StreamType.MEDICAL}>
                  {t(`enum.streamType.${StreamType.MEDICAL}`, 'Medical')}
                </MenuItem>
                <MenuItem value={StreamType.TRAINING}>
                  {t(`enum.streamType.${StreamType.TRAINING}`, 'Training')}
                </MenuItem>
                <MenuItem value={StreamType.SALES}>
                  {t(`enum.streamType.${StreamType.SALES}`, 'Sales')}
                </MenuItem>
                <MenuItem value={StreamType.SCHOOL}>
                  {t(`enum.streamType.${StreamType.SCHOOL}`, 'School')}
                </MenuItem>
              </Field>

              <Field
                component={Select}
                name="defaultLoginType"
                variant="outlined"
                label={t(
                  'newCompanyDialog.form.defaultLoginType',
                  'Login type',
                )}
                required
              >
                <MenuItem value={UNSELECTED}>
                  {t(`newCompanyDialog.noLoginType`, 'Not defined (inherited)')}
                </MenuItem>
                <MenuItem value={LoginType.PHONE}>
                  {t(`enum.LoginType.${LoginType.PHONE}`, 'Phone (sms)')}
                </MenuItem>
                <MenuItem value={LoginType.USERNAME}>
                  {t(
                    `enum.LoginType.${LoginType.USERNAME}`,
                    'Username and password',
                  )}
                </MenuItem>
              </Field>
            </Flex>
            <Flex justifyContent="space-around" paddingTop={4}>
              <Button
                id="new-company-dialog-cancel"
                size="large"
                color="primary"
                onClick={onClose}
              >
                {t('common.cancel', 'Cancel')}
              </Button>
              <Button
                id="new-company-dialog-confirm"
                type="submit"
                size="large"
                color="primary"
                variant="contained"
                disabled={isSubmitting || !isValid}
              >
                {t('newCompanyDialog.form.create', 'Create')}
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </BaseDialog>
  );
};

export default NewCompanyDialog;

const TextField = styled(FormikTextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const getValidationSchema = (t: TFunction) =>
  Yup.object({
    name: Yup.string()
      .min(
        4,
        t('newCompanyDialog.form.nameMin', 'Minimal number of characters is 4'),
      )
      .required(t('newCompanyDialog.form.nameRequired', 'Fill clientName')),
    streamType: Yup.string().required(
      t('newCompanyDialog.form.streamTypeRequired', 'Type is required'),
    ),
  });
