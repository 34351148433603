import { isNil } from 'ramda';

export const compareByProp =
  <T>(property: keyof T, direction: 'asc' | 'desc' = 'asc') =>
  (a: T, b: T) => {
    const firstValue = a[property];
    const secondValue = b[property];

    if (typeof firstValue === 'string' && typeof secondValue === 'string') {
      return (
        firstValue.localeCompare(secondValue, undefined, { numeric: true }) *
        (direction === 'asc' ? 1 : -1)
      );
    }
    if (firstValue < secondValue || !firstValue) {
      return direction === 'asc' ? -1 : 1;
    }
    if (firstValue > secondValue || !secondValue) {
      return direction === 'asc' ? 1 : -1;
    }
    return 0;
  };

export const compareByPropPreference =
  <T>(
    property: keyof T,
    preference: any[],
    direction: 'asc' | 'desc' = 'asc',
  ) =>
  (a: T, b: T) => {
    const firstValue = a[property];
    const secondValue = b[property];

    const firstValueIndex = isNil(firstValue)
      ? -1
      : preference.indexOf(firstValue);
    const secondValueIndex = isNil(secondValue)
      ? -1
      : preference.indexOf(secondValue);

    if (firstValueIndex >= 0 && secondValueIndex >= 0) {
      return firstValueIndex - secondValueIndex;
    }
    if (firstValueIndex >= 0) {
      return -1;
    }
    if (secondValueIndex >= 0) {
      return 1;
    }
    return compareByProp(property, direction)(a, b);
  };

export const compareByOrdinalAndProp =
  <T extends { ordinal: number | null }>(
    property: keyof T,
    direction: 'asc' | 'desc' = 'asc',
  ) =>
  (a: T, b: T) => {
    const isANumber = typeof a.ordinal === 'number';
    const isBNumber = typeof b.ordinal === 'number';

    if (isANumber && !isBNumber) {
      return -1;
    }

    if (!isANumber && isBNumber) {
      return 1;
    }
    if (isANumber && typeof isBNumber && a.ordinal !== b.ordinal) {
      return compareByProp('ordinal', direction)(a, b);
    }

    return compareByProp(property, direction)(a, b);
  };
