import { Route, Routes } from 'react-router-dom';
import NotFoundDetail from '../../../admin/components/NotFoundDetail/NotFoundDetail';
import ReportDetailPage from './ReportDetailPage';
import ReportListPage from './ReportListPage';

const ReportsRoute = () => {
  return (
    <Routes>
      <Route index element={<ReportListPage />} />
      <Route path="/:id" element={<ReportDetailPage />} />
      <Route path="*" element={<NotFoundDetail />} />
    </Routes>
  );
};

export default ReportsRoute;
