import { formatMonth } from '../../../../lib/date';

/*
const getStartOfMonth = (val: string) => {
  const regex = /-[0-9]{2}T[^+]+/;
  return val.replace(regex, '-01T00:00:00.000');
};*/
export const prepareMonthAxis = (
  data: readonly { key: string }[],
  position: 'left' | 'bottom' = 'bottom',
) => {
  let lastMonth: string | null = null;

  return {
    type: 'category',
    position: position,
    axisPointer: {
      show: false,
    },
    data: data
      .map((date) => formatMonth(date.key))
      .map((value: string, idx: number, others: string[]) => {
        if (position === 'bottom') {
          //only keep first value replace rest with empty string
          const result = lastMonth !== value ? value : '';
          lastMonth = value;
          return result;
        } else {
          //left side, show last occurance
          if (idx === others.length - 1) return value;
          if (value !== others[idx + 1]) return value;
          return '';
        }
      }),
    axisLabel: {
      interval: (_: number, value: string) => !!value,
      fontWeight: 600,
      padding: position === 'bottom' ? [18, 0, 0, 0] : [0, 80, 0, 0],
      hideOverlap: true,
    },
    axisTick: {
      inside: false,
    },
    splitArea: {
      show: true,
      interval: (_: number, value: string) => !!value,
    },
    splitLine: {
      show: true,
      interval: (_: number, value: string) => !!value,
    },
  };
};
