import { OnSubscriptionDataOptions, useSubscription } from '@apollo/client';
import { useState } from '@hookstate/core';
import React, { PropsWithChildren, useReducer } from 'react';
import { adminState } from '../../../adminState';
import {
  GetLiveSessionsSubscriptionDocument,
  GetLiveSessionsSubscriptionSubscription,
} from '../../../graphql/graphql-operations';
import LiveSessionsReducer from './LiveSessionsReducer';

interface Props {}

export interface LiveSessionsContextType {
  loading: boolean;
  vrSessions: LiveSessionType[];
  changeActiveSession?: (vrSessionId: string | null) => void;
}

export interface LiveSessionType {
  id: string;
  deviceName: string;
  vrDeviceId: string;
  userName?: string;
  startedAt: Date;
  active?: boolean;
}

const initialContext = {
  loading: true,
  vrSessions: [],
};

export const LiveSessionsContext =
  React.createContext<LiveSessionsContextType>(initialContext);

const LiveSessionsProvider: React.FC<PropsWithChildren<Props>> = ({
  children,
}: PropsWithChildren<Props>) => {
  const [state, dispatch] = useReducer(LiveSessionsReducer, initialContext);
  const selectedCompanyId = useState(adminState.selectedCompanyId);

  useSubscription(GetLiveSessionsSubscriptionDocument, {
    fetchPolicy: 'no-cache',
    variables: {
      activeSessionsInput: { companyId: selectedCompanyId.value },
    },
    onSubscriptionData: (
      options: OnSubscriptionDataOptions<GetLiveSessionsSubscriptionSubscription>,
    ) => {
      const {
        subscriptionData: { data },
      } = options;
      if (data && data.activeSessions) {
        dispatch(data.activeSessions);
      }
    },
  });

  const handleChangeActiveteSession = (sessionId: string | null) => {
    dispatch({ __typename: 'ChangeActiveSession', sessionId: sessionId });
  };

  return (
    <LiveSessionsContext.Provider
      value={{ ...state, changeActiveSession: handleChangeActiveteSession }}
    >
      {children}
    </LiveSessionsContext.Provider>
  );
};

export default LiveSessionsProvider;
