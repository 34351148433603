import { useMutation, useQuery } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { Button, Card, CardContent } from '@mui/material';
import { Box } from '@mui/system';
import { FormikHelpers, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDebounce } from 'react-use';
import * as Yup from 'yup';
import { ObjectShape } from 'yup/lib/object';
import { useCompanyUserStatusMutation } from '../../../admin/pages/Companies/hooks';
import { adminState } from '../../../adminState';
import { BaseTypography } from '../../../base/components/BaseTypography';
import ConfirmDialog from '../../../base/components/ConfirmDialog/ConfirmDialog';
import { Flex } from '../../../base/components/Flex';
import HasPermission from '../../../base/components/UserProvider/HasPermission';
import {
  CheckUserDuplicityDocument,
  CreateUserDocument,
  EditUserDocument,
  GetCompanyActiveLoginTypeDocument,
  GetCompanyDocument,
  GetUserDocument,
  LoginType,
  UserFragmentFragment,
  UserPermission,
  UserRole,
} from '../../../graphql/graphql-operations';
import { useMutationWithSnackbar } from '../../../hooks/useMutationWithSnackbar';
import {
  TFunctionPrefixed,
  useTranslationPrefix,
} from '../../../hooks/useTranslationPrefix';
import { PHONE_NO_REGEX, PHONE_PREFIXES } from '../../../lib/constants';
import { handleValidationFieldError } from '../../../lib/handleValidationFieldError';
import { joinStrings } from '../../../lib/strings';
import ContentWrapper from '../../components/ContentWrapper';
import { Loader } from '../../components/Loader';
import { NamedAvatar } from '../../components/NamedAvatar';
import PageHeader from '../../components/PageHeader';
import UserDetailForm, {
  UNSELECTED_LOGIN_TYPE,
  UserFormData,
} from './components/UserDetailForm';

import { useAvailableRoles } from './hooks';
import { PASSWORD_REGEX } from './UserChangePasswordPage';

const UserEditPage = () => {
  const [archiveConfirmOpen, setArchiveConfirmOpen] = useState(false);
  const navigate = useNavigate();
  const { userId } = useParams<{ userId?: string }>();
  const { availableRoles } = useAvailableRoles();
  const [duplicate, setDuplicate] = useState(false);
  const { data, loading: userLoading } = useQuery(GetUserDocument, {
    variables: {
      userId,
    },
    skip: !userId,
  });
  const { data: companyData, loading: companyLoading } = useQuery(
    GetCompanyActiveLoginTypeDocument,
    {
      variables: {
        companyId: data?.user?.companyId || adminState.selectedCompanyId.get(),
      },
      skip: !!userId && !data,
    },
  );

  const canChangePassword =
    data?.user?.role &&
    data?.user?.loginType !== LoginType.PHONE &&
    data?.user?.role !== UserRole.NONE &&
    availableRoles?.includes(data.user.role);
  const loading = (userId && userLoading) || companyLoading;

  const defaultLoginType = userId
    ? data?.user?.loginType
    : companyData?.company.activeLoginType;

  const [editUser] = useMutationWithSnackbar(
    'UserEditPage.editMutation',
    EditUserDocument,
  );
  const [createUser] = useMutationWithSnackbar(
    'UserEditPage.createMutation',
    CreateUserDocument,
  );

  const { suspendUsers } = useCompanyUserStatusMutation({
    onCompleted: () => {
      setArchiveConfirmOpen(false);
      navigate('/users');
    },
  });

  const { _t, t } = useTranslationPrefix('UserEditPage');

  const isNewUser = userId == null;

  const handleSubmit = async (
    values: UserFormData,
    formikHelpers: FormikHelpers<UserFormData>,
  ) => {
    const {
      firstName,
      lastName,
      role,
      loginType,
      externalId,
      password,
      diagnosis,
    } = values;

    const variables =
      role === UserRole.NONE
        ? {
            role: role as UserRole,
            lastName: lastName!,
            firstName: firstName,
            externalId: externalId || null,
            diagnosis: diagnosis || null,
          }
        : {
            ...values,
            firstName: firstName || null,
            lastName: lastName!,
            externalId: externalId || null,
            email: values.email || null,
            phonePrefix: values.phoneNumber ? values.phonePrefix : null,
            phoneNumber: values.phoneNumber || null,
            username: values.username || null,
            role: role as UserRole,
            diagnosis: null,
            loginType:
              loginType === UNSELECTED_LOGIN_TYPE ||
              role === UserRole.TRAINING_STUDENT_BASIC
                ? null
                : (loginType as LoginType),
          };

    const companyId = adminState.selectedCompanyId.get();

    try {
      if (
        variables.role === UserRole.TRAINING_STUDENT_BASIC &&
        variables.externalId &&
        !variables.lastName
      ) {
        variables.lastName = variables.externalId;
      }
      if (isNewUser) {
        await createUser({
          variables: {
            companyId,
            ...variables,
            password: password || undefined,
          },
          onCompleted: (data) => {
            navigate(`/users/${data.createUser.id}`);
          },
          refetchQueries: [getOperationName(GetCompanyDocument)!],
        });
      } else {
        await editUser({
          variables: {
            userId: userId!,
            ...variables,
          },
          onCompleted: () => {
            navigate(`/users/${userId}`);
          },
        });
      }
    } catch (error: any) {
      handleValidationFieldError({ error, formikHelpers, t });
    }
  };

  const handleArchive = () => {
    suspendUsers({
      variables: { suspendUsersInput: { userIds: [userId!] } },
    });
  };

  const formik = useFormik<UserFormData>({
    initialValues: {
      firstName: data?.user.firstName || '',
      lastName: data?.user.lastName || '',
      externalId: data?.user.externalId || '',
      phonePrefix: data?.user.phonePrefix || PHONE_PREFIXES[0],
      phoneNumber: data?.user.phoneNumber || '',
      role: data?.user.role || availableRoles?.[0] || '',
      diagnosis: data?.user.diagnosis?.id || '',
      groups: (data?.user.groups || []).map((g) => g.id),
      loginType: defaultLoginType || UNSELECTED_LOGIN_TYPE,
      email: data?.user.email || '',
      username: data?.user.username || '',
      password: '',
    },
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: () =>
      getValidationSchema(_t, formik.values, duplicate, data?.user),
  });

  const [checkDuplicity] = useMutation(CheckUserDuplicityDocument, {
    onCompleted: (duplicateData) => {
      setDuplicate(!!duplicateData?.checkUserDuplicity?.duplicate);
      formik.validateField('externalId');
    },
  });

  useDebounce(
    () => {
      if (formik.values.firstName && formik.values.lastName) {
        checkDuplicity({
          variables: {
            input: {
              companyId: data?.user?.companyId
                ? String(data?.user?.companyId)
                : adminState.selectedCompanyId.get(),
              lastName: formik.values.lastName,
              firstName: formik.values.firstName,
              externalId: formik.values.externalId,
              userId: data?.user?.id,
            },
          },
        });
      }
    },
    500,
    [
      formik.values.firstName,
      formik.values.lastName,
      formik.values.externalId,
      data?.user?.companyId,
      data?.user?.id,
    ],
  );

  const {
    setFieldValue,
    values: { role: selectedRole },
  } = formik;

  useEffect(() => {
    if (!selectedRole && availableRoles.length) {
      setFieldValue('role', availableRoles[0], true);
    }
  }, [setFieldValue, selectedRole, availableRoles]);

  const pageTitle = isNewUser
    ? _t('userCreate', 'Create user')
    : _t('editUser', `Edit user`);

  return loading ? (
    <Loader />
  ) : (
    <>
      <ContentWrapper>
        <PageHeader back title={pageTitle}>
          {userId && (
            <Button
              id="archive-user"
              variant="outlined"
              onClick={() => setArchiveConfirmOpen(true)}
              color="primary"
            >
              {_t('archive', 'Archive')}
            </Button>
          )}
        </PageHeader>
        <Card
          sx={{
            width: '100%',
            maxWidth: '38rem',
            alignSelf: 'center',
            flexShrink: 0,
          }}
        >
          <CardContent>
            <Flex
              flexDirection="row"
              justifyContent="center"
              gap={2}
              alignItems="center"
            >
              <NamedAvatar
                firstName={formik.values.firstName}
                lastName={formik.values.lastName}
                sx={{
                  width: '6rem',
                  py: 3,
                }}
              />
              <Flex flexDirection="column" alignItems="center" maxWidth="70%">
                <BaseTypography
                  variant="h3"
                  maxWidth="100%"
                  overflow="hidden"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                >
                  {joinStrings(
                    ' ',
                    formik.values.firstName,
                    formik.values.lastName,
                  ) || pageTitle}
                </BaseTypography>
                {canChangePassword && (
                  <HasPermission
                    val={UserPermission.UPDATE_COMPANY_USER_PASSWORD}
                  >
                    <Button
                      id="change-password"
                      component={Link}
                      to={`/users/${userId}/password`}
                      variant="text"
                      size="small"
                    >
                      {_t('changePassword', 'Change password')}
                    </Button>
                  </HasPermission>
                )}
              </Flex>
            </Flex>
            <Box p={3}>
              <UserDetailForm
                availableRoles={availableRoles}
                formik={formik}
                user={data?.user}
              />
            </Box>
          </CardContent>
        </Card>
      </ContentWrapper>
      <ConfirmDialog
        title={_t(
          'archiveConfirmYouWishToArchive',
          'You wish to archive {{user}}',
          {
            user: `${data?.user.firstName} ${data?.user.lastName}`,
          },
        )}
        open={archiveConfirmOpen}
        onClose={() => setArchiveConfirmOpen(false)}
        onConfirm={handleArchive}
      />
    </>
  );
};

export default UserEditPage;

const getValidationSchema = (
  _t: TFunctionPrefixed,
  formValues: UserFormData,
  duplicate: boolean,
  user?: UserFragmentFragment,
) => {
  const mainPart: ObjectShape = {
    firstName: Yup.string()
      .when(['role'], {
        is: (role: UserRole | null) => role !== UserRole.TRAINING_STUDENT_BASIC,
        then: Yup.string().required(
          _t('form.firstNameRequired', 'First name is required'),
        ),
        otherwise: Yup.string().notRequired(),
      })
      .min(3, _t('form.firstNameMin', 'Minimal number of characters is 3')),
    lastName: Yup.string()
      .when(['role'], {
        is: (role: UserRole | null) => role !== UserRole.TRAINING_STUDENT_BASIC,
        then: Yup.string().required(
          _t('form.lastNameRequired', 'Last name is required'),
        ),
        otherwise: Yup.string().notRequired(),
      })
      .min(3, _t('form.lastNameMin', 'Minimal number of characters is 3')),
    role: Yup.string().required(_t('form.roleRequired', 'Role is required')),
    diagnosis: Yup.string().when(['role'], {
      is: (role: UserRole | null) => role === UserRole.NONE,
      then: Yup.string().required(
        _t('form.diagnosisRequired', 'Diagnosis is required'),
      ),
      otherwise: Yup.string().notRequired(),
    }),
  };

  const duplicatePart: ObjectShape = {
    externalId: Yup.string().when(['role'], {
      is: (role: UserRole | null) => role === UserRole.TRAINING_STUDENT_BASIC,
      then: Yup.string()
        .required(_t('form.idRequired', 'ID is required'))
        .min(3, _t('form.idMinLength', 'Minimal number of characters is 3')),

      otherwise: Yup.string().test(
        'duplicate',
        _t('form.duplicate', 'ID and name must be unique'),
        () => !duplicate,
      ),
    }),
  };

  const userPart: ObjectShape = {
    email: Yup.string()
      .email(_t('form.emaiInvalid', 'Invalid e-mail address'))
      .when(['loginType', 'password', 'role'], {
        is: (
          loginType: LoginType | null,
          password: string,
          role: UserRole | null,
        ) =>
          !(user?.hasPassword || password || loginType === LoginType.PHONE) &&
          role !== UserRole.TRAINING_STUDENT_BASIC,
        then: Yup.string().required(
          user
            ? _t('form.emailRequired', 'Email is required')
            : _t('form.emailOrPasswordRequired', 'Email or password required'),
        ),
        otherwise: Yup.string().notRequired(),
      }),
    phonePrefix: Yup.string().when(['loginType', 'role'], {
      is: (loginType: LoginType, role: UserRole | null) =>
        loginType !== LoginType.USERNAME &&
        role !== UserRole.TRAINING_STUDENT_BASIC,
      then: Yup.string().required(
        _t('form.phonePrefixShortRequired', 'Required'),
      ),
      otherwise: Yup.string().notRequired(),
    }),
    phoneNumber: Yup.string()
      .matches(
        PHONE_NO_REGEX,
        _t('form.phoneNumberInvalid', 'Invalid phone number'),
      )
      .when(['loginType', 'role'], {
        is: (loginType: LoginType, role: UserRole | null) =>
          loginType !== LoginType.USERNAME &&
          role !== UserRole.TRAINING_STUDENT_BASIC,
        then: Yup.string().required(
          _t('form.phoneNumberRequired', 'Phone number is required'),
        ),
        otherwise: Yup.string().notRequired(),
      }),
  };

  const loginPart: ObjectShape = {
    username: Yup.string().when(['role'], {
      is: (role: UserRole | null) => role !== UserRole.TRAINING_STUDENT_BASIC,
      then: Yup.string().required(
        _t('form.usernameRequired', 'Username required'),
      ),
      otherwise: Yup.string().notRequired(),
    }),
    password: Yup.string().when(['role'], {
      is: (role: UserRole | null) => role !== UserRole.TRAINING_STUDENT_BASIC,
      then: Yup.string()
        .min(8, _t('form.passwordMin', 'Minimal number of characters is 8'))
        .matches(
          PASSWORD_REGEX,
          _t(
            'form.passwordRegex',
            'Password must have at least 1 uppercase, 1 lower case character and 1 digit',
          ),
        )
        .test(
          'emailOrPassword',
          _t('form.emailOrPasswordRequired', 'Email or password required'),
          (password) => !!(user || password || formValues.email),
        ),
      otherwise: Yup.string().notRequired(),
    }),
  };

  return Yup.object({
    ...mainPart,
    ...duplicatePart,
    ...(formValues.role === UserRole.NONE ? {} : userPart),
    ...(formValues.role === UserRole.NONE ||
    formValues.loginType === LoginType.PHONE
      ? {}
      : loginPart),
  });
};
