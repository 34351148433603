import { useQuery } from '@apollo/client';
import { Button, styled, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Flex } from '../../../base/components/Flex';
import {
  AddAllApplicationScenesDocument,
  AddApplicationSceneDocument,
  AssignableApplicationScenesDocument,
  CompanyApplicationScenesDocument,
  RemoveApplicationSceneDocument,
} from '../../../graphql/graphql-operations';
import { useMutationWithSnackbar } from '../../../hooks/useMutationWithSnackbar';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import { compareByProp } from '../../../lib/compare';
import { filterByProp } from '../../../lib/filter';
import SearchField from '../../components/SearchField/SearchField';
import { SectionPaper } from '../../components/SectionPaper';
import ApplicationScene from './ApplicationScene';

interface App {
  readonly id: string;
  readonly name: string;
  readonly name_t: string;
}

interface Props {
  companyId: string;
}

const getAvailableApps = (
  apps: readonly App[] | null | undefined,
  selectedApps: readonly App[] | null | undefined,
) => {
  return (apps || [])
    .filter(
      (app) =>
        !(selectedApps || []).some((selectedApp) => selectedApp.id === app.id),
    )
    .sort(compareByProp('name_t', 'asc'));
};

const ApplicationSceneList = ({ companyId }: Props) => {
  const { _t } = useTranslationPrefix('applicationSceneSelection');
  const [availableQuery, setAvailableQuery] = useState('');
  const [selectedQuery, setSelectedQuery] = useState('');
  const [availableApps, setAvailableApps] = useState<App[]>([]);
  const { data: assignableAppsData } = useQuery(
    AssignableApplicationScenesDocument,
    {
      variables: {
        companyIdInput: {
          companyId,
        },
      },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
    },
  );
  const { data: companyAppsData } = useQuery(CompanyApplicationScenesDocument, {
    variables: {
      companyIdInput: {
        companyId,
      },
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const [addApplicationScene] = useMutationWithSnackbar(
    'applicationSceneSelection.appAdded',
    AddApplicationSceneDocument,
  );

  const [addAllApplicationScenes] = useMutationWithSnackbar(
    'applicationSceneSelection.appAllAdded',
    AddAllApplicationScenesDocument,
  );

  const [removeApplicationScene] = useMutationWithSnackbar(
    'applicationSceneSelection.appRemoved',
    RemoveApplicationSceneDocument,
  );

  useEffect(() => {
    setAvailableApps(
      getAvailableApps(
        assignableAppsData?.assignableApplicationScenes,
        companyAppsData?.companyApplicationScenes,
      ),
    );
  }, [
    assignableAppsData?.assignableApplicationScenes,
    companyAppsData?.companyApplicationScenes,
  ]);

  const addApp = async (app: App) => {
    await addApplicationScene({
      variables: {
        companySceneInput: {
          companyId,
          applicationSceneId: app.id,
        },
      },
      refetchQueries: [
        {
          query: CompanyApplicationScenesDocument,
          variables: {
            companyIdInput: {
              companyId,
            },
          },
        },
      ],
    });
  };

  const addAllApps = async () => {
    await addAllApplicationScenes({
      variables: {
        companyIdInput: {
          companyId,
        },
      },
      refetchQueries: [
        {
          query: CompanyApplicationScenesDocument,
          variables: {
            companyIdInput: {
              companyId,
            },
          },
        },
      ],
    });
  };

  const removeApp = async (app: App) => {
    await removeApplicationScene({
      variables: {
        companySceneInput: {
          companyId,
          applicationSceneId: app.id,
        },
      },
      refetchQueries: [
        {
          query: CompanyApplicationScenesDocument,
          variables: {
            companyIdInput: {
              companyId,
            },
          },
        },
      ],
    });
  };

  const selectedApps = [
    ...(companyAppsData?.companyApplicationScenes || []),
  ].sort(compareByProp('name_t', 'asc'));

  return (
    <>
      <Flex justifyContent="flex-end" mt={4}>
        <Button
          id="application-scenes-list-add-all"
          size="large"
          color="primary"
          variant="contained"
          onClick={addAllApps}
        >
          {_t(`actions.addAllApps`, 'Add All Apps')}
        </Button>
      </Flex>
      <Container>
        <Flex flex={1}>
          <ApplicationSceneColumn sx={{ mr: 4 }}>
            <Flex justifyContent="space-between">
              <Typography variant="h6" mb={2}>
                {_t('availableApps', 'Available Apps')}
              </Typography>
              <SearchField
                id="search-available"
                placeholder={_t('searchAvailable', 'Search available apps')}
                value={availableQuery}
                onChange={setAvailableQuery}
              />
            </Flex>
            <ApplicationSelectionList>
              {availableApps
                .filter(filterByProp('name_t', availableQuery))
                .map((app) => (
                  <ApplicationScene
                    id={`scene-${app.id}`}
                    key={app.id}
                    name={app.name_t}
                    action={
                      <Button
                        id={`application-scenes-list-add-${app.id}`}
                        onClick={() => addApp(app)}
                      >
                        {_t('add', 'add')}
                      </Button>
                    }
                  />
                ))}
              {availableApps.length === 0 && (
                <NoAppsMessage>
                  {_t('noAppsAvailable', 'No apps available')}
                </NoAppsMessage>
              )}
            </ApplicationSelectionList>
          </ApplicationSceneColumn>
          <ApplicationSceneColumn sx={{ ml: 4 }}>
            <Flex justifyContent="space-between">
              <Typography variant="h6" mb={2}>
                {_t('selectedApps', 'Selected Apps')}
              </Typography>
              <SearchField
                id="search-selected"
                placeholder={_t('searchSelected', 'Search selected apps')}
                value={selectedQuery}
                onChange={setSelectedQuery}
              />
            </Flex>
            <ApplicationSelectionList>
              {selectedApps
                .filter(filterByProp('name_t', selectedQuery))
                .map((app) => (
                  <ApplicationScene
                    id={`scene-${app.id}`}
                    key={app.id}
                    name={app.name_t}
                    action={
                      <Button
                        id={`application-scenes-list-remove-${app.id}`}
                        onClick={() => removeApp(app)}
                      >
                        {_t('remove', 'remove')}
                      </Button>
                    }
                  />
                ))}
              {selectedApps.length === 0 && (
                <NoAppsMessage>
                  {_t('noAppsSelected', 'No apps selected')}
                </NoAppsMessage>
              )}
            </ApplicationSelectionList>
          </ApplicationSceneColumn>
        </Flex>
      </Container>
    </>
  );
};

export default ApplicationSceneList;

const Container = styled(SectionPaper)(
  ({ theme: { spacing } }) => `
  margin-top: ${spacing(2)};
`,
);

const ApplicationSceneColumn = styled(Flex)`
  flex: 1;
  flex-direction: column;
`;

const NoAppsMessage = styled(Flex)`
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const ApplicationSelectionList = styled(Flex)`
  min-height: 400px;
  max-height: 400px;
  overflow: auto;
  flex: 1;
  flex-direction: column;
`;
