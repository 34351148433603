import maxDate from 'date-fns/max';
import { initDB, useIndexedDB } from 'react-indexed-db';
import {
  CompanyFragmentFragment,
  GetSessionPlaylistItemReportsMutationDocument,
  SessionPlaylistItemReportFragmentFragment,
} from '../../../graphql/graphql-operations';
import { useMutationWithSnackbar } from '../../../hooks/useMutationWithSnackbar';
import { useOrderedActiveCompanies } from '../../../hooks/useOrderedActiveCompanies';
import { compareByProp } from '../../../lib/compare';
import { ReportFormData } from './components/ReportFilterForm';

const REPORT_INDEX_DB_NAME = 'ReportDb';
const SESSION_PLAYLIST_REPORT_DB_STORE = 'SessionPlaylistReport';

export interface ReportSummary extends ReportFormData {
  id?: number;
  generatedDate: Date;
  lastRunTime: Date | null;
  totalDurationMs: number;
  totalRuns: number;
}

export interface ReportSubCompany {
  id: string;
  companyName: string;
  parentCompanyId?: string | null;
  lastRunTime: Date | null;
  totalDurationMs: number;
  totalRuns: number;
}

export interface ReportContent extends ReportSummary {
  data: SessionPlaylistItemReportFragmentFragment[];
  subCompanies: ReportSubCompany[];
}

let inited = false;
const initDatabase = () => {
  if (inited) {
    return;
  }

  inited = true;
  initDB({
    name: REPORT_INDEX_DB_NAME,
    version: 1,
    objectStoresMeta: [
      {
        store: SESSION_PLAYLIST_REPORT_DB_STORE,
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [],
      },
    ],
  });
};
const getSubCompanies = (
  companyId: string,
  activeCompanies: CompanyFragmentFragment[],
) => {
  const subCompanies = activeCompanies.filter(
    (c) => c.parentCompanyId === companyId,
  );
  const additional: CompanyFragmentFragment[] = [];
  for (const subCompany of subCompanies) {
    additional.push(...getSubCompanies(subCompany.id, activeCompanies));
  }
  return [...subCompanies, ...additional];
};

const getCompanySummary = (
  companyId: string,
  data: readonly SessionPlaylistItemReportFragmentFragment[],
) => {
  const companyData = data.filter((row) =>
    row.countTowardsCompanies.includes(companyId),
  );
  const runDates: Date[] = companyData
    .map((row) => (row.endedAt ? new Date(row.endedAt) : null))
    .filter((d) => !!d) as Date[];
  return {
    lastRunTime: runDates.length ? maxDate(runDates) : null,
    totalRuns: companyData?.length || 0,
    totalDurationMs:
      companyData?.reduce<number>(
        (duration, row) => duration + row.durationMs,
        0,
      ) || 0,
  };
};

const prepareReportContent = (
  formData: ReportFormData,
  data: readonly SessionPlaylistItemReportFragmentFragment[],
  activeCompanies: CompanyFragmentFragment[],
): ReportContent => {
  const subCompanies = getSubCompanies(formData.companyId, activeCompanies).map(
    (c) => ({
      id: c.id,
      parentCompanyId: c.parentCompanyId,
      companyName: c.name,
      ...getCompanySummary(c.id, data),
    }),
  );

  return {
    ...formData,
    generatedDate: new Date(),
    subCompanies,
    ...getCompanySummary(formData.companyId, data),
    data: [...data],
  };
};

const useSessionPlaylistItemReports = ({
  onChange,
}: {
  onChange?: () => Promise<void> | void;
} = {}) => {
  initDatabase();
  const database = useIndexedDB(SESSION_PLAYLIST_REPORT_DB_STORE);
  const activeCompanies = useOrderedActiveCompanies();

  const [getReports] = useMutationWithSnackbar(
    'SessionPlaylistItemReports.query',
    GetSessionPlaylistItemReportsMutationDocument,
    { fetchPolicy: 'no-cache' },
  );

  const generateReport = async (formData: ReportFormData) => {
    const result = await getReports({
      variables: {
        filter: {
          companyIds: [
            formData.companyId,
            ...getSubCompanies(formData.companyId, activeCompanies).map(
              (c) => c.id,
            ),
          ],
          fromDate: formData.from.toISOString(),
          toDate: formData.to.toISOString(),
        },
      },
    });

    if (!result.data) {
      return;
    }

    const reportContent = prepareReportContent(
      formData,
      result.data.sessionPlaylistItemReportsMutation,
      activeCompanies,
    );
    await database.add(reportContent);
    onChange && (await onChange());
  };

  const getReportSummaries = async () => {
    const data = await database.getAll<ReportContent>();
    const summaries: ReportSummary[] = data.map(
      ({ data, subCompanies, ...rest }) => rest,
    );
    summaries.sort(compareByProp('generatedDate', 'desc'));
    return summaries;
  };

  const removeReport = async (id: number | string) => {
    await database.deleteRecord(id);
    onChange && (await onChange());
  };

  const getReport = async (id: number | string): Promise<ReportContent> => {
    const report = await database.getByID(id);
    return report;
  };

  return { generateReport, getReportSummaries, removeReport, getReport };
};

export default useSessionPlaylistItemReports;
