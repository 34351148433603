import { SvgIcon, SvgIconProps } from '@mui/material';

const VrDeviceOutlinedIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M23.25 9.25H22.4016C22.4188 9.30363 22.4361 9.35688 22.4516 9.41256C22.6858 10.2514 23.0529 12.9893 23.1919 14.5H23.25C23.6625 14.5 24 14.1625 24 13.75V10C24 9.5875 23.6625 9.25 23.25 9.25Z"
        fill="currentColor"
      />
      <path
        d="M1.59881 9.25H0.75C0.3375 9.25 0 9.5875 0 10V13.75C0 14.1625 0.3375 14.5 0.75 14.5H0.808312C0.947437 12.9889 1.31494 10.2507 1.54875 9.41256C1.56431 9.35688 1.58175 9.30363 1.59881 9.25Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0558 16.3654C13.4455 16.3899 14.5932 16.5838 15.4985 16.8271C16.6334 17.1322 17.4087 17.1688 17.9314 16.9618C18.5956 16.6988 19.203 16.4294 19.5729 16.215C19.8575 16.05 20.1925 15.8517 20.5083 15.6634C20.4365 14.8085 20.3344 13.7124 20.2264 12.9071C20.1178 12.0968 19.9511 11.0307 19.8184 10.2077C19.7999 10.0931 19.7657 10.0261 19.7478 9.99883C19.0794 9.75066 17.9067 9.41707 16.0886 9.22325C14.172 9.08517 12.9516 9.04872 12.2487 9.04348L12.2075 9.04317L12.1662 9.04116C12.0275 9.03439 11.0007 9.00051 7.91143 9.22325C6.09331 9.41707 4.9206 9.75066 4.25221 9.99884C4.2343 10.0261 4.20009 10.0931 4.18161 10.2077C4.04886 11.0307 3.88222 12.0968 3.77357 12.9071C3.66558 13.7124 3.56354 14.8085 3.49171 15.6634C3.80752 15.8517 4.14249 16.05 4.4271 16.215C4.79701 16.4294 5.4044 16.6988 6.0686 16.9618C6.59134 17.1688 7.36664 17.1322 8.50154 16.8271C9.40676 16.5838 10.5546 16.3899 11.9442 16.3654L12.0001 16.3637L12.0558 16.3654ZM7.734 7.23085C10.8797 7.00326 12.0059 7.03096 12.2637 7.04353C13.0215 7.04919 14.2958 7.0883 16.266 7.23085C18.2816 7.44302 19.6386 7.82141 20.4783 8.13671C21.2205 8.41542 21.6666 9.10653 21.7929 9.88923C21.926 10.7147 22.0963 11.8029 22.2087 12.6413C22.3249 13.508 22.4313 14.6611 22.5036 15.5234C22.5662 16.2705 22.1964 16.9851 21.5526 17.3692C21.2282 17.5628 20.8771 17.7707 20.576 17.9452C20.0701 18.2385 19.3462 18.5526 18.6678 18.8213C17.5074 19.2809 16.1848 19.0826 14.9794 18.7586C14.2266 18.5563 13.2401 18.3863 12.0171 18.365L12 18.3646L11.9829 18.365C10.7599 18.3863 9.77336 18.5563 9.02065 18.7586C7.81524 19.0826 6.49265 19.2809 5.33216 18.8213C4.65382 18.5526 3.92994 18.2385 3.42402 17.9452C3.12296 17.7707 2.7718 17.5628 2.44736 17.3692C1.80356 16.9851 1.43383 16.2705 1.49642 15.5234C1.56867 14.6611 1.67509 13.508 1.79131 12.6413C1.90373 11.8029 2.07398 10.7147 2.20713 9.88924C2.33337 9.10654 2.77954 8.41542 3.52175 8.13671C4.36141 7.82141 5.71842 7.44303 7.734 7.23085Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default VrDeviceOutlinedIcon;
