import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Collapse, IconButton } from '@mui/material';
import { useTheme } from '@mui/system';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ApplicationSceneGroupStatus,
  ApplicationStatus,
} from '../../../graphql/graphql-operations';
import { SectionPaper } from '../../components/SectionPaper';
import { TopBar } from '../../components/TopBar';
import AllScenesTable from './AllScenesTable';
import ApplicationGroupsPageActions from './ApplicationGroupsPageActions';
import ApplicationGroupsTable from './ApplicationGroupsTable';
import ApplicationPageActions from './ApplicationPageActions';
import ApplicationsTable from './ApplicationsTable';

const ApplicationsPage: React.VFC = () => {
  const { t } = useTranslation();
  const { spacing } = useTheme();
  const [programsExpanded, setProgramsExpanded] = useState(false);
  return (
    <>
      <TopBar leftSideText={t('scenes.topBar.title', 'Scenes')} />
      <AllScenesTable />
      <TopBar
        style={{ marginTop: spacing(5.5) }}
        leftSideText={t('applications.topBar.title', 'APKs')}
        actions={<ApplicationPageActions />}
      />
      <SectionPaper>
        <ApplicationsTable status={ApplicationStatus.ACTIVE} />
      </SectionPaper>
      <TopBar
        leftSideText={
          <>
            {t(
              'applicationGroups.topBar.titleDeprecated',
              'Programs - Deprecated',
            )}
            <IconButton
              id="groups-toggle"
              onClick={() => setProgramsExpanded((e) => !e)}
            >
              {programsExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </>
        }
        actions={programsExpanded && <ApplicationGroupsPageActions />}
      />
      <Collapse in={programsExpanded}>
        <SectionPaper>
          <ApplicationGroupsTable status={ApplicationSceneGroupStatus.ACTIVE} />
        </SectionPaper>
      </Collapse>
    </>
  );
};

export default ApplicationsPage;
