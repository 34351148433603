import { useMutation } from '@apollo/client';
import { StateMethods } from '@hookstate/core';
import { Button, MenuItem, styled } from '@mui/material';
import { Field, Formik } from 'formik';
import { TextField as FormikTextField } from 'formik-mui';
import { omit } from 'ramda';
import { TFunction, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { BaseDialog } from '../../../base/components/BaseDialog';
import { Flex } from '../../../base/components/Flex';
import FormikFileSelect from '../../../base/components/form/FormikFileSelect';
import FormikForm from '../../../base/components/form/FormikForm';
import { useUser } from '../../../base/components/UserProvider/useUserHook';
import {
  FeedbackCategory,
  SubmitFeedbackDocument,
} from '../../../graphql/graphql-operations';
import useMutationSnackbar from '../../../hooks/useMutationSnackbar';
import { MAX_UPLOAD_FILES, MAX_UPLOAD_FILE_SIZE } from '../../../lib/constants';

interface Props {
  showFeedback: StateMethods<boolean>;
}

interface FormValues {
  name: string;
  email: string;
  title: string;
  description: string;
  attachments: File[] | null;
  category: FeedbackCategory;
}

const SUPPORTED_FORMATS = ['image/png', 'image/jpeg'];

const FeedbackDialog = ({ showFeedback }: Props) => {
  const { t } = useTranslation();
  const handleDialogClose = () => showFeedback.set(false);
  const submitFeedbackHandler = useMutationSnackbar('feedbackDialog', {
    onSuccessfullyCompleted: handleDialogClose,
  });
  const [submitFeedback] = useMutation(
    SubmitFeedbackDocument,
    submitFeedbackHandler,
  );
  const { user } = useUser();

  const handleSubmit = (feedbackInput: FormValues) =>
    submitFeedback({
      variables: {
        feedbackInput: omit(['attachments'], {
          ...feedbackInput,
          categoryExplanation: t(
            `feedbackDialog.form.category.${feedbackInput.category}`,
          ),
        }),
        files: feedbackInput.attachments,
      },
    });
  const autoFocusName = !user?.lastName;
  return (
    <BaseDialog
      maxWidth="xl"
      title={t('feedbackDialog.title', 'Contact support')}
      open={showFeedback.get()}
      onClose={handleDialogClose}
    >
      <Formik<FormValues>
        initialValues={{
          name: user?.lastName ?? '',
          email: user?.email ?? '',
          title: '',
          category: FeedbackCategory.A,
          description: '',
          attachments: null,
        }}
        validationSchema={getValidationSchema(t)}
        onSubmit={handleSubmit}
        validateOnMount
      >
        {({ isSubmitting, isValid }) => (
          <FormikForm>
            <Field
              name="name"
              component={TextField}
              variant="outlined"
              type="text"
              placeholder={t(
                'feedbackDialog.form.name.placeholder',
                'Your name',
              )}
              label={t('feedbackDialog.form.name.label', 'Name')}
              required
              autoFocus={autoFocusName}
            />
            <Field
              name="email"
              component={TextField}
              variant="outlined"
              type="text"
              placeholder={t(
                'feedbackDialog.form.email.placeholder',
                'Your email',
              )}
              label={t('feedbackDialog.form.email.label', 'Email')}
              required
            />
            <Field
              name="title"
              component={TextField}
              variant="outlined"
              type="text"
              placeholder={t('feedbackDialog.form.title.placeholder', 'Title')}
              label={t('feedbackDialog.form.title.label', 'Title')}
              required
              autoFocus={!autoFocusName}
            />
            <Field
              name="category"
              required
              select
              component={TextField}
              variant="outlined"
              placeholder={t(
                'feedbackDialog.form.category.placeholder',
                'Category',
              )}
              label={t('feedbackDialog.form.category.label', 'Category')}
              children={Object.keys(FeedbackCategory).map((key) => {
                const value =
                  FeedbackCategory[key as keyof typeof FeedbackCategory];
                return (
                  <MenuItem key={key} value={value}>
                    {t(`feedbackDialog.form.category.${key}`)}
                  </MenuItem>
                );
              })}
            />
            <Field
              name="description"
              component={TextField}
              variant="outlined"
              type="text"
              placeholder={t(
                'feedbackDialog.form.description.placeholder',
                'Description',
              )}
              label={t('feedbackDialog.form.description.label', 'Description')}
              required
              InputProps={{ minRows: 4, maxRows: 10, multiline: true }}
            />
            <FormikFileSelect
              name="attachments"
              label={t(
                'feedbackDialog.form.attachments.label',
                'Add attachments',
              )}
              accept={SUPPORTED_FORMATS.join(', ')}
              disabled={isSubmitting}
              multiple
              color="primary"
            />
            <Flex justifyContent="space-between">
              <Button
                id="feedback-dialog-cancel"
                size="large"
                color="primary"
                disabled={isSubmitting}
                onClick={handleDialogClose}
              >
                {t('common.cancel', 'Cancel')}
              </Button>
              <Button
                id="feedback-dialog-confirm"
                type="submit"
                size="large"
                color="primary"
                variant="contained"
                disabled={isSubmitting || !isValid}
              >
                {t('feedbackDialog.save', 'Save')}
              </Button>
            </Flex>
          </FormikForm>
        )}
      </Formik>
    </BaseDialog>
  );
};

export default FeedbackDialog;

const TextField = styled(FormikTextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  minWidth: theme.spacing(40),
}));

const getValidationSchema = (t: TFunction) =>
  Yup.object({
    name: Yup.string().required(
      t('feedbackDialog.form.name.required', 'Fill your name'),
    ),
    email: Yup.string().required(
      t('feedbackDialog.form.email.required', 'Fill your email'),
    ),
    title: Yup.string().required(
      t('feedbackDialog.form.title.required', 'Fill title'),
    ),
    description: Yup.string().required(
      t('feedbackDialog.form.description.required', 'Fill description'),
    ),
    category: Yup.string().required(
      t('feedbackDialog.form.category.required', 'Choose category'),
    ),
    attachments: Yup.array()
      .max(
        MAX_UPLOAD_FILES,
        t(
          'feedbackDialog.form.attachments.tooManyFiles',
          'Too many files. Maximum is 5',
        ),
      )
      .of(
        Yup.mixed()
          .test(
            'fileSize',
            t(
              'feedbackDialog.form.attachments.fileTooLarge',
              'File is too large. Maximum is 10 MB',
            ),
            (value) => {
              if (!value) {
                return true;
              }
              return value.size <= MAX_UPLOAD_FILE_SIZE;
            },
          )
          .test(
            'fileType',
            t(
              'feedbackDialog.form.attachments.fileTypeForbidden',
              'This file type is not supported.',
            ),
            (value) => {
              if (!value) {
                return true;
              }
              return SUPPORTED_FORMATS.includes(value?.type);
            },
          ),
      )
      .nullable(),
  });
