import { useQuery } from '@apollo/client';
import {
  Box,
  Card,
  CardContent,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import stabilogramHorizontal from '../../../assets/reports/stabilogram-horizontal.png';
import stabilogramlaterolateral from '../../../assets/reports/stabilogram-laterolateral.png';
import stabilogramVerical from '../../../assets/reports/stabilogram-vertical.png';
import { Flex } from '../../../base/components/Flex';
import {
  GetHeadMovementIntervalsDocument,
  GetHeadMovementSeriesDocument,
} from '../../../graphql/graphql-operations';
import useQueryErrorSnackbar from '../../../hooks/useQueryErrorSnackbar';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import { ensureDate, formatDate, getEndOfDay } from '../../../lib/date';
import ContentWrapper from '../../components/ContentWrapper';
import { Loader } from '../../components/Loader';
import PageHeader from '../../components/PageHeader';
import { UserReportPage } from './UserReportsRoute';
import HeadStabilogramChart, {
  HeadStabilogramView,
} from './components/HeadStabilogramChart';
import ReportNav from './components/ReportNav';
import { useReportUser } from './utils/useReportUser';

const now = new Date();

const HeadStabilogramPage = () => {
  const { _t } = useTranslationPrefix('HeadStabilogramPage');
  const { userId, userData, userLoading, backUrl } = useReportUser();

  const [selectedDay, setSelectedDay] = useState<string>();
  const [view, setView] = useState<HeadStabilogramView>('vertical');

  const {
    data: intervalData,
    refetch: intervalRefetch,
    error: intervalError,
    loading: intervalLoading,
  } = useQuery(GetHeadMovementIntervalsDocument, {
    variables: {
      userId: userId!,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    skip: !userId,
  });

  useQueryErrorSnackbar(intervalError, intervalRefetch);

  useEffect(() => {
    if (
      !selectedDay &&
      intervalData?.userReports.headMovementIntervals.length
    ) {
      const date =
        intervalData?.userReports.headMovementIntervals[
          intervalData?.userReports.headMovementIntervals.length - 1
        ];
      setSelectedDay(date);
    }
  }, [intervalData, selectedDay]);

  const {
    data: seriesData,
    refetch: seriesRefetch,
    error: seriesError,
  } = useQuery(GetHeadMovementSeriesDocument, {
    variables: {
      userId: userId!,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      fromDate: ensureDate(selectedDay || now).toISOString(),
      toDate: getEndOfDay(selectedDay || now).toISOString(),
    },
    skip: !userId && !selectedDay,
    fetchPolicy: 'cache-and-network',
  });
  useQueryErrorSnackbar(seriesError, seriesRefetch);

  const loading = userLoading || intervalLoading;
  return (
    <ContentWrapper>
      <PageHeader
        title={_t('title', 'Report {{firstName}} {{lastName}}', {
          firstName: userData?.user.firstName,
          lastName: userData?.user.lastName,
        })}
        back={backUrl}
      ></PageHeader>
      <ReportNav currentPage={UserReportPage.HEAD_STABILOGRAM} />
      {loading ? (
        <Loader />
      ) : (
        <>
          <Flex flexDirection="column" gap={2}>
            <Card>
              <CardContent>
                <Flex
                  marginX={2}
                  gap={2}
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  flexWrap="wrap"
                >
                  <Flex alignItems="center">
                    <img
                      height={100}
                      width={100}
                      alt="range"
                      src={
                        view === 'vertical'
                          ? stabilogramVerical
                          : view === 'horizontal'
                          ? stabilogramHorizontal
                          : stabilogramlaterolateral
                      }
                    />
                    <Box flexDirection="column" alignItems="flex-start" ml={2}>
                      <Typography variant="h6" component="h2">
                        {_t('headStabilogram', 'Pohyb hlavy pacienta v čase')}
                      </Typography>
                    </Box>
                  </Flex>
                  <Flex gap={2}>
                    <ToggleButtonGroup
                      color="primary"
                      value={view}
                      exclusive
                      onChange={(_, val) => val && setView(val)}
                    >
                      <ToggleButton value="vertical">
                        {_t('vertical', 'Vertikálně')}
                      </ToggleButton>
                      <ToggleButton value="laterolateral">
                        {_t('laterolateral', 'Laterolaterální')}
                      </ToggleButton>
                      <ToggleButton value="horizontal">
                        {_t('horizontal', 'Horizontálně')}
                      </ToggleButton>
                    </ToggleButtonGroup>
                    <Select
                      value={selectedDay || ''}
                      onChange={(e) => setSelectedDay(e.target.value)}
                      sx={{ minWidth: '12ch' }}
                    >
                      {intervalData?.userReports.headMovementIntervals.map(
                        (date) => (
                          <MenuItem key={date} value={date}>
                            {formatDate(date)}
                          </MenuItem>
                        ),
                      )}
                    </Select>
                  </Flex>
                </Flex>
                <Box>
                  <HeadStabilogramChart
                    data={seriesData?.userReports.headMovementSeries || []}
                    view={view}
                  />
                </Box>
              </CardContent>
            </Card>
          </Flex>
        </>
      )}
    </ContentWrapper>
  );
};

export default HeadStabilogramPage;
