import { useTranslation } from 'react-i18next';
import {
  BaseRefetchTableVariables,
  BaseTable,
  Cell,
  ComparatorFunction,
  getHeadCellsFromObject,
  getRowContentFromObject,
  RefetchTable,
  RowContentConfig,
  TableSearchControlBar,
} from '../BaseTable';

interface Props<
  TableData,
  K extends BaseRefetchTableVariables | undefined = undefined,
> {
  id: string;
  translationPrefix: string;
  data: TableData[];
  columns: ColumnConfig<TableData>[];
  rowId: keyof TableData;
  isLoading?: boolean;
  searchConfig?: TableSearchConfig<TableData>;
  lazyConfig?: RefetchTable<K>;
  initialOrder?: keyof TableData;
  renderRowActions?: (row: TableData) => React.ReactNode;
}

interface TableSearchConfig<TableData> {
  defaultTitle: string;
  filterBy: keyof TableData | string;
  defaultPlaceholder: string;
}

export interface ColumnConfig<TableData> {
  id: keyof TableData;
  defaultTrans: string;
  sortable?: boolean;
  width?: string;
  getLabel?: Function;
  comparator?: ComparatorFunction;
  component?: React.ComponentType<
    TableData & {
      cellId: keyof TableData;
    }
  >;
}

const SimpleTable = <
  TableData extends { [key: string]: any },
  K extends BaseRefetchTableVariables | undefined = undefined,
>({
  id,
  rowId,
  translationPrefix,
  columns,
  data,
  isLoading,
  searchConfig,
  lazyConfig,
  initialOrder,
  renderRowActions,
}: Props<TableData, K>) => {
  const { t } = useTranslation();

  const headConfig: Cell<TableData>[] = columns.map(
    ({ id, defaultTrans, width, sortable }) => ({
      id,
      defaultTrans,
      width,
      sortable,
    }),
  );

  const tableHead = getHeadCellsFromObject(headConfig, translationPrefix, t);

  const rowsConfig: RowContentConfig<TableData>[] = columns.map(
    ({ id, comparator, component, getLabel }) => ({
      id,
      comparator,
      component,
      getLabel,
    }),
  );

  const tableRows = data.map((row) => ({
    id: row[rowId],
    rowContent: getRowContentFromObject<TableData>(row, rowsConfig),
    rowActions: renderRowActions && renderRowActions(row),
  }));

  const lazyConfigNotNull = lazyConfig || {};

  return (
    <BaseTable<TableData>
      id={id}
      isLoading={isLoading}
      rows={tableRows}
      head={tableHead}
      useInBuildFeatures={!lazyConfig}
      initialOrder={initialOrder}
      controlBar={
        searchConfig && (
          <TableSearchControlBar
            filterBy={searchConfig.filterBy}
            title={t(`${translationPrefix}.title`, searchConfig.defaultTitle)}
            placeholderTransKey={`${translationPrefix}.placeholder`}
            defaultPlaceholder={searchConfig.defaultPlaceholder}
          />
        )
      }
      {...lazyConfigNotNull}
    />
  );
};

export default SimpleTable;
