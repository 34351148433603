import { useQuery } from '@apollo/client';
import { useState } from '@hookstate/core';
import { adminState } from '../adminState';
import {
  GetCompanyDocument,
  GetCompanyQuery,
} from '../graphql/graphql-operations';

export type CompanyDetail = GetCompanyQuery['company'];

const useCompany = () => {
  const companyIdState = useState(adminState.selectedCompanyId);
  const companyId = companyIdState.get();

  const { data: companyData } = useQuery(GetCompanyDocument, {
    variables: {
      companyIdInput: {
        companyId,
      },
    },
    fetchPolicy: 'cache-first',
    skip: !companyId,
  });
  return { company: companyData?.company || null };
};

export default useCompany;
