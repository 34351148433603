import { MenuItem, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { BaseTypography } from '../../../base/components/BaseTypography';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import { compareByOrdinalAndProp } from '../../../lib/compare';
import { SceneParametr, SceneParametrValue } from './PlaylistTypes';

const NO_SELECT_OPTION = 'nothing-selected';

interface Props {
  param: SceneParametr;
  required?: boolean;
  selectedId?: string;
  enabledIds?: string[];
  onChange: (valueId?: string) => void;
}

const PlaylistSelectParam = ({
  param,
  required,
  selectedId,
  enabledIds,
  onChange,
}: Props) => {
  const { _t } = useTranslationPrefix('PlaylistSelectParam');
  const disabled = !param.values.find((p) => enabledIds?.includes(p.id));
  const renderValue = (value: SceneParametrValue) => {
    const selected = selectedId === value.id;

    const handleClick = () => {
      if (!selected) {
        onChange(value.id);
      } else if (!required) {
        onChange();
      }
    };
    return (
      <MenuItem
        id={`param-${param.name}-${value.name}`}
        disabled={!enabledIds?.includes(value.id)}
        key={value.id}
        value={value.id}
        onClick={handleClick}
      >
        {value.name_t}
      </MenuItem>
    );
  };

  return (
    <Box>
      <BaseTypography variant="h5" color="text.secondary">
        {param.name_t}
        {required ? ' *' : ''}
      </BaseTypography>
      <TextField
        select
        value={selectedId || NO_SELECT_OPTION}
        sx={{ width: '25ch', mt: 1 }}
        disabled={disabled}
      >
        <MenuItem
          disabled={required}
          value={NO_SELECT_OPTION}
          onClick={() => onChange()}
        >
          {_t('notSelected', 'Not selected')}
        </MenuItem>
        {param.values.sort(compareByOrdinalAndProp('name_t')).map(renderValue)}
      </TextField>
    </Box>
  );
};

export default PlaylistSelectParam;
