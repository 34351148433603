import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button, Menu, MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router';
import { compareVersions } from '../../../lib/versionComparator';

interface Props {
  versions?: { id: string; version: string }[];
  selectedVersionId?: string;
  onVersionSelected: (selectedVersionId: string) => void;
}

const ApplicationVersionSelector = ({
  versions,
  selectedVersionId,
  onVersionSelected,
}: Props) => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    hasVersions && !anchorEl && setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => setAnchorEl(null);

  useEffect(() => {
    const applicationVersionId = new URLSearchParams(search).get(
      'applicationVersionId',
    );
    if (applicationVersionId && applicationVersionId !== selectedVersionId) {
      onVersionSelected(applicationVersionId);
    }
  }, [search, selectedVersionId, onVersionSelected]);

  const handleSelect = (versionId: string) => () => {
    const params = new URLSearchParams(search);
    params.delete('applicationVersionId');
    params.append('applicationVersionId', versionId);
    navigate({ search: params.toString() });
  };

  const selectedVersion = versions?.find(
    (version) => version.id === selectedVersionId,
  );
  const hasVersions = !!versions?.length;
  const sortedVersions = versions
    ? [...versions].sort(compareVersions('desc'))
    : [];

  return (
    <>
      <Button
        id="application-version-selector-open"
        variant="text"
        color={hasVersions ? 'primary' : 'error'}
        onClick={handleMenuOpen}
        endIcon={<ArrowDropDownIcon />}
      >
        {selectedVersion
          ? `V ${selectedVersion.version}`
          : t('applications.detail.noVersionSelected', 'No version selected')}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {sortedVersions?.map((version) => (
          <MenuItem
            key={version.id}
            selected={version.id === selectedVersionId}
            onClick={handleSelect(version.id)}
          >
            {version.version}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ApplicationVersionSelector;
