import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { RefetchList } from '.';
import { PaginationConfig } from './types';

interface ListPaginationData<T> {
  pageNo: number;
  pageCount: number;
  hasNext: boolean;
  hasPrev: boolean;
  loadMore: () => void;
  handlePrev: () => void;
  handleNext: () => void;
  pageData: readonly T[];
}

const prepareListLazyPagination = <T>(
  lazyConfig: RefetchList<any, T>,
  data: readonly T[],
): ListPaginationData<T> => {
  const handlePrev = () => {
    if (lazyConfig?.variables && lazyConfig?.pageCursors?.previous) {
      lazyConfig.refetch({
        ...lazyConfig.variables,
        after: lazyConfig.pageCursors.previous.cursor,
      });
    }
  };

  const handleNext = () => {
    if (lazyConfig?.variables && lazyConfig?.pageCursors?.next) {
      lazyConfig.refetch({
        ...lazyConfig.variables,
        after: lazyConfig.pageCursors.next.cursor,
      });
    }
  };

  const handleLoadMore = () => {
    if (lazyConfig?.variables && lazyConfig?.pageCursors?.next) {
      lazyConfig.fetchMore({
        variables: {
          ...lazyConfig.variables,
          after: lazyConfig.pageCursors.next.cursor,
        },
        updateQuery: (previousQueryResult, { fetchMoreResult }) => {
          const result = { ...fetchMoreResult };
          for (const [key, value] of Object.entries(result)) {
            const val: any = value;
            if (Array.isArray(val?.nodes)) {
              result[key].nodes = [
                ...previousQueryResult[key].nodes,
                ...val.nodes,
              ];
            }
          }
          return result;
        },
      });
    }
  };

  let pageNo = 0;
  if (lazyConfig?.pageCursors?.previous) {
    pageNo = lazyConfig.pageCursors.previous.pageNumber + 1;
  } else if (lazyConfig?.pageCursors?.next) {
    pageNo = lazyConfig.pageCursors.next.pageNumber - 1;
  }

  return {
    pageCount: lazyConfig?.pageCursors?.last?.pageNumber || 0,
    pageNo: pageNo,
    hasNext: !!lazyConfig?.pageCursors?.next,
    hasPrev: !!lazyConfig?.pageCursors?.previous,
    loadMore: handleLoadMore,
    handlePrev,
    handleNext,
    pageData: data,
  };
};

const calculatePage = (itemCount: number, pageSize: number) => {
  return Math.floor(itemCount / pageSize) + (itemCount % pageSize > 0 ? 1 : 0);
};

const prepareListEagerPagination = <T>(
  eagerConfig: PaginationConfig,
  data: readonly T[],
  pageNo: number,
  setPageNo: Dispatch<SetStateAction<number>>,
): ListPaginationData<T> => {
  const { pageSize } = eagerConfig;
  const pageCount = calculatePage(data.length, pageSize);
  const hasNext = pageNo < pageCount;
  const hasPrev = pageNo > 1;

  const firstPageIndex = Math.max((pageNo - 1) * pageSize, 0);
  const lastPageIndex = Math.min(pageNo * pageSize, data.length);

  const handleNext = () => {
    if (hasNext) setPageNo((pn) => pn + 1);
  };

  const handlePrev = () => {
    if (hasPrev) setPageNo((pn) => pn - 1);
  };

  return {
    pageCount,
    pageNo,
    hasNext,
    hasPrev,
    loadMore: () => {},
    handlePrev,
    handleNext,
    pageData: data.slice(firstPageIndex, lastPageIndex),
  };
};

export const useListPagination = <T>({
  lazyConfig,
  eagerConfig,
  data,
}: {
  lazyConfig?: RefetchList<any, T>;
  eagerConfig?: PaginationConfig;
  data: readonly T[];
}): ListPaginationData<T> => {
  const [pageNo, setPageNo] = useState(1);

  useEffect(() => {
    if (eagerConfig?.pageSize != null) {
      const pageCount = calculatePage(data.length, eagerConfig.pageSize);
      setPageNo((prevPageNo) => {
        if (prevPageNo > pageCount || prevPageNo < 1) {
          return 1;
        }
        return prevPageNo;
      });
    }
  }, [eagerConfig?.pageSize, data.length]);

  if (lazyConfig) {
    return prepareListLazyPagination(lazyConfig, data);
  } else if (eagerConfig) {
    return prepareListEagerPagination(eagerConfig, data, pageNo, setPageNo);
  }
  return {
    pageCount: 0,
    pageNo: 0,
    hasNext: false,
    hasPrev: false,
    loadMore: () => {},
    handlePrev: () => {},
    handleNext: () => {},
    pageData: data,
  };
};
