import { Outlet } from 'react-router-dom';
import NotFoundPage from '../../../client/pages/NotFoundPage/NotFoundPage';
import { UserPermission } from '../../../graphql/graphql-operations';
import { useUser } from './useUserHook';

interface Props {
  permission?: UserPermission | UserPermission[];
}

export const AuthCheck = ({ permission }: Props) => {
  const permissions = Array.isArray(permission)
    ? permission
    : permission
    ? [permission]
    : [];
  const { hasAnyPermission } = useUser();

  const canView = !permissions.length || hasAnyPermission(...permissions);
  if (!canView) {
    console.error('Missing permissions', permissions);
  }
  return canView ? <Outlet /> : <NotFoundPage />;
};

export default AuthCheck;
