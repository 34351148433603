import { PropsWithChildren } from 'react';
import { UserPermission } from '../../../graphql/graphql-operations';
import { useUser } from './useUserHook';

interface Props {
  val: UserPermission | UserPermission[];
}

const HasAnyPermission: React.FC<Props> = ({
  val,
  children,
}: PropsWithChildren<Props>) => {
  const permissions = Array.isArray(val) ? val : [val];
  const { hasAnyPermission } = useUser();
  return hasAnyPermission(...permissions) ? <>{children}</> : null;
};

export default HasAnyPermission;
