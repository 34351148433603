import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded';
import { styled } from '@mui/material';
import React from 'react';
import { BaseTypography } from '../../../../base/components/BaseTypography';
import { Flex } from '../../../../base/components/Flex';
import { IconButtonFilled } from '../../../../base/components/IconButton';
import { VrDeviceType } from '../../../../base/components/VrDevicesProvider/VrDevicesProvider';
import { DeviceState } from '../../../../graphql/graphql-operations';
import { useTranslationPrefix } from '../../../../hooks/useTranslationPrefix';

interface Props {
  device: VrDeviceType;
  onClick?: (device: VrDeviceType) => void;
  small?: boolean;
}

const DeviceUpdateIndicator = ({ device, onClick, small }: Props) => {
  const { _t } = useTranslationPrefix('DeviceUpdateIndicator');
  const updateInProgress = device.status.updateInProgress;

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onClick && onClick(device);
  };

  const renderUpdateAvailable = () => {
    return (
      <>
        {!small && (
          <BaseTypography
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            color={
              device.status.state === DeviceState.ONLINE
                ? 'primary.main'
                : 'bgContrast.main'
            }
            pr={1}
          >
            {_t('updateAvailable', 'Update available')}
          </BaseTypography>
        )}

        <UpdateIconStyled
          id={`update-device-${device.id}`}
          onClick={handleClick}
          color="primary"
          disabled={!onClick}
        >
          <AutorenewRoundedIcon />
        </UpdateIconStyled>
      </>
    );
  };

  const renderUpdateInProgress = () => {
    return (
      <>
        {!small && (
          <BaseTypography
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            color="grey.300"
            pr={1}
          >
            {_t('updateInProgress', 'Update in progress')}
          </BaseTypography>
        )}
        <SpinningIcon
          color={
            device.status.state === DeviceState.ONLINE
              ? 'primary'
              : 'bgContrast'
          }
          sx={{
            fontSize: '1.5rem',
          }}
        />
      </>
    );
  };

  return (
    <Flex alignItems="center" overflow="hidden">
      {updateInProgress ? renderUpdateInProgress() : renderUpdateAvailable()}
    </Flex>
  );
};

export default DeviceUpdateIndicator;

const UpdateIconStyled = styled(IconButtonFilled)(
  ({ theme: { palette, spacing } }) => `
    padding: ${spacing(0.25)};
    border: ${spacing(0.5)} solid ${palette.primary.lighter};
`,
);

const SpinningIcon = styled(AutorenewRoundedIcon)`
  animation-name: spin;
  animation-duration: 4000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
