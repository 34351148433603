import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Box, Button, ButtonTypeMap, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useField } from 'formik';
import { ChangeEvent } from 'react';
import { useUID } from 'react-uid';
import { BaseTypography } from '../BaseTypography';
import { Flex } from '../Flex';
interface Props {
  name: string;
  label: string;
  withValueLabel?: string;
  accept?: string;
  disabled?: boolean;
  color?: ButtonTypeMap['props']['color'];
  buttonClassName?: string;
  validateOnChange?: boolean;
  className?: string;
  buttonAlign?: 'center' | 'right';
  multiple?: boolean;
}

const FormikFileSelect = ({
  name,
  label,
  withValueLabel,
  accept,
  disabled,
  color = 'primary',
  buttonClassName,
  validateOnChange = true,
  multiple = false,
  className,
  buttonAlign = 'center',
}: Props) => {
  const [{ value, ...field }, { touched, error }, { setValue, setTouched }] =
    useField<File | File[] | null>(name);
  const id = useUID();

  const handleRemoveFile = (index: number) => {
    if (value && Array.isArray(value)) {
      const newValue = [...value];
      newValue.splice(index, 1);
      setValue(newValue, validateOnChange);
    } else if (!multiple) {
      setValue(null);
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!touched) {
      setTouched(true, false);
    }
    const files = Array.from(event.target.files ?? []);
    let newValue = null;
    if (multiple) {
      newValue = files.length ? files : null;
    } else {
      newValue = files[0] ?? null;
    }
    if (!multiple || (multiple && newValue)) {
      setValue(newValue, validateOnChange);
    }
    event.target.value = '';
  };

  const ErrorField = (error: string | undefined) =>
    touched && error && <BaseTypography color="error">{error}</BaseTypography>;

  const errorIsArray = Array.isArray(error);
  const arrayValue = Array.isArray(value) ? value : value ? [value] : [];

  return (
    <Flex flexDirection="column" className={className}>
      <Box
        component="label"
        htmlFor={id}
        textAlign={buttonAlign === 'center' ? 'center' : 'right'}
      >
        <input
          id={id}
          accept={accept}
          style={{ display: 'none' }}
          multiple={multiple}
          {...field}
          type="file"
          onChange={handleInputChange}
        />
        <Button
          id={`${field.name}-upload`}
          color={color}
          variant="outlined"
          disabled={disabled}
          component="span"
          startIcon={<CloudUploadIcon color="primary" />}
        >
          {value && withValueLabel ? withValueLabel : label}
        </Button>
      </Box>
      <Box marginTop={1}>
        {arrayValue.map((val, i) => (
          <Flex
            flexDirection="column"
            alignItems="center"
            key={`${i}-${val.lastModified}`}
          >
            <Flex>
              <FileName>{val.name}</FileName>
              <IconButton
                id={`${field.name}-remove`}
                aria-label="remove"
                sx={{ padding: 0 }}
                onClick={() => handleRemoveFile(i)}
                size="large"
              >
                <CloseIcon color="error" />
              </IconButton>
            </Flex>
            {error && errorIsArray && error[i] && ErrorField(error[i])}
          </Flex>
        ))}
        {!errorIsArray && ErrorField(error)}
      </Box>
    </Flex>
  );
};

export default FormikFileSelect;

const FileName = styled('span')(({ theme: { spacing } }) => ({
  width: spacing(37.5),
  maxWidth: spacing(37.5),
  overflow: 'hidden',
  direction: 'rtl',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  textAlign: 'left',
}));
