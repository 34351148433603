import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { Card, CardActions, CardContent, IconButton } from '@mui/material';
import { useState } from 'react';
import ConfirmDialog from '../../../../base/components/ConfirmDialog/ConfirmDialog';
import ImagePlaceholder from '../../../../base/components/ImagePlaceholder/ImagePlaceholder';
import HasPermission from '../../../../base/components/UserProvider/HasPermission';
import {
  CompanyFragmentFragment,
  UpdateCompanyLogoDocument,
  UserPermission,
} from '../../../../graphql/graphql-operations';
import { useMutationWithSnackbar } from '../../../../hooks/useMutationWithSnackbar';
import { useTranslationPrefix } from '../../../../hooks/useTranslationPrefix';
import CompanyLogoDialog from './CompanyLogoDialog';

interface Props {
  company: CompanyFragmentFragment;
}

const CompanyLogoCard = ({ company }: Props) => {
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const { _t } = useTranslationPrefix('CompanyLogoCard');

  const [updateLogo] = useMutationWithSnackbar(
    'CompanyLogoCard.mutation',
    UpdateCompanyLogoDocument,
  );

  const handleUpload = async (file: File) => {
    await updateLogo({
      variables: {
        companyInput: {
          companyId: company.id,
        },
        file: file,
      },
    });
    setUploadDialogOpen(false);
  };

  const handleDelete = async () => {
    await updateLogo({
      variables: {
        companyInput: {
          companyId: company.id,
        },
        file: null,
      },
    });
    setDeleteDialogOpen(false);
  };

  return (
    <>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: { xs: '100%', md: '30%' },
          width: '100%',
        }}
      >
        <CardContent sx={{ minHeight: '3rem' }}>
          {company.logoUrl ? (
            <img
              src={company.logoUrl}
              alt="Company logo"
              style={{ width: '100%' }}
            />
          ) : (
            <ImagePlaceholder />
          )}
        </CardContent>
        <HasPermission val={UserPermission.UPDATE_COMPANY_LOGO}>
          <CardActions sx={{ justifyContent: 'end', mt: 'auto' }}>
            {company.hasCustomLogo && (
              <IconButton
                id="delete-logo"
                color="error"
                onClick={() => setDeleteDialogOpen(true)}
              >
                <DeleteForeverIcon />
              </IconButton>
            )}
            <IconButton
              id="edit-logo"
              color="primary"
              onClick={() => setUploadDialogOpen(true)}
            >
              <EditIcon />
            </IconButton>
          </CardActions>
        </HasPermission>
      </Card>
      <CompanyLogoDialog
        open={uploadDialogOpen}
        onConfirm={handleUpload}
        onClose={() => setUploadDialogOpen(false)}
      />
      <ConfirmDialog
        title={_t('deleteConfirm', 'Delete logo from this company?')}
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={handleDelete}
        confirmButtonColor="error"
        confirmLabel={_t('delete', 'Delete')}
        confirmButtonStartIcon={<DeleteForeverIcon />}
      />
    </>
  );
};

export default CompanyLogoCard;
