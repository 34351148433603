import { BoxProps, styled } from '@mui/material';
import { useMemo } from 'react';
import { useTranslationPrefix } from '../../../../hooks/useTranslationPrefix';
import { compareByProp } from '../../../../lib/compare';
import { formatDate } from '../../../../lib/date';
import { formatDistance } from '../../../../lib/distance';
import Chart from '../../../components/Charts/Chart';
import { EchartTheme } from '../../../components/Charts/echarts_theme';
import { prepareMonthAxis } from '../utils/monthAxis';

interface Props extends BoxProps {
  data: readonly PositionData[];
  colorIndexes: number[];
}

interface PositionData {
  readonly key: string;
  readonly leftTotal: number;
  readonly leftPosition1: number;
  readonly leftPosition2: number;
  readonly leftPosition3: number;
  readonly leftPosition4: number;
  readonly leftPosition5: number;
  readonly rightTotal: number;
  readonly rightPosition1: number;
  readonly rightPosition2: number;
  readonly rightPosition3: number;
  readonly rightPosition4: number;
  readonly rightPosition5: number;
}

const placeholderSize = 2.5;
const calcPercent = (data: PositionData) => {
  const left = data.leftTotal || 1;
  const right = data.rightTotal || 1;

  return {
    ...data,
    leftPlaceholder: -placeholderSize,
    rightPlaceholder: placeholderSize,
    leftPosition1Percent: (data.leftPosition1 / left) * 100 * -1,
    leftPosition2Percent: (data.leftPosition2 / left) * 100 * -1,
    leftPosition3Percent: (data.leftPosition3 / left) * 100 * -1,
    leftPosition4Percent: (data.leftPosition4 / left) * 100 * -1,
    leftPosition5Percent: (data.leftPosition5 / left) * 100 * -1,
    rightPosition1Percent: (data.rightPosition1 / right) * 100,
    rightPosition2Percent: (data.rightPosition2 / right) * 100,
    rightPosition3Percent: (data.rightPosition3 / right) * 100,
    rightPosition4Percent: (data.rightPosition4 / right) * 100,
    rightPosition5Percent: (data.rightPosition5 / right) * 100,
  };
};

const prepareSeries = (key: string, label: string, colorIndex: number) => {
  return {
    id: key,
    name: label,
    type: 'bar',
    barGap: '1%',
    stack: 'total',
    barCategoryGap: '30%',
    dimensions: [
      { name: 'key', type: 'time' },
      { name: key, type: 'number' },
    ],
    itemStyle: {
      color: EchartTheme.theme.color[colorIndex],
    },
    label: {
      show: true,
      position: 'inside',
      formatter: ({ value }: { value: any }) =>
        Math.abs(value[key]) >= 12 ? formatPercent(value[key], 0) : '',
      overflow: 'truncate',
    },
  };
};

const prepareplaceholderSeries = (
  placeholder: 'leftPlaceholder' | 'rightPlaceholder',
) => {
  return {
    type: 'bar',
    barGap: '1%',
    stack: 'total',
    barCategoryGap: '30%',
    itemStyle: {
      borderColor: 'transparent',
      color: 'transparent',
    },
    emphasis: {
      itemStyle: {
        borderColor: 'transparent',
        color: 'transparent',
      },
    },

    dimensions: [
      { name: 'key', type: 'time' },
      { name: placeholder, type: 'number' },
    ],
  };
};

const formatPercent = (value: number, decimalPlaces = 2) => {
  const decimalMultiplier = decimalPlaces ? 10 ** decimalPlaces : 1;

  return `${
    Math.round(Math.abs(value) * decimalMultiplier) / decimalMultiplier
  } %`;
};

const FineMotoricsChart = ({ data, colorIndexes, ...boxProps }: Props) => {
  const { _t } = useTranslationPrefix('FineMotoricsChart');
  const dataWithPercent = useMemo(() => data.map(calcPercent), [data]);
  const position1Label = _t('position1', 'Pozice 1');
  const position2Label = _t('position2', 'Pozice 2');
  const position3Label = _t('position3', 'Pozice 3');
  const position4Label = _t('position4', 'Pozice 4');
  const position5Label = _t('position5', 'Pozice 5');

  return (
    <FineMotoricsChartWrapper
      {...boxProps}
      options={{
        tooltip: {
          show: true,
          trigger: 'axis',
          triggerOn: 'click',
          confine: true,
          valueFormatter: (val: number) => formatDistance(val),
          formatter: (params: any, ticket: any, callback: any) => {
            const leftSeries = params
              .filter((series: any) => series.seriesId.startsWith('left'))
              .sort(compareByProp('seriesId', 'asc'));
            const rightSeries = params
              .filter((series: any) => series.seriesId.startsWith('right'))
              .sort(compareByProp('seriesId', 'asc'));

            return `<div>${formatDate(params[0].axisValue)}</div>
              <div style="display: flex; gap: 1rem;">
              <div>
                <div><b>Levá</b></div>  
                ${leftSeries
                  .map(
                    (series: any) => `
                <div style="display: flex; align-items: baseline;">
                  ${series.marker}
                  <span style="padding-right:1rem">${series.seriesName}</span>
                  <b style="margin-left: auto;">${formatPercent(
                    series.value[series.seriesId],
                  )}</b>
                </div>
                `,
                  )
                  .join('')}  
              </div>
                  
              <div>
                  <div><b>Pravá</b></div>  
                  ${rightSeries
                    .map(
                      (series: any) => `
                  <div style="display: flex; align-items: baseline;">
                    ${series.marker}
                    <span style="padding-right:1rem">${series.seriesName}</span>
                    <b style="margin-left: auto;">${formatPercent(
                      series.value[series.seriesId],
                    )}</b>
                  </div>
                  `,
                    )
                    .join('')}                
              </div>
            `;
          },
        },
        grid: {
          left: '3%',
          right: '50px',
          bottom: '3%',
          top: '3%',
          containLabel: true,
        },
        dataZoom: [
          {
            type: 'inside',
            start: Math.max(0, 100 - (10 / data.length) * 100),
            end: 100,
            orient: 'vertical',
            zoomOnMouseWheel: false,
            moveOnMouseWheel: true,
          },
          {
            type: 'slider',
            start: 0,
            end: 100,
            labelFormatter: (_: number, val: string) => formatDate(val),
            orient: 'vertical',
          },
        ],
        dataset: {
          source: dataWithPercent,
        },

        xAxis: {
          type: 'value',
          axisLabel: {
            formatter: (val: number) => {
              return val % 50 === 0 ? formatPercent(val) : '';
            },
          },
          min: -100 - 2 - placeholderSize,
          max: 100 + 2 + placeholderSize,
        },
        yAxis: [
          {
            type: 'category',
            axisLabel: {
              formatter: (val: string) => {
                return formatDate(val);
              },
            },
          },
          prepareMonthAxis(data, 'left'),
        ],

        series: [
          prepareplaceholderSeries('leftPlaceholder'),
          prepareSeries(
            'leftPosition1Percent',
            position1Label,
            colorIndexes[0],
          ),
          prepareSeries(
            'leftPosition2Percent',
            position2Label,
            colorIndexes[1],
          ),
          prepareSeries(
            'leftPosition3Percent',
            position3Label,
            colorIndexes[2],
          ),
          prepareSeries(
            'leftPosition4Percent',
            position4Label,
            colorIndexes[3],
          ),
          prepareSeries(
            'leftPosition5Percent',
            position5Label,
            colorIndexes[4],
          ),

          prepareplaceholderSeries('rightPlaceholder'),
          prepareSeries(
            'rightPosition1Percent',
            position1Label,
            colorIndexes[0],
          ),
          prepareSeries(
            'rightPosition2Percent',
            position2Label,
            colorIndexes[1],
          ),
          prepareSeries(
            'rightPosition3Percent',
            position3Label,
            colorIndexes[2],
          ),
          prepareSeries(
            'rightPosition4Percent',
            position4Label,
            colorIndexes[3],
          ),
          prepareSeries(
            'rightPosition5Percent',
            position5Label,
            colorIndexes[4],
          ),
        ],
      }}
    />
  );
};

const FineMotoricsChartWrapper = styled(Chart)`
  min-height: 350px;
  height: calc(100vh - 420px);
`;

export default FineMotoricsChart;
