import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { uuid4 } from '@sentry/utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSearchParam } from 'react-use';
import { ResponsiveButton } from '../../../base/components/ResponsiveButton';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import { setToLocalStorageWithExpiration } from '../../../lib/localExpiringStorage';
import ContentWrapper from '../../components/ContentWrapper';
import PageHeader from '../../components/PageHeader';
import PlaylistBuilder from '../../components/PlaylistBuilder/PlaylistBuilder';
import { usePlaylistData } from '../../components/PlaylistBuilder/playlistHooks';

const PlaylistCreateSessionPage = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const userId = useSearchParam('userId');
  const { _t } = useTranslationPrefix('PlaylistCreateSessionPage');
  const playlistData = usePlaylistData();

  const handleNext = () => {
    const id = uuid4();
    const scenes = playlistData.getCurrentPlaylistData();
    setToLocalStorageWithExpiration(id, scenes, 24 * 60 * 60 * 1000);
    userId
      ? navigate(`/devices/launch/${id}?userId=${userId}`)
      : navigate(`/devices/launch/${id}`);
  };

  return (
    <ContentWrapper ignoreNavSpacing>
      <PageHeader
        title={_t('title', 'Prepare playlist')}
        back={pathname && pathname !== '/'}
      ></PageHeader>
      <PlaylistBuilder
        playlistData={playlistData}
        actions={
          <ResponsiveButton
            id="create-session"
            variant="contained"
            startIcon={<PlayArrowIcon />}
            onClick={handleNext}
            color="primary"
            disabled={playlistData.playlistEmpty}
            size="small"
          >
            {_t('launchOnDevice', 'Launch on device')}
          </ResponsiveButton>
        }
      />
    </ContentWrapper>
  );
};

export default PlaylistCreateSessionPage;
