// WIP
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';
import { Button } from '@mui/material';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import HasPermission from '../../../base/components/UserProvider/HasPermission';
import { useUser } from '../../../base/components/UserProvider/useUserHook';
import {
  StreamType,
  UserPermission,
} from '../../../graphql/graphql-operations';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import ContentWrapper from '../../components/ContentWrapper';
import PageHeader from '../../components/PageHeader';
import { SearchField } from '../../components/SearchField';
import UserTrainingList from './components/UserTrainingList';

const UserTrainingListPage = () => {
  const { _t } = useTranslationPrefix('UserTrainingListPage');
  const [query, setQuery] = useState('');
  const { user } = useUser();

  return (
    <ContentWrapper>
      <PageHeader title={_t('title', 'My trainings')}>
        <HasPermission
          val={UserPermission.VIEW_MY_MEDICAL_REPORTS}
          streamType={StreamType.MEDICAL}
        >
          <Button
            id="medical-report"
            variant="outlined"
            component={Link}
            to={`./medical-reports`}
            color="bgContrast"
            startIcon={<WorkspacePremiumOutlinedIcon />}
          >
            {_t('userReport', 'User report')}
          </Button>
        </HasPermission>
        <HasPermission
          val={UserPermission.VIEW_MY_TRAINING_REPORTS}
          streamType={StreamType.TRAINING}
        >
          <Button
            id="training-report"
            variant="outlined"
            component={Link}
            to={`./training-reports`}
            color="bgContrast"
            startIcon={<WorkspacePremiumOutlinedIcon />}
          >
            {_t('trainingReport', 'Training results')}
          </Button>
        </HasPermission>
        <SearchField value={query} onValueChange={setQuery} />
      </PageHeader>
      <UserTrainingList
        userId={user?.id}
        searchConfig={{ filterBy: 'playlistTemplate.name', filterValue: query }}
      />
    </ContentWrapper>
  );
};

export default UserTrainingListPage;
