import { BoxProps, styled } from '@mui/material';
import React from 'react';
import { useTranslationPrefix } from '../../../../hooks/useTranslationPrefix';
import { formatDate } from '../../../../lib/date';
import Chart from '../../../components/Charts/Chart';
import { prepareMonthAxis } from '../utils/monthAxis';

interface Props extends BoxProps {
  data: RangeData[];
  view: HeadRotationChartView;
}

interface RangeData {
  key: string;
  minX: number;
  maxX: number;
  minY: number;
  maxY: number;
  minZ: number;
  maxZ: number;
}

export type HeadRotationChartView = 'rotation' | 'lateralTilt' | 'forwardTilt';

const formatDegrees = (val: number) => Math.round(Math.abs(val)) + '°';

const HeadRotationChart = ({ data, view, ...boxProps }: Props) => {
  const { _t } = useTranslationPrefix('HeadRotationChart');

  return (
    <HeadRotationChartWrapper
      {...boxProps}
      options={{
        tooltip: {
          trigger: 'axis',
          valueFormatter: formatDegrees,
        },
        legend: {},
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        grid: {
          left: '2%',
          right: '1%',
          bottom: '40px',
          top: '40px',
          containLabel: true,
        },
        dataZoom: [
          {
            type: 'inside',
            start: Math.max(0, 100 - (25 / data.length) * 100),
            end: 100,
          },
          {
            type: 'slider',
            start: 0,
            end: 100,
            labelFormatter: (_: number, val: string) => formatDate(val),
          },
        ],
        dataset: {
          source: data,
        },

        xAxis: [
          {
            type: 'category',
            axisLabel: {
              formatter: (val: string) => {
                return formatDate(val);
              },
            },
            axisPointer: {
              label: {
                formatter: (val: any) => {
                  return formatDate(val.value);
                },
              },
            },
          },
          prepareMonthAxis(data),
        ],
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: formatDegrees,
          },
        },
        series: [
          {
            name:
              view === 'forwardTilt'
                ? _t('back', 'Dozadu')
                : _t('left', 'Vlevo'),
            type: 'bar',
            barGap: '0%',
            stack: 'stack',
            barCategoryGap: '50%',
            dimensions: [
              { name: 'key', type: 'time', displayName: 'test' },
              {
                name:
                  view === 'forwardTilt'
                    ? 'minX'
                    : view === 'lateralTilt'
                    ? 'minZ'
                    : 'minY',
                type: 'number',
              },
            ],
          },
          {
            name:
              view === 'forwardTilt'
                ? _t('forward', 'Dopředu')
                : _t('right', 'Vpravo'),
            type: 'bar',
            barGap: '0%',
            stack: 'stack',
            barCategoryGap: '25%',
            dimensions: [
              { name: 'key', type: 'time' },
              {
                name:
                  view === 'forwardTilt'
                    ? 'maxX'
                    : view === 'lateralTilt'
                    ? 'maxZ'
                    : 'maxY',
                type: 'number',
              },
            ],
          },
        ],
      }}
    />
  );
};

const HeadRotationChartWrapper = styled(Chart)`
  min-height: 330px;
  height: 33vh;
`;

export default React.memo(HeadRotationChart);
