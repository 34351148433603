import { useQuery } from '@apollo/client';
import { CircularProgress } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import {
  GetUserDetailAdminDocument,
  UserStatus,
} from '../../../graphql/graphql-operations';
import useQueryErrorSnackbar from '../../../hooks/useQueryErrorSnackbar';
import { joinStrings } from '../../../lib/strings';
import NotFoundDetail from '../../components/NotFoundDetail/NotFoundDetail';
import UserDetail, { UserData } from '../VrSession/UserDetail';
import PatientDetailActions from './PatientDetailActions';

const PatientDetailPage: React.VFC = () => {
  const { userId } = useParams<{ userId: string }>();
  const isInvalid = isNaN(Number(userId));
  const { data, loading, error, refetch } = useQuery(
    GetUserDetailAdminDocument,
    {
      skip: isInvalid,
      variables: { userInput: { userId } },
    },
  );
  useQueryErrorSnackbar(error, refetch);
  if (isInvalid) {
    return <NotFoundDetail />;
  }
  const user = data?.user;
  return loading ? (
    <CircularProgress />
  ) : (
    <UserDetail
      user={data?.user as UserData}
      actions={
        user &&
        user.status === UserStatus.ACTIVE && (
          <PatientDetailActions
            userId={user.id}
            name={joinStrings(' ', user.firstName, user.lastName)}
            vrSessionId={user.activeSession?.id}
          />
        )
      }
    />
  );
};

export default PatientDetailPage;
