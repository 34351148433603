import { CardActionArea, Tooltip, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { useMemo } from 'react';
import { BaseTypography } from '../../../../base/components/BaseTypography';
import { Flex } from '../../../../base/components/Flex';
import { PlaylistItemFragmentFragment } from '../../../../graphql/graphql-operations';
import { useTranslationPrefix } from '../../../../hooks/useTranslationPrefix';
import { compareByPropPreference } from '../../../../lib/compare';
import { SCENE_PARAM_ORDER_PREFERENCE } from '../../../../lib/constants';
import { joinStrings } from '../../../../lib/strings';
import { PLAYLIST_SCENE_WIDTH } from '../../../components/PlaylistBuilder/PlaylistSelectedScene';
import SessionParamBubble from './SessionParamBubble';

interface Props {
  item: PlaylistItemFragmentFragment;
  skip?: (itemId?: string) => void;
  disabled?: boolean;
}

const SessionSceneCard = ({ item, skip, disabled: rawDisabled }: Props) => {
  const { _t } = useTranslationPrefix('SessionSceneCard');
  const { palette } = useTheme();
  const params = useMemo(
    () =>
      [...item.params].sort(
        compareByPropPreference('name', SCENE_PARAM_ORDER_PREFERENCE),
      ),
    [item.params],
  );
  const active = item.startedAt && !item.endedAt;
  const disabled =
    typeof rawDisabled === 'boolean' ? rawDisabled : item.endedAt;

  const renderSceneParams = (params: { name: string; value_t: string }[]) => {
    const maxValues = 3;

    const renderedParams = params.slice(
      0,
      params.length > maxValues ? maxValues - 1 : params.length,
    );
    return (
      <Flex mx={2} pb={2} gap={1} position="relative">
        {renderedParams.map((param) => (
          <SessionParamBubble key={param.name}>
            {param.value_t}
          </SessionParamBubble>
        ))}
        {params.length > maxValues && (
          <Tooltip
            title={joinStrings(', ', ...params.map((p) => p.value_t))}
            describeChild
            placement="top"
          >
            <span style={{ display: 'flex' }}>
              <SessionParamBubble>
                {_t('moreParams', '{{count}} more', {
                  count: params.length - (maxValues - 1),
                })}
              </SessionParamBubble>
            </span>
          </Tooltip>
        )}
      </Flex>
    );
  };

  const renderCardContent = () => {
    return (
      <Flex
        sx={{
          position: 'relative',
          height: '100%',
          width: '100%',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Flex px={2} mt={2} maxHeight="3.5rem" alignItems="center">
          <BaseTypography
            variant="h5"
            color={disabled ? 'grey.100' : 'white.main'}
            sx={{
              maxHeight: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {item.scene.name_t}
          </BaseTypography>
        </Flex>
        {renderSceneParams(params)}
      </Flex>
    );
  };

  return (
    <Flex
      sx={{
        width: PLAYLIST_SCENE_WIDTH,
        position: 'relative',
        height: '100%',
        flexShrink: 0,
        borderRadius: '10px',
        backgroundColor: disabled ? 'grey.300' : 'primary.main',
        backgroundImage: item.scene.imageUrl
          ? `url("${item.scene.imageUrl}")`
          : undefined,
        filter: disabled ? 'grayscale(100%)' : undefined,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          borderRadius: '10px',
          background:
            'linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%)',
          boxShadow: active
            ? `inset 0 0 0 4px ${palette.warning.main}`
            : undefined,
        }}
      />

      {item.startedAt || !skip ? (
        renderCardContent()
      ) : (
        <CardActionArea
          onClick={() => skip(item.id)}
          sx={{ height: '100%', position: 'relative' }}
        >
          {renderCardContent()}
        </CardActionArea>
      )}
    </Flex>
  );
};

export default SessionSceneCard;
