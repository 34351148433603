import { Button, DialogProps } from '@mui/material';
import { Formik } from 'formik';
import { equals } from 'ramda';
import { TFunction } from 'react-i18next';
import * as Yup from 'yup';
import { BaseDialog } from '../../../../base/components/BaseDialog';
import { Flex } from '../../../../base/components/Flex';
import FormikForm from '../../../../base/components/form/FormikForm';
import FormikTextField from '../../../../base/components/form/FormikTextField';
import {
  CreateVrBoxDocument,
  GetVrBoxesDocument,
} from '../../../../graphql/graphql-operations';
import { useMutationWithSnackbar } from '../../../../hooks/useMutationWithSnackbar';
import { useTranslationPrefix } from '../../../../hooks/useTranslationPrefix';

interface VrBoxForm {
  name: string;
}

interface Props extends DialogProps {
  onClose: () => void;
  companyId: string;
}

const NewVrBoxDialog = ({ open, onClose, companyId, ...rest }: Props) => {
  const { _t, t } = useTranslationPrefix('vrBoxCreateDialog');
  const [createVrBox] = useMutationWithSnackbar(
    'vrBoxCreateDialog.vrBoxCreated',
    CreateVrBoxDocument,
  );

  const handleFormSubmit = (toEdit: VrBoxForm) => {
    createVrBox({
      variables: {
        vrBoxInput: {
          companyId,
          ...toEdit,
        },
      },
      refetchQueries: [
        {
          query: GetVrBoxesDocument,
          variables: {
            companyIdOptionalInput: {
              companyId,
            },
          },
        },
      ],
    });
    onClose();
  };

  const initialValues: VrBoxForm = {
    name: '',
  };

  return (
    <BaseDialog
      {...rest}
      open={open}
      onClose={onClose}
      title={_t('title', 'Create VR Box')}
      disableEnforceFocus
    >
      <Formik<VrBoxForm>
        initialValues={initialValues}
        validationSchema={getValidationSchema(_t)}
        onSubmit={handleFormSubmit}
      >
        {({ isSubmitting, values, isValid }) => (
          <FormikForm>
            <FormikTextField
              name="name"
              variant="outlined"
              label={_t('form.name', 'VR Box name')}
              inputProps={{
                autoComplete: 'off',
              }}
              required
            />
            <Flex justifyContent="space-between">
              <Button
                id="create-vr-device-cancel"
                size="large"
                color="primary"
                disabled={isSubmitting}
                onClick={onClose}
              >
                {t('common.cancel', 'Cancel')}
              </Button>
              <Button
                id="create-vr-device-save"
                type="submit"
                size="large"
                color="primary"
                variant="contained"
                disabled={
                  isSubmitting || !isValid || equals(initialValues, values)
                }
              >
                {t('createVrBoxDialog.save', 'Save')}
              </Button>
            </Flex>
          </FormikForm>
        )}
      </Formik>
    </BaseDialog>
  );
};

export default NewVrBoxDialog;

const getValidationSchema = (t: TFunction) =>
  Yup.object({
    name: Yup.string().required(
      t('form.nameRequired', 'VR Box name is required'),
    ),
  });
