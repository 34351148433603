import { useQuery } from '@apollo/client';
import { useState } from '@hookstate/core';
import * as Sentry from '@sentry/react';
import i18next from 'i18next';
import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { adminState } from '../../../adminState';
import { logout } from '../../../auth';
import UserProvider from '../../../base/components/UserProvider/UserProvider';
import {
  AuthUserDocument,
  UserPermission,
} from '../../../graphql/graphql-operations';
import ErrorPage from '../ErrorPage/ErrorPage';
import LoadingPage from '../LoadingPage/LoadingPage';

const PrivateRoute = ({ children }: { children: React.ReactNode }) => {
  const selectedCompanyId = useState(adminState.selectedCompanyId);

  useEffect(() => {
    if (selectedCompanyId.value) {
      sessionStorage.setItem('selectedCompanyId', `${selectedCompanyId.value}`);
    }
  }, [selectedCompanyId, selectedCompanyId.value]);

  const { data, loading, error } = useQuery(AuthUserDocument, {
    onCompleted: (userQuery) => {
      const canSwitchCompanies = userQuery.user.permissions.some(
        (permission) => permission === UserPermission.SWITCH_COMPANIES,
      );
      if (canSwitchCompanies && userQuery.user.subsidiaries.length > 0) {
        const userCompanyArray = userQuery.user.companyId
          ? [userQuery.user.companyId]
          : [];
        const availableCompanies = userCompanyArray.concat(
          userQuery.user.subsidiaries,
        );

        const prevCompany = sessionStorage.getItem('selectedCompanyId');
        const companyId =
          availableCompanies.find(
            (companyId) => String(companyId) === prevCompany,
          ) ||
          userQuery.user.companyId ||
          availableCompanies[0];
        adminState.selectedCompanyId.set(String(companyId));
      } else if (userQuery.user.companyId) {
        adminState.selectedCompanyId.set(userQuery.user.companyId.toString());
      } else {
        console.error(
          "Selected state was not set. Non admin user doesn't has null companyId.",
        );
      }
    },
    onError: (error) => {
      if (error.message === 'Unauthorized') {
        logout();
      }
    },
  });

  useEffect(() => {
    Sentry.configureScope((scope) => {
      scope.setUser({ 'id:': data?.user.id });
    });
  }, [data]);

  const location = useLocation();

  useEffect(() => {
    const languageCode = data?.user.languageCode;
    if (languageCode && languageCode !== i18next.languages[0]) {
      i18next.changeLanguage(languageCode);
    }
  }, [data]);

  if (error) {
    if (
      error.message === 'Unauthorized' ||
      error.message === 'Jwt token invalidated'
    ) {
      return <Navigate state={{ from: location }} to="/login" />;
    }
    return <ErrorPage error={error} />;
  }

  if (loading) {
    return <LoadingPage />;
  }

  if (!data) {
    return <Navigate state={{ from: location }} to="/login" />;
  }

  if (selectedCompanyId.value === '') {
    return <LoadingPage />;
  }

  return <UserProvider user={data.user}>{children}</UserProvider>;
};

export default PrivateRoute;
