import { PlaylistTemplateFragmentFragment } from '../../../../graphql/graphql-operations';
import { ListView } from '../../../components/ListView';
import { SceneListItem } from '../../../components/SceneListItem';

type PlaylistItem = PlaylistTemplateFragmentFragment['items'][0];

interface Props {
  template: PlaylistTemplateFragmentFragment;
  onPlayClick?: (idx: number) => void;
}

const PlaylistTemplateSceneList = ({ template, onPlayClick }: Props) => {
  const renderItem = (item: PlaylistItem, idx: number) => {
    if (true) {
      return (
        <SceneListItem
          idx={idx}
          scene={item.scene}
          params={item.params}
          onPlayClick={onPlayClick ? () => onPlayClick(idx) : undefined}
          status={!!onPlayClick ? 'runnable' : undefined}
        />
      );
    }
  };

  return (
    <ListView
      columns={1}
      rowKey="id"
      data={template.items}
      renderValue={renderItem}
    />
  );
};

export default PlaylistTemplateSceneList;
