import { getOperationName } from '@apollo/client/utilities';
import { useState } from 'react';
import ConfirmDialog from '../../../../base/components/ConfirmDialog/ConfirmDialog';
import {
  GetTrainingAssignmentForTemplateDocument,
  GetUserTrainingAssignmentsDocument,
  RemoveTrainingAssignmentsDocument,
} from '../../../../graphql/graphql-operations';
import { useMutationWithSnackbar } from '../../../../hooks/useMutationWithSnackbar';
import { useTranslationPrefix } from '../../../../hooks/useTranslationPrefix';
import { joinStrings } from '../../../../lib/strings';
import { ListView } from '../../../components/ListView';
import UserListCard from '../../Users/components/UserListCard';
import { UserTrainingAssignment } from './hooks';

interface Props {
  userTrainingAssignments: UserTrainingAssignment[];
}

const PlaylistTemplateUserAssignmentList = ({
  userTrainingAssignments,
}: Props) => {
  const { _t } = useTranslationPrefix('PlaylistTemplateUserAssignmentList');
  const [toDelete, setToDelete] = useState<UserTrainingAssignment | null>(null);

  const [deleteTrainingAssignment] = useMutationWithSnackbar(
    'PlaylistTemplateUserAssignmentList.removeMutation',
    RemoveTrainingAssignmentsDocument,
    {
      refetchQueries: [
        getOperationName(GetUserTrainingAssignmentsDocument)!,
        getOperationName(GetTrainingAssignmentForTemplateDocument)!,
      ],
    },
  );

  const handleDeleteConfirm = async (assignment: UserTrainingAssignment) => {
    await deleteTrainingAssignment({
      variables: {
        input: {
          trainingAssignmentIds: [assignment.id],
        },
      },
    });
    setToDelete(null);
  };

  const renderValue = (userTraningAssignment: UserTrainingAssignment) => {
    return (
      <UserListCard
        user={userTraningAssignment.user}
        onDeleteClick={() => setToDelete(userTraningAssignment)}
      />
    );
  };

  return (
    <>
      <ListView
        rowKey="id"
        renderValue={renderValue}
        data={userTrainingAssignments}
        columns={1}
      />
      <ConfirmDialog
        open={!!toDelete}
        title={_t(
          'unassignFromGroupConfirm',
          'Unassign training from {{name}}?',
          {
            name: joinStrings(
              ' ',
              toDelete?.user.firstName,
              toDelete?.user.lastName,
            ),
          },
        )}
        onClose={() => setToDelete(null)}
        onConfirm={() => toDelete && handleDeleteConfirm(toDelete)}
      />
    </>
  );
};

export default PlaylistTemplateUserAssignmentList;
