import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import PlaylistLargeParam from './PlaylistLargeParam';
import { SceneParametrValue } from './PlaylistTypes';

interface Props {
  categories: SceneParametrValue[];
  selectedCategory?: SceneParametrValue;
  setSelectedCategory: (category: SceneParametrValue) => void;
}

const PlaylistCategory = ({
  categories,
  selectedCategory,
  setSelectedCategory,
}: Props) => {
  const { _t } = useTranslationPrefix('PlaylistCategory');

  const handleChange = (id?: string) => {
    const category = categories?.find((c) => c.id === id);
    if (category) {
      setSelectedCategory(category);
    }
  };

  return categories?.length > 1 ? (
    <PlaylistLargeParam
      param={{
        id: 'category',
        name: 'category',
        name_t: _t('title', 'Category'),
        values: categories,
      }}
      onChange={handleChange}
      required={true}
      enabledIds={categories.map((c) => c.id)}
      selectedId={selectedCategory?.id}
    />
  ) : null;
};

export default PlaylistCategory;
