import isPropValid from '@emotion/is-prop-valid';
import { styled } from '@mui/system';
interface PointProps {
  disabled?: boolean;
  isSelected: boolean;
  posX: number;
  posY: number;
}

interface Props extends PointProps {
  onClick: () => void;
}

const SilhouetteLocationPoint = ({
  onClick,
  isSelected,
  posX,
  posY,
  disabled,
}: Props) => {
  const handleClick = () => {
    if (!disabled) {
      onClick();
    }
  };
  return (
    <SilhouetteLocationPointWrapper
      disabled={disabled}
      onClick={handleClick}
      isSelected={isSelected}
      posX={posX}
      posY={posY}
    />
  );
};

const SilhouetteLocationPointWrapper = styled('div', {
  shouldForwardProp: isPropValid,
})<PointProps>(
  ({ posX, posY, isSelected, disabled, theme: { palette, shadows } }) => `
    position: absolute;
    width: 39px;
    height: 39px;

    
    top: ${posY}px;
    left: ${posX}px;

    transform: translateX(-50%) translateY(-50%);

    border-radius: 40px;

    
    background-color: ${isSelected ? palette.primary.main : '#6060604d'};

    ${
      !disabled &&
      `
    cursor: pointer;

    :hover {
      box-shadow: ${(shadows as string[])[5]}
    }
    `
    }
    
    
`,
);

export default SilhouetteLocationPoint;
