import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import { styled } from '@mui/material';
import { Box, BoxProps, lighten } from '@mui/system';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';

interface Props extends BoxProps {
  variant: Variant;
}

type Variant = 'success' | 'error';

const TrainingStatusTag = ({ variant, ...boxProps }: Props) => {
  const { _t } = useTranslationPrefix('TrainingStatusTag');
  return (
    <TrainingStatusTagWrapper {...boxProps} variant={variant}>
      {variant === 'success' ? (
        <CheckRoundedIcon />
      ) : (
        <ErrorOutlineRoundedIcon />
      )}
      {variant === 'success'
        ? _t('completed', 'Completed')
        : _t('pastDue', 'Past due')}
    </TrainingStatusTagWrapper>
  );
};

export default TrainingStatusTag;

const TrainingStatusTagWrapper = styled(Box)<{ variant: Variant }>(
  ({ variant, theme: { palette, spacing } }) => `
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: ${spacing(0.5)}  ${spacing(1.5)};
    padding-right: ${spacing(2)};
    
    border-radius: 200px;
    background-color: ${lighten(palette[variant].main, 0.8)};
    color: ${palette[variant].dark};
    font-size: 0.75rem;

    & svg {
       font-size: 1rem;
       color: ${palette[variant].dark};
       margin-right: ${spacing(1)}
    }
`,
);
