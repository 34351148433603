import { useQuery } from '@apollo/client';
import { uuid4 } from '@sentry/utils';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  CompanyFeature,
  CreateSessionPlaylistItemInput,
  GetPlaylistTemplateDocument,
} from '../../../graphql/graphql-operations';
import useQueryErrorSnackbar from '../../../hooks/useQueryErrorSnackbar';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import { setToLocalStorageWithExpiration } from '../../../lib/localExpiringStorage';
import useCompany from '../../../lib/useCompany';
import ContentWrapper from '../../components/ContentWrapper';
import { Loader } from '../../components/Loader';
import PageHeader from '../../components/PageHeader';
import PlaylistTemplateDetailContent from './components/PlaylistTemplateDetailContent';
import PlaylistTemplateHeaderCard from './components/PlaylistTemplateHeaderCard';

const PlaylistTemplateDetailPage = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { company } = useCompany();
  const { _t } = useTranslationPrefix('PlaylistTemplateDetailPage');
  const { templateId } = useParams<{ templateId: string }>();

  const {
    data: templateData,
    loading,
    refetch,
    error,
  } = useQuery(GetPlaylistTemplateDocument, {
    variables: {
      id: templateId,
    },
    skip: !templateId,
  });
  useQueryErrorSnackbar(error, refetch);
  const playlistTemplate = templateData?.playlistTemplate;

  const canPlay =
    (pathname.includes('group-sessions') &&
      company?.features.includes(CompanyFeature.LAUNCH_GROUP_SESSION)) ||
    false;

  const handlePlayClick = (idx: number) => {
    if (!playlistTemplate) {
      return;
    }

    const id = uuid4();
    const toRunItems = playlistTemplate.items.slice(idx);
    const scenes: CreateSessionPlaylistItemInput[] = toRunItems.map((item) => ({
      sceneId: item.scene.id,
      params: item.params,
    }));
    setToLocalStorageWithExpiration(id, scenes, 24 * 60 * 60 * 1000);
    navigate(`/devices/launch/${id}`);
  };

  return loading || !playlistTemplate ? (
    <Loader />
  ) : (
    <ContentWrapper>
      <PageHeader title={_t('title', 'Playlist')} back="./.." />
      <PlaylistTemplateHeaderCard
        template={playlistTemplate}
        sx={{ mb: 2 }}
        onPlayClick={canPlay ? () => handlePlayClick(0) : undefined}
      />
      <PlaylistTemplateDetailContent
        template={playlistTemplate}
        onPlayClick={canPlay ? handlePlayClick : undefined}
      />
    </ContentWrapper>
  );
};

export default PlaylistTemplateDetailPage;
