import * as Sentry from '@sentry/react';
import { ApolloLink, Observable } from '@apollo/client';

const captureBread = (operation: any, data: any) => {
  Sentry.addBreadcrumb({
    message:
      operation.query.definitions[0].operation === 'subscription'
        ? `Subscription ${operation.operationName}`
        : `Ending request for ${operation.operationName}`,
    category: 'graphql',
    data: {
      variables: operation.variables,
      result: data,
    },
  });
  return data;
};

const loggingLink = new ApolloLink((operation, forward) => {
  Sentry.addBreadcrumb({
    message: `Starting request for ${operation.operationName}`,
    category: 'graphql',
    data: { variables: operation.variables },
  });
  if (forward == null) {
    return null;
  }
  if (forward(operation).map != null) {
    return forward(operation).map((data) => {
      return captureBread(operation, data);
    });
  } else {
    return Observable.from(forward(operation)).map((data) => {
      return captureBread(operation, data);
    });
  }
});

export default loggingLink;
