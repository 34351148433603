import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  AutocompleteValue,
  Box,
  IconButton,
  MenuItem,
  styled,
  TextField,
} from '@mui/material';
import { MouseEvent, useMemo, useState } from 'react';
import { highlighQuery } from '../../../lib/filter';
import { Flex } from '../Flex';
import CompanySelectPopper from './CompanySelectPopper';
import useCompanyTree, { TreeCompany } from './useCompanyTree';

interface Props {
  id: string;
  value: string;
  onValueChange: (value: string) => void;
  label?: string;
  error?: React.ReactNode;
}

interface TreeCompanyOption extends TreeCompany {
  visible: boolean;
  lvl: number;
}

const CompanySelect = ({ id, value, onValueChange, label, error }: Props) => {
  const [preventQueryOnBlur, setPreventQueryOnBlur] = useState(0);

  const [query, setQuery] = useState('');
  const { rootCompanies, toggleCompany } = useCompanyTree();
  const options = useMemo(
    () => preprareOptions(rootCompanies, true, 0),
    [rootCompanies],
  );
  const selectedCompany = options.find((c) => c.id === value);

  const handleChange = (
    event: React.SyntheticEvent,
    value: AutocompleteValue<TreeCompanyOption | '', false, false, false>,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<TreeCompanyOption | ''>,
  ) => {
    if (value) {
      onValueChange(value.id);
    }
  };

  const handleToggleCompany =
    (companyId: string) => (e: MouseEvent<HTMLButtonElement>) => {
      e.nativeEvent.stopImmediatePropagation();
      e.nativeEvent.preventDefault();
      e.stopPropagation();
      e.preventDefault();
      toggleCompany(companyId);
    };

  const handleQueryChange = (
    event: React.SyntheticEvent,
    value: string,
    reason: AutocompleteInputChangeReason,
  ) => {
    if (reason === 'reset' && preventQueryOnBlur > Date.now() - 250) {
      //clicked on expand button, dot reset query this time
      return;
    }
    setQuery(value);
  };

  const renderOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: TreeCompanyOption | '',
    state: AutocompleteRenderOptionState,
  ) => {
    if (!option) {
      return null;
    }
    const searching = !!state.inputValue;
    if (!option.visible && !searching) {
      return null;
    }
    return (
      <MenuItem {...props} key={option.id}>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          sx={{ pl: searching ? undefined : option.lvl * 2 }}
        >
          <HighlightedListItemText sx={{ py: 1 }} title={option.name}>
            {highlighQuery(option.name, state.inputValue)}
          </HighlightedListItemText>
          {!searching && !!option.subCompanies.length && (
            <IconButton
              id={`${id}-toggle-${option.id}`}
              size="small"
              onClick={handleToggleCompany(option.id)}
              onMouseDown={() => setPreventQueryOnBlur(Date.now())}
            >
              {option.expanded ? (
                <ExpandLessRoundedIcon />
              ) : (
                <ExpandMoreRoundedIcon />
              )}
            </IconButton>
          )}
        </Flex>
      </MenuItem>
    );
  };

  const renderInput = (params: AutocompleteRenderInputParams) => {
    const handleFocus = (e: React.FocusEvent<HTMLInputElement, Element>) => {
      params.inputProps.onFocus?.(e);
      setQuery('');
    };
    return (
      <TextField
        {...params}
        inputProps={{ ...params.inputProps, onFocus: handleFocus }}
        error={!!error}
        helperText={error}
        label={label}
      />
    );
  };

  return (
    <Autocomplete<TreeCompanyOption | '', false, true, false>
      id={id}
      options={options}
      value={selectedCompany || ''}
      onChange={handleChange}
      getOptionLabel={(o: any) => o?.name || ''}
      disableClearable
      openOnFocus
      renderOption={renderOption}
      isOptionEqualToValue={(o: any, v: any) => o?.id === v?.id}
      renderInput={renderInput}
      onInputChange={handleQueryChange}
      inputValue={query}
      PopperComponent={CompanySelectPopper}
    />
  );
};

export default CompanySelect;

const HighlightedListItemText = styled(Box)(
  ({ theme: { palette } }) => `
  overflow: hidden;
  text-overflow: ellipsis;
  & mark {
    background-color: transparent;
    font-weight: bold;
    color: ${palette.success.dark};
  }
`,
);

const preprareOptions = (
  companies: TreeCompany[],
  parentExpanded: boolean,
  lvl: number,
): TreeCompanyOption[] => {
  const result: TreeCompanyOption[] = [];
  for (const company of companies) {
    const expanded = parentExpanded && company.expanded;
    result.push({
      ...company,
      visible: parentExpanded,
      expanded: expanded,
      lvl: lvl,
    });
    result.push(...preprareOptions(company.subCompanies, expanded, lvl + 1));
  }
  return result;
};
