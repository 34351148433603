import { useQuery } from '@apollo/client';
import { Maybe } from 'graphql/jsutils/Maybe';
import { useMemo } from 'react';
import { useSearchParam } from 'react-use';
import {
  GetHistoricPlaylistDocument,
  GetPlaylistTemplateDocument,
} from '../../../graphql/graphql-operations';
import { compareByProp } from '../../../lib/compare';
import { PlaylistSelectedScene } from './PlaylistTypes';

//type OriginalPlaylistItem = GetHistoricPlaylistQuery['session']['playlist'][0];
interface OriginalPlaylistItem {
  readonly scene: {
    readonly id: string;
  };
  readonly params: readonly {
    readonly paramId: string;
    readonly valueId?: Maybe<string>;
  }[];
}

export const usePlaylistPreload = () => {
  const prevSessionId = useSearchParam('prevSessionId');
  const templateId = useSearchParam('templateId');

  const { data: historicData } = useQuery(GetHistoricPlaylistDocument, {
    variables: {
      sessionId: prevSessionId,
    },
    skip: !prevSessionId,
  });

  const { data: templateData } = useQuery(GetPlaylistTemplateDocument, {
    variables: {
      id: templateId,
    },
    skip: !!prevSessionId || !templateId,
  });

  const historicScenes: PlaylistSelectedScene[] = useMemo(() => {
    const playlist = [...(historicData?.session.playlist || [])];
    const result = playlist
      .sort(compareByProp('ordinal'))
      .map(mapPlaylistScene);
    return result;
  }, [historicData]);

  const templateScenes: PlaylistSelectedScene[] = useMemo(() => {
    const playlist = [...(templateData?.playlistTemplate.items || [])];
    const result = playlist
      .sort(compareByProp('ordinal'))
      .map(mapPlaylistScene);
    return result;
  }, [templateData]);

  return {
    scenes: historicScenes.length ? historicScenes : templateScenes,
    template: templateData,
  };
};

const mapPlaylistScene = (
  from: OriginalPlaylistItem,
): PlaylistSelectedScene => {
  return {
    sceneId: from.scene.id,
    params: from.params.reduce(
      (result, current) => ({ ...result, [current.paramId]: current.valueId }),
      {},
    ),
  };
};
