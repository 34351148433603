import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {
  ButtonProps,
  CardActionArea,
  CardContent,
  styled,
} from '@mui/material';
import { OutlinedCard } from '../../../base/components/OutlinedCard';

interface Props extends ButtonProps {
  children: React.ReactNode;
}

const HelpFaqItemChoiceButton = ({ children, ...buttonProps }: Props) => {
  return (
    <OutlinedCard>
      <CardActionArea {...buttonProps}>
        <StyledCardContent>
          {children}
          <ArrowRightIcon fontSize="large" color="primary" />
        </StyledCardContent>
      </CardActionArea>
    </OutlinedCard>
  );
};

export default HelpFaqItemChoiceButton;

const StyledCardContent = styled(CardContent)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
`;
