import { useQuery } from '@apollo/client';
import BackHandIcon from '@mui/icons-material/BackHand';
import { Card, CardContent } from '@mui/material';
import { useCallback } from 'react';
import { Flex } from '../../../base/components/Flex';
import {
  GetUserReactionTimeRangeReportDocument,
  GetUserReactionTimeSeriesDocument,
} from '../../../graphql/graphql-operations';
import useQueryErrorSnackbar from '../../../hooks/useQueryErrorSnackbar';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import { getEndOfDay } from '../../../lib/date';
import { UpdateAxisPointerEvent } from '../../components/Charts/ChartTypes';
import ContentWrapper from '../../components/ContentWrapper';
import { Loader } from '../../components/Loader';
import PageHeader from '../../components/PageHeader';
import { UserReportPage } from './UserReportsRoute';
import ReactionTimeChart from './components/ReactionTimeChart';
import ReportNav from './components/ReportNav';
import ReportValue from './components/ReportValue';
import useDebouncedQuery from './utils/useDeboucedQuery';
import { useReportUser } from './utils/useReportUser';

const UserTimeReactionReportPage = () => {
  const { _t } = useTranslationPrefix('UserTimeReactionPage');
  const { userId, userData, userLoading, backUrl } = useReportUser();

  const { data: reportData, refetch: reportRefetch } = useDebouncedQuery(
    GetUserReactionTimeRangeReportDocument,
    {
      variables: {
        userId: userId!,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      skip: !userId,
    },
  );
  const updateDate = useCallback(
    (e: UpdateAxisPointerEvent) => {
      const value = e.axesInfo.find((i) => i.axisDim === 'x')?.value;
      if (value) {
        reportRefetch({
          toDate: getEndOfDay(value).toISOString(),
        });
      }
    },
    [reportRefetch],
  );
  const {
    data: seriesData,
    refetch: seriesRefetch,
    error: seriesError,
    loading: seriesLoading,
  } = useQuery(GetUserReactionTimeSeriesDocument, {
    variables: {
      userId: userId!,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    skip: !userId,
  });

  useQueryErrorSnackbar(seriesError, seriesRefetch);
  const loading = userLoading || !reportData || seriesLoading;

  const formatDifference = (first?: number, last?: number) => {
    const diff = (last ?? 0) - (first ?? 0);
    const rounded = Math.round(diff * 100) / 100;
    return rounded.toFixed(2) + 's';
  };

  return (
    <ContentWrapper>
      <PageHeader
        title={_t('title', 'Report {{firstName}} {{lastName}}', {
          firstName: userData?.user.firstName,
          lastName: userData?.user.lastName,
        })}
        back={backUrl}
      ></PageHeader>
      <ReportNav currentPage={UserReportPage.UPPER_LIMB_REACTION_TIME} />
      {loading ? (
        <Loader />
      ) : (
        <>
          <Flex flexDirection="column" gap={2}>
            <Flex
              sx={{
                flexDirection: { xs: 'row', md: 'row' },
                gap: 2,
                flexGrow: 2,
              }}
            >
              <ReportValue
                label={_t('arm', 'Paže - rozdíl')}
                value={formatDifference(
                  reportData?.userReports?.reactionTimeChange?.leftArmFirst,
                  reportData?.userReports?.reactionTimeChange?.leftArmLast,
                )}
                secondLabel={_t('fingers', 'Prsty - rozdíl')}
                secondValue={formatDifference(
                  reportData?.userReports?.reactionTimeChange?.leftFingersFirst,
                  reportData?.userReports?.reactionTimeChange?.leftFingersLast,
                )}
                icon={<BackHandIcon fontSize="large" />}
                title={_t('leftHandImproved', 'Levá ruka')}
              />

              <ReportValue
                label={_t('arm', 'Paže')}
                value={formatDifference(
                  reportData?.userReports?.reactionTimeChange?.rightArmFirst,
                  reportData?.userReports?.reactionTimeChange?.rightArmLast,
                )}
                secondLabel={_t('fingers', 'Prsty - rozdíl')}
                secondValue={formatDifference(
                  reportData?.userReports?.reactionTimeChange
                    ?.rightFingersFirst,
                  reportData?.userReports?.reactionTimeChange?.rightFingersLast,
                )}
                icon={
                  <BackHandIcon
                    fontSize="large"
                    sx={{ transform: 'scaleX(-1)' }}
                  />
                }
                title={_t('rightHand', 'Pravá ruka - rozdíl')}
              />
            </Flex>
            <Card>
              <CardContent>
                <ReactionTimeChart
                  data={seriesData!.userReports.reactionTimeSeries}
                  onAxisPointerChange={updateDate}
                />
              </CardContent>
            </Card>
          </Flex>
        </>
      )}
    </ContentWrapper>
  );
};

export default UserTimeReactionReportPage;
