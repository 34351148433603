import { useTranslation } from 'react-i18next';
import { DateLike, getDayDiff } from '../../../lib/date';

interface Props {
  term: DateLike;
}

const TermDays = ({ term }: Props) => {
  const dayDiff = getDayDiff(term, new Date());
  const { t } = useTranslation();

  return <>{t('term.day', '{{count}} days', { count: Math.abs(dayDiff) })}</>;
};

export default TermDays;
