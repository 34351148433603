import { useTranslation } from 'react-i18next';
import { UserStatus } from '../../../graphql/graphql-operations';
import PatientsTable from '../../components/PatientsTable/PatientsTable';
import { SectionPaper } from '../../components/SectionPaper';
import { TopBar } from '../../components/TopBar';
import ArchivedPatientRowActions from './ArchivedPatientRowActions';

const ArchivedPatientsPage: React.VFC = () => {
  const { t } = useTranslation();
  return (
    <>
      <TopBar
        leftSideText={t('archivedPatients.topBar.title', 'Archived Patients')}
      />
      <SectionPaper>
        <PatientsTable
          patientStatus={UserStatus.SUSPENDED}
          rowActions={(rowData) => (
            <ArchivedPatientRowActions rowData={rowData} />
          )}
        />
      </SectionPaper>
    </>
  );
};

export default ArchivedPatientsPage;
