import { SvgIcon, SvgIconProps } from '@mui/material';

const UkIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <g
        transform="matrix(1.3556 0 0 1.3556 -21.54 -8.7205)"
        stroke-width=".27663"
      >
        <path
          d="m33.595 15.285c0 1.0346-0.17704 2.0277-0.50347 2.9517-0.38729 1.101-0.98758 2.0996-1.7511 2.9517-1.6211 1.8092-3.978 2.9489-6.5977 2.9489s-4.9766-1.1397-6.5977-2.9489c-0.7635-0.85203-1.3638-1.8507-1.7511-2.9517-0.32643-0.92395-0.50347-1.9171-0.50347-2.9517z"
          fill="#fcd000"
        />
        <path
          d="m33.595 15.285h-17.704c0-1.0346 0.17704-2.0249 0.50347-2.9489 0.38728-1.101 0.98758-2.0996 1.7483-2.9517 1.6238-1.8119 3.978-2.9517 6.6004-2.9517 2.6225 0 4.9766 1.1397 6.6004 2.9517 0.76074 0.85203 1.361 1.8507 1.7483 2.9517 0.32643 0.92395 0.50347 1.9143 0.50347 2.9489z"
          fill="#004aac"
        />
      </g>
    </SvgIcon>
  );
};

export default UkIcon;
