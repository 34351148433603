import { Card, CardContent } from '@mui/material';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import ContentWrapper from '../../components/ContentWrapper';
import HelpFaqSlide from '../../components/HelpFaq/HelpFaqSlide';
import { useHelpFaq } from '../../components/HelpFaq/provider';
import PageHeader from '../../components/PageHeader';

const HelpFaqPage = () => {
  const { _t } = useTranslationPrefix('HelpFaqPage');
  const { goBack, activeSlide, step } = useHelpFaq();

  return (
    <ContentWrapper>
      <PageHeader
        title={_t('title', 'Support')}
        back={step > 1 ? goBack : undefined}
      />
      <Card>
        <CardContent>
          <HelpFaqSlide slide={activeSlide} />
        </CardContent>
      </Card>
    </ContentWrapper>
  );
};

export default HelpFaqPage;
