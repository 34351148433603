import { useMutation, useQuery } from '@apollo/client';
import { Button } from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseDialog } from '../../../base/components/BaseDialog';
import { Flex } from '../../../base/components/Flex';
import FormikAutocomplete from '../../../base/components/form/FormikAutocomplete';
import FormikForm from '../../../base/components/form/FormikForm';
import {
  ApplicationSceneGroupFilterField,
  ApplicationSceneGroupOrderField,
  ApplicationSceneGroupStatus,
  EditApplicationSceneDocument,
  GetApplicationSceneGroupsDocument,
  GetApplicationSceneQuery,
  OrderDirection,
} from '../../../graphql/graphql-operations';
import useMutationSnackbar from '../../../hooks/useMutationSnackbar';

type Group = NonNullable<
  GetApplicationSceneQuery['applicationScene']['applicationSceneGroups']
>[0];

interface Props {
  applicationSceneId: string;
  applicationVersionId: string;
  open: boolean;
  groups: Group[];
  onClose: () => void;
}

interface FormData {
  groupId?: { id: string; label: string };
}

const ApplicationSceneAddGroupDialog = ({
  open,
  onClose,
  groups: currentGroups,
  applicationSceneId,
  applicationVersionId,
}: Props) => {
  const { t } = useTranslation();

  const editSceneHandler = useMutationSnackbar('editApplicationScene', {
    translationKey: 'applications.scene.editApplicationScene',
    onSuccessfullyCompleted: onClose,
  });

  const [editMutation] = useMutation(EditApplicationSceneDocument, {
    ...editSceneHandler,
  });

  const {
    data: groupsData,
    loading: optionsLoading,
    refetch: refetchOptions,
  } = useQuery(GetApplicationSceneGroupsDocument, {
    variables: {
      first: 10,
      filter: {
        status: ApplicationSceneGroupStatus.ACTIVE,
      },
      orderBy: {
        field: ApplicationSceneGroupOrderField.NAME,
        direction: OrderDirection.ASC,
      },
    },
  });

  const currentGroupIds = currentGroups?.map((group) => group.id) || [];
  const options =
    groupsData?.applicationSceneGroups?.nodes?.filter(
      (option) => !currentGroupIds.includes(option.id),
    ) || [];

  const handleQuery = (event: SyntheticEvent, value: string) => {
    refetchOptions({
      first: 10,
      filter: {
        status: ApplicationSceneGroupStatus.ACTIVE,
      },
      orderBy: {
        field: ApplicationSceneGroupOrderField.NAME,
        direction: OrderDirection.ASC,
      },
      filterBy: value
        ? {
            field: ApplicationSceneGroupFilterField.NAME,
            query: value,
          }
        : undefined,
    });
  };

  const handleSubmit = async (
    values: FormData,
    helpers: FormikHelpers<FormData>,
  ) => {
    if (values.groupId?.id) {
      await editMutation({
        variables: {
          scene: {
            applicationSceneId: applicationSceneId,
            applicationVersionId: applicationVersionId,
            applicationSceneGroupIds: [...currentGroupIds, values.groupId.id],
          },
        },
      });
    } else {
      helpers.setFieldError(
        'groupId',
        t(
          'applications.scene.addGroupDialog.groupIdRequired',
          'Program is required',
        ),
      );
    }
  };

  return (
    <BaseDialog
      open={open}
      title={t('applications.scene.addGroupDialog.title', 'Add to program')}
      onClose={onClose}
    >
      <Formik<FormData> initialValues={{}} onSubmit={handleSubmit}>
        {({ isSubmitting, setFieldValue, values }) => (
          <FormikForm>
            <FormikAutocomplete
              name="groupId"
              options={options.map((option) => ({
                id: option.id,
                label: option.name,
              }))}
              disabled={isSubmitting}
              onInputChange={handleQuery}
              loading={optionsLoading}
            />
            <Flex justifyContent="space-between">
              <Button
                id="application-scene-add-group-dialog-cancel"
                size="large"
                color="primary"
                onClick={onClose}
              >
                {t('common.cancel', 'Cancel')}
              </Button>
              <Button
                id="application-scene-add-group-dialog-confirm"
                type="submit"
                size="large"
                color="primary"
                variant="contained"
                disabled={isSubmitting}
              >
                {t('applications.scene.addGroupDialog.save', 'Save')}
              </Button>
            </Flex>
          </FormikForm>
        )}
      </Formik>
    </BaseDialog>
  );
};

export default ApplicationSceneAddGroupDialog;
