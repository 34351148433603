import { Box, BoxProps } from '@mui/material';
import sanitizeHtml from 'sanitize-html';

export const prepositions = (value?: string) => {
  return value
    ? value
        .toString()
        .replace(
          /(\s|^)(a|i|k|o|s|u|v|z)(\s(a|i|k|o|s|u|v|z))?(\s+)/gim,
          '$1$2$3\u00A0',
        )
    : '';
};

interface Props {
  html: string | null;
  id?: string;
  sx?: BoxProps['sx'];
}

const HtmlParser = ({ html, id, ...other }: Props) => {
  if (html == null || html === 'null') {
    return null;
  }

  const safeHtml = sanitizeHtml(prepositions(html), {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(['iframe', 'img']),
    allowedIframeHostnames: ['www.youtube.com', 'player.vimeo.com'],
    allowedAttributes: {
      img: ['src', 'id', 'alt', 'srcset', 'sizes', 'width', 'height'],
      iframe: ['src', 'id', 'width', 'height'],
      a: ['href', 'id', 'rel', 'type', 'name', 'target'],
      span: ['style'],
      strong: ['style'],
    },
  });

  return (
    <Box {...other} id={id} dangerouslySetInnerHTML={{ __html: safeHtml }} />
  );
};

export default HtmlParser;
