import { useQuery } from '@apollo/client';
import { Box, Card, CardContent, Typography } from '@mui/material';
import position1Img from '../../../assets/reports/fine-motorics-pos-1.png';
import position2Img from '../../../assets/reports/fine-motorics-pos-2.png';
import position3Img from '../../../assets/reports/fine-motorics-pos-3.png';
import position4Img from '../../../assets/reports/fine-motorics-pos-4.png';
import position5Img from '../../../assets/reports/fine-motorics-pos-5.png';
import { Flex } from '../../../base/components/Flex';
import {
  GetUserFineMotoricsReportSeriesDocument,
  SeriesAggregationInterval,
} from '../../../graphql/graphql-operations';
import useQueryErrorSnackbar from '../../../hooks/useQueryErrorSnackbar';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import ContentWrapper from '../../components/ContentWrapper';
import { Loader } from '../../components/Loader';
import PageHeader from '../../components/PageHeader';
import { UserReportPage } from './UserReportsRoute';

import BackHandIcon from '@mui/icons-material/BackHand';
import { EchartTheme } from '../../components/Charts/echarts_theme';
import InfoTooltip from '../../components/InfoTooltip';
import FineMotoricsChart from './components/FineMotoricsChart';
import ReportNav from './components/ReportNav';
import { useReportUser } from './utils/useReportUser';

const colorMapping = [2, 1, 3, 0, 4];

const UserFineMotoricsReportPage = () => {
  const { _t } = useTranslationPrefix('UserFineMotoricsReportPage');
  const { userId, userData, userLoading, backUrl } = useReportUser();

  const {
    data: seriesData,
    loading: seriesLoading,
    error: seriesError,
    refetch: seriesRefetch,
  } = useQuery(GetUserFineMotoricsReportSeriesDocument, {
    variables: {
      userId: userId!,
      seriesInterval: SeriesAggregationInterval.day,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    skip: !userId,
  });
  useQueryErrorSnackbar(seriesError, seriesRefetch);

  const loading = userLoading || seriesLoading;

  const renderLegend = (positionIndex: number, image: string) => {
    return (
      <Flex alignItems="center" gap={1}>
        <img height={32} alt="range" src={image} />
        <Flex flexDirection="column">
          <Typography component="span" variant="body3">
            {_t(`position${positionIndex}`, `Pozice ${positionIndex}`)}
          </Typography>
          <Box
            sx={{
              width: 34,
              height: 8,
              borderRadius: 4,
              backgroundColor:
                EchartTheme.theme.color[colorMapping[positionIndex - 1]],
            }}
          ></Box>
        </Flex>
      </Flex>
    );
  };
  return (
    <ContentWrapper>
      <PageHeader
        title={_t('title', 'Jemná motorika {{firstName}} {{lastName}}', {
          firstName: userData?.user.firstName,
          lastName: userData?.user.lastName,
        })}
        back={backUrl}
      ></PageHeader>
      <ReportNav currentPage={UserReportPage.FINE_MOTORICS} />
      {loading ? (
        <Loader />
      ) : (
        <>
          <Flex flexDirection="column" gap={2}>
            <Card>
              <CardContent>
                <Flex marginX={2} gap={1} flexDirection="column">
                  <Box flexDirection="column" alignItems="flex-start">
                    <Typography variant="h6" component="h2">
                      {_t('motionsList', 'Seznam pohybů')}
                      <InfoTooltip
                        title={_t(
                          'motionsListInfo',
                          'V seznamu jsou pohyby přehledně seskupeny podle barevných kategorií.',
                        )}
                      />
                    </Typography>
                  </Box>
                </Flex>
                <Flex
                  gap={4}
                  flexWrap="wrap"
                  justifyContent="space-around"
                  pl="14ch"
                  pr={6}
                >
                  <Flex alignItems="center">
                    <BackHandIcon fontSize="large" />
                    <Typography>{_t('leftHand', 'Levá ruka')}</Typography>
                  </Flex>
                  <Flex alignItems="center">
                    <BackHandIcon
                      fontSize="large"
                      sx={{ transform: 'scaleX(-1)' }}
                    />
                    <Typography>{_t('rightHand', 'Pravá ruka')}</Typography>
                  </Flex>
                </Flex>
                <FineMotoricsChart
                  data={seriesData!.userReports.fineMotoricsSeries}
                  colorIndexes={colorMapping}
                />
                <Flex gap={4} flexWrap="wrap" justifyContent="center">
                  {renderLegend(1, position1Img)}
                  {renderLegend(2, position2Img)}
                  {renderLegend(3, position3Img)}
                  {renderLegend(4, position4Img)}
                  {renderLegend(5, position5Img)}
                </Flex>
              </CardContent>
            </Card>
          </Flex>
        </>
      )}
    </ContentWrapper>
  );
};

export default UserFineMotoricsReportPage;
