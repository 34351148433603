import { CardActionArea, CardContent, CardProps } from '@mui/material';
import { Flex } from '../../../../base/components/Flex';
import { OutlinedCard } from '../../../../base/components/OutlinedCard';
import { FolderFragment } from '../../../../graphql/graphql-operations';

interface Props {
  folder: FolderFragment;
  onClick?: (template: FolderFragment) => void;
  children?: React.ReactNode;
  sx?: CardProps['sx'];
}

const PlaylistTemplateFolderCard = ({
  folder,
  onClick,
  children,
  sx,
}: Props) => {
  const renderCardContent = () => {
    return (
      <CardContent>
        <Flex flexDirection="column" flexGrow={1}>
          {children}
        </Flex>
      </CardContent>
    );
  };

  return (
    <OutlinedCard id={`playlist-template-folder-${folder.id}`} sx={sx}>
      {onClick ? (
        <CardActionArea onClick={() => onClick(folder)} sx={{ height: '100%' }}>
          {renderCardContent()}
        </CardActionArea>
      ) : (
        renderCardContent()
      )}
    </OutlinedCard>
  );
};

export default PlaylistTemplateFolderCard;
