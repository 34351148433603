import { getOperationName } from '@apollo/client/utilities';
import { Button } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { BaseDialog } from '../../../../base/components/BaseDialog';
import { Flex } from '../../../../base/components/Flex';
import FormikForm from '../../../../base/components/form/FormikForm';
import FormikTextField from '../../../../base/components/form/FormikTextField';
import {
  CreateApiKeyDocument,
  EditApiKeyDocument,
  GetApiKeysDocument,
} from '../../../../graphql/graphql-operations';
import { useMutationWithSnackbar } from '../../../../hooks/useMutationWithSnackbar';
import {
  TFunctionPrefixed,
  useTranslationPrefix,
} from '../../../../hooks/useTranslationPrefix';

export interface EditApiKeyDialogData {
  open: boolean;
  name?: string;
  id?: string;
}

interface FormValues {
  name: string;
}

interface Props extends EditApiKeyDialogData {
  companyId: string;
  onClose: () => void;
  onNewAccessToken: (accessToken: string) => void;
}

const EditApiKeyDialog = ({
  companyId,
  name,
  id,
  onNewAccessToken,
  ...dialogProps
}: Props) => {
  const { _t } = useTranslationPrefix('EditApiKeyDialog');
  const [createMutation] = useMutationWithSnackbar(
    'EditApiKeyDialog.createMutation',
    CreateApiKeyDocument,
    {
      onCompleted: (data) => {
        if (data?.createApiKey.accessToken) {
          onNewAccessToken(data.createApiKey.accessToken);
        }
        dialogProps.onClose();
      },
      refetchQueries: [getOperationName(GetApiKeysDocument)!],
    },
  );

  const [editMutation] = useMutationWithSnackbar(
    'EditApiKeyDialog.editMutation',
    EditApiKeyDocument,
    {
      onCompleted: dialogProps.onClose,
    },
  );

  const handleSubmit = async (values: FormValues) => {
    if (!id) {
      await createMutation({
        variables: {
          input: {
            companyId,
            name: values.name,
          },
        },
      });
    } else {
      await editMutation({
        variables: {
          input: {
            apiKeyId: id,
            name: values.name,
          },
        },
      });
    }
  };

  return (
    <>
      <BaseDialog
        {...dialogProps}
        title={
          id
            ? _t('editTitle', 'Edit API key')
            : _t('createTitle', 'Create API key')
        }
      >
        <Formik<FormValues>
          initialValues={{
            name: name || '',
          }}
          onSubmit={handleSubmit}
          validationSchema={getValidationSchema(_t)}
          enableReinitialize={true}
        >
          {({ isSubmitting }) => (
            <FormikForm>
              <Flex flexDirection="column" gap={3} alignItems="center">
                <FormikTextField
                  name="name"
                  label={_t('name', 'Name')}
                  fullWidth
                />
                <Flex gap={2}>
                  <Button
                    id="edit-company-note-cancel"
                    size="large"
                    color="primary"
                    onClick={dialogProps.onClose}
                  >
                    {_t('cancel', 'Cancel')}
                  </Button>
                  <Button
                    id="submit-create-api-key"
                    type="submit"
                    variant="contained"
                    size="large"
                    disabled={isSubmitting}
                  >
                    {id ? _t('save', 'Save') : _t('create', 'Create')}
                  </Button>
                </Flex>
              </Flex>
            </FormikForm>
          )}
        </Formik>
      </BaseDialog>
    </>
  );
};

export default EditApiKeyDialog;

export const getValidationSchema = (_t: TFunctionPrefixed) =>
  Yup.object({
    name: Yup.string()
      .min(3, _t('form.nameMin', 'Minimal number of characters is 3'))
      .max(255, _t('form.nameMax', 'Maximum number of characters is 255'))
      .required(_t('form.nameRequired', 'Name is required')),
  });
