import { Grid } from '@mui/material';
import { groupBy } from 'ramda';
import { useMemo } from 'react';
import { SessionPlaylistItemReportFragmentFragment } from '../../../../graphql/graphql-operations';
import ReportUserCard from './ReportUserCard';

const REPORT_USER_UNKNOWN = 'unknown';

interface Props {
  data: SessionPlaylistItemReportFragmentFragment[];
}

const ReportUserList = ({ data }: Props) => {
  const reportsPerUser = useMemo(
    () => groupBy((row) => row.launchedByUser?.id || REPORT_USER_UNKNOWN, data),
    [data],
  );
  const userIds = Object.keys(reportsPerUser);

  return (
    <Grid container spacing={2}>
      {userIds.map((userId) => (
        <Grid key={userId} id={`user-list-report-${userId}`} item xs={12}>
          <ReportUserCard data={reportsPerUser[userId]} />
        </Grid>
      ))}
    </Grid>
  );
};

export default ReportUserList;
