import { SvgIcon, SvgIconProps } from '@mui/material';

const CEIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        d="m9.8557 19.252a7.1437 7.1437 0 0 1-0.71437 0.03581 7.1437 7.1437 0 0 1 0-14.287 7.1437 7.1437 0 0 1 0.71437 0.035808v2.1431a5.0006 5.0006 0 0 0-0.71437-0.035808 5.0006 5.0006 0 0 0 0 10.001 5.0006 5.0006 0 0 0 0.71437-0.0358z"
        stroke-width=".071437"
      />
      <path
        d="m22 19.252a7.1437 7.1437 0 0 1-0.71437 0.03581 7.1437 7.1437 0 0 1 0-14.287 7.1437 7.1437 0 0 1 0.71437 0.035808v2.1431a5.0006 5.0006 0 0 0-0.71437-0.035808 5.0006 5.0006 0 0 0-4.8849 3.929h4.1705v2.1431h-4.1705a5.0006 5.0006 0 0 0 4.8849 3.929 5.0006 5.0006 0 0 0 0.71437-0.03581z"
        stroke-width=".071437"
      />
    </SvgIcon>
  );
};

export default CEIcon;
