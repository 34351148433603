import { Card } from '@mui/material';
import { Box, styled, useTheme } from '@mui/system';
import { BaseTypography } from '../../../base/components/BaseTypography';
import { Flex } from '../../../base/components/Flex';
import ImagePlaceholder from '../../../base/components/ImagePlaceholder/ImagePlaceholder';
import { GetVrDeviceScenesQuery } from '../../../graphql/graphql-operations';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import LaunchSceneForm from './LaunchSceneForm';

type LaunchableScene = GetVrDeviceScenesQuery['vrDevice']['scenes'][0];

interface Props {
  vrDeviceId: string;
  userId?: string;
  scene: LaunchableScene;
}

const SelectApplicationSceneParams = ({
  scene: ls,
  vrDeviceId,
  userId,
}: Props) => {
  const { _t } = useTranslationPrefix('launchSceneDialog.selectSceneParams');
  const { spacing } = useTheme();
  const { scene, applicationVersion } = ls;
  return (
    <Flex justifyContent="space-evenly">
      <Flex flexDirection="column">
        <LaunchSceneForm
          vrDeviceId={vrDeviceId}
          userId={userId}
          launchableScene={ls}
        ></LaunchSceneForm>
      </Flex>

      <SelectApplicationSceneParamsCard>
        {scene.imageUrl ? (
          <SelectApplicationSceneParamsCardImage
            alt={scene.name_t}
            src={scene.imageUrl}
          />
        ) : (
          <SelectApplicationSceneParamsImagePlaceholder />
        )}
        <Box padding={spacing(2)}>
          <BaseTypography variant="h5">{scene.name_t}</BaseTypography>
          <SelectApplicationSceneParamsCardLabel>
            {_t('description', 'Description')}
          </SelectApplicationSceneParamsCardLabel>
          <BaseTypography variant="body1">{scene.description}</BaseTypography>
          <SelectApplicationSceneParamsCardLabel>
            {_t('appName', 'App name')}
          </SelectApplicationSceneParamsCardLabel>
          <BaseTypography variant="body1">
            {applicationVersion.application.name}
          </BaseTypography>
          <SelectApplicationSceneParamsCardLabel>
            {_t('appVersion', 'App version')}
          </SelectApplicationSceneParamsCardLabel>
          <BaseTypography variant="body1">
            {applicationVersion.version}
          </BaseTypography>
        </Box>
      </SelectApplicationSceneParamsCard>
    </Flex>
  );
};

export default SelectApplicationSceneParams;

const SelectApplicationSceneParamsCard = styled(Card)(`
    min-width: 250px;
    max-width: 400px;
`);

const SelectApplicationSceneParamsCardImage = styled('img')(`
        width: 100%;
        height: auto;
`);

const SelectApplicationSceneParamsImagePlaceholder = styled(ImagePlaceholder)(`
        width: 100%;
        height: 180px;
`);

const SelectApplicationSceneParamsCardLabel = styled('div')(
  ({ theme: { spacing, palette } }) => `
    margin-top: ${spacing(1)};
    text-transform: uppercase;
    color: ${palette.text.secondary};
`,
);
