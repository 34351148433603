import { useQuery } from '@apollo/client';
import ArchiveRoundedIcon from '@mui/icons-material/ArchiveRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import UnarchiveRoundedIcon from '@mui/icons-material/UnarchiveRounded';
import { Button, IconButton } from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { BaseTypography } from '../../../base/components/BaseTypography';
import ConfirmDialog from '../../../base/components/ConfirmDialog/ConfirmDialog';
import { Flex } from '../../../base/components/Flex';
import { SimpleTable } from '../../../base/components/SimpleTable';
import { Loader } from '../../../client/components/Loader';
import {
  EditApiKeyDocument,
  GetApiKeysDocument,
  GetApiKeysQuery,
  GetCompanyDocument,
  RegenerateApiKeyDocument,
  UserStatus,
} from '../../../graphql/graphql-operations';
import { useMutationWithSnackbar } from '../../../hooks/useMutationWithSnackbar';
import useQueryErrorSnackbar from '../../../hooks/useQueryErrorSnackbar';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import { SectionPaper } from '../../components/SectionPaper';
import { TopBar } from '../../components/TopBar';
import ApiKeyViewDialog from './components/ApiKeyViewDialog';
import EditApiKeyDialog, {
  EditApiKeyDialogData,
} from './components/EditApiKeyDialog';

type ApiKey = GetApiKeysQuery['apiKeys'][0];

interface ConfirmDialogData {
  apiKeyId: string;
  name: string;
  status: UserStatus;
}

const CompanyApiKeysPage = () => {
  const { _t, t } = useTranslationPrefix('CompanyApiKeysPage');
  const [editDialogData, setEditDialogData] = useState<EditApiKeyDialogData>({
    open: false,
  });
  const [regenerateApiKeyCofirm, setRegenerateApiKeyCofirm] =
    useState<ConfirmDialogData | null>(null);
  const [confirmDialogData, setConfirmDialogData] =
    useState<ConfirmDialogData | null>(null);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const { companyId } = useParams<{ companyId: string }>();
  const { data } = useQuery(GetCompanyDocument, {
    variables: {
      companyIdInput: {
        companyId: companyId,
      },
    },
    fetchPolicy: 'cache-first',
    skip: !companyId,
  });
  const company = data?.company;

  const {
    data: apiKeyData,
    loading,
    error,
    refetch,
  } = useQuery(GetApiKeysDocument, {
    variables: {
      input: {
        companyId: companyId,
      },
    },
    skip: !companyId,
  });
  useQueryErrorSnackbar(error, refetch);

  const [changeApiKeyStatus, { loading: statusChangeInProgress }] =
    useMutationWithSnackbar(
      'CompanyApiKeysPage.statusChangeMutation',
      EditApiKeyDocument,
      {
        onCompleted: () => setConfirmDialogData(null),
      },
    );
  const [regenerateApiKey, { loading: regeneratingApiKey }] =
    useMutationWithSnackbar(
      'CompanyApiKeysPage.regenerateMutation',
      RegenerateApiKeyDocument,
      {
        onCompleted: (data) => {
          setAccessToken(data.regenerateApiKey.accessToken!);
          setRegenerateApiKeyCofirm(null);
        },
      },
    );

  const apiKeys = apiKeyData?.apiKeys || [];

  const handleEditDialogClose = () => {
    setEditDialogData({ open: false });
  };

  const handleStatusChangeConfirm = () => {
    if (confirmDialogData) {
      changeApiKeyStatus({
        variables: {
          input: {
            apiKeyId: confirmDialogData.apiKeyId,
            status: confirmDialogData.status,
          },
        },
      });
    }
  };

  const handleRegenerateConfirm = () => {
    if (regenerateApiKeyCofirm) {
      regenerateApiKey({
        variables: {
          input: {
            apiKeyId: regenerateApiKeyCofirm.apiKeyId,
          },
        },
      });
    }
  };

  const renderRowAction = (row: ApiKey) => {
    return row.status === UserStatus.ACTIVE ? (
      <Flex gap={2} justifyContent="end">
        <IconButton
          id={`regenerate-api-key-${row.id}`}
          onClick={() =>
            setRegenerateApiKeyCofirm({
              name: row.name || '',
              apiKeyId: row.id,
              status: UserStatus.SUSPENDED,
            })
          }
        >
          <SyncProblemIcon />
        </IconButton>
        <IconButton
          id={`edit-api-key-${row.id}`}
          onClick={() =>
            setEditDialogData({ open: true, name: row.name || '', id: row.id })
          }
        >
          <EditRoundedIcon />
        </IconButton>
        <IconButton
          id={`archive-api-key-${row.id}`}
          color="error"
          onClick={() =>
            setConfirmDialogData({
              name: row.name || '',
              apiKeyId: row.id,
              status: UserStatus.SUSPENDED,
            })
          }
        >
          <ArchiveRoundedIcon />
        </IconButton>
      </Flex>
    ) : (
      <>
        <IconButton
          color="success"
          id={`unarchive-api-key-${row.id}`}
          onClick={() =>
            setConfirmDialogData({
              name: row.name || '',
              apiKeyId: row.id,
              status: UserStatus.ACTIVE,
            })
          }
        >
          <UnarchiveRoundedIcon />
        </IconButton>
      </>
    );
  };

  return (
    <>
      <TopBar
        leftSideText={_t('title', 'API keys of {{companyName}}', {
          companyName: company?.name,
        })}
        actions={
          <Button
            id="create-api-key"
            variant="contained"
            onClick={() => setEditDialogData({ open: true })}
            size="large"
          >
            {_t('createApiKey', 'Create API key')}
          </Button>
        }
      />
      {loading ? (
        <Loader />
      ) : (
        <SectionPaper sx={{ mt: 2 }}>
          <SimpleTable
            id="api-keys-table"
            columns={[
              { id: 'name', defaultTrans: 'Name' },
              {
                id: 'status',
                defaultTrans: 'status',
                getLabel: (val: UserStatus) => t(`enum.UserStatus.${val}`),
              },
            ]}
            data={[...apiKeys]}
            rowId="id"
            translationPrefix="CompanyApiKeysPage.table"
            searchConfig={{
              defaultPlaceholder: 'Search {{count}} API keys',
              filterBy: 'name',
              defaultTitle: 'API key list',
            }}
            renderRowActions={renderRowAction}
          />
        </SectionPaper>
      )}
      <EditApiKeyDialog
        {...editDialogData}
        companyId={companyId!}
        onClose={handleEditDialogClose}
        onNewAccessToken={setAccessToken}
      />
      <ConfirmDialog
        open={!!confirmDialogData}
        title={
          confirmDialogData?.status === UserStatus.ACTIVE
            ? _t('unarchiveConfirm', 'Unarchive key {{name}}?', {
                name: confirmDialogData?.name,
              })
            : _t('archiveConfirm', 'Archive key {{name}}?', {
                name: confirmDialogData?.name,
              })
        }
        onClose={() => setConfirmDialogData(null)}
        onConfirm={handleStatusChangeConfirm}
        confirmDisabled={statusChangeInProgress}
      />
      <ApiKeyViewDialog
        value={accessToken}
        onClose={() => setAccessToken(null)}
      />
      <ConfirmDialog
        open={!!regenerateApiKeyCofirm}
        onClose={() => setRegenerateApiKeyCofirm(null)}
        title={_t('regenerateKey', 'Regenerate API key {{name}}?', {
          name: confirmDialogData?.name,
        })}
        onConfirm={handleRegenerateConfirm}
        confirmDisabled={regeneratingApiKey}
      >
        <BaseTypography textAlign="center" pb={4}>
          {_t(
            'regenerateWarning',
            'This will make previosly generated key invalid. From now only newly generated key will work for api calls.',
          )}
        </BaseTypography>
      </ConfirmDialog>
    </>
  );
};

export default CompanyApiKeysPage;
