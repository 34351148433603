import { styled } from '@mui/material';
import { Form, FormikContextType, FormikProvider } from 'formik';
interface Props {
  width?: string;
}

interface ProviderProps<T = any> {
  formik: FormikContextType<T>;
  children?: React.ReactNode;
  width?: string;
}

export const FormikFormProvider = ({
  formik,
  children,
  width,
}: ProviderProps) => (
  <StyledFormikProvider value={formik} width={width}>
    <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
      {children}
    </form>
  </StyledFormikProvider>
);

const StyledFormikProvider = styled(FormikProvider)<{ width?: string }>(
  ({ theme, width }) => ({
    width: width ? width : theme.spacing(40),
    margin: theme.spacing(0, 5.5),
    display: 'flex',
    flexDirection: 'column',
    '& >div:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  }),
);

const FormikForm = styled(Form)<Props>(({ theme, width }) => ({
  width: width ? width : theme.spacing(40),
  margin: theme.spacing(0, 5.5),
  display: 'flex',
  flexDirection: 'column',
  '& >div:not(:last-child)': {
    marginBottom: theme.spacing(2),
  },
}));

export default FormikForm;
