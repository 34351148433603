import { useTranslation } from 'react-i18next';
import {
  PatientAdminFragmentFragment,
  UserSessionFragmentFragment,
} from '../../../graphql/graphql-operations';
import { joinStrings } from '../../../lib/strings';
import { SectionPaper } from '../../components/SectionPaper';
import { TopBar } from '../../components/TopBar';
import UserSessionsTable from './UserSessionsTable';
import UserSessionSummary from './UserSessionSummary';

interface Props {
  vrSessionId?: string;
  actions?: React.ReactNode;
  user: UserData;
}

export type UserSession = UserSessionFragmentFragment;
export type UserData = PatientAdminFragmentFragment;

const UserDetail: React.VFC<Props> = ({
  vrSessionId,
  user,
  actions,
}: Props) => {
  const { id: userId, firstName, lastName } = user;
  const { t } = useTranslation();

  return (
    <>
      <TopBar
        leftSideText={joinStrings(' ', firstName, lastName)}
        actions={actions}
      />
      {user.sessionCumulativeStatistic && (
        <UserSessionSummary statisctics={user.sessionCumulativeStatistic} />
      )}

      <SectionPaper
        title={t('vrSessionDetail.pastSessions.title', 'Past sessions')}
        style={{ marginTop: '16px' }}
      >
        <UserSessionsTable vrSessionId={vrSessionId} userId={userId} />
      </SectionPaper>
    </>
  );
};
export default UserDetail;
