import { AppBar as MuiAppBar, Box, styled } from '@mui/material';
import LiveSessions from '../../admin/components/LiveSessions/LiveSessions';
import EnviromentWarningBar, {
  ENV_WARNING_BAR_HEIGHT,
} from '../../base/components/EnviromentWarningBar/EnviromentWarningBar';
import { Flex } from '../../base/components/Flex';
import { StreamLogo } from '../../base/components/StreamLogo';
import { TopBarCompanySelector } from '../../base/components/TopBarCompanySelector';
import HasAnyPermission from '../../base/components/UserProvider/HasPermission';
import { UserPermission } from '../../graphql/graphql-operations';
import AppBarMenu from './AppBarMenu';

const appBarHeight = '3.75rem';
export const APP_BAR_HEIGHT = `calc( ${appBarHeight} + ${ENV_WARNING_BAR_HEIGHT} )`;

const AppBar = () => {
  return (
    <MedicalAppBar>
      <EnviromentWarningBar />
      <Flex width="100%" height={appBarHeight}>
        <BarContent>
          <LogoContainer>
            <Logo />
          </LogoContainer>
          <LiveSessions />
        </BarContent>
        <HasAnyPermission
          val={[
            UserPermission.SWITCH_COMPANIES,
            UserPermission.MANAGE_SUBSIDIARIES,
          ]}
        >
          <Flex
            minWidth="20ch"
            maxWidth="30ch"
            alignItems="center"
            justifyContent="end"
          >
            <TopBarCompanySelector color="inherit" />
          </Flex>
        </HasAnyPermission>
        <AppBarMenu />
      </Flex>
    </MedicalAppBar>
  );
};

export default AppBar;

const MedicalAppBar = styled(MuiAppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  boxShadow: 'none',
  height: APP_BAR_HEIGHT,
  flexDirection: 'column',
}));

const LogoContainer = styled(Flex)(
  ({ theme }) => `
  max-width: 200px;
  fill: ${theme.palette.common.white};
`,
);

const Logo = styled(StreamLogo)`
  width: auto;
  height: 100%;
  margin-left: 1rem;
`;

const BarContent = styled(Flex)(({ theme }) => ({
  height: '100%',
  width: '100%',
  padding: theme.spacing(1, 1, 1, 0),
}));

export const AppBarHeight = styled(Box)({
  height: APP_BAR_HEIGHT,
});
