import { useQuery } from '@apollo/client';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ManualsDocument } from '../../../graphql/graphql-operations';
import useQueryErrorSnackbar from '../../../hooks/useQueryErrorSnackbar';
import { CardList } from '../../components/CardList';
import ManualCard from './ManualCard';

const ManualList = () => {
  const { t } = useTranslation();
  const { data, loading, error, refetch } = useQuery(ManualsDocument);
  const manuals = data?.manuals || [];
  useQueryErrorSnackbar(error, refetch);
  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <CardList
          id="manual-list"
          list={manuals}
          card={ManualCard}
          title={t('manualList.title', 'Manual list')}
          filterField="name"
          placeholderTransKey={'manualList.placeholder'}
        />
      )}
    </>
  );
};

export default ManualList;
