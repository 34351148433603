import { useQuery } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { useEffect, useMemo, useState } from 'react';
import {
  ChangePlaylistTemplateFolderCompanyAssignmentDocument,
  GetPlaylistTemplateFolderCompaniesDocument,
} from '../../../../graphql/graphql-operations';
import { useMutationWithSnackbar } from '../../../../hooks/useMutationWithSnackbar';
import {
  RankedCompany,
  useOrderedActiveCompanies,
} from '../../../../hooks/useOrderedActiveCompanies';
import useQueryErrorSnackbar from '../../../../hooks/useQueryErrorSnackbar';

export interface AssignableCompany extends RankedCompany {
  selected: boolean;
}

interface Params {
  folderId?: string;
  onCompleted: () => void;
}

const listsAreTheSame = (list1: string[], list2: string[]) => {
  return (
    list1.every((v) => list2.includes(v)) &&
    list2.every((v) => list1.includes(v))
  );
};

const usePlaylistFolderAssignCompanies = ({
  folderId,
  onCompleted,
}: Params) => {
  const companiesRaw = useOrderedActiveCompanies();

  const [selectedCompanies, setSelectedCompanies] = useState<string[]>([]);
  const { data, error, refetch } = useQuery(
    GetPlaylistTemplateFolderCompaniesDocument,
    {
      variables: {
        input: {
          id: folderId,
        },
      },
      skip: !folderId,
    },
  );
  useQueryErrorSnackbar(error, refetch);

  const [changeAssignment, { loading: submitting }] = useMutationWithSnackbar(
    'ChangePlaylistTemplateFolderCompanyAssignmentMutation',
    ChangePlaylistTemplateFolderCompanyAssignmentDocument,
    {
      onCompleted: onCompleted,
      refetchQueries: [
        getOperationName(GetPlaylistTemplateFolderCompaniesDocument)!,
      ],
    },
  );

  const originalSelectedCompanies = useMemo(
    () =>
      data?.folder?.assignedCompanies
        ? data?.folder?.assignedCompanies.map((c) => c.id)
        : [],
    [data?.folder?.assignedCompanies],
  );

  useEffect(
    () => setSelectedCompanies(originalSelectedCompanies),
    [setSelectedCompanies, originalSelectedCompanies],
  );

  const companies = useMemo(() => {
    return companiesRaw.map((c) => ({
      ...c,
      selected: selectedCompanies.includes(c.id),
    }));
  }, [companiesRaw, selectedCompanies]);

  const handleAssignCompanies = async () => {
    const assignedCompanies = selectedCompanies.filter(
      (c) => !originalSelectedCompanies.includes(c),
    );
    const unAssignedCompanies = originalSelectedCompanies.filter(
      (c) => !selectedCompanies.includes(c),
    );

    await changeAssignment({
      variables: {
        input: {
          folderId: folderId!,
          assignedCompanies: assignedCompanies,
          unAssignedCompanies: unAssignedCompanies,
        },
      },
    });
  };

  const disabled = useMemo(
    () =>
      submitting ||
      listsAreTheSame(originalSelectedCompanies, selectedCompanies),
    [originalSelectedCompanies, selectedCompanies, submitting],
  );

  return {
    companies,
    setSelectedCompanies,
    assignCompaniesDisabled: disabled,
    assignCompanies: handleAssignCompanies,
  };
};

export default usePlaylistFolderAssignCompanies;
