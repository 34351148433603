import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import {
  alpha,
  createTheme,
  PaletteColor,
  PaletteOptions,
  ThemeOptions,
} from '@mui/material/styles';
import { SwitchProps } from 'formik-mui';
import React from 'react';

const spacing = (factor: number) => `${0.5 * factor}rem`;
//lightColors: PaletteOptions
export const lightColors = {
  mode: 'light',
  common: {
    black: '#000',
    white: '#FFF',
  },
  primary: {
    lighter: '#E5DCFE',
    light: '#A78AFC',
    main: '#7B4FFB',
    dark: '#5235A7',
    darker: '#291A54',
    contrastText: '#FFF',
  },
  secondary: {
    lighter: '#FEF7D3',
    light: '#FBE66C',
    main: '#F9D923',
    dark: '#A69117',
    darker: '#53480C',
    contrastText: '#000',
  },
  success: {
    lighter: '#D2F5E8',
    light: '#6ADEB3',
    main: '#20CE8D',
    dark: '#15895E',
    darker: '#0B452F',
    contrastText: '#FFF',
  },
  warning: {
    lighter: '#FFEDCC',
    light: '#FFC355',
    main: '#FFA500',
    dark: '#15895E',
    darker: '#553700',
    contrastText: '#FFF',
  },
  error: {
    lighter: '#FDD1D9',
    light: '#F76781',
    main: '#F31B42',
    dark: '#A2122C',
    darker: '#510916',
    contrastText: '#FFF',
  },
  white: {
    main: '#FFFFFF',
    light: '#687FFD',
    dark: '#E8E8E9',
    contrastText: '#7B4FFB',
  },
  bgContrast: {
    lighter: '#E5DCFE',
    light: '#A78AFC',
    main: '#7B4FFB',
    dark: '#5235A7',
    darker: '#291A54',
    contrastText: '#FFF',
  },
  offwhite: {
    main: '#F2F2F3',
    light: '#F2F2F3',
    dark: '#E5DCFE',
    contrastText: '#000',
  },
  neutral: {
    main: '#000',
    light: '#5D5B62',
    dark: '#000',
    contrastText: '#FFF',
  },
  text: {
    primary: '#000',
    secondary: '#000',
  },
  grey: {
    50: '#F2F2F3',
    100: '#D1D0D2',
    200: '#B9B9BC',
    300: '#A2A1A5',
    400: '#8B8A8F',
    500: '#747279',
    600: '#5D5B62',
    700: '#45434C',
    800: '#2E2C35',
    900: '#141A1F',
  },
  background: {
    default: '#FAFAFA',
    paper: '#FFF',
  },
};

const baseThemeOptions: ThemeOptions = {
  typography: (palette) => ({
    htmlFontSize: 16,
    fontFamily: `'Inter', sans-serif`,
    fontWeightBold: 700,
    fontWeightMedium: 600,
    fontWeightRegular: 400,
    fontWeightLight: 200,

    h1: {
      fontSize: '3.25rem',
      lineHeight: '4rem',
      fontWeight: 700,
      letterSpacing: '-0.03em',
    },
    h2: {
      fontSize: '2.5rem',
      lineHeight: '3rem',
      fontWeight: 700,
      letterSpacing: '-0.02em',
    },
    h3: {
      fontSize: '2rem',
      lineHeight: '2.5rem',
      fontWeight: 700,
    },
    h4: {
      fontSize: '1.5rem',
      lineHeight: '2rem',
      fontWeight: 600,
    },
    h5: {
      fontSize: '1.25rem',
      lineHeight: '1.75rem',
      fontWeight: 600,
    },
    h6: {
      fontSize: '1.125rem',
      lineHeight: '1.75rem',
      fontWeight: 600,
    },
    body1: {
      /* normal */
      fontSize: '1rem',
      lineHeight: '1.5rem',
    },
    body2: {
      /* large */
      fontSize: '1.125rem',
      lineHeight: '1.75rem',
    },
    caption: {
      /* extra small */
      fontSize: '0.75rem',
      lineHeight: '1rem',
      color: palette.text.primary,
    },
    ...{
      body3: {
        /* small */
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
      },
    },
  }),

  components: {
    MuiChip: {
      defaultProps: {
        deleteIcon: React.createElement(CancelOutlinedIcon),
      },
      styleOverrides: {
        root: {
          borderRadius: '.625rem',
        },
        filled: ({ theme }) => ({
          backgroundColor: 'rgba(0, 0, 0, 0.05)',
        }),
        sizeSmall: {
          height: '2.5rem',
        },
        labelSmall: {
          fontSize: '0.875rem',
          lineHeight: '1rem',
          paddingLeft: '.75rem',
          paddingright: '.75rem',
        },
        deleteIconSmall: {
          marginRight: '.75rem',
        },
        sizeMedium: {
          height: '3.5rem',
        },
        labelMedium: {
          fontSize: '1rem',
          lineHeight: '1.5rem',
          paddingLeft: '1rem',
          paddingright: '1rem',
        },
        deleteIconMedium: {
          marginRight: '1rem',
        },
        outlined: {
          border: 'none',
          boxShadow: 'inset 0 0 0 1px',
        },
        colorOffwhite: ({ theme: { palette } }) => ({
          color: palette.text.primary,
          '&.MuiChip-outlined': {
            boxShadow: `inset 0 0 0 1px ${palette.grey[100]}`,
          },
          '&.Mui-selected': {
            color: palette.text.primary,
            backgroundColor: palette.offwhite.dark,
          },
        }),
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        disableElevation: true,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          textTransform: 'initial',
          fontWeight: 400,
          borderRadius: 200,
          '&.Mui-disabled': {
            color: theme.palette.grey[300],
          },
        }),
        contained: ({ theme }) => ({
          '&.Mui-disabled': {
            backgroundColor: theme.palette.grey[50],
          },
        }),
        outlinedSizeLarge: {
          padding: 'calc( 1.25rem - 1px ) calc( 2.5rem - 1px )',
        },
        outlinedSizeMedium: {
          padding: 'calc( 1rem - 1px ) calc( 2rem - 1px )',
        },
        outlinedSizeSmall: {
          padding: 'calc( .75rem - 1px ) calc( 1.75rem - 1px )',
        },
        sizeLarge: {
          fontSize: '1.125rem',
          lineHeight: '1.5rem',
          padding: '1.25rem 2.5rem',
        },
        sizeMedium: {
          fontSize: '1rem',
          lineHeight: '1.5rem',
          padding: '1rem 2rem',
        },
        sizeSmall: {
          fontSize: '0.875rem',
          lineHeight: '1rem',
          padding: '.75rem 1.75rem',
        },
        iconSizeLarge: {
          '& > svg:first-of-type': {
            fontSize: '1.25rem',
          },
        },
        iconSizeMedium: {
          '& > svg:first-of-type': {
            fontSize: '1.25rem',
          },
        },
        iconSizeSmall: {
          '& > svg:first-of-type': {
            fontSize: '1rem',
          },
        },

        outlined: ({ theme: { palette }, ownerState: { color } }) => {
          const colorOption: PaletteColor = palette[
            (color || 'primary') as keyof PaletteOptions
          ] as PaletteColor;
          return {
            '&:hover': {
              backgroundColor: colorOption?.main
                ? alpha(colorOption.main, 0.2)
                : 'transparent',
            },
          };
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        color: 'neutral',
      },
      styleOverrides: {
        sizeLarge: {
          fontSize: '1.75rem',
          padding: '1.125rem',
        },
        sizeMedium: {
          fontSize: '1.5rem',
          padding: '1rem',
        },
        sizeSmall: {
          fontSize: '1.25rem',
          padding: '.625rem',
        },

        root: ({ theme }) => ({
          '&.Mui-disabled': {
            color: theme.palette.grey[300],
          },
        }),
      },
    },
    MuiIcon: {
      defaultProps: {
        fontSize: 'inherit',
      },
    },
    MuiSvgIcon: {
      defaultProps: {
        fontSize: 'inherit',
      },
    },
    MuiCard: {
      defaultProps: {
        elevation: 24,
      },
      styleOverrides: {
        root: {
          borderRadius: spacing(1.25),
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: spacing(2),
          '&:last-child': {
            paddingBottom: spacing(2),
          },
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.grey[50],
          borderRadius: '50px',
        }),
        bar: {
          borderRadius: '50px',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderBottomWidth: '2px',
          borderColor: theme.palette.grey[300],
          opacity: 0.2,
        }),
        vertical: {
          borderRightWidth: '2px',
        },
      },
    },
    MuiListItemText: {
      defaultProps: {
        primaryTypographyProps: {
          fontSize: '1em',
        },
      },
    },

    MuiCardActionArea: {
      styleOverrides: {
        //fix for FF where for some reason fonst are reset
        root: {
          font: 'inherit',
          lineHeight: 'inherit',
          letterSpacing: 'inherit',
        },
      },
    },
    MuiCollapse: {
      styleOverrides: {
        root: {
          '@media print': {
            height: 'auto',
            visibility: 'visible',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.primary,
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: '0.625rem',
          borderColor: theme.palette.grey[200],
        }),
        notchedOutline: ({ theme }) => ({
          borderColor: theme.palette.grey[200],
        }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        colorWhite: ({ theme }) => ({
          color: theme.palette.white.main,
          '& .MuiSvgIcon-root': { color: theme.palette.white.main },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.grey[200],
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: `${theme.palette.white.main} !important`,
          },
        }),
        colorBgContrast: ({ theme }) => ({
          color: theme.palette.bgContrast.main,
          '& .MuiSvgIcon-root': { color: theme.palette.bgContrast.main },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor:
              theme.palette.mode === 'dark'
                ? theme.palette.grey[200]
                : theme.palette.bgContrast.main,
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: `${theme.palette.bgContrast.main} !important`,
          },
        }),
        input: {
          '&::placeholder': {
            opacity: 0.5,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '& .MuiListItemText-root': {
            margin: 0,
          },
          '& .MuiListItemText-root .MuiTypography-body1': {
            lineHeight: '1.4375rem',
          },
        },
        icon: {
          color: 'rgba(0, 0, 0, 0.54)',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.MuiFormLabel-colorWhite': {
            color: theme.palette.white.main,
          },
          '&.MuiFormLabel-colorBgContrast': {
            color: theme.palette.bgContrast.main,
          },
        }),
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          position: 'absolute',
          bottom: '0',
          left: '0',
          right: '0',
          transform: 'translate(0, 100%)',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: 'rgba(0, 0, 0, 0.23)',
        },
      },
    },
    MuiSwitch: {
      defaultProps: {
        focusVisibleClassName: '.Mui-focusVisible',
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          width: 42,
          height: 26,
          padding: 0,
        },
        switchBase: ({ theme, ownerState: { color } }) => {
          const typedColor = color as SwitchProps['color'];
          const paletteColor =
            typedColor && typedColor !== 'default'
              ? theme.palette[typedColor]
              : theme.palette.primary;

          return {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
              color: theme.palette.white.main,
              transform: 'translateX(16px)',

              '& + .MuiSwitch-track': {
                opacity: 1,
                border: 0,
              },
              '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
              },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
              color: paletteColor.main,
              border: '6px solid #fff',
            },
            '&.Mui-disabled': {
              '& + .MuiSwitch-track': {
                opacity: 0.7,
              },
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
              color: theme.palette.grey[50],
            },
          };
        },

        thumb: {
          boxSizing: 'border-box',
          width: 22,
          height: 22,
        },
        track: ({ theme }) => ({
          borderRadius: 26 / 2,
          backgroundColor: theme.palette.grey[50],
          opacity: 1,
          transition: theme.transitions.create(['background-color'], {
            duration: 500,
          }),
        }),
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderBottomWidth: 1,
          borderBottomStyle: 'solid',
          borderBottomColor: theme.palette.grey[100],
        }),
        flexContainer: ({ theme }) => ({
          [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
          },
        }),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(1),
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),

          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: theme.palette.grey[100],
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          marginBottom: -1,
          '&.Mui-selected': {
            backgroundColor: theme.palette.primary.lighter,
          },
        }),
        wrapped: () => ({
          flexShrink: 1,
        }),
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& th': {
            borderBottom: 'none',
            backgroundColor: `${theme.palette.primary.main}12`,
          },
          '& th:first-child': {
            borderRadius: '8px 0 0 8px',
          },
          '& th:last-child': {
            borderRadius: '0 8px 8px 0',
          },
        }),
      },
    },
  },
  shadows: [
    'none',
    '0px 2px 1px -1px rgba(0,0,0,0.1),0px 1px 1px 0px rgba(0,0,0,0.07),0px 1px 3px 0px rgba(0,0,0,0.06)',
    '0px 3px 1px -2px rgba(0,0,0,0.1),0px 2px 2px 0px rgba(0,0,0,0.07),0px 1px 5px 0px rgba(0,0,0,0.06)',
    '0px 3px 3px -2px rgba(0,0,0,0.1),0px 3px 4px 0px rgba(0,0,0,0.07),0px 1px 8px 0px rgba(0,0,0,0.06)',
    '0px 2px 4px -1px rgba(0,0,0,0.1),0px 4px 5px 0px rgba(0,0,0,0.07),0px 1px 10px 0px rgba(0,0,0,0.06)',
    '0px 3px 5px -1px rgba(0,0,0,0.1),0px 5px 8px 0px rgba(0,0,0,0.07),0px 1px 14px 0px rgba(0,0,0,0.06)',
    '0px 3px 5px -1px rgba(0,0,0,0.1),0px 6px 10px 0px rgba(0,0,0,0.07),0px 1px 18px 0px rgba(0,0,0,0.06)',
    '0px 4px 5px -2px rgba(0,0,0,0.1),0px 7px 10px 1px rgba(0,0,0,0.07),0px 2px 16px 1px rgba(0,0,0,0.06)',
    '0px 5px 5px -3px rgba(0,0,0,0.1),0px 8px 10px 1px rgba(0,0,0,0.07),0px 3px 14px 2px rgba(0,0,0,0.06)',
    '0px 5px 6px -3px rgba(0,0,0,0.1),0px 9px 12px 1px rgba(0,0,0,0.07),0px 3px 16px 2px rgba(0,0,0,0.06)',
    '0px 6px 6px -3px rgba(0,0,0,0.1),0px 10px 14px 1px rgba(0,0,0,0.07),0px 4px 18px 3px rgba(0,0,0,0.06)',
    '0px 6px 7px -4px rgba(0,0,0,0.1),0px 11px 15px 1px rgba(0,0,0,0.07),0px 4px 20px 3px rgba(0,0,0,0.06)',
    '0px 7px 8px -4px rgba(0,0,0,0.1),0px 12px 17px 2px rgba(0,0,0,0.07),0px 5px 22px 4px rgba(0,0,0,0.06)',
    '0px 7px 8px -4px rgba(0,0,0,0.1),0px 13px 19px 2px rgba(0,0,0,0.07),0px 5px 24px 4px rgba(0,0,0,0.06)',
    '0px 7px 9px -4px rgba(0,0,0,0.1),0px 14px 21px 2px rgba(0,0,0,0.07),0px 5px 26px 4px rgba(0,0,0,0.06)',
    '0px 8px 9px -5px rgba(0,0,0,0.1),0px 15px 22px 2px rgba(0,0,0,0.07),0px 6px 28px 5px rgba(0,0,0,0.06)',
    '0px 8px 10px -5px rgba(0,0,0,0.1),0px 16px 24px 2px rgba(0,0,0,0.07),0px 6px 30px 5px rgba(0,0,0,0.06)',
    '0px 8px 11px -5px rgba(0,0,0,0.1),0px 17px 26px 2px rgba(0,0,0,0.07),0px 6px 32px 5px rgba(0,0,0,0.06)',
    '0px 9px 11px -5px rgba(0,0,0,0.1),0px 18px 28px 2px rgba(0,0,0,0.07),0px 7px 34px 6px rgba(0,0,0,0.06)',
    '0px 9px 12px -6px rgba(0,0,0,0.1),0px 19px 29px 2px rgba(0,0,0,0.07),0px 7px 36px 6px rgba(0,0,0,0.06)',
    '0px 10px 13px -6px rgba(0,0,0,0.1),0px 20px 31px 3px rgba(0,0,0,0.07),0px 8px 38px 7px rgba(0,0,0,0.06)',
    '0px 10px 13px -6px rgba(0,0,0,0.1),0px 21px 33px 3px rgba(0,0,0,0.07),0px 8px 40px 7px rgba(0,0,0,0.06)',
    '0px 10px 14px -6px rgba(0,0,0,0.1),0px 22px 35px 3px rgba(0,0,0,0.07),0px 8px 42px 7px rgba(0,0,0,0.06)',
    '0px 11px 14px -7px rgba(0,0,0,0.1),0px 23px 36px 3px rgba(0,0,0,0.07),0px 9px 44px 8px rgba(0,0,0,0.06)',
    '0px 11px 15px -7px rgba(0,0,0,0.1),0px 24px 38px 3px rgba(0,0,0,0.07),0px 9px 46px 8px rgba(0,0,0,0.06)',
  ],
};

export const lightTheme = createTheme({
  spacing: spacing,
  palette: {
    ...(lightColors as PaletteOptions),
  },
  ...baseThemeOptions,
});

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    white: PaletteColor;
    bgContrast: PaletteColor;
    offwhite: PaletteColor;
    neutral: PaletteColor;
  }
  interface PaletteOptions {
    white: PaletteColor;
    bgContrast: PaletteColor;
    offwhite: PaletteColor;
    neutral: PaletteColor;
  }

  interface PaletteColor {
    darker?: string;
    lighter?: string;
  }
  interface SimplePaletteColorOptions {
    darker?: string;
    lighter?: string;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    white: true;
    bgContrast: true;
  }
  /*
  interface ButtonClasses {
    outlinedWhite: string;
  }
  */
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    white: true;
    bgContrast: true;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    white: true;
    bgContrast: true;
    neutral: true;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    white: true;
    bgContrast: true;
    offwhite: true;
  }
  interface ChipClasses {
    colorOffwhite: true;
    colorBgContrast: true;
    colorWhite: true;
  }
}

declare module '@mui/material/TextField' {
  interface TextFieldPropsColorOverrides {
    white: true;
    bgContrast: true;
  }
}

declare module '@mui/material/InputBase' {
  interface InputBasePropsColorOverrides {
    white: true;
    bgContrast: true;
  }

  interface InputBaseClasses {
    colorWhite: string;
    colorBgContrast: true;
  }
}

declare module '@mui/material/FormLabel' {
  interface FormLabelClasses {
    colorWhite: string;
    colorBgContrast: true;
  }
}
