import { Box, Button, Typography } from '@mui/material';
import { Flex } from '../../../base/components/Flex';
import HtmlParser from '../HtmlParser';
import HelpFaqItemChoices from './HelpFaqItemChoices';
import HelpFaqItemContent from './HelpFaqItemContent';
import HelpFaqItemTextField from './HelpFaqItemTextField';
import { useHelpFaq } from './provider';
import {
  HelpFaqButtonType,
  HelpFaqContentButton,
  HelpFaqContentItem,
  HelpFaqContentItemType,
  HelpFaqContentSlide,
} from './types';

interface Props {
  slide: HelpFaqContentSlide | null;
}

const isButtonDisabled = (
  button: HelpFaqContentButton,
  isNextDisabled: boolean,
) => {
  if (
    [HelpFaqButtonType.CONFIRM, HelpFaqButtonType.NEXT].includes(button.type)
  ) {
    return isNextDisabled;
  }
  return false;
};

const HelpFaqSlide = ({ slide }: Props) => {
  const { onButtonClick, canMoveOn } = useHelpFaq();

  const renderItem = (item: HelpFaqContentItem) => {
    switch (item.type) {
      case HelpFaqContentItemType.CHOICES:
        return <HelpFaqItemChoices item={item} />;
      case HelpFaqContentItemType.CONTENT:
        return <HelpFaqItemContent item={item} />;
      case HelpFaqContentItemType.TEXT_INPUT:
        return <HelpFaqItemTextField item={item} />;
      case HelpFaqContentItemType.TEXT_AREA:
        return <HelpFaqItemTextField item={item} multiline />;
      default:
        return null;
    }
  };

  if (slide == null) {
    return null;
  }

  const { items, buttons, title } = slide;
  return (
    <Box width="100%" p={2}>
      {title && (
        <Typography variant="h5" mb={4}>
          <HtmlParser html={title} />
        </Typography>
      )}
      {items?.map((item, index) => (
        <Flex mt={3} key={`${item.type}_${index}`} width="100%">
          {renderItem(item)}
        </Flex>
      ))}
      <Flex justifyContent="space-between" mt={3}>
        {buttons?.map((button) => (
          <Button
            id={button.id.toString()}
            key={button.id}
            disabled={isButtonDisabled(button, canMoveOn)}
            onClick={() => onButtonClick(button)}
            color="primary"
            variant={button.variant || 'contained'}
          >
            {button.title}
          </Button>
        ))}
      </Flex>
    </Box>
  );
};

export default HelpFaqSlide;
