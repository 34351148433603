import { useState } from '@hookstate/core';
import { useTranslation } from 'react-i18next';
import { VrDeviceType } from '../../../base/components/VrDevicesProvider/VrDevicesProvider';
import LaunchSceneDialog from '../../components/LaunchSceneDialog/LaunchSceneDialog';
import { SectionPaper } from '../../components/SectionPaper';
import { TopBar } from '../../components/TopBar';
import VrDeviceList from '../../components/VrDeviceList/VrDeviceList';
import VrDevicesActions from './VrDevicesActions';

const VrDevices = () => {
  const { t } = useTranslation();
  const state = useState<{ selectedDevice: VrDeviceType | null }>({
    selectedDevice: null,
  });
  const handleDialogClose = () => state.selectedDevice.set(null);
  const handleDialogOpen = (device: VrDeviceType) =>
    state.selectedDevice.set(device);
  const selectedDeviceId = state.selectedDevice.get()?.id;

  return (
    <>
      <TopBar
        leftSideText={t('vrDevices.topBar.title', 'Headsets')}
        actions={<VrDevicesActions />}
      />
      <SectionPaper title={t('vrDevices.section.title', 'Headset list')}>
        <VrDeviceList onDeviceSelected={handleDialogOpen} />
      </SectionPaper>
      <LaunchSceneDialog
        open={!!selectedDeviceId}
        vrDeviceId={selectedDeviceId}
        onClose={handleDialogClose}
      />
    </>
  );
};

export default VrDevices;
