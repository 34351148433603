import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import React, { useMemo } from 'react';
import { BaseTypography } from '../../../../base/components/BaseTypography';
import { SessionPlaylistItemReportFragmentFragment } from '../../../../graphql/graphql-operations';
import { useTranslationPrefix } from '../../../../hooks/useTranslationPrefix';
import { compareByProp } from '../../../../lib/compare';
import { formatTime } from '../../../../lib/date';
import { formatDuration } from '../../../../lib/duration';

interface Props {
  data: SessionPlaylistItemReportFragmentFragment[];
}

const ReportSceneList = ({ data }: Props) => {
  const { _t } = useTranslationPrefix('ReportSceneList');
  const rows = useMemo(
    () => data.sort(compareByProp('endedAt', 'desc')),
    [data],
  );

  const renderHeader = () => {
    return (
      <TableRow>
        <TableCell>
          <BaseTypography>{_t('sceneName', 'Scene')}</BaseTypography>
        </TableCell>
        <TableCell>
          <BaseTypography>{_t('startedAt', 'Started at')}</BaseTypography>
        </TableCell>
        <TableCell>
          <BaseTypography>{_t('timeInVR', 'Time in VR')}</BaseTypography>
        </TableCell>
        <TableCell>
          <BaseTypography>{_t('headset', 'Headset name')}</BaseTypography>
        </TableCell>
        <TableCell align="center"></TableCell>
      </TableRow>
    );
  };

  const renderRow = (row: SessionPlaylistItemReportFragmentFragment) => {
    return (
      <TableRow key={row.id}>
        <TableCell>
          <BaseTypography fontSize="1rem" fontWeight="bold">
            {row.scene.name_t}
          </BaseTypography>
        </TableCell>
        <TableCell>
          <BaseTypography fontSize="1rem" fontWeight="bold">
            {row.startedAt && formatTime(row.startedAt)}
          </BaseTypography>
        </TableCell>
        <TableCell>
          <BaseTypography fontSize="1rem" fontWeight="bold">
            {formatDuration(row.durationMs)}
          </BaseTypography>
        </TableCell>
        <TableCell>
          <BaseTypography
            fontSize="1rem"
            fontWeight="bold"
            maxWidth="20ch"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {row.vrDevice.name}
          </BaseTypography>
        </TableCell>
        <TableCell>
          <Tooltip
            title={
              row.completed
                ? _t('completed', 'Completed')
                : _t('notCompleted', 'Did not complete')
            }
          >
            <CheckCircleOutlineRoundedIcon
              color={row.completed ? 'success' : 'disabled'}
            />
          </Tooltip>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Box overflow="auto">
      <Table sx={{ whiteSpace: 'nowrap' }}>
        <colgroup>
          <col style={{ width: '40%' }} />
          <col style={{ width: '15%' }} />
          <col style={{ width: '15%' }} />
          <col style={{ width: '15%' }} />
          <col style={{ width: '5%' }} />
        </colgroup>
        <TableHead>{renderHeader()}</TableHead>
        <TableBody>{rows.map(renderRow)}</TableBody>
      </Table>
    </Box>
  );
};

export default React.memo(ReportSceneList);
