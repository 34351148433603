import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import PeopleOutlineRoundedIcon from '@mui/icons-material/PeopleOutlineRounded';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import { Button, CardContent, CardProps } from '@mui/material';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BaseTypography } from '../../../../base/components/BaseTypography';
import ConfirmDialog from '../../../../base/components/ConfirmDialog/ConfirmDialog';
import { Flex } from '../../../../base/components/Flex';
import { IconButtonOutlined } from '../../../../base/components/IconButton';
import { ImageBackground } from '../../../../base/components/ImageBackground';
import { OutlinedCard } from '../../../../base/components/OutlinedCard';
import HasAnyPermission from '../../../../base/components/UserProvider/HasAnyPermission';
import { useUser } from '../../../../base/components/UserProvider/useUserHook';
import {
  DeletePlaylistTemplateDocument,
  PlaylistTemplateFragmentFragment,
  UserPermission,
} from '../../../../graphql/graphql-operations';
import { useMutationWithSnackbar } from '../../../../hooks/useMutationWithSnackbar';
import { useTranslationPrefix } from '../../../../hooks/useTranslationPrefix';
import { joinStrings } from '../../../../lib/strings';
import UploadCertificateTemplateDialog from './UploadCertificateTemplateDialog';

interface Props {
  template: PlaylistTemplateFragmentFragment;
  sx?: CardProps['sx'];
  onPlayClick?: () => void;
}

const PlaylistTemplateHeaderCard = ({ template, sx, onPlayClick }: Props) => {
  const { hasAnyPermission, user } = useUser();
  const { _t } = useTranslationPrefix('PlaylistTemplateHeaderCard');
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [uploadCertificateTemplateOpen, setUploadCertificateTemplateOpen] =
    useState(false);
  const navigate = useNavigate();
  const [deleteMutation] = useMutationWithSnackbar(
    'PlaylistTemplateDetailPage.deleteMutation',
    DeletePlaylistTemplateDocument,
    {
      onCompleted: () => {
        navigate('/playlist-templates');
      },
    },
  );

  const handleDelete = () => {
    deleteMutation({
      variables: {
        input: {
          id: template.id,
        },
      },
    });
  };
  const handleDialogClose = () => setUploadCertificateTemplateOpen(false);

  const certificateTemplate = template.certificateTemplate;

  const imageUrl = template.items.find((i) => i.scene.imageUrl)?.scene
    ?.imageUrl;

  const canEdit: boolean =
    (template.owner.id === user?.id &&
      hasAnyPermission(UserPermission.MANAGE_MY_PLAYLIST_TEMPLATES)) ||
    hasAnyPermission(UserPermission.MANAGE_OTHERS_PLAYLIST_TEMPLATES);

  return (
    <>
      <OutlinedCard id={`playlist-template-${template.id}`} sx={sx}>
        <CardContent>
          <Flex flexDirection={{ xs: 'column', md: 'row' }} gap={2}>
            <ImageBackground
              src={imageUrl}
              sx={{ aspectRatio: '16/10', width: { xs: '100%', md: '30%' } }}
              darken={false}
            ></ImageBackground>
            <Flex flexDirection="column" flexGrow={1} overflow="hidden">
              <Flex alignItems="center" gap={2}>
                <BaseTypography
                  variant="h3"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  color="text.secondary"
                >
                  {template.name}
                </BaseTypography>
                {canEdit && (
                  <IconButtonOutlined
                    id="edit-playlist"
                    component={Link}
                    to={`/playlist-templates/edit?templateId=${template.id}`}
                    color="bgContrast"
                    title={_t('edit', 'Edit')}
                  >
                    <ModeEditOutlineOutlinedIcon />
                  </IconButtonOutlined>
                )}
                {!!onPlayClick ? (
                  <Button
                    id="play-playlist"
                    onClick={onPlayClick}
                    sx={{ ml: 'auto' }}
                    startIcon={<PlayArrowOutlinedIcon />}
                    color="success"
                  >
                    {_t('play', 'Play')}
                  </Button>
                ) : undefined}
              </Flex>
              <BaseTypography variant="body3" color="grey.500">
                {_t('createdBy', 'Created by: {{ name }}', {
                  name: joinStrings(
                    ' ',
                    template.owner.firstName,
                    template.owner.lastName,
                  ),
                })}
              </BaseTypography>
              <Flex
                alignItems="center"
                justifyContent="space-between"
                pt={2}
                mt="auto"
                gap={2}
              >
                {canEdit && (
                  <Button
                    id="delete-playlist"
                    variant="outlined"
                    color="error"
                    onClick={() => setDeleteConfirmOpen(true)}
                  >
                    {_t('deletePlaylist', 'Delete playlist')}
                  </Button>
                )}
                <Flex gap={2}>
                  {canEdit && (
                    <Button
                      id="upload-certificate"
                      variant="outlined"
                      color="bgContrast"
                      onClick={() => setUploadCertificateTemplateOpen(true)}
                    >
                      {!!certificateTemplate?.name
                        ? _t('editCertificate', 'Edit certificate')
                        : _t('uploadCertificate', 'Upload certificate')}
                    </Button>
                  )}
                  <HasAnyPermission
                    val={[
                      UserPermission.MANAGE_TRAINING_ASSIGNMENTS,
                      UserPermission.MANAGE_OTHERS_PLAYLIST_TEMPLATES,
                    ]}
                  >
                    <Button
                      id="assign-playlist"
                      variant="contained"
                      color="primary"
                      component={Link}
                      startIcon={<PeopleOutlineRoundedIcon />}
                      to={`/playlist-templates/${template.id}/assign`}
                    >
                      {_t('assignPlaylist', 'Assign playlist')}
                    </Button>
                  </HasAnyPermission>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </CardContent>
      </OutlinedCard>
      <ConfirmDialog
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
        onConfirm={handleDelete}
        title={_t('deleteTemplateConfirm', 'Delete template {{name}}?', {
          name: template.name,
        })}
      />
      <UploadCertificateTemplateDialog
        open={uploadCertificateTemplateOpen}
        onClose={handleDialogClose}
        title={_t(
          'uploadCertificateTemplateConfirm',
          'Upload certificate template?',
        )}
        playlistId={template.id}
        certificateTemplate={certificateTemplate}
      />
    </>
  );
};

export default PlaylistTemplateHeaderCard;
