import { useQuery } from '@apollo/client';
import { useUser } from '../../../base/components/UserProvider/useUserHook';
import { GetUserGroupsDocument } from '../../../graphql/graphql-operations';
import useCompany from '../../../lib/useCompany';
import { getAvailableRolesByStreamAndCurrentUserRole } from './helpers';

export const useAvailableRoles = () => {
  const { user } = useUser();
  const { company } = useCompany();

  const availableRoles =
    company?.streamType && user
      ? getAvailableRolesByStreamAndCurrentUserRole(
          company.streamType,
          user.role,
          company.features,
        )
      : [];

  return { availableRoles };
};

export const useUserAvailableGroups = (companyId: string | null) => {
  const { data } = useQuery(GetUserGroupsDocument, {
    variables: {
      input: {
        companyId: companyId,
      },
    },
    fetchPolicy: 'cache-and-network',
  });
  return { groups: data?.userGroups };
};
