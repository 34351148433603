import { useMutation, useQuery } from '@apollo/client';
import { Button } from '@mui/material';
import { useFormik } from 'formik';
import { BaseDialog } from '../../../../base/components/BaseDialog';
import { Flex } from '../../../../base/components/Flex';
import { FormikFormProvider } from '../../../../base/components/form/FormikForm';
import {
  EditPlaylistTemplateFolderDocument,
  GetPlaylistTemplateFolderDocument,
  GetPlaylistTemplatesDocument,
  GetUnassignedPlaylistTemplatesDocument,
} from '../../../../graphql/graphql-operations';
import { useTranslationPrefix } from '../../../../hooks/useTranslationPrefix';
import { getOperationName } from '@apollo/client/utilities';
import { FormikSelectMultiple } from '../../../../base/components/form/FormikSelectMultiple';

interface FolderForm {
  playlistTemplates: any[];
}

interface Props {
  folderId?: string | number | null;
  open: boolean;
  onClose: () => void;
  addedPlaylistTemplates?: { id: number | string; name: string }[];
}

const PlaylistTemplateFolderAddDialog = ({
  folderId,
  open,
  onClose,
  addedPlaylistTemplates = [],
}: Props) => {
  const { _t } = useTranslationPrefix('PlaylistTemplateFolderAddtDialog');

  const { data } = useQuery(GetUnassignedPlaylistTemplatesDocument, {
    variables: {
      input: {
        folderId,
      },
    },
    skip: !folderId,
  });

  const [editPlaylistFolder] = useMutation(EditPlaylistTemplateFolderDocument);

  const handleSubmit = async (values: FolderForm) => {
    editPlaylistFolder({
      variables: {
        input: {
          id: folderId!.toString(),
          playlistTemplateIds: [
            ...addedPlaylistTemplates.map((playlistItem) => playlistItem.id),
            ...values.playlistTemplates,
          ],
        },
      },
      onCompleted: () => {
        onClose();
        formik.resetForm();
      },
      refetchQueries: [
        getOperationName(GetPlaylistTemplateFolderDocument)!,
        getOperationName(GetPlaylistTemplatesDocument)!,
        getOperationName(GetUnassignedPlaylistTemplatesDocument)!,
      ],
    });
  };

  const formik = useFormik<FolderForm>({
    onSubmit: handleSubmit,
    enableReinitialize: true,
    initialValues: {
      playlistTemplates: [],
    },
  });

  const playlistTemplates = data?.unassignedPlaylistTemplates || [];

  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      title={_t('title', 'Add playlist to folder')}
      disableEnforceFocus
    >
      <>
        <FormikFormProvider formik={formik}>
          <FormikSelectMultiple
            name="playlistTemplates"
            fullWidth
            label={_t('form.playlist', 'Playlists')}
            color="primary"
            sx={{ width: '100%' }}
            options={playlistTemplates.map((playlistItem) => ({
              id: playlistItem.id,
              name: playlistItem.name,
            }))}
            formValues={formik.values}
            setFieldValue={formik.setFieldValue}
          />
          <Flex
            justifyContent="center"
            mt={3}
            gap={3}
            flexDirection={{ xs: 'column-reverse', md: 'row' }}
          >
            <Button
              id="playlist-folder-assignment-cancel"
              color="primary"
              variant="outlined"
              onClick={onClose}
              disabled={formik.isSubmitting}
            >
              {_t('form.cancel', 'Cancel')}
            </Button>
            <Button
              id="playlist-folder-assignment-confirm"
              type="submit"
              color="primary"
              variant="contained"
              disabled={formik.isSubmitting}
            >
              {_t('form.submit', 'Add')}
            </Button>
          </Flex>
        </FormikFormProvider>
      </>
    </BaseDialog>
  );
};

export default PlaylistTemplateFolderAddDialog;
