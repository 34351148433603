import { IconButtonProps } from '@mui/material';
import React, { useRef } from 'react';
import { useUnmount } from 'react-use';
import {
  DecreaseDeviceVolumeDocument,
  IncreaseDeviceVolumeDocument,
} from '../../../graphql/graphql-operations';
import { useMutationWithSnackbar } from '../../../hooks/useMutationWithSnackbar';

interface ResultButtonProps {
  onMouseDown?: IconButtonProps['onMouseDown'];
  onMouseUp?: IconButtonProps['onMouseUp'];
  onTouchStart?: IconButtonProps['onTouchStart'];
  onTouchEnd?: IconButtonProps['onTouchEnd'];
  onMouseOut?: IconButtonProps['onMouseOut'];
  onClick?: IconButtonProps['onClick'];
  onDoubleClick?: IconButtonProps['onDoubleClick'];
}

interface Result {
  increaseButtonProps: ResultButtonProps;
  decreaseButtonProps: ResultButtonProps;
}

type VolumeDirection = 'up' | 'down';

const useDeviceVolume = (deviceId?: string): Result => {
  const timeout = useRef<NodeJS.Timeout>();
  const isTouch = useRef<boolean>(false);

  useUnmount(() => timeout.current && clearTimeout(timeout.current));

  const [increaseDeviceVolumeMutation] = useMutationWithSnackbar(
    'DeviceVolume',
    IncreaseDeviceVolumeDocument,
    { skipSuccessSnackbar: true },
  );
  const [decreaseDeviceVolumeMutation] = useMutationWithSnackbar(
    'DeviceVolume',
    DecreaseDeviceVolumeDocument,
    { skipSuccessSnackbar: true },
  );

  const changeVolume = (direction: VolumeDirection) => {
    if (!deviceId) {
      return;
    }
    if (direction === 'up') {
      increaseDeviceVolumeMutation({
        variables: {
          deviceId: deviceId,
        },
      });
    } else {
      decreaseDeviceVolumeMutation({
        variables: {
          deviceId: deviceId,
        },
      });
    }
  };

  const stopVolumeChange = () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
  };

  const continueVolumeChange = (direction: VolumeDirection) => {
    changeVolume(direction);
    timeout.current = setTimeout(() => continueVolumeChange(direction), 250);
  };

  const startVolumeChange = (direction: VolumeDirection) => {
    stopVolumeChange();
    changeVolume(direction);
    timeout.current = setTimeout(() => continueVolumeChange(direction), 500);
  };

  const handleMouseDown =
    (direction: VolumeDirection) => (e: React.MouseEvent) => {
      e.stopPropagation();
      isTouch.current = false;
      startVolumeChange(direction);
    };

  const handleTouchStart =
    (direction: VolumeDirection) => (e: React.TouchEvent) => {
      isTouch.current = true;
      e.stopPropagation();
      startVolumeChange(direction);
    };

  const handleMouseOut = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!isTouch.current) {
      stopVolumeChange();
    }
  };

  const handleMouseUp = (e: React.MouseEvent) => {
    e.stopPropagation();
    stopVolumeChange();
  };

  const handleTouchEnd = (e: React.TouchEvent) => {
    e.stopPropagation();
    e.preventDefault();
    e.nativeEvent.stopImmediatePropagation();
    e.nativeEvent.preventDefault();

    stopVolumeChange();
  };

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    e.nativeEvent.preventDefault();
  };

  return {
    increaseButtonProps: {
      onMouseDown: handleMouseDown('up'),
      onTouchStart: handleTouchStart('up'),
      onMouseUp: handleMouseUp,
      onTouchEnd: handleTouchEnd,
      onMouseOut: handleMouseOut,
      onClick: handleClick,
      onDoubleClick: handleClick,
    },
    decreaseButtonProps: {
      onMouseDown: handleMouseDown('down'),
      onTouchStart: handleTouchStart('down'),
      onMouseUp: handleMouseUp,
      onTouchEnd: handleTouchEnd,
      onMouseOut: handleMouseOut,
      onClick: handleClick,
      onDoubleClick: handleClick,
    },
  };
};

export default useDeviceVolume;
