import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded';
import { CardActionArea, CardContent, styled } from '@mui/material';
import { Box } from '@mui/system';
import { Link } from 'react-router-dom';
import { BaseTypography } from '../../../../base/components/BaseTypography';
import { Flex } from '../../../../base/components/Flex';
import { IconButtonFilled } from '../../../../base/components/IconButton';
import { SelectableCard } from '../../../../base/components/SelectableCard';
import {
  GetUsersQuery,
  UserRole,
} from '../../../../graphql/graphql-operations';
import { useTranslationPrefix } from '../../../../hooks/useTranslationPrefix';
import { formatDate, getDayDiff } from '../../../../lib/date';
import { joinStrings } from '../../../../lib/strings';
import { NamedAvatar } from '../../../components/NamedAvatar';

type UserType = NonNullable<GetUsersQuery['users']['nodes']>[0];

interface Props {
  user: UserType;
  onClick?: () => void;
  link?: string;
  selected?: boolean;
  onDeleteClick?: () => void;
}

const hasTrainingStatus = (status?: UserType['trainingCompletion']) => {
  if (!status) {
    return false;
  }
  if (status.completed && status.completedDate) {
    return true;
  }
  if (!status.completed && status.dueDate) {
    return true;
  }
  return false;
};

const UserListCard = ({
  user,
  onClick,
  link,
  selected,
  onDeleteClick,
}: Props) => {
  const { t, _t } = useTranslationPrefix('UserListCard');

  const renderUserDetail = () => {
    return hasTrainingStatus(user.trainingCompletion) || user.groups.length ? (
      <Box overflow="hidden" mt="auto">
        {user.groups.length ? (
          <Flex overflow="hidden" alignItems="center">
            <PersonOutlineRoundedIcon sx={{ mr: 0.5 }} />
            <BaseTypography
              variant="body3"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              title={joinStrings(', ', ...user.groups.map((g) => g.name))}
            >
              {joinStrings(', ', ...user.groups.map((g) => g.name))}
            </BaseTypography>
          </Flex>
        ) : null}

        {renderTrainingStatus(user.trainingCompletion)}
      </Box>
    ) : null;
  };

  const renderTrainingStatus = (status: UserType['trainingCompletion']) => {
    if (!status || !hasTrainingStatus(status)) return null;

    return (
      <Flex mt="auto" sx={{ overflow: 'hidden' }}>
        {status.completed && status.completedDate && (
          <CheckCircleOutlineRoundedIcon color="success" sx={{ mr: 0.5 }} />
        )}
        {!status.completed && (
          <ErrorOutlineRoundedIcon color="error" sx={{ mr: 0.5 }} />
        )}
        {status.completed ? (
          <TrainingStatusText variant="caption">
            {formatDate(status.completedDate!)}
          </TrainingStatusText>
        ) : (
          <TrainingStatusText variant="caption">
            <b>
              {t('term.day', '{{count}} days', {
                count: getDayDiff(new Date(), status.dueDate),
              })}
            </b>
            {'\u00a0'}
            {_t('pastDue', 'past due')}
          </TrainingStatusText>
        )}
        {}
      </Flex>
    );
  };

  const renderLastActivity = (
    stats: UserType['sessionCumulativeStatistic'],
  ) => {
    if (!stats) return null;

    return (
      <Flex sx={{ overflow: 'hidden' }} mt="auto" alignItems="center">
        <CalendarMonthRoundedIcon sx={{ mr: 0.5 }} />
        <BaseTypography
          variant="caption"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {formatDate(stats.lastSession)}
        </BaseTypography>
      </Flex>
    );
  };

  const renderContent = () => {
    return (
      <CardContent
        sx={{
          display: 'flex',
          flexGrow: 1,
          height: '100%',
          overflow: 'hidden',
        }}
      >
        <Flex
          flexDirection="column"
          height="100%"
          justifyContent="center"
          overflow="hidden"
        >
          <Flex overflow="hidden">
            <NamedAvatar
              firstName={user.firstName}
              lastName={user.lastName}
              sx={{ width: '2.5rem' }}
            />
            <Flex
              flexDirection="column"
              justifyContent="center"
              pl={2}
              overflow="hidden"
            >
              <BaseTypography
                fontWeight="bold"
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
                title={joinStrings(' ', user.firstName, user.lastName)}
              >
                {joinStrings(' ', user.firstName, user.lastName)}
              </BaseTypography>
              {user.externalId && (
                <BaseTypography
                  variant="caption"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  color="grey.500"
                >
                  {user.externalId}
                </BaseTypography>
              )}
              {user.role === UserRole.NONE
                ? renderLastActivity(user.sessionCumulativeStatistic)
                : renderUserDetail()}
            </Flex>
          </Flex>
        </Flex>
        <Flex alignItems="center" flexShrink={0} ml="auto">
          {onDeleteClick && (
            <IconButtonFilled
              id={`remove-user-${user.id}`}
              color="error"
              onClick={onDeleteClick}
              sx={{ mr: 2 }}
            >
              <DeleteIcon />
            </IconButtonFilled>
          )}
        </Flex>
      </CardContent>
    );
  };

  return (
    <SelectableCard
      selected={selected}
      id={`user-card-${user.id}`}
      sx={{ display: 'flex', height: '100%', minHeight: '7.5rem' }}
    >
      {!!link ? (
        <CardActionArea component={Link} to={link} sx={{ display: 'flex' }}>
          {renderContent()}
        </CardActionArea>
      ) : !!onClick ? (
        <CardActionArea onClick={onClick} sx={{ display: 'flex' }}>
          {renderContent()}
        </CardActionArea>
      ) : (
        renderContent()
      )}
    </SelectableCard>
  );
};

export default UserListCard;

const TrainingStatusText = styled(BaseTypography)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
