import { getOperationName } from '@apollo/client/utilities';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import { uuid4 } from '@sentry/utils';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex } from '../../../base/components/Flex';
import { ResponsiveButton } from '../../../base/components/ResponsiveButton';
import { useUser } from '../../../base/components/UserProvider/useUserHook';
import {
  CreatePlaylistTemplateDocument,
  EditPlaylistTemplateDocument,
  GetPlaylistTemplateFoldersDocument,
  PlaylistItemTemplateParamInput,
  PlaylistTemplateFragmentFragment,
  StreamType,
  UserPermission,
} from '../../../graphql/graphql-operations';
import { useMutationWithSnackbar } from '../../../hooks/useMutationWithSnackbar';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import { setToLocalStorageWithExpiration } from '../../../lib/localExpiringStorage';
import useStreamType from '../../../lib/useStreamType';
import ContentWrapper from '../../components/ContentWrapper';
import PageHeader from '../../components/PageHeader';
import PlaylistBuilder from '../../components/PlaylistBuilder/PlaylistBuilder';
import { usePlaylistData } from '../../components/PlaylistBuilder/playlistHooks';
import PlaylistTemplateFolderDialog from './components/PlaylistTemplateFolderDialog';
import PlaylistTemplateNameDialog from './PlaylistTemplateNameDialog';

interface AdditionalData {
  createdFolderId?: string;
}

interface DialogState {
  dialogType: 'savePlaylist' | 'createFolder' | null;
  additionData: AdditionalData;
}

const PlaylistTemplateEditPage = () => {
  const navigate = useNavigate();
  const { _t } = useTranslationPrefix('PlaylistTemplatePage');
  const [dialog, setDialog] = useState<DialogState>({
    dialogType: null,
    additionData: {},
  });
  // const [dialogOpen, setDialogOpen] = useState(false);
  const playlistData = usePlaylistData();
  const { template } = playlistData;
  const trainingVariant = useStreamType() === StreamType.TRAINING;
  const { hasPermission } = useUser();

  const handlePlay = () => {
    const id = uuid4();
    const scenes = playlistData.getCurrentPlaylistData();
    setToLocalStorageWithExpiration(id, scenes, 24 * 60 * 60 * 1000);
    navigate(`/devices/launch/${id}`);
  };

  const handleSuccess = (updated: PlaylistTemplateFragmentFragment) => {
    setDialog({ additionData: {}, dialogType: null });
    navigate(`/playlist-templates/${updated.id}`);
  };

  const [createTemplate] = useMutationWithSnackbar(
    'PlaylistTemplatePage.createTemplateMutation',
    CreatePlaylistTemplateDocument,
    {
      onCompleted: (d) => handleSuccess(d.createPlaylistTemplate),
      refetchQueries: [getOperationName(GetPlaylistTemplateFoldersDocument)!],
    },
  );

  const [editTemplate] = useMutationWithSnackbar(
    'PlaylistTemplatePage.editTemplateMutation',
    EditPlaylistTemplateDocument,
    {
      onCompleted: (d) => handleSuccess(d.editPlaylistTemplate),
      refetchQueries: [getOperationName(GetPlaylistTemplateFoldersDocument)!],
    },
  );

  const handleSaveClick = async ({
    name,
    enforceOrder,
    addedFolders,
    removedFolders,
  }: {
    name: string;
    enforceOrder: boolean;
    addedFolders: string[];
    removedFolders: string[];
  }) => {
    const scenes = playlistData.getCurrentPlaylistData();
    if (template) {
      await editTemplate({
        variables: {
          template: {
            id: template.playlistTemplate.id,
            name: name,
            enforceOrder: enforceOrder,
            addedFolders: addedFolders,
            removedFolders: removedFolders,
            scenes: scenes.map((s) => ({
              ...s,
              params:
                (s.params?.filter(
                  (p) => p.paramId,
                ) as PlaylistItemTemplateParamInput[]) || [],
            })),
          },
        },
      });
    } else {
      await createTemplate({
        variables: {
          template: {
            name: name,
            enforceOrder: enforceOrder,
            folderIds: addedFolders,
            scenes: scenes.map((s) => ({
              ...s,
              params:
                (s.params?.filter(
                  (p) => p.paramId,
                ) as PlaylistItemTemplateParamInput[]) || [],
            })),
          },
        },
      });
    }
  };

  return (
    <ContentWrapper ignoreNavSpacing>
      <PageHeader
        title={
          template
            ? _t('titleEdit', 'Edit playlist {{name}}', {
                name: template?.playlistTemplate.name,
              })
            : _t('titleNew', 'Create playlist')
        }
        back
      ></PageHeader>
      <Flex sx={{ backgroundColor: 'red' }}></Flex>
      <PlaylistBuilder
        playlistData={playlistData}
        actions={
          <>
            {!trainingVariant && hasPermission(UserPermission.PLAYLIST_ROUTE) && (
              <ResponsiveButton
                id="playlist-tamplate-play"
                variant="outlined"
                onClick={handlePlay}
                color="primary"
                disabled={playlistData.playlistEmpty}
                startIcon={<PlayArrowRoundedIcon />}
                size="small"
              >
                {_t('play', 'Play')}
              </ResponsiveButton>
            )}
            <ResponsiveButton
              id="playlist-tamplate-save"
              variant="contained"
              onClick={() =>
                setDialog({ additionData: {}, dialogType: 'savePlaylist' })
              }
              color="primary"
              disabled={playlistData.playlistEmpty}
              startIcon={<SaveRoundedIcon />}
              size="small"
            >
              {_t('save', 'Save playlist')}
            </ResponsiveButton>
          </>
        }
      />

      <PlaylistTemplateFolderDialog
        open={dialog.dialogType === 'createFolder'}
        onSuccess={(folderId) =>
          setDialog({
            additionData: { createdFolderId: folderId },
            dialogType: 'savePlaylist',
          })
        }
        onClose={() =>
          setDialog({ additionData: {}, dialogType: 'savePlaylist' })
        }
      />
      <PlaylistTemplateNameDialog
        open={dialog.dialogType === 'savePlaylist'}
        playlistTemplateId={template?.playlistTemplate.id}
        openCreateFolderDialog={() =>
          setDialog({ dialogType: 'createFolder', additionData: {} })
        }
        onClose={() => setDialog({ dialogType: null, additionData: {} })}
        name={template?.playlistTemplate.name || ''}
        enforceOrder={template?.playlistTemplate.enforceOrder ?? true}
        justCreatedFolderId={dialog.additionData.createdFolderId}
        onSubmit={handleSaveClick}
      />
    </ContentWrapper>
  );
};

export default PlaylistTemplateEditPage;
