import humps from 'humps';
import { TFunction } from 'i18next';
import { omit } from 'ramda';
import i18n from '../../../i18n/i18n';
import {
  ComparatorFunction,
  TableCellProps,
  TableHeadCellProps,
} from './types';

export function getPropValue<T>(array: TableCellProps<T>[], id: keyof T) {
  return array.find((cell) => cell.id === id);
}

export function stringComparator(a: string | null, b: string | null) {
  if (a === null && b === null) {
    return 0;
  }
  if (a === null || b === null) {
    return a === null ? -1 : 1;
  }
  return a.localeCompare(b, i18n.languages[0], {
    ignorePunctuation: true,
    sensitivity: 'base',
  });
}

export function numberComparator(a: number, b: number) {
  return a - b;
}

export interface Cell<T> extends Omit<TableHeadCellProps<T>, 'label'> {
  defaultTrans: string;
}

export function getHeadCellsFromObject<T>(
  cells: Array<Cell<T>>,
  translationPrefix: string,
  t: TFunction,
  sortEnum?: Record<string, string>,
): TableHeadCellProps<T>[] {
  let sortableFields: string[] | undefined;
  if (sortEnum) {
    sortableFields = Object.values(sortEnum).map((field) =>
      humps.camelize(field.toLowerCase()),
    );
  }
  return cells.map((cell) => ({
    sortable: sortableFields
      ? sortableFields.includes(cell.id.toString())
      : undefined,
    ...omit(['defaultTrans'], cell),
    label: t(`${translationPrefix}.${cell.id}`, cell.defaultTrans),
  }));
}

export interface RowContentConfig<T extends { [key: string]: any }> {
  id: keyof T;
  getLabel?: Function;
  comparator?: ComparatorFunction;
  component?: React.ComponentType<
    T & {
      cellId: keyof T;
    }
  >;
}

export function getRowContentFromObject<T extends { [key: string]: any }>(
  data: T,
  props: Array<RowContentConfig<T>>,
  t?: TFunction,
): TableCellProps<T>[] {
  return props.map(({ id, getLabel, ...rest }) => {
    let label = data[id];
    if (getLabel) {
      label = getLabel(label);
    }
    return {
      id,
      data,
      label,
      ...rest,
    };
  });
}
