import escapeStringRegexp from 'escape-string-regexp';
import { path } from 'ramda';

export const filterByProp =
  <T extends Record<string, any>>(
    filterField?: keyof T,
    filterQuery?: string,
  ) =>
  (row: T): boolean => {
    if (!filterField || !filterQuery) {
      return true;
    }
    const fieldValue = path(String(filterField).split('.'), row);
    return !!matchQuery(String(fieldValue), filterQuery);
  };

export const matchQuery = (value: string, query: string) => {
  if (!query) {
    return true;
  }
  if (!value) {
    return false;
  }
  // remove all special character, or it could break search regex
  const filterQuery = escapeStringRegexp(query);
  const regex = new RegExp(`^(.*)(${filterQuery})(.*)$`, 'gi');
  return regex.exec(value);
};

export const highlighQuery = (value: string, query: string) => {
  const match = matchQuery(value, query);
  if (typeof match === 'boolean' || match === null) {
    return value;
  }

  return (
    <>
      {match[1]}
      <mark>{match[2]}</mark>
      {match[3]}
    </>
  );
};
