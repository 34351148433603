import { useQuery } from '@apollo/client';
import { useHookstate } from '@hookstate/core';
import { adminState } from '../../../../adminState';
import { useUser } from '../../../../base/components/UserProvider/useUserHook';
import {
  GetTrainingAssignmentForTemplateDocument,
  UserFragmentFragment,
  UserGroupFragmentFragment,
  UserPermission,
} from '../../../../graphql/graphql-operations';
import useQueryErrorSnackbar from '../../../../hooks/useQueryErrorSnackbar';

export interface UserTrainingAssignment {
  id: string;
  user: UserFragmentFragment;
}

export interface UserGroupTrainingAssignment {
  id: string;
  userGroup: UserGroupFragmentFragment;
}

export const usePlaylistTemplateAssignments = (playlistTemplateId: string) => {
  const companyId = useHookstate(adminState.selectedCompanyId);
  const { hasPermission } = useUser();
  const { data, error, refetch } = useQuery(
    GetTrainingAssignmentForTemplateDocument,
    {
      variables: {
        companyId: companyId.get(),
        playlistTemplateIds: [playlistTemplateId],
      },
      fetchPolicy: 'cache-and-network',
      skip: !hasPermission(UserPermission.MANAGE_TRAINING_ASSIGNMENTS),
    },
  );
  useQueryErrorSnackbar(error, refetch);

  const assignments = data?.trainingAssignments || [];

  const userGroupAssignments: UserGroupTrainingAssignment[] =
    assignments.filter((ta) => ta.userGroup) as UserGroupTrainingAssignment[];
  const userAssignments: UserTrainingAssignment[] = assignments.filter(
    (ta) => ta.user,
  ) as UserTrainingAssignment[];

  return { userGroupAssignments, userAssignments };
};
