import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardProps,
  Grid,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { Flex } from '../../../../base/components/Flex';
import { IconButtonFilled } from '../../../../base/components/IconButton';
import { useTranslationPrefix } from '../../../../hooks/useTranslationPrefix';
import { formatDate } from '../../../../lib/date';
import { DateOptionPreset } from '../../../../lib/datePresetHelper';
import { formatDuration } from '../../../../lib/duration';
import { LabeledValue } from '../../../components/LabeledValue';

export interface ReportSummaryData {
  id?: string | number;
  datePreset: DateOptionPreset;
  from: Date;
  to: Date;
  companyName: string;
  generatedDate: Date;
  lastRunTime: Date | null;
  totalDurationMs: number;
  totalRuns: number;
}

interface Props {
  report: ReportSummaryData;
  onDeleteClick?: () => void;
  sx?: CardProps['sx'];
  to?: string;
}

const ReportSummaryCard = ({ report, onDeleteClick, sx = {}, to }: Props) => {
  const { _t, t } = useTranslationPrefix('ReportSummaryCard');

  const renderCardContent = () => {
    return (
      <CardContent>
        <Flex>
          <Grid container spacing={2} p={1}>
            <Grid item xs={12} sm={6} md={4}>
              <LabeledValue
                label={_t('organizationUnit', 'Org. unit')}
                value={report.companyName}
                size="medium"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <LabeledValue
                label={_t('period', 'Period')}
                value={t(`enum.DateOptionPreset.${report.datePreset}`)}
                title={`${formatDate(report.from)} - ${formatDate(report.to)}`}
                size="medium"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <LabeledValue
                label={_t('generatedDate', 'Generated date')}
                value={formatDate(report.generatedDate)}
                size="medium"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <LabeledValue
                label={_t('appRunCount', 'App runs')}
                value={report.totalRuns}
                size="medium"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <LabeledValue
                label={_t('durationSum', 'Time in VR')}
                value={formatDuration(report.totalDurationMs)}
                size="medium"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <LabeledValue
                label={_t('lastRunTime', 'Last session')}
                value={report.lastRunTime && formatDate(report.lastRunTime)}
                size="medium"
              />
            </Grid>
          </Grid>
          <Box sx={{ width: '3.5rem' }} />
        </Flex>
      </CardContent>
    );
  };

  return (
    <Card sx={{ position: 'relative', flexShrink: 0, ...sx }}>
      {to ? (
        <CardActionArea component={Link} to={to}>
          {renderCardContent()}
        </CardActionArea>
      ) : (
        renderCardContent()
      )}
      {onDeleteClick && (
        <CardActions sx={{ position: 'absolute', top: 16, right: 16 }}>
          <IconButtonFilled
            id={`delete-report-${report.id}`}
            color="error"
            onClick={onDeleteClick}
          >
            <DeleteOutlineRoundedIcon />
          </IconButtonFilled>
        </CardActions>
      )}
    </Card>
  );
};

export default ReportSummaryCard;
