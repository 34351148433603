import { useState } from 'react';
import { BaseTabs } from '../../../../base/components/BaseTabs';
import { PlaylistTemplateFragmentFragment } from '../../../../graphql/graphql-operations';
import { useTranslationPrefix } from '../../../../hooks/useTranslationPrefix';
import { usePlaylistTemplateAssignments } from './hooks';
import PlaylistTemplateGroupAssignmentList from './PlaylistTemplateGroupAssignmentList';
import PlaylistTemplateSceneList from './PlaylistTemplateSceneList';
import PlaylistTemplateUserAssignmentList from './PlaylistTemplateUserAssignmentList';

interface Props {
  template: PlaylistTemplateFragmentFragment;
  onPlayClick?: (idx: number) => void;
}

enum TabView {
  SCENES = 'SCENES',
  USERS = 'USERS',
  GROUPS = 'GROUPS',
}

const PlaylistTemplateDetailContent = ({ template, onPlayClick }: Props) => {
  const { _t } = useTranslationPrefix('PlaylistTemplateDetailContent');
  const [selectedTab, setSelectedTab] = useState<TabView>(TabView.SCENES);
  const { userAssignments, userGroupAssignments } =
    usePlaylistTemplateAssignments(template.id);

  const hasTabs = !!(userAssignments.length || userGroupAssignments.length);

  return (
    <>
      {hasTabs && (
        <BaseTabs
          value={selectedTab}
          onChange={(e, val) => setSelectedTab(val)}
          sx={{ mb: 2 }}
          tabs={[
            {
              value: TabView.SCENES,
              label: _t('scenes', 'Scenes'),
              count: template.items.length,
            },
            {
              value: TabView.USERS,
              label: _t('users', 'Users'),
              count: userAssignments.length,
            },
            {
              value: TabView.GROUPS,
              label: _t('groups', 'Groups'),
              count: userGroupAssignments.length,
            },
          ]}
        />
      )}

      {(selectedTab === TabView.SCENES || !hasTabs) && (
        <PlaylistTemplateSceneList
          template={template}
          onPlayClick={onPlayClick}
        />
      )}
      {selectedTab === TabView.USERS && (
        <PlaylistTemplateUserAssignmentList
          userTrainingAssignments={userAssignments}
        />
      )}
      {selectedTab === TabView.GROUPS && (
        <PlaylistTemplateGroupAssignmentList
          userGroupTrainingAssignments={userGroupAssignments}
        />
      )}
    </>
  );
};

export default PlaylistTemplateDetailContent;
