import { useMutation } from '@apollo/client';
import { useState } from '@hookstate/core';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from '../../../base/components/ConfirmDialog/ConfirmDialog';
import { DisappointUserToSessionDocument } from '../../../graphql/graphql-operations';
import useMutationSnackbar from '../../../hooks/useMutationSnackbar';

interface Props {
  vrSessionId: string;
}

const VrSessionUserActions: React.VFC<Props> = ({ vrSessionId }: Props) => {
  const { t } = useTranslation();
  const state = useState({ disappointConfirmOpen: false });

  const disappointHandler = useMutationSnackbar('removeUserFromSession', {
    translationKey: 'vrSessionDetail.user.actions.disappoint',
  });

  const [appointMutation] = useMutation(
    DisappointUserToSessionDocument,
    disappointHandler,
  );

  const handleDissapointClick = () => {
    appointMutation({
      variables: { removeUserFromSessionInput: { sessionId: vrSessionId } },
    });
  };

  return (
    <>
      <Button
        id="session-disappoint-patient"
        size="large"
        variant="contained"
        color="error"
        onClick={() => state.disappointConfirmOpen.set(true)}
      >
        {t('vrSessionDetail.user.disappoint', 'Disappoint patient')}
      </Button>
      <ConfirmDialog
        open={state.disappointConfirmOpen.get()}
        onClose={() => state.disappointConfirmOpen.set(false)}
        onConfirm={handleDissapointClick}
      />
    </>
  );
};

export default VrSessionUserActions;
