import { getOperationName } from '@apollo/client/utilities';
import DateRangeIcon from '@mui/icons-material/DateRange';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  LinearProgress,
  styled,
} from '@mui/material';
import clsx from 'clsx';
import { differenceInYears } from 'date-fns';
import { FC, useState } from 'react';
import { BaseTypography } from '../../../../base/components/BaseTypography';
import ConfirmDialog from '../../../../base/components/ConfirmDialog/ConfirmDialog';
import { Flex } from '../../../../base/components/Flex';
import { IconButtonFilled } from '../../../../base/components/IconButton';
import { ImageBackground } from '../../../../base/components/ImageBackground';
import TermDays from '../../../../base/components/TimeFormat/TermDays';
import { useUser } from '../../../../base/components/UserProvider/useUserHook';
import {
  GetUserTrainingAssignmentsDocument,
  RemoveTrainingAssignmentsDocument,
  UserPermission,
  UserTrainingFragmentFragment,
} from '../../../../graphql/graphql-operations';
import { useMutationWithSnackbar } from '../../../../hooks/useMutationWithSnackbar';
import { useTranslationPrefix } from '../../../../hooks/useTranslationPrefix';
import TrainingStatusTag from '../TrainingStatusTag';

type Training = UserTrainingFragmentFragment;

export interface TrainingListCardFeatures {
  delete?: boolean;
}

interface Props {
  training: Training;
  onClick?: () => void;
  features?: TrainingListCardFeatures;
  fullHeight?: boolean;
}

const MAX_DUE_DATE_YEARS = 10;

const UserTrainingListCard: FC<Props> = ({
  training,
  onClick,
  features,
  fullHeight,
}) => {
  const { _t } = useTranslationPrefix('UserTrainingCard');
  const { hasPermission } = useUser();
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const pastdue = new Date(training.completion.dueDate) < new Date();
  const completionValue =
    100 * (training.completion.itemsCompleted / training.completion.itemsTotal);
  const completed = completionValue === 100;
  const imageUrl = training.playlistTemplate.items.find((i) => i.scene.imageUrl)
    ?.scene?.imageUrl;

  const [remove] = useMutationWithSnackbar(
    'userTrainingListCard.removeTrainingFromUser',
    RemoveTrainingAssignmentsDocument,
  );

  const handleDelete = () => {
    remove({
      variables: {
        input: {
          trainingAssignmentIds: [training.trainingAssignmentId!],
        },
      },
      refetchQueries: [getOperationName(GetUserTrainingAssignmentsDocument)!],
    });
  };

  const renderCompletion = () => {
    return (
      <ValueWrapper>
        <span>{_t('finished', 'Finished')}:</span>
        <span className={clsx(classes.value)}>
          {training.completion.itemsCompleted}/{training.completion.itemsTotal}
        </span>
      </ValueWrapper>
    );
  };

  const renderDueDate = () => {
    if (
      differenceInYears(new Date(training.completion.dueDate), new Date()) >
      MAX_DUE_DATE_YEARS
    ) {
      return null;
    }
    return (
      <ValueWrapper>
        <DateRangeIcon />
        <span className={classes.pastDueLabel}>
          {pastdue ? _t('pastDue', 'Past due') : _t('dueBy', 'Due by')}:
        </span>
        <span className={classes.value}>
          <TermDays term={training.completion.dueDate} />
        </span>
      </ValueWrapper>
    );
  };

  const renderCardContent = () => {
    return (
      <>
        <CardContent>
          <Flex flexDirection="column">
            <ImageBackground
              src={imageUrl}
              greyScale={completed}
              sx={{
                aspectRatio: '16/10',
                width: '100%',
              }}
            >
              <Flex
                flexDirection="column"
                height="100%"
                justifyContent="space-between"
              >
                <Flex justifyContent="space-between">
                  <BaseTypography
                    variant="h5"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    color="white.main"
                  >
                    {training.playlistTemplate.name}
                  </BaseTypography>
                  {features?.delete &&
                    !training.userGroup &&
                    hasPermission(
                      UserPermission.MANAGE_TRAINING_ASSIGNMENTS,
                    ) && (
                      <IconButtonFilled
                        color="error"
                        size="small"
                        id={`delete-training-${training.id}`}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setConfirmDialogOpen(true);
                        }}
                        sx={{ mt: '-0.35rem', mr: '-0.4rem' }}
                      >
                        <DeleteIcon />
                      </IconButtonFilled>
                    )}
                </Flex>
                <Flex justifyContent="left">
                  <TrainingStatusTag
                    variant={completed ? 'success' : 'error'}
                  />
                </Flex>
              </Flex>
            </ImageBackground>
            <Box flexGrow={1} pt={2}>
              {renderCompletion()}
              <LinearProgress
                variant="determinate"
                value={completionValue}
                sx={{
                  height: '.5rem',
                  mb: 1,
                }}
              />
              {renderDueDate()}
            </Box>
          </Flex>
        </CardContent>
      </>
    );
  };

  return (
    <>
      <Card
        id={`training-${training.id}`}
        sx={{ height: fullHeight ? '100%' : 'auto' }}
      >
        {onClick ? (
          <CardActionArea onClick={onClick}>
            {renderCardContent()}
          </CardActionArea>
        ) : (
          renderCardContent()
        )}
      </Card>
      <ConfirmDialog
        title={_t(
          'removeTraining',
          'Are you sure you want to remove this training?',
        )}
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        onConfirm={handleDelete}
      />
    </>
  );
};

export default UserTrainingListCard;

const classes = {
  value: 'UserTrainingCard-value',
  pastDueLabel: 'UserTrainingCard-pastDueLabel',
};

const ValueWrapper = styled(Flex)(
  ({ theme: { palette, spacing } }) => `
  flex-direction: row;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1rem; 
  margin-bottom: ${spacing(1)};
  
  & svg {
    font-size: 1rem;
    margin-right: ${spacing(1)};
  }

  & .${classes.value} {
    flex-grow: 1;
    text-align: right;
  }
  & .${classes.pastDueLabel} {
    color: ${palette.grey[500]}
  }
`,
);
