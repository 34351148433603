import { useQuery } from '@apollo/client';
import { adminState } from '../../../../adminState';
import { useUser } from '../../../../base/components/UserProvider/useUserHook';
import {
  GetSessionHistoryDocument,
  GetSessionHistoryQueryVariables,
  UserPermission,
  UserSessionPlaylistFragmentFragment,
} from '../../../../graphql/graphql-operations';
import useQueryErrorSnackbar from '../../../../hooks/useQueryErrorSnackbar';
import { ListView } from '../../../components/ListView';
import { PlaylistCard } from './PlaylistCard';

interface Props {
  userId: string;
}

const SessionHistoryList = ({ userId }: Props) => {
  const { hasPermission } = useUser();
  const { data, refetch, variables, error, loading, fetchMore } = useQuery(
    GetSessionHistoryDocument,
    {
      variables: {
        userId: userId,
        companyId: adminState.selectedCompanyId.get(),
        first: 10,
      },
      fetchPolicy: 'cache-and-network',
    },
  );
  useQueryErrorSnackbar(error, refetch);

  const renderSession = (session: UserSessionPlaylistFragmentFragment) => {
    return (
      <PlaylistCard
        id={`sessionList-${session.id}`}
        playlist={session.playlist}
        duration={session.duration}
        to={
          hasPermission(UserPermission.PLAYLIST_ROUTE)
            ? `/playlist/create-session?userId=${userId}&prevSessionId=${session.id}`
            : undefined
        }
      />
    );
  };

  return (
    <ListView<
      UserSessionPlaylistFragmentFragment,
      GetSessionHistoryQueryVariables
    >
      columns={1}
      data={data?.sessions.nodes || []}
      rowKey="id"
      lazyConfig={{
        fetchMore: fetchMore,
        refetch: refetch,
        variables: variables,
        pageCursors: data?.sessions.pageCursors,
        totalCount: data?.sessions.totalCount,
      }}
      renderValue={renderSession}
      loading={loading}
      gridSpacing={3}
      sx={{ mt: 0 }}
    />
  );
};

export default SessionHistoryList;
