import { styled } from '@mui/material';
import { StreamLogo } from '../../../base/components/StreamLogo';

const ClientLoginLogo = styled(StreamLogo)`
  width: 100%;
  height: auto;
  max-height: 200px;
  max-width: 350px;
`;

export default ClientLoginLogo;
