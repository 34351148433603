import { styled } from '@mui/material';
import { ReactNode } from 'react';
import { Flex } from '../../../base/components/Flex';

interface Props {
  id: string;
  name: string;
  action: ReactNode;
}

const ApplicationScene = ({ id, name, action }: Props) => {
  return (
    <Container id={id}>
      <Flex>{name}</Flex>
      <Flex>{action}</Flex>
    </Container>
  );
};

export default ApplicationScene;

const Container = styled(Flex)(
  ({ theme: { spacing, palette } }) => `
  border-top: solid 1px ${palette.grey[500]};
  align-items: center;
  justify-content: space-between;
  padding: ${spacing(1)} 0;
`,
);
