import React from 'react';
import { useTranslation } from 'react-i18next';
import { BaseDialog } from '../../../base/components/BaseDialog';
import NewPatientForm from './NewPatientForm';
interface Props {
  open: boolean;
  onClose: () => void;
  onUserCreated: (userId: string) => void;
}

const NewPatientDialog: React.VFC<Props> = (props: Props) => {
  const { open, onClose, onUserCreated } = props;
  const { t } = useTranslation();

  return (
    <BaseDialog
      open={open}
      title={t('newPatientDialog.title', 'Create new patient')}
      onClose={onClose}
    >
      <NewPatientForm onCancel={onClose} onUserCreated={onUserCreated} />
    </BaseDialog>
  );
};

export default NewPatientDialog;
