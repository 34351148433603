import BlockIcon from '@mui/icons-material/Block';
import ClearIcon from '@mui/icons-material/Clear';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import WorkOutlineRoundedIcon from '@mui/icons-material/WorkOutlineRounded';
import { Collapse, ListItemIcon, ListItemText } from '@mui/material';
import { useContext, useMemo, useState } from 'react';
import { VrDevicesContext } from '../../base/components/VrDevicesProvider/VrDevicesProvider';
import { useTranslationPrefix } from '../../hooks/useTranslationPrefix';
import ClientUserMenuItem from './ClientUserMenuItem';

interface Props {
  onClose: () => void;
}

const VrBoxSelector = ({ onClose }: Props) => {
  const { _t } = useTranslationPrefix('vrBoxSelector');

  const [vrBoxSelectOpened, setVrBoxSelectOpened] = useState(false);
  const { vrBoxes, vrBoxId, setVrBoxId } = useContext(VrDevicesContext);

  const toggleVrBoxSelect = () => {
    setVrBoxSelectOpened((prev) => !prev);
  };
  const handleSetVrBox = (vrBoxId: string) => {
    setVrBoxSelectOpened(false);
    if (selectedVrBox?.id === vrBoxId) {
      setVrBoxId(null);
    } else {
      setVrBoxId(vrBoxId);
    }
    onClose();
  };

  const selectedVrBox = useMemo(() => {
    return vrBoxes.find((box) => box.id === vrBoxId);
  }, [vrBoxes, vrBoxId]);
  return (
    <>
      <ClientUserMenuItem
        sx={{
          justifyContent: 'space-between',
        }}
        onClick={toggleVrBoxSelect}
      >
        <ListItemIcon sx={{ color: 'grey.600', fontSize: '1.5rem' }}>
          {selectedVrBox ? (
            <WorkOutlineRoundedIcon sx={{ mr: 1 }} />
          ) : (
            <BlockIcon sx={{ mr: 1 }} />
          )}
        </ListItemIcon>
        <ListItemText sx={{ color: 'grey.600' }}>
          {selectedVrBox?.name || _t('notSelected', 'Not selected')}
        </ListItemText>
        <ListItemIcon sx={{ color: 'grey.600' }}>
          {vrBoxSelectOpened ? (
            <ExpandLess sx={{ ml: 1 }} />
          ) : (
            <ExpandMore sx={{ ml: 1 }} />
          )}
        </ListItemIcon>
      </ClientUserMenuItem>
      <Collapse in={vrBoxSelectOpened} unmountOnExit>
        <>
          {vrBoxes.map((vrBox) => (
            <ClientUserMenuItem
              key={vrBox.id}
              sx={{
                justifyContent: 'space-between',
              }}
              onClick={() => handleSetVrBox(vrBox.id)}
            >
              <ListItemIcon></ListItemIcon>
              <ListItemText sx={{ color: 'grey.600' }}>
                {vrBox.name}
              </ListItemText>
              {selectedVrBox?.id === vrBox.id && (
                <ListItemIcon>
                  <ClearIcon sx={{ ml: 1 }} color="error" />
                </ListItemIcon>
              )}
            </ClientUserMenuItem>
          ))}
        </>
      </Collapse>
    </>
  );
};

export default VrBoxSelector;
