import { BoxProps, styled } from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslationPrefix } from '../../../../hooks/useTranslationPrefix';
import { formatDate } from '../../../../lib/date';
import Chart, { ChartProps } from '../../../components/Charts/Chart';
import { UpdateAxisPointerEvent } from '../../../components/Charts/ChartTypes';
import { axisPointer } from '../utils/axisPointer';

interface Props extends BoxProps {
  data: readonly RangeData[];
  onAxisPointerChange?: ChartProps<any>['onAxisPointerChange'];
}

interface RangeData {
  readonly key: string;
  readonly leftFingers: number;
  readonly leftArm: number;
  readonly rightFingers: number;
  readonly rightArm: number;
}

const formatReactionTime = (val: number) => {
  const rounded = Math.round(val * 100) / 100;
  return Math.abs(rounded) + 's';
};

const ReactionTimeChart = ({
  data,
  onAxisPointerChange: onAxisPointerChangeOrig,
  ...boxProps
}: Props) => {
  const { _t } = useTranslationPrefix('UserTimeReactionPage');

  const onAxisPointerChange = useCallback(
    //Its category type, so value is index, by actual value is needed
    (e: UpdateAxisPointerEvent) => {
      const dimension = e.axesInfo.find((i) => i.axisDim === 'x');
      if (typeof dimension?.value === 'number' && data[dimension.value]) {
        var item = data[dimension.value];
        dimension.value = item.key;
      }
      onAxisPointerChangeOrig?.(e);
    },
    [onAxisPointerChangeOrig, data],
  );

  return (
    <ReactionTimeChartWrapper
      {...boxProps}
      onAxisPointerChange={onAxisPointerChange}
      options={{
        tooltip: {
          trigger: 'axis',
          triggerOn: 'click',
          valueFormatter: formatReactionTime,
        },
        legend: {},
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '70px',
          containLabel: true,
        },
        dataZoom: [
          {
            type: 'inside',
            start: Math.max(0, 100 - (20 / data.length) * 100),
            end: 100,
            filterMode: 'none',
          },
          {
            type: 'slider',
            start: 0,
            end: 100,
            labelFormatter: (_: number, val: string) => formatDate(val),
            filterMode: 'none',
          },
        ],
        dataset: {
          source: data,
        },

        xAxis: [
          {
            type: 'time',

            axisPointer: axisPointer({}),
          },
        ],
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: formatReactionTime,
          },
        },
        series: [
          {
            name: _t('leftArm', 'Levá ruka'),
            type: 'line',
            smooth: 0.4,
            dimensions: [
              { name: 'key', type: 'time' },
              { name: 'leftArm', type: 'number' },
            ],
          },
          {
            name: _t('leftArmFingers', 'Levá ruka prsty'),
            type: 'line',
            smooth: 0.4,
            dimensions: [
              { name: 'key', type: 'time' },
              { name: 'leftFingers', type: 'number' },
            ],
          },
          {
            name: _t('rightArm', 'Pravá ruka'),
            type: 'line',
            smooth: 0.4,
            dimensions: [
              { name: 'key', type: 'time' },
              { name: 'rightArm', type: 'number' },
            ],
          },
          {
            name: _t('rightArmFingers', 'Pravá ruka prsty'),
            type: 'line',
            smooth: 0.4,
            dimensions: [
              { name: 'key', type: 'time' },
              { name: 'rightFingers', type: 'number' },
            ],
          },
        ],
      }}
    />
  );
};

const ReactionTimeChartWrapper = styled(Chart)`
  min-height: 330px;
  height: 33vh;
`;

export default React.memo(ReactionTimeChart);
