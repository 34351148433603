import escapeStringRegexp from 'escape-string-regexp';
import { path } from 'ramda';
import { useDebounce } from 'react-use';
import { RefetchList, SearchListVariables } from '.';

export const useListSearch = <T extends Record<string, any>>({
  lazyConfig,
  searchConfig,
  loading,
}: {
  lazyConfig?: RefetchList<any, T>;
  searchConfig?: SearchListVariables<T>;
  loading?: boolean;
}): { filter: (data: readonly T[]) => readonly T[] } => {
  const searchTerm = (searchConfig?.filterValue || '')
    .trim()
    .toLocaleLowerCase();
  const searchField = searchConfig?.filterBy;

  const currentLazySearchTerm = lazyConfig?.variables?.filterBy?.query || '';
  const currentLazySearchField = lazyConfig?.variables?.filterBy?.field;
  const lazyRefetch = lazyConfig?.refetch;
  const lazyVariables = lazyConfig?.variables;

  useDebounce(
    () => {
      if (
        !loading &&
        lazyRefetch &&
        lazyVariables &&
        searchTerm !== currentLazySearchTerm
      ) {
        lazyRefetch({
          ...lazyVariables,
          filterBy: searchField
            ? {
                query: searchTerm,
                field: searchField,
              }
            : null,
          after: null,
        });
      }
    },
    300,
    [
      searchTerm,
      searchField,
      currentLazySearchTerm,
      currentLazySearchField,
      lazyRefetch,
      lazyVariables,
      loading,
    ],
  );

  return {
    filter: lazyConfig
      ? (data: readonly T[]) => data
      : filter(searchField, searchTerm),
  };
};

const filter =
  <T extends Record<string, any>>(
    filterField?: keyof T | string,
    filterQuery?: string,
  ) =>
  (rows: readonly T[]) => {
    if (!filterField || !filterQuery) {
      return rows;
    }
    // remove all special character, or it could break search regex
    filterQuery = escapeStringRegexp(filterQuery);
    const regex = new RegExp(`.*${filterQuery}.*`, 'gi');
    return rows.filter((r) => {
      const fieldValue = path(String(filterField).split('.'), r);
      if (!fieldValue) return false;
      return (fieldValue as string).match(regex);
    });
  };
