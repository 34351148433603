/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import ConfirmDialog from '../../../base/components/ConfirmDialog/ConfirmDialog';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import ContentWrapper from '../../components/ContentWrapper';
import { ListView } from '../../components/ListView';
import PageHeader from '../../components/PageHeader';
import ReportFilterForm from './components/ReportFilterForm';
import ReportSummaryCard from './components/ReportSummaryCard';
import useSessionPlaylistItemReports, {
  ReportSummary,
} from './useSessionPlaylistItemReports';

const ReportListPage = () => {
  const { _t } = useTranslationPrefix('ReportListPage');
  const [reports, setReports] = useState<ReportSummary[]>([]);
  const [toDelete, setToDelete] = useState<number | null>();

  const loadData = async () => {
    const reports = await getReportSummaries();
    setReports(reports);
  };
  useEffect(() => {
    loadData();
  }, []);
  const { generateReport, getReportSummaries, removeReport } =
    useSessionPlaylistItemReports({
      onChange: loadData,
    });

  const handleDelete = async () => {
    if (toDelete) {
      await removeReport(toDelete);
      setToDelete(null);
    }
  };

  const renderReportOverview = (report: ReportSummary) => {
    return (
      <ReportSummaryCard
        report={report}
        to={`./${report.id}`}
        onDeleteClick={() => setToDelete(report.id)}
      />
    );
  };

  return (
    <ContentWrapper>
      <PageHeader title={_t('title', 'Reports')} />
      <ReportFilterForm onSubmit={generateReport} />

      <ListView<ReportSummary>
        rowKey="id"
        data={reports}
        renderValue={renderReportOverview}
        columns={1}
        sx={{ mt: 1 }}
      />
      <ConfirmDialog
        open={!!toDelete}
        title={_t('removeReportConfirm', 'Remove report?')}
        onConfirm={handleDelete}
        onClose={() => setToDelete(null)}
      ></ConfirmDialog>
    </ContentWrapper>
  );
};

export default ReportListPage;
