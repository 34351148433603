import { Box, Typography } from '@mui/material';
import { Flex } from '../../../base/components/Flex';
import HtmlParser from '../HtmlParser';
import { HelpFaqContentItemContent } from './types';

const HelpFaqItemContent = ({ item }: { item: HelpFaqContentItemContent }) => {
  const { title, image } = item;
  return (
    <Box width="100%">
      {title && (
        <Typography variant="h4" mb={4}>
          {title}
        </Typography>
      )}
      <Flex
        flexDirection={image?.position === 'right' ? 'row' : 'row-reverse'}
        width="100%"
      >
        <Flex
          sx={{
            width: image ? '50%' : '100%',
            marginRight: image?.position === 'left' ? 0 : 3,
            marginLeft: image?.position === 'right' ? 0 : 3,
          }}
        >
          <HtmlParser html={item.text || ''} />
        </Flex>
        {image && <img src={image.uri} alt={title} />}
      </Flex>
    </Box>
  );
};

export default HelpFaqItemContent;
