import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { styled } from '@mui/material';
import { BoxProps } from '@mui/system';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { BaseTypography } from '../../../base/components/BaseTypography';
import { Flex } from '../../../base/components/Flex';
import { IconButtonOutlined } from '../../../base/components/IconButton';
import { joinStrings } from '../../../lib/strings';

interface Props extends BoxProps {
  title: string;
  back?: string | (() => void) | boolean;
  collapsed?: boolean;
}

const PageHeader: FC<Props> = ({
  id,
  title,
  back,
  collapsed,
  children,
  ...boxProps
}) => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    if (typeof back === 'function') {
      back();
    } else if (typeof back === 'string') {
      navigate(back);
    } else if (back) {
      navigate(-1);
    }
  };

  return (
    <Flex
      id={id}
      flexDirection={{ xs: 'column', sm: 'row' }}
      width="100%"
      justifyContent={collapsed ? 'flex-start' : 'space-between'}
      mb={4}
      gap={2}
      {...boxProps}
    >
      <Flex alignItems="center" color="text.secondary">
        {back && (
          <BackButton
            id={joinStrings('-', id, 'header-back')}
            aria-label="back"
            color="bgContrast"
            onClick={handleBackClick}
          >
            <ArrowBackIcon />
          </BackButton>
        )}
        <BaseTypography ml={back ? 3 : 0} variant="h4">
          {title}
        </BaseTypography>
      </Flex>
      <ButtonWrapper
        gap={2}
        alignItems="center"
        flexWrap="wrap"
        justifyContent="center"
      >
        {children}
      </ButtonWrapper>
    </Flex>
  );
};

export default PageHeader;

const ButtonWrapper = styled(Flex)`
  white-space: nowrap;
  @media print {
    display: none;
  }
`;

const BackButton = styled(IconButtonOutlined)`
  @media print {
    display: none;
  }
`;
