import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import { Button } from '@mui/material';
import { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSearchParam } from 'react-use';
import { Flex } from '../../../base/components/Flex';
import {
  VrDeviceType,
  VrDevicesContext,
} from '../../../base/components/VrDevicesProvider/VrDevicesProvider';
import {
  CompanyFeature,
  CreateSessionMutationDocument,
  CreateSessionMutationMutation,
  CreateSessionPlaylistItemInput,
  DeviceState,
  StreamType,
} from '../../../graphql/graphql-operations';
import { useMutationWithSnackbar } from '../../../hooks/useMutationWithSnackbar';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import { getFromLocalStorageWithExpiration } from '../../../lib/localExpiringStorage';
import useCompany from '../../../lib/useCompany';
import useStreamType from '../../../lib/useStreamType';
import ContentWrapper from '../../components/ContentWrapper';
import PageHeader from '../../components/PageHeader';
import { DeviceList } from './DeviceList';
import DeviceUpdateDialog from './DeviceUpdateDialog';
import useDeviceRestart from './useDeviceRestart';
import useDeviceUpdate from './useDeviceUpdate';

interface UpdateState {
  device: VrDeviceType;
  skipAvailable: boolean;
}

type SelectionMode = 'single' | 'multiple';

const LaunchSessionOnDevicePage = () => {
  const { company } = useCompany();
  const { restartVrDevice } = useDeviceRestart();
  const { boxDevices } = useContext(VrDevicesContext);
  const { playlistStorageKey } = useParams<{ playlistStorageKey: string }>();
  const navigate = useNavigate();
  const userId = useSearchParam('userId');
  const { _t } = useTranslationPrefix('selectDevicePage');
  const { checkDeviceUpdatable } = useDeviceUpdate();
  const [devices, setDevices] = useState<string[]>([]);
  const streamType = useStreamType();
  const [deviceToUpdate, setDeviceToUpdate] = useState<UpdateState | null>(
    null,
  );
  const selectMode: SelectionMode = userId ? 'single' : 'multiple';

  const isDeviceActive = (device: VrDeviceType) =>
    device.status?.state === DeviceState.ONLINE;

  const activeDeviceIds = (boxDevices || [])
    .filter(isDeviceActive)
    .map((d) => d.id);

  // for school devices means unselected, for others is selected
  // for school, all active devices are automaticly selected unless they are manually unselected
  const selectedDevices =
    selectMode === 'multiple' &&
    (streamType === StreamType.SCHOOL ||
      company?.features.includes(CompanyFeature.LAUNCH_GROUP_SESSION))
      ? activeDeviceIds.filter((d) => !devices.includes(d))
      : activeDeviceIds.filter((d) => devices.includes(d));

  const handleLaunchSuccess = ({
    createSession: sessions,
  }: CreateSessionMutationMutation) => {
    const sessionId = sessions[0].id;
    if (selectMode === 'multiple') {
      navigate(`/sessions/${sessionId}?multiple`);
    } else {
      navigate(`/sessions/${sessionId}`);
    }
  };

  const [createSession] = useMutationWithSnackbar(
    'LaunchSessionOnDevicePage.createSessionMutation',
    CreateSessionMutationDocument,
    { onCompleted: handleLaunchSuccess },
  );

  const handleDeviceUpdateClick = (device: VrDeviceType) => {
    const updatable = checkDeviceUpdatable(device);
    if (updatable) {
      setDeviceToUpdate({ device, skipAvailable: false });
    }
  };

  const launchOnDevices = () => {
    if (!selectedDevices.length) {
      return;
    }

    const playlist =
      getFromLocalStorageWithExpiration<CreateSessionPlaylistItemInput[]>(
        playlistStorageKey!,
      ) || [];
    createSession({
      variables: {
        createSessionInput: {
          launch: true,
          scenes: playlist.map((playlistItem) => ({
            sceneId: playlistItem.sceneId,
            params: playlistItem.params?.map((param) => ({
              paramId: param.paramId,
              valueId: param.valueId,
              name: param.name,
              value: param.value,
            })),
          })),
          vrDeviceIds: selectedDevices,
          userId: userId,
        },
      },
    });
  };

  const handleDeviceClick = (device: VrDeviceType) => {
    if (selectMode === 'single') {
      setDevices((d) => (d.includes(device.id) ? [] : [device.id]));
    } else {
      setDevices((d) =>
        d.includes(device.id)
          ? d.filter((id) => id !== device.id)
          : [...d, device.id],
      );
    }
  };

  const handleDeviceRestartClick = async (device: VrDeviceType) => {
    await restartVrDevice(device);
  };

  //Make sure component for select is separare, so it can be used not just for launching
  return (
    <ContentWrapper>
      <PageHeader back title={_t('selectDevice', 'Select device')}>
        <Button
          id="launch-on-device"
          variant="contained"
          disabled={!selectedDevices.length}
          onClick={launchOnDevices}
          startIcon={<PlayArrowOutlinedIcon />}
        >
          {selectedDevices.length
            ? _t('launchOnDevice', 'Launch on selected')
            : _t('selecrDevice', 'Select device')}
        </Button>
      </PageHeader>
      <Flex justifyContent="center">
        <DeviceList
          selectedDevices={selectedDevices}
          isActive={isDeviceActive}
          onDeviceUpdateClick={handleDeviceUpdateClick}
          onDeviceClick={handleDeviceClick}
          onDeviceRestartClick={handleDeviceRestartClick}
        />
      </Flex>
      <DeviceUpdateDialog
        device={deviceToUpdate?.device}
        onClose={() => setDeviceToUpdate(null)}
      ></DeviceUpdateDialog>
    </ContentWrapper>
  );
};
export default LaunchSessionOnDevicePage;
