import { ReactNode } from 'react';
import { Flex } from '../../../base/components/Flex';
import { SectionPaper } from '../SectionPaper';

interface Props {
  children: ReactNode;
  className?: string;
}

const DetailCard = ({ children, className }: Props) => {
  return (
    <SectionPaper className={className}>
      <Flex flexDirection="row">{children}</Flex>
    </SectionPaper>
  );
};

export default DetailCard;
