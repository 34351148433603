import { SvgIcon, SvgIconProps } from '@mui/material';

const DeIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0_789_9406)">
        <path
          d="M0.746307 16.1749C2.44182 20.7445 6.84034 24.001 11.9999 24.001C17.1595 24.001 21.558 20.7445 23.2535 16.1749L11.9999 15.1315L0.746307 16.1749Z"
          fill="#FFDA44"
        />
        <path
          d="M11.9999 0.000778198C6.84034 0.000778198 2.44182 3.25728 0.746307 7.82689L11.9999 8.87033L23.2535 7.82684C21.558 3.25728 17.1595 0.000778198 11.9999 0.000778198Z"
          fill="black"
        />
        <path
          d="M0.746421 7.82578C0.264077 9.12576 3.05176e-05 10.5318 3.05176e-05 11.9997C3.05176e-05 13.4675 0.264077 14.8736 0.746421 16.1736H23.2537C23.736 14.8736 24 13.4675 24 11.9997C24 10.5318 23.736 9.12576 23.2536 7.82578H0.746421Z"
          fill="#D80027"
        />
      </g>
      <defs>
        <clipPath id="clip0_789_9406">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default DeIcon;
