import { useQuery } from '@apollo/client';
import { State, useState } from '@hookstate/core';
import { CircularProgress } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseDialog } from '../../../base/components/BaseDialog';
import {
  GetVrSessionDocument,
  GetVrSessionQuery,
} from '../../../graphql/graphql-operations';
import useQueryErrorSnackbar from '../../../hooks/useQueryErrorSnackbar';
import EndSessionStep from './EndSessionStep';
import NewUserStep from './NewUserStep';
import SelectUserStep from './SelectUserStep';

interface Props {
  open: boolean;
  onClose: () => void;
  onFinished: (userId?: string) => void;
  vrSessionId: string;
}

export enum Step {
  EndSession = 'endSession',
  SelectUser = 'selectUser',
  NewUser = 'newUser',
}

interface StateValues {
  currentStep: Step;
  cachedVrSessionId?: string;
}

const EndVrSessionDialog: React.VFC<Props> = (props: Props) => {
  const { open, vrSessionId: tmpSessionId, onClose, onFinished } = props;
  const { t } = useTranslation();
  const state = useState<StateValues>({ currentStep: Step.EndSession });
  //from user table, when session ends, vrSessionId dissapears, but its needed for rating
  const vrSessionId = tmpSessionId || state.cachedVrSessionId.value;
  const { data, error, refetch } = useQuery(GetVrSessionDocument, {
    variables: {
      sessionInput: {
        sessionId: vrSessionId,
      },
    },
    skip: !open || !vrSessionId,
  });
  useQueryErrorSnackbar(error, refetch);
  useEffect(
    () => {
      open &&
        data &&
        state.batch((s) => {
          if (tmpSessionId) {
            state.cachedVrSessionId.set(tmpSessionId);
          }
          if (!data.session.endedAt) {
            state.currentStep.set(Step.EndSession);
          } else {
            resolveAfterEndStep(data, state);
          }
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [open, data],
  );

  const resolveAfterEndStep = (
    data: GetVrSessionQuery,
    state: State<StateValues>,
  ) => {
    if (data.session.user) {
      onFinished(data.session.user.id);
      return;
    }
    state.currentStep.set(Step.SelectUser);
  };

  const handleClose = () => {
    onClose();
  };

  const currentStep = state.currentStep.get();
  return (
    <BaseDialog
      open={open}
      onClose={handleClose}
      title={t(`endVrSessionDialog.${currentStep}.title`)}
    >
      {!data ? (
        <CircularProgress />
      ) : (
        <>
          {currentStep === Step.EndSession && (
            <EndSessionStep
              vrSessionId={vrSessionId!}
              //userId={data.session.user?.id}
              onCancel={handleClose}
              onNext={() => resolveAfterEndStep(data, state)}
            />
          )}
          {currentStep === Step.SelectUser && (
            <SelectUserStep
              vrSessionId={vrSessionId!}
              onCancel={handleClose}
              onNewUser={() => state.currentStep.set(Step.NewUser)}
              onNext={() => onFinished(data.session.user?.id)}
            />
          )}
          {currentStep === Step.NewUser && (
            <NewUserStep
              vrSessionId={vrSessionId!}
              onNext={() => onFinished(data.session.user?.id)}
              onCancel={handleClose}
            />
          )}
        </>
      )}
    </BaseDialog>
  );
};

export default EndVrSessionDialog;
