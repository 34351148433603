import { Box } from '@mui/material';
import { Flex } from '../../../base/components/Flex';
import useResponsiveStyleValue from '../../../lib/useResponsiveStyleValue';
import { ScrollableList } from '../../pages/Users/components/ScrollableList';
import PlaylistSelectParam from './PlaylistSelectParam';
import {
  PlaylistParamValueIds,
  PlaylistSelectedParams,
  SceneParametr,
} from './PlaylistTypes';

interface Props {
  params: SceneParametr[];
  selectedParams: PlaylistSelectedParams;
  enabledParams: PlaylistParamValueIds;
  setFilterParam: (paramId: string, valueId?: string) => void;
}

const PlaylistOptionalFilter = ({
  params,
  setFilterParam,
  selectedParams,
  enabledParams,
}: Props) => {
  const direction = useResponsiveStyleValue({ xs: 'row', md: 'column' });
  const handleChange = (paramId: string) => (valueId?: string) =>
    setFilterParam(paramId, valueId);

  const renderParam = (param: SceneParametr) => {
    return (
      <PlaylistSelectParam
        key={param.id}
        param={param}
        onChange={handleChange(param.id)}
        selectedId={selectedParams[param.id]}
        enabledIds={enabledParams[param.id]}
      />
    );
  };
  return direction === 'column' ? (
    <>{params.map(renderParam)}</>
  ) : (
    <Box>
      <ScrollableList
        id="playlist-optional-filters"
        leftButtonSx={{ transform: 'translateY(5%)' }}
        rightButtonSx={{ transform: 'translateY(5%)' }}
      >
        <Flex gap={4}>{params.map(renderParam)}</Flex>
      </ScrollableList>
    </Box>
  );
};

export default PlaylistOptionalFilter;
