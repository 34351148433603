import { useState } from '@hookstate/core';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseTypography } from '../../../base/components/BaseTypography';
import { Flex } from '../../../base/components/Flex';
import { LiveSessionsContext } from './LiveSessionsProvider';
import VrSessionBubble from './VrSessionBubble';

interface State {
  nowTime: number;
  scrollButtonsHidden: boolean;
  scrolling: boolean;
  clientX: number;
  scrollX: number;
}

const LiveSessions: React.VFC = () => {
  const { t } = useTranslation();
  const { vrSessions } = useContext(LiveSessionsContext);

  const state = useState<State>({
    nowTime: new Date().getTime(),
    scrollButtonsHidden: false,
    scrolling: false,
    clientX: 0,
    scrollX: 0,
  });
  const vrSessionsElementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const interval = setInterval(
      () =>
        state.batch((state) => {
          state.nowTime.set(new Date().getTime());
          state.scrollButtonsHidden.set(
            vrSessionsElementRef.current
              ? vrSessionsElementRef.current.scrollWidth <=
                  vrSessionsElementRef.current.clientWidth
              : false,
          );
        }),
      1000,
    );
    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      clearInterval(interval);
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('mousemove', handleMouseMove);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMouseDown = (e: React.MouseEvent) => {
    state.batch((state) => {
      state.scrolling.set(true);
      state.clientX.set(e.clientX);
    });
  };

  const handleMouseUp = () => {
    state.scrolling.set(false);
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (state.scrolling.value && vrSessionsElementRef.current) {
      const newScrollX = state.scrollX.value - e.clientX + state.clientX.value;
      vrSessionsElementRef.current.scrollLeft = newScrollX;
      state.batch((state) => {
        state.scrollX.set(newScrollX);
        state.clientX.set(e.clientX);
      });
    }
  };

  return (
    <StyledFlex flexGrow={1} flexDirection="row" alignItems="center">
      <Box className={classes.labelBox}>
        <BaseTypography variant="body2" fontWeight="bold">
          {t('liveSessions.title', 'Live:')}
        </BaseTypography>
      </Box>

      <div
        ref={vrSessionsElementRef}
        className={classes.vrSessionsBox}
        onMouseDown={handleMouseDown}
      >
        {vrSessions.map((vrSession) => (
          <VrSessionBubble
            key={vrSession.id}
            vrSessionId={vrSession.id}
            name={vrSession.userName || vrSession.deviceName}
            duration={state.nowTime.value - vrSession.startedAt.getTime()}
            active={!!vrSession.active}
          />
        ))}
      </div>
    </StyledFlex>
  );
};

export default LiveSessions;

const PREFIX = 'LiveSessions';

const classes = {
  labelBox: `${PREFIX}-labelBox`,
  vrSessionsBox: `${PREFIX}-vrSessionsBox`,
};

const StyledFlex = styled(Flex)(() => {
  return {
    [`& .${classes.labelBox}`]: {
      paddingLeft: '0.6rem',
      paddingRight: '0.6rem',
      textTransform: 'uppercase',
    },
    [`& .${classes.vrSessionsBox}`]: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'row',
      alignItems: 'center',
      width: '0rem',
      overflow: 'auto',
      whiteSpace: 'nowrap',
      msOverflowStyle: 'none',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      userSelect: 'none',
    },
  };
});
