import { useMutation } from '@apollo/client';
import { useState } from '@hookstate/core';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from '../../../base/components/ConfirmDialog/ConfirmDialog';
import { ActivateUsersDocument } from '../../../graphql/graphql-operations';
import useMutationSnackbar from '../../../hooks/useMutationSnackbar';
import { TableData } from '../../components/PatientsTable/PatientsTable';
interface Props {
  rowData: TableData;
}

const ArchivedPatientRowActions: React.VFC<Props> = ({
  rowData: { id: patientId },
}: Props) => {
  const { t } = useTranslation();
  const state = useState({ reactivateConfirmDialogOpen: false });

  const activateUserHandler = useMutationSnackbar('activateUsers', {
    translationKey: 'archivedPatients.reactivate',
  });

  const [activateUsers] = useMutation(ActivateUsersDocument, {
    ...activateUserHandler,
  });

  const handleActivateClick = () => {
    activateUsers({
      variables: { activateUsersInput: { userIds: [patientId] } },
    });
  };

  return (
    <>
      <Button
        id={`archived-patient-reactivate-${patientId}`}
        color="secondary"
        variant="contained"
        size="medium"
        startIcon={<UnarchiveIcon />}
        onClick={() => state.reactivateConfirmDialogOpen.set(true)}
      >
        {t('archivedPatients.reactivate.action', 'Reactivate')}
      </Button>
      <ConfirmDialog
        open={state.reactivateConfirmDialogOpen.get()}
        onClose={() => state.reactivateConfirmDialogOpen.set(false)}
        onConfirm={handleActivateClick}
      />
    </>
  );
};

export default ArchivedPatientRowActions;
