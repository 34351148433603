import { useQuery } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { useHookstate } from '@hookstate/core';
import { useState } from 'react';
import { adminState } from '../../../../adminState';
import {
  AddTrainingAssigmentForUserGroupsDocument,
  GetTrainingAssignmentsDocument,
  GetUserGroupsDocument,
  GetUserTrainingAssignmentsDocument,
  StreamType,
  TrainingAssignmentUserGroupsInput,
} from '../../../../graphql/graphql-operations';
import { useMutationWithSnackbar } from '../../../../hooks/useMutationWithSnackbar';
import useQueryErrorSnackbar from '../../../../hooks/useQueryErrorSnackbar';
import {
  MEDICAL_ASSIGNMENT_GRACE_PERIOD_DEFAULT,
  MEDICAL_ASSIGNMENT_VALID_FOR_DEFAULT,
  TRAINING_ASSIGNMENT_GRACE_PERIOD_DEFAULT,
  TRAINING_ASSIGNMENT_VALID_FOR_DEFAULT,
} from '../../../../lib/constants';
import useStreamType from '../../../../lib/useStreamType';
import { DebugTrainingAssignmentFormValues } from '../../../components/Debug/DebugTrainingAssignmentDialog';

interface Params {
  templateId?: string;
  onCompleted: () => void;
}

const usePlaylistAssignGroups = ({ templateId, onCompleted }: Params) => {
  const streamType = useStreamType();
  const companyId = useHookstate(adminState.selectedCompanyId);
  const [selectedUserGroupIds, setSelectedUserGroupIds] = useState<string[]>(
    [],
  );

  const { data, error, refetch } = useQuery(GetUserGroupsDocument, {
    variables: {
      input: {
        companyId: companyId.get(),
      },
    },
    fetchPolicy: 'cache-and-network',
  });
  useQueryErrorSnackbar(error, refetch);

  const { data: existing } = useQuery(GetTrainingAssignmentsDocument, {
    variables: {
      input: {
        companyId: companyId.get(),
        playlistTemplateIds: [templateId!],
      },
    },
    skip: !templateId,
    fetchPolicy: 'cache-and-network',
  });

  const alreadyAssignedIds =
    existing?.trainingAssignments.map((ta) => ta.userGroupId) || [];
  const groups =
    data?.userGroups.filter((g) => !alreadyAssignedIds.includes(g.id)) || [];

  const [createAssignmnet, { loading }] = useMutationWithSnackbar(
    'PlaylistTemplateAssignUserGroupsPage.mutation',
    AddTrainingAssigmentForUserGroupsDocument,
    {
      onCompleted: onCompleted,
      refetchQueries: [getOperationName(GetUserTrainingAssignmentsDocument)!],
    },
  );

  const assignToGroups = async (vals?: DebugTrainingAssignmentFormValues) => {
    const input: TrainingAssignmentUserGroupsInput = {
      userGroupIds: selectedUserGroupIds,
      playlistTemplateId: templateId!,
      gracePeriodDays:
        streamType === StreamType.MEDICAL
          ? MEDICAL_ASSIGNMENT_GRACE_PERIOD_DEFAULT
          : TRAINING_ASSIGNMENT_GRACE_PERIOD_DEFAULT,
      validForDays:
        streamType === StreamType.MEDICAL
          ? MEDICAL_ASSIGNMENT_VALID_FOR_DEFAULT
          : TRAINING_ASSIGNMENT_VALID_FOR_DEFAULT,

      ...vals,
      validTo: vals?.validTo?.toISOString() || null,
    };
    await createAssignmnet({
      variables: {
        input: input,
      },
    });
  };

  return {
    assignToGroups,
    selectedUserGroupIds,
    setSelectedUserGroupIds,
    groups,
    groupAssignmentSubmitting: loading,
  };
};

export default usePlaylistAssignGroups;
