import { useQuery } from '@apollo/client';
import AccountBalanceRoundedIcon from '@mui/icons-material/AccountBalanceRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { Box, Button, MenuItem } from '@mui/material';
import { Formik } from 'formik';
import { equals, omit } from 'ramda';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { BaseDialog } from '../../../../base/components/BaseDialog';
import ConfirmDialog from '../../../../base/components/ConfirmDialog/ConfirmDialog';
import { Flex } from '../../../../base/components/Flex';
import FormikCompanySelect from '../../../../base/components/form/FormikCompanySelect';
import FormikForm from '../../../../base/components/form/FormikForm';
import FormikSelect from '../../../../base/components/form/FormikSelect';
import FormikTextField from '../../../../base/components/form/FormikTextField';
import HasPermission from '../../../../base/components/UserProvider/HasPermission';
import {
  DeleteVrDeviceDocument,
  EditVrDeviceDocument,
  GetDeviceByIdDocument,
  GetVrBoxesDocument,
  UserPermission,
} from '../../../../graphql/graphql-operations';
import { useMutationWithSnackbar } from '../../../../hooks/useMutationWithSnackbar';
import {
  TFunctionPrefixed,
  useTranslationPrefix,
} from '../../../../hooks/useTranslationPrefix';

interface VrDeviceForm {
  name: string;
  vrBoxId: string;
  companyId: string;
}

interface Props {
  vrDeviceId: string | null;
  onClose: () => void;
}

const EditDeviceDialog = ({ vrDeviceId, onClose }: Props) => {
  const { _t } = useTranslationPrefix('EditDeviceDialog');
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [transferOpened, setTransferOpened] = useState(false);

  useEffect(() => {
    setDeleteConfirmOpen(false);
    setTransferOpened(false);
  }, [vrDeviceId]);

  const { data } = useQuery(GetDeviceByIdDocument, {
    variables: {
      vrDeviceId: vrDeviceId,
    },
    skip: !vrDeviceId,
  });
  const vrDevice = data?.vrDevice;

  const { data: vrBoxData } = useQuery(GetVrBoxesDocument, {
    variables: {
      companyIdOptionalInput: {
        companyId: String(vrDevice?.companyId),
      },
    },
    skip: !vrDevice?.companyId,
  });

  const [deleteVrDevice, { loading }] = useMutationWithSnackbar(
    'EditDeviceDialog.deleteMutation',
    DeleteVrDeviceDocument,
    {
      onCompleted: onClose,
    },
  );

  const handleDeleteVrDevice = () => {
    if (!vrDevice) {
      return;
    }
    deleteVrDevice({
      variables: { deleteVrDeviceInput: { vrDeviceId: vrDevice.id } },
    });
  };

  const [editVrDevice] = useMutationWithSnackbar(
    'EditDeviceDialog.mutation',
    EditVrDeviceDocument,
  );

  const handleSubmit = async ({ vrBoxId, ...rest }: VrDeviceForm) => {
    if (!vrDevice) {
      return;
    }
    let toEdit: Partial<VrDeviceForm> = rest;
    if (rest.companyId === vrDevice.companyId.toString()) {
      toEdit = omit(['companyId'], toEdit);
    }
    await editVrDevice({
      variables: {
        editVrDeviceInput: {
          vrDeviceId: vrDevice.id,
          vrBoxId: vrBoxId || null,
          ...toEdit,
        },
      },
    });
    onClose();
  };

  const initialValues: VrDeviceForm = {
    name: vrDevice?.name || '',
    vrBoxId: vrDevice?.vrBoxId || '',
    companyId: vrDevice?.companyId.toString() || '',
  };

  return (
    <>
      <BaseDialog
        open={!!vrDeviceId}
        onClose={onClose}
        title={
          <Box
            sx={{
              whiteSpace: 'nowrap',
              maxWidth: '20ch',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {_t('title', 'Edit device {{name}}', { name: vrDevice?.name })}
          </Box>
        }
      >
        {vrDevice && (
          <Formik<VrDeviceForm>
            initialValues={initialValues}
            validationSchema={getValidationSchema(_t)}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, values, isValid }) => (
              <FormikForm>
                <FormikTextField
                  variant="outlined"
                  name="name"
                  label={_t('form.name', 'Device name')}
                  inputProps={{
                    autoComplete: 'off',
                  }}
                  required
                />
                <FormikSelect name="vrBoxId" label={_t('form.vrBox', 'VR Box')}>
                  <MenuItem value="">
                    <em>{_t('from.vrBoxUnassigned', 'Not Assigned')}</em>
                  </MenuItem>
                  {vrBoxData?.vrBoxes.map((vrBox) => (
                    <MenuItem key={vrBox.id} value={vrBox.id}>
                      {vrBox.name}
                    </MenuItem>
                  ))}
                </FormikSelect>
                <HasPermission val={UserPermission.MANAGE_COMPANY_VR_DEVICES}>
                  {transferOpened && (
                    <FormikCompanySelect
                      name="companyId"
                      label={_t('company', 'Organization')}
                    />
                  )}
                  <Flex justifyContent="center" gap={2}>
                    <Button
                      id="edit-vr-device-delete"
                      color="error"
                      variant="text"
                      size="small"
                      startIcon={<DeleteRoundedIcon />}
                      onClick={() => setDeleteConfirmOpen(true)}
                    >
                      {_t('delete', 'Delete')}
                    </Button>
                    {!transferOpened && (
                      <Button
                        id="edit-vr-device-transfer"
                        color="primary"
                        variant="text"
                        size="small"
                        startIcon={<AccountBalanceRoundedIcon />}
                        onClick={() => setTransferOpened(true)}
                      >
                        {_t('transfer', 'Transfer')}
                      </Button>
                    )}
                  </Flex>
                </HasPermission>
                <Flex justifyContent="center" gap={2}>
                  <Button
                    id="edit-vr-device-confirm"
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={
                      isSubmitting || !isValid || equals(initialValues, values)
                    }
                  >
                    {_t('save', 'Save')}
                  </Button>
                </Flex>
              </FormikForm>
            )}
          </Formik>
        )}
      </BaseDialog>
      <ConfirmDialog
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
        onConfirm={handleDeleteVrDevice}
        title={_t('deleteVrDevice.title', 'Do you want to remove this device?')}
        confirmLabel={_t('deleteVrDevice.confirm', 'Remove')}
        confirmButtonColor="error"
        confirmDisabled={loading}
        confirmButtonStartIcon={<DeleteRoundedIcon />}
      />
    </>
  );
};

export default EditDeviceDialog;

const getValidationSchema = (_t: TFunctionPrefixed) =>
  Yup.object({
    name: Yup.string().required(
      _t('form.nameRequired', 'Device name is required'),
    ),
  });
