import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Button } from '@mui/material';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import HasAnyPermission from '../../../base/components/UserProvider/HasAnyPermission';
import {
  PlaylistTemplateFilterField,
  UserPermission,
} from '../../../graphql/graphql-operations';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import ContentWrapper from '../../components/ContentWrapper';
import PageHeader from '../../components/PageHeader';
import { SearchField } from '../../components/SearchField';
import PlaylistTemplateFolderDialog from './components/PlaylistTemplateFolderDialog';
import PlaylistTemplateFoldersList from './components/PlaylistTemplateFoldersList';
import PlaylistTemplatesList from './components/PlaylistTemplatesList';

const PlaylistTemplatesPage = () => {
  const navigate = useNavigate();
  const [dialog, setDialog] = useState({
    isOpen: false,
    folderId: null,
  });
  const { _t } = useTranslationPrefix('PlaylistTemplatesPage');
  const [query, setQuery] = useState('');

  return (
    <ContentWrapper>
      <PageHeader title={_t('title', 'Playlists')}>
        <HasAnyPermission val={[UserPermission.MANAGE_PLAYLIST_FOLDERS]}>
          <Button
            id="create-playlist-folder"
            onClick={() => setDialog({ isOpen: true, folderId: null })}
            variant="outlined"
            color="bgContrast"
          >
            {_t('newFolder', 'New Folder')}
          </Button>
        </HasAnyPermission>
        <HasAnyPermission
          val={[
            UserPermission.MANAGE_MY_PLAYLIST_TEMPLATES,
            UserPermission.MANAGE_OTHERS_PLAYLIST_TEMPLATES,
          ]}
        >
          <Button
            id="create-playlist"
            to="./create"
            component={Link}
            variant="contained"
            color="primary"
            startIcon={<AddRoundedIcon />}
          >
            {_t('newPlaylist', 'New Playlist')}
          </Button>
        </HasAnyPermission>
        <SearchField value={query} onValueChange={setQuery} />
      </PageHeader>
      <HasAnyPermission
        val={[
          UserPermission.READ_PLAYLIST_FOLDERS,
          UserPermission.MANAGE_PLAYLIST_FOLDERS,
        ]}
      >
        <PlaylistTemplateFoldersList
          sx={{ mb: 2 }}
          onClick={(folder) => navigate(`./folders/${folder.id}`)}
          searchConfig={{
            filterBy: PlaylistTemplateFilterField.NAME,
            filterValue: query,
          }}
        />{' '}
      </HasAnyPermission>
      <PlaylistTemplatesList
        onClick={(item) => navigate(`./${item.id}`)}
        searchConfig={{
          filterBy: PlaylistTemplateFilterField.NAME,
          filterValue: query,
        }}
      />

      <PlaylistTemplateFolderDialog
        open={dialog.isOpen}
        folderId={dialog.folderId}
        onClose={() => setDialog({ folderId: null, isOpen: false })}
      />
    </ContentWrapper>
  );
};

export default PlaylistTemplatesPage;
