import { TextFieldProps } from '@mui/material';
import { Field } from 'formik';
import { TextField } from 'formik-mui';

interface Props extends Omit<TextFieldProps, 'value' | 'error'> {
  name: string;
}

const FormikTextField = ({ name, inputProps, ...textFieldProps }: Props) => {
  return (
    <Field
      component={TextField}
      name={name}
      inputProps={{ required: false, ...inputProps }}
      {...textFieldProps}
    />
  );
};

export default FormikTextField;
