import { useMutation } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import ArchiveIcon from '@mui/icons-material/Archive';
import { Button } from '@mui/material';
import { Field, Formik, FormikHelpers } from 'formik';
import { TextField } from 'formik-mui';
import { useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { ObjectShape } from 'yup/lib/object';
import { BaseDialog } from '../../../base/components/BaseDialog';
import ConfirmDialog from '../../../base/components/ConfirmDialog/ConfirmDialog';
import { Flex } from '../../../base/components/Flex';
import FormikCheckBox from '../../../base/components/form/FormikCheckBox';
import FormikForm from '../../../base/components/form/FormikForm';
import {
  ApplicationFragmentFragment,
  ApplicationStatus,
  CreateApplicationDocument,
  CreateApplicationMutation,
  EditApplicationDocument,
  GetAllApplicationScenesDocument,
  GetApplicationsDocument,
  GetApplicationVersionDocument,
  SaveApplicationErrorCode,
} from '../../../graphql/graphql-operations';
import useMutationSnackbar from '../../../hooks/useMutationSnackbar';
import ApplicationsApkUploader from './ApplicationsApkUploader';

interface Props {
  open: boolean;
  application?: ApplicationFragmentFragment;
  onClose: () => void;
}

export interface ApplicationEditFormData {
  fileId: string;
  name: string;
  enabled: boolean;
  systemApp: boolean;
  version: string;
}

type SubmitErrors = CreateApplicationMutation['createApplication']['errors'];

const ApplicationEditDialog = ({ open, application, onClose }: Props) => {
  const [archiveConfirmOpen, setArchiveConfirmOpen] = useState(false);
  const { t } = useTranslation();

  const createApplicationHandler = useMutationSnackbar('createApplication', {
    translationKey: 'applications.dialog.createAction',
    onSuccessfullyCompleted: onClose,
  });

  const [createMutation] = useMutation(CreateApplicationDocument, {
    ...createApplicationHandler,
    refetchQueries: [
      getOperationName(GetApplicationsDocument)!,
      getOperationName(GetAllApplicationScenesDocument)!,
    ],
  });

  const editApplicationHandler = useMutationSnackbar('editApplication', {
    translationKey: 'applications.dialog.editAction',
    onSuccessfullyCompleted: onClose,
  });

  const [editMutation] = useMutation(EditApplicationDocument, {
    ...editApplicationHandler,
    refetchQueries: [
      getOperationName(GetApplicationVersionDocument)!,
      getOperationName(GetAllApplicationScenesDocument)!,
    ],
  });

  const suspendHandler = useMutationSnackbar('editApplication', {
    translationKey: 'applications.dialog.suspend',
    onSuccessfullyCompleted: () => {
      setArchiveConfirmOpen(false);
      onClose();
    },
  });

  const [suspendMutation] = useMutation(EditApplicationDocument, {
    ...suspendHandler,
  });

  const handleArchiveClick = () => {
    if (application) {
      suspendMutation({
        variables: {
          app: {
            applicationId: application?.id,
            status: ApplicationStatus.SUSPENDED,
          },
        },
      });
    }
  };

  const handleSubmit = async (
    { version, ...values }: ApplicationEditFormData,
    helpers: FormikHelpers<ApplicationEditFormData>,
  ) => {
    if (application) {
      const { data } = await editMutation({
        variables: { app: { applicationId: application.id, ...values } },
      });
      if (data?.editApplication.errors.length) {
        handleErrors(data.editApplication.errors, helpers);
      }
    } else {
      const { data } = await createMutation({
        variables: { app: values },
      });
      if (data?.createApplication.errors.length) {
        handleErrors(data.createApplication.errors, helpers);
      }
    }
  };

  const handleErrors = (
    errors: SubmitErrors,
    { setFieldError }: FormikHelpers<ApplicationEditFormData>,
  ) => {
    for (const err of errors) {
      if (err.code === SaveApplicationErrorCode.PACKAGE_NOT_UNIQUE) {
        setFieldError(
          'fileId',
          t(
            'applications.dialog.editAction.error.packageNotUnique',
            'Package must be unique',
          ),
        );
      }
      if (err.code === SaveApplicationErrorCode.PACKAGE_MISMATCH) {
        setFieldError(
          'fileId',
          t(
            'applications.dialog.editAction.error.packageMismatch',
            'Package different from current app',
          ),
        );
      }
    }
  };

  return (
    <>
      <BaseDialog
        open={open}
        title={
          application
            ? t('applications.dialog.edit.title', 'Edit application')
            : t('applications.dialog.create.title', 'New application')
        }
        onClose={onClose}
      >
        <Formik<ApplicationEditFormData>
          initialValues={{
            enabled:
              application?.enabled === undefined ? true : !!application.enabled,
            fileId: '',
            name: application?.name || '',
            version: '',
            systemApp: application?.systemApp || false,
          }}
          onSubmit={handleSubmit}
          validationSchema={getValidationSchema(t, !!application)}
        >
          {({ isSubmitting, setFieldValue, values }) => (
            <FormikForm>
              <ApplicationsApkUploader name="fileId" disabled={isSubmitting} />
              <Field
                component={TextField}
                name="name"
                variant="outlined"
                label={t('applications.dialog.nameLabel', 'Application name')}
              />
              <Field
                component={TextField}
                name="version"
                variant="outlined"
                label={t(
                  'applications.dialog.versionLabel',
                  'Application version',
                )}
                disabled={true}
              />
              <FormikCheckBox
                name="systemApp"
                label={t(
                  'applications.dialog.systemAppLabel',
                  'System application',
                )}
              />
              <FormikCheckBox
                name="enabled"
                label={t('applications.dialog.enabledLabel', 'Enabled')}
              />
              {application && (
                <Flex justifyContent="center">
                  <Button
                    id="application-edit-dialog-archive"
                    variant="text"
                    size="large"
                    color="error"
                    onClick={() => setArchiveConfirmOpen(true)}
                    startIcon={<ArchiveIcon />}
                  >
                    {t('applications.dialog.archive', 'Archive')}
                  </Button>
                </Flex>
              )}
              <Flex justifyContent="space-between">
                <Button
                  id="application-edit-dialog-cancel"
                  size="large"
                  color="primary"
                  onClick={onClose}
                >
                  {t('common.cancel', 'Cancel')}
                </Button>
                <Button
                  id="application-edit-dialog-confirm"
                  type="submit"
                  size="large"
                  color="primary"
                  variant="contained"
                  disabled={isSubmitting}
                >
                  {application
                    ? t('applications.dialog.edit.save', 'Save')
                    : t('applications.dialog.create.save', 'Create')}
                </Button>
              </Flex>
            </FormikForm>
          )}
        </Formik>
      </BaseDialog>
      <ConfirmDialog
        open={archiveConfirmOpen}
        onClose={() => setArchiveConfirmOpen(false)}
        onConfirm={handleArchiveClick}
      />
    </>
  );
};

const getValidationSchema = (t: TFunction, edit: boolean) => {
  const object: ObjectShape = {
    name: Yup.string()
      .min(
        4,
        t('applications.dialog.nameMin', 'Minimal number of characters is 4'),
      )
      .max(
        255,
        t('applications.dialog.nameMax', 'Maximum number of characters is 255'),
      )
      .required(t('applications.dialog.nameRequired', 'App name is required')),
  };
  if (!edit) {
    object.fileId = Yup.string().required(
      t('applications.dialog.fileIdRequired', 'APK file is required'),
    );
  }

  return Yup.object(object);
};

export default ApplicationEditDialog;
