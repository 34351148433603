import { logout } from '../auth';

export default function useLogout() {
  const doLogout = async () => {
    await logout();
    sessionStorage.clear();
    window.location.reload(true);
  };
  return { logout: doLogout };
}
