import { useQuery } from '@apollo/client';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, ModalProps, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import { BaseTypography } from '../../../base/components/BaseTypography';
import { Flex } from '../../../base/components/Flex';
import { VrDeviceType } from '../../../base/components/VrDevicesProvider/VrDevicesProvider';
import {
  GetVrDeviceScenesDocument,
  GetVrDeviceScenesQuery,
  VrDeviceSceneGroupFragmentFragment,
} from '../../../graphql/graphql-operations';
import useQueryErrorSnackbar from '../../../hooks/useQueryErrorSnackbar';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import VrDeviceList from '../VrDeviceList/VrDeviceList';
import SelectApplicationGroup from './SelectApplicationGroup';
import SelectApplicationScene from './SelectApplicationScene';
import SelectApplicationSceneParams from './SelectApplicationSceneParams';

interface Props {
  vrDeviceId?: string;
  userId?: string;
  onClose?: ModalProps['onClose'];
}

type SceneGroup = VrDeviceSceneGroupFragmentFragment;
type LaunchableScene = GetVrDeviceScenesQuery['vrDevice']['scenes'][0];

const SelectSceneWizard = ({ vrDeviceId, userId, onClose }: Props) => {
  const { _t } = useTranslationPrefix('launchSceneWizard');
  const [selectedVrDeviceId, setSelectedVrDeviceId] = useState(vrDeviceId);
  const [selectedGroupId, setSelectedGroupId] = useState<string>();
  const [selectedScene, setSelectedScene] = useState<LaunchableScene>();
  const { spacing } = useTheme();

  const title = !selectedVrDeviceId
    ? _t('selectvrDeviceTitle', 'Select device')
    : !selectedGroupId
    ? _t('selectGroupTitle', 'Select program')
    : !selectedScene
    ? _t('selectSceneTitle', 'Select scene')
    : _t('selectSceneParamsTitle', 'Select parameters');

  const { data, error, refetch } = useQuery(GetVrDeviceScenesDocument, {
    variables: { vrDeviceId: selectedVrDeviceId },
    skip: !selectedVrDeviceId,
  });
  useQueryErrorSnackbar(error, refetch);

  const { groupedScenes, groups } = getGroupedScenes(data);
  const selectedGroupScenes =
    (selectedGroupId && groupedScenes[selectedGroupId]) || [];

  const handleBack = () => {
    if (selectedScene) {
      setSelectedScene(undefined);
      return;
    }
    if (selectedGroupId) {
      setSelectedGroupId(undefined);
      return;
    }
    if (selectedVrDeviceId && !vrDeviceId) {
      setSelectedVrDeviceId(undefined);
    }
  };

  const handleVrDeviceSelected = (device: VrDeviceType) => {
    setSelectedVrDeviceId(device.id);
    refetch({ vrDeviceId: device.id });
  };

  return (
    <>
      <Flex padding={2} flexDirection="column">
        <Flex justifyContent="space-between">
          <Box>
            {(selectedGroupId || (selectedVrDeviceId && !vrDeviceId)) && (
              <IconButton
                id="select-scene-wizzard-back"
                size="small"
                aria-label="back"
                onClick={handleBack}
              >
                <ArrowBackIcon color="primary" />
                <BaseTypography color="primary">
                  {_t('back', 'Back')}
                </BaseTypography>
              </IconButton>
            )}
          </Box>

          <Box>
            {onClose && (
              <IconButton
                id="select-scene-wizzard-close"
                size="small"
                aria-label="close"
                onClick={(event) => onClose(event, 'escapeKeyDown')}
              >
                <CloseIcon color="primary" />
              </IconButton>
            )}
          </Box>
        </Flex>
        <Flex justifyContent="center" paddingBottom={spacing(2)}>
          <BaseTypography variant="h4">{title}</BaseTypography>
        </Flex>
      </Flex>
      <Box paddingX={spacing(2)}>
        {!selectedVrDeviceId && (
          <VrDeviceList onDeviceSelected={handleVrDeviceSelected} />
        )}
        {selectedVrDeviceId && !selectedGroupId && (
          <SelectApplicationGroup
            vrDviceId={selectedVrDeviceId}
            userId={userId}
            groups={groups}
            onGroupSelected={(group) => setSelectedGroupId(group.id)}
          />
        )}
        {selectedVrDeviceId && selectedGroupId && !selectedScene && (
          <SelectApplicationScene
            scenes={selectedGroupScenes}
            onSceneSelected={(scene) => setSelectedScene(scene)}
          />
        )}
        {selectedVrDeviceId && selectedScene && (
          <SelectApplicationSceneParams
            vrDeviceId={selectedVrDeviceId}
            userId={userId}
            scene={selectedScene}
          />
        )}
      </Box>
    </>
  );
};

const getGroupedScenes = (data?: GetVrDeviceScenesQuery) => {
  const scenes = data?.vrDevice.scenes || [];
  const groupedScenes: { [groupId: string]: LaunchableScene[] } = {};
  const uniqueGroups: { [groupId: string]: SceneGroup } = {};
  scenes.forEach((scene) => {
    scene.scene.applicationSceneGroups?.forEach((group) => {
      groupedScenes[group.id] = [...(groupedScenes[group.id] || []), scene];
      uniqueGroups[group.id] = group;
    });
  });
  return {
    groupedScenes,
    groups: Object.values(uniqueGroups).filter((group) => group.enabled),
  };
};

export default SelectSceneWizard;
