import { getOperationName } from '@apollo/client/utilities';
import DeleteIcon from '@mui/icons-material/Delete';
import { Card, CardContent } from '@mui/material';
import { useState } from 'react';
import { BaseTypography } from '../../../../base/components/BaseTypography';
import ConfirmDialog from '../../../../base/components/ConfirmDialog/ConfirmDialog';
import { IconButtonFilled } from '../../../../base/components/IconButton';
import HasPermission from '../../../../base/components/UserProvider/HasPermission';
import {
  GetTrainingAssignmentForTemplateDocument,
  GetUserTrainingAssignmentsDocument,
  RemoveTrainingAssignmentsDocument,
  UserPermission,
} from '../../../../graphql/graphql-operations';
import { useMutationWithSnackbar } from '../../../../hooks/useMutationWithSnackbar';
import { useTranslationPrefix } from '../../../../hooks/useTranslationPrefix';
import { ListView } from '../../../components/ListView';
import { UserGroupTrainingAssignment } from './hooks';

interface Props {
  userGroupTrainingAssignments: UserGroupTrainingAssignment[];
}

const PlaylistTemplateGroupAssignmentList = ({
  userGroupTrainingAssignments,
}: Props) => {
  const { _t } = useTranslationPrefix('PlaylistTemplateGroupAssignmentList');
  const [toDelete, setToDelete] = useState<UserGroupTrainingAssignment | null>(
    null,
  );

  const [deleteTrainingAssignment] = useMutationWithSnackbar(
    'PlaylistTemplateGroupAssignmentList.removeMutation',
    RemoveTrainingAssignmentsDocument,
    {
      refetchQueries: [
        getOperationName(GetUserTrainingAssignmentsDocument)!,
        getOperationName(GetTrainingAssignmentForTemplateDocument)!,
      ],
    },
  );

  const handleDeleteConfirm = async (
    assignment: UserGroupTrainingAssignment,
  ) => {
    await deleteTrainingAssignment({
      variables: {
        input: {
          trainingAssignmentIds: [assignment.id],
        },
      },
    });
    setToDelete(null);
  };

  const renderValue = (
    userGroupTrainingAssignment: UserGroupTrainingAssignment,
  ) => {
    return (
      <Card>
        <CardContent
          sx={{
            minHeight: '6rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <BaseTypography variant="h4" fontWeight="bold" px={1}>
            {userGroupTrainingAssignment.userGroup.name}
          </BaseTypography>
          <HasPermission val={UserPermission.MANAGE_TRAINING_ASSIGNMENTS}>
            <IconButtonFilled
              id={`remove-group-${userGroupTrainingAssignment.userGroup.id}`}
              color="error"
              onClick={() => setToDelete(userGroupTrainingAssignment)}
            >
              <DeleteIcon />
            </IconButtonFilled>
          </HasPermission>
        </CardContent>
      </Card>
    );
  };

  return (
    <>
      <ListView
        rowKey="id"
        renderValue={renderValue}
        data={userGroupTrainingAssignments}
        columns={1}
      />
      <ConfirmDialog
        open={!!toDelete}
        title={_t(
          'unassignFromGroupConfirm',
          'Unassign training from group {{name}}?',
          { name: toDelete?.userGroup.name },
        )}
        onClose={() => setToDelete(null)}
        onConfirm={() => toDelete && handleDeleteConfirm(toDelete)}
      />
    </>
  );
};

export default PlaylistTemplateGroupAssignmentList;
