import { useState } from '@hookstate/core';
import escapeStringRegexp from 'escape-string-regexp';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseTypography } from '../../../base/components/BaseTypography';
import { Flex } from '../../../base/components/Flex';
import SearchControlBar from '../SearchControlBar/SearchControlBar';

interface BaseObject {
  id: string;
}
interface Props<T extends BaseObject> {
  id: string;
  title: string;
  placeholderTransKey: string;
  filterField: keyof T;
  list: readonly T[];
  card: React.ComponentType<T>;
}

const CardList = <T extends BaseObject>({
  id,
  title,
  placeholderTransKey,
  list,
  filterField,
  card: Card,
}: Props<T>) => {
  const filterQuery = useState('');
  const { t } = useTranslation();

  const handleChange = (value: string) => filterQuery.set(value);

  const filteredList = useMemo(
    () => filter(list, filterField, filterQuery.get()),
    [list, filterField, filterQuery],
  );
  return (
    <>
      <SearchControlBar
        id={`${id}-controll-bar`}
        title={title}
        totalCount={list.length}
        placeholderTransKey={placeholderTransKey}
        onChange={handleChange}
      />
      {filteredList.length ? (
        <Flex flexWrap="wrap">
          {filteredList.map((item) => (
            <Card key={item.id} {...item} />
          ))}
        </Flex>
      ) : (
        <BaseTypography variant="h6" color="primary">
          {t('cardList.emptyList', 'List is Empty')}
        </BaseTypography>
      )}
    </>
  );
};

export default CardList;

function filter<T>(
  list: readonly T[],
  filterField: keyof T,
  filterQuery: string | null,
) {
  if (!filterField || !filterQuery) {
    return list;
  }
  // remove all special character, or it could break search regex
  filterQuery = escapeStringRegexp(filterQuery);
  const regex = new RegExp(`.*${filterQuery}.*`, 'gi');
  return list.filter((item) => {
    return (item[filterField] as unknown as string).match(regex);
  });
}
