import { Box, styled } from '@mui/material';
import { useField } from 'formik';
import FormikErrorMessage from './FormikErrorMessage';

const defaultcolors = [
  '#2653c9',
  '#fdc955',
  '#20ce8d',
  '#ea5161',
  '#606060',
  '#bf862a',
  '#f296b7',
  '#53d815',
];

interface Props {
  label?: string;
  name: string;
  colors?: string[];
}

const FormikColorPicker = ({ label, name, colors = defaultcolors }: Props) => {
  const [{ value }, { touched, error }, { setValue, setTouched }] =
    useField(name);

  const handleOnChange = (newValue: string) => {
    if (!touched) {
      setTouched(true);
    }
    setValue(newValue);
  };

  const renderColorBox = (color: string, index: number) => {
    return (
      <ColorBoxWrapper
        key={`color-box-${index}`}
        selected={value === color}
        onClick={() => handleOnChange(color)}
      >
        <ColorBox colorValue={color} />
      </ColorBoxWrapper>
    );
  };

  return (
    <Box>
      {label && <label>{label}</label>}
      <ColorsWrapperBox>{colors.map(renderColorBox)}</ColorsWrapperBox>
      {error && touched && <FormikErrorMessage>{error}</FormikErrorMessage>}
    </Box>
  );
};

export default FormikColorPicker;

const ColorBox = styled(Box)<{ colorValue: string }>(
  ({ colorValue, theme: { spacing } }) => `
  background-color: ${colorValue};
  width: 100%;
  height: 100%;
  border-radius: ${spacing(0.5)};
`,
);

const ColorBoxWrapper = styled(Box)<{ selected: boolean }>(
  ({ selected, theme: { palette, spacing } }) => `
  width: ${spacing(4)};
  height: ${spacing(4)};
  margin: ${spacing(0.5)};
  border-radius: ${spacing(0.5)};
  padding:${spacing(0.25)};
  cursor: pointer;
  border: 2px solid transparent; 
  
  ${
    selected
      ? `
    border-color: ${palette.primary.main};
  `
      : `
    :hover {
      border-color: ${palette.primary.light};
    }
  `
  }
`,
);

const ColorsWrapperBox = styled(Box)`
  display: flex;
  flex-wrap: wrap;
`;
