import { Backdrop } from '@mui/material';
import { Box } from '@mui/system';
import {
  BeforeCapture,
  DragDropContext,
  DropResult,
  ResponderProvided,
} from '@react-forked/dnd';
import { useSnackbar } from 'notistack';
import { useCallback, useMemo, useRef, useState } from 'react';
import ConfirmDialog from '../../../base/components/ConfirmDialog/ConfirmDialog';
import { Flex } from '../../../base/components/Flex';
import { PdfReader } from '../../../base/components/PdfReader';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import { ClientTopBarHeight } from '../../../layouts/ClientLayout/ClientTopBar';
import { Loader } from '../Loader';
import { PlaylistData } from '../PlaylistBuilder/playlistHooks';
import PlaylistAdditionalParamsDialog from './PlaylistAdditionalParamsDialog';
import PlaylistAvailableScenes from './PlaylistAvailableScenes';
import PlaylistCategory from './PlaylistCategory';
import PlaylistFilter from './PlaylistFilter';
import PlaylistSelectedScenesDrawer, {
  BOTTOM_DRAWER_COLLAPSED_HEIGHT,
  BOTTOM_DRAWER_EXPANDED_HEIGHT,
} from './PlaylistSelectedScenesDrawer';
import {
  PlayListSceneParamsResolution,
  PlaylistSelectedScene,
} from './PlaylistTypes';

interface Props {
  playlistData: PlaylistData;
  actions: React.ReactNode;
}

const PlaylistBuilder = ({ playlistData, actions }: Props) => {
  const { _t } = useTranslationPrefix('PlaylistBuilder');
  const { enqueueSnackbar } = useSnackbar();
  const {
    loading,
    categories,
    filteredScenes,
    enabledScenes,
    params,
    selectedParams,
    enabledParams,
    selectedCategory,
    resolveSceneParams,
    setSelectedCategory,
    setFilterParam,
    selectedScenes,
    selectScene,
    removeScene,
    reorder,
  } = playlistData;
  const [selectedScenesExpanded, setSelectedScenesExpanded] = useState<
    'y' | 'n' | 'forced'
  >('n');
  const filterRef = useRef<HTMLDivElement>(null);
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [draggingNewScene, setDraggingNewScene] = useState(false);
  const [manualUrl, setManualUrl] = useState<string | null>(null);
  const [addDialogData, setAddDialogData] =
    useState<PlayListSceneParamsResolution | null>(null);
  const [removeConfirm, setRemoveConfirm] = useState<{
    sceneId: string;
    idx: number;
  } | null>(null);
  const selectedSceneIds = useMemo(
    () => selectedScenes.map((s) => s.id),
    [selectedScenes],
  );
  const handleSceneSelected = useCallback(
    (sceneId: string, idx?: number) => {
      const params = resolveSceneParams(sceneId);
      if (params.complete) {
        selectScene(
          { sceneId: params.sceneId, params: params.filledParams },
          idx,
        );
        enqueueSnackbar(_t('sceneAdded', 'Scene added'), {
          variant: 'success',
        });
      } else {
        setAddDialogData({ ...params, idx });
      }
    },
    [resolveSceneParams, selectScene, enqueueSnackbar, _t],
  );

  const handleAddDialogConfirm = (
    sceneData: PlaylistSelectedScene,
    idx?: number,
  ) => {
    selectScene(sceneData, idx);
    enqueueSnackbar(_t('sceneAdded', 'Scene added'), { variant: 'success' });

    setAddDialogData(null);
  };

  const handleRemoveClick = useCallback(
    (sceneId: string, idx: number) => {
      setRemoveConfirm({ sceneId, idx });
    },
    [setRemoveConfirm],
  );

  const handleRemoveConfirmClick = () => {
    if (removeConfirm) {
      removeScene(removeConfirm.sceneId, removeConfirm.idx);
      setRemoveConfirm(null);
    }
  };

  const handleBeforeDragStart = useCallback(
    (start: BeforeCapture) => {
      if (selectedScenesExpanded === 'n') {
        //must be without transition else it breaks drag and drop
        setSelectedScenesExpanded('forced');
      }
      if (start.draggableId.startsWith('available-scene-')) {
        setDraggingNewScene(true);
      }
      if (start.draggableId.startsWith('selected-scene-')) {
        setBackdropOpen(true);
      }
    },
    [selectedScenesExpanded],
  );

  const handleDragEnd = useCallback(
    (result: DropResult, provider: ResponderProvided) => {
      setBackdropOpen(false);
      setDraggingNewScene(false);
      if (
        result.source.droppableId === 'available-scenes' &&
        result.destination?.droppableId === 'selected-scenes'
      ) {
        const idx = result.destination.index;
        const scene = /^available-scene-([0-9]+)$/.exec(result.draggableId);
        if (!scene || scene.length < 2) {
          console.error(
            'Failed to extract scene id from draggable id',
            result.draggableId,
          );
          return;
        }
        handleSceneSelected(scene[1], idx);
        return;
      }
      if (
        result.source.droppableId === 'selected-scenes' &&
        result.destination?.droppableId === 'selected-scenes'
      ) {
        if (result.source.index !== result.destination.index) {
          reorder(result.source.index, result.destination.index);
        }
        return;
      }
      if (
        result.source.droppableId === 'selected-scenes' &&
        !result.destination
      ) {
        const idx = result.source.index;
        const scene = /^selected-scene-([0-9]+)-([0-9]+)$/.exec(
          result.draggableId,
        );
        if (!scene || scene.length < 2) {
          console.error(
            'Failed to extract scene id from draggable id',
            result.draggableId,
          );
          return;
        }
        handleRemoveClick(scene[1], idx);
        return;
      }
    },
    [handleSceneSelected, reorder, handleRemoveClick],
  );

  return loading ? (
    <Loader />
  ) : (
    <Flex
      flexDirection={{ xs: 'column', md: 'row' }}
      flexGrow={1}
      flexWrap={{ md: 'wrap' }}
      gap={{ xs: 4, md: 2 }}
    >
      <Flex flexDirection="column" flexGrow={{ md: 1 }} width={{ md: '1px' }}>
        {/*Keep filter in view while scrolling*/}
        <Box
          ref={filterRef}
          display="flex"
          flexDirection="column"
          gap={4}
          position="sticky"
          top={
            filterRef.current?.scrollHeight
              ? `min(1rem, -${
                  filterRef.current?.scrollHeight
                }px + 100vh - 3.5rem - ${ClientTopBarHeight} - ${
                  selectedScenesExpanded === 'n'
                    ? BOTTOM_DRAWER_COLLAPSED_HEIGHT
                    : BOTTOM_DRAWER_EXPANDED_HEIGHT
                })`
              : 0
          }
          bottom={0}
        >
          <PlaylistCategory
            categories={categories}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
          />
          <PlaylistFilter
            enabledParams={enabledParams}
            params={params}
            selectedParams={selectedParams}
            setFilterParam={setFilterParam}
            enabledScenes={enabledScenes}
          />
        </Box>
      </Flex>
      <DragDropContext
        onBeforeCapture={handleBeforeDragStart}
        onDragEnd={handleDragEnd}
      >
        <PlaylistAvailableScenes
          filteredScenes={filteredScenes}
          enabledScenes={enabledScenes}
          onSceneSelected={handleSceneSelected}
          onInfoClick={setManualUrl}
          selectedSceneIds={selectedSceneIds}
        />
        <Backdrop open={backdropOpen}></Backdrop>
        <PlaylistSelectedScenesDrawer
          selectedScenes={selectedScenes}
          onRemoveClick={handleRemoveClick}
          expanded={selectedScenesExpanded}
          setExpanded={setSelectedScenesExpanded}
          draggingNewScene={draggingNewScene}
          actions={actions}
        />
      </DragDropContext>
      <PlaylistAdditionalParamsDialog
        data={addDialogData}
        onClose={() => setAddDialogData(null)}
        onConfirm={handleAddDialogConfirm}
      />
      <PdfReader
        title={_t('instructionsTitle', 'Instructions')}
        url={manualUrl}
        onClose={() => setManualUrl(null)}
      />
      <ConfirmDialog
        open={!!removeConfirm}
        onClose={() => setRemoveConfirm(null)}
        onConfirm={handleRemoveConfirmClick}
        title={_t('sceneRemovalConfirmTitle', 'Remove scene from playlist?')}
        confirmButtonColor="error"
        cancelButtonColor="error"
        confirmLabel={_t('remove', 'Remove')}
        disableRestoreFocus
      />
    </Flex>
  );
};

export default PlaylistBuilder;
