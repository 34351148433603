import { useEffect, useState } from 'react';
import { getVrBoxIdFromLocalStorage } from '../base/components/VrDevicesProvider/VrDevicesProvider';
import { getLoginVrBox } from './auth';
import { LoginVrBox } from './types';

export const useLoginVrBox = () => {
  const vrBoxId = getVrBoxIdFromLocalStorage();
  const [loading, setLoading] = useState(!!vrBoxId);
  const [vrBox, setLoginVrBox] = useState<LoginVrBox | null>(null);

  useEffect(() => {
    const loaded =
      !vrBoxId || (vrBox?.vrBoxId && String(vrBox.vrBoxId) === vrBoxId);
    setLoading(!loaded);

    if (loaded) {
      return;
    }

    getLoginVrBox(vrBoxId!)
      .then((vrBox) => {
        setLoginVrBox(vrBox);
      })
      .finally(() => setLoading(false));
  }, [vrBoxId, vrBox?.vrBoxId]);

  return { loading, vrBox };
};
