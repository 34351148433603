import {
  Palette,
  PaletteColor,
  SvgIcon,
  SvgIconProps,
  useTheme,
} from '@mui/material';

interface Props extends SvgIconProps {
  text?: string;
}

const LetterIcon = ({ text, color = 'primary', ...props }: Props) => {
  const { palette } = useTheme();
  const c: PaletteColor = palette[color as keyof Palette] as PaletteColor;
  return (
    <SvgIcon color={color} {...props}>
      <style></style>
      <circle cx="12" cy="12" r="12" fill={c.lighter || c.light} />
      <text
        x="12"
        y="12"
        dominantBaseline="central"
        textAnchor="middle"
        style={{
          fontSize: '11px',
          fontWeight: 'bold',
          color: c.main,
        }}
      >
        {text}
      </text>
    </SvgIcon>
  );
};

export default LetterIcon;
