import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { Box, BoxProps, Tooltip } from '@mui/material';
import React from 'react';
import { BaseTypography } from '../../../base/components/BaseTypography';
import { Flex } from '../../../base/components/Flex';
import { IconButtonFilled } from '../../../base/components/IconButton';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import { compareByPropPreference } from '../../../lib/compare';
import { SCENE_PARAM_ORDER_PREFERENCE } from '../../../lib/constants';
import { joinStrings } from '../../../lib/strings';
import SessionParamBubble from '../../pages/Sessions/components/SessionParamBubble';
import { Scene, SelectedParam } from './PlaylistTypes';

export const PLAYLIST_SCENE_WIDTH = '19.5rem';

interface Props {
  idx: number;
  scene: Scene;
  onRemoveClick?: (sceneId: string, idx: number) => void;
  cardRef?: React.Ref<HTMLDivElement>;
  sx?: BoxProps['sx'];
  disabled?: boolean;
}

const PlaylistSelectedScene = ({
  cardRef,
  idx,
  scene,
  onRemoveClick,
  sx = {},
  disabled,
  ...restProps
}: Props) => {
  const { _t } = useTranslationPrefix('PlaylistSelectedScene');

  const renderSceneParams = (params: SelectedParam[]) => {
    const maxValues = 3;

    const renderedParams = params.slice(
      0,
      params.length > maxValues ? maxValues - 1 : params.length,
    );
    return (
      <Flex mx={2} pb={2} gap={1} position="relative">
        {renderedParams.map((param) => (
          <SessionParamBubble key={param.paramName}>
            {param.valueName_t}
          </SessionParamBubble>
        ))}
        {params.length > maxValues && (
          <Tooltip
            title={joinStrings(', ', ...params.map((p) => p.valueName_t))}
            describeChild
            placement="top"
          >
            <span style={{ display: 'flex' }}>
              <SessionParamBubble>
                {_t('moreParams', '{{count}} more', {
                  count: params.length - (maxValues - 1),
                })}
              </SessionParamBubble>
            </span>
          </Tooltip>
        )}
      </Flex>
    );
  };

  return (
    <Box
      ref={cardRef}
      sx={{
        display: 'flex',
        position: 'relative',
        width: PLAYLIST_SCENE_WIDTH,
        height: '100%',
        flexShrink: 0,
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderRadius: '10px',
        backgroundColor: disabled ? 'grey.300' : 'primary.main',
        backgroundImage: scene.imageUrl
          ? `url("${scene.imageUrl}")`
          : undefined,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        filter: disabled ? 'grayscale(100%)' : undefined,
        ...sx,
      }}
      {...restProps}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          borderRadius: '6px',
          background:
            'linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%)',
        }}
      />

      <Flex
        px={2}
        mt={2}
        maxHeight="3.5rem"
        justifyContent="space-between"
        alignItems="center"
        position="relative"
      >
        <BaseTypography
          variant="h5"
          color={disabled ? 'grey.100' : 'white.main'}
          sx={{
            maxHeight: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {scene.name_t}
        </BaseTypography>
        {onRemoveClick && (
          <IconButtonFilled
            id={`remove-scene-${idx}`}
            onClick={() => onRemoveClick(scene.id, idx)}
            color="error"
          >
            <ClearRoundedIcon />
          </IconButtonFilled>
        )}
      </Flex>
      {renderSceneParams(
        scene.selectedParams?.sort(
          compareByPropPreference('paramName', SCENE_PARAM_ORDER_PREFERENCE),
        ) || [],
      )}
    </Box>
  );
};

export default React.memo(PlaylistSelectedScene);
