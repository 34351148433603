import { useContext } from 'react';
import SearchControlBar from '../../../admin/components/SearchControlBar/SearchControlBar';
import { BaseTableContext } from './BaseTableProvider';

interface Props<T extends {}> {
  filterBy: keyof T;
  title: string;
  placeholderTransKey: string;
  defaultPlaceholder?: string;
}

const TableSearchControlBar = <T extends {}>({
  filterBy,
  title,
  placeholderTransKey,
  defaultPlaceholder,
}: Props<T>) => {
  const { totalCount, setSearch, id } = useContext(BaseTableContext);
  const handleChange = (value: string) => setSearch(filterBy, value);

  return (
    <SearchControlBar
      id={`${id}-search-controll`}
      title={title}
      placeholderTransKey={placeholderTransKey}
      defaultPlaceholder={defaultPlaceholder}
      totalCount={totalCount}
      onChange={handleChange}
    />
  );
};

export default TableSearchControlBar;
