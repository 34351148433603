import { Popper, PopperProps } from '@mui/material';

const CompanySelectPopper = ({ style, ...props }: PopperProps) => {
  return (
    <Popper
      placement="bottom-start"
      modifiers={[
        {
          name: 'flip',
          enabled: true,
          options: {
            altBoundary: true,
            rootBoundary: 'document',
            padding: 8,
          },
        },
      ]}
      sx={{
        minWidth: style?.width,
        maxWidth: '40ch',
      }}
      {...props}
    />
  );
};

export default CompanySelectPopper;
