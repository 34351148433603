import { BoxProps, styled } from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslationPrefix } from '../../../../hooks/useTranslationPrefix';
import { formatDate } from '../../../../lib/date';
import { formatDistance } from '../../../../lib/distance';
import Chart, { ChartProps } from '../../../components/Charts/Chart';
import { UpdateAxisPointerEvent } from '../../../components/Charts/ChartTypes';
import { axisPointer } from '../utils/axisPointer';
import { prepareMonthAxis } from '../utils/monthAxis';

interface Props extends BoxProps {
  data: readonly RangeData[];
  onAxisPointerChange?: ChartProps<any>['onAxisPointerChange'];
}

interface RangeData {
  readonly key: string;
  readonly maxShoulderLeft: number;
  readonly maxShoulderRight: number;
}

const ShoulderRangeChart = ({
  data,
  onAxisPointerChange: onAxisPointerChangeOrig,
  ...boxProps
}: Props) => {
  const { _t } = useTranslationPrefix('UserShoulderRangeReportPage');

  const onAxisPointerChange = useCallback(
    //Its category type, so value is index, by actual value is needed
    (e: UpdateAxisPointerEvent) => {
      const dimension = e.axesInfo.find((i) => i.axisDim === 'x');
      if (typeof dimension?.value === 'number' && data[dimension.value]) {
        var item = data[dimension.value];
        dimension.value = item.key;
      }
      onAxisPointerChangeOrig?.(e);
    },
    [onAxisPointerChangeOrig, data],
  );

  return (
    <ShoulderRangeChartWrapper
      onAxisPointerChange={onAxisPointerChange}
      {...boxProps}
      options={{
        tooltip: {
          trigger: 'axis',
          triggerOn: 'click',
          valueFormatter: (val: number) => formatDistance(val),
        },
        legend: {},
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '70px',
          containLabel: true,
        },
        dataZoom: [
          {
            type: 'inside',
            start: Math.max(0, 100 - (20 / data.length) * 100),
            end: 100,
          },
          {
            type: 'slider',
            start: 0,
            end: 100,
            labelFormatter: (_: number, val: string) => formatDate(val),
          },
        ],
        dataset: {
          source: data,
        },

        xAxis: [
          {
            type: 'category',
            axisLabel: {
              formatter: (val: string) => {
                return formatDate(val);
              },
            },
            axisPointer: axisPointer({}),
          },
          prepareMonthAxis(data),
        ],
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: (val: number) => formatDistance(val),
          },
        },
        series: [
          {
            name: _t('leftShoulder', 'Levé rameno'),
            type: 'bar',
            barGap: '0%',
            barCategoryGap: '50%',
            dimensions: [
              { name: 'key', type: 'time' },
              { name: 'maxShoulderLeft', type: 'number' },
              { name: 'key', type: 'time' },
            ],
          },
          {
            name: _t('rightShoulder', 'Pravé rameno'),
            type: 'bar',
            barGap: '0%',
            barCategoryGap: '25%',
            dimensions: [
              { name: 'key', type: 'time' },
              { name: 'maxShoulderRight', type: 'number' },
            ],
          },
        ],
      }}
    />
  );
};

const ShoulderRangeChartWrapper = styled(Chart)`
  min-height: 330px;
  height: 33vh;
`;

export default React.memo(ShoulderRangeChart);
