import React, { Suspense, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import NotFoundDetail from '../../admin/components/NotFoundDetail/NotFoundDetail';
import AuthCheck from '../../base/components/UserProvider/AuthCheck';
import { useUser } from '../../base/components/UserProvider/useUserHook';
import { VrDevicesContext } from '../../base/components/VrDevicesProvider/VrDevicesProvider';
import { UserPermission, UserRole } from '../../graphql/graphql-operations';
import { ClientLayout } from '../../layouts';
import AppStartedPage from './AppStarted/AppStartedPage';
import DevicesRoute from './Devices/DevicesRoute';
import HelpFaqRoute from './HelpFaq/HelpFaqRoute';
import NotFoundPage from './NotFoundPage/NotFoundPage';
import PlaylistRoute from './Playlist/PlaylistRoute';
import PlaylistTemplatesRoute from './PlaylistTemplates/PlaylistTemplatesRoute';
import ReportsRoute from './Reports/ReportsRoute';
import SalesRoute from './Sales/SalesRoute';
import SessionsRoute from './Sessions/SessionsRoute';
import { SuperAdminHomepage } from './SuperAdminHomePage';
import TrainingRoute from './Trainings/TrainingsRoute';
import UsersRoute from './Users/UsersRoute';

export const roleHomepage: Record<UserRole, React.ReactElement | string> = {
  [UserRole.SUPER_ADMIN]: <SuperAdminHomepage />,
  [UserRole.MEDICAL_ADMIN]: '/users',
  [UserRole.MEDICAL_LECTURER]: '/users',
  [UserRole.MEDICAL_LECTURER_ASSISTANT]: '/users',
  [UserRole.MEDICAL_PATIENT]: '/trainings/user',
  [UserRole.TRAINING_ADMIN]: '/users',
  [UserRole.TRAINING_LECTURER]: '/users',
  [UserRole.TRAINING_LECTURER_ASSISTANT]: '/users',
  [UserRole.TRAINING_STUDENT]: '/trainings/user',
  [UserRole.TRAINING_STUDENT_BASIC]: '/trainings/user',
  [UserRole.SALES_ADMIN]: '/users',
  [UserRole.SALES_SALESPERSON]: '/sales',
  [UserRole.SCHOOL_ADMIN]: '/users',
  [UserRole.SCHOOL_TEACHER]: '/playlist/create-session',
  [UserRole.NONE]: <NotFoundPage />,
  [UserRole.API_ACCESS]: <NotFoundDetail />,
  [UserRole.CI_CD]: <NotFoundDetail />,
};

const ClientContent = () => {
  const { t } = useTranslation();
  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  const { user, hasPermission } = useUser();
  const { setVrBoxId } = useContext(VrDevicesContext);

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const boxId = queryParams.get('boxId');
    if (boxId) {
      setVrBoxId(boxId);
    }
  }, [search, setVrBoxId]);

  const homepage = roleHomepage[user!.role];

  const isRoot = pathname === '/';
  if (isRoot && typeof homepage === 'string') {
    navigate(homepage);
  }

  return hasPermission(UserPermission.LOGIN_CLIENT) ? (
    <ClientLayout>
      <Suspense fallback={<div />}>
        <Routes>
          {typeof homepage !== 'string' && (
            <Route index element={roleHomepage[user!.role]} />
          )}
          <Route
            element={<AuthCheck permission={UserPermission.DEVICES_ROUTE} />}
          >
            <Route path="/devices/*" element={<DevicesRoute />} />
          </Route>

          <Route
            element={
              <AuthCheck permission={UserPermission.MANAGE_MY_SESSIONS} />
            }
          >
            <Route path="/sessions/*" element={<SessionsRoute />} />
          </Route>

          <Route path="/users/*" element={<UsersRoute />} />

          <Route path="/trainings/*" element={<TrainingRoute />} />

          <Route
            element={
              <AuthCheck
                permission={[
                  UserPermission.PLAYLIST_ROUTE,
                  UserPermission.LAUNCH_ANNONYMOUS_PLAYLIST,
                ]}
              />
            }
          >
            <Route path="/playlist/*" element={<PlaylistRoute />} />
          </Route>

          <Route
            element={
              <AuthCheck
                permission={
                  (UserPermission.MANAGE_MY_PLAYLIST_TEMPLATES,
                  UserPermission.READ_MY_PLAYLIST_TEMPLATES)
                }
              />
            }
          >
            <Route
              path="/playlist-templates/*"
              element={<PlaylistTemplatesRoute />}
            />
            <Route
              path="/group-sessions/*"
              element={<PlaylistTemplatesRoute />}
            />
          </Route>

          <Route
            element={<AuthCheck permission={UserPermission.SALES_ROUTE} />}
          >
            <Route path="/sales/*" element={<SalesRoute />} />
          </Route>

          <Route
            element={<AuthCheck permission={UserPermission.VIEW_REPORTS} />}
          >
            <Route path="/reports/*" element={<ReportsRoute />} />
          </Route>

          <Route
            element={<AuthCheck permission={UserPermission.DEVICES_ROUTE} />}
          >
            <Route path="/app-started/:deviceId" element={<AppStartedPage />} />
          </Route>
          <Route path="/help/*" element={<HelpFaqRoute />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Suspense>
    </ClientLayout>
  ) : (
    <div>{t('common.unauthorized', 'Unauthorized')}</div>
  );
};

export default ClientContent;
