import { useQuery } from '@apollo/client';
import {
  Box,
  Card,
  CardContent,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import statokinesiogramBack from '../../../assets/reports/statokinesiogram-back.png';
import statokinesiogramSide from '../../../assets/reports/statokinesiogram-side.png';
import statokinesiogramTop from '../../../assets/reports/statokinesiogram-top.png';

import { Flex } from '../../../base/components/Flex';
import {
  GetHeadMovementIntervalsDocument,
  GetHeadMovementSeriesDocument,
} from '../../../graphql/graphql-operations';
import useQueryErrorSnackbar from '../../../hooks/useQueryErrorSnackbar';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import { ensureDate, formatDate, getEndOfDay } from '../../../lib/date';
import ContentWrapper from '../../components/ContentWrapper';
import { Loader } from '../../components/Loader';
import PageHeader from '../../components/PageHeader';
import { UserReportPage } from './UserReportsRoute';
import HeadStatokinesiogramChart, {
  HeadStatokinesiogramChartView,
} from './components/HeadStatokinesiogramChart';
import ReportNav from './components/ReportNav';
import { useReportUser } from './utils/useReportUser';

const now = new Date();

const HeadStatokinesiogramPage = () => {
  const { _t } = useTranslationPrefix('HeadStatokinesiogramPage');
  const { userId, userData, userLoading, backUrl } = useReportUser();

  const [selectedDay, setSelectedDay] = useState<string>();
  const [view, setView] = useState<HeadStatokinesiogramChartView>('top');

  const {
    data: intervalData,
    refetch: intervalRefetch,
    error: intervalError,
    loading: intervalLoading,
  } = useQuery(GetHeadMovementIntervalsDocument, {
    variables: {
      userId: userId!,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    skip: !userId,
  });

  useQueryErrorSnackbar(intervalError, intervalRefetch);

  useEffect(() => {
    if (
      !selectedDay &&
      intervalData?.userReports.headMovementIntervals.length
    ) {
      const date =
        intervalData?.userReports.headMovementIntervals[
          intervalData?.userReports.headMovementIntervals.length - 1
        ];
      setSelectedDay(date);
    }
  }, [intervalData, selectedDay]);

  const {
    data: seriesData,
    refetch: seriesRefetch,
    error: seriesError,
  } = useQuery(GetHeadMovementSeriesDocument, {
    variables: {
      userId: userId!,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      fromDate: ensureDate(selectedDay || now).toISOString(),
      toDate: getEndOfDay(selectedDay || now).toISOString(),
    },
    skip: !userId && !selectedDay,
    fetchPolicy: 'cache-and-network',
  });
  useQueryErrorSnackbar(seriesError, seriesRefetch);

  const loading = userLoading || intervalLoading;
  return (
    <ContentWrapper>
      <PageHeader
        title={_t('title', 'Report {{firstName}} {{lastName}}', {
          firstName: userData?.user.firstName,
          lastName: userData?.user.lastName,
        })}
        back={backUrl}
      ></PageHeader>
      <ReportNav currentPage={UserReportPage.HEAD_STATOKINEZIOGRAM} />
      {loading ? (
        <Loader />
      ) : (
        <>
          <Flex flexDirection="column" gap={2}>
            <Card>
              <CardContent>
                <Flex
                  marginX={2}
                  gap={2}
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  flexWrap="wrap"
                >
                  <Flex alignItems="center">
                    <img
                      height={100}
                      width={100}
                      alt="range"
                      src={
                        view === 'top'
                          ? statokinesiogramTop
                          : view === 'side'
                          ? statokinesiogramSide
                          : statokinesiogramBack
                      }
                    />
                    <Box flexDirection="column" alignItems="flex-start" ml={2}>
                      <Typography variant="h6" component="h2">
                        {_t(
                          'HeadStatokinesiogram',
                          'Pohyb hlavy pacienta v prostoru',
                        )}
                      </Typography>
                    </Box>
                  </Flex>
                  <Flex gap={2}>
                    <ToggleButtonGroup
                      color="primary"
                      value={view}
                      exclusive
                      onChange={(_, val) => val && setView(val)}
                    >
                      <ToggleButton value="top">
                        {_t('top', 'Seshora')}
                      </ToggleButton>
                      <ToggleButton value="side">
                        {_t('side', 'Z boku')}
                      </ToggleButton>
                      <ToggleButton value="back">
                        {_t('back', 'Zezadu')}
                      </ToggleButton>
                    </ToggleButtonGroup>
                    <Select
                      value={selectedDay || ''}
                      onChange={(e) => setSelectedDay(e.target.value)}
                      sx={{ minWidth: '12ch' }}
                    >
                      {intervalData?.userReports.headMovementIntervals.map(
                        (date) => (
                          <MenuItem key={date} value={date}>
                            {formatDate(date)}
                          </MenuItem>
                        ),
                      )}
                    </Select>
                  </Flex>
                </Flex>
                <Box
                  sx={{
                    overflowX: 'auto',
                    scrollbarWidth: 'thin',
                  }}
                >
                  <HeadStatokinesiogramChart
                    data={seriesData?.userReports.headMovementSeries || []}
                    view={view}
                  />
                </Box>
              </CardContent>
            </Card>
          </Flex>
        </>
      )}
    </ContentWrapper>
  );
};

export default HeadStatokinesiogramPage;
