import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { Flex } from '../../../base/components/Flex';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import { TopBar } from '../../components/TopBar';

const DebugPage = () => {
  const { _t } = useTranslationPrefix('DebugPage');
  return (
    <>
      <TopBar leftSideText={_t('title', 'Debug')} />

      <Flex>
        <Button
          size="large"
          id="debug-roles"
          component={Link}
          sx={{ mx: 1 }}
          to="/admin/debug/roles"
        >
          {_t('roles', 'Roles X Persmissions')}
        </Button>
        <Button
          size="large"
          id="debug-reports"
          component={Link}
          sx={{ mx: 1 }}
          to="/admin/debug/reports"
        >
          {_t('reports', 'Reports')}
        </Button>
        <Button
          size="large"
          id="training-assignments"
          component={Link}
          sx={{ mx: 1 }}
          to="/admin/debug/assignments"
        >
          {_t('assignment', 'Assignments')}
        </Button>
      </Flex>
    </>
  );
};

export default DebugPage;
