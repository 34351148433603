import { Box, Button } from '@mui/material';
import { CopyBlock, dracula } from 'react-code-blocks';
import { BaseDialog } from '../../../../base/components/BaseDialog';
import { BaseTypography } from '../../../../base/components/BaseTypography';
import { Flex } from '../../../../base/components/Flex';
import { useTranslationPrefix } from '../../../../hooks/useTranslationPrefix';

interface Props {
  value: string | null;
  onClose: () => void;
}

const ApiKeyViewDialog = ({ value, onClose }: Props) => {
  const { _t } = useTranslationPrefix('ApiKeyViewDialog');

  return (
    <BaseDialog open={!!value} onClose={onClose} title={_t('title', 'API key')}>
      <BaseTypography textAlign="center">
        {_t('copyInstructions1', 'Please take a note of the API key.')}
      </BaseTypography>
      <BaseTypography textAlign="center">
        {_t(
          'copyInstructions2',
          'After this dialog is closed, you will not be able to view it again.',
        )}
      </BaseTypography>
      <Flex flexDirection="column" gap={2} pt={3}>
        <Box>
          <BaseTypography variant="h6">
            {_t('rawKey', 'API key')}
          </BaseTypography>
          <CopyBlock
            text={value || ''}
            language="text"
            showLineNumbers={false}
            theme={dracula}
            className="asdasda"
            codeBlock
          />
        </Box>
        <Box>
          <BaseTypography variant="h6">
            {_t('keyAsHeader', 'API key as header')}
          </BaseTypography>
          <CopyBlock
            text={`Authorization: Bearer ${value}`}
            language="text"
            showLineNumbers={false}
            theme={dracula}
            className="asdasda"
            codeBlock
          />
        </Box>
        <Flex justifyContent="center" pt={2}>
          <Button
            id="close-api-key-view"
            onClick={onClose}
            size="large"
            variant="contained"
          >
            {_t('close', 'Close')}
          </Button>
        </Flex>
      </Flex>
    </BaseDialog>
  );
};

export default ApiKeyViewDialog;
