import { useMutation } from '@apollo/client';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {
  CircularProgress,
  LinearProgress,
  styled,
  TextField,
} from '@mui/material';
import { captureException } from '@sentry/react';
import { useField, useFormikContext } from 'formik';
import { ExecutionResult } from 'graphql';
import { useSnackbar } from 'notistack';
import { ChangeEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  UploadApkDocument,
  UploadApkMutation,
} from '../../../graphql/graphql-operations';
import { ApplicationEditFormData } from './ApplicationEditDialog';

interface Props {
  name: string;
  disabled?: boolean;
}

const ApplicationsApkUploader = ({ name, disabled }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [progress, setProgress] = useState<{
    finished: number;
    outOf: number;
  } | null>(null);
  const { values, setFieldValue } = useFormikContext<ApplicationEditFormData>();
  const [{ value }, { touched, error }, { setValue, setTouched }] =
    useField(name);
  const inputRef = useRef<HTMLInputElement>();
  const [uploadApk] = useMutation(UploadApkDocument);

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      setValue('');
      let fileId: string | null = null;
      const file = e.target.files[0];
      const fileSize = file.size;
      const chunkSize = 1024 * 1024 * 8; //8MB Chunk size
      let chunkIndex = 0;
      let consecutiveErrors = 0;
      const chunkCount = Math.ceil(fileSize / chunkSize);
      let lastData: UploadApkMutation | null = null;
      setProgress({ finished: 0, outOf: fileSize });

      while (chunkIndex < chunkCount) {
        const offset = chunkIndex * chunkSize;
        const currentFilePart = file.slice(
          offset,
          Math.min(offset + chunkSize, file.size),
        );

        try {
          const { data: uploadData } = (await uploadApk({
            variables: {
              file: currentFilePart,
              input: {
                fileId,
                chunkCount,
                chunkIndex,
                chunkSize,
                fileSize,
              },
            },
          })) as ExecutionResult<UploadApkMutation>;

          if (uploadData?.uploadApk) {
            const { fileId: newFileId, confirmedChunk } = uploadData.uploadApk;
            fileId = newFileId;
            chunkIndex = confirmedChunk + 1;
            consecutiveErrors = 0;
            lastData = uploadData;
            setProgress({
              finished: Math.min(confirmedChunk * chunkSize, fileSize),
              outOf: fileSize,
            });
          }
        } catch (err) {
          console.error(err);
          consecutiveErrors++;
          if (consecutiveErrors >= 3) {
            enqueueSnackbar(
              t('applications.dialog.uploadError', 'Failed to upload apk file'),
              { variant: 'error' },
            );
            captureException(err);
            setProgress(null);
            return;
          }
        }
      }
      //upload completed
      setProgress(null);

      if (!lastData) {
        return;
      }
      if (!touched) {
        setTouched(true);
      }
      setValue(lastData.uploadApk.fileId);
      setFieldValue('version', lastData.uploadApk.version);
      if (
        !values.name &&
        lastData.uploadApk.name &&
        !lastData.uploadApk.name.startsWith('resourceId')
      ) {
        setFieldValue('name', lastData.uploadApk.name);
      }
    }
  };

  const renderAdorment = () => {
    if (progress) {
      return <CircularProgress size="1rem" />;
    }

    if (value) {
      return (
        <CheckCircleIcon
          className={classes.uploadInputIcon}
          onClick={(e) => inputRef.current?.click()}
        />
      );
    }

    return (
      <CloudUploadIcon
        className={classes.uploadInputIcon}
        onClick={(e) => inputRef.current?.click()}
      />
    );
  };

  return (
    <Wrapper>
      <TextField
        type="file"
        inputRef={inputRef}
        inputProps={{
          className: classes.uploadInput,
          accept: 'application/vnd.android.package-archive',
        }}
        InputProps={{
          endAdornment: renderAdorment(),
        }}
        fullWidth
        onChange={handleChange}
        disabled={!!progress || disabled}
        error={!!error}
        helperText={error}
      />
      {progress && (
        <LinearProgress
          variant="determinate"
          value={Math.round((progress.finished / progress.outOf) * 100)}
        />
      )}
    </Wrapper>
  );
};

const classes = {
  uploadInput: 'ApplicationsApkUploader-input',
  uploadInputIcon: 'ApplicationsApkUploader-inputIcon',
};

const Wrapper = styled('div')(
  ({ theme: { palette } }) => `
  & .${classes.uploadInput}::file-selector-button {
    display: none;
  }

  & .${classes.uploadInputIcon} {
    cursor: pointer;
    color: ${palette.primary.main};
  }
`,
);

export default ApplicationsApkUploader;
