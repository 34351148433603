import { useQuery } from '@apollo/client';
import { TableCell } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'react-use';
import { TableCellLink } from '../../../base/components/BaseTable';
import {
  ColumnConfig,
  SimpleTable,
} from '../../../base/components/SimpleTable';
import {
  ApplicationSceneGroupOrderField,
  ApplicationSceneGroupStatus,
  GetApplicationSceneGroupsDocument,
  GetApplicationSceneGroupsQueryVariables,
  OrderDirection,
} from '../../../graphql/graphql-operations';
import useQueryErrorSnackbar from '../../../hooks/useQueryErrorSnackbar';
import { joinStrings } from '../../../lib/strings';

interface Props {
  status?: ApplicationSceneGroupStatus;
}

interface Scene {
  id: string;
  name_t: string;
}

interface TableData {
  id: string;
  name: string;
  scenes: Scene[];
  enabled: boolean;
}

const ApplicationGroupsTable = ({ status }: Props) => {
  const { t } = useTranslation();
  const [rowsPerPage] = useSessionStorage('rowsPerPage', 15);

  const { data, loading, error, refetch, variables } = useQuery(
    GetApplicationSceneGroupsDocument,
    {
      variables: getDefaultVariables(rowsPerPage, status),
    },
  );

  const tableData: TableData[] =
    data?.applicationSceneGroups.nodes?.map((group) => ({
      id: group.id,
      name: group.name,
      scenes: group.currentScenes ? [...group.currentScenes] : [],
      enabled: group.enabled,
    })) || [];

  const columns: ColumnConfig<TableData>[] = [
    {
      id: 'name',
      defaultTrans: 'Name',
      component: (row: TableData) => {
        return (
          <TableCellLink
            to={`/admin/applications/groups/${row.id}`}
            label={row.name}
          />
        );
      },
    },
    {
      id: 'scenes',
      defaultTrans: 'Scenes',
      component: (row: TableData) => {
        return (
          <TableCell>
            {joinStrings(
              ' | ',
              row.scenes.length,
              ...row.scenes.map((scene) => scene.name_t),
            )}
          </TableCell>
        );
      },
    },
    {
      id: 'enabled' as const,
      defaultTrans: 'Status',
      getLabel: (enabled: boolean) =>
        enabled
          ? t('applicationGroups.table.enabledValue', 'Enabled')
          : t('applicationGroups.table.disabled', 'Disabled'),
    },
  ];

  useQueryErrorSnackbar(error, refetch);
  return (
    <SimpleTable<TableData, GetApplicationSceneGroupsQueryVariables>
      id="application-groups"
      rowId="id"
      translationPrefix="applicationGroups.table"
      data={tableData}
      columns={columns}
      isLoading={loading}
      searchConfig={{
        defaultPlaceholder: 'Search {{count}} programs',
        defaultTitle: 'Program list',
        filterBy: 'name',
      }}
      lazyConfig={{
        refetch: refetch,
        variables: variables,
        pageCursors: data?.applicationSceneGroups.pageCursors,
        totalCount: data?.applicationSceneGroups.totalCount,
      }}
    />
  );
};

export default ApplicationGroupsTable;

const getDefaultVariables = (
  rowsPerPage: number,
  status?: ApplicationSceneGroupStatus,
): GetApplicationSceneGroupsQueryVariables => {
  const defaultVariables = {
    first: rowsPerPage,
    orderBy: {
      direction: OrderDirection.ASC,
      field: ApplicationSceneGroupOrderField.NAME,
    },
    filter: status && {
      status: status,
    },
  };
  return defaultVariables;
};
