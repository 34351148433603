import { useQuery } from '@apollo/client';
import { Box, BoxProps, Button, Chip } from '@mui/material';
import clsx from 'clsx';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { Flex } from '../../../../base/components/Flex';
import {
  GetUserGroupsDocument,
  UserGroupFragmentFragment,
  UserRole,
} from '../../../../graphql/graphql-operations';
import useQueryErrorSnackbar from '../../../../hooks/useQueryErrorSnackbar';
import { useTranslationPrefix } from '../../../../hooks/useTranslationPrefix';
import { onlyUnique } from '../../../../lib/arrayFilters';

interface Props {
  companyId: string;
  selectedGroupIds: string[];
  setSelectedGroupIds: Dispatch<SetStateAction<string[]>>;
  selectableRoles: UserRole[];
  selectedRoles: UserRole[];
  setSelectedRoles: Dispatch<SetStateAction<UserRole[]>>;
  sx?: BoxProps['sx'];
}

const UserGroupsFilter = ({
  companyId,
  selectedGroupIds,
  setSelectedGroupIds,
  selectableRoles,
  selectedRoles,
  setSelectedRoles,
  sx,
}: Props) => {
  const { _t, t } = useTranslationPrefix('UserGroupsFilter');
  const { data, error, refetch } = useQuery(GetUserGroupsDocument, {
    variables: {
      input: {
        companyId: companyId,
      },
    },
    fetchPolicy: 'cache-and-network',
  });
  useQueryErrorSnackbar(error, refetch);
  const groups = data?.userGroups;

  useEffect(() => setSelectedGroupIds([]), [setSelectedGroupIds, data]);

  useEffect(() => {
    const anyInvalid = selectedRoles.find((r) => !selectableRoles.includes(r));
    if (anyInvalid) {
      setSelectedRoles((roles) =>
        roles.filter((r) => selectableRoles.includes(r)),
      );
    }
  }, [setSelectedRoles, selectableRoles, selectedRoles]);

  const handleClearAll = () => {
    setSelectedRoles([]);
    setSelectedGroupIds([]);
  };

  const renderRole = (role: UserRole) => {
    const selected = selectedRoles?.includes(role);

    const handleSelectClick = () => {
      setSelectedRoles((s) => [...s, role].filter(onlyUnique));
    };
    const handleUnselectClick = () => {
      setSelectedRoles((s) => [...s].filter((g) => g !== role));
    };

    return (
      <Chip
        key={role}
        label={t(`enum.UserRole.${role}`)}
        variant={selected ? 'filled' : 'outlined'}
        sx={{ m: 0.5 }}
        onClick={selected ? handleUnselectClick : handleSelectClick}
        onDelete={selected ? handleUnselectClick : undefined}
        color="offwhite"
        className={clsx({ 'Mui-selected': selected })}
      />
    );
  };

  const renderGroup = (group: UserGroupFragmentFragment) => {
    const selected = selectedGroupIds?.includes(group.id);

    const handleSelectClick = () => {
      setSelectedGroupIds((s) => [...s, group.id].filter(onlyUnique));
    };
    const handleUnselectClick = () => {
      setSelectedGroupIds((s) => [...s].filter((g) => g !== group.id));
    };

    return (
      <Chip
        key={group.id}
        label={group.name}
        variant={selected ? 'filled' : 'outlined'}
        sx={{ m: 0.5 }}
        onClick={selected ? handleUnselectClick : handleSelectClick}
        onDelete={selected ? handleUnselectClick : undefined}
        color="offwhite"
        className={clsx({ 'Mui-selected': selected })}
      />
    );
  };

  return groups?.length || selectableRoles?.length ? (
    <Box sx={sx} flexGrow={1} flexShrink={1}>
      <Flex flexWrap="wrap" sx={{ m: -0.5 }}>
        {selectableRoles?.map(renderRole)}
        {groups?.map(renderGroup)}
        <Button
          id="clear-all-user-groups"
          onClick={handleClearAll}
          variant="text"
          color="primary"
          sx={{ ml: 1 }}
        >
          {_t('clear', 'Clear')}
        </Button>
      </Flex>
    </Box>
  ) : (
    <div />
  );
};
export default UserGroupsFilter;
