import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';
import { getLoginCompanies } from './auth';
import { LoginCompany } from './types';

export const useLoginCompanies = () => {
  const [loginCompanies, setLoginCompanies] = useState<{
    loading: boolean;
    loginCompanies: LoginCompany[] | null;
  }>({ loading: true, loginCompanies: null });
  useEffect(() => {
    getLoginCompanies()
      .catch((networkError) => {
        Sentry.captureException(networkError);
        setLoginCompanies({ loading: false, loginCompanies: null });
      })
      .then((data) =>
        setLoginCompanies({ loading: false, loginCompanies: data || [] }),
      );
  }, []);
  return loginCompanies;
};
