import { useMutation, useQuery } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import ArchiveIcon from '@mui/icons-material/Archive';
import { Button } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import { Field, Formik, FormikHelpers } from 'formik';
import { TextField } from 'formik-mui';
import { useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { BaseDialog } from '../../../base/components/BaseDialog';
import ConfirmDialog from '../../../base/components/ConfirmDialog/ConfirmDialog';
import { Flex } from '../../../base/components/Flex';
import FormikCheckBox from '../../../base/components/form/FormikCheckBox';
import FormikForm from '../../../base/components/form/FormikForm';
import {
  ApplicationSceneGroupStatus,
  CreateApplicationSceneGroupDocument,
  EditApplicationSceneGroupDocument,
  GetApplicationSceneGroupQuery,
  GetApplicationSceneGroupsDocument,
  GetApplicationSceneGroupsMapSelectionDocument,
} from '../../../graphql/graphql-operations';
import useMutationSnackbar from '../../../hooks/useMutationSnackbar';
import FormikSilhouetteLocationSelect from '../../components/SilhouetteLocationSelect/FormikSilhouetteLocationSelect';

interface Props {
  open: boolean;
  applicationGroup?: GetApplicationSceneGroupQuery['applicationSceneGroup'];
  onClose: () => void;
}

interface FormData {
  name: string;
  enabled: boolean;
  mapSelectionEnabled: boolean;
  mapSelectionId: number | null;
}

const ApplicationGroupEditDialog = ({
  open,
  applicationGroup,
  onClose,
}: Props) => {
  const [archiveConfirmOpen, setArchiveConfirmOpen] = useState(false);
  const { t } = useTranslation();
  const { spacing } = useTheme();

  const { data, refetch: refetchUsedMapPointIds } = useQuery(
    GetApplicationSceneGroupsMapSelectionDocument,
    {
      fetchPolicy: 'cache-and-network',
    },
  );
  const usedMapPointIds =
    data?.applicationSceneGroupsWithMapSelection
      .map((used) => used.mapSelectionId)
      .filter((id) => !!id) || [];

  const isPointAvailable = (pointId: number) => {
    if (applicationGroup?.mapSelectionId === pointId) {
      return true;
    }
    return !usedMapPointIds.includes(pointId);
  };

  const handleEditComplete = () => {
    refetchUsedMapPointIds();
    onClose();
  };

  const createApplicationGroupHandler = useMutationSnackbar(
    'createApplicationSceneGroup',
    {
      translationKey: 'applicationGroups.dialog.createAction',
      onSuccessfullyCompleted: handleEditComplete,
    },
  );

  const operationName = getOperationName(GetApplicationSceneGroupsDocument);
  const [createMutation] = useMutation(CreateApplicationSceneGroupDocument, {
    ...createApplicationGroupHandler,
    refetchQueries: operationName ? [operationName] : undefined,
  });

  const editApplicationGroupHandler = useMutationSnackbar(
    'editApplicationSceneGroup',
    {
      translationKey: 'applicationGroups.dialog.editAction',
      onSuccessfullyCompleted: handleEditComplete,
    },
  );

  const [editMutation] = useMutation(EditApplicationSceneGroupDocument, {
    ...editApplicationGroupHandler,
  });

  const suspendHandler = useMutationSnackbar('editApplicationSceneGroup', {
    translationKey: 'applicationGroups.dialog.suspend',
    onSuccessfullyCompleted: () => {
      setArchiveConfirmOpen(false);
      onClose();
    },
  });

  const [suspendMutation] = useMutation(EditApplicationSceneGroupDocument, {
    ...suspendHandler,
  });

  const handleArchiveClick = () => {
    if (applicationGroup) {
      suspendMutation({
        variables: {
          editApplicationSceneGroupInput: {
            applicationSceneGroupId: applicationGroup.id,
            status: ApplicationSceneGroupStatus.SUSPENDED,
          },
        },
      });
    }
  };

  const handleSubmit = async (
    { mapSelectionEnabled, mapSelectionId, ...rest }: FormData,
    helpers: FormikHelpers<FormData>,
  ) => {
    const values = {
      ...rest,
      mapSelectionEnabled: mapSelectionEnabled,
      mapSelectionId: mapSelectionEnabled ? mapSelectionId : null,
    };
    if (applicationGroup) {
      await editMutation({
        variables: {
          editApplicationSceneGroupInput: {
            applicationSceneGroupId: applicationGroup.id,
            ...values,
          },
        },
      });
    } else {
      await createMutation({
        variables: { createApplicationSceneGroup: { ...values } },
      });
    }
  };

  return (
    <>
      <BaseDialog
        open={open}
        title={
          applicationGroup
            ? t('applicationGroups.dialog.edit.title', 'Edit program')
            : t('applicationGroups.dialog.create.title', 'New program')
        }
        onClose={onClose}
      >
        <Formik<FormData>
          initialValues={{
            mapSelectionId: applicationGroup?.mapSelectionId || null,
            mapSelectionEnabled: !!applicationGroup?.mapSelectionEnabled,
            enabled:
              applicationGroup?.enabled === undefined
                ? true
                : !!applicationGroup.enabled,
            name: applicationGroup?.name || '',
          }}
          onSubmit={handleSubmit}
          validationSchema={getValidationSchema(t)}
        >
          {({
            isSubmitting,
            setFieldValue,
            values: { mapSelectionEnabled },
          }) => (
            <FormikForm width="auto">
              <Flex flexDirection="row">
                <Flex
                  flexDirection="column"
                  width={spacing(40)}
                  paddingTop={spacing(4)}
                  paddingRight={spacing(4)}
                >
                  <Field
                    fullWidth
                    component={TextField}
                    name="name"
                    variant="outlined"
                    label={t(
                      'applicationGroups.dialog.nameLabel',
                      'Program name',
                    )}
                  />
                  <FormikCheckBox
                    name="mapSelectionEnabled"
                    label={t(
                      'applicationGroups.dialog.mapSelectionEnabled',
                      'Enable map selection',
                    )}
                  />
                  <FormikCheckBox
                    name="enabled"
                    label={t(
                      'applicationGroups.dialog.enabledLabel',
                      'Enabled',
                    )}
                  />
                  {applicationGroup && (
                    <Flex justifyContent="center" paddingTop={spacing(4)}>
                      <Button
                        id="application-group-edit-dialog-archive"
                        variant="text"
                        size="large"
                        color="error"
                        onClick={() => setArchiveConfirmOpen(true)}
                        startIcon={<ArchiveIcon />}
                      >
                        {t('applicationGroups.dialog.archive', 'Archive')}
                      </Button>
                    </Flex>
                  )}
                  <Box flexGrow={1}></Box>
                  <Flex justifyContent="space-between">
                    <Button
                      id="application-group-edit-dialog-cancel"
                      size="large"
                      color="primary"
                      onClick={onClose}
                    >
                      {t('common.cancel', 'Cancel')}
                    </Button>
                    <Button
                      id="application-group-edit-dialog-confirm"
                      type="submit"
                      size="large"
                      color="primary"
                      variant="contained"
                      disabled={isSubmitting}
                    >
                      {applicationGroup
                        ? t('applicationGroups.dialog.edit.save', 'Save')
                        : t('applicationGroups.dialog.create.save', 'Create')}
                    </Button>
                  </Flex>
                </Flex>
                <div>
                  <FormikSilhouetteLocationSelect
                    name="mapSelectionId"
                    disabled={!mapSelectionEnabled}
                    pointsFilter={isPointAvailable}
                  ></FormikSilhouetteLocationSelect>
                </div>
              </Flex>
            </FormikForm>
          )}
        </Formik>
      </BaseDialog>
      <ConfirmDialog
        open={archiveConfirmOpen}
        onClose={() => setArchiveConfirmOpen(false)}
        onConfirm={handleArchiveClick}
        title={t(
          'applicationGroups.dialog.archiveConfirm',
          'Do you want to disable this program?',
        )}
      />
    </>
  );
};

const getValidationSchema = (t: TFunction) => {
  return Yup.object({
    name: Yup.string()
      .min(
        4,
        t('applications.dialog.nameMin', 'Minimal number of characters is 4'),
      )
      .max(
        255,
        t('applications.dialog.nameMax', 'Maximum number of characters is 255'),
      )
      .required(t('applications.dialog.nameRequired', 'App name is required')),
    mapSelectionId: Yup.number()
      .nullable(true)
      .when('mapSelectionEnabled', {
        is: true,
        then: Yup.number()
          .typeError(
            t(
              'applications.dialog.mapSelectionIdRequired',
              'Map location is required',
            ),
          )
          .required(
            t(
              'applications.dialog.mapSelectionIdRequired',
              'Map location is required',
            ),
          ),
      }),
  });
};

export default ApplicationGroupEditDialog;
