import { styled } from '@mui/system';

interface Props {
  className?: string;
  label?: string;
}

const VerticalSeparator = ({ className, label }: Props) => {
  return (
    <VerticalSeparatorWrapper className={className}>
      {label && (
        <VerticalSeparatorLabelWrapper>
          <VerticalSeparatorLabel>{label}</VerticalSeparatorLabel>
        </VerticalSeparatorLabelWrapper>
      )}
    </VerticalSeparatorWrapper>
  );
};

const VerticalSeparatorWrapper = styled('div')(
  ({ theme: { spacing } }) => `
    position: relative;
    width: 0px;
    border: 1px solid #F2F2F2;
    margin: 0 ${spacing(2)};
`,
);

const VerticalSeparatorLabelWrapper = styled('span')(
  ({ theme: { spacing } }) => `
    position: absolute;
    top: ${spacing(2)};
`,
);

const VerticalSeparatorLabel = styled('span')(
  ({ theme: { palette, spacing } }) => `
    position: absolute;
    background-color: ${palette.background.paper};
    transform: translateX(-50%);
    line-height: 1em;
    padding: ${spacing(1)};
    font-weight: bold;
    text-transform: uppercase;
`,
);

export default VerticalSeparator;
