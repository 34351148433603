import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { BaseTypography } from '../../../base/components/BaseTypography';
import { Flex } from '../../../base/components/Flex';
import { IconButtonOutlined } from '../../../base/components/IconButton';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import { PLAYLIST_SCENE_WIDTH } from './PlaylistSelectedScene';

interface Props {
  draggingNewScene: boolean;
}

const PlaylistSelectedScenePlaceholder = ({ draggingNewScene }: Props) => {
  const { _t } = useTranslationPrefix('PlaylistSelectedScenePlaceholder');

  return (
    <Flex
      sx={{
        width: PLAYLIST_SCENE_WIDTH,
        height: '100%',
        border: '1px dashed',
        borderColor: draggingNewScene ? 'success.dark' : 'grey.300',
        color: draggingNewScene ? 'success.dark' : undefined,
        backgroundColor: draggingNewScene ? 'success.lighter' : undefined,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '10px',
        flexShrink: 0,
      }}
    >
      {draggingNewScene ? (
        <>
          <IconButtonOutlined
            id="add-scene-placeholder"
            sx={{ color: 'success.dark' }}
          >
            <AddRoundedIcon />
          </IconButtonOutlined>
          <BaseTypography variant="h5">
            {_t('addScene', 'Add scene')}
          </BaseTypography>
        </>
      ) : (
        <>
          <BaseTypography variant="h5">
            {_t('title', 'Add scenes here')}
          </BaseTypography>
          <BaseTypography variant="body3" color="grey.500">
            {_t('instructuion', 'by clicking + or dragging scene here')}
          </BaseTypography>
        </>
      )}
    </Flex>
  );
};

export default PlaylistSelectedScenePlaceholder;
