import { PureQueryOptions, useMutation } from '@apollo/client';
import { useState } from '@hookstate/core';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import { useMemo } from 'react';
import ConfirmDialog from '../../../base/components/ConfirmDialog/ConfirmDialog';
import {
  DeleteVrSessionDocument,
  GetUserDetailDocument,
  GetVrSessionDocument,
} from '../../../graphql/graphql-operations';
import useMutationSnackbar from '../../../hooks/useMutationSnackbar';
import { TableData } from './UserSessionsTable';

interface Props {
  userId: string;
  vrSessionId?: string;
  rowData: TableData;
}

const UserSessionsTableRowActions: React.VFC<Props> = ({
  vrSessionId,
  userId,
  rowData,
}: Props) => {
  const state = useState({ confirmDeleteDialogOpen: false });

  const toggleDialogActionCreator = (open: boolean) => () => {
    state.confirmDeleteDialogOpen.set(open);
  };

  const deleteSessionHandler = useMutationSnackbar('deleteSession', {
    translationKey: 'vrSessionDetail.sessionsTable.actions.delete',
    onSuccessfullyCompleted: toggleDialogActionCreator(false),
  });

  const refetchQuerties = useMemo(() => {
    const q: PureQueryOptions[] = [
      {
        query: GetUserDetailDocument,
        variables: { userInput: { userId } },
      },
    ];
    if (vrSessionId) {
      q.push({
        query: GetVrSessionDocument,
        variables: {
          sessionInput: { sessionId: vrSessionId },
        },
      });
    }
    return q;
  }, [userId, vrSessionId]);

  const [deleteSessionMutation] = useMutation(DeleteVrSessionDocument, {
    ...deleteSessionHandler,
    refetchQueries: [...refetchQuerties],
  });

  const handleDelete = () => {
    deleteSessionMutation({
      variables: { deleteSessionInput: { sessionId: rowData.id } },
    });
  };
  return (
    <>
      <IconButton
        id={`user-session-row-action-remove-${rowData.id}`}
        onClick={toggleDialogActionCreator(true)}
        size="large"
      >
        <DeleteIcon color="error" />
      </IconButton>
      <ConfirmDialog
        open={state.confirmDeleteDialogOpen.get()}
        onClose={toggleDialogActionCreator(false)}
        onConfirm={handleDelete}
      />
    </>
  );
};

export default UserSessionsTableRowActions;
