import { capitalize, SvgIconProps } from '@mui/material';
import { useMemo } from 'react';
import LetterIcon from './LetterIcon';

interface Props {
  firstName?: string | null;
  lastName?: string | null;
  sx?: SvgIconProps['sx'];
  color?: SvgIconProps['color'];
}

const NamedAvatar = ({ firstName, lastName, color, sx }: Props) => {
  const sxStyle = { height: 'auto', ...(sx || {}) };

  const text = useMemo(() => {
    const first = firstName?.trim();
    const last = lastName?.trim();

    if (first && last) {
      return (first[0] + last[0]).toUpperCase();
    } else if (first) {
      return capitalize(first.substring(0, Math.min(first.length, 2)));
    } else if (last) {
      return capitalize(last.substring(0, Math.min(last.length, 2)));
    }
    return '?';
  }, [firstName, lastName]);

  return <LetterIcon text={text} color={color} sx={sxStyle} />;
};

export default NamedAvatar;
