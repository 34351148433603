import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import { Button, Card, CardContent } from '@mui/material';
import { compareDesc } from 'date-fns';
import { prop, sortBy } from 'ramda';
import { Link } from 'react-router-dom';
import { BaseTypography } from '../../../../base/components/BaseTypography';
import { Flex } from '../../../../base/components/Flex';
import {
  PlaylistItemFragmentFragment,
  UserSessionPlaylistFragmentFragment,
} from '../../../../graphql/graphql-operations';
import { useTranslationPrefix } from '../../../../hooks/useTranslationPrefix';
import { formatDate } from '../../../../lib/date';
import SessionSceneCard from '../../Sessions/components/SessionSceneCard';
import { ScrollableList } from './ScrollableList';

type PlaylistItem = PlaylistItemFragmentFragment;

interface Props {
  id: string;
  to?: string;
  duration?: number | null;
  playlist: PlaylistItem[];
}

const getPlaylistStart = (
  playlist: UserSessionPlaylistFragmentFragment['playlist'],
) => {
  const startDates = playlist
    .map((item) => (item.startedAt ? new Date(item.startedAt) : null))
    .filter((date) => date)
    .sort((date, otherDate) => {
      if (date === null) {
        return -1;
      }
      if (otherDate === null) {
        return 1;
      }
      return compareDesc(date, otherDate);
    });
  const playlistStart = startDates[0];

  return playlistStart ? formatDate(playlistStart) : null;
};

export const PlaylistCard = ({ id, playlist, duration, to }: Props) => {
  const minutes = duration ? Math.round(duration / 60) : 0;
  const { _t } = useTranslationPrefix('playlistCard');
  return (
    <Card id={id}>
      <CardContent>
        <Flex alignItems="center" gap={2}>
          <Flex
            flexDirection="column"
            flexShrink={0}
            alignItems="center"
            gap={2}
          >
            {typeof duration === 'number' && (
              <BaseTypography variant="h5">
                {_t('durationMin', `{{minutes}} min`, {
                  minutes,
                })}
              </BaseTypography>
            )}
            <Button
              id={`relaunch-session-${id}`}
              to={to || '#'}
              component={Link}
              disabled={!to}
              color="primary"
              startIcon={<PlayArrowOutlinedIcon />}
              sx={{ minWidth: '18ch' }}
            >
              {getPlaylistStart(playlist)}
            </Button>
          </Flex>
          <ScrollableList id={`${id}-playlist`}>
            <Flex gap={2} height="7.5rem">
              {sortBy(prop('ordinal'), playlist).map((playlistItem) => (
                <SessionSceneCard
                  key={playlistItem.id}
                  item={playlistItem}
                  disabled={false}
                />
              ))}
            </Flex>
          </ScrollableList>
        </Flex>
      </CardContent>
    </Card>
  );
};
