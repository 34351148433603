import { useTranslation } from 'react-i18next';

const radarOptions = {
  scales: {
    r: {
      beginAtZero: true,
      ticks: {
        maxTicksLimit: 6,
        min: 0,
        max: 100,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};

const randomize = (seed: number, period: number) => (seed * period) % 100;

const getRadarData = (period: number, labels: string[], color: string) => {
  return {
    labels,
    datasets: [
      {
        data: [
          randomize(23, period),
          randomize(67, period),
          randomize(33, period),
          randomize(35, period),
        ],
        fill: false,
        backgroundColor: color,
        borderColor: color,
        borderWidth: 2,
      },
    ],
  };
};

// create mocks for patient data
export const usePatientData = (period: number, color: string) => {
  const { t } = useTranslation();
  const labels = [
    t('vrSessionDetail.summary.radar.topAngle', 'Top Angle'),
    t('vrSessionDetail.summary.radar.rightAngle', 'Right Angle'),
    t('vrSessionDetail.summary.radar.bottomAngle', 'Bottom Angle'),
    t('vrSessionDetail.summary.radar.leftAngle', 'Left Angle'),
  ];
  return {
    radarOptions,
    radarData: getRadarData(period, labels, color),
    mobilityData: (period * 3.7).toFixed(),
  };
};
