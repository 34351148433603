import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { CardContent, CardProps } from '@mui/material';
import React, { MouseEvent } from 'react';
import { Flex } from '../../../base/components/Flex';
import { IconButtonOutlined } from '../../../base/components/IconButton';
import InfoIcon from '../../../base/components/Icons/InfoIcon';
import { SelectableCard } from '../../../base/components/SelectableCard';
import PlaylistSelectedScene from './PlaylistSelectedScene';
import { Scene } from './PlaylistTypes';

interface Props {
  id?: string;
  scene: Scene;
  isDragging?: boolean;
  onSceneAddClick: (sceneId: string) => void;
  onInfoClick: (manualUrl: string) => void;
  disabled?: boolean;
  idx: number;
  selected?: boolean;
  cardRef?:
    | ((instance: HTMLDivElement | null) => void)
    | React.RefObject<HTMLDivElement>
    | null
    | undefined;
  sx?: CardProps['sx'];
}

const PlaylistSceneCard = ({
  id,
  scene,
  onSceneAddClick,
  disabled,
  onInfoClick,
  idx,
  sx = {},
  selected,
  ...restCardProps
}: Props) => {
  const handleAddClick = (e: MouseEvent) => {
    if (disabled) return;
    e.stopPropagation();
    e.preventDefault();
    onSceneAddClick(scene.id);
  };
  const handleInfoClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    onInfoClick(scene.manualUrl!);
  };

  return (
    <SelectableCard {...restCardProps} disabled={disabled} selected={selected}>
      <CardContent onClick={handleAddClick}>
        <Flex justifyContent="space-between" alignItems="center" gap={2}>
          <Flex
            alignItems="center"
            width="100%"
            overflow="hidden"
            height="6rem"
            m={-1}
          >
            <PlaylistSelectedScene
              idx={0}
              scene={scene}
              disabled={disabled}
              sx={{ flexShrink: 1, maxWidth: 1 }}
            />
          </Flex>
          <Flex alignItems="center" gap={2}>
            {scene.manualUrl && (
              <IconButtonOutlined
                id={`info-scene-${scene.id}`}
                onClick={handleInfoClick}
                color={disabled ? 'bgContrast' : 'primary'}
              >
                <InfoIcon />
              </IconButtonOutlined>
            )}
            <IconButtonOutlined
              id={`add-scene-${scene.id}`}
              onClick={handleAddClick}
              disabled={disabled}
              color="primary"
            >
              <AddRoundedIcon />
            </IconButtonOutlined>
          </Flex>
        </Flex>
      </CardContent>
    </SelectableCard>
  );
};

export default React.memo(PlaylistSceneCard);
