import { Route, Routes } from 'react-router-dom';
import NotFoundDetail from '../../components/NotFoundDetail/NotFoundDetail';
import VrSessionDetailPage from './VrSessionDetailPage';

const VrSessionRoute = () => {
  return (
    <Routes>
      <Route path="/:vrSessionId" element={<VrSessionDetailPage />} />
      <Route path="*" element={<NotFoundDetail />} />
    </Routes>
  );
};

export default VrSessionRoute;
