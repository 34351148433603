import { EXPIRING_LOCAL_DATA_KEY_PREFIX } from './localStorageKeys';

interface StorageRecord<T> {
  value: T;
  expireAt: number;
}

const clearExpired = () => {
  const now = Date.now();
  Object.keys(localStorage).forEach((key) => {
    if (!key.startsWith(EXPIRING_LOCAL_DATA_KEY_PREFIX)) {
      return;
    }
    const valueString = localStorage.getItem(key);
    if (!valueString) {
      return null;
    }
    const item: StorageRecord<any> = JSON.parse(valueString);
    if (item.expireAt < now) {
      localStorage.removeItem(key);
    }
  });
};

export const setToLocalStorageWithExpiration = <T>(
  key: string,
  value: T,
  ttlMs: number,
) => {
  clearExpired();
  const expireAt = Date.now() + ttlMs;
  const item: StorageRecord<T> = {
    value: value,
    expireAt: expireAt,
  };
  localStorage.setItem(
    EXPIRING_LOCAL_DATA_KEY_PREFIX + key,
    JSON.stringify(item),
  );
};

export const getFromLocalStorageWithExpiration = <T>(key: string): T | null => {
  clearExpired();

  const now = Date.now();
  const storageKey = EXPIRING_LOCAL_DATA_KEY_PREFIX + key;
  const valueString = localStorage.getItem(storageKey);

  if (!valueString) {
    return null;
  }

  const item: StorageRecord<T> = JSON.parse(valueString);
  if (item.expireAt < now) {
    localStorage.removeItem(storageKey);
    return null;
  }
  return item.value;
};
