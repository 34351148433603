import { TableCell, TableRow } from '@mui/material';
import { BaseTypography } from '../BaseTypography';
import { TableRowProps } from './types';

interface Props<T> extends TableRowProps<T> {
  withoutActions: boolean;
  actionsWidth?: string;
}
const BaseTableBodyRow = <T extends {}>({
  withoutActions,
  actionsWidth,
  rowActions,
  rowContent: closeContent,
}: Props<T>) => {
  return (
    <TableRow>
      {closeContent.map(({ component: Component, ...cell }) => {
        if (Component) {
          return <Component key={cell.id as string} {...cell.data} />;
        }
        return (
          <TableCell key={cell.id as string}>
            <BaseTypography variant={'body1'}>{cell.label}</BaseTypography>
          </TableCell>
        );
      })}
      {!withoutActions && (
        <TableCell
          style={{ width: actionsWidth, textAlign: 'right', padding: '0px' }}
        >
          {rowActions}
        </TableCell>
      )}
    </TableRow>
  );
};

export default BaseTableBodyRow;
