import { Button, MenuItem, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { LoginCompany } from '../../../auth';
import { Flex } from '../../../base/components/Flex';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';

interface Props {
  companyId?: string | null;
  companies: LoginCompany[];
  onContinue: (companyId: string | null) => void;
}

const ADMIN_SELECT_OPTION = 'ADMIN';

const ClientLoginSelectCompany = ({
  companyId,
  companies,
  onContinue,
}: Props) => {
  const [valueState, setValueState] = useState(
    companyId === null ? ADMIN_SELECT_OPTION : companyId,
  );
  const [error, setError] = useState<string>();
  useEffect(() => {
    setValueState(companyId === null ? ADMIN_SELECT_OPTION : companyId);
  }, [companyId]);

  const { _t } = useTranslationPrefix('ClientLoginSelectCompany');

  const handleContinue = () => {
    if (!valueState) {
      setError(_t('companyRequired', 'Select company'));
      return;
    }
    onContinue(valueState === ADMIN_SELECT_OPTION ? null : valueState);
  };

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      width="100%"
      maxWidth="300px"
      pt={{ xs: 2, sm: 9 }}
      paddingBottom={3}
    >
      <TextField
        id="companyId"
        value={valueState}
        onChange={(e) => {
          setValueState(e.target.value);
          setError(undefined);
        }}
        fullWidth
        label={_t('company', 'Company')}
        select
        onKeyDown={(e) => e.key === 'Enter' && handleContinue()}
        error={!!error}
        helperText={error}
      >
        {companies.map((company) => (
          <MenuItem key={company.id} value={company.id}>
            {company.name}
          </MenuItem>
        ))}
        <MenuItem value={ADMIN_SELECT_OPTION}>
          {_t('administration', 'Administration')}
        </MenuItem>
      </TextField>
      <Button
        id="next"
        variant="contained"
        sx={{ mb: 2, mt: 3 }}
        onClick={handleContinue}
      >
        {_t('continue', 'Continue')}
      </Button>
    </Flex>
  );
};

export default ClientLoginSelectCompany;
