import { Route, Routes } from 'react-router-dom';
import NotFoundPage from '../NotFoundPage/NotFoundPage';
import PlaylistCreateSessionPage from './PlaylistCreateSessionPage';

const PlaylistRoute = () => {
  return (
    <Routes>
      <Route path="/create-session" element={<PlaylistCreateSessionPage />} />
      <Route path="/*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default PlaylistRoute;
