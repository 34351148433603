import { useQuery } from '@apollo/client';
import {
  Box,
  Card,
  CardContent,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { Flex } from '../../../base/components/Flex';
import {
  GetHeadRotationDataDocument,
  SeriesAggregationInterval,
} from '../../../graphql/graphql-operations';
import useQueryErrorSnackbar from '../../../hooks/useQueryErrorSnackbar';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import ContentWrapper from '../../components/ContentWrapper';
import { Loader } from '../../components/Loader';
import PageHeader from '../../components/PageHeader';
import { UserReportPage } from './UserReportsRoute';
import HeadRotationChart, {
  HeadRotationChartView,
} from './components/HeadRotationChart';
import ReportNav from './components/ReportNav';

import headForwardTilt from '../../../assets/reports/head-forward-tilt.png';
import headLateralTilt from '../../../assets/reports/head-lateral-tilt.png';
import headRotationLeft from '../../../assets/reports/head-rotation-left.png';
import headRotationRight from '../../../assets/reports/head-rotation-right.png';
import headRotation from '../../../assets/reports/head-rotation.png';
import headTiltBack from '../../../assets/reports/head-tilt-back.png';
import headTiltForward from '../../../assets/reports/head-tilt-forward.png';
import headTiltLeft from '../../../assets/reports/head-tilt-left.png';
import headTiltRight from '../../../assets/reports/head-tilt-right.png';
import { LabeledValue } from '../../components/LabeledValue';
import { useReportUser } from './utils/useReportUser';

const HeadRotationReportPage = () => {
  const [view, setView] = useState<HeadRotationChartView>('forwardTilt');
  const { _t } = useTranslationPrefix('HeadRotationReportPage');
  const { userId, userData, userLoading, backUrl } = useReportUser();

  const {
    data: seriesData,
    loading: seriesLoading,
    error: seriesError,
    refetch: seriesRefetch,
  } = useQuery(GetHeadRotationDataDocument, {
    variables: {
      userId: userId!,
      seriesInterval: SeriesAggregationInterval.day,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    skip: !userId,
  });
  useQueryErrorSnackbar(seriesError, seriesRefetch);

  const loading = userLoading || seriesLoading;

  const renderValue = (range: 'min' | 'max') => {
    const dimenstion =
      view === 'forwardTilt' ? 'X' : view === 'lateralTilt' ? 'Z' : 'Y';
    const val = range + dimenstion;

    const imageSrc =
      val === 'minX'
        ? headTiltForward
        : val === 'maxX'
        ? headTiltBack
        : val === 'minZ'
        ? headTiltLeft
        : val === 'maxZ'
        ? headTiltRight
        : val === 'minY'
        ? headRotationLeft
        : val === 'maxY'
        ? headRotationRight
        : null;

    return (
      <Card sx={{ flexGrow: 1 }}>
        <CardContent>
          <Flex alignItems="center">
            <img height={80} width={80} alt="range" src={imageSrc} />
            <LabeledValue
              label={_t(`reachedMax.${dimenstion}`, `Dosažené maximum`)}
              value={
                Math.abs(
                  Math.round(
                    seriesData!.userReports.headRotationRanges[
                      val as 'minX' | 'maxX' | 'minY' | 'maxY' | 'minZ' | 'maxZ'
                    ],
                  ),
                ) + '°'
              }
            />
          </Flex>
        </CardContent>
      </Card>
    );
  };
  return (
    <ContentWrapper>
      <PageHeader
        title={_t('title', 'Report {{firstName}} {{lastName}}', {
          firstName: userData?.user.firstName,
          lastName: userData?.user.lastName,
        })}
        back={backUrl}
      ></PageHeader>
      <ReportNav currentPage={UserReportPage.CERVICAL_SPINE} />
      {loading ? (
        <Loader />
      ) : (
        <>
          <Flex flexDirection="column" gap={2}>
            <Flex gap={2}>
              {view === 'forwardTilt' ? (
                <>
                  {renderValue('max')}
                  {renderValue('min')}
                </>
              ) : (
                <>
                  {renderValue('min')}
                  {renderValue('max')}
                </>
              )}
            </Flex>

            <Card>
              <CardContent>
                <Flex
                  marginX={2}
                  gap={2}
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  flexWrap="wrap"
                >
                  <Flex alignItems="center">
                    <img
                      height={100}
                      width={100}
                      alt="range"
                      src={
                        view === 'forwardTilt'
                          ? headForwardTilt
                          : view === 'lateralTilt'
                          ? headLateralTilt
                          : headRotation
                      }
                    />
                    <Box flexDirection="column" alignItems="flex-start" ml={2}>
                      <Typography variant="h6" component="h2">
                        {_t('headRotation', 'Rozsah pohybu krční páteře')}
                      </Typography>
                    </Box>
                  </Flex>
                  <Flex gap={2}>
                    <ToggleButtonGroup
                      color="primary"
                      value={view}
                      exclusive
                      onChange={(_, val) => val && setView(val)}
                    >
                      <ToggleButton value="forwardTilt">
                        {_t('forwardTilt', 'Flexe')}
                      </ToggleButton>
                      <ToggleButton value="lateralTilt">
                        {_t('lateralTilt', 'Lalerálnní flexe')}
                      </ToggleButton>
                      <ToggleButton value="back">
                        {_t('rotation', 'Rotace')}
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Flex>
                </Flex>

                <HeadRotationChart
                  data={seriesData!.userReports.headRotationSeries}
                  view={view}
                />
              </CardContent>
            </Card>
          </Flex>
        </>
      )}
    </ContentWrapper>
  );
};

export default HeadRotationReportPage;
