import {
  OrderDirection,
  UserOrderField,
} from '../../../../graphql/graphql-operations';
import { TFunctionPrefixed } from '../../../../hooks/useTranslationPrefix';

export const prepareUserSortOptions = (_t: TFunctionPrefixed) => {
  return [
    {
      field: UserOrderField.LAST_NAME,
      direction: OrderDirection.ASC,
      label: _t('orderBy.az', 'A-Z'),
    },
    {
      field: UserOrderField.LAST_NAME,
      direction: OrderDirection.DESC,
      label: _t('orderBy.za', 'Z-A'),
    },
    {
      field: UserOrderField.LAST_SESSION,
      direction: OrderDirection.DESC,
      label: _t('orderBy.lastVisit', 'Last visit'),
    },
    {
      field: UserOrderField.LAST_SESSION,
      direction: OrderDirection.ASC,
      label: _t('orderBy.inactivity', 'Inactivity'),
    },
  ];
};
