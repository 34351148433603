import AddRoundedIcon from '@mui/icons-material/AddRounded';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';
import { Box, Button, CardContent, CardProps } from '@mui/material';
import { Link } from 'react-router-dom';
import { BaseTypography } from '../../../../base/components/BaseTypography';
import { Flex } from '../../../../base/components/Flex';
import { IconButtonOutlined } from '../../../../base/components/IconButton';
import { OutlinedCard } from '../../../../base/components/OutlinedCard';
import HasPermission from '../../../../base/components/UserProvider/HasPermission';
import {
  StreamType,
  UserDetailFragmentFragment,
  UserPermission,
} from '../../../../graphql/graphql-operations';
import { useTranslationPrefix } from '../../../../hooks/useTranslationPrefix';
import { joinStrings } from '../../../../lib/strings';
import { NamedAvatar } from '../../../components/NamedAvatar';
import { useAvailableRoles } from '../hooks';

interface Props {
  user?: UserDetailFragmentFragment | null;
  sx?: CardProps['sx'];
  trainingVariant: boolean;
}

const UserDetailHeaderCard = ({ user, sx, trainingVariant }: Props) => {
  const { _t } = useTranslationPrefix('UserDetailHeaderCard');
  const { availableRoles } = useAvailableRoles();
  const canEdit = user?.role && availableRoles?.includes(user?.role);

  return (
    <OutlinedCard sx={sx}>
      <CardContent>
        <Flex
          alignItems="center"
          overflow="hidden"
          flexDirection={{ xs: 'column', lg: 'row' }}
          gap={2}
          p={2}
        >
          <Box overflow="hidden" maxWidth="100%">
            <Flex
              alignItems="center"
              overflow="hidden"
              flexDirection={{ xs: 'column', sm: 'row' }}
              gap={2}
            >
              <NamedAvatar
                firstName={user?.firstName}
                lastName={user?.lastName}
                sx={{
                  width: '6rem',
                }}
              />
              <Box mb={1}>
                <BaseTypography variant="h3" color="text.secondary">
                  {joinStrings(' ', user?.firstName, user?.lastName)}
                </BaseTypography>
                {user?.externalId && (
                  <BaseTypography color="grey.500">
                    {joinStrings(' ', _t('externalId', 'ID'), user?.externalId)}
                  </BaseTypography>
                )}
                {user?.groups.length ? (
                  <Flex overflow="hidden" alignItems="center">
                    <PersonOutlineRoundedIcon
                      sx={{ mr: 0.5, color: 'grey.500' }}
                    />
                    <BaseTypography
                      variant="body3"
                      whiteSpace="nowrap"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      color="grey.500"
                      title={joinStrings(
                        ', ',
                        ...user.groups.map((g) => g.name),
                      )}
                    >
                      {joinStrings(', ', ...user.groups.map((g) => g.name))}
                    </BaseTypography>
                  </Flex>
                ) : null}
              </Box>
            </Flex>
          </Box>
          <Flex
            ml="auto"
            mr={{ xs: 'auto', lg: 0 }}
            flexDirection={{ xs: 'column', md: 'row' }}
            flexShrink={0}
            alignItems="center"
            flexWrap="wrap"
            gap={1}
          >
            {canEdit && (
              <IconButtonOutlined
                id="edit-user"
                component={Link}
                to={`./edit`}
                color="bgContrast"
                title={_t('edit', 'Edit')}
              >
                <ModeEditOutlineOutlinedIcon />
              </IconButtonOutlined>
            )}
            <HasPermission
              val={UserPermission.VIEW_MEDICAL_REPORTS}
              streamType={StreamType.MEDICAL}
            >
              <Button
                id="medical-report"
                variant="outlined"
                component={Link}
                to={`./medical-reports`}
                color="bgContrast"
                startIcon={<WorkspacePremiumOutlinedIcon />}
              >
                {_t('userReport', 'User report')}
              </Button>
            </HasPermission>
            <HasPermission
              val={UserPermission.VIEW_TRAINING_REPORTS}
              streamType={StreamType.TRAINING}
            >
              <Button
                id="training-report"
                variant="outlined"
                component={Link}
                to={`./training-reports`}
                color="bgContrast"
                startIcon={<WorkspacePremiumOutlinedIcon />}
              >
                {_t('trainingReport', 'Training results')}
              </Button>
            </HasPermission>
            <HasPermission val={UserPermission.MANAGE_TRAINING_ASSIGNMENTS}>
              <Button
                id="add-training"
                variant={trainingVariant ? 'contained' : 'outlined'}
                component={Link}
                to={`./assign-training`}
                color={trainingVariant ? 'primary' : 'bgContrast'}
                startIcon={<AddRoundedIcon />}
              >
                {_t('addTraining', 'Add training')}
              </Button>
            </HasPermission>
            {!trainingVariant && (
              <HasPermission val={UserPermission.PLAYLIST_ROUTE}>
                <Button
                  id="run-rehab"
                  variant="contained"
                  component={Link}
                  to={{
                    pathname: '/playlist/create-session',
                    search: `?userId=${user?.id}`,
                  }}
                  color="primary"
                  startIcon={<PlayArrowOutlinedIcon />}
                >
                  {_t('runRehabilitation', 'Run rehabilitation')}
                </Button>
              </HasPermission>
            )}
          </Flex>
        </Flex>
      </CardContent>
    </OutlinedCard>
  );
};

export default UserDetailHeaderCard;
