import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import SearchIcon from '@mui/icons-material/Search';
import { InputProps, TextField, TextFieldProps } from '@mui/material';
import { styled } from '@mui/system';
import { ChangeEvent, useRef } from 'react';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';

type Props = TextFieldProps & {
  onValueChange?: (value: string) => void;
};

const SearchField = ({
  onValueChange,
  onChange,
  InputProps,
  value,
  ...textFieldProps
}: Props) => {
  const inputRef = useRef<HTMLInputElement>();
  const { _t } = useTranslationPrefix('SearchField');

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e);
    }
    if (onValueChange) {
      onValueChange(e.target.value);
    }
  };

  const handleClear = () => {
    inputRef.current?.focus();
    onValueChange?.('');
  };

  const handleClick = () => {
    inputRef.current?.focus();
  };

  const SearchInputProps: Partial<InputProps> = {
    inputRef: inputRef,
    startAdornment: (
      <SearchIcon
        color="primary"
        sx={{ fontSize: '1.5rem', my: 2, mx: 0.25 }}
      />
    ),
    endAdornment: (
      <ClearRoundedIcon
        sx={{
          visibility: value ? 'visible' : 'hidden',
          cursor: 'pointer',
        }}
        className={classes.clear}
        onClick={handleClear}
      />
    ),
    classes: {
      input: classes.input,
    },
    ...InputProps,
  };

  return (
    <SearchFieldStyled
      placeholder={_t('placeholder', 'Search')}
      onChange={handleChange}
      value={value}
      color="bgContrast"
      onClick={handleClick}
      {...textFieldProps}
      InputProps={SearchInputProps}
    />
  );
};

const classes = {
  clear: 'SearchFieldStyled-clear',
  input: 'SearchFieldStyled-input',
};

const SearchFieldStyled = styled(TextField)(
  ({ theme: { palette, spacing }, value }) => `
    max-width: 255px;
    
    
    & .MuiOutlinedInput-root {
      border-radius: 200px;
    }
    & .MuiOutlinedInput-notchedOutline {
        border-radius: 200px;
    }
    &:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
       
    }
    
    & input {
      padding-top: 13.5px;
      padding-bottom: 13.5px;
    }

    & .${classes.input},
    & .${classes.clear} {
      max-width: 11rem;
      transition:max-width .5s,opacity .5s;
    }

    & .${classes.clear} {
      max-width: 1rem;
    }

    ${
      value
        ? ''
        : `
      &:not(:hover):not(:focus-within) .${classes.input},
      &:not(:hover):not(:focus-within) .${classes.clear} {
        max-width: 0;
        max-height: 0;
      }
      
    
    `
    }
`,
);

export default SearchField;
