import { useQuery } from '@apollo/client';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, styled, Typography } from '@mui/material';
import { ResponsiveStyleValue, SxProps, Theme } from '@mui/system';
import { useMemo } from 'react';
import { adminState } from '../../../../adminState';
import { Flex } from '../../../../base/components/Flex';
import {
  FolderFragment,
  GetPlaylistTemplatesFoldersDocument,
  GetPlaylistTemplatesFoldersQueryVariables,
} from '../../../../graphql/graphql-operations';
import useQueryErrorSnackbar from '../../../../hooks/useQueryErrorSnackbar';
import { useTranslationPrefix } from '../../../../hooks/useTranslationPrefix';
import { SearchListVariables } from '../../../components/ListView';
import { ScrollableList } from '../../Users/components/ScrollableList';
import PlaylistTemplateFolderCard from './PlaylistTemplateFolderCard';

interface Props {
  onClick?: (val: FolderFragment) => void;
  searchConfig?: SearchListVariables<FolderFragment>;
  columns?: ResponsiveStyleValue<number>;
  foldersPerPage?: number;
  sx?: SxProps<Theme>;
  selectedFolder?: FolderFragment | null;
}

const PlaylistTemplateFoldersList = ({
  searchConfig,
  foldersPerPage = 45,
  columns = { xs: 1, sm: 2, md: 3, lg: 4 },
  onClick,
  sx,
  selectedFolder,
}: Props) => {
  const { _t } = useTranslationPrefix('PlayListTemplatesList');
  const companyId = adminState.selectedCompanyId.get();
  const vars: GetPlaylistTemplatesFoldersQueryVariables = useMemo(
    () => ({
      filter: {
        companyId: companyId,
      },
      first: foldersPerPage,
    }),
    [companyId, foldersPerPage],
  );

  const { data, refetch, error } = useQuery(
    GetPlaylistTemplatesFoldersDocument,
    {
      variables: vars,
      fetchPolicy: 'cache-and-network',
    },
  );
  useQueryErrorSnackbar(error, refetch);

  const folders: FolderFragment[] = data?.playlistTemplatesFolders?.nodes
    ? [...data.playlistTemplatesFolders.nodes]
    : [];

  const renderValue = (folder: FolderFragment) => {
    const isSelected = selectedFolder?.id === folder.id;

    return (
      <PlaylistTemplateFolderCard
        folder={folder}
        key={`folder-${folder.id}`}
        onClick={() => onClick?.(folder)}
        sx={{
          width: 250,
          maxHeight: 150,
          color: isSelected ? ' primary.main' : 'text.secondary',
          backgroundColor: isSelected ? 'primary.lighter' : undefined,
        }}
      >
        <EllipsisText variant="h5">{folder.name}</EllipsisText>
        <Flex alignItems="center" gap={0.5} mt={1}>
          <InfoOutlinedIcon />
          <Typography variant="caption" color="text.secondary">
            {_t('playlistCount', '{{count}} playlists', {
              count: folder.playlistTemplatesCount || 0,
            })}
          </Typography>
        </Flex>
      </PlaylistTemplateFolderCard>
    );
  };

  const filteredFolders = folders.filter((folder) =>
    searchConfig?.filterValue
      ? folder.name
          .toLocaleLowerCase()
          .includes(searchConfig.filterValue.toLocaleLowerCase())
      : true,
  );

  if (filteredFolders.length === 0) {
    return null;
  }

  return (
    <Box sx={sx}>
      <ScrollableList
        id={`folders-list`}
        leftButtonSx={{ transform: 'translateY(-40%)' }}
        rightButtonSx={{ transform: 'translateY(-40%)' }}
      >
        <Flex gap={2} pt={1}>
          {filteredFolders.map((folder) => renderValue(folder))}
        </Flex>
      </ScrollableList>
    </Box>
  );
};

export default PlaylistTemplateFoldersList;

const EllipsisText = styled(Typography)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;
