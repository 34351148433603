import { SvgIcon, SvgIconProps } from '@mui/material';

const CzIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0_789_9404)">
        <path
          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
          fill="#F0F0F0"
        />
        <path
          d="M10.9575 11.9987C10.9575 11.9987 3.52266 20.4857 3.51562 20.484C5.68716 22.6556 8.6872 23.9987 12.0009 23.9987C18.6283 23.9987 24.0009 18.6261 24.0009 11.9987H10.9575Z"
          fill="#D80027"
        />
        <path
          d="M3.51471 3.51562C-1.17157 8.20191 -1.17157 15.7999 3.51471 20.4862C5.45126 18.5497 7.31375 16.6872 12 12.0009L3.51471 3.51562Z"
          fill="#0052B4"
        />
      </g>
      <defs>
        <clipPath id="clip0_789_9404">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default CzIcon;
