import { differenceInDays } from 'date-fns';
import i18n from '../i18n/i18n';

export type DateLike = Date | string | number;

export const ensureDate = (value: DateLike) => {
  return value instanceof Date ? value : new Date(value);
};

export const formatDate = (value: DateLike) => {
  if (!value) return '';

  return new Intl.DateTimeFormat(i18n.language, {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  }).format(ensureDate(value));
};

export const formatMonth = (value: DateLike) => {
  if (!value) return '';

  return new Intl.DateTimeFormat(i18n.language, {
    month: 'long',
  }).format(ensureDate(value));
};

export const formatDateShort = (value: DateLike) => {
  if (!value) return '';

  return new Intl.DateTimeFormat(i18n.language, {
    day: 'numeric',
    month: 'numeric',
  }).format(ensureDate(value));
};

export const formatTime = (value: DateLike) => {
  return new Intl.DateTimeFormat(i18n.language, {
    hour: 'numeric',
    minute: 'numeric',
  }).format(ensureDate(value));
};

export const formatDateTime = (value: DateLike) => {
  return new Intl.DateTimeFormat(i18n.language, {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }).format(ensureDate(value));
};

export const formatDateTimeSeconds = (value: DateLike) => {
  return new Intl.DateTimeFormat(i18n.language, {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  }).format(ensureDate(value));
};

export const getStartOfDay = (date: DateLike) => {
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
};

export const getEndOfDay = (date: DateLike) => {
  const newDate = new Date(date);
  newDate.setHours(23, 59, 59, 999);
  return newDate;
};

export const getDayDiff = (later: DateLike, earlier: DateLike) => {
  return differenceInDays(getStartOfDay(later), getStartOfDay(earlier));
};
