import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import {
  Card,
  CardContent,
  Checkbox,
  Collapse,
  FormControlLabel,
  IconButton,
  SxProps,
  Theme,
} from '@mui/material';
import { useMemo, useState } from 'react';
import { BaseTypography } from '../../../../base/components/BaseTypography';
import { Flex } from '../../../../base/components/Flex';
import { matchQuery } from '../../../../lib/filter';
import { AssignableCompany } from './usePlaylistAssignCompanies';

interface Props {
  query: string;
  companies: AssignableCompany[];
  setSelectedCompanies: React.Dispatch<React.SetStateAction<string[]>>;
}

const splitCompaniesByRoot = (
  companies: AssignableCompany[],
): AssignableCompany[][] => {
  const minRank = companies.reduce(function (res, c) {
    return Math.min(res, c.rank);
  }, Infinity);
  const cardCompanies: AssignableCompany[][] = [];
  let group: AssignableCompany[] = [];
  for (const company of companies) {
    if (company.rank === minRank && group.length) {
      cardCompanies.push(group);
      group = [];
    }
    group.push(company);
  }
  if (group.length) {
    cardCompanies.push(group);
  }
  return cardCompanies;
};

const PlaylistAssignCompaniesTab = ({
  query,
  companies,
  setSelectedCompanies,
}: Props) => {
  const [expanded, setExpanded] = useState<string[]>([]);

  const cardCompanies = useMemo(
    () =>
      splitCompaniesByRoot(companies).filter((c) =>
        matchQuery(c[0].name, query),
      ),
    [companies, query],
  );

  const toggleCompanyExpanded = (companyId: string) => {
    setExpanded((e) =>
      e.includes(companyId)
        ? e.filter((c) => c !== companyId)
        : [...e, companyId],
    );
  };

  const toggleCompanySelection =
    (companyId: string) => (_: any, checked: boolean) => {
      setSelectedCompanies((selected) =>
        checked
          ? [...selected, companyId]
          : selected.filter((c) => c !== companyId),
      );
    };

  const renderCompanyCard = (companies: AssignableCompany[]) => {
    const root = companies[0];
    const showSubcompanies = expanded.includes(root.id);
    return (
      <Card key={root.id}>
        <CardContent sx={{ p: 3 }}>
          <Flex alignItems="center" justifyContent="space-between">
            <FormControlLabel
              checked={root.selected}
              control={<Checkbox />}
              onChange={toggleCompanySelection(root.id)}
              label={<BaseTypography variant="h5">{root.name}</BaseTypography>}
            />
            {companies.length > 1 && (
              <IconButton
                id={`expand-company-${root.id}`}
                onClick={() => toggleCompanyExpanded(root.id)}
              >
                {showSubcompanies ? (
                  <ExpandLessRoundedIcon />
                ) : (
                  <ExpandMoreRoundedIcon />
                )}
              </IconButton>
            )}
          </Flex>
        </CardContent>
        <Collapse in={showSubcompanies}>
          <Flex sx={SubcompaniesWrapper}>
            {companies
              .filter((c) => c.id !== root.id)
              .map((company) => (
                <Flex
                  key={company.id}
                  sx={{ ...SubcompanyRow, pl: company.rank * 2 }}
                >
                  <FormControlLabel
                    checked={company.selected}
                    onChange={toggleCompanySelection(company.id)}
                    control={<Checkbox />}
                    label={
                      <BaseTypography variant="body2">
                        {company.name}
                      </BaseTypography>
                    }
                  />
                </Flex>
              ))}
          </Flex>
        </Collapse>
      </Card>
    );
  };
  return (
    <Flex flexDirection="column" gap={2}>
      {cardCompanies.map(renderCompanyCard)}
    </Flex>
  );
};

export default PlaylistAssignCompaniesTab;

const SubcompaniesWrapper: SxProps<Theme> = {
  flexDirection: 'column',
  boxShadow: 'inset 0 10px 30px -10px rgba(0,0,0,0.2)',
  px: 3,
  pb: 2,
};

const SubcompanyRow: SxProps<Theme> = {
  py: 2,

  borderBottom: '1px solid',
  borderColor: 'grey.300',
};
