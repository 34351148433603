import { useQuery } from '@apollo/client';
import {
  GetCompaniesDocument,
  CompanyStatus,
} from '../graphql/graphql-operations';

export default function useActiveCompanies() {
  const { data: companiesData } = useQuery(GetCompaniesDocument, {
    variables: {
      companyFilter: {
        status: CompanyStatus.ACTIVE,
      },
    },
    fetchPolicy: 'cache-first',
  });
  if (!companiesData) {
    return [];
  }
  return companiesData.companies;
}
