import { useContext } from 'react';
import { UserPermission } from '../../../graphql/graphql-operations';
import { AuthUserContext } from './UserProvider';

export const useUser = () => {
  const user = useContext(AuthUserContext);

  const hasPermission = (...requiredPermissions: UserPermission[]) => {
    return requiredPermissions.every((permission) =>
      user?.permissions.includes(permission),
    );
  };

  const hasAnyPermission = (...requiredAnyPermissions: UserPermission[]) => {
    return requiredAnyPermissions.some((permission) =>
      user?.permissions.includes(permission),
    );
  };

  const hasCompanyManagementPermission = (companyId: string | number) => {
    const targetCompanyId = Number(companyId);
    if (!user) {
      return false;
    }
    if (hasPermission(UserPermission.MANAGE_COMPANIES)) {
      return true;
    }
    const isSubsidiary = user.subsidiaries?.find(
      (companyId) => companyId === targetCompanyId,
    );
    return (
      (user.companyId === targetCompanyId || isSubsidiary) &&
      user.permissions.includes(UserPermission.MANAGE_SUBSIDIARIES)
    );
  };

  return {
    user,
    hasPermission,
    hasAnyPermission,
    hasCompanyManagementPermission,
  };
};
