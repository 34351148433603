import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import {
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  styled,
} from '@mui/material';
import { Link } from 'react-router-dom';
import HasPermission from '../../base/components/UserProvider/HasPermission';
import { useUser } from '../../base/components/UserProvider/useUserHook';
import { UserPermission } from '../../graphql/graphql-operations';
import useLogout from '../../hooks/useLogout';
import { useTranslationPrefix } from '../../hooks/useTranslationPrefix';
import { useAppVariant } from '../../lib/useAppVariant';
import ClientUserMenuItem from './ClientUserMenuItem';
import VrBoxSelector from './VrBoxSelector';

interface Props {
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
}

const ClientUserMenu = ({ open, anchorEl, onClose }: Props) => {
  const { t, _t } = useTranslationPrefix('ClientUserMenu');
  const { user } = useUser();
  const { logout } = useLogout();
  const { setVariant } = useAppVariant();

  if (!user) return null;
  return (
    <>
      <Menu
        id="training-top-bar-lang-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl) && open}
        onClose={onClose}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            top: 'initial !important',
            bottom: '1rem',
          },
        }}
      >
        <HasPermission val={UserPermission.UPDATE_MY_PASSWORD}>
          <ClientUserMenuItem
            onClick={onClose}
            component={Link}
            to={`/users/${user.id}/password`}
          >
            <ItemIcon>
              <AccountCircleOutlinedIcon />
            </ItemIcon>
            <ItemText>{_t('changePassword', 'Change password')}</ItemText>
          </ClientUserMenuItem>
        </HasPermission>
        <HasPermission val={UserPermission.READ_COMPANY_VR_BOXES}>
          <VrBoxSelector onClose={onClose} />
        </HasPermission>
        {user && [
          <HasPermission
            key="app-variant-admin"
            val={UserPermission.LOGIN_ADMIN}
          >
            <ClientUserMenuItem onClick={() => setVariant('admin')}>
              <ItemIcon>
                <ManageAccountsOutlinedIcon />
              </ItemIcon>
              <ItemText>{t('appBarMenu.admin', 'Administration')}</ItemText>
            </ClientUserMenuItem>
          </HasPermission>,
          <Divider sx={{ m: '0px !important' }} key="divider" />,
          <ClientUserMenuItem key="logout" onClick={logout}>
            <ItemIcon>
              <LogoutIcon color="error" />
            </ItemIcon>
            <ListItemText primaryTypographyProps={{ color: 'error' }}>
              {t('appBarMenu.logout', 'Log out')}
            </ListItemText>
          </ClientUserMenuItem>,
        ]}
      </Menu>
    </>
  );
};

export default ClientUserMenu;

const ItemIcon = styled(ListItemIcon)(
  ({ theme: { palette } }) => `
  color: ${palette.grey[600]};
  font-size: 1.5rem;
`,
);

const ItemText = styled(ListItemText)(
  ({ theme: { palette } }) => `
  color: ${palette.grey[600]};
`,
);
