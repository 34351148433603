import { useQuery } from '@apollo/client';
import BackHandIcon from '@mui/icons-material/BackHand';
import {
  Box,
  Card,
  CardContent,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';

import { useEffect, useState } from 'react';
import { Flex } from '../../../base/components/Flex';
import {
  GetLimbMovementIntervalsDocument,
  GetLimbMovementSeriesDocument,
} from '../../../graphql/graphql-operations';
import useQueryErrorSnackbar from '../../../hooks/useQueryErrorSnackbar';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import { ensureDate, formatDate, getEndOfDay } from '../../../lib/date';
import ContentWrapper from '../../components/ContentWrapper';
import { Loader } from '../../components/Loader';
import PageHeader from '../../components/PageHeader';
import { UserReportPage } from './UserReportsRoute';
import LimbMovementChart, {
  LimbMovementView,
} from './components/LimbMovementChart';
import ReportNav from './components/ReportNav';
import { useReportUser } from './utils/useReportUser';

const now = new Date();

const UpperLimbMovementViewPage = () => {
  const { _t } = useTranslationPrefix('UpperLimbMovementViewPage');
  const { userId, userData, userLoading, backUrl } = useReportUser();
  const [selectedDay, setSelectedDay] = useState<string>();
  const [view, setView] = useState<LimbMovementView>('back');

  const {
    data: intervalData,
    refetch: intervalRefetch,
    error: intervalError,
    loading: intervalLoading,
  } = useQuery(GetLimbMovementIntervalsDocument, {
    variables: {
      userId: userId!,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    skip: !userId,
  });

  useQueryErrorSnackbar(intervalError, intervalRefetch);

  useEffect(() => {
    if (
      !selectedDay &&
      intervalData?.userReports.upperLimbMovementIntervals.length
    ) {
      const date =
        intervalData?.userReports.upperLimbMovementIntervals[
          intervalData?.userReports.upperLimbMovementIntervals.length - 1
        ];
      setSelectedDay(date);
    }
  }, [intervalData, selectedDay]);

  const {
    data: seriesData,
    refetch: seriesRefetch,
    error: seriesError,
  } = useQuery(GetLimbMovementSeriesDocument, {
    variables: {
      userId: userId!,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      fromDate: ensureDate(selectedDay || now).toISOString(),
      toDate: getEndOfDay(selectedDay || now).toISOString(),
    },
    skip: !userId && !selectedDay,
    fetchPolicy: 'cache-and-network',
  });
  useQueryErrorSnackbar(seriesError, seriesRefetch);

  const loading = userLoading || intervalLoading;
  return (
    <ContentWrapper>
      <PageHeader
        title={_t('title', 'Report {{firstName}} {{lastName}}', {
          firstName: userData?.user.firstName,
          lastName: userData?.user.lastName,
        })}
        back={backUrl}
      ></PageHeader>
      <ReportNav currentPage={UserReportPage.UPPER_LIMB_MOVEMENT_VIEW} />
      {loading ? (
        <Loader />
      ) : (
        <>
          <Flex flexDirection="column" gap={2}>
            <Card>
              <CardContent>
                <Flex
                  marginX={2}
                  gap={2}
                  flexDirection="row"
                  alignItems="baseline"
                  justifyContent="space-between"
                  mb={2}
                  flexWrap="wrap"
                >
                  <Box flexDirection="column" alignItems="flex-start">
                    <Typography variant="h6" component="h2">
                      {_t(
                        'limbMovementBackView',
                        'Pohyb rukou se středem v ramenním kloubu',
                      )}
                    </Typography>
                  </Box>
                  <Flex gap={2}>
                    <ToggleButtonGroup
                      color="primary"
                      value={view}
                      exclusive
                      onChange={(_, val) => val && setView(val)}
                    >
                      <ToggleButton value="back">
                        {_t('back', 'Zezadu')}
                      </ToggleButton>
                      <ToggleButton value="top">
                        {_t('top', 'Seshora')}
                      </ToggleButton>
                      <ToggleButton value="side">
                        {_t('side', 'Z boku')}
                      </ToggleButton>
                    </ToggleButtonGroup>
                    <Select
                      value={selectedDay || ''}
                      onChange={(e) => setSelectedDay(e.target.value)}
                      sx={{ minWidth: '12ch' }}
                    >
                      {intervalData?.userReports.upperLimbMovementIntervals.map(
                        (date) => (
                          <MenuItem key={date} value={date}>
                            {formatDate(date)}
                          </MenuItem>
                        ),
                      )}
                    </Select>
                  </Flex>
                </Flex>
                <Box
                  sx={{
                    overflowX: 'auto',
                    scrollbarWidth: 'thin',
                  }}
                >
                  <Box width="fit-content" minWidth="100%">
                    <Flex gap={4} justifyContent="space-between">
                      <Flex alignItems="center" ml="185px">
                        <BackHandIcon fontSize="large" />
                        <Typography>{_t('leftHand', 'Levá ruka')}</Typography>
                      </Flex>
                      <Flex alignItems="center" mr="185px">
                        <BackHandIcon
                          fontSize="large"
                          sx={{ transform: 'scaleX(-1)' }}
                        />
                        <Typography>{_t('rightHand', 'Pravá ruka')}</Typography>
                      </Flex>
                    </Flex>

                    <LimbMovementChart
                      data={
                        seriesData?.userReports.upperLimbMovementSeries || []
                      }
                      view={view}
                    />
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Flex>
        </>
      )}
    </ContentWrapper>
  );
};

export default UpperLimbMovementViewPage;
