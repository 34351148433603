/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import {
  GetIceServerConfigDocument,
  WebRtcCandidateDocument,
  WebRtcCandidateMessageInput,
  WebRtcDeviceCandidateDocument,
  WebRtcNewOfferDocument,
  WebRtsAnswerDocument,
} from '../../../graphql/graphql-operations';
import useQueryErrorSnackbar from '../../../hooks/useQueryErrorSnackbar';
import { ConnectionHolder, prepareConnection } from './webRTCUtilsWorkaround';

interface Props {
  deviceId: string;
  className?: string;
}

const ScreenMirrorWorkaround: React.VFC<Props> = ({
  deviceId,
  className,
}: Props) => {
  const {
    data: iceServerConfigData,
    error,
    refetch,
  } = useQuery(GetIceServerConfigDocument);
  useQueryErrorSnackbar(error, refetch);
  const elementId = `screen-mirror-${deviceId}`;
  const [webRtcNewOffer] = useMutation(WebRtcNewOfferDocument);
  const [sendWebRtcCandidate] = useMutation(WebRtcCandidateDocument);
  const [connection, setConnection] = useState<ConnectionHolder>();
  const [webRtcSessionId, setWebRtcSessionId] = useState<string>();

  useSubscription(WebRtsAnswerDocument, {
    variables: { sessionId: webRtcSessionId },
    skip: !webRtcSessionId,
    onSubscriptionData: ({ subscriptionData }) => {
      if (
        subscriptionData &&
        subscriptionData.data &&
        subscriptionData.data.webRtsAnswer
      ) {
        const { sdp } = subscriptionData.data.webRtsAnswer;

        connection!.handleAnswer({
          type: 'answer',
          sdp,
        });
      }
    },
  });

  useSubscription(WebRtcDeviceCandidateDocument, {
    variables: { sessionId: webRtcSessionId },
    skip: !webRtcSessionId,
    onSubscriptionData: ({ subscriptionData }) => {
      if (
        subscriptionData &&
        subscriptionData.data &&
        subscriptionData.data.webRtcDeviceCandidate
      ) {
        connection?.addRemoteCandidate(
          subscriptionData.data.webRtcDeviceCandidate,
        );
      }
    },
  });

  useEffect(() => {
    if (iceServerConfigData && !connection) {
      const conn = prepareConnection(
        iceServerConfigData,
        handleNewLocalCandidate,
        handleNewImage,
      );
      conn.prepareOffer(handleNewOffer);
      setConnection(conn);
    }
  }, [iceServerConfigData, connection]);

  //onUnload disconnect
  useEffect(() => {
    return () => {
      connection &&
        connection.peerConnection &&
        connection.peerConnection.close();
    };
  }, [connection]);

  const handleNewOffer = async (sd: RTCSessionDescriptionInit) => {
    const response = await webRtcNewOffer({
      variables: {
        offerReq: {
          deviceId,
          offer: {
            type: sd.type,
            sdp: sd.sdp!,
          },
        },
      },
    });

    const { data } = response;
    if (data) {
      const { webRtcNewOffer: sessionId } = data;
      setWebRtcSessionId(sessionId);
      return sessionId;
    }

    return null;
  };

  const handleNewLocalCandidate = (
    sesstionId: string,
    { candidate, sdpMid, sdpMLineIndex }: RTCIceCandidate,
  ) => {
    const input: WebRtcCandidateMessageInput = {
      deviceId: deviceId,
      source: 'admin',
      sessionId: sesstionId,
      candidate: {
        candidate,
        sdpMid,
        sdpMLineIndex,
      },
    };
    sendWebRtcCandidate({ variables: { candidate: input } });
  };

  const handleNewImage = (imageSrc: string) => {
    const imageElement = document.getElementById(elementId) as HTMLImageElement;
    if (imageElement) {
      imageElement.src = imageSrc;
    }
  };

  return (
    <img
      alt="sceen mirror stream"
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
      id={elementId}
      className={className}
    />
  );
};

export default ScreenMirrorWorkaround;
