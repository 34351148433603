import {
  FormControlProps,
  FormHelperTextProps,
  InputLabelProps,
  SelectProps,
} from '@mui/material';
import { Field } from 'formik';
import { Select } from 'formik-mui';

interface Props extends Omit<SelectProps, 'value' | 'error'> {
  name: string;
  formControl?: FormControlProps;
  formHelperText?: FormHelperTextProps;
  inputLabel?: InputLabelProps;
}

const FormikSelect = ({
  name,
  fullWidth,
  formControl = {},
  inputProps,
  required,
  ...selectProps
}: Props) => {
  //For some reason select from formik-mui does not support fullWidth prop directly, must be in formControl
  return (
    <Field
      name={name}
      component={Select}
      inputProps={{ required: false, ...inputProps }}
      {...selectProps}
      formControl={{ fullWidth: fullWidth, required: required, ...formControl }}
    />
  );
};

export default FormikSelect;
