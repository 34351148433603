import Battery0BarRoundedIcon from '@mui/icons-material/Battery0BarRounded';
import Battery1BarRoundedIcon from '@mui/icons-material/Battery1BarRounded';
import Battery2BarRoundedIcon from '@mui/icons-material/Battery2BarRounded';
import Battery3BarRoundedIcon from '@mui/icons-material/Battery3BarRounded';
import Battery4BarRoundedIcon from '@mui/icons-material/Battery4BarRounded';
import Battery5BarRoundedIcon from '@mui/icons-material/Battery5BarRounded';
import Battery6BarRoundedIcon from '@mui/icons-material/Battery6BarRounded';
import BatteryFullRoundedIcon from '@mui/icons-material/BatteryFullRounded';
import { SvgIconProps } from '@mui/material';
import { BaseTypography } from '../../../../base/components/BaseTypography';
import { Flex } from '../../../../base/components/Flex';
import { useTranslationPrefix } from '../../../../hooks/useTranslationPrefix';

interface Props {
  battery?: number | null;
  textColor?: string;
}

const DeviceBatteryStatus = ({ battery, textColor }: Props) => {
  const { _t } = useTranslationPrefix(`DeviceBatteryStatus`);

  const renderBatteryIcon = () => {
    const sx: SvgIconProps['sx'] = {
      color: !battery
        ? 'grey.300'
        : battery > 20
        ? 'success.main'
        : 'warning.main',
      fontSize: '1.5rem',
    };

    if (typeof battery !== 'number' || battery > 95) {
      return <BatteryFullRoundedIcon sx={sx} />;
    }
    if (battery > 81) {
      return <Battery6BarRoundedIcon sx={sx} />;
    }
    if (battery > 67) {
      return <Battery5BarRoundedIcon sx={sx} />;
    }
    if (battery > 53) {
      return <Battery4BarRoundedIcon sx={sx} />;
    }
    if (battery > 39) {
      return <Battery3BarRoundedIcon sx={sx} />;
    }
    if (battery > 25) {
      return <Battery2BarRoundedIcon sx={sx} />;
    }
    if (battery > 10) {
      return <Battery1BarRoundedIcon sx={sx} />;
    }
    return <Battery0BarRoundedIcon sx={sx} />;
  };

  return (
    <Flex alignItems="center">
      {renderBatteryIcon()}
      <BaseTypography
        color={textColor || 'text.primary'}
        fontWeight="bold"
        whiteSpace="nowrap"
      >
        {battery ? `${battery} %` : _t('notAvailable', 'N/A')}
      </BaseTypography>
    </Flex>
  );
};

export default DeviceBatteryStatus;
