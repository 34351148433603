import { SvgIcon, SvgIconProps } from '@mui/material';

const InfoIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M11.9993 18.6667C12.3771 18.6667 12.694 18.5387 12.95 18.2827C13.2051 18.0275 13.3327 17.7111 13.3327 17.3333V11.9667C13.3327 11.5889 13.2051 11.2778 12.95 11.0333C12.694 10.7889 12.3771 10.6667 11.9993 10.6667C11.6216 10.6667 11.3051 10.7942 11.05 11.0493C10.794 11.3053 10.666 11.6222 10.666 12V17.3667C10.666 17.7444 10.794 18.0556 11.05 18.3C11.3051 18.5444 11.6216 18.6667 11.9993 18.6667ZM11.9993 7.99999C12.3771 7.99999 12.694 7.87199 12.95 7.61599C13.2051 7.36088 13.3327 7.04444 13.3327 6.66666C13.3327 6.28888 13.2051 5.972 12.95 5.716C12.694 5.46088 12.3771 5.33333 11.9993 5.33333C11.6216 5.33333 11.3051 5.46088 11.05 5.716C10.794 5.972 10.666 6.28888 10.666 6.66666C10.666 7.04444 10.794 7.36088 11.05 7.61599C11.3051 7.87199 11.6216 7.99999 11.9993 7.99999Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
/*
const InfoIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="m14.95 8.7913-5.7251 0.63118-0.205 0.83572 1.125 0.18254c0.73501 0.15395 0.88001 0.38706 0.72001 1.0314l-1.845 7.6271c-0.48501 1.9727 0.26251 2.9008 2.02 2.9008 1.3625 0 2.9451-0.5542 3.6626-1.3152l0.22-0.91482c-0.50001 0.38706-1.23 0.54098-1.715 0.54098-0.68753 0-0.93753-0.42447-0.76002-1.1722zm0.175-4.592c0 0.58328-0.2634 1.1427-0.73226 1.5551-0.46884 0.41244-1.1047 0.64414-1.7678 0.64414-0.66305 0-1.299-0.23171-1.7678-0.64414-0.46885-0.41244-0.73224-0.97183-0.73224-1.5551s0.2634-1.1427 0.73224-1.5551c0.46885-0.41244 1.1048-0.64415 1.7678-0.64415 0.66306 0 1.299 0.23171 1.7678 0.64415 0.46885 0.41244 0.73226 0.97183 0.73226 1.5551z"
        fill="currentColor"
        strokeWidth=".89286"
      />
    </SvgIcon>
  );
};
*/
export default InfoIcon;
