import { PropsWithChildren } from 'react';
import {
  StreamType,
  UserPermission,
} from '../../../graphql/graphql-operations';
import useCompany from '../../../lib/useCompany';
import { useUser } from './useUserHook';

interface Props {
  val: UserPermission | UserPermission[];
  streamType?: StreamType;
}

const HasPermission: React.FC<Props> = ({
  val,
  streamType,
  children,
}: PropsWithChildren<Props>) => {
  const permissions = Array.isArray(val) ? val : [val];
  const { hasPermission } = useUser();
  const { company } = useCompany();
  const isSameStream = !streamType || company?.streamType === streamType;
  return hasPermission(...permissions) && isSameStream ? <>{children}</> : null;
};

export default HasPermission;
